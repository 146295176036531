define("apollo/pods/carriers/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "apollo/mixins/menu-mixin"], function (_exports, _authenticatedRouteMixin, _menuMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _menuMixin.default, {
    sessionAccount: service('session-account'),
    access: ['ROLE_CARRIERS_TAB'],
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      params.max = this.get('sessionAccount.currentUser.maxRowsInCompaniesTable') || 10;
      params.page = params.page || 1;
      params.principalCompanyId = localStorage.getItem('principalId');
      var partnershipTypesNames = this.store.peekAll('partnership-type').map(function (pt) {
        return pt.get('name');
      });
      params.partnershipTypesNames = partnershipTypesNames.filter(function (name) {
        return name === 'CARRIER' || name === 'OPERATOR';
      });
      return this.store.query('company', params);
    },
    renderTemplate: function renderTemplate() {
      this.render('companies.index');
    },
    setupController: function setupController(controller, model) {
      this.controllerFor('companies.index').setProperties({
        model: model,
        totalPages: model.get('meta.totalPages'),
        showCarriersTable: true
      });
    },
    activate: function activate() {
      this.activateCurrentTab('CARRIERS_TAB');
    },
    actions: {
      willTransition: function willTransition() {
        this.get('controller').setProperties({
          info: null,
          errors: null,
          successMessage: null
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});