define("apollo/pods/components/file-upload/component", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    action: '',
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    // eslint-disable-next-line ember/no-component-lifecycle-hooks
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var self = this;
      var token = this.get('sessionAccount').get('token');
      var transportId = this.get('transport.id');
      var sectionId = this.get('section.id');
      var properId = transportId || sectionId;
      var isFromDocsModalOrSection = this.get('isFromDocsModal') || this.get('isFromSection');
      this.$().find('.file-upload-input').attr('data-url', _environment.default.serverURL + this.get('resourceUrl')).fileupload({
        autoUpload: true,
        formData: function formData() {
          return [{
            name: 'transportId',
            value: transportId
          }, {
            name: 'sectionId',
            value: sectionId
          }, {
            name: 'fileType',
            value: self.get('fileType')
          }, {
            name: 'token',
            value: token
          }];
        },
        submit: function submit(e, data) {
          if (data.files.some(function (it) {
            return it.size === 0;
          })) {
            if (isFromDocsModalOrSection) {
              self.set('action', 'fileUploadFail');
              self.sendAction('action', properId);
            }

            $('.progress').hide();
            alert(self.get('intl').t('errors.emptyFile'));
            return false;
          }

          return true;
        },
        progress: function progress(e, data) {
          console.debug('File upload in progress..');

          if (isFromDocsModalOrSection) {
            self.set('action', 'fileUploadStarted');
            self.sendAction('action');
          }

          var progressBar = self.$().find('.progress');
          progressBar.show();
          var progress = parseInt(data.loaded / data.total * 100, 10);
          progressBar.find('.bar').css('width', progress + '%');
        },
        done: function done() {
          if (isFromDocsModalOrSection) {
            self.set('action', 'fileUploadSuccess');
            self.sendAction('action', properId);
          }

          if (!isFromDocsModalOrSection) {
            self.get('transport').reload();
          }

          $('.progress').hide();
        },
        fail: function fail(jqXHR, textStatus) {
          if (isFromDocsModalOrSection) {
            self.set('action', 'fileUploadFail');
            self.sendAction('action', properId);
          }

          $('.progress').hide();

          if (textStatus.jqXHR.status === 409) {
            alert(self.get('intl').t('errors.fileAlreadyExists'));
            return;
          }

          alert(self.get('intl').t('errors.fileUploadError'));
        }
      });
    },
    // eslint-disable-next-line ember/no-component-lifecycle-hooks
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.$().find('.file-upload-input').fileupload('destroy');
    },
    change: function change(e) {
      if (this.get('transport.id')) {
        // walidujemy ilość przesłanych do zlecenia plików
        var maxCountString = this.get('sessionAccount').getSettingValue('MAX_SIMULTANEOUSLY_ADDED_FILES_COUNT');
        var maxCount = maxCountString ? Number(maxCountString) : null;
        var filesCount = e.target.files ? e.target.files.length : 0;

        if (maxCount && maxCount < filesCount) {
          alert(this.get('intl').t('document.maxCountExceeded', {
            maxCount: maxCount
          }));
          return;
        }
      }

      console.log('change ' + e);
      var event = jQuery.Event('change');
      event.target = e.target;
      this.$().find('.file-upload-input').trigger(event);
    }
  });

  _exports.default = _default;
});