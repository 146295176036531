define("apollo/pods/components/classification-resource/place-assignment/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    ajaxService: Ember.inject.service('ajax-service'),
    store: Ember.inject.service(),
    shouldButtonBeDisabled: Ember.computed('resource.id', function () {
      return this.get('resource.place.id') || !this.get('resource.active');
    }),
    buttonClass: Ember.computed('resource.id', function () {
      if (this.get('resource.state') || !this.get('resource.transport') || this.get('shouldButtonBeDisabled')) {
        return "btn dark-font";
      }

      return "btn";
    }),
    sendAssignPlace: function sendAssignPlace(resource, place) {
      resource.set('place', place);
      var assignPlaceData = {
        classificationResource: resource,
        checkpointToConfirm: resource.get('transport.firstUnconfirmedCheckpoint.id')
      };
      this.get('ajaxService').send('/classification-resources/assign-place', 'PUT', assignPlaceData, true).then(function () {
        resource.reload();
        $('.assign-place-button').button('reset');
      });
    },
    sendReserveClassificationResource: function sendReserveClassificationResource(resource) {
      var _this = this;

      this.get('ajaxService').send('/classification-resources/reserve', 'POST', resource, true).then(function (res) {
        _this.store.findRecord('classification-resource', res.classificationResource.id);

        $('.assign-place-button').button('reset');
      });
    },
    actions: {
      assignPlace: function assignPlace(resource, place) {
        var self = this;
        var isDummyResource = resource.get('transport') === null;

        if (isDummyResource) {
          resource = {
            enabled: true,
            queue: place.get('queue.id'),
            place: place.get('id')
          };
          self.sendReserveClassificationResource(resource);
        } else {
          self.sendAssignPlace(resource, place);
        }
      }
    }
  });

  _exports.default = _default;
});