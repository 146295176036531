define("apollo/pods/auth/remind/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Controller.extend({
    intl: service(),
    ajaxService: service(),
    loginController: Ember.inject.controller('auth/login'),
    remindEmail: '',
    actions: {
      sendRemindEmail: function sendRemindEmail() {
        var self = this;
        self.set('errorMessage', null);
        var remindingProgess = $('.reminding-progress');
        remindingProgess.show();
        var url = '/remind';
        var email = this.get('remindEmail');
        var emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        if (email.length === 0) {
          remindingProgess.hide();
          self.set('errorMessage', self.get('intl').t('common.formShouldNotBeEmpty'));
          return;
        }

        if (!email.match(emailFormat)) {
          remindingProgess.hide();
          self.set('errorMessage', self.get('intl').t('errors.invalidEmail'));
          return;
        }

        var data = {
          email: email
        };
        this.get('ajaxService').send(url, 'POST', data, true).then(function (response) {
          var msg = self.get('intl').t('auth.passwordRemindedMessage', {
            email: email
          });
          self.get('loginController').set('message', msg);
          self.transitionToRoute('auth.login');
        }).catch(function (response) {
          remindingProgess.hide();
          var errors = response.responseJSON.errors;

          if (errors) {
            self.set('errorMessage', errors);
            console.error(errors);
          }
        });
      }
    }
  });

  _exports.default = _default;
});