define("apollo/pods/components/transport-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      close: function close() {
        this.set('action', 'hideModal');
        this.sendAction('action');
      },
      showModal: function showModal(transport) {
        this.set('action', 'showModal');
        this.sendAction('action', 'transports.modals.create-transport', transport);
      } // Za pokazanie okienka będzie odpowiedzialny komponent `custom-modal/controller.js`

    }
  });

  _exports.default = _default;
});