define("apollo/pods/components/warehouse/warehouse-location-multi-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    warehouseLocationsNames: Ember.computed('step.stepWarehouseLocations.length', function () {
      var objects = [];
      this.get('step.stepWarehouseLocations').forEach(function (whl) {
        objects.pushObject({
          id: whl.get('id'),
          name: whl.get('warehouseLocation.nameInAppropriateLanguage'),
          wlId: whl.get('warehouseLocation.id')
        });
      });
      return objects;
    }),
    actions: {
      setWarehouseLocations: function setWarehouseLocations(locations) {
        var self = this;
        var step = this.get('step');
        var swls = step.get('stepWarehouseLocations');

        if (!step || !swls) {
          return;
        }

        locations.forEach(function (loc) {
          if (!swls.get('length') || !swls.any(function (swl) {
            return loc.id === swl.get('id');
          })) {
            var newSwl = self.get('store').createRecord('stepWarehouseLocation', {
              step: step,
              warehouseLocation: self.get('store').peekRecord('warehouseLocation', loc.wlId),
              test: true
            });
            step.get('stepWarehouseLocations').pushObject(newSwl);
          }
        });

        if (locations.length === step.get('stepWarehouseLocations.length')) {
          return;
        }

        var obsoleteLoc = step.get('stepWarehouseLocations').find(function (swl) {
          return !locations.any(function (loc) {
            return loc.id === swl.get('id');
          });
        });

        if (!obsoleteLoc) {
          obsoleteLoc = step.get('stepWarehouseLocations').find(function (swl) {
            return !locations.any(function (loc) {
              return !loc.id && loc.wlId === swl.get('warehouseLocation.id');
            });
          });
        }

        if (obsoleteLoc) {
          step.get('stepWarehouseLocations').removeObject(obsoleteLoc);
        }
      }
    }
  });

  _exports.default = _default;
});