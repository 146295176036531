define("apollo/pods/components/auction/create-auction-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    DEFAULT_AUCTION_FINISH_DELAY_INTERVAL: '2h',
    finishDelayInterval: Ember.computed('', function () {
      var auctionSetting = this.get('sessionAccount').getAuctionSetting(this.get('transport.transportType.id'));
      return auctionSetting ? auctionSetting.get('defaultAuctionFinishDelayInterval') : this.DEFAULT_AUCTION_FINISH_DELAY_INTERVAL;
    }),
    suggestedFinishTime: Ember.computed('finishDelayInterval', function () {
      var delayInterval = this.get('finishDelayInterval');
      var intervalValue = delayInterval.substring(0, delayInterval.length - 1);
      var intervalUnit = delayInterval.substring(delayInterval.length - 1);
      return moment().add(intervalValue, intervalUnit).toDate();
    }),
    actions: {
      showAuctionCreationForm: function showAuctionCreationForm() {
        var _this = this;

        var self = this;
        var suggestedFinishTime = this.get('suggestedFinishTime');
        var principalCompanyId = localStorage.getItem('principalId');
        var auctionSetting = this.get('sessionAccount').getAuctionSetting(this.get('transport.transportType.id'));
        var symbol = auctionSetting ? auctionSetting.get('defaultCurrency') : "PLN";
        var carriersGroups = this.get('store').peekAll('contractors-group');
        var currency = this.get('store').peekAll('currency').filterBy('symbol', symbol).get('firstObject');
        this.set('action', 'showModal');
        self.get('store').query('company', {
          principalCompanyId: principalCompanyId,
          partnershipTypesNames: ['CARRIER', 'OPERATOR'],
          statuses: ['NO_INVITATION', 'ACCEPTED', 'UPDATED', 'BLOCKED']
        }).then(function (carriers) {
          var auctionModel = _this.get('store').createRecord('auction', {
            currency: currency,
            transport: _this.get('transport'),
            plannedFinishTime: suggestedFinishTime,
            carriers: carriers,
            carriersGroups: carriersGroups
          });

          self.sendAction('action', 'auctions.modals.auction-form', auctionModel);
        });
      }
    }
  });

  _exports.default = _default;
});