define("apollo/pods/invitations/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    receivedInvitations: Ember.computed('model.invitations.@each', function () {
      var currentUserId = this.get('sessionAccount').get('currentUser.id');
      var currentUserEmail = this.get('sessionAccount.currentUser.email');
      return this.get('model.invitations').filter(function (item) {
        return item.get("creator.id") !== currentUserId && item.get('email') === currentUserEmail;
      });
    }),
    sentInvitations: Ember.computed('model.invitations.@each', function () {
      var currentUserId = this.get('sessionAccount').get('currentUser.id');
      return this.get('model.invitations').filter(function (item) {
        return item.get("creator.id") === currentUserId;
      }).sortBy('status.idx');
    }),
    actions: {
      getInvitations: function getInvitations(type) {
        this.send('refreshModel', type);
      }
    }
  });

  _exports.default = _default;
});