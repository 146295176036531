define("apollo/pods/advice/model", ["exports", "ember-data", "ember-data/model", "apollo/mixins/model-validator", "ember-data-copyable", "apollo/utils/validators/registration-number-validator"], function (_exports, _emberData, _model, _modelValidator, _emberDataCopyable, _registrationNumberValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = _model.default.extend(_modelValidator.default, _emberDataCopyable.default, {
    sessionAccount: Ember.inject.service('session-account'),
    changeTracker: Object.freeze({
      auto: true,
      enableIsDirty: true
    }),
    DOCUMENT_TYPES: Object.freeze({
      ID_CARD: 'ID_CARD',
      PASSPORT: 'PASSPORT'
    }),
    transport: belongsTo('transport', {
      async: true
    }),
    creator: belongsTo('user', {
      async: true,
      embedded: 'save'
    }),
    driverLanguage: belongsTo('country', {
      async: true,
      embedded: 'save'
    }),
    truck: belongsTo('truck', {
      async: true,
      embedded: 'save'
    }),
    dateCreated: attr(),
    driverName: attr('string'),
    driverId: attr(),
    driverMobilePhonePrefix: attr('string', {
      defaultValue: '48'
    }),
    driverMobilePhoneNumber: attr('string'),
    carRegistrationNumber: attr('string'),
    trailerRegistrationNumber: attr('string'),
    documentNumber: attr(),
    documentType: attr('string', {
      defaultValue: function defaultValue() {
        return 'ID_CARD';
      }
    }),
    termsOfConditionsAccepted: attr('boolean'),
    rodoAccepted: attr('boolean'),
    saveAsResource: attr('boolean'),

    /**
     * Jeśli dane awizacji nie są wypełnione przy tworzeniu zlecenia nie wymagamy zaznaczenia rodo
     */
    rodoValuesAreEmpty: Ember.computed('driverLanguage', 'driverName', 'driverMobilePhonePrefix', 'driverMobilePhoneNumber', 'carRegistrationNumber', 'trailerRegistrationNumber', 'documentNumber', function () {
      return !this.get('driverLanguage.content') && !this.get('driverName') && !this.get('driverMobilePhonePrefix') && !this.get('driverMobilePhoneNumber') && !this.get('carRegistrationNumber') && !this.get('trailerRegistrationNumber') && !this.get('documentNumber');
    }),
    validations: {
      driverName: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!model.get('requiresDriverName') || !model.get('requiresAdvice')) {
              return true;
            }

            return !!value;
          },
          message: 'blank'
        }]
      },
      driverMobilePhonePrefix: {
        custom: [{
          validation: function validation(key, value, model) {
            if ((!model.get('requiresPhoneNumber') || !model.get('requiresAdvice')) && !value) {
              return true;
            }

            return !!value;
          },
          message: 'blank'
        }]
      },
      driverMobilePhoneNumber: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!model.get('requiresPhoneNumber') || !model.get('requiresAdvice')) {
              return true;
            }

            return !!value;
          },
          message: 'blank'
        }, {
          validation: function validation(key, value, model) {
            if (!value || model.get('driverMobilePhonePrefix') !== '48' || !model.get('requiresPhoneNumber') || !model.get('requiresAdvice')) {
              return true;
            }

            return /^\d+$/.test(value) && value.length === 9;
          },
          message: 'regex'
        }]
      },
      carRegistrationNumber: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!model.get('requiresCarRegistration') || !model.get('requiresAdvice')) {
              return true;
            }

            return !!value;
          },
          message: 'blank'
        }, {
          validation: function validation(key, value) {
            return !value || _registrationNumberValidator.default.validate(value);
          },
          message: 'regex'
        }]
      },
      trailerRegistrationNumber: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!model.get('requiresTrailer') || !model.get('requiresAdvice')) {
              return true;
            }

            return !!value;
          },
          message: 'blank'
        }, {
          validation: function validation(key, value) {
            return !value || _registrationNumberValidator.default.validate(value);
          },
          message: 'regex'
        }]
      },
      documentNumber: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!model.get('requiresDriverDocumentNumber') || !model.get('allowsDriverDocumentNumber') || !model.get('requiresAdvice')) {
              return true;
            }

            return !!value;
          },
          message: 'blank'
        }]
      },
      documentType: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!model.get('allowsDriverDocumentNumber') || !model.get('requiresAdvice')) {
              return true;
            }

            return !!value;
          },
          message: 'blankSelect'
        }]
      },
      driverLanguage: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!model.get('requiresDriverLanguage') || !model.get('requiresAdvice')) {
              return true;
            }

            return !!value.content;
          },
          message: 'blank'
        }]
      },
      rodoAccepted: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!model.get('id') && model.get('rodoValuesAreEmpty')) {
              return true;
            }

            if (model.get('id') && (value === undefined || model.get('showRodo') === value)) {
              return true;
            }

            return !model.get('showRodo') || !!value;
          },
          message: 'notSelected'
        }]
      },
      termsOfConditionsAccepted: {
        custom: [{
          validation: function validation(key, value, model) {
            return !model.get('requiresAcceptanceOfTermsOfCondition') || !!value;
          },
          message: 'notSelected'
        }]
      },
      saveAsResource: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!value) {
              return true;
            }

            var phoneNumberIsValid = model.get('transport.transportType.adviceRequiresPhoneNumber') ? !!model.get('driverMobilePhoneNumber') : true;
            var driverNameIsValid = model.get('requiresDriverName') ? !!model.get('driverName') : true;
            var documentNumberIsValid = !model.get('requiresDriverDocumentNumber') || !model.get('allowsDriverDocumentNumber') || !!model.get('documentNumber');
            var languageIsValid = !model.get('requiresDriverLanguage') || !!model.get('driverLanguage.id');
            return languageIsValid && phoneNumberIsValid && driverNameIsValid && documentNumberIsValid;
          },
          message: 'info'
        }]
      },
      truck: {
        custom: [{
          validation: function validation(key, value) {
            return !value || !value.get('disabled');
          },
          message: function message(key, value) {
            return value.get('cannotBeChosenInfo');
          }
        }]
      }
    },
    showRodo: Ember.computed('canBeUpdated', 'transport.{transportType.allowsRodoCheckbox,isRelatedTransportInTransportCombining}', function () {
      return this.get('canBeUpdated') && this.get('transport.transportType.allowsRodoCheckbox');
    }),
    canBeUpdated: Ember.computed('transport.{canBeUpdated,firstUnconfirmedCheckpoint,advice.id,activeAuction,isRelatedTransportInTransportCombining}', function () {
      if (!this.get('transport.advice.id')) {
        return true;
      }

      return this.get('transport.content').actionCanBePerformed('UPDATE_ADVICE');
    }),
    disabled: Ember.computed('canBeUpdated', function () {
      return !this.get('canBeUpdated');
    }),
    formattedPhoneNumber: Ember.computed('driverMobilePhonePrefix', 'driverMobilePhoneNumber', function () {
      if (!this.get('driverMobilePhonePrefix')) {
        return this.get('driverMobilePhoneNumber');
      }

      return '+' + this.get('driverMobilePhonePrefix') + ' ' + this.get('driverMobilePhoneNumber');
    }),
    requiresPhoneNumber: Ember.computed('transport.transportType.adviceRequiresPhoneNumber', function () {
      return this.get('transport.transportType.adviceRequiresPhoneNumber');
    }),
    requiresAdvice: Ember.computed('transport.transportType.requiresAdvice', function () {
      return this.get('transport.transportType.requiresAdvice');
    }),
    requiresDriverDocumentNumber: Ember.computed('transport.transportType.requiresDriverDocumentNumber', function () {
      return this.get('transport.transportType.requiresDriverDocumentNumber');
    }),
    allowsDriverDocumentNumber: Ember.computed('transport.transportType.allowsDriverDocumentNumber', function () {
      return this.get('transport.transportType.allowsDriverDocumentNumber');
    }),
    requiresDriverName: Ember.computed('transport.transportType.requiresDriverName', function () {
      return this.get('transport.transportType.requiresDriverName');
    }),
    requiresDriverLanguage: Ember.computed('transport.transportType.requiresDriverLanguage', function () {
      return this.get('transport.transportType.requiresDriverLanguage');
    }),
    requiresCarRegistration: Ember.computed('transport.transportType.{requiresCarRegistrationNumber,allowsCarRegistrationNumber}', function () {
      return this.get('transport.transportType.requiresCarRegistrationNumber') && this.get('transport.transportType.allowsCarRegistrationNumber');
    }),
    requiresTrailer: Ember.computed('transport.transportType.requiresTrailer', function () {
      return this.get('transport.transportType.requiresTrailer');
    })
  });

  _exports.default = _default;
});