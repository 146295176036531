define("apollo/pods/advice/modals/create-advice/controller", ["exports", "apollo/mixins/advice-utils-mixin", "apollo/mixins/persistence-mixin", "apollo/mixins/transports-mixin", "jquery"], function (_exports, _adviceUtilsMixin, _persistenceMixin, _transportsMixin, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_adviceUtilsMixin.default, _persistenceMixin.default, _transportsMixin.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    scheduleController: Ember.inject.controller('warehouses/schedule'),
    createTransportController: Ember.inject.controller('transports/modals/create-transport'),
    dateToPerformAction: '',
    actionPlanned: false,
    transport: Ember.computed('model.transport', function () {
      return this.get('model.transport');
    }),
    hasAnyWindows: Ember.computed('model.transport', function () {
      return this.get('model.transport.timeWindows').filter(function (tw) {
        return tw.id !== null;
      }).length > 0;
    }),
    errors: Ember.computed('model.errors', function () {
      return this.get('model.errors') ? this.get('model.errors') : [];
    }),
    title: Ember.computed('transport.{title,internalIndex}', function () {
      return this.get('transport.title') + ' #' + this.get('transport.internalIndex');
    }),
    showSearchSpotsButton: Ember.computed('model.showSearchSpotsButton', function () {
      return this.get('model.showSearchSpotsButton');
    }),
    // eslint-disable-next-line ember/no-observers
    setRequiresAcceptanceOfTermsOfCondition: Ember.observer('model.advice', function () {
      if (!this.get('model.advice.id')) {
        return;
      }

      var principalCompanyId = localStorage.getItem('principalId');
      var s = this.get('store').peekAll('setting').filterBy('company.id', principalCompanyId).filterBy('name', 'SHOW_TERMS_OF_CONDITIONS_ACCEPTED').get('firstObject');
      this.get('model.advice').set('requiresAcceptanceOfTermsOfCondition', s.get('value') === 'true');
    }),
    initialStart: Ember.computed('model.initialStart', function () {
      return this.get('model.initialStart');
    }),
    initialStartFormatted: Ember.computed('model.initialStart', function () {
      return moment(this.get('model.initialStart')).format('DD.MM.YYYY HH:mm');
    }),
    hasUnsavedTimeWindow: Ember.computed('transport', function () {
      return this.get('transport.timeWindows').filter(function (tw) {
        return !tw.get('id');
      }).get('length') > 0;
    }),
    hasErrors: Ember.computed('model.errors.length', function () {
      return this.get('model.errors.length') > 0;
    }),
    currentWarehouseInError: Ember.computed('transport.exceptionInformation.whId', function () {
      return this.get('transport.exceptionInformation.whId');
    }),
    actions: {
      handleClickWithSpot: function handleClickWithSpot(timeWindowCandidate) {
        var self = this;
        self.send('createAdviceAndTimeWindow', Ember.RSVP.defer(), timeWindowCandidate);
      },
      createAdviceAndTimeWindow: function createAdviceAndTimeWindow(defer, timeWindowCandidate) {
        var _this = this;

        (0, _jquery.default)('#main-modal').data('skip-cancel-action', true);
        var self = this;
        var transport = self.get('store').peekRecord('transport', self.get('transport.id'));
        var advice = transport.get('advice');
        var editedTimeWindow = transport.get('editedTimeWindow');

        if (timeWindowCandidate) {
          editedTimeWindow = transport.get('timeWindows').filter(function (tw) {
            return Number(tw.get('ramp.warehouse.id')) === timeWindowCandidate.ramp.warehouse.id;
          }).get('firstObject');
          editedTimeWindow.set('start', timeWindowCandidate.start);
          editedTimeWindow.set('stop', timeWindowCandidate.stop);
          editedTimeWindow.set('ramp', self.get('store').peekRecord('ramp', timeWindowCandidate.ramp.id));
          console.debug("Wybrana data awizacji: ".concat(timeWindowCandidate.start));
        }

        transport.set('isDuringAddingOfAdvice', true);
        var firstUnconfirmedCheckpoint = transport.get('firstUnconfirmedCheckpoint') || transport.get('firstUnconfirmedCheckpoint.content');
        firstUnconfirmedCheckpoint.set('isDuringAction', true);

        if (advice) {
          transport.set('advice', self.removeWhiteSpacesAndSetUpperCase(advice));
        }

        transport.validate();

        if (self.get('transport.hasErrors')) {
          transport.set('isDuringAddingOfAdvice', false);
          firstUnconfirmedCheckpoint.set('isDuringAction', false);
          defer.resolve();
          return;
        }

        var updateAllFutureTransportsAndWindows = this.updateAllFuturePeriodicTransports(transport);
        transport.set('updateAllFuturePeriodicTransports', updateAllFutureTransportsAndWindows);
        transport.set('updateAllFuturePeriodicWindows', updateAllFutureTransportsAndWindows);
        transport.save().then(function () {
          console.log("Advice and window for transport ".concat(self.get('transport.id'), " has been saved."));
          var adviceContent = transport.get('advice.content');

          if (adviceContent) {
            adviceContent.saveTrackerChanges();
          }

          transport.set('isDuringAddingOfAdvice', false);
          firstUnconfirmedCheckpoint.set('isDuringAction', false);
          self.unloadRedundantTimeWindows(transport);
          transport.reload().then(function () {
            return self.handleTransportReload(transport, self.get('scheduleController'));
          });
          self.send('hideModal');
          self.get('scheduleController').setProperties({
            info: self.get('intl').t('warehouse.adviceHasBeenSavedInfo') + moment(transport.get('earliestTimeWindowStart')).format('HH:mm'),
            warehouseStepToHandle: null
          });
          self.set('model.errors', []);
          defer.resolve();

          if (transport.get('transportType.adviceSeparatedFromWindow')) {
            console.debug('Nie przekierowuje do harmonogramu po dodaniu awizacji');
            return;
          }

          if (editedTimeWindow) {
            var warehouse = editedTimeWindow.get('ramp.warehouse');
            var dateString = moment(editedTimeWindow.get('start')).format('YYYY-MM-DD');
            self.transitionToRoute('warehouses.schedule', warehouse.get('id'), dateString, '1');
          }
        }).catch(function (response) {
          var errors = response.errors;
          var responseMeta = errors[0].meta;
          var exceptionType = responseMeta ? responseMeta.exceptionType : null;

          if (responseMeta) {
            var exceptionInformation = Ember.ObjectProxy.create({
              initialStart: responseMeta.initialStart,
              windowSizeInMinutes: responseMeta.windowSizeInMinutes,
              rampName: responseMeta.rampName,
              whId: responseMeta.warehouseId
            });
            transport.set('exceptionInformation', exceptionInformation);
          }

          _this.setProperties({
            hasErrors: self.EXCEPTIONS_THAT_ALLOWS_SPOTS_SEARCHING.includes(exceptionType)
          });

          self.set('model.errors', errors);
          transport.set('isDuringAddingOfAdvice', false);
          firstUnconfirmedCheckpoint.set('isDuringAction', false);
          defer.resolve();
        });
      },
      cancel: function cancel() {
        console.debug('Creation of advice cancelled.');
        var self = this;
        self.set('model.errors', []);
        self.get('scheduleController').set('warningMessage', null);
        self.get('transport.advice.content').rollback();
        var editedWindow = self.get('scheduleController.editedWindow');

        if (editedWindow && editedWindow.content) {
          // Anulacja podczas edycji wybranego okna spowoduje jedynie przywrócenie okna na poprzednie miejsce
          editedWindow.content.restorePlace();
        } else {
          // Anulacja podczas tworzenia awizacji spowoduje wyczyszczenie wszystkich niezapisanych okien i przeniesienie do magazynu pierwszego takiego okna
          var transport = self.get('transport');
          var unsavedTimeWindows = transport.get('timeWindows').filter(function (tw) {
            return !tw.get('id');
          });
          self.removeUnsavedTimeWindows(transport);
          var stepsWithUnsavedTimeWindow = self.get('transport.stepsWithWarehouse').filter(function (step) {
            return !step.get('timeWindow.id');
          });
          stepsWithUnsavedTimeWindow.forEach(function (step) {
            step.set('timeWindow', null);
          });
          transport.set('isDuringAddingOfAdvice', false);

          if (stepsWithUnsavedTimeWindow.get('length') > 1) {
            // Zapamiętujemy czas pierwszego okna, by móc przenieść użytkownika do odpowiedniego dnia
            var firstStart = unsavedTimeWindows.get('firstObject.start');
            var warehouse = stepsWithUnsavedTimeWindow.get('firstObject.warehouse');
            var scheduleDateString = moment(firstStart).format('YYYY-MM-DD');
            console.debug("Zapisywanie awizacji do transportu ".concat(self.get('transport.id'), " zosta\u0142o przerwane. Przenosimy si\u0119 do magazynu ").concat(warehouse.get('name'), " w dniu ").concat(scheduleDateString, ".."));
            self.transitionToRoute('warehouses.schedule', warehouse.get('id'), scheduleDateString, '1');
          }
        }
      },
      close: function close() {
        this.send('hideModal');
      },
      performSaveAt: function performSaveAt(date) {
        var _this2 = this;

        this.executeAt(date, function () {
          $('.save-advice-button').click();

          _this2.set('actionPlanned', false);
        });
        this.set('actionPlanned', true);
      }
    },
    executeAt: function executeAt(timeString, func) {
      var time = moment(timeString).toDate();
      var currentTime = new Date().getTime();
      setTimeout(func, time - currentTime);
      return true;
    }
  });

  _exports.default = _default;
});