define("apollo/pods/components/popup-dialog/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      closeDialog: function closeDialog() {
        this.sendAction('removePopupDialog');
      }
    },
    show: function () {
      this.$('.popup-dialog').dialog({
        dialogClass: 'popup-dialog-container',
        modal: false,
        backdrop: 'static',
        width: 350,
        position: {
          my: "left top",
          at: "right bottom"
        }
      }).on('dialogclose', function () {
        if (!this.isDestroyed) {
          this.sendAction('close');
        }
      }.bind(this));
    }.on('didInsertElement')
  });

  _exports.default = _default;
});