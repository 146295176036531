define("apollo/pods/assortment-groups/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: service('session-account'),
    access: ['ROLE_CREATE_ASSORTMENT_GROUP'],
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      var warehouse = this.get('store').peekRecord('warehouse', params.warehouse_id);
      return this.store.createRecord("assortmentGroup", {
        enabled: true,
        warehouse: warehouse
      });
    }
  });

  _exports.default = _default;
});