define("apollo/pods/components/companies/apply-form/blockade-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Component.extend({
    store: service(),
    intl: service(),
    sessionAccount: service('session-account'),
    classNames: ['padding-left']
  });

  _exports.default = _default;
});