define("apollo/pods/components/transport/couriers-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    data: null,
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    goodsValueCurrency: ['EUR', 'PLN', 'USD', 'CHF', 'CZK'],
    incoterms: ['DAP', 'FCA', 'CFR', 'CIF', 'CIP', 'CPT', 'DAF', 'DDP', 'DDU', 'DEQ', 'DES', 'EXW', 'FAS', 'FOB'],
    isLoading: null,
    sessionAccount: Ember.inject.service('session-account'),
    init: function init() {
      this._super.apply(this, arguments);

      this.isLoading = true;
      this.data = [];
    },
    getCurrencies: Ember.computed('sessionAccount.currentUser', function () {
      return this.get('store').peekAll('currency').map(function (c) {
        return c.get('symbol');
      });
    }),
    couriersSectionErrors: Ember.computed('transport.errors.[]', function () {
      return [this.get('transport.errors.goodsValue'), this.get('transport.errors.goodsValueCurrency'), this.get('transport.errors.incoterms')];
    }),
    disableEditionOfCouriersFields: Ember.computed('', function () {
      return !this.get('sessionAccount').hasRole('ROLE_CAN_EDIT_COURIERS_SECTION');
    }),
    sortFunction: function sortFunction(a, b) {
      // puste ceny, czyli błedy idą na sam dól
      if (a.price.value === '') {
        return 1;
      }

      if (b.price.value === '') {
        return -1;
      } // pozostałe sortujemy po cenie, od najniższej do najwyższej


      return a.price.value < b.price.value ? -1 : 1;
    },
    actions: {
      fetchData: function fetchData() {
        var _this = this;

        var transportExists = this.get('transport.id') !== null;

        if (!transportExists) {
          return;
        }

        this.get('transport').reload().then(function (transport) {
          var pricings = transport.get('courierPricings');

          if (pricings.length === 0 && !pricings.isFulfilled) {
            var self = _this;
            var retryFetchDataTimeout = 10000;
            setTimeout(function () {
              self.send('fetchData');
            }, retryFetchDataTimeout);
            return;
          }

          _this.set('data', []);

          pricings.forEach(function (pricing) {
            var pricingSuccesses = pricing.get('pricingSuccesses');
            var pricingErrors = pricing.get('pricingErrors');

            if (pricingSuccesses.length > 0) {
              pricingSuccesses.forEach(function (success) {
                _this.data.push({
                  'courier': success.get('courierPricing.courierCompany'),
                  'price': {
                    'value': success.get('courierPricing.courierCompany.name') === 'DHL Express' && success.price ? success.price : success.priceSumNetto != null ? success.priceSumNetto : success.price,
                    'currency': success.priceCurrency
                  },
                  'price_brutto': success.price,
                  'price_fuel': success.priceFuel,
                  'price_sum_netto': success.priceSumNetto,
                  'price_sum_vat': success.priceSumVat,
                  'price_es': success.dhlPriceEs,
                  'price_base': success.priceBase,
                  'price_base_vat': success.priceBaseVat,
                  'price_peak': success.pricePeak,
                  'price_multiple_packaging': success.priceMultiplePackaging,
                  'price_dhl_premium': success.dhlPricePremium,
                  'type_of_service': success.serviceName,
                  'delivery_date': success.dhlDeliveryCapabilities ? moment(success.dhlDeliveryCapabilities).format('YYYY-MM-DD hh:ss') : '',
                  'order_by': success.dhlDeliveryCapabilities ? moment(success.dhlPickupCapabilities).format('YYYY-MM-DD hh:ss') : ''
                });
              });
            } else {
              var msg = _this.get('intl').t('common.error');

              pricingErrors.forEach(function (error) {
                _this.data.push({
                  'courier': error.get('courierPricing.courierCompany'),
                  'price': {
                    'value': '',
                    'currency': ''
                  },
                  'type_of_service': msg + ': ' + error.errorCode + ' | ' + error.errorMessage,
                  'delivery_date': '',
                  'order_by': ''
                });
              });
            }
          });

          _this.data.sort(_this.sortFunction);
        });
        this.set('isLoading', false);
        return this.data;
      }
    }
  });

  _exports.default = _default;
});