define("apollo/pods/components/role-group-statistics-report/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajaxService: Ember.inject.service('ajax-service'),
    intl: Ember.inject.service('intl'),
    selectedReportIds: Ember.computed('roleGroupStatisticsReports', function () {
      return this.get('roleGroupStatisticsReports').map(function (rgsr) {
        return rgsr.get('report.id');
      });
    }),
    actions: {
      save: function save() {
        var _this = this;

        var data = {
          roleGroupId: this.get('roleGroup.id'),
          reportIds: this.get('selectedReportIds')
        };
        this.get('ajaxService').send('/role-group-statistics-reports', 'POST', data, true).then(function () {
          _this.set('errors', []);

          _this.set('successMessage', _this.get('intl').t('common.saveSuccessMessage'));
        }).catch(function (response) {
          _this.set('errors', response.responseJSON.errors);
        });
      },
      toggleRole: function toggleRole(selection) {
        var report = this.get('selectedReportIds').find(function (id) {
          return id === selection.get('id');
        });

        if (!report) {
          this.get('selectedReportIds').addObject(selection.get('id'));
        } else {
          this.get('selectedReportIds').removeObject(selection.get('id'));
        }
      }
    }
  });

  _exports.default = _default;
});