define("apollo/helpers/warehouse-weights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params, nameArgs) {
      var warehouse = nameArgs.warehouse;
      var weights = nameArgs.weights;
      return weights.filter(function (w) {
        return w.get('enabled') && (w.get('id') || w.get('notSynchronized')) && w.get('warehouse.id') === warehouse.get('id');
      });
    }
  });

  _exports.default = _default;
});