define("apollo/helpers/last-weight-task", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params, nameArgs) {
      var transport = nameArgs.transport;
      var weight = nameArgs.weight;
      var lastConfirmedCheckpointWithWeighingIncluded = nameArgs.checkpoint;
      var currentWeight = lastConfirmedCheckpointWithWeighingIncluded ? lastConfirmedCheckpointWithWeighingIncluded.get('currentWeight') : null;
      return currentWeight ? currentWeight.get('id') === weight.get('id') : transport.get('enabledWeights').length === 1;
    }
  });

  _exports.default = _default;
});