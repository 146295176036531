define("apollo/pods/components/role-group-notification-settings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajaxService: Ember.inject.service('ajax-service'),
    intl: Ember.inject.service('intl'),
    actions: {
      save: function save() {
        var _this = this;

        var roleGroupId = this.get("roleGroup.id");
        var notificationIds = this.get("roleGroupNotifications").filter(function (n) {
          return n.checked;
        }).getEach('content.id');
        var data = {
          roleGroup: {
            id: roleGroupId
          },
          notifications: notificationIds.map(function (n) {
            return {
              id: n
            };
          })
        };
        this.set('successMessage', null);
        this.set('errors', []);
        this.get('ajaxService').send("/role-group-notifications", "POST", data, true).then(function () {
          _this.set('successMessage', _this.get('intl').t('common.saveSuccessMessage'));
        }).catch(function (response) {
          _this.set('errors', response.responseJSON["errors"]);
        });
      }
    }
  });

  _exports.default = _default;
});