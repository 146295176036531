define("apollo/pods/components/documents-transport/add-document-button/component", ["exports", "apollo/mixins/attachment-mixin"], function (_exports, _attachmentMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Component.extend(_attachmentMixin.default, {
    sessionAccount: service('session-account'),
    showAddDocumentButton: Ember.computed('transport.transportType', function () {
      if (!this.get('transport')) {
        return false;
      }

      return this.get('transport').actionCanBePerformed ? this.get('transport').actionCanBePerformed('ADD_DOCUMENTS') : this.get('transport.content').actionCanBePerformed('ADD_DOCUMENTS');
    })
  });

  _exports.default = _default;
});