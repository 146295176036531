define("apollo/pods/invitations/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service('session-account'),
    access: Ember.A(['ROLE_INVITATIONS_TAB']),
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model() {
      var self = this;
      var origin = window.location.origin + '#';
      var invitationModel = this.store.createRecord('invitation', {
        enabled: true,
        origin: origin
      }); // Usuwamy typ Operator z listy typów partnerstw do wyboru przy zaproszeniu- A-9610

      var partnershipTypes = this.store.peekAll('partnershipType').filter(function (pt) {
        return pt.name !== 'OPERATOR';
      });
      var principalCompanyId = localStorage.getItem('principalId');
      var roleGroups = this.store.peekRecord('company', principalCompanyId).get('roleGroups');
      var userRoleGroup = this.get('sessionAccount').get('currentUser.roleGroup');
      var roleGroupInvRestriction = this.store.query('role-group-invitation-restriction', {
        roleGroup: userRoleGroup.get('id')
      });
      var roleAccessQueuesAccessibility = this.get('sessionAccount').hasRole('ROLE_ACCESS_QUEUES_ACCESSIBILITY');
      var squares = roleAccessQueuesAccessibility ? this.get('store').findAll('square') : null;
      var queues = roleAccessQueuesAccessibility ? this.get('store').findAll('queue') : null;
      var companyId = this.get('sessionAccount.currentUser.company.id');

      if (!this.get('sessionAccount.isSuperUser')) {
        partnershipTypes = partnershipTypes.filter(function (pt) {
          return pt.name !== 'COURIER';
        });
      }

      return new Promise(function (resolve) {
        self.store.query('company-ramp', {
          companyId: companyId
        }).then(function (companyRamps) {
          var hasCompanyRamps = !!companyRamps.get('length');
          var defaultCompanyRamps = hasCompanyRamps ? self.get('store').query('ramp', {
            rampIds: companyRamps.getEach('ramp.id')
          }) : null;
          var defaultCompanyWarehouses = hasCompanyRamps ? self.get('store').query('warehouse', {
            warehouseIds: companyRamps.getEach('warehouse.id')
          }) : null;
          resolve(Ember.RSVP.hash({
            invitation: invitationModel,
            partnershipTypes: partnershipTypes,
            roleGroups: roleGroups,
            roleGroupInvRestriction: roleGroupInvRestriction,
            squares: squares,
            queues: queues,
            defaultCompanyRamps: defaultCompanyRamps,
            defaultCompanyWarehouses: defaultCompanyWarehouses
          }));
        });
      });
    },
    actions: {
      willTransition: function willTransition() {
        // eslint-disable-next-line ember/no-controller-access-in-routes
        this.get('controller').setProperties({
          errors: null,
          invitationSuccess: null,
          gusHasProblem: false,
          verifiedCompanyName: null
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});