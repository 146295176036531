define("apollo/pods/components/slider-component/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['inline-block', 'padding-left'],
    didRender: function didRender() {
      var self = this;
      var sliderDiv = (0, _jquery.default)("#slider");
      var handle = (0, _jquery.default)("#slider-info");
      sliderDiv.slider({
        min: self.get('min'),
        max: self.get('max'),
        value: self.get('initialValue'),
        create: function create() {
          handle.text("".concat(self.get('initialValue'), "%"));
        },
        change: function change(event, ui) {
          // Przy programistycznym (w kodzie) ustawieniu wartość -- poprzez `jQuery("#slider").slider('option', 'value', x)`
          // -- nie pojawia się `originalEvent`
          var hasBeenSetManually = !!event.originalEvent;
          self.onChangeAction(ui.value, hasBeenSetManually);
          handle.text("".concat(ui.value, "%"));
        }
      });
    },
    actions: {
      reset: function reset() {
        this.onResetAction();
      }
    }
  });

  _exports.default = _default;
});