define("apollo/pods/invitations/create/controller", ["exports", "apollo/mixins/ramps-access-mixin", "apollo/mixins/queues-access-mixin"], function (_exports, _rampsAccessMixin, _queuesAccessMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_rampsAccessMixin.default, _queuesAccessMixin.default, {
    SHOW_ALL_WAREHOUSES: true,
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    gusService: Ember.inject.service('gus-service'),
    errors: Ember.A([]),
    countries: Ember.computed('', function () {
      return this.get('store').peekAll('country');
    }),
    availablePartnershipTypes: Ember.computed('model.{partnershipTypes.[],invitation.currentUserPartnershipTypeId}', function () {
      if (!this.get('model.partnershipTypes')) {
        return [];
      }

      var invitationRestriction = this.get('model.roleGroupInvRestriction');
      var availablePartnershipTypes = invitationRestriction ? invitationRestriction.getEach('invitedRoleGroup.partnershipType.id') : [];
      var currentUserPartnershipTypeId = this.get('model.invitation.currentUserPartnershipTypeId'); // drugi warunek - przewoźnik nie może zaprosić nowego przewoźnika, dostawca nie może zaprosić nowego dostawcy

      return this.get('model.partnershipTypes').filter(function (pt) {
        return availablePartnershipTypes.includes(pt.get('id')) && currentUserPartnershipTypeId !== pt.get('id');
      });
    }),
    availableRoleGroup: Ember.computed('model.invitation.partnershipType', 'properPartnershipTypeId', function () {
      var invitationRestriction = this.get('model.roleGroupInvRestriction');

      if (!invitationRestriction) {
        return [];
      }

      var properPartnershipTypeId = this.get('model.invitation.properPartnershipTypeId');
      return invitationRestriction.filter(function (ir) {
        return ir.get('invitedRoleGroup.partnershipType.id') === properPartnershipTypeId;
      }).map(function (ir) {
        return ir.get('invitedRoleGroup');
      });
    }),
    selectedRoleGroup: Ember.computed('model.invitation.roleGroup', function () {
      if (this.get('availableRoleGroup.length') === 1) {
        this.get('model.invitation').set('roleGroup', this.get('availableRoleGroup.firstObject'));
      }

      return this.get('model.invitation.roleGroup');
    }),
    // eslint-disable-next-line ember/no-observers
    roleGroupsChanged: Ember.observer('roleGroups.@each', 'model.invitation.partnershipType.name', function () {
      if (!this.get('model.invitation')) {
        return;
      }

      if (this.get('roleGroups.length') === 1) {
        this.get('model.invitation').set('roleGroup', this.get('roleGroups.firstObject'));
      } else {
        this.get('model.invitation').set('roleGroup', null);
      }
    }),
    showUserGroupSection: Ember.computed('invitedCompany', function () {
      return !this.get('invitedCompany.isPrincipal') && this.get('invitedCompany.customUserGroupsExists');
    }),
    ownCompanyInvitation: Ember.computed('', function () {
      return this.get('sessionAccount.currentUser.roleGroup.ownCompanyInvitation');
    }),
    // eslint-disable-next-line ember/no-observers
    invitedCompanyObserver: Ember.on('init', Ember.observer('model.invitation.company', 'model.invitation.partnershipType', 'availablePartnershipTypes.@each', function () {
      if (this.get('model.invitation.company.id')) {
        // zaproszenie do istniejącej firmy
        this.set('invitedCompany', this.get('model.invitation.company'));
      } else if (!this.get('model.invitation.partnershipType.id')) {
        // zaproszenie do własnej firmy
        this.set('invitedCompany', this.get('sessionAccount.currentUser.company'));
      } else {
        // zaproszenie nowej firmy
        this.set('invitedCompany', null);
      }
    })),
    // eslint-disable-next-line ember/no-observers
    allowedDomainsObserver: Ember.observer('invitedCompany.id', 'model.invitation', function () {
      if (!this.get('model.invitation')) {
        return;
      }

      var companyId = this.get('invitedCompany.id');

      if (!companyId) {
        this.set('model.invitation.allowedDomains', null);
        return;
      }

      this.set('model.invitation.allowedDomains', this.get('store').query('companyEmailDomain', {
        companyId: companyId
      }));
    }),
    canChooseUserGroup: Ember.computed('invitedCompany', 'sessionAccount.currentUser.userGroup.{id,mainGroup}', function () {
      return this.get('invitedCompany.id') && (!this.get('sessionAccount.currentUser.userGroup') || this.get('sessionAccount.currentUser.userGroup.mainGroup'));
    }),
    userGroupOfCurrentUser: Ember.computed(function () {
      if (this.get('sessionAccount.currentUser.userGroup.id')) {
        return this.get('store').peekRecord('userGroup', this.get('sessionAccount.currentUser.userGroup.id'));
      } else {
        return null;
      }
    }),
    mustInviteToOwnGroup: Ember.computed('userGroupOfCurrentUser', function () {
      return this.get('userGroupOfCurrentUser') && !this.get('userGroupOfCurrentUser.canSeeOtherGroupsTransports') && this.get('sessionAccount.currentUser.company.id') === this.get('invitedCompany.id');
    }),
    showQueuesSection: Ember.computed('selectedRoleGroup', function () {
      var show = false;

      if (this.get('selectedRoleGroup.id')) {
        // Zalogowany użytkownik powinien mieć rolę 'ROLE_ACCESS_QUEUES_ACCESSIBILITY'
        var authority = this.get('sessionAccount.currentUser.roles').find(function (role) {
          return role.get('name') === 'ROLE_ACCESS_QUEUES_ACCESSIBILITY';
        }); // Mając model 'authority' wyciągnięty z bieżącego użytkownika możemy sprawdzić
        // czy wybrana rola też ma przypisane takie 'authority' bez wykonywania zapytania na backend

        if (authority) {
          show = Boolean(this.get('selectedRoleGroup.authoritiesIds').find(function (id) {
            return id === authority.get('id');
          }));
        }

        this.get('proxiedSquares').forEach(function (square) {
          square.set('checked', show);
          square.get('proxiedQueues').forEach(function (r) {
            return r.set('checked', show);
          });
        });
      }

      return show;
    }),
    // eslint-disable-next-line ember/no-observers
    setProxiedRamps: Ember.observer('proxiedRamps.length', 'model', function () {
      Ember.run.once(this, 'checkRamps');
    }),
    checkRamps: function checkRamps() {
      var proxiedRamps = this.get('proxiedRamps');
      var companyRampsIds = this.get('model.defaultCompanyRamps') ? this.get('model.defaultCompanyRamps').getEach('id').uniq() : null;
      proxiedRamps.forEach(function (r) {
        return r.set('checked', companyRampsIds ? companyRampsIds.includes(r.get('id')) : true);
      });
    },
    allRampsChecked: Ember.computed('proxiedRamps.length', 'checkedRamps.length', function () {
      return this.get('proxiedRamps.length') === this.get('checkedRamps.length');
    }),
    actions: {
      toggleAllRamps: function toggleAllRamps() {
        var shouldBeChecked = !this.get('allRampsChecked');
        this.get('proxiedWarehouses').forEach(function (w) {
          w.set('checked', shouldBeChecked);
          w.get('proxiedRamps').forEach(function (r) {
            return r.set('checked', shouldBeChecked);
          });
        });
      },
      selectPartnershipType: function selectPartnershipType(id) {
        var type = id ? this.store.peekRecord('partnershipType', id) : null;
        this.get('model.invitation').set('partnershipType', type);
      },
      setRoleGroup: function setRoleGroup(model, roleGroup) {
        model.set('roleGroup', roleGroup);
      },
      changeCountry: function changeCountry(country) {
        this.set('model.invitation.country', country);
        this.send('verifyNip');
      },
      verifyNip: function verifyNip() {
        var self = this;
        this.set('gusHasProblem', false);
        var ibanSymbol = this.get('model.invitation.country.ibanSymbol');
        var nip = this.get('model.invitation.taxIdNumber');
        this.get('gusService').verifyNip(ibanSymbol, nip).then(function (response) {
          var existingCompanyName = response ? response.existingCompanyName : null;
          var gusCompanyName = response ? response.gusCompanyName : null;
          console.log("Odpowied\u017A z GUS: ".concat(response));
          self.set('verifiedCompanyName', existingCompanyName != null ? existingCompanyName : gusCompanyName);
        }).catch(function (error) {
          console.error("Problem z po\u0142\u0105czeniem z GUS: ".concat(error));
          self.set('verifiedCompanyName', null);
          self.set('gusHasProblem', true);
        });
      },
      sendAnInvitation: function sendAnInvitation() {
        var self = this;
        this.set('invitationSuccess', null);

        if (this.get('mustInviteToOwnGroup')) {
          console.debug("U\u017Cytkownik ma przypisan\u0105 grup\u0119 i do niej zaprasza - nie mo\u017Ce jej zmieni\u0107, wi\u0119c przypisujemy j\u0105 r\u0119cznie na ".concat(this.get('userGroupOfCurrentUser.name'), "."));
          this.set('model.invitation.userGroup', this.get('userGroupOfCurrentUser'));
        }

        var invitation = this.get('model.invitation');
        var checkedRamps = this.get('checkedRamps');
        invitation.get('ramps').clear();
        checkedRamps.forEach(function (ramp) {
          invitation.get('ramps').pushObject(ramp);
        });
        var checkedQueues = this.get('checkedQueues');
        invitation.get('queues').clear();
        checkedQueues.forEach(function (queue) {
          invitation.get('queues').pushObject(queue);
        });

        if (!invitation.validate()) {
          return;
        }

        invitation.save().then(function (savedInvitation) {
          var msg = savedInvitation.get('customMessage') || '';
          self.set('invitationSuccess', msg.split('|'));

          if (savedInvitation.get('company.id')) {
            savedInvitation.get('company').get('partnerships').then(function (partnerships) {
              partnerships.forEach(function (p) {
                p.reload();
              });
            });
          }
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      },
      cancel: function cancel() {
        var companyId = this.get('model.invitation').get('company.id');
        this.get('model.invitation').rollbackAttributes();
        this.get('model.invitation').unloadRecord();

        if (companyId) {
          if (this.get('sessionAccount').hasRole('ROLE_COMPANIES_TAB')) {
            this.transitionToRoute('companies');
          } else {
            this.transitionToRoute('companies.show', companyId);
          }
        } else {
          this.transitionToRoute('invitations');
        }
      }
    }
  });

  _exports.default = _default;
});