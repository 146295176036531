define("apollo/pods/time-windows-summary-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N1T8IJi7",
    "block": "{\"symbols\":[\"tw\"],\"statements\":[[4,\"custom-modal\",null,[[\"title\",\"showCancelButton\",\"classNames\"],[[27,\"concat\",[\"Transport #\",[23,[\"model\",\"transportToHandle\",\"identifier\"]]],null],true,\"temporary-account-modal\"]],{\"statements\":[[0,\"        \"],[7,\"div\"],[9],[0,\"\\n            \"],[7,\"h5\"],[9],[0,\"\\n                \"],[1,[27,\"t\",[\"transport.selectedWindows\"],[[\"transportIdentifier\"],[[23,[\"model\",\"transportToHandle\",\"identifier\"]]]]],false],[0,\":\\n            \"],[10],[0,\"\\n\\n            \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"transportToHandle\",\"timeWindows\"]]],null,{\"statements\":[[0,\"                    \"],[7,\"li\"],[9],[0,\"\\n                        \"],[1,[22,1,[\"ramp\",\"warehouse\",\"name\"]],false],[0,\", \"],[1,[22,1,[\"ramp\",\"name\"]],false],[0,\", \"],[1,[22,1,[\"formattedTime\"]],false],[0,\"\\n                    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"modal-footer\"],[9],[0,\"\\n            \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[11,\"type\",\"button\"],[9],[0,\"OK\"],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/time-windows-summary-modal/template.hbs"
    }
  });

  _exports.default = _default;
});