define("apollo/pods/companies/modals/edit-address/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Controller.extend({
    store: service(),
    sessionAccount: service('session-account'),
    showVoivodeshipField: Ember.computed('model.country.ibanSymbol', function () {
      return this.get('sessionAccount').getSettingValue('SHOW_COMPANY_VOIVODESHIP_FIELD') === 'true' && this.get('model.country.ibanSymbol') === 'PL';
    }),
    countries: Ember.computed('', function () {
      return this.get('store').peekAll('country').sortBy('ibanSymbol');
    }),
    _handleSuccessfullSave: function _handleSuccessfullSave() {
      this.set('errors', []);
      this.send('refreshModel');
      this.send('hideModal');
      this.setProperties({
        errors: [],
        model: null
      });
    },
    actions: {
      update: function update() {
        var self = this;
        var address = this.get('model');

        if (address.get('id')) {
          address.copy(true).then(function (newAddress) {
            newAddress.set('idToDisable', address.get('id'));
            newAddress.save().then(function () {
              self._handleSuccessfullSave();
            }).catch(function (response) {
              self.set('errors', response.errors);
            });
          });
        } else {
          address.save().then(function () {
            self._handleSuccessfullSave();
          }).catch(function (response) {
            self.set('errors', response.errors);
          });
        }
      },
      cancel: function cancel() {
        console.debug('Zamykamy okienko edycji adresu...');

        if (!this.get('model.id')) {
          this.get('model').unloadRecord();
        } else if (this.get('model')) {
          this.get('model').rollbackAttributes();
        }

        this.setProperties({
          errors: [],
          model: null
        });
      }
    }
  });

  _exports.default = _default;
});