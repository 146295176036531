define("apollo/services/transport-relations/relations-combiner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajaxService: Ember.inject.service('ajax-service'),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    timeWindowService: Ember.inject.service('time-window-service'),
    isOverweight: function isOverweight(relation) {
      var maxWeight = relation.relation.maxTotalWeight;

      if (!maxWeight) {
        return false;
      }

      var totalWeight = 0;
      var masters = Ember.A(relation.masters.slice());
      var slaves = Ember.A(relation.slaves.slice());
      var allTransports = masters.concat(slaves);
      allTransports.forEach(function (transport) {
        transport.get('unloadSteps').forEach(function (step) {
          if (!step || !step.get('weight')) {
            return;
          }

          totalWeight += step.get('weight');
        });
      });
      return totalWeight > maxWeight;
    },
    _transportWithHighestNumOfWindows: function _transportWithHighestNumOfWindows(relation) {
      // eslint-disable-next-line no-useless-call
      return [].concat.apply([], [relation.masters, relation.slaves]).sortBy('stepsWithWarehouse.length').get('lastObject');
    },
    _handleTransportCombining: function _handleTransportCombining(selectedRelation) {
      var self = this;
      var slaveTransports = selectedRelation.slaves;
      var masterTransports = selectedRelation.masters;
      var transports = slaveTransports.concat(masterTransports);
      var relationType = selectedRelation.relation;
      var payload = {
        transportRelation: relationType.get('id'),
        relatedTransports: transports.map(function (transport) {
          return transport.get('id');
        })
      };
      var slaveTransportType = slaveTransports.get('firstObject.transportType');
      var masterTransportType = masterTransports.get('firstObject.transportType');
      this.get('ajaxService').send('/transport-transport-relations/transport-combining', 'POST', payload, true).then(function (response) {
        self.get('store').pushPayload(response.transportTransportRelations);

        self._refreshAfterTransportCombining(masterTransportType, slaveTransportType);
      }).catch(function (response) {
        console.debug(response);
        console.error(response.errors);
      });
    },
    _handleHasToWaitFor: function _handleHasToWaitFor(relation) {
      var _this = this;

      var masterTransports = relation.masters;
      var masterTransportType = masterTransports.get('firstObject.transportType');
      var slaveTransportType = relation.slaves.get('firstObject.transportType');
      var relationIdentifier = masterTransports.get('firstObject.internalIndex');
      masterTransports.forEach(function (master) {
        if (!master) {
          return;
        }

        var otherTransports = relation.slaves;
        var relationType = relation.relation;

        if (master && otherTransports) {
          otherTransports.forEach(function (relatedTransport) {
            var transportTransportRelation = _this.get('store').createRecord('transportTransportRelation', {
              transportRelation: relationType,
              masterTransport: master,
              relatedTransport: relatedTransport,
              alias: relationType.get('alias'),
              relationIdentifier: relationIdentifier
            });

            transportTransportRelation.save().then(function () {
              relatedTransport.reload();
            }).catch(function (response) {
              console.debug(response);
              console.error(response.errors);
            });
          });
          master.reload();
        }
      });

      this._refreshAfterTransportCombining(masterTransportType, slaveTransportType);
    },
    _refreshAfterTransportCombining: function _refreshAfterTransportCombining(masterTransportType, slaveTransportType) {
      masterTransportType.set('transportsTabVisited', false);
      masterTransportType.set('forcedPollRequestTime', new Date().getTime());
      slaveTransportType.set('transportsTabVisited', false);
      slaveTransportType.set('forcedPollRequestTime', new Date().getTime());
    },
    combine: function combine(relation) {
      try {
        if (this.isOverweight(relation)) {
          throw new Error(this.get('intl').t('transport.relations.overweight'));
        }

        if (relation.relation.get('alias') === 'TRANSPORT_COMBINING') {
          this._handleTransportCombining(relation);
        }

        if (relation.relation.get('alias') === 'HAS_TO_WAIT_FOR') {
          this._handleHasToWaitFor(relation);
        }

        if (relation.relation.get('autoAdviceWhenCombined')) {
          if (this._relationHasAdviceAlready(relation)) {
            console.debug("Relacja :: ".concat(relation, " :: posiada ju\u017C awizacje, nie dodajemy kolejnej awizacji"));
            return;
          }

          this._handleCreatingTimeWindow(relation);
        }
      } catch (e) {
        console.error('Błąd podczas tworzenia relacji :: ' + e);
      }
    },
    decombineAllRelatedTransports: function decombineAllRelatedTransports(transport) {
      var relation = {
        masters: [transport],
        slaves: []
      };
      this.decombine(relation);
    },
    decombine: function decombine(relation) {
      var transportId = this._transportWithHighestNumOfWindows(relation).get('id');

      var url = "/transport-transport-relations/delete-all-combined-relations/".concat(transportId);
      this.ajaxService.send(url, 'DELETE').then(function (response) {
        if (response.code === '204') {
          return;
        }

        console.log("Usuneli\u015Bmy wszystkie relacje combining transportu :: ".concat(transportId));
        console.debug(response);
      }).catch(function (response) {
        return console.error('Błąd podczas próby rozłączania transportów. ' + response.responseJSON.errors);
      });
    },
    _handleCreatingTimeWindow: function _handleCreatingTimeWindow(relation) {
      var _this2 = this;

      this._transportWithHighestNumOfWindows(relation).reload().then(function (transport) {
        var stepsWithWarehouse = transport.get('stepsWithWarehouse');
        var step = stepsWithWarehouse && stepsWithWarehouse.get('length') > 0 ? stepsWithWarehouse.get('firstObject') : transport.get('steps.firstObject');

        _this2._goToWarehouse(step);
      });
    },
    _goToWarehouse: function _goToWarehouse(step) {
      var _this3 = this;

      var adviceButton = $('.advice-button-' + step.get('transport.id'));
      adviceButton.button('loading');

      if (!step.get('isInWarehouse')) {
        this.openCreateAdviceModal(step.get('transport'));
        adviceButton.button('reset');
        return;
      }

      var warehouseId = step.get('warehouse.id');
      var transport = this.get('store').peekRecord('transport', step.get('transport.id'));
      this.timeWindowService.getFirstPossibleWindowStart(step, transport).then(function (response) {
        var firstPossibleStart = response.firstPossibleStart;

        if (!firstPossibleStart) {
          alert(response.error);
          adviceButton.button('reset');
          return;
        }

        var dateString = moment(firstPossibleStart).format('YYYY-MM-DD');

        _this3.router.transitionTo('warehouses.combined-schedule', warehouseId, dateString, '1', step.get('id'));

        adviceButton.button('reset');
      });
    },
    _relationHasAdviceAlready: function _relationHasAdviceAlready(relation) {
      return relation.masters.concat(relation.slaves).slice().some(function (t) {
        return t.get('hasAdvice');
      });
    }
  });

  _exports.default = _default;
});