define("apollo/pods/components/transport-modal-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    showAddressData: Ember.computed('asPlainTemplate', function () {
      return this.get('asPlainTemplate') && this.get('sessionAccount').getSettingValue('SHOW_PRINCIPAL_ADDRESS_IN_PRINT_VIEW') === 'true';
    }),
    otherRelatedTransports: Ember.computed('transport.allCombinedTransports', function () {
      return this.get('transport.allCombinedTransports').slice().removeObject(this.get('transport'));
    }),
    actions: {
      showModal: function showModal(transport) {
        this.set('action', 'showModal');
        this.sendAction('action', transport);
      }
    }
  });

  _exports.default = _default;
});