define("apollo/translations/pl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "CARRIER": {
      "label": "Przewoźnik",
      "panel": {
        "label": "Karta przewoźnika"
      }
    },
    "CLIENT": {
      "panel": {
        "label": "Karta klienta"
      }
    },
    "COMPANY": {
      "panel": {
        "label": "Karta firmy"
      }
    },
    "OPERATOR": {
      "label": "Operator",
      "panel": {
        "label": "Karta operatora"
      }
    },
    "SERVICE": {
      "panel": {
        "label": "Karta serwisu"
      }
    },
    "SERVICEMAN": {
      "panel": {
        "label": "Karta serwisanta"
      }
    },
    "SUPPLIER": {
      "label": "Dostawca",
      "panel": {
        "label": "Karta kontrahenta"
      }
    },
    "address": {
      "addNewAddress": "Dodaj adres",
      "autoCompletePlaceholder": "Zacznij pisać, by wyszukać miasto, ulicę, itp",
      "chooseCompanyFirst": "Proszę najpierw wybrać firmę",
      "city": "Miasto",
      "country": "Kraj",
      "deleteAddress": "Czy jesteś pewien, że chcesz usunąć ten adres?",
      "errors": {},
      "label": "Adres",
      "newAddress": "Nowy adres",
      "postal": "Kod pocztowy",
      "province": "Dzielnica",
      "recipient": "Adresat",
      "removeAddress": "Usuń adres",
      "street": "Ulica",
      "voivodeship": "Województwo"
    },
    "administration": {
      "newPrincipal": {
        "aliasHint": "Alias powinien składać się z jednego słowa (najlepiej nazwy klienta), małymi literami (np.: logintegra)",
        "baseData": "Podstawowe dane firmy",
        "companyAddress": "Siedziba firmy",
        "create": "Stwórz nowego pryncypała",
        "creation": "Tworzenie nowego pryncypała",
        "successMsg": "Poprawnie zapisano nowa firmę pryncypała. Możesz teraz zalogować się na konto: {username}",
        "userData": "Dane użytkownika (SUPER_ADMIN)"
      }
    },
    "advice": {
      "add": "Dodaj awizację",
      "carRegistrationNumber": "Numer rejestracyjny ciągnika",
      "carRegistrationNumber.placeholder": "np. GDA12345",
      "carRegistrationNumberSimple": "Numer rejestracyjny",
      "creator": "Twórca awizacji",
      "dateCreated": "Data utworzenia",
      "documentNumber": "Numer dokumentu kierowcy",
      "documentNumber.placeholder": "np. 78646003",
      "documentType": {
        "ID_CARD": "Dowód os.",
        "PASSPORT": "Paszport"
      },
      "driverId": "Numer id kierowcy",
      "driverLanguage": "Język kierowcy",
      "driverMobilePhoneNumber": "Numer telefonu kierowcy",
      "driverMobilePhonePrefix": "Prefix numeru tel. kierowcy",
      "driverName": "Imię i nazwisko kierowcy",
      "driverName.placeholder": "np. Jan Kowalski",
      "errors": {
        "carRegistrationNumber": {
          "regex": "Numer rejestracyjny musi mieć między 4 a 50 znaków oraz może zawierać jedynie litery, cyfry oraz znaki . , ; / \\\\ & _"
        },
        "driverMobilePhoneNumber": {
          "regex": "Numer telefonu w Polsce musi się składać z dziewięciu cyfr"
        },
        "rodoAccepted": {
          "notSelected": "Dane muszą być przetwarzane legalnie"
        },
        "saveAsResource": {
          "info": "Żeby zapisać kierowcę na liście zasobów, konieczne jest wypełnienie pól obowiązkowych w sekcji awizacji."
        },
        "termsOfConditionsAccepted": {
          "notSelected": "Akceptacja warunków jest konieczna, by dokonać awizacji"
        },
        "trailerRegistrationNumber": {
          "regex": "Numer rejestracyjny naczepy/przyczepy mieć między 4 a 50 znaków oraz może zawierać jedynie litery, cyfry oraz znaki . , ; / \\\\ & _"
        }
      },
      "label": "Awizacja",
      "pasteFromExcel": "Wklej dane awizacji",
      "rodo": "Dane wprowadzane do formularza mogą być legalnie przetwarzane przez Logintegra sp. z o.o.",
      "saveAsResource": "Zapisz dane kierowcy i pojazdu na liście zasobów",
      "termsOfConditionsAccepted": "Akceptuję",
      "trailerRegistrationNumber": "Numer rejestracyjny naczepy/przyczepy",
      "trailerRegistrationNumber.placeholder": "np. GDA6789",
      "transport": "Transport"
    },
    "afterRegistration": {
      "addWarehouseButton": "Dodaj magazyn",
      "info": "Załóż własny magazyn i zaproś kontrahentów, by planować transporty i awizacje.",
      "invitationButton": "Lista zaproszeń",
      "label": "Witamy!",
      "title": "Jesteś na dobrej drodze do pożegnania kolejek!"
    },
    "algorithmSettings": {
      "deleteSetting": "Czy na pewno chcesz usunąć to ustawienie?",
      "deletionSuccessMessage": "Poprawnie usunęliśmy ustawienia algorytmu dla {n} palet.",
      "label": "Ustawienia algorytmu obliczania wielkości okna czasowego",
      "loadTimeInMinutes": "Czas załadunku w minutach",
      "numberOfPallets": "Ilość palet (górna granica)",
      "saveSuccessMessage": "Poprawnie zapisaliśmy ustawienia algorytmu."
    },
    "announcement": {
      "errors": {
        "companies": {
          "chooseAtLeastOneCompany": "Wybierz co najmniej jedną firmę"
        },
        "roleGroups": {
          "chooseAtLeastOneRoleGroup": "Wybierz co najmniej jedną rolę"
        }
      }
    },
    "announcements": {
      "add": "Dodaj ogłoszenie",
      "announcementInEnglish": "Ogłoszenie w języku angielskim",
      "companies": "Firmy, którym pojawi się komunikat:",
      "content": "Treść komunikatu",
      "contentEn": "Treść komunikatu (EN)",
      "dateCreated": "Data utworzenia",
      "dateFrom": "Data ważności od",
      "dateTo": "Data ważności do",
      "delete": "Jesteś pewien, że chcesz usunąć to ogłoszenie?",
      "edit": "Edytuj ogłoszenie",
      "expirationDates": "Daty wyświetlania komunikatu",
      "label": "Ogłoszenia",
      "lastEditor": "Autor ostatniej edycji",
      "lastUpdated": "Ostatnia edycja",
      "manage": "Zarządzanie ogłoszeniami",
      "noActiveAnnouncements": "Aktualnie na tablicy nie ma żadnych ogłoszeń.",
      "noAnnouncements": "Brak ogłoszeń",
      "range": "Wybór użytkowników",
      "ranges": {
        "COMPANY": "Według przypisania do firm",
        "ROLE_GROUP": "Według ról użytkowników"
      },
      "roleGroups": "Użytkownicy z odpowiednimi rolami, którym pojawi się komunikat:",
      "subject": "Temat komunikatu",
      "subjectEn": "Temat komunikatu (EN)",
      "type": "Ogłoszenie administracyjne"
    },
    "anti-corruption-section": {
      "errors": {
        "signedFiles": "Aby wysłać prośbę o weryfikację wcześniej musisz dodać podpisane oświadczenie o przeciwdziałaniu korupcji"
      },
      "internalNotes": "Uwagi",
      "patternFileDescription": "Wzór formularza do uzupełnienia",
      "signedFile": "Dodaj podpisany plik",
      "statementFileDescription": "Oświadczenie podpisane przez {company} do pobrania"
    },
    "apiIntegrationLogs": {
      "event": {
        "CHECKPOINT_CONFIRMED": "Zatwierdzono status",
        "TRANSPORT_CREATED": "Utworzenie transportu przez publiczne API",
        "TRANSPORT_UPDATED": "Edycja transportu przez publiczne API",
        "TRANSPORT_UPDATED_MANUAL": "Edycja transportu"
      }
    },
    "appliesSettings": {
      "add": "Dodaj ustawienia zgłoszeń",
      "description": {
        "createAntiCorruptionSection": "Dodaj sekcję o przeciwdziałaniu korupcji dla nowych zgłoszeń",
        "showPatternToDownload": "Pokaż wzory oświadczeń do pobrania",
        "showStatementToDownload": "Pokaż podpisane oświadczenia pryncypała do pobrania"
      },
      "label": "Ustawienia zgłoszeń",
      "noSettings": "Nie dodano jeszcze ustawień zgłoszeń"
    },
    "artrSettings": {
      "add": "Dodaj ustawienia ARTR",
      "fields": {
        "entryGateCheckpointAlias": "Alias statusu wjazdu (szlaban)",
        "entryWhiteListCheckpointAlias": "Alias etapu, który powinien wysyłać numer rej. na białą listę - wjazd",
        "exitGateCheckpointAlias": "Alias statusu wyjazdu (szlaban)",
        "exitWhiteListCheckpointAlias": "Alias etapu, który powinien wysyłać numer rej. na białą listę - wyjazd",
        "expirationTimeInHours": "Czas ważności wpisu na białą listę (w godzinach)"
      },
      "label": "Ustawienia ARTR",
      "noSettings": "Nie dodano jeszcze ustawień ARTR"
    },
    "assortmentGroup": {
      "add": "Dodaj grupę asortymentową",
      "adviceFromTime": "Awizacja od godziny",
      "adviceToTime": "Awizacja do godziny",
      "confirmDeletion": "Na pewno chcesz usunąć grupę asortymentową?",
      "confirmEdition": "Uwaga. Zostały zmienione godziny dla awizacji grup asortymentowych. Czy na pewno chcesz kontynuować?",
      "errors": {
        "adviceFromTime": {
          "invalidAssortmentGroupAdviceFromTime": "Awizacja musi mieścić sie w godzinach pracy magazynu"
        },
        "adviceToTime": {
          "invalidAssortmentGroupAdviceToTime": "Awizacja musi mieścić sie w godzinach pracy magazynu"
        },
        "nameEn": {
          "minLength": "Pole musi zawierać co najmniej 3 znaki"
        },
        "namePl": {
          "minLength": "Pole musi zawierać co najmniej 3 znaki"
        }
      },
      "label": "Grupa asortymentowa",
      "listLabel": "Grupy asortymentowe",
      "transportTypes": "Typy transportu, dla których ma być dostępna grupa asortymentowa"
    },
    "auction": {
      "AUCTION": {
        "actualFinishTime": "Ostateczny czas zakończenia aukcji:",
        "buttonLabel": "+ Aukcja",
        "creator": "Twórca aukcji",
        "currency": "Waluta aukcji",
        "dateCreated": "Utworzenie aukcji",
        "endedWithoutOffers": "Aukcja zakończyła się bez ofert",
        "finish": "Zakończ aukcję",
        "inProgress": "Koniec aukcji",
        "initialFinishTime": "Początkowy czas zakończenia aukcji:",
        "label": "Aukcja",
        "noPreviousAuctions": "Brak poprzednich aukcji",
        "participants": "Firmy biorące udział w aukcji",
        "plannedFinishTime": "Termin zakończenia aukcji",
        "previousAuctions": "Poprzednie aukcje",
        "reject": {
          "alert": "Jesteś pewien, że chcesz odrzucić tą aukcję?",
          "fail": {
            "CannotRejectOffersExist": "Nie możesz odrzucić aukcji - Twoja firma już złożyła do niej ofertę."
          },
          "label": "Nie jestem zainteresowany",
          "success": "Aukcja została odrzucona"
        },
        "save": "Wystaw aukcję",
        "savingInProgress": "Zapisujemy aukcję..",
        "selectParticipants": "Wybierz uczestników aukcji"
      },
      "INQUIRY": {
        "actualFinishTime": "Ostateczny czas zakończenia zapytania ofertowego:",
        "buttonLabel": "Zapytanie",
        "creator": "Twórca zapytania ofertowego",
        "currency": "Waluta zapytania ofertowego",
        "dateCreated": "Utworzenie zapytania ofertowego",
        "endedWithoutOffers": "Zapytanie ofertowe zakończyło się bez ofert",
        "finish": "Zakończ zapytanie ofertowe",
        "inProgress": "Termin ważności zapytania",
        "initialFinishTime": "Początkowy czas zakończenia zapytania ofertowego:",
        "label": "Zapytanie",
        "noPreviousAuctions": "Brak poprzednich zapytań ofertowych",
        "participants": "Firmy biorące udział w zapytaniu",
        "plannedFinishTime": "Termin zakończenia zapytania",
        "previousAuctions": "Poprzednie zapytania ofertowe",
        "reject": {
          "alert": "Jesteś pewien, że chcesz odrzucić to zapytanie ofertowe?",
          "fail": {
            "CannotRejectOffersExist": "Nie możesz odrzucić zapytania ofertowego - Twoja firma już złożyła ofertę."
          },
          "label": "Nie jestem zainteresowany",
          "success": "Zapytanie ofertowe zostało odrzucone"
        },
        "save": "Stwórz zapytanie ofertowe",
        "savingInProgress": "Zapisujemy zapytanie..",
        "selectParticipants": "Wybierz uczestników zapytania"
      },
      "VALUATION": {
        "actualFinishTime": "Ostateczny czas zakończenia składania ofert:",
        "buttonLabel": "+ Wycena",
        "creator": "Twórca wyceny",
        "currency": "Waluta ofertacji",
        "dateCreated": "Utworzenie wyceny",
        "endedWithoutOffers": "Wycena zakończyła się bez ofert",
        "finish": "Zakończ wycenę",
        "inProgress": "Koniec wyceny",
        "initialFinishTime": "Początkowy czas zakończenia składania ofert:",
        "label": "Wycena",
        "noPreviousAuctions": "Brak poprzednich wycen",
        "participants": "Firmy biorące udział w ofertacji",
        "plannedFinishTime": "Termin zakończenia składania ofert",
        "previousAuctions": "Poprzednie wyceny",
        "reject": {
          "alert": "Jesteś pewien, że chcesz odrzucić tą wycenę?",
          "fail": {
            "CannotRejectOffersExist": "Nie możesz odrzucić wyceny - Twoja firma już złożyła do niej ofertę."
          },
          "label": "Nie jestem zainteresowany",
          "success": "Wycena została odrzucona"
        },
        "save": "Wystaw wycenę",
        "savingInProgress": "Zapisujemy wycenę..",
        "selectParticipants": "Wybierz uczestników wyceny"
      },
      "bid": "Ofertuj",
      "blockedCarriers": "Zablokowane firmy przewozowe",
      "buttonLabel": "+ Aukcja",
      "buyNow": "Przyjmij",
      "buyNowConfirmation": "Na pewno chcesz przyjąć zlecenie {transportIdentifier} za stawkę {minPrice} {currencySymbol}?",
      "buyNowHeader": "Natychmiast przyjmij ofertę i otrzymaj zlecenie za stawkę minimalną",
      "buyNowLabel": "Stawka minimalna",
      "create": "Utwórz aukcję",
      "createTitle": "Tworzenie aukcji do transportu {transportIdentifier}",
      "currentOffer": "Aktualna oferta",
      "details": "Historia",
      "errors": {
        "companies": {
          "empty": "Proszę wybrać przynajmniej jedną firmę"
        },
        "maxPrice": {
          "invalidNumber": "Cena maksymalna musi być liczbą większą od zera.",
          "maxPriceLowerThanMinPrice": "Cena maksymalna nie może być mniejsza niż cena minimalna."
        },
        "minPrice": {
          "invalidNumber": "Cena minimalna musi być liczbą większą bądź równą zeru."
        },
        "plannedFinishTime": {
          "isInPast": "Termin zakończenia musi się znajdować w przyszłości."
        }
      },
      "finishTimeChanged": "Godz. zakończenia została zmieniona z {date}.",
      "label": "Aukcja",
      "lastUpdated": "Ostatnio aktualizowana",
      "maxPrice": "Cena max",
      "minPrice": "Cena min",
      "noOffers": "Brak poprawnych ofert",
      "plannedFinishTimeAbbr": "plan.",
      "priceRange": "Zakres cenowy",
      "selectGroup": "Wybierz grupę firm transportowych",
      "transport": "Transportu"
    },
    "auth": {
      "accountActivated": "Konto zostało aktywowane",
      "accountExpired": "Z powodu dłuższej nieaktywności konto mogło zostać zawieszone. Na adres mailowy powiązany z kontem został wysłany link aktywujący konto. W przypadku problemów z zalogowaniem napisz do nas na adres {supportEmail} lub zadzwoń: {supportPhone}.",
      "accountLocked": "Z powodu wielokrotnych prób logowania konto zostało zablokowane na {arg} min. Spróbuj ponownie za {arg} min lub zresetuj hasło.",
      "accountLockedByAdmin": "Twoje konto zostało zablokowane przez administratora systemu",
      "activateAccount": "Aktywuj konto",
      "alreadyRegistered": "Masz już konto?",
      "changePassword": {
        "false": "Zmień hasło",
        "true": "Nie zmieniaj hasła"
      },
      "confirmPassword": "Potwierdź hasło",
      "description": "Platforma logistyczna",
      "email": "Adres e-mail",
      "emailPlaceholder": "Wpisz adres e-mail",
      "fail": "Logowanie nie powiodło się",
      "footer": "Przygotowany przez",
      "impersonate": "Zaloguj się na konto użytkownika",
      "invalidLoginOrPassword": "Login lub hasło są niepoprawne.",
      "login": "Login",
      "loginPlaceholder": "Wpisz email",
      "loginWithActiveDirectory": "Zaloguj z użyciem Active Directory",
      "logout": "Wyloguj",
      "logoutByConcurrentSession": "Zostałeś wylogowany, ponieważ używasz tego konta na innym urządzeniu.",
      "logoutByService": "Zostałeś wylogowany z powodu prac administracyjnych lub przez dłuższy czas nie korzystałeś z systemu.",
      "newPassword": "Nowe hasło",
      "noLoginAndPassword": "Proszę podać login i hasło.",
      "notRegistered": "Nie masz konta?",
      "oldPassword": "Dotychczasowe hasło",
      "or": "Lub",
      "password": "Hasło",
      "passwordChanged": "Hasło dla użytkownika {username} zostało zmienione.",
      "passwordPlaceholder": "Wpisz hasło",
      "passwordRemindedMessage": "Jeżeli podany adres {email} istnieje, zostanie wysłana wiadomość.",
      "remind": "Przypomnij",
      "remindEmail": "Podaj adres e-mail powiązany z Twoim kontem, na który wyślemy link do zmiany hasła",
      "remindFail": "Operacja nie powiodła się",
      "remindHeader": "Resetowanie hasła",
      "remindLink": "Zapomniałeś hasła?",
      "remindingInProgress": "Wysyłamy e-mail",
      "repeatNewPassword": "Powtórzone nowe hasło",
      "repeatPassword": "Powtórz hasło",
      "serverError": "Serwer nie odpowiada. Spróbuj ponownie za kilka minut lub skontaktuj się z info@logintegra.com.",
      "signin": "Zaloguj",
      "username": "Nazwa użytkownika"
    },
    "authorityGroup": {
      "ADVICE": "Awizacja",
      "ANNOUNCEMENT": "Ogłoszenia",
      "API": "API",
      "APPLIES": "Zgłoszenia przewoźników",
      "ASSORTMENT_GROUP": "Grupy asortymentowe",
      "AUCTION": "Przekazywanie zlecenia, aukcje",
      "COMPANY": "Firmy",
      "COURIER": "Kurierzy",
      "CUSTOM_FIELD": "Pola niestandardowe",
      "INDEX": "Indeksy",
      "MAIN_TRANSPORT_DATA": "Pola główne formularza",
      "MODE_OF_TRANSPORTATION": "Weryfikacja środków transportu",
      "NOTES_AND_DOCUMENTS": "Notatki, dokumenty",
      "OTHERS": "Inne",
      "PACKAGE": "Paczki",
      "PRICE_LIST": "Cenniki",
      "PRODUCT": "Produkty",
      "QUEUE": "Kolejki",
      "SETTINGS": "Ustawienia",
      "TASKS": "Statusy",
      "TIME_WINDOW": "Okna czasowe",
      "TRANSPORT": "Transport, zlecenie",
      "USER": "Użytkownicy",
      "WAREHOUSE": "Magazyny",
      "WEIGHT": "Ważenie"
    },
    "blockades": {
      "invalidSection": "Odrzucona lub nieważna sekcja",
      "noActiveBlockades": "Brak aktywnych blokad"
    },
    "checkpoint": {
      "actualExecutionDate": "Rzeczywista data potwierdzenia",
      "actualExecutionDateLabel": "Podanie rzeczywistej daty potwierdzenia statusu",
      "blockLabel": "Zablokuj",
      "blockText": "Możliwość potwierdzenia etapu została zablokowana przez {name}",
      "blockTextShort": "Zablokowano przez {name}",
      "confirmator": "Zatwierdzający",
      "errors": {
        "blockadeReason": {
          "length": "Maksymalna ilość znaków to 255"
        },
        "executionDate": {
          "isInPast": "Podana data musi być z przeszłości oraz musi być późniejsza niż data potwierdzenia poprzedniego statusu."
        }
      },
      "executionDate": "Data potwierdzenia statusu",
      "noInternetHint": "Brak Internetu - status zostanie zatwierdzony po nawiązaniu połączenia",
      "reject": "Odrzuć",
      "rejectConfirm": "Czy na pewno chcesz odrzucić ten status? Odrzucenie statusu oznacza odesłanie kierowcy oraz wysłanie powiadomień mailowych do klienta i przewoźnika.",
      "rejectionDate": "Ostatnia data odrzucenia: ",
      "unblockLabel": "Odblokuj"
    },
    "checkpoints": {
      "confirm": "Potwierdź",
      "confirmAfterTime": "Status potwierdzony z opóźnieniem",
      "confirmBeforeTime": "Status potwierdzony przed czasem",
      "confirmStatus": "Potwierdź status",
      "confirmedMessage": "potw. {date}",
      "confirmedMessageBy": "przez {person}",
      "empty": "Brak statusów",
      "plan": "plan.",
      "processFinished": "Proces jest zakończony!",
      "revokeConfirm": "Na pewno chcesz cofnąć potwierdzenie tego statusu?",
      "revokeManyConfirm": "Uwaga: jako że nie jest to ostatni potwierdzony status, usuniemy także wszystkie późniejsze potwierdzenia. Na pewno chcesz to zrobić?"
    },
    "classifications": {
      "successMessage": "Pojazd {registrationNumber} został przydzielony do kolejki: {squareName} - {queueName}"
    },
    "cmr-setting": {
      "errors": {
        "senderInstructionText": {
          "maxLineCountIs8": "Maksymalna liczba linii w tym polu to 8"
        }
      }
    },
    "cmrSettings": {
      "add": "Dodaj ustawienia CMR",
      "fields": {
        "appendedDocsSpecificationCustomField": "Wyświetl wartość pola niestandardowego w polu nr 5 (Załączam dokumenty)",
        "appendedDocsSpecificationNumber": "Dodaj numer w polu nr 5 (Załączam dokumenty)",
        "appendedDocsText": "Stały tekst wyświetlany w polu nr 5 (Załączam dokumenty)",
        "featuresAndNumbersCustomField": "Wyświetl wartość pola niestandardowego w polu nr 6 (Cechy i numery)",
        "featuresAndNumbersText": "Stały tekst w polu nr 6 (Cechy i numery)",
        "packageTypeText": "Stały tekst w polu nr 8 (Sposób opakowania)",
        "quantityCustomField": "Wyświetl wartość pola niestandardowego w polu nr 7 (Ilość sztuk)",
        "quantityText": "Stały tekst w polu nr 7 (Ilość sztuk)",
        "senderInstructionCustomField": "Wyświetl wartość pola niestandardowego w polu nr 13 (Instrukcja nadawcy)",
        "senderInstructionText": "Stały tekst w polu nr 13 (Instrukcja nadawcy)",
        "showPrincipalName": "Wyświetl w polu numer 1 (Nadawca) nazwę pryncypała",
        "showWarehouseName": "Wyświetl w polu numer 1 (Nadawca) nazwę magazynu",
        "weightGrossCustomField": "Wyświetl wartość pola niestandardowego w polu nr 11 (Waga brutto w kg)"
      },
      "label": "Ustawienia CMR",
      "noSettings": "Nie dodano jeszcze ustawień CMR",
      "senderInstructionTextHint": "To pole może mieć maksymalnie 8 linii. Pamiętaj, by linie nie były zbyt długie - inaczej nie będą widoczne na wydruku!"
    },
    "columns": {
      "ADVICE": "Awizacja",
      "ALT_IDENTIFIER": "Alternatywny identyfikator",
      "ARRIVAL_DATE": "Dostawa",
      "CAR": "Pojazd",
      "CARRIER": "Przewoźnik",
      "CONTRACTOR": "Kontrahent",
      "CONTRACTOR_FULL_NAME": "Kontrahent - pełna nazwa",
      "CONTRACTOR_SHORT_NAME": "Kontrahent - skrócona nazwa",
      "CREATION_DATE": "Utworzenie",
      "CUSTOM_FIELDS": "Pola niestandardowe",
      "DOCUMENTS": "Dokumenty",
      "DOCUMENT_IDENTIFIER": "Numer WZ",
      "DRIVER": "Kierowca",
      "DRIVER_IDENTIFIER": "Dowód",
      "DRIVER_PHONE": "Telefon",
      "EMAIL_ADVICE": "Awizacja e-mail",
      "EMAIL_ADVICE_EMAIL_ADDRESS": "Adres e-mail",
      "IDENTIFIER": "Id",
      "INDEX_DOCUMENT_NUMBER": "Numer zamówienia",
      "INDEX_PRODUCTS": "Produkty",
      "INDEX_TRANSPORT_NUMBER": "Nr transportu",
      "INSPECTION": "Kontrola",
      "INVOICE": "Faktura",
      "LOAD_CITY": "Miasto",
      "LOAD_COUNTRY": "Kraj",
      "LOAD_PACKAGES_QUANTITY": "Opakowania zał.",
      "LOAD_PLACE": "Miejsce załadunku",
      "LOAD_QUANTITY": "Ilość palet zał.",
      "LOAD_TIME_WINDOW": "Załadunek",
      "LOAD_WAREHOUSE": "Magazyn",
      "LOCATION": "Lokalizacja",
      "MANUAL_NOTIFICATION": "Powiadomienie ręczne",
      "NOTES": "Notatki",
      "PERIODIC_TRANSPORT_INTERNAL_INDEX": "Nr awizacji okresowej",
      "PRICE": "Cena",
      "QUANTITY": "Ilość palet",
      "RAMP": "Nr rampy",
      "REQUIRES_INSPECTION": "Wymaga kontroli",
      "SERVICE_COMPANY_SHORT_NAME": "Serwis",
      "STATUS": "Status",
      "STORAGE_ZONE": "Strefa",
      "SUM_LOAD_QUANTITY": "Suma palet zał.",
      "SUM_UNLOAD_QUANTITY": "Suma palet rozł.",
      "TOTAL_WEIGHT": "Waga[kg]",
      "TRAILER": "Naczepa / Przyczepa",
      "TRANSPORT_DESCRIPTION": "Uwagi",
      "TRANSPORT_RELATION": "Numer powiązania",
      "TRANSPORT_SUPERVISOR": "Osoba kontaktowa",
      "UNLOAD_CITY": "Miasto",
      "UNLOAD_COUNTRY": "Kraj",
      "UNLOAD_PACKAGES_QUANTITY": "Opakowania rozł.",
      "UNLOAD_PLACE": "Miejsce dostawy",
      "UNLOAD_QUANTITY": "Ilość palet rozł.",
      "UNLOAD_TIME_WINDOW": "Rozładunek",
      "UNLOAD_WAREHOUSE": "Magazyn",
      "WORKER": "Pracownicy",
      "disableCustomFieldInfo": "Włączenie/wyłączenie tej pozycji spowoduje włączenie/wyłączenie wszystkich pól typu customField widocznych w tabeli",
      "label": "Widoczność kolumn"
    },
    "common": {
      "accept": "Zaakceptuj",
      "accepted": "Zaakceptowane",
      "active": "Aktywny",
      "add": "Dodaj",
      "addedBy": "dodany przez",
      "address": "Adres",
      "allRightsReserved": "Wszelkie prawa zastrzeżone",
      "and": "i",
      "andBetween": "a",
      "appVersion": "Wersja",
      "back": "Powrót",
      "browseAndUploadDocuments": "Przeglądaj i dodawaj dokumenty",
      "by": "przez",
      "byYou": "przez Ciebie",
      "cancel": "Anuluj",
      "cancelProcess": "Anuluj proces",
      "changeOfField": "Zmiana pola",
      "check": "sprawdź",
      "choosingReason": "Powód",
      "clear": "Wyczyść",
      "clearFilters": "Wyczyść filtry",
      "clearTextArea": "Wyczyść zawartość tego obszaru",
      "client": "Klient",
      "close": "Zamknij",
      "company": "Firma",
      "confirmMessage": "Jesteś pewien?",
      "createSimilar": "Utwórz podobny",
      "currency": "Waluta",
      "dateCreated": "Data utworzenia",
      "day": "Dzień",
      "days2": "2 Dni",
      "days4": "4 Dni",
      "daysOfWeek": {
        "1": "Pon",
        "2": "Wt",
        "3": "Śr",
        "4": "Czw",
        "5": "Pt",
        "6": "So",
        "7": "Nie",
        "label": "w dniach"
      },
      "dedicatedWindow": "Okno dedykowane",
      "delete": "Usuń",
      "deleteTitle": "Usuń transport (usunięty transport znajdzie się w archiwum).",
      "deletingProgress": "usuwanie",
      "desc": "Opis",
      "description": "Opis",
      "details": "szczegóły",
      "disable": "Wyłącz",
      "disableXLS": "Do eksportu pliku do XLS wymagane jest podanie zakresu dat, lub ograniczenie wyszukiwania filtrami",
      "download": "Pobierz {name}",
      "downloadFile": "Pobierz plik",
      "downloadManualHeader": "Pobierz instrukcję obsługi",
      "driverMobilePhoneNumber.placeholder": "np. 111222333",
      "driverMobilePhonePrefix.placeholder": "np. 48",
      "edit": "Edytuj",
      "enable": "Włącz",
      "english": {
        "abbr": "ang.",
        "label": "angielski"
      },
      "error": "Błąd",
      "errorCode": "Kod błędu",
      "errorList": "Lista błędów",
      "export": "Eksportuj",
      "exportAsXLS": "Eksportuj do pliku XLS.",
      "finishedWindow": "Proces zakończony",
      "forDateRange": {
        "M": "miesiąca",
        "X": "niestandardowego okresu",
        "Y": "roku"
      },
      "formShouldNotBeEmpty": "Dane formularz nie mogą być puste!",
      "from": "od",
      "fromCompany": "z firmy",
      "further": "Dalej",
      "grayWindow": "Okno blokady",
      "hint": "Podpowiedź:",
      "hour": "Godzina",
      "id": "Id",
      "idx": "Indeks",
      "instructionalVideo": "Film instruktażowy",
      "interval": {
        "M": "{count} {count, plural, one {miesiąc} few {miesiące} other {miesięcy}}",
        "d": "{count} {count, plural, one {dzień} other {dni}}"
      },
      "language": {
        "ENG": "ENG",
        "PL": "PL"
      },
      "lastUpdated": "Data ostatniej zmiany",
      "lateWindow": "Okno opóźnione",
      "legend": "Legenda",
      "loadingData": "Ładujemy dane...",
      "max": "Max",
      "maxDate": "Data max",
      "message": "Treść wiadomości",
      "min": "Min",
      "minDate": "Data min",
      "more": "Więcej",
      "name": "Nazwa",
      "nameEn": "Nazwa angielska",
      "namePl": "Nazwa polska",
      "newMessagesTitle": "Masz nieprzeczytane wiadomości.",
      "next": "Następna",
      "no": "Nie",
      "noAdviceAddedYet": "Nie dodano jeszcze żadnej awizacji",
      "noAuthority": "Nie masz uprawnień, by wykonać tą akcję",
      "noData": "Brak danych",
      "noResults": "Brak wyników dla danego wyszukiwania",
      "notSpecified": "Nie podano",
      "ok": "Ok",
      "oldBrowser": "Używasz przestarzałej przeglądarki. Nasza aplikacja będzie lepiej działać na nowoczesnej przeglądarce, takiej jak Google Chrome, Firefox, Opera, Vivaldi, Safari, lub IE Edge.",
      "onDay": "dnia",
      "operationInProgress": "Wykonujemy operację..",
      "options": "Opcje",
      "or": "lub",
      "orderNumber": "Lp.",
      "other": "Inne",
      "otherRequirements": "Inne wymagania",
      "otherRequirementsVisibleToCarrier": "Inne wymagania (pole widoczne dla przewoźnika)",
      "outOf": "z",
      "perPage": "na stronie",
      "phone": "Telefon",
      "pleaseWait": "Proszę czekać...",
      "previous": "Poprzednia",
      "recipient": "Adresat",
      "refresh": "Odśwież",
      "register": "Dołącz",
      "registryOfPersons": "Ewidencja osób",
      "reject": "Odrzuć",
      "rejected": "Odrzucone",
      "rememberFilters": "Zapamiętuj filtry",
      "resend": "Wyślij ponownie",
      "reset": "Resetuj",
      "restore": "Przywróć",
      "role": "Rola",
      "save": "Zapisz",
      "saveAndClose": "Zapisz i zamknij",
      "saveSuccessAdditionalMessage": "Zmiany będą widoczne po odświeżeniu strony",
      "saveSuccessMessage": "Poprawnie zapisaliśmy zmiany",
      "saved": "Zapisano",
      "saving": "Zapisywanie",
      "scrollToClosestTransport": "Przejdź do transportu z najbliższą datą",
      "search": "Szukaj",
      "selectDate": "Wybierz datę",
      "selectDeselectAll": "Zaznacz/Odznacz wszystko",
      "selectValue": "Wybierz wartość",
      "send": "Wyślij",
      "settings": "Ustawienia",
      "shortName": "Nazwa skrócona",
      "shortNameEn": "Skrócona nazwa angielska",
      "shortNamePl": "Skrócona nazwa polska",
      "sure": "Jesteś pewien, że chcesz to zrobić?",
      "timeSign": {
        "after": "po",
        "before": "przed"
      },
      "timeUnit": {
        "d": "dni",
        "h": "godzin",
        "m": "minut",
        "s": "sekund"
      },
      "to": "do",
      "today": "Dziś",
      "transportCombiningIndicator": "Posiada relacje z transportami tego samego typu",
      "transportDeletionDisabled": "Nie możesz usunąć tego transportu. Skontaktuj się z administratorem.",
      "transportEditionDisabled": "Nie masz uprawnień do edycji tego transportu.",
      "type": "Typ",
      "update": "Aktualizuj",
      "uploadFile": "Dodaj plik",
      "value": "Wartość",
      "waiting": "Oczekuje",
      "week": "Tydzień",
      "weightAndSave": "Ważenie i zapis",
      "windowMovedAutomatically": "Ze względu na opóźnienie, okno czasowe zostało przesunięte automatycznie",
      "yes": "Tak"
    },
    "company": {
      "address": "Adres",
      "addresses": "Adresy",
      "alias": "Alias",
      "allowedDomains": {
        "emailNotMatch": "Do tej firmy można zaprosić jedynie użytkowników z mejlami w podanych domenach: {allowedDomains}",
        "header": "Dozwolone domeny dla firmy {companyName}",
        "info": "Do firmy można zapraszać tylko te osoby, które w adresie mailowym mają jedną z dozwolonych domen z poniższej listy",
        "label": "Dozwolone domeny",
        "list": "Lista dozwolonych domen:",
        "successDeleteMsg": "Poprawnie usunięto domenę",
        "successSaveMsg": "Poprawnie zapisano domeny"
      },
      "chooseCompanyTypeHint": "Proszę uzupełnić typ firmy",
      "chooseCountryHint": "Najpierw wybierz kraj!",
      "contractorNumber": "Numer kontrahenta",
      "country": "Kraj",
      "createCompany": "Dodaj firmę",
      "deleteConfirm": "Czy na pewno chcesz usunąć powiązanie firmy {companyName} z firmą {principalName}? Po tej operacji firma {companyName} nie będzie miała dostępu do platformy {principalName}.",
      "deletedMessage": "Poprawnie usunęliśmy firmę \"{companyName}\".",
      "descriptionOfTheFleet": "Opis floty",
      "editCompany": "Edytuj firmę",
      "employees": "Pracownicy",
      "errors": {},
      "existingCompanyName": "Firma już istnieje w platformie. Nie możesz edytować nazwy firmy.",
      "gusHasProblem": "Brak połączenia z bazą GUS. Firma zostanie zweryfikowana zgodnie z danymi z GUS po przywróceniu połączenia",
      "krs": "KRS/CEiDG",
      "label": "Firma",
      "legalStatus": {
        "businessActivity": "Działalność gospodarcza",
        "label": "Stan prawny",
        "limitedCompany": "Spółka"
      },
      "limitedDataHint": "Widoczność i edycja niektórych szczegółów firmy {company} jest ograniczona, zarządzanie jest mozliwe jedynie przez Administratorów {company}.",
      "list": "Lista firm",
      "maxNumberOfAccounts": "Maksymalna liczba kont",
      "maxNumberOfAccountsHint": "Po osiągnięciu maksymalnej ilości kont nie będzie można zaprosić nowego użytkownika do tej firmy. W przypadku, gdy nie ma podanej wartości maksymalnej to limit kont nie obowiązuje.",
      "name": "Nazwa",
      "noEmployees": "Firma nie ma pracowników",
      "noPartnershipWarning": "Twoja firma nie nawiązała z nikim partnerstwa. Może to powodować problemy z wyborem kontrahentów.",
      "partnershipStatus": {
        "ACCEPTED": "Zaakceptowane",
        "AWAITING_VERIFICATION": "Oczekuje na weryfikację",
        "BLOCKED": "Zablokowane",
        "NO_INVITATION": "Brak zaproszenia",
        "REGISTRATION_IS_IN_PROGRESS": "W trakcie rejestracji",
        "REJECTED": "Odrzucone",
        "UPDATED": "Aktualizacja",
        "WAITING": "Oczekuje",
        "label": "Status"
      },
      "partnershipTable": {
        "awaitingLabel": "Lista oczekujących zaproszeń",
        "companyName": "Nazwa firmy",
        "label": "Lista powiązanych firm"
      },
      "patron": "Opiekun",
      "patronWillChange": "Zostanie zmieniony opiekun w zleceniach {transportIds}. Czy jesteś pewien? Jeśli klikniesz \"Anuluj\", zmiany nie zostaną zapisane",
      "possessedCertificates": "Posiadane certyfikaty",
      "pricingPlan": "Pakiet firmy",
      "rampEdition": {
        "header": "Modyfikacja ramp dla użytkowników firmy {companyName}",
        "label": "Modyfikacja ramp",
        "updateForAllUsers": "Zaktualizuj rampy dla wszystkich użytkowników tej firmy"
      },
      "registrationDate": "Data rejestracji",
      "regon": "REGON",
      "saveSuccessMessage": "Poprawnie zapisaliśmy firmę",
      "sections": "Sekcje",
      "selectCountry": "Wybierz kraj",
      "selectType": "Wybierz typ",
      "selectVoivodeship": "Wybierz województwo",
      "shortName": "Nazwa skrócona",
      "taxIdNumber": "NIP",
      "type": {
        "CARRIER": "Przewoźnik",
        "CLIENT": "Klient",
        "OPERATOR": "Operator",
        "SERVICE": "Serwis",
        "SERVICEMAN": "Serwisant",
        "SUPPLIER": "Dostawca \\\\ Odbiorca",
        "SUPPLIER.CLIENT": "Klient",
        "SUPPLIER.COLLECTION": "Odbiorca",
        "SUPPLIER.COMPANY": "Firma",
        "SUPPLIER.COURIER": "Kurier",
        "SUPPLIER.DELIVERY": "Dostawca",
        "label": "Typ",
        "producer": "Producent"
      },
      "typeNipHint": "Najpierw podaj NIP!",
      "update": "Aktualizacja",
      "verifiedByGus": "Firma została poprawnie zweryfikowana w GUS. Nie możesz edytować nazwy firmy."
    },
    "companyRamp": {
      "notExists": "Firma {companyName} nie ma jeszcze przypisanych domyślnych dostępów do ramp",
      "updateForAllUsersConfirmMsg": "Czy na pewno chcesz nadpisać dostępy do poszczególnych ramp dla wszystkich użytkowników firmy?",
      "usersWithIndividualRampAccess": "Lista użytkowników z innym przypisaniem ramp"
    },
    "contractors-group": {
      "addGroup": "Dodaj grupę",
      "description": {
        "details": "Szczegóły",
        "ifYouDoNotHaveDocument": "Jeżeli nie posiadasz dokumentu, który jest wymagany, wyłącz wybraną grupę.",
        "label": "Wybranie grupy wymaga uzupełnienia odpowiednich dokumentów licencji lub ubezpieczenia",
        "notApplicable": "Nie dotyczy",
        "optionalDocuments": "oraz jeden z następujących dokumentów:"
      },
      "errors": {
        "obligatoryDocuments": "Aby należeć do grupy {groupName}, firma musi posiadać dokumenty: {documents}.",
        "optionalDocuments": "Aby należeć do grupy {groupName}, firma musi posiadać chociaż jeden z dokumentów: {documents}."
      },
      "label": "Grupowanie",
      "name": "Nazwa",
      "nameEn": "Nazwa w jęz. ang.",
      "selectADRInfo": "Po wybraniu tej opcji firma zostanie przyłączona do grupy Przewoźników, którzy będą mieli możliwość realizacji zleceń z towarami niebezpiecznymi. Proszę się upewnić, że Twoja firma posiada uprawnienia do przewozu materiałów niebezpiecznych.",
      "shortName": "Nazwa skrócona",
      "shortNameEn": "Nazwa skrócona w jęz. ang.",
      "unSelectADRInfo": "Po odznaczeniu tej opcji firma zostanie usunięta z grupy Przewoźników, którzy mają możliwość realizacji zleceń z towarami niebezpiecznymi. Czy na pewno chcesz opuścić grupę ADR?"
    },
    "contractors-group-type": {
      "ADR": "Podział ze względu na przewóz towarów i ładunków niebezpiecznych",
      "AVAILABLE_VEHICLES": "Podział ze względu na dostępne podziały",
      "PRIVATE": "Grupy prywatne, niewidoczne dla kontrahenta",
      "SUPPORTED_REGIONS": "Podział ze względu na obsługiwane regiony",
      "TYPES_OF_BUSINESS": "Podział ze względu na rodzaj prowadzonej działalności",
      "TYPES_OF_TRANSPORT": "Podział ze względu na obsługiwane rodzaje transportu",
      "errors": {
        "canSelectOnlyOneContractorsGroup": "Proszę wybrać tylko jedną grupę",
        "requiresContractorsGroup": "Proszę wybrać co najmniej jedną grupę z sekcji \"{groupType}\""
      }
    },
    "courier": {
      "COURIER_PACKAGE": {
        "add": "Dodaj",
        "disableConfirm": "Na pewno chcesz usunąć to opakowanie?",
        "edit": "Edytuj",
        "internalId": "ID",
        "numberOfPackages": "Ilość",
        "numberOfPackagesTotal": "Łączna ilość:",
        "packageDescription": "Zawartość",
        "packageHeight": "Wysokość [cm]",
        "packageIdPlaceholder": "ID",
        "packageLength": "Długość [cm]",
        "packageType": "Typ",
        "packageTypePlaceholder": "Typ",
        "packageValue": "Wartość towaru (szt.)",
        "packageValueLine": "Wartość towaru (linia)",
        "packageWeight": "Waga (szt.) [kg]",
        "packageWeightTotal": "Łączna waga:",
        "packageWidth": "Szerokość [cm]",
        "pallet": "PALLET",
        "parcel": "PACKAGE",
        "sectionLabel": "Paczki",
        "sum": "Suma:"
      },
      "documents": {
        "connote": "List przew.",
        "label": "Etykieta",
        "manifest": "Manifest"
      },
      "priceDetails": {
        "Vat": "Vat",
        "basePrice": "Kwota podstawowa",
        "basePriceVat": "VAT od podstawy",
        "dhlPremium": "12:00 Premium",
        "emergencySituation": "Sytuacje awaryjne",
        "finalPriceComponents": "Składowe finalnej kwoty",
        "fuelCharge": "Opłata paliwowa",
        "grossPrice": "Cena brutto",
        "grossServicePrice": "Cena brutto serwisu",
        "netPriceComponents": "Składowe ceny netto",
        "netWorth": "Netto",
        "peakPrice": "Dopłata Peak",
        "priceMultiplePackaging": "Przesyłka wielopaczkowa",
        "servicePrice": "Cena serwisowa"
      },
      "validation.float.range": "Wartość musi być liczbą z zakresu 0.001 - 999999999999",
      "validation.integer.range": "Wartość musi być liczbą calkowitą z zakresu 1 - 9999999",
      "validation.minimumOnePackage": "Musisz dodać conajmniej jedną poprawną paczkę",
      "validation.range": "Wartość musi być liczbą z zakresu 1 - 9999999"
    },
    "courierSettings": {
      "add": "Dodaj ustawienia kurierskie",
      "description": {
        "isGetCourierQuotesFieldVisibility": "Widoczność pola \"Uzyskaj wyceny kurierów\"",
        "shouldGetCourierQuotesByDefault": "Domyślnie uzyskaj wyceny kurierów",
        "transportType": "Typ transportu"
      },
      "label": "Ustawienia kurierskie",
      "noSettings": "Nie dodano jeszcze ustawień kurierskich"
    },
    "custom-field-definition": {
      "addNewField": "Dodaj nowe pole",
      "amountOfDelayedTimeForTaskToBeRequired": "Ilość czasu opóźnienia etapu powodująca wymaglaność pola",
      "autocompleteEnabled": "Podpowiadanie danych historycznych",
      "cancel": "Czy na pewno chcesz anulować wprowadzone zmiany?",
      "create": "Utwórz definicję pola formularza",
      "defaultOption": {
        "hint": "Podana opcja będzie domyślnie wybraną wartością",
        "label": "Nazwa domyślnej opcji",
        "placeholder": "Wybierz jedną z dostępnych opcji pola wyboru",
        "title": "Domyślna wartość pola wyboru"
      },
      "delete": "Czy na pewno chcesz usunąć to pole?",
      "deleteSuccessMessage": "Poprawnie usunęliśmy ustawienie pola.",
      "englishName": "Nazwa angielska",
      "errors": {
        "maxValue": {
          "dotSeparator": "Separatorem cześci dziesiętnej musi być kropka `.`",
          "maxLowerThenMin": "Wartość maksymalna nie może być mniejsza niż wartośc minimalna"
        },
        "minValue": {
          "dotSeparator": "Separatorem cześci dziesiętnej musi być kropka `.`",
          "minHigherThanMax": "Wartość minimalna nie może być większa niż wartośc maksymalna"
        },
        "numberDecimalFormat": {
          "regex": "Format liczby może składać się tylko z następujących znaków: # , . 0 i _"
        },
        "obligatory": {
          "invalid": "W przypadku zaznaczenia pola \"Wymagane\" należy wybrać przynajmniej jedną z opcji: \"Pole wymagane podczas oraz po potwierdzaniu etapu\", \"Widoczne podczas tworzenia zlecenia\"."
        },
        "options": {
          "empty": "Proszę podać chociaż jedną opcję"
        },
        "textAreaRowsNumber": {
          "textAreaSize": "Liczba wierszy musi być większa niż 0"
        }
      },
      "filterInArchive": "Możliwość filtrowania w archiwum",
      "filterLabel": "Filtry po polach dodatkowych:",
      "idx": "Idx",
      "includedInPriceList": "Uwzględnij w cenniku",
      "makeFieldDependentOn": {
        "definitionPlaceholder": "Wybierz nazwę pola zależnego",
        "dependsOnTaskInfo": "Wybrane pole jest powiązane ze statusem zlecenia",
        "fieldCompleted": "Pole wypełnione",
        "hint": {
          "obligatory": "Pole w zależności od spełnionego warunku będzie widoczne i wymagane",
          "voluntary": "Pole w zależności od spełnionego warunku będzie widoczne i niewymagane"
        },
        "title": "Uzależnij widoczność pola od wartości w innym polu"
      },
      "maxValue": "Maksymalna dopuszczalna wartość:",
      "minValue": "Minimalna dopuszczalna wartość:",
      "name": "Nazwa",
      "noTaskSelected": "Niezależne od etapu",
      "numberDecimalFormat": {
        "comma": "Separator tysięcy",
        "dot": "Separator dziesiętny",
        "hash": "Cyfra, wyświetla tylko cyfry znaczące",
        "label": "Format liczby",
        "meaning": "Znaczenie",
        "minus": "Znak minus",
        "symbol": "Symbol",
        "zero": "Cyfra, jeśli brak uzupełnia zerami"
      },
      "obligatory": "Wymagane",
      "order": "Kolejność wyświetlania (idx)",
      "requiredInDelayedTransport": "Wymagane w przypadku wystąpienia opóźnienia",
      "requiredTaskInDelayedTransport": "Pole wymagane podczas potwierdzenia etapu w przypadku opóźnienia etapu",
      "saveSuccessMessage": "Poprawnie zapisaliśmy ustawienia pól",
      "saveSuccessMessageForTimeWindow": "Poprawnie zapisaliśmy ustawienia pól. Zmiany będą widoczne za kilka minut",
      "settingsFields": "Ustawienia pól",
      "side": "Strona, po której będzie widoczne pole, gdy w formularzu będzie ich więcej niż {number}",
      "sideLeft": "Lewa",
      "sideRight": "Prawa",
      "sideShort": "Strona",
      "task": "Pole wymagane podczas oraz po potwierdzaniu etapu",
      "taskHint": "Aby ustawić obligatoryjność pola na: \"Niezależne od etapu\" należy kliknąć krzyżyk przy wybranym powyżej etapie",
      "textAreaRowsNumber": "Ilość wierszy dla pola",
      "type": {
        "date": "Data",
        "datetime": "Data i godzina",
        "number": "Liczba",
        "select": "Pole wyboru",
        "text": "Tekst",
        "text_area": "Pole tekstowe"
      },
      "typeField": "Typ pola",
      "visibilityDependsOnDefinition": "Nazwa pola zależnego",
      "visibilityDependsOnOption": "Wartość pola zależnego",
      "visibleDuringTransportCreation": "Widoczne podczas tworzenia zlecenia",
      "visibleInAdviceSection": "Widoczne w formularzu awizacji",
      "visibleInDeliveryDocument": "Widoczne na dokumencie dostawy",
      "visibleInDiscrepancyReportSection": "Widoczne w sekcji \"Protokół Niezgodności\"",
      "visibleInInspectionPanelSection": "Widoczne w sekcji \"Kontrola\"",
      "visibleInTable": "Widoczne w tabeli",
      "visibleInTransportWeightReceipt": "Widoczne w kwicie wagowym transportu",
      "visibleInWeightSection": "Widoczne w sekcji \"Ważenie\"",
      "visibleOnTimeWindow": "Widoczne na oknie czasowym"
    },
    "custom-field-option": {
      "add": "Dodaj opcję",
      "dependenciesHint": "Proszę wybrać pole niestandardowe i opcję, od których zależny będzie wybrany klucz",
      "editDependencies": "Edytuj powiązania",
      "errors": {},
      "optionKey": "Wartość - klucz",
      "relatedDefinition": "Pole niestandardowe",
      "relatedOptions": "Powiązane opcje",
      "remove": "Usuń opcję"
    },
    "custom-field-value": {
      "autoCompletePlaceholder": "Zacznij pisać, by wyszukać opcje.",
      "errors": {
        "maxValue": {
          "value": "Maksymalna wartość to {maxValue}"
        },
        "minValue": {
          "value": "Minimalna wartość to {minValue}"
        },
        "value": {
          "notProperValueType": "Zły typ wartości, wymagana jest liczba"
        }
      }
    },
    "dateFilterProperty": {
      "DATE_CREATED": "Utworzenie zlecenia",
      "DEPARTURE_DATE": "Zlecona data dostawy (etap bez okna czasowego)",
      "LOAD_DATE": "Data zładunku",
      "TIME_RANGE_FOR_ADVICE_START": "Początek zakresu czasu (etap bez okna czasowego)",
      "TIME_RANGE_FOR_ADVICE_STOP": "Koniec zakresu czasu (etap bez okna czasowego)",
      "TIME_WINDOW_DATES": "Początek lub koniec okna czasowego",
      "TIME_WINDOW_START": "Początek okna czasowego",
      "TIME_WINDOW_STOP": "Koniec okna czasowego",
      "UNLOAD_DATE": "Data rozładunku",
      "hint": "Jeśli typ transportu umożliwia tworzenie zlecenia bez okna czasowego, to takie zlecenie nie będzie uwzględnione w filtrach dotyczących własności okna czasowego (np. Początek okna czasowego)."
    },
    "deliveries": {
      "add": "Dodaj dostawę",
      "label": "Dostawy"
    },
    "delivery": {
      "label": "Dostawa"
    },
    "dev": {
      "confirm": "Potwierdź",
      "performSaveAt": "Wykonaj zapis o"
    },
    "discrepancyReport": {
      "generate": "Wygeneruj protokół niezgodności",
      "label": "Protokół Niezgodności",
      "noAdviceInfo": "Wygenerowanie protokołu będzie możliwe po dodaniu awizacji",
      "noDiscrepanciesFound": "Brak stwierdzonych niezgodności",
      "noDiscrepanciesInfo": "Wygenerowanie protokołu będzie możliwe po stwierdzeniu niezgodności"
    },
    "document": {
      "CERTIFICATE": {
        "expirationDate": "Data ważności",
        "number": "Numer certifikatu"
      },
      "INSURANCE": {
        "create": "Dodaj ubezpieczenie",
        "expirationDate": "Okres ubezpieczenia do",
        "expirationDateFrom": "Okres ubezpieczenia od",
        "new": "Nowe {typeLabel}",
        "number": "Numer ubezpieczenia",
        "save": "Zapisz ubezpieczenie",
        "savingInProgress": "Zapisujemy ubezpieczenie..",
        "showArchived": "Pokaż archiwalne ubezpieczenia"
      },
      "LICENCE": {
        "create": "Dodaj licencję",
        "expirationDate": "Data ważności",
        "new": "Nowa {typeLabel}",
        "number": "Numer licencji",
        "save": "Zapisz licencję",
        "savingInProgress": "Zapisujemy licencję..",
        "showArchived": "Pokaż archiwalne licencje"
      },
      "MAPPING": {
        "expirationDate": "Data ważności",
        "expirationDateFrom": "Data mapowania",
        "number": "Numer mapowania"
      },
      "amount": "Suma gwarancyjna",
      "currency": "Waluta",
      "disableConfirm": "Na pewno chcesz przenieść ten dokument do archiwum? Dokumenty archiwalne nie są brane pod uwagę przy sprawdzaniu ważności.",
      "documentType": "Zakres",
      "documentType.placeholder": "Wybierz zakres",
      "errors": {
        "expirationDate": {
          "isInPast": "Data ważności nie może znajdować się w przeszłości."
        },
        "expirationDateFrom": {
          "afterExpirationDate": "Data ważności \"od\" musi być wcześniejsza niż data \"do\"."
        }
      },
      "expirationShiftInfo": "Dokument uznaje się za nieważny na {days} dni przed końcem okresu ubezpieczenia, tj. {date}",
      "expired": "Dokument stracił ważność!",
      "files": "Dodane pliki",
      "maxCountExceeded": "W jednym żądaniu można załączyć maksymalnie {maxCount} plików",
      "noFilesInfo": "Do dokumentu nie został dodany żaden plik.",
      "noFilesWarning": "Proszę dodać zeskanowany plik dokumentu!",
      "scopeOfInsurance": "Zasięg terytorialny",
      "showArchived": "Pokaż archiwalne dokumenty"
    },
    "documentType": {
      "CERTIFICATE": {
        "add": "Dodaj świadectwo kalibracji",
        "expirationDate": "Okres ważności do",
        "expirationDateFrom": "Okres ważności od",
        "expirationDateLabel": "Ważność kalibracji",
        "name": "Świadectwo kalibracji czujników",
        "number": "Numer świadectwa",
        "showArchived": "Pokaż archiwalne świadectwa kalibracji"
      },
      "SUMMER_MAPPING": {
        "add": "Dodaj mapowanie letnie",
        "expirationDate": "Okres ważności do",
        "expirationDateFrom": "Okres ważności od",
        "name": "Mapowanie letnie",
        "showArchived": "Pokaż archiwalne mapowania letnie"
      },
      "WINTER_MAPPING": {
        "add": "Dodaj mapowanie zimowe",
        "expirationDate": "Okres ważności do",
        "expirationDateFrom": "Okres ważności od",
        "name": "Mapowanie zimowe",
        "showArchived": "Pokaż archiwalne mapowania zimowe"
      },
      "isRequiredForModeOfTransportationInfo": "Brakujące dokumenty:"
    },
    "driver": {
      "addDriver": "Dodaj kierowcę",
      "disableConfirm": "Na pewno chcesz usunąć kierowcę?",
      "documentNumber": "Numer dokumentu",
      "documentType": {
        "ID_CARD": "Dowód",
        "PASSPORT": "Paszport",
        "label": "Typ dokumentu"
      },
      "errors": {
        "phoneNumber": {
          "regex": "Numer musi zawierać od 9 do 12 cyfr"
        }
      },
      "label": "Kierowcy",
      "lastUpdated": "Data ostatniej edycji",
      "name": "Imię i nazwisko",
      "nationality": "Narodowość",
      "phoneNumber": "Telefon",
      "timeWindow": "Okno czasowe"
    },
    "emailAdvice": {
      "adviceDetails": "Dane awizacji",
      "adviceDetailsCf": "Uzupełnienie danych",
      "alreadySent": "Prośba o awizacje mailową została wysłana przez",
      "alreadySentCf": "Prośba o uzupełnienie danych została wysłana przez",
      "confirm": "Czy na pewno chcesz ponownie wysłać prośbę o podanie danych? Poprzedni link zostanie zdezaktywowany.",
      "logLabel": {
        "ADVICE_DATA": "Prośba o dane awizacji",
        "CF_DATA": "Prośba o uzupełnienie danych"
      },
      "noRole": "Nie posiadasz uprawnień do wykonania tej operacji",
      "title": "Wysłanie prośby o podanie danych awizacji za pomocą awizacji mailowej.",
      "titleCf": "Wysłanie prośby o uzupełnienie danych zlecenia za pomocą awizacji mailowej."
    },
    "errors": {
      "blank": "Proszę wprowadzić wartość",
      "blankCheckbox": "Pole musi być zaznaczone",
      "blankSelect": "Proszę wybrać wartość",
      "emptyFile": "Przesłany plik jest pusty, proszę wybrać inny plik",
      "fileAlreadyExists": "Wystąpił błąd podczas przesyłania pliku. Plik o podanej nazwie już istnieje.",
      "fileUploadError": "Wystąpił błąd podczas przesyłania pliku",
      "filterRequestTimeout": "Wystąpił błąd podczas wyszukiwania. Proszę zawęzić zakres dat wyszukiwania i spróbować ponownie.",
      "invalidDatesSequence": "Data \"od\" musi być przed wcześniejsza niż data \"do\"",
      "invalidEmail": "Proszę podać poprawny email",
      "invalidQuantity": "Liczba palet musi byc dodatnią liczbą całkowitą",
      "noNegativeNumber": "To pole nie może być liczbą ujemną",
      "number": "To pole może zawierać jedynie liczby",
      "positiveInteger": "To pole musi być dodatnią liczbą całkowitą",
      "positiveNumber": "To pole musi być liczbą dodatnią",
      "tooLong": "To pole może zawierać maksymalnie %@ znaków",
      "tooShort": "To pole musi zawierać co najmniej %@ znaków"
    },
    "file": {
      "confirmDeletion": "Czy na pewno chcesz usunąć ten plik?"
    },
    "formChanges": {
      "dirtyValueChanged": "Uwaga! Zmieniłeś/zmieniłaś wartość w tym polu na \"{yourValue}\", ale inny użytkownik zapisał zmiany w zleceniu z wartością \"{newValue}\".",
      "newValue": "Uwaga! Inny użytkownik wprowadził wartość \"{value}\" w tym polu.",
      "valueChanged": "Uwaga! Inny użytkownik dokonał zmiany wartości w tym polu z \"{oldValue}\" na \"{newValue}\".",
      "valueRemoved": "Uwaga! Inny użytkownik usunął wartość \"{value}\" z tego pola."
    },
    "freeSubsequentSpots": {
      "infoAboutCurrentSpot": "Wybrana rampa: <b>{currentRamp}</b>, czas: <b>{currentStart}</b>",
      "infoAboutWindowSize": "Twoje okno czasowe nie mieści się we wskazanym miejscu, czas potrzebny na rozładunek: <b>{windowSizeInMinutes}</b> minut",
      "showSearchSpotsButton": "Pokaż wolne miejsca w Harmonogramie",
      "suggestionsHeader": "Najbliższa możliwa data awizacji to:"
    },
    "gates": {
      "artrIdentifier": "Identyfikator ARTR",
      "create": "Utwórz bramę",
      "gateTypes": {
        "ENTRY_GATE": "Brama wjazdowa",
        "EXIT_GATE": "Brama wyjazdowa",
        "UNKNOWN": "Typ nieznany",
        "label": "Typ bramy"
      },
      "label": "Bramy"
    },
    "help": {
      "changePrincipalSpaceInfo": "Uwaga: Strona na której się znajdujesz zostanie odświeżona; prosimy o zapisanie zmian przed wykonaniem tej akcji.",
      "contactUs": "Pomoc techniczna Logintegra",
      "contactWithWarehouse": "Kontakt z magazynem",
      "label": "Pomoc",
      "newMessagesTitle": "Nieprzeczytane wiadomości: %@1.",
      "selectRamp": "Wybierz grupę załadunkową.",
      "statute": "Regulamin"
    },
    "index": {
      "INDEX": {
        "add": "Dodaj indeks",
        "disableConfirm": "Na pewno chcesz usunąć ten indeks?",
        "enabled": "Indeks aktywny",
        "globalNumber": "Numer indeksu",
        "globalNumber.abbr": "Nr indeksu",
        "label": "Indeks",
        "localNumber": "Numer lokalny",
        "localNumber.abbr": "Nr lokal.",
        "name": "Nazwa produktu",
        "name.abbr": "Nazwa",
        "notes": "Uwagi do indeksu",
        "product": "Produkt",
        "recipient": "Kontrahent",
        "sectionLabel": "Indeksy",
        "shortName": "Skrócona nazwa",
        "step": "Przypisanie indeksu do etapu transportu",
        "version": "Numer wersji"
      },
      "PALLETS": {
        "add": "Dodaj Palety",
        "disableConfirm": "Na pewno chcesz usunąć tę pozycję?",
        "enabled": "Pozycja aktywna",
        "globalNumber": "Numer palety",
        "globalNumber.abbr": "Nr palety",
        "label": "Palety",
        "localNumber": "Numer lokalny",
        "localNumber.abbr": "Nr lokal.",
        "name": "Nazwa produktu",
        "name.abbr": "Nazwa",
        "notes": "Informacje dodatkowe",
        "product": "Produkt",
        "sectionLabel": "Palety",
        "shortName": "Skrócona nazwa",
        "step": "Przypisanie palet do etapu transportu"
      },
      "company": "Kontrahent",
      "companyRecipient": "Odbiorca klienta",
      "dateCreated": "Data utworzenia",
      "documentNumber": "Numer zamówienia",
      "documentNumber.abbr": "Numer zamówienia",
      "exportDisabledHint": "Eksport będzie możliwy po zapisaniu zmian.",
      "selectCompany": "Wybierz klienta",
      "selectCompanyHint": "Proszę najpierw wybrać klienta w formularzu zlecenia",
      "selectRecipient": "Wybierz odbiorcę",
      "transportNumber": "Rodzaj zamówienia",
      "transportNumber.abbr": "Rodz. zam."
    },
    "index-quantity": {
      "errors": {
        "quantity": {
          "conditionallyObligatory": "Proszę podać jedną z wartości: {names}.",
          "invalidNumber": "Proszę podać poprawną liczbę dodatnią.",
          "obligatory": "Proszę podać ilość."
        }
      },
      "incompleteLoadingRulesNotMet": "Podanie tej wartości jest możliwe tylko w przypadku niekompletnego załadunku."
    },
    "inspectionSection": {
      "fillInspection": "Uzupełnij",
      "label": "Kontrola",
      "noInspection": "Nie wykonano kontroli transportu"
    },
    "invitation": {
      "acceptedByAnotherPerson": "Zaproszenie zostało już zaakceptowane przez inną osobę z Państwa firmy.",
      "acceptedSummary": "Zaakceptowane",
      "alreadyAccepted": "To zaproszenie zostało już zaakceptowane.",
      "alreadyHandled": "Zaproszenie zostało już obsłużone",
      "companyRegistration": "Rejestracja firmy",
      "createdBy": "{personName} zaprasza Cię, byś rozpoczął współpracę z firmą {companyName} jako {roleName}",
      "creator": "Autor",
      "email": "E-mail",
      "errors": {},
      "expirationDate": "Data ważności",
      "for": {
        "CARRIER": "Przewoźnika",
        "CLIENT": "Klienta",
        "JOIN_COMPANY": "Do swojej firmy",
        "OPERATOR": "Operatora",
        "SERVICEMAN": "Serwisanta",
        "SUPPLIER": "Dostawcę \\\\ Odbiorcę",
        "SUPPLIER.CLIENT": "Klienta"
      },
      "hasExpired": "Przeterminowane",
      "instruction": {
        "label": "Zapoznałem się z",
        "linkName": "OGÓLNYMI WARUNKAMI ŚWIADCZENIA USŁUG"
      },
      "invalidTaxIdNumber": {
        "info": "Dane firmy nie zgadzają się z informacjami z zaproszenia. Prosimy o kontakt z Logintegra."
      },
      "invite": "Zaproś",
      "inviteToCompany": "Zaproś do firmy",
      "label": "Zaproszenie",
      "notFound": "Zaproszenie nie istnieje, lub straciło ważność.",
      "ofCompany": "firmy {companyName}",
      "registeredAccount": "Zarejestrowane konto",
      "registrationComplete": "Dziękujemy. Twoje konto zostało zarejestrowane. Możesz się zalogować wykorzystując login {username}",
      "registrationCompleteLdap": "Dziękujemy. Twoje konto zostało zarejestrowane. Możesz się zalogować, wykorzystując login {username} oraz hasło, które jest wykorzystywane do logowania w systemach wewnętrznych Państwa firmy (Active Directory)",
      "rejectedSummary": "Odrzucone",
      "sendTo": "Wyślij zaproszenie",
      "sent": "Zaproszenie zostało wysłane na adres {email}.",
      "taxIdNumberExist": {
        "info": "Podany numer NIP już istnieje w bazie danych. Prosimy o kontakt z Logintegra."
      },
      "toJoinExistingCompany": "do firmy {companyName}",
      "toPartnership": "{personName} zaprasza Cię, byś rozpoczął współpracę z firmą {companyName} jako {roleName}.",
      "type": {
        "CARRIER": "Dołącz jako przewoźnik",
        "CLIENT": "Dołącz jako klient",
        "JOIN_COMPANY": "Dołącz do firmy",
        "SERVICEMAN": "Dołącz jako serwisant",
        "SUPPLIER": "Dołącz jako dostawca \\\\ odbiorca",
        "SUPPLIER.CLIENT": "Dołącz jako klient"
      },
      "userRegistration": "Rejestracja użytkownika"
    },
    "invitations": {
      "cannotInviteAnyRoleGroup": "Nie masz uprawnień, by zaprosić jakiegokolwiek użytkownika",
      "emptyReceived": "Nie otrzymano jeszcze żadnego zaproszenia.",
      "emptySent": "Nie wysłano jeszcze żadnego zaproszenia.",
      "label": "Zaproszenia",
      "noRoleGroupsFound": "Nie masz uprawnień, by zaprosić jakąś rolę z podanym typem partnerstwa",
      "received": "Otrzymane",
      "sent": "Wysłane",
      "types": {
        "ACCEPTED": "Zaakceptowane",
        "EXPIRED": "Przeterminowane",
        "REJECTED": "Odrzucone",
        "WAITING": "Oczekujące"
      }
    },
    "log": {
      "actor": "Autor zmiany",
      "change": "Zmiana",
      "dateCreated": "Data",
      "fieldChange": "Zmiana pola",
      "integration": {
        "resend": "Ponów",
        "resendFailed": "Ponowienie komunikatu nie powiodło się",
        "resendSuccess": "Zaplanowaliśmy ponowienie komunikatu",
        "sureToResend": "Jesteś pewien, że chcesz ponowić komunikat API?"
      },
      "method": "Metoda",
      "object": "Obiekt",
      "partnership": "Partnerstwo #{id}",
      "request": "Żądanie",
      "requestType": "Typ żądania",
      "response": "Odpowiedź",
      "transport": "Transport",
      "url": "Adres URL"
    },
    "logintegraEmail": "info@logintegra.com",
    "logintegraPhone": "+48 585 357 806",
    "logs": {
      "DELETE": {
        "ActionAuthority": "Usunięcie uprawnienia zależnego od typu transportu",
        "ActiveAuctionRestriction": "Usunięcie uprawnienia",
        "ActiveRestriction": "Usunięcie uprawnienia",
        "AdviceDatesRestriction": "Usunięcie uprawnienia",
        "AfterCheckpointBasedRestriction": "Usunięcie uprawnienia",
        "AuthorityRestriction": "Usunięcie uprawnienia zależnego od obiektu",
        "BeforeCheckpointBasedRestriction": "Usunięcie uprawnienia",
        "BlockedRestriction": "Usunięcie blokady uprawnienia",
        "CompanyRamp": "Usunięcie przypisania ramp dla firmy",
        "CustomFieldValue": "Edycja pola specjalnego",
        "NoRouteOfferRestriction": "Usunięcie uprawnienia",
        "OwnCompanyRestriction": "Usunięcie uprawnienia",
        "PersonNotification": "Usunięcie powiadomienia mailowego",
        "PersonRamp": "Usunięcie przypisania ramp dla użytkownika",
        "RoleGroupAuthority": "Usunięcie uprawnienia dla roli użytkownika",
        "Step": "Usunięcie etapu",
        "TimeBasedRestriction": "Usunięcie uprawnienia",
        "TransportTransportRelation": "Usunięcie powiązania",
        "UnlimitedRestriction": "Usunięcie uprawnienia"
      },
      "INSERT": {
        "ARTR": "Integracja z systemem ARTR",
        "ActionAuthority": "Dodanie uprawnienia",
        "ActiveAuctionRestriction": "Dodanie uprawnienia",
        "ActiveRestriction": "Dodanie uprawnienia",
        "Advice": "Utworzenie awizacji",
        "AdviceDatesRestriction": "Dodanie uprawnienia",
        "AfterCheckpointBasedRestriction": "Dodanie uprawnienia",
        "Auction": "Utworzenie aukcji",
        "AuthorityRestriction": "Dodanie uprawnienia",
        "BeforeCheckpointBasedRestriction": "Dodanie uprawnienia",
        "BlockedRestriction": "Blokada uprawnienia",
        "CompanyRamp": "Zmiana przypisania ramp dla firmy",
        "CustomFieldValue": "Uzupełnienie pola specjalnego",
        "File": "Dodanie pliku",
        "Index": "Dodanie indeksu",
        "NoRouteOfferRestriction": "Dodanie uprawnienia",
        "Note": "Utworzenie notatki",
        "Offer": "Dodanie oferty",
        "OwnCompanyRestriction": "Dodanie uprawnienia",
        "Person": "Utworzenie konta",
        "PersonNotification": "Dodanie powiadomienia mailowego",
        "PersonRamp": "Zmiana przypisania ramp dla użytkownika",
        "RoleGroupAuthority": "Dodanie uprawnienia dla roli użytkownika",
        "Step": "Dodanie etapu",
        "TimeBasedRestriction": "Dodanie uprawnienia",
        "Transaction": "Przekazanie zlecenia",
        "Transport": "Utworzenie transportu",
        "TransportTransportRelation": "Dodanie powiązania",
        "UnlimitedRestriction": "Dodanie uprawnienia"
      },
      "TYPE_EMAIL": "Wysłanie powiadomienia e-mail",
      "TYPE_SMS": "Wysłanie powiadomienia SMS",
      "UPDATE": {
        "ActionAuthority": "Zmiana uprawnienia zależnego od typu transportu",
        "Advice": "Edycja awizacji",
        "Auction": "Edycja aukcji",
        "AuthorityRestriction": "Zmiana uprawnienia zależnego od obiektu",
        "Checkpoint": "Zmiana statusu",
        "CompanyRamp": "Zmiana przypisania ramp dla firmy",
        "CustomFieldValue": "Edycja pola specjalnego",
        "File": "Edycja pliku",
        "Index": "Edycja indeksu",
        "IndexQuantity": "Edycja ilości w indeksie",
        "Note": "Edycja notatki",
        "Offer": "Aktualizacja oferty",
        "Person": "Logowanie",
        "PersonRamp": "Zmiana przypisania ramp dla użytkownika",
        "Step": "Edycja etapu",
        "StepPackageType": "Opakowania",
        "TimeWindow": "Edycja okna czasowego",
        "Transaction": "Zmiana danych transakcji",
        "Transport": "Edycja transportu"
      },
      "WeightLog": "Odczyt wagi"
    },
    "main-section": {
      "errors": {
        "hasErrors": "Sekcja danych głównych zawiera błędy. Proszę poprawnie uzupełnić wymagane pola.",
        "krsFile": "Aby wysłać prośbę o weryfikację wcześniej musisz dodać wymagany dokument potwierdzający KRS/CEiDG",
        "nipFile": "Aby wysłać prośbę o weryfikację wcześniej musisz dodać wymagany dokument potwierdzający NIP",
        "regonFile": "Aby wysłać prośbę o weryfikację wcześniej musisz dodać wymagany dokument potwierdzający REGON"
      }
    },
    "max-pallet": {
      "errors": {},
      "label": "Limit palet",
      "title": "Limit palet na dzień"
    },
    "modeOfTransportation": {
      "approve": "Zatwierdź środek transportu",
      "brand": "Marka",
      "create": "Dodaj środek transportu",
      "disabledReason": {
        "blockedSections": "Zablokowane sekcje: {blockedSections}",
        "blockingDocument": "Nie można wybrać do awizacji środka transportu ze względu na ważność dokumentu {blockingDocument} ({minValidityDate}). Ważność kończy się przed końcem realizacji transportu ({maxTransportDate}).",
        "label": "Środek transportu ma status {status}",
        "rejectedSections": "Odrzucone sekcje: {rejectedSections}"
      },
      "edit": {
        "disable": "Czy na pewno chcesz wyłączyć ten środek transportu?"
      },
      "label": "{count, plural, one {Środek transportu} other {Środki transportu}}",
      "serialNumberOfRecorder": "Numer seryjny rejestratora",
      "showEnabled": {
        "false": "Pokaż tylko włączone środki transportu",
        "true": "Pokaż dodatkowo wyłączone środki transportu"
      },
      "status": {
        "ACCEPTED": "Zaakceptowany",
        "AWAITING_VERIFICATION": "Niezaakceptowany",
        "BLOCKED": "Zablokowany",
        "DISABLED": "Wyłączony",
        "REJECTED": "Odrzucony",
        "UPDATED": "Zaktualizowany"
      },
      "subcontractor": "Podwykonawca",
      "verificationInfo": "Środek transportu został zatwierdzony {date} przez użytkownika {user}"
    },
    "noAccessibleStatisticsReports": "Aktualnie nie masz dostępu do żadnego raportu",
    "noAccessibleTransportTypes": "Aktualnie nie masz dostępu do żadnego typu transportu",
    "note": {
      "autoGenerated": "Wygenerowany automatycznie",
      "creator": "Twórca",
      "dateCreated": "Data utworzenia",
      "label": "Notatka",
      "note": "Notatka",
      "transport": "Transport"
    },
    "notification": {
      "ADVICE_CONFIRMATION": {
        "desc": "Powiadomienie wysyłane do twórcy po zapisaniu awizacji.",
        "label": "Potwierdzenie utworzenia awizacji: {transportTypeName}",
        "labelShort": "Potwierdzenie utworzenia awizacji"
      },
      "ADVICE_CREATED": {
        "desc": "Powiadomienie wysyłane po zapisaniu awizacji.",
        "label": "Utworzenie awizacji: {transportTypeName}",
        "labelShort": "Utworzenie awizacji"
      },
      "ADVICE_CREATED_SMS": {
        "labelShort": "Powiadomienie SMS do kierowcy po utworzeniu awizacji"
      },
      "ADVICE_UPDATED": {
        "desc": "Powiadomienie wysyłane do zainteresowanych (np. jeżeli twórcą awizacji jest dostawca\\\\odbiorca, e-mail zostanie wysłany do logistyka oraz przewoźników) po zmianie awizacji.",
        "label": "Zmiana awizacji: {transportTypeName}",
        "labelShort": "Zmiana awizacji"
      },
      "ADVICE_UPDATED_SMS": {
        "labelShort": "Powiadomienie SMS do kierowcy po edycji awizacji"
      },
      "ADVICE_VERIFICATION": {
        "labelShort": "Powiadomienie o weryfikacji awizacji"
      },
      "AUCTION_ENDED_WITHOUT_OFFERS": {
        "desc": "Powiadomienie wysyłane gdy aukcja zakończy sie bez ofert, do użytkowników, którzy mogą wystawiać zlecenie na aukcji.",
        "label": "Aukcja zakończona bez ofert: {transportTypeName}",
        "labelShort": "Aukcja zakończona bez ofert"
      },
      "AUCTION_LOST": {
        "desc": "Powiadomienie wysyłane w przypadku przegrania aukcji.",
        "label": "Przegrana aukcja: {transportTypeName}",
        "labelShort": "Przegrana aukcja"
      },
      "AUCTION_REMINDER": {
        "desc": "Powiadomienie wysyłane w celu przypomnienia o zbliżającym się końcu aukcji.",
        "label": "Przypomnienie o końcu aukcji: {transportTypeName}",
        "labelShort": "Przypomnienie o końcu aukcji"
      },
      "AUCTION_WON": {
        "desc": "Powiadomienie wysyłane w przypadku wygrania aukcji.",
        "label": "Wygrana aukcja: {transportTypeName}",
        "labelShort": "Wygrana aukcja"
      },
      "CARRIER_CHOSEN": {
        "desc": "Powiadomienie wysyłane, gdy przewoźnik został wybrany lub zmieniony",
        "label": "Przewoźnik został wybrany: {transportTypeName}",
        "labelShort": "Przewoźnik został wybrany"
      },
      "CHECKPOINT_CONFIRMED_BY_SMS": {
        "desc": "Powiadomienie SMS do kierowcy wysyłane w/g planowanej daty potwierdzenia danego statusu.",
        "label": "Powiadomienie SMS do kierowcy z prośbą o potwierdzenie statusu",
        "labelShort": "Powiadomienie SMS do kierowcy z prośbą o potwierdzenie statusu"
      },
      "CHECKPOINT_CONFIRMED_SMS": {
        "desc": "Powiadomienie SMS do kierowcy wysyłane po potwierdzeniu wybranego statusu",
        "label": "Powiadomienie SMS do kierowcy po potwierdzeniu statusu",
        "labelShort": "Powiadomienie SMS do kierowcy po potwierdzeniu statusu"
      },
      "CHECKPOINT_CONFIRMED_SMS_ASSOCIATED_WITH_USER": {
        "desc": "Powiadomienie SMS wysyłane do użytkownika po potwierdzeniu wybranego etapu",
        "label": "Powiadomienie SMS do użytkownika po potwierdzeniu etapu",
        "labelShort": "Powiadomienie SMS do użytkownika po potwierdzeniu etapu"
      },
      "CONFIRMED_CHECKPOINT": {
        "desc": "Powiadomienie wysyłane po zatwierdzeniu statusu {checkpointName}.",
        "label": "Zatwierdzenie statusu {checkpointName} dla transportu typu {transportTypeName}",
        "labelShort": "Zatwierdzenie statusu"
      },
      "DISCREPANCY_PROTOCOL_CREATED": {
        "desc": "Powiadomienie wysyłane po utworzeniu protokołu rozbieżności do użytkowników, którzy mają dostęp do zlecenia, do którego został dodany protokół.",
        "label": "Utworzenie protokołu rozbieżności dla {transportTypeName}",
        "labelShort": "Utworzenie protokołu rozbieżności"
      },
      "LATE_ADVICE": {
        "desc": "Powiadomienie wysyłane w przypadku opóźnienia przyjazdu kierowcy.",
        "label": "Opóźnienie awizacji: {transportTypeName}",
        "labelShort": "Opóźnienie awizacji"
      },
      "LATE_DOCUMENT_EMAIL": {
        "desc": "Powiadomienie wysyłane, jeżeli dostawca nie doda dokumentów przewozowych w odpowiednim terminie.",
        "label": "Opóźnienie w dodaniu dokumentów: {transportTypeName}, {checkpointName}",
        "labelShort": "Opóźnienie w dodaniu dokumentów"
      },
      "MODE_OF_TRANSPORTATION_UPDATED": {
        "desc": "Powiadomienie wysyłane po aktualizacji środka transportu",
        "label": "Aktualizacja środka transportu",
        "labelShort": "Aktualizacja środka transportu"
      },
      "NEW_ADDED_OFFER": {
        "desc": "Powiadomienie wysyłane po dodaniu nowej oferty przez przewoźnika.",
        "label": "Dodanie nowej oferty: {transportTypeName}",
        "labelShort": "Dodanie nowej oferty"
      },
      "NEW_AUCTION": {
        "desc": "Powiadomienie wysyłane do użytkowników, którzy mogą wystawiać zlecenie na aukcji oraz do użytkowników firm wybranych podczas jej tworzenia.",
        "label": "Nowa aukcja do transportu {transportTypeName}",
        "labelShort": "Nowa aukcja do transportu"
      },
      "NEW_MODE_OF_TRANSPORTATION": {
        "desc": "Powiadomienie wysyłane po dodaniu nowego środka transportu",
        "label": "Nowy środek transportu",
        "labelShort": "Nowy środek transportu"
      },
      "NEW_OFFER": {
        "desc": "Powiadomienie wysyłane do użytkowników firm wybranych podczas tworzenia zapytania ofertowego.",
        "label": "Nowe zapytanie ofertowe do transportu: {transportTypeName}",
        "labelShort": "Nowe zapytanie ofertowe"
      },
      "NEW_USER_IN_COMPANY": {
        "desc": "Powiadomienie wysyłane po dodaniu nowego użytkownika do firmy",
        "label": "Powiadomienie wysyłane po dodaniu nowego użytkownika do firmy",
        "labelShort": "Nowy użytkownik w firmie"
      },
      "OFFER_HAS_BEEN_OUTBID": {
        "desc": "Powiadomienie wysyłane gdy następuje zmiana na pierwszym miejscu w aukcji.",
        "label": "Oferta została przelicytowana: {transportTypeName}",
        "labelShort": "Oferta została przelicytowana"
      },
      "OFFER_REJECTED": {
        "desc": "Powiadomienie wysyłane do użytkowników firm wybranych podczas tworzenia zapytania ofertowego w przypadku niewybrania oferty",
        "label": "Niewybranie oferty w zapytaniu ofertowym: {transportTypeName}",
        "labelShort": "Niewybranie oferty w zapytaniu ofertowym"
      },
      "ORDER_PASSED": {
        "desc": "Powiadomienie wysyłane do użytkowników z firmy przewoźnika, gdy zlecenie zostanie przekazane do przewoźnika",
        "label": "Zlecenie zostało przekazane: {transportTypeName}",
        "labelShort": "Zlecenie zostało przekazane"
      },
      "ORDER_WAITING_FOR_CONFIRMATION": {
        "desc": "Powiadomienie wysyłane do użytkowników z firmy przewoźnika, gdy zlecenie zostanie przekazane do przewoźnika i czeka na jego akceptację",
        "label": "Zlecenie czeka na potwierdzenie: {transportTypeName}",
        "labelShort": "Zlecenie czeka na potwierdzenie"
      },
      "REJECTED_CHECKPOINT": {
        "desc": "Powiadomienie wysyłane po odrzuceniu statusu {checkpointName}.",
        "label": "Odrzucenie statusu {checkpointName} dla transportu typu {transportTypeName}",
        "labelShort": "Odrzucenie statusu"
      },
      "TIME_WINDOW_ADDED": {
        "desc": "Powiadomienie wysyłane po dodaniu okna czasowego do zlecenia",
        "label": "Utworzenie okna czasowego",
        "labelShort": "Utworzenie okna"
      },
      "TIME_WINDOW_EDITED": {
        "desc": "Powiadomienie wysyłane do użytkownika po edycji okna czasowego",
        "label": "Powiadomienie wysyłane po edycji okna czasowego",
        "labelShort": "Edycja okna czasowego"
      },
      "TRANSPORT_CREATED": {
        "desc": "Powiadomienie wysyłane do wykonawców po zapisaniu transportu.",
        "label": "Utworzenie transportu {transportTypeName}",
        "labelShort": "Utworzenie transportu"
      },
      "TRANSPORT_DELETED_SMS": {
        "labelShort": "Powiadomienie SMS po usunięciu zlecenia (awizacje mailowe)"
      },
      "roleGroups": {
        "desc": "Użytkownicy z daną rolą będą mogli otrzymywać dane powiadomienie oraz włączać/wyłączać chęć otrzymywania go w ustawieniach swojego konta.",
        "label": "Role użytkowników"
      }
    },
    "notificationLog": {
      "TYPE_EMAIL": "Wysłano e-mail",
      "TYPE_SMS": "Wysłano sms"
    },
    "notifications": {
      "add": "Dodaj powiadomienie",
      "appendTransportFiles": "Wyślij załączone dokumenty",
      "availableNotifications": "Dostępne powiadomienia",
      "edit": "Edycja",
      "label": "Powiadomienia",
      "legendAdviceUpdated": "Zmienne używane dla powiadomienia EDYCJA AWIZACJI",
      "legendCommon": "Zmienne ogólne",
      "legendDescription": "W treści SMS można korzystać ze zmiennych. Wystarczy w treści SMS wpisać nazwę zmiennej ograniczonej znakami \"%\". Przykładowe użycie zmiennej: %TRAILER_REG_NUM%.",
      "mailNotification": "Powiadomienie mailowe",
      "manualNotification": "Ręczne powiadomienie",
      "messageEn": "Treść w wersji angielskiej",
      "messageInputType": {
        "ADDRESS_CITY": "Miasto rozładunku",
        "CAR_REG_NUM": "Numer rejestracyjny pojazdu",
        "COMPANY_SHORT_NAME": "Skrócona nazwa firmy",
        "GATE": "Nazwa bramy w lokalizacji",
        "LOCATION": "Lokalizacja",
        "NEW_ADVICE_DATE": "Nowa data awizacji",
        "OLD_ADVICE_DATE": "Stara data awizacji",
        "OLD_REGISTRATION_NUMBER": "Stray numer rejestracyjny pojazdu",
        "PLACE_NAME": "Nazwa miejsca w kolejce",
        "QUEUE_NAME": "Nazwa kolejki",
        "RAMP_MESSAGE_EN": "Dodatkowa informacja z rampy przypisanej do transportu EN",
        "RAMP_MESSAGE_PL": "Dodatkowa informacja z rampy przypisanej do transportu PL",
        "RAMP_NUMBER": "Numer rampy",
        "SQUARE_NAME": "Nazwa placu",
        "TIME_WINDOW_DATES": "Daty okien czasowych",
        "TRAILER_REG_NUM": "Numer rejestracyjny naczepy/przyczepy",
        "TRANSPORT_ID": "Numer identyfikacyjny transportu"
      },
      "messagePl": "Treść w wersji polskiej",
      "new": "Nowe powiadomienie",
      "noNotificationsForRole": "Dla wybranej roli nie ma dostępnych powiadomień",
      "selectRole": "Proszę najpierw wybrać rolę.",
      "singularLabel": "Powiadomienie",
      "sureToDelete": "Jesteś pewien, że chcesz usunąć to powiadomienie?",
      "task": "Etap",
      "transportType": "Typ transportu"
    },
    "offer": {
      "auction": "Aukcja",
      "company": "Firma",
      "creator": "Użytkownik",
      "dateCreated": "Data dodania",
      "deleted": "Skasowana",
      "description": "Uwagi do oferty",
      "enabled": "Dostępna",
      "errors": {
        "auction": {
          "hasEnded": "Przepraszamy, aukcja do tego zlecenia jest już zakończona. Nie mogliśmy zapisać Twojej oferty."
        },
        "price": {
          "higherThanMaximum": "Podana cena jest wyższa od maksymalnej ceny dla tego zlecenia ustalonej przez zleceniodawcę.",
          "lowerThanMinimum": "Podana cena jest niższa od minimalnej ceny dla tego zlecenia ustalonej przez zleceniodawcę.",
          "nonPositiveNumber": "Proponowana stawka musi być liczbą większą od zera.",
          "reductionNotMet": "Przepraszamy, ale zgodnie z wymogiem firmy {principalCompanyName}, minimalna kwota, o jaką należy obniżyć poprzednią ofertę wynosi {minReductionSize} {currencySymbol}."
        }
      },
      "hasWon": "Wygrana",
      "label": "Oferta",
      "place": "miejsce",
      "price": "Twoja oferta",
      "revoke": {
        "alert": "Czy na pewno chcesz anulować złożoną ofertę do tego zlecenia?",
        "fail": {
          "CannotRevokeOffer": "Nie możesz anulować tej oferty."
        },
        "label": "Anuluj złożoną ofertę",
        "success": "Oferta została anulowana."
      },
      "save": "Zapisz ofertę",
      "savingInProgress": "Zapisujemy ofertę..",
      "valid": "Ważna"
    },
    "packageTypes": {
      "CARTONS": {
        "label": "Kartony",
        "lowerCase": "kartony",
        "singular": "karton"
      },
      "CHEP_PALLETS": {
        "label": "Palety CHEP",
        "lowerCase": "palety CHEP",
        "singular": "Paleta CHEP"
      },
      "DISPOSABLE_PALLETS": {
        "label": "Palety jednorazowe",
        "lowerCase": "palety jednorazowe",
        "singular": "Paleta jednorazowa"
      },
      "EURO_PALLETS": {
        "label": "Palety EURO",
        "lowerCase": "palety EURO",
        "singular": "Paleta EURO"
      },
      "LOADING_PLACES": {
        "label": "Miejsca ładowne",
        "lowerCase": "miejsca ładowne",
        "singular": "Miejsce ładowne"
      },
      "PALLETS": {
        "label": "Palety",
        "lowerCase": "palety",
        "singular": "paleta"
      },
      "PALLET_PLACES": {
        "label": "Miejsca paletowe",
        "lowerCase": "miejsca paletowe",
        "singular": "Miejsce paletowe"
      },
      "THE_NUMBER_OF_ITEMS": {
        "label": "Sztuki",
        "lowerCase": "sztuki",
        "singular": "Sztuka"
      },
      "noRelationsWithPackageTypes": "Brak powiązań typu transportu z opakowaniami",
      "referenceQuantity": "Ilość przeliczona na {referencePackage}: {referenceQuantity}",
      "transportTypes": {
        "confirmDelete": "Jestes pewien, że chcesz usunąć powiązanie typu transportu z opakowaniem?",
        "defaultValue": "Wartość domyślna",
        "idx": "Kolejność wyświetlania",
        "minValue": "Minimalna dozwolona wartość",
        "multiplier": "{package} per {referencePackage}",
        "packageType": "Typ opakowania",
        "referencePackage": "Uwaga! Edytujesz ustawienia opakowania referencyjnego!",
        "referencePackageChkbox": "Opakowanie referencyjne",
        "required": "Wymagane",
        "transportType": "Typ transportu",
        "usedForMaxPalletLimitChkbox": "Uwzględniaj przy limicie",
        "visibleInContractorStep": "Widoczne w etapie kontrahenta",
        "visibleInLoadPackageQuantityInTransportTable": "Widoczność w tabeli ilości załadunkowej",
        "visibleInUnloadPackageQuantityInTransportTable": "Widoczność w tabeli ilości rozładunkowej",
        "visibleInWarehouseStep": "Widoczne w etapie magazynowym"
      }
    },
    "page": {
      "pageTitle": "Platforma logistyczna"
    },
    "paidFeaturesInfo": {
      "authorities": {
        "UNLIMITED_ACCESS_TO_ARCHIVED_TRANSPORTS": "Aktualny pakiet Twojej firmy uwzględnia dostęp do zleceń z ostatnich <b>3 miesięcy</b>."
      },
      "label": "Zachęcamy do zapoznania się z dostępnymi pakietami dla firm"
    },
    "partnership": {
      "ANTI_CORRUPTION_TYPE": "Oświadczenie o Przeciwdziałaniu Korupcji",
      "CONTRACTOR_GROUP_ADDED": "Dodano grupę",
      "CONTRACTOR_GROUP_REMOVED": "Usunięto grupę",
      "GROUPS": "Grupowanie",
      "INSURANCE_DOMESTIC_OCP": "Ubezpieczenie OCP w ruchu krajowym",
      "INSURANCE_INTERNATIONAL_OCP": "Ubezpieczenie OCP w ruchu międzynarodowym",
      "INSURANCE_OC": "Ubezpieczenie OC przedsiębiorcy",
      "INSURANCE_OCS": "Ubezpieczenie OCS",
      "LICENCE_DOMESTIC": "Licencja Krajowa",
      "LICENCE_FORWARDING": "Licencja na pośrednictwo przy przewozie rzeczy",
      "LICENCE_INTERNATIONAL": "Licencja Międzynarodowa",
      "MAIN_DATA_TYPE": "Sekcja danych głównych",
      "SECONDARY_DATA_TYPE": "Sekcja danych dodatkowych",
      "STATUS_CHANGED": "Zmiana statusu",
      "TYPE_EMAIL": "Wysłano e-mail",
      "add": {
        "hint": "Przycisk umożliwia dodanie powiązania pomiędzy dostawcą a przewoźnikiem.",
        "label": "Dodaj powiązanie",
        "title": "Dodaj partnerstwo między kontrahentami"
      },
      "additionalDetails": "Dane dodatkowe",
      "adviceVerification": {
        "firstPartLabel": "Wyślij e-mail o weryfikację danych",
        "secondPartLabel": "przed każdą awizacją"
      },
      "allowsPeriodicTransport": "Awizacje okresowe",
      "auxiliary": {
        "editionDisabled": "Nie możesz jej edytować.",
        "title": "Firma {contractorName} działa jako kontrahent firmy {principalCompanyName}.",
        "usersEditionDisabled": "Nie możesz edytować jej użytkowników."
      },
      "connectAll": {
        "CARRIER": "przewoźnikami",
        "SUPPLIER": "dostawcami",
        "SUPPLIER.CLIENT": "klientami",
        "cofirmMsg": "Czy na pewno chcesz powiązać firmę {companyName} ze wszystkimi {contractorsType} w ramach firmy {principalCompanyName}?",
        "label": "Powiąż wszystkich kontrahentów",
        "success": "Powiązania zostały dodane"
      },
      "contractor": "Kontrahent",
      "contractorOfCompany": "firmy {companyName}",
      "dateCreated": "Data powiązania",
      "deleteMsg": "Czy na pewno chcesz usunąć to powiązanie pomiędzy kontrahentami?",
      "errors": {
        "additionalDetails": {
          "length": "Maksymalna liczba znaków to 1000"
        },
        "adviceVerificationInterval": {
          "intervalFormatError": "Błędny format czasu - należy podać liczbę, a następnie jednostkę czasu - s (sekundy), m (minuty), h (godziny) lub d (dni), np. 48h"
        },
        "contractorShortName": {
          "contractorShortNameTooLong": "Nazwa skrócona jest zbyt długa!"
        },
        "representativeEmail": {
          "email": "Proszę poddać poprawny adres email"
        },
        "representativePhoneNumber": {
          "regex": "Numer telefonu w Polsce musi się składać z dziewięciu cyfr"
        },
        "transportLimit": {
          "invalidNumber": "Limit transportów musi być liczbą naturalną."
        }
      },
      "logs": {
        "CONTRACTOR_GROUP_ADDED": "Dodano grupę",
        "CONTRACTOR_GROUP_REMOVED": "Usunięto grupę",
        "DOCUMENT_DISABLED": "Wyłączono dokument",
        "DOCUMENT_SAVED": "Zapisano dokument",
        "DOCUMENT_UPDATED": "Zaktualizowano dokument",
        "FILE_ADDED": "Dodano plik",
        "FILE_DELETED": "Usunięto plik",
        "SECTION_CONFIRMED": "Potwierdzono sekcję",
        "SECTION_REJECTED": "Odrzucono sekcję",
        "SECTION_SAVED": "Zapisano sekcję",
        "SECTION_UPDATED": "Zaktualizowano sekcję",
        "STATUS_CHANGED": "Zmienił się status zgłoszenia",
        "TYPE_EMAIL": "Powiadomienie email"
      },
      "representativeEmail": "Przedstawiciel - e-mail",
      "representativeName": "Przedstawiciel",
      "representativePhoneNumber": "Przedstawiciel - telefon",
      "transportLimit": "Limit transportów",
      "transportLimitHint": "W przypadku braku limitu, pozostaw puste pole",
      "transportsCompletedCount": "W tym zakończone",
      "transportsCreatedCount": "Transporty utworzone",
      "transportsInProgressCount": "Transporty w realizacji"
    },
    "partnerships": {
      "label": "Partnerstwa"
    },
    "periodicTransport": {
      "cannotChangeCompany": "Nie możesz zmienić tej firmy - w zleceniu musi byc co najmniej jedna firma z włączoną możliwością tworzenia awizacji okresowych",
      "cannotDeleteStep": "Nie możesz usunąć tego etapu - w zleceniu musi byc co najmniej jedna firma z włączoną możliwością tworzenia awizacji okresowych",
      "deleteAllConfirmMsg": "Czy usunąć wszystkie awizacje okresowych powielone w dalszym okresie? Naciśnięcie \"Anuluj\" usunie tylko to zlecenie",
      "errors": {
        "chooseAtLeastOneDay": "Wybierz co najmniej jeden dzień",
        "chooseFirstDayOfPeriodicTransport": "Wybierz dzień, w którym wypada pierwsza awizacja okresowa",
        "endDateMustBeAfterStartDate": "Data końca zakresu musi być późniejsza niż data początku",
        "incorrectRangeOption": "Niedozwolony zakres - możliwe wartości to \"Codziennie\", \"Co tydzień\" lub \"Co miesiąc\"",
        "windowMustBeInFirstDayOfPeriodicTransport": "Pierwszy dzień awizacji okresowej musi przypadać w tym samym dniu, co planowana data awizacji",
        "wrongDateRange": "Dla wybranej opcji \"{range}\" maksymalny zakres dat dla awizacji okresowej to {interval}. Ustaw mniejszy zakres dat dla awizacji okresowej."
      },
      "frequencies": {
        "DAILY": "Codziennie",
        "MONTHLY": "Co miesiąc",
        "WEEKLY": "Co tydzień"
      },
      "frequency": "Okresowość awizacji",
      "label": "Awizacja okresowa",
      "updateAllConfirmMsg": "Czy zapisać zmiany we wszystkich awizacjach okresowych powielonych w dalszym okresie? Naciśnięcie \"Anuluj\" zapisze zmiany tylko w tym zleceniu"
    },
    "pricingPlans": {
      "BASIC": "BASIC",
      "CLIENT": "KLIENT",
      "OPERATOR": "OPERATOR",
      "PRO": "PRO"
    },
    "product": {
      "INDEX": {
        "name": "Nazwa produktu",
        "name.autoCompletePlaceholder": "Zacznij pisać, by wyszukać produkt po nazwie"
      },
      "ORDER": {
        "name": "Rodzaj zamówienia",
        "name.autoCompletePlaceholder": "Zacznij pisać, by wyszukać po rodzaju zamówienia"
      },
      "add": "Dodaj produkt",
      "disableConfirm": "Na pewno chcesz usunąć ten produkt?",
      "disabled": "Poprawnie usunęliśmy produkt {index}.",
      "errors": {
        "index": {
          "tooLong": "Wprowadzony tekst jest zbyt długi. Dopuszczalna długość to 255 znaków."
        },
        "name": {
          "tooLong": "Wprowadzony tekst jest zbyt długi. Dopuszczalna długość to 255 znaków."
        },
        "shortName": {
          "tooLong": "Wprowadzony tekst jest zbyt długi. Dopuszczalna długość to 255 znaków."
        }
      },
      "index": "Indeks",
      "index.autoCompletePlaceholder": "Zacznij pisać, by wyszukać produkt po indeksie",
      "saved": "Poprawnie zapisaliśmy produkt {index}.",
      "shortName": "Skrócona nazwa",
      "shortName.autoCompletePlaceholder": "Zacznij pisać, by wyszukać produkt po skróconej nazwie",
      "unit": "Jednostka",
      "updated": "Poprawnie zaktualizowaliśmy produkt {index}."
    },
    "products": {
      "INDEX": {
        "label": "Produkty",
        "table": "Tabela produktów"
      },
      "ORDER": {
        "label": "Produkty",
        "table": "Tabela produktów"
      }
    },
    "queues": {
      "disableConfirm": "Na pewno chcesz wyłączyć tę kolejkę?",
      "englishName": "Angielska nazwa",
      "idx": "Kolejność",
      "updateSuccessMessage": "Poprawnie zapisaliśmy zmiany w ustawieniach kolejki {queueName}"
    },
    "queuesStatus": {
      "sendSmsModal": {
        "messagePlaceholder": "Wpisz treść wiadomości",
        "title": "SMS do kierowców z kolejki:"
      },
      "tableHeader": "Podsumowanie aktualnego stanu kolejek"
    },
    "ramp": {
      "additionalSmsNotificationInfoEn": "Dodatkowe informacje EN",
      "additionalSmsNotificationInfoPl": "Dodatkowe informacje PL",
      "backToWarehouse": "Powrót do magazynu",
      "deleteDedicatedWindows": "Usuwaj niewykorzystane/wykorzystane okna dedykowane",
      "disableConfirm": "Na pewno chcesz wyłączyć tę rampę?",
      "disabledMessage": "Poprawnie usunęliśmy rampę \"{rampName}\".",
      "enableConfirm": "Na pewno chcesz włączyć tę rampę?",
      "enabledMessage": "Poprawnie włączyliśmy rampę \"{rampName}\".",
      "errors": {
        "cannotMoveWindowException": "Nie możesz przesunąć okna we wskazane miejsce",
        "idx": {
          "idxTooBig": "Kolejność rampy w magazynie nie może być większa niż liczba ramp tego magazynu.",
          "nonPositiveInteger": "Kolejność rampy w magazynie musi być dodatnią liczbą całkowitą."
        },
        "shortName": {
          "sizeTooBig": "Maksymalna długość nazwy skróconej to 10 znaków."
        },
        "wrongSwappedWindowSize": "Zamieniane okna czasowe mają różne czasy trwania."
      },
      "idx": "Kolejność",
      "label": "Rampa",
      "maxPallets": "Limit palet",
      "roleGroupsLabel": "Role użytkowników, dla których rampa będzie dostępna",
      "updateSuccessMessage": "Poprawnie zapisaliśmy zmiany ustawień rampy.",
      "wantToSwapWindow": "Czy chcesz zamienić okna czasowe miejscami?"
    },
    "ramps": {
      "label": "Rampy"
    },
    "reports": {
      "contractors": {
        "header": "Wygeneruj raport dla transportów, których załadunek/rozładunek odbył się między",
        "label": "Raport z okien czasowych"
      },
      "label": "Raporty"
    },
    "resetPasswordWindow": {
      "enteredPasswordsIsNotIdentical": "Wprowadzone hasła muszą być identyczne.",
      "header": "Zmiana hasła logowania do systemu",
      "info": "Reset hasła jest wymagany przez politykę bezpieczeństwa systemu.",
      "info2": "Nowe hasło musi być różne od wcześniej wprowadzonego oraz powinno zawierać 8 znaków (cyfry oraz małe i wielkie litery)."
    },
    "roleGroup": {
      "label": "Rola",
      "placeholder": "Wybierz rolę"
    },
    "route": {
      "addNewRate": "Dodaj nową stawkę",
      "addNewRoute": "Dodaj nową trasę",
      "address": {
        "city": "Miasto",
        "country": "Kraj",
        "load": "Załadunek",
        "postal": "Kod pocztowy",
        "unload": "Rozładunek"
      },
      "addresses": "Adresy",
      "bestOffer": "Najlepsza oferta",
      "details": "Szczegóły trasy",
      "errors": {
        "description": {
          "length": "Wprowadzony tekst jest zbyt długi. Dopuszczalna długość to 255 znaków."
        }
      },
      "label": "Trasa",
      "newRoute": "Nowa trasa",
      "noTransportTypeAvailable": "Nie masz obecnie dostępu do żadnego typu transportu z cennikiem",
      "offer": {
        "carrier": "Przewoźnik",
        "confirmation": {
          "failure": "Niestety nie udało nam się potwierdzić oferty. Skontaktuj się z administratorem systemu.",
          "message": "Na pewno chcesz potwierdzić tę ofertę?"
        },
        "confirmed": "Potwierdzona",
        "date": "Data dodania",
        "deleteMessage": "Czy na pewno chcesz usunąć tę ofertę?",
        "edition": "Edycja",
        "number": "Numer",
        "price": "Stawka",
        "showLess": "Pokaż mniej ofert ({n})",
        "showMore": "Pokaż więcej ofert ({n})",
        "toggleTable": "Pokaż lub ukryj listę ofert",
        "validity": "Ważność"
      },
      "rate": "Stawka",
      "rates": {
        "label": "Stawki"
      },
      "sureToDelete": "Czy na pewno chcesz usunąć te trasę?",
      "termOfValidity": "Termin ważności"
    },
    "secondary-section": {
      "bankAccountNumber": "Numer konta bankowego",
      "bankFilePattern": {
        "label": "Wzór formularza"
      },
      "errors": {},
      "internalNotes": "Notatki wewnętrzne",
      "label": "Dodatkowe dane",
      "otherAttachments": "Inne załączniki"
    },
    "section": {
      "accept": "Akceptuj",
      "dateOfArchiving": "Data archiwizacji",
      "dateOfDeletion": "Data usunięcia",
      "dateOfExpiration": "Data przedawnienia",
      "deleted": "Usunięta",
      "disable": "Wyłącz",
      "disabled": "Wyłączona",
      "expired": "Przedawniona",
      "label": "sekcja",
      "reject": "Odrzuć",
      "requestVerification": "Wyślij prośbę o weryfikację",
      "state": {
        "ACCEPTED": "Zaakceptowana",
        "ARCHIVED": "Zarchiwizowana",
        "DELETED": "Usunięta",
        "DOCUMENT_ADDED": "Zaktualizowana",
        "EXPIRED": "Brak ważności",
        "PENDING": "Oczekuje na reakcję",
        "REJECTED": "Odrzucona",
        "UPDATED": "Zaktualizowana",
        "label": "Status sekcji"
      },
      "successMessage": "Pomyślnie zapisaliśmy sekcję",
      "update": "Zaktualizuj"
    },
    "section-action": {
      "noCommentError": "Proszę podać powód odrzucenia",
      "rejectionComment": "Podaj powód, dla którego odrzucasz tę sekcję"
    },
    "sectionType": {
      "ANTI_CORRUPTION_TYPE": "Oświadczenie o Przeciwdziałaniu Korupcji",
      "BLOCKADES": "Aktywne blokady",
      "GROUPS": "Grupowanie",
      "HISTORY": "Historia",
      "INSURANCE": "Ubezpieczenie",
      "INSURANCE_DOMESTIC_OCP": "Ubezpieczenie OCP w ruchu krajowym",
      "INSURANCE_INTERNATIONAL_OCP": "Ubezpieczenie OCP w ruchu międzynarodowym",
      "INSURANCE_OC": "Ubezpieczenie OC przedsiębiorcy",
      "INSURANCE_OCS": "Ubezpieczenie OCS",
      "LICENCE": "Licencja",
      "LICENCE_DOMESTIC": "Licencja Krajowa",
      "LICENCE_FORWARDING": "Licencja na pośrednictwo przy przewozie rzeczy",
      "LICENCE_INTERNATIONAL": "Licencja Międzynarodowa",
      "MAIN_DATA_TYPE": "Dane główne",
      "SECONDARY_DATA_TYPE": "Dodatkowe dane",
      "SENSOR_CALIBRATION_CERTIFICATE": "Świadectwo kalibracji czujników",
      "SUMMER_MAPPING": "Mapowanie letnie",
      "USERS": "Użytkownicy",
      "VEHICLE_MAIN_DATA_TYPE": "Dane środka transportu",
      "WINTER_MAPPING": "Mapowanie zimowe"
    },
    "service": {
      "arrival": "Wjazd",
      "company": "Firma",
      "contractorCompanyPlaceholder": "Nazwa firmy",
      "createTitle": "Zaplanuj serwis",
      "departure": "Wyjazd",
      "description": "Opis prac",
      "registrationNumber": "Numer rejestracyjny",
      "selectCompany": "Wybierz firmę",
      "serviceman": "Nazwisko serwisanta",
      "title": "Serwis #%@1"
    },
    "serviceNotWorking": {
      "content": "Platforma jest chwilowo niedostępna. Prosimy spróbować później. Za utrudnienia przepraszamy.",
      "header": "Szanowni Państwo",
      "signature": "Zespół Logintegra Sp. z o.o."
    },
    "services": {
      "add": "Zaplanuj serwis",
      "label": "Serwisy"
    },
    "settings": {
      "backToSettings": "Powrót do listy ustawień",
      "confirmDelete": "Czy na pewno chcesz usunąć to ustawienie?",
      "create": "Dodaj ustawienie",
      "desc": {
        "ADDITIONAL_ADD_FILE_BUTTON": "Dodatkowy klawisz do dodawania dokumentów w formularzu zlecenia",
        "ADDITIONAL_CONFIRMATION_DURING_REMOVING_GRAY_WINDOW": "Dodatkowe potwierdzenie przy usuwaniu szarego okna blokady",
        "ADDITIONAL_INFO_ON_PLAIN_TEMPLATE_VIEW": "Dodatkowa informacja wyświetlana w widoku wydruku formularza zlecenia",
        "ADDITIONAL_INFO_ON_PLAIN_TEMPLATE_VIEW_EN": "Dodatkowa informacja wyświetlana w widoku wydruku formularza zlecenia - wersja angielska",
        "ADD_FILE_TO_TRANSPORT_AFTER_OFFER_ACCEPTATION": "Określa, czy plik powinien zostać dodany do transportu po wygraniu aukcji, lub przekazaniu zlecenia do przewoźnika",
        "ADD_FILE_TO_TRANSPORT_AFTER_OFFER_ACCEPTATION_NAME": "Nazwa pliku dołączonego do transportu po wygraniu aukcji, lub przekazaniu zlecenia do przewoźnika",
        "ADVICE_CREATED_EMAIL_FILE_NAME": "Wersja maila o dodaniu awizacji",
        "ADVICE_CREATED_EMAIL_SUBJECT": "Temat maila o dodaniu awizacji",
        "ADVICE_UPDATED_EMAIL_FILE_NAME": "Wersja maila o edycji awizacji",
        "ADVICE_VERIFICATION_EMAIL_ENABLED": "Określa, czy pola związane z zarządzaniem wysyłania wiadomości e-mail odnośnie weryfikacji danych awizacji są widoczne w szczegółach firmy",
        "ALT_IDENTIFIER_LABEL": "Etykieta kolumny z numerem zlecenia",
        "ALT_IDENTIFIER_LABEL_EN": "Etykieta kolumny z numerem zlecenia - w języku angielskim",
        "ALT_IDENTIFIER_RESET_INTERVAL": "Częstotliwość resetowania sekwencji automatycznie generowanego numeru \"altIdentifier\"",
        "ANNOUNCEMENTS_COUNTER_POLL_INTERVAL": "Częstotliwość odświeżania danych w ms - licznik ogłoszeń",
        "API_MESSAGE_URL": "Adres wysyłki komunikatów API",
        "ARCHIVING_JOB_INTERVAL": "Ile czasu po potwierdzeniu ostatniego statusu powinniśmy przenieść transport do archiwum.",
        "ASSORTMENT_GROUP_LABEL": "Etykieta pola formularza określającego grupę magazynową",
        "ASSORTMENT_GROUP_LABEL_EN": "Etykieta pola formularza określającego grupę magazynową - w języku angielskim",
        "AUCTION_ENDED_WITHOUT_OFFERS_EMAIL_FILE_NAME": "Wersja maila o zakończeniu aukcji bez ofert",
        "AUTOMATIC_DELETING_DEDICATED_WINDOWS_INTERVAL": "Kiedy, w porównaniu z ich godziną w harmonogramie, powinny być usuwane nieobsadzone okna dedykowane (w przypadku zaznaczenia opcji “Automatycznie usuwaj nieobsadzone/obsadzone okna dedykowane”). Na przykład dla wartości “-2h”, nieobsadzone okno dedykowane z godziny 16:00 zostanie usunięte o 14:00.",
        "AUTOMATIC_DELETING_UNUSED_DEDICATED_WINDOWS_ENABLED": "Automatycznie usuwaj nieobsadzone okna dedykowane",
        "AUTOMATIC_DELETING_USED_DEDICATED_WINDOWS_ENABLED": "Automatycznie usuwaj obsadzone okna dedykowane",
        "AUTOMATIC_GENERATE_DOCUMENT": "Określa czy powinniśmy pokazywać flagę shouldGenerateDocument",
        "AUTOMATIC_WEIGHING": "Włączenie funkcjonalności automatycznego ważenia",
        "AUTO_GENERATED_DELIVERY_NOTE_FIXED_TEXT": "Stały tekst wyświetlany na autmotycznie generowanym dokumencie dostawy",
        "AVAILABLE_LOGIN_PAGE_BACKGROUND_FILE_NAMES": "Nazwy plików z dostępnymi tłami logowania dla pryncypała",
        "CAN_ARRIVAL_DATE_BE_IN_THE_PAST": "Określa, czy data dostawy może znajdować się w przeszłości",
        "CAN_EXPORT_INDEXES_TO_XLS": "Włącza możliwość eksportu indeksów do pliku Excel",
        "CAN_REFRESH_MANUALLY": "Możliwość ręcznego odświeżania danych",
        "CHANGE_TRANSPORT_INTERVAL": "Do jakiego momentu w stosunku do planowanej daty załadunku/rozładunku powinna być możliwa zmiana transportu przez dostawcę/przewoźnika",
        "CHECKPOINT_CONFIRMED_EMAIL_FILE_NAME": "Wersja maila o potwierdzeniu statusu",
        "CHECKPOINT_REJECTED_EMAIL_FILE_NAME": "Wersja maila o odrzuceniu statusu",
        "CLASSIFICATIONS_TAB_POLL_INTERVAL": "Częstotliwość odświeżania danych w ms - zakładka \"Klasyfikacja\"",
        "CLASSIFICATION_RESOURCE_COMPLETED_DELAY": "Określa po jakim czasie ma znikać obiekt z kolejki w zakładce \"Kolejki\" jeśli nie został potwierdzony jeszcze status Wyjazdu",
        "CLASSIFIED_RESOURCE_DISAPPEARANCE_DELAY": "Określa, po jakim czasie sklasyfikowane transporty będą znikać z tabeli w zakładce \"Klasyfikacja\"",
        "COMPANY_FORM_SHOW_REPRESENTATIVE": "Widoczność pól związanych z przestawicielem w formularzu firmy.",
        "CONTRACTOR_NUMBER_LABEL": "Nazwa dla pola określającego numer kontrahenta",
        "CONTRACTOR_NUMBER_LABEL_EN": "Nazwa dla pola określającego numer kontrahenta - w języku angielskim",
        "CUSTOM_FIELD_SPLIT_VALUE": "Ilość zdefiniowanych pól niestandardowych, powyżej której pola te będą podzielone na dwie kolumny w formularzu zlecenia",
        "DAYS_TO_RESET_PASSWORD": "Co ile dni powinien się pojawiać komunikat o konieczności resetu hasła.",
        "DAYS_TO_SHOW_ADVICE_HISTORY": "Z ilu ostatnich dni powinniśmy wyświetlać dane kierowców na liście podpowiedzi podczas tworzenia/edycji awizacji?",
        "DEFAULT_ADVICE_FUTURE_DAYS_LIMIT": "Domyślna wartość ograniczenia dni dostępnych na awizację przypisywana nowym kontrahentom.",
        "DEFAULT_EMAIL_DELAY_INTERVAL": "Domyślne opóźnienie w wysyłaniu maili",
        "DEFAULT_NUMBER_OF_DISPLAYED_OFFERS": "Domyślna liczba ofert dla trasy w cenniku",
        "DEFAULT_TAB_AFTER_LOGIN": "Domyślna zakładka, wyświetlana po zalogowaniu",
        "DELAY_TIME_TO_MOVE_ORDER_TO_ARCHIVE_AFTER_TASK_TIMEOUT": "Czas opóźnienia, po którym zlecenie jest automatycznie przenoszone do archiwum (tylko dla wskazanych etapów)",
        "DELAY_TIME_TO_MOVE_TIME_WINDOW_TO_NEXT_FREE_SPOT": "Ilość czasu opóźnienia, po upływie którego platforma powinna przenieść okno na najbliższe możliwe okno",
        "DISPLAY_INDEXES_IN_SCHEDULE": "Wyświetlanie listy indeksów w widoku harmonogramu",
        "DISPLAY_TRAILER_REG_NUMBER_IN_ADVICE_COLUMN": "W kolumnie \"Awizacja\" wyświetlamy numer naczepy zamiast numeru ciągnika",
        "DOCUMENTS_MONITORING_INTERVAL": "Ile czasu przed planowaną datą potwierdzenia statusu powinien być wysłany mail o opóźnieniu w dodawaniu dokumentów do zlecenia",
        "DOCUMENT_SECTION_VISIBLE_IN_TRANSPORT_FORM": "Widoczność sekcji Dokumenty w formularzu transportu",
        "DRIVER_PUNCTUALITY_INTERVAL": "Czas względem początku okna czasowego określający nieterminowość potwierdzenia etapu",
        "EMAIL_NOTIFICATION_CONTAINS_PRODUCTS_SECTION": "Określa, czy w mailach ma być widoczna sekcja z produktami",
        "EMAIL_NOTIFICATION_CONTAINS_UNLOAD_SECTION": "Określa, czy w mailach ma być widoczna sekcja z rozładunkami",
        "ENABLE_TRANSPORT_ROW_HIGHLIGHTING": "Określa, czy wiersz transportu powinien się podświetlić po kliknięciu",
        "ENABLE_TRANSPORT_TABLE_DAYS_LIMIT": "Włącza filtr w tabeli zleceń aktywnych ukrywający zlecenia posiadające okna czasowe później niż określona data",
        "END_OF_DAY_IN_WAREHOUSE_INTERVAL": "Godzina końca dnia w magazynie wykorzystywana przy liczeniu okien czasowych awizacji. Uwaga! Koniec pracy magazynu może być inny. Przykład: Aby ustawić koniec dnia na 6:00 dnia następnego wartość parametru powinna być równa 30h [30.01. 00:00 + 30h = 31.01. 06:00]",
        "ERP_IDENTIFIER_LABEL": "Etykieta kolumny z numerem erp zlecenia",
        "ERP_IDENTIFIER_LABEL_EN": "Etykieta kolumny z numerem erp zlecenia - w języku angielskim",
        "FILE_TERMS_OF_CONDITIONS_ACCEPTED": "Link do warunków usługi.",
        "FIND_ADDRESSES_MAX": "Ilość adresów zwracana przez mechanizm auto-complete",
        "FOOTER": "Link do stopki wyświetlanej w platformie",
        "FOREIGN_DELIVERY_MULTIPLIER": "Wartość przez którą mnożona będzie długość rozładunku dla dostaw zagranicznych.",
        "HAS_ACCESS_TO_APPLIES": "Określa, czy ma być weryfikacja dokumentów przewoźnika",
        "HAS_ACCESS_TO_MAX_PALLET_PER_DAY": "Określa, czy ma być włączony limit palet dla każdego dnia",
        "HAS_ACCESS_TO_MAX_PALLET_PER_RAMP": "Określa, czy ma być włączony limit palet dla poszczególnych ramp",
        "HAS_ACCESS_TO_NUMBER_OF_WINDOWS": "Określa, czy wyświetlać w magazynie ilość okien dla danego dnia. Zależy od początku i końca dnia w magazynie.",
        "HD_MODE_NEXT_DAY_SHIFT_TIME": "Określa, o której godzinie harmonogram w widoku HD ma przesuwać się na kolejny dzień (w formacie HH:mm)",
        "HOW_MANY_DAYS_BEFORE_CERTIFICATE_EXPIRATION_SHOULD_MAIL_BE_SENT": "Ile czasu przed końcem ważności świadectwa kalibracji czujników powinno być wysłane powiadomienie mailowe z przypomnieniem",
        "HOW_MANY_DAYS_BEFORE_DOCUMENT_EXPIRATION_DATE_CARRIER_SHOULD_BE_BLOCKED": "Ile dni przed końcem daty ważności dokumentu ma nastąpić blokada przewoźnika?",
        "HOW_MANY_DAYS_BEFORE_INSURANCE_EXPIRATION_SHOULD_MAIL_BE_SENT": "Ile dni przed końcem ważności ubezpieczenia powinno być wysyłane do przewoźnika powiadomienie mailowe z przypomnieniem",
        "HOW_MANY_DAYS_BEFORE_MAPPING_EXPIRATION_SHOULD_MAIL_BE_SENT": "Ile czasu przed końcem ważności mapowania powinno być wysłane powiadomienie mailowe z przypomnieniem",
        "HOW_MUCH_TIME_BEFORE_START_WINDOW_EDITING_ORDER_SENDS_SMS": "Ile czasu przed początkiem okna czasowego edycja awizacji ma powodować wysłanie SMS-a.",
        "HYPERLINK_TO_LOGO_ON_NAVBAR": "Hiperlink do logo, które jest wyświetlane po zalogowaniu w pasku nawigacyjnym",
        "INVITATION_EXPIRATION_INTERVAL": "Okres ważności zaproszenia.",
        "LATE_ADVICE_EMAIL_FILE_NAME": "Wersja maila o opóźnieniu",
        "LIMITED_INVITATION_MODE": "Użytkownicy - za wyjątkiem administratora, który może wszystko - mogą zapraszać tylko użytkowników dla swojej roli oraz kontrahentów",
        "LINK_TO_APPLIES_MANUAL": "Link do instrukcji zgłoszeń",
        "LOADING_URL": "Adres URL platformy",
        "LOCAL_PRINCIPAL_LANGUAGE": "Lokalny język firmy",
        "LOGO_ON_LOGIN_PAGE": "Link do logo, które jest wyświetlane na stronie logowania",
        "LOGO_ON_NAVBAR": "Link do logo, które jest wyświetlane po zalogowaniu w pasku nawigacyjnym",
        "MANAGE_TRANSPORT_INTERVAL": "Do jakiego momentu w stosunku do planowanej daty załadunku/rozładunku powinna być możliwość dodania i edycji awizacji",
        "MAXIMUM_NUMBER_OF_ORDERS_TO_COPY": "Maksymalna ilość zleceń, jaką można utworzyć za pomocą funkcji \"Utwórz podobne\"",
        "MAXIMUM_PERMISSIBLE_VEHICLE_WEIGHT_SETTING": "Maksymalna dopuszczalna masa pojazdu [t]",
        "MAX_CHARACTERS_IN_INDEX_PRODUCTS_COLUMN": "Ilość wyświetlanych znaków w kolumnie z produktami",
        "MAX_CHARACTERS_IN_ORDER_NUMBER_COLUMN": "Ilość wyświetlanych znaków w kolumnach z numerami zleceń",
        "MAX_NUMBER_OF_TIME_WINDOWS_FOR_SUGGEST": "Maksymalna ilość sugerowanych okien czasowych",
        "MAX_SIMULTANEOUSLY_ADDED_FILES_COUNT": "Maksymalna liczba plików, które można jednocześnie dodać do zlecenia",
        "MAX_WINDOW_LENGTH": "Maksymalna dozwolona długość okna czasowego",
        "NAME_TERMS_OF_CONDITIONS_ACCEPTED": "Nazwa warunków usługi.",
        "NAME_TERMS_OF_CONDITIONS_ACCEPTED_EN": "Nazwa warunków usługi - w języku angielskim",
        "NEW_ADVICE_EMAIL_RECIPIENTS": "Dodatkowe adresy e-mail, na które wysyłane jest powiadomienie o utworzeniu nowej awizacji (oddzielane przecinkiem, np. xyz@xyz.com,abc@abc.com)",
        "NEW_AUCTION_EMAIL_FILE_NAME": "Wersja maila o nowej aukcji",
        "NEW_TRANSPORT_EMAIL_FILE_NAME": "Wersja maila o nowym zleceniu",
        "ORDER_FORM_PRINTING_ENABLED": "Określa, czy jest dostępna w formularzu zlecenia wersja do druku",
        "ORDER_WAITING_FOR_CONFIRMATION_EMAIL_FILE_NAME": "Wersja maila o przekazaniu zlecenia do przewoźnika",
        "OWN_ADDRESSES_ONLY": "Dostęp tylko do adresów wprowadzonych w danej przestrzeni pryncypała (nie dotyczy głównych adresów firm)",
        "PACKAGE_TYPES_FEATURE_ENABLED": "Widoczność zakładki do zarządzania ustawieniami opakowań",
        "PATRON_IN_CONTRACTOR_DETAILS_ENABLED": "Określa, czy pole \"Opiekun\" jest widoczne w szczegółach firmy",
        "PERCENTAGE_OF_TRANSPORTS_TAKEN_FOR_RANDOM_INSPECTIONS": "Jaki procent transportów powinien być typowany do losowych kontroli?",
        "POLL_INTERVAL": "Częstotliwość odświeżania danych w ms - tabela i harmonogram",
        "QUEUES_TAB_POLL_INTERVAL": "Częstotliwość odświeżania danych w ms - zakładka \"Kolejki\" oraz \"Stan kolejek\"",
        "RESET_PASSWORD_IS_DISABLED": "Wyłącz konieczność resetowania hasła.",
        "ROLES_WITH_ENABLED_USER_AND_GROUP_FILTER": "Lista ról, które mają włączone dodatkowe filtrowanie (moje zlecenia, zlecenia grupy, wszystkie zlecenia). Wartości podawane po przecinku, np. \"ADMIN,GUARD\".",
        "ROLE_ADD_CONTRACTOR_GROUP": "Możliwość dodania nowej grupy w sekcji grupowanie w szczegółach kontrahenta",
        "SCHEDULE_LEGEND_ENABLED": "Włączenie legendy w harmonogramie",
        "SEND_API_MESSAGE_EVERY_TRANSPORT_UPDATE": "Włącza wysyłkę komunikatów API po każdej aktualizacji transportu",
        "SEND_FUTURE_PERIODIC_TRANSPORTS_EDIT_MAIL_INTERVAL_IN_DAYS": "Ilość dni z przyszłości, dla których powinny być wysyłane powiadomienia mailowe w przypadku edycji awizacji okresowych",
        "SEND_SMS_AFTER_CREATING_ORDER": "Wysyłaj SMS do kierowcy po utworzeniu awizacji",
        "SEND_SMS_AFTER_EDITING_ORDER": "Wysyłaj SMS do kierowcy po edycji awizacji",
        "SET_SCHEDULE_LEGEND_TO_FIXED_POSITION": "Określa, czy legenda w harmonogramie powinna być zawsze na widoku",
        "SHOULD_RENDER_ALL_TRANSPORT_TYPES": "Domyślnie wyświetlaj wszystkie typy transportów w tabeli zleceń aktywnych",
        "SHOW_COMPANY_VOIVODESHIP_FIELD": "Widoczność pola \"Województwo\" w szczegółach firmy",
        "SHOW_PRINCIPAL_ADDRESS_IN_PRINT_VIEW": "Widoczność adresu pryncypała w formularzu \"Do druku\"",
        "SHOW_QUANTITY_SUM_CHECK_MESSAGE": "Wyświetl komunikat w przypadku przekroczenia X palet w zleceniu",
        "SHOW_TERMS_OF_CONDITIONS_ACCEPTED": "Pole akceptacji warunków usługi powinno być widoczne w formularzu awizacji",
        "SHOW_TRANSPORT_COMBINING_MODAL_ON_SINGLE_OPTION": "Pokazuje okienko łączenia transportów, nawet w przypadku tylko jednej możliwej opcji łączenia",
        "SHOW_USERS_FOR_SUPPLIER": "Włącza listę użytkowników w szczegółach dostawcy/odbiorcy",
        "SORT_TABLE_BY_TRANSPORT_COMPLETED": "Określa czy transporty w tabeli transportów będą domyślnie, w pierwszej kolejności sortowane po fladze statusie \"Zakończone\"",
        "START_OF_DAY_IN_WAREHOUSE_INTERVAL": "Godzina początku dnia w magazynie wykorzystywana przy liczeniu okien czasowych awizacji. Uwaga! Początek pracy magazynu może być inny.",
        "SUPPLIER_FIELD_IN_COMPANY_DETAILS_TRANSLATION_KEY": "Klucz tłumaczenia pola \"Dostawca / odbiorca\" w zakładce 'Firmy'. Możliwe wartości: 'CLIENT','COMPANY','COURIER'",
        "TERMS_OF_SERVICE_FILE_EXT": "Rozszerzenie pliku warunków usługi, np. docx, pdf",
        "TIME_TO_ACCEPT_AUTO_ROUTE_OFFER_PASS": "Czas na przyjęcie zlecenia w przypadku automatycznego przekazywania",
        "TIME_TO_ACCEPT_TRANSPORT_INTERVAL": "Czas na przyjęcie zlecenia",
        "TIME_TO_RESTORE_ORDER_FROM_ARCHIVE": "Ilość czasu przed upływem którego można przywrócić zlecenie z archiwum",
        "TIME_WINDOW_OFFSET_INTERVAL": "Ilość minut o ile można przesuwać okna czasowe. Możliwe do wprowadzenia wartości to 15, 30, lub 60.",
        "TRANSPORTS_LIST_LABEL": "Etykieta zakładki z listą transportów",
        "TRANSPORTS_LIST_LABEL_EN": "Etykieta zakładki z listą transportów - język angielski",
        "TRANSPORT_DESCRIPTION_COLUMN_MAX_CHARS": "Ilość wyświetlanych znaków w kolumnie Uwagi",
        "TRANSPORT_FREE_SLOT_SEARCH_INTERVAL_IN_DAYS": "Jaki okres (w dniach) powinniśmy sprawdzać podczas poszukiwania wolnego miejsca przy tworzeniu awizacji okresowej",
        "TRANSPORT_IDENTIFIER_TYPE": "Określa po jakiej wartości użytkownik z dostępem do REST API będzie miał dostęp do transportów. Dostępne opcje: ID, ERP_IDENTIFIER, ALT_IDENTIFIER",
        "TRANSPORT_PAGINATION_DEFAULT_MAX_ROWS": "Określa maksymalną domyślną ilość wierszy na strone, przy stronicowaniu tabeli transportów",
        "TRANSPORT_TABLE_DAYS_LIMIT": "Ilość czasu (w dniach) od aktualnej daty, powyżej której ukrywane będą zlecenia w tabeli zleceń aktywnych",
        "USE_ADFS_AUTH_FOR_API_MESSAGE": "Włącza autoryzację dla usługi ADFS (w przypadku wysyłki komunikatów API)",
        "USE_WEBSOCKETS": "Włącza obsługę websocketów",
        "WAREHOUSE_LABEL": "Etykieta pola formularza określającego magazyn",
        "WAREHOUSE_LABEL_EN": "Etykieta pola formularza określającego magazyn - w języku angielskim",
        "WAREHOUSE_ZONE_LABEL": "Etykieta pola formularza określającego strefę magazynową",
        "WAREHOUSE_ZONE_LABEL_EN": "Etykieta pola formularza określającego strefę magazynową - w języku angielskim",
        "WEEK_SCHEDULE_START_DATE_SHIFT": "Ilość dni dodawana do początku harmonogramu w widoku tygodniowym",
        "WEIGHT_OF_PALLET": "Określa wagę palety w kilogramach",
        "WEIGHT_SELECTOR": "Wskazanie elementu z wagą na stronie",
        "WEIGHT_URL": "Link do aplikacji wagi",
        "WINDOW_CHANGE_MAIL_DELAY_INTERVAL": "Wielkość opóźnienia, po którym ma być wysłany e-mail po przesunięciu okna czasowego.",
        "WINDOW_CONSTANT_TIME": "Stała wartość w minutach dodawana do długości rozładunku w oknach.",
        "XLS_ADDRESSES_ENABLED": "Uwzględniaj w raporcie XLS kolumnę \"Adresy\"",
        "XLS_DESCRIPTION_ENABLED": "Uwzględniaj w raporcie XLS kolumnę \"Uwagi\"",
        "XLS_EXTERNAL_ADDRESSES_DATA_ENABLED": "Uwzględniaj w raporcie XLS rozbite dane adresu\"",
        "XLS_TOP_ROW_ENABLED": "Włącz nagłówek dla raportów XLS."
      },
      "description": "Opis",
      "edit": "Edytuj ustawienie",
      "editable": "Możliwość edycji",
      "label": "Główne ustawienia",
      "name": "Nazwa",
      "other": "Inne ustawienia",
      "restSettingLabel": "Pozostałe ustawienia",
      "roleGroups": {
        "actionIsPossible": "akcja jest możliwa",
        "actionType": "Uprawnienie",
        "actionTypeDescription": {
          "ACCEPT_AND_REJECT_TRANSPORT": "Uprawnienie pozwala przyjmować i odrzucać przekazane do kontrahenta zlecenia.",
          "ACCESS_CARRIER_COMPANY_FIELD": "Ustawia widoczność pola \"Przewoźnik\" w formularzu transportu. Wymaga też uprawnienia: \"Dostęp do typu transportu\"",
          "ACCESS_CUSTOM_FIELD": "Ustawia widoczność pola niestandardowego w formularzu transportu",
          "ACCESS_DESCRIPTION_FIELD": "Ustawia widoczność pola Uwagi w formularzu transportu. Wymaga też uprawnienia: \"Dostęp do typu transportu\"",
          "ACCESS_INDEX": "Użytkownik z tą rolą ma dostęp do sekcji dotyczącej indeksów.",
          "ACCESS_PACKAGES": "Ustawia widoczność sekcji \"Paczki\" w formularzu transportu. Wymaga też uprawnienia: \"Dostęp do typu transportu\"",
          "ACCESS_SERVICE_COMPANY_FIELD": "Ustawia widoczność pola \"Serwis\" w formularzu transportu. Wymaga też uprawnienia: \"Dostęp do typu transportu\"",
          "ACCESS_SUPPLIER_COMPANY_FIELD": "Ustawia widoczność pola \"Dostawca/Odbiorca\" w formularzu transportu. Wymaga też uprawnienia: \"Dostęp do typu transportu\"",
          "ACCESS_TRANSPORT": "Włącza dostęp do danego typu transportu (widoczność typu transportu w tabeli zleceń).",
          "ADD_ADVICE": "Włącza możliwość dodawania danych awizacji.",
          "ADD_DOCUMENTS": "Włącza możliwość załączania dokumentów do zlecenia. Wymagany jest dostęp do kolumny z dokumentami w tabeli.",
          "ADD_LOAD_STEP_FROM_LOAD": "Dodawanie etapu załadunku za etapem załadunkowym. Wymagane też włączone ustawienia typu transportu \"Widoczność sekcji etapów transportu w formularzu\".",
          "ADD_LOAD_STEP_FROM_UNLOAD": "Dodawanie etapu załadunku za etapem rozładunkowym. Wymagane też włączone ustawienia typu transportu \"Widoczność sekcji etapów transportu w formularzu\".",
          "ADD_NOTES": "Włącza możliwość dodania notatki do transportu w tabeli zleceń. Wymagany jest dostęp do kolumny z notatkami w tabeli.",
          "ADD_PACKAGES": "Włącza możliwość dodania paczki w sekcji \"Paczki\" w formularzu transportu. Wymaga też uprawnienia: \"Widoczność sekcji paczki\"",
          "ADD_TIME_WINDOW": "Włącza możliwość dodawania okna czasowego.",
          "ADD_UNLOAD_STEP_FROM_LOAD": "Dodawanie etapu rozładunku za etapem załadunkowym. Wymagane też włączone ustawienia typu transportu \"Widoczność sekcji etapów transportu w formularzu\".",
          "ADD_UNLOAD_STEP_FROM_UNLOAD": "Dodawanie etapu rozładunku za etapem rozładunkowym. Wymagane też włączone ustawienia typu transportu \"Widoczność sekcji etapów transportu w formularzu\".",
          "BID_AUCTION": "Włącza możliwość brania udziału w aukcji i składania ofert.",
          "CREATE_AUCTION": "Użytkownik z tym uprawnieniem ma możliwość stworzenia aukcji.",
          "CREATE_TRANSPORT": "Umożliwia stworzenie nowego transportu. Wymaga też uprawnienia: \"Dostęp do typu transportu\".",
          "DELETE_DOCUMENTS": "Umożliwia użytkownikowi usunięcie załączonego dokumentu do transportu. Wymagany jest dostęp do kolumny z dokumentami w tabeli.",
          "DELETE_INDEX": "Umożliwia usunięcie dodanego indeksu do transportu.",
          "DELETE_LOAD_STEPS": "Włącza możliwość usuwania etapów załadunkowych. Wymagane też włączone ustawienia typu transportu \"Widoczność sekcji etapów transportu w formularzu\".",
          "DELETE_PACKAGES": "Włącza możliwość usuwania paczki w sekcji \"Paczki\" w formularzu transportu. Wymaga też uprawnienia: \"Widoczność sekcji paczki\"",
          "DELETE_TRANSPORT": "Użytkownik z tym uprawnieniem może usuwać stworzone wcześniej transporty. Wymaga też uprawnienia: \"Dostęp do typu transportu\".",
          "DELETE_UNLOAD_STEPS": "Włącza możliwość usuwania etapów rozładunkowych. Wymagane też włączone ustawienia typu transportu \"Widoczność sekcji etapów transportu w formularzu\".",
          "DISABLE_DATA_FIELDS": "Określa czy dane w formularzu transportu powinny byź wyłączone",
          "EDIT_COURIER_INVOICE_SECTION": "Włącza możliwość edycji pól w sekcji \"Faktura za towar\"",
          "EDIT_DESCRIPTION_FIELD": "Włącza możliwość edycji pola \"Uwagi\" w szczegółach zlecenia. Wymaga też uprawnienia: \"Dostęp do typu transportu\"",
          "EDIT_INDEX": "Włącza możłiwość edycji indeksów.",
          "EDIT_PACKAGES": "Włącza możliwość edycji paczki w sekcji \"Paczki\" w formularzu transportu. Wymaga też uprawnienia: \"Widoczność sekcji paczki\"",
          "EDIT_TRANSACTION_FREIGHT": "Włącza możliwość edycji frachtu w zleceniu przekazanym do przewoźnika",
          "MOVE_ADVICE_WINDOW": "Włącza możliwość przesuwania okien czasowych awizacji.",
          "MOVE_LOAD_STEPS": "Włącza możliwość przesuwania etapów załadunkowych w formularzu. Wymagane też włączone ustawienie typu transportu \"Widoczność sekcji etapów transportu w formularzu\".",
          "MOVE_UNLOAD_STEPS": "Włącza możliwość przesuwania etapów rozładunku w formularzu. Wymagane też włączone ustawienie typu transportu \"Widoczność sekcji etapów transportu w formularzu\".",
          "PASS_COURIER": "Włącza możliwość przekazania transportu do kuriera.",
          "PASS_TRANSPORT": "Włącza możliwość przekazania transportu do kontrahenta.",
          "RESIZE_ADVICE_WINDOW": "Umożliwia zmianę rozmiaru okien czasowych awizacji. Do prawidłowego działania jest wymagane też uprawnienie \"Zmiana położenia okna czasowego awizacji\".",
          "ROLE_ACCESS_AUCTION_DATA": "Włącza widoczność danych aukcji",
          "ROLE_ACCESS_COURIER_INVOICE_SECTION": "Włącza dostęp do sekcji \"Faktura za towar\"",
          "ROLE_ACCESS_FREIGHT_DATA": "Włącza widoczność stawki frachtu",
          "ROLE_ACCESS_TO_INSPECTION_BUTTON": "Dostęp do przycisku \"Kontrola\"",
          "ROLE_ACCESS_TO_INSPECTION_PANEL": "Dostęp do sekcji \"Kontrola\"",
          "ROLE_ACCESS_TO_LOCATION": "Widoczność pola 'Lokalizacja'",
          "ROLE_ACCESS_WEIGHT_SECTION": "Ustawia widoczność sekcji \"Ważenie\" w formularzu transportu. Wymaga też uprawnienia: \"Dostęp do typu transportu\"",
          "ROLE_ACCESS_WORKER_FIELD": "Uprawnienie pozwala na widoczność pol \"Pracownicy\" w formularzu tworzenia transportu",
          "ROLE_AUTOMATIC_PASS_TRANSPORT_TO_ROUTE_OFFERS": "Umożliwia automatyczne przekazywanie transportu do kolejnych kontrahentów.",
          "ROLE_CAN_ACCESS_COURIERS_SECTION": "Ustawia dostęp do sekcji \"Kurierzy\" w formularzu transportu",
          "ROLE_CAN_ACCESS_TASK": "Ustawia widoczność etapów",
          "ROLE_CAN_ADD_HAS_TO_WAIT_FOR_RELATION": "Włącza możliwość dodania powiązania relacji między transportami",
          "ROLE_CAN_ADD_ROUTE": "Możliwość dodania nowej trasy do cennika",
          "ROLE_CAN_ADD_TRANSPORT_COMBINING_RELATION": "Włącza możliwość powiązania łączenia transportów. Połączone transporty mają wspólną awizację, okno lub statusy.",
          "ROLE_CAN_AUTOMATICALLY_CONFIRM_PREVIOUS_CHECKPOINTS": "Użytkownik potwierdzając wskazany status automatycznie zatwierdza też wszystkie poprzednie niepotwierdzone statusy",
          "ROLE_CAN_BLOCK_TASK": "Włącza możliwość zablokowania potwierdzenia etapu",
          "ROLE_CAN_CHOOSE_MORE_THAN_ONE_LOCATION": "Włącza możliwość wyboru więcej niż 1 wartości w polu \"Lokalizacja\" w szczegółach zlecenia. Wymaga też uprawnienia: \"Dostęp do typu transportu\"",
          "ROLE_CAN_CONFIRM_TASK": "Umożliwia zatwierdzanie etapów",
          "ROLE_CAN_DELETE_RELATION": "Włącza możliwość usunięcia powiązania łączenia transportów. Uprawnienie pozwala odłączyć jeden z transportów.",
          "ROLE_CAN_DELETE_ROUTE": "Możliwość usuwania tras w cennikach",
          "ROLE_CAN_DOWNLOAD_REGISTRY_OF_PERSONS_XLS": "Uprawnienie pozwala eksportować raport ewidencji osób do pliku Excel",
          "ROLE_CAN_EDIT_COURIERS_SECTION": "Umożliwia edycję pól znajdujących się w sekcji \"Kurierzy\" w formularzu transportu. Wymaga uprawnienia \"Dostęp do sekcji Kurierzy\"",
          "ROLE_CAN_EDIT_READY_TO_PLAN": "Włącza możliwość edycji pola \"Gotowe do planowania\" w szczegółach zlecenia. Wymaga też uprawnienia: \"Dostęp do typu transportu\".",
          "ROLE_CAN_EDIT_ROUTE": "Możliwość edycji trasy w cennikach",
          "ROLE_CAN_SCAN_BARCODES": "Możliwość otwierania modalu do wyszukiwania transportów poprzez skanowanie kodów kreskowych",
          "ROLE_CAN_SEND_EMAIL_ADVICE": "Włącza możliwość wysłania prośby o podanie danych awizacji za pomocą awizacji mailowej",
          "ROLE_CAN_SEND_EMAIL_ADVICE_CF": "Włącza możliwość wysłania prośby o uzupełnienie danych w zleceniu za pomocą awizacji mailowej",
          "ROLE_CAN_SKIP_CHECKPOINT_CONFIRMATION": "Umożliwia pominięcie etapu pomimo wymaganej kolejności potwierdzeń poszczególnych etapów",
          "ROLE_CREATE_LDAP_USER": "Możliwość tworzenia użytkowników korzystających z integracji LDAP",
          "ROLE_CREATE_SIMILAR": "Możliwość stworzenia zlecenia podobnego (kopia zlecenia).",
          "ROLE_CREATE_SIMILAR_MULTIPLE": "Utwórz podobne - możliwość definiowania ilości kopii zlecenia",
          "ROLE_CREATE_WORKER": "Możliwość utworzenia pracownika w podzakładce \"Lista pracowników\"",
          "ROLE_DELETE_WORKER": "Możliwość usunięcia pracownika w podzakładce \"Lista pracowników\"",
          "ROLE_EDIT_WAREHOUSE_LOCATION": "Włącza możliwość edycji pola \"Lokalizacja\" w szczegółach zlecenia. Wymaga też uprawnienia: \"Dostęp do typu transportu\"",
          "ROLE_EDIT_WEIGHT": "Edycja wagi w sekcji \"Ważenie\"",
          "ROLE_EDIT_WORKER": "Możliwość edycji pracownika w podzakładce \"Lista pracowników\"",
          "ROLE_EDIT_WORKER_FIELD": "Uprawnienie pozwala na edycję pol \"Pracownicy\" w formularzu tworzenia transportu",
          "SWAP_ADVICE_WINDOWS": "Umożliwia zamianę miejscami okien czasowych awizacji",
          "UPDATE_ADVICE": "Umożliwia edycję danych awizacji.",
          "UPDATE_CARRIER_COMPANY_FIELD": "Włącza możliwość edycji pola \"Przewoźnik\" w szczegółach zlecenia. Wymaga też uprawnienia: \"Dostęp do typu transportu\"",
          "UPDATE_CUSTOM_FIELD": "Włącza możliwość edycji pola niestandardowego w formularzu transportu",
          "UPDATE_MAIN_DATA_SECTION": "Włącza możliwość edycji transportu. Dotyczy wszystkich pól za wyjątkiem danych awizacji, pól niestandardowych oraz pola \"Uwagi\". Wymaga też uprawnienia: \"Dostęp do typu transportu\".",
          "UPDATE_SERVICE_COMPANY_FIELD": "Włącza możliwość edycji pola \"Serwis\" w szczegółach zlecenia. Wymaga też uprawnienia: \"Dostęp do typu transportu\"",
          "chooseActionType": "Wybierz uprawnienie",
          "null": null
        },
        "addPermission": "Dodaj uprawnienie",
        "aggregate": {
          "header": "Wszystkie uprawnienia",
          "showAll": "Pokaż wszystkie"
        },
        "authorityLabels": {
          "ACCEPT_AND_REJECT_TRANSPORT": "Przyjęcie/odrzucenie przekazanego transportu",
          "ACCESS_CARRIER_COMPANY_FIELD": "Widoczność pola \"Przewoźnik\"",
          "ACCESS_CUSTOM_FIELD": "Widoczność pola niestandardowego",
          "ACCESS_DESCRIPTION_FIELD": "Widoczność pola uwagi",
          "ACCESS_INDEX": "Dostęp do sekcji indeksy",
          "ACCESS_PACKAGES": "Widoczność sekcji Paczki",
          "ACCESS_SERVICE_COMPANY_FIELD": "Widoczność pola \"Serwis\"",
          "ACCESS_SUPPLIER_COMPANY_FIELD": "Widoczność pola \"Dostawca/Odbiorca\"",
          "ACCESS_TO_TRANSPORT_DETAILS": "Dostęp do szczegółów zlecenia",
          "ACCESS_TRANSPORT": "Dostęp do typu transportu",
          "ADD_ADVICE": "Dodawanie danych awizacji",
          "ADD_DOCUMENTS": "Dodawanie dokumentów",
          "ADD_LOAD_STEP_FROM_LOAD": "Dodawanie etapu załadunku za etapem załadunkowym.",
          "ADD_LOAD_STEP_FROM_UNLOAD": "Dodawanie etapu załadunku za etapem rozładunkowym.",
          "ADD_NOTES": "Dodawanie notatek w tabeli",
          "ADD_PACKAGES": "Dodawanie paczki",
          "ADD_TIME_WINDOW": "Dodawanie okna czasowego",
          "ADD_UNLOAD_STEP_FROM_LOAD": "Dodawanie etapu rozładunku za etapem załadunkowym.",
          "ADD_UNLOAD_STEP_FROM_UNLOAD": "Dodawanie etapu rozładunku za etapem rozładunkowym.",
          "ADVICE_ONLY_ON_DEDICATED_WINDOWS": "Dodawanie okien czasowych tylko i wyłącznie na oknach dedykowanych",
          "BID_AUCTION": "Ofertowanie w aukcji",
          "CAN_DELETE_APPLIES_FILES": "Usuwanie dokumentów ze zgłoszenia przewoźnika",
          "CAN_DELETE_MOT_FILES": "Usuwanie dokumentów ze środka transportu",
          "CREATE_AUCTION": "Tworzenie aukcji",
          "CREATE_TRANSPORT": "Tworzenie transportu",
          "DELETE_DOCUMENTS": "Usuwanie dokumentów",
          "DELETE_INDEX": "Usuwanie indeksu",
          "DELETE_LOAD_STEPS": "Usuwanie etapu załadunku",
          "DELETE_PACKAGES": "Usuwanie paczki",
          "DELETE_TRANSPORT": "Usuwanie transportu",
          "DELETE_UNLOAD_STEPS": "Usuwanie etapu rozładunku",
          "DISABLE_DATA_FIELDS": "Ukrywanie danych w formularzu transportu",
          "EDIT_COURIER_INVOICE_SECTION": "Włącza możliwość edycji pól w sekcji \"Faktura za towar\"",
          "EDIT_DESCRIPTION_FIELD": "Włącza możliwość edycji pola \"Uwagi\"",
          "EDIT_INDEX": "Edycja indeksu",
          "EDIT_PACKAGES": "Edycja paczki",
          "EDIT_TRANSACTION_FREIGHT": "Edycja frachtu",
          "MOVE_ADVICE_WINDOW": "Zmiana położenia okna czasowego awizacji",
          "MOVE_LOAD_STEPS": "Przesuwanie etapów załadunkowych",
          "MOVE_UNLOAD_STEPS": "Przesuwanie etapów rozładunkowych",
          "PASS_COURIER": "Przekazanie transportu do kuriera",
          "PASS_TRANSPORT": "Przekazanie transportu",
          "RESIZE_ADVICE_WINDOW": "Zmiana rozmiaru okna czasowego awizacji",
          "ROLE_ACCESS_ALL_DEDICATED_WINDOWS": "Tworzenie awizacji w oknach czasowych dowolnej firmy",
          "ROLE_ACCESS_ALL_QUEUES": "Dostęp do wszystkich kolejek",
          "ROLE_ACCESS_ALL_RAMPS": "Dostęp do wszystkich ramp/slotów w harmonogramie",
          "ROLE_ACCESS_ALL_USER_GROUP_DEDICATED_WINDOWS": "Tworzenie awizacji w oknach czasowych dowolnego działu",
          "ROLE_ACCESS_API": "Dostęp do REST API",
          "ROLE_ACCESS_AUCTION_DATA": "Widoczność danych aukcji",
          "ROLE_ACCESS_COURIER_INVOICE_SECTION": "Włącza dostęp do sekcji \"Faktura za towar\"",
          "ROLE_ACCESS_FREIGHT_DATA": "Widoczność stawki frachtu",
          "ROLE_ACCESS_PALLETS_SUMMARY": "Dostęp do informacji o limitach palet na rampach",
          "ROLE_ACCESS_PRODUCT": "Dostęp do szczegółów produktu",
          "ROLE_ACCESS_QUEUES_ACCESSIBILITY": "Widoczność dostępów do placów i kolejek",
          "ROLE_ACCESS_TASK_DATA": "Dostęp do danych tasku",
          "ROLE_ACCESS_TO_APPLY_HISTORY": "Dostęp do historii zgłoszenia przewoźnika",
          "ROLE_ACCESS_TO_INSPECTION_BUTTON": "Dostęp do przycisku \"Kontrola\"",
          "ROLE_ACCESS_TO_INSPECTION_PANEL": "Dostęp do sekcji \"Kontrola\"",
          "ROLE_ACCESS_TO_LOCATION": "Widoczność pola 'Lokalizacja'",
          "ROLE_ACCESS_TO_NUMBER_OF_WINDOWS": "Licznik okien czasowych w Harmonogramie",
          "ROLE_ACCESS_TO_WAREHOUSE_TEMPLATES": "Dostęp do szablonów harmonogramu",
          "ROLE_ACCESS_TRANSPORT_TYPE_DATA": "Dostęp do danych typu transportu",
          "ROLE_ACCESS_TRUCKS": "Dostęp do listy ciągników",
          "ROLE_ACCESS_USERS_ACTIVITY": "Dostęp do historii aktywności użytkowników",
          "ROLE_ACCESS_WAREHOUSE_DATA": "Dostęp do danych magazynu",
          "ROLE_ACCESS_WEIGHT_SECTION": "Widoczność sekcji Ważenie",
          "ROLE_ACCESS_WORKER_FIELD": "Widoczność pola \"Pracownicy\"",
          "ROLE_ACCESS_WORKFLOW_DATA": "Dostęp do danych workflow",
          "ROLE_ADD_CONTRACTOR_GROUP": "Dodawanie grupy dla kontrahenta",
          "ROLE_ADD_NEW_ADDRESS_IN_COMPANY_DETAILS": "Dodanie nowego adresu w szczegółach firmy",
          "ROLE_ADD_WINDOW_NOTES": "Dodawanie notaki na szarym oknie czasowym",
          "ROLE_ADVICE_EXCEL": "Pobieranie zleceń do pliku Excel",
          "ROLE_ALLOW_CONCURRENT_SESSIONS": "Umożliwia równoległe logowanie jednego użytkownika",
          "ROLE_ANNOUNCEMENTS_TAB": "Dostęp do zakładki Ogłoszenia",
          "ROLE_API_MESSAGE_RESENDING": "Ponowienie komunikatu API",
          "ROLE_APPROVE_MOT": "Zatwierdzanie środka transportu",
          "ROLE_ARCHIVE_TAB": "Dostęp do zakładki archiwum",
          "ROLE_AUTOMATIC_PASS_TRANSPORT_TO_ROUTE_OFFERS": "Automatyczne przekazywanie transportu",
          "ROLE_BLOCK_RAMPS": "Ustawianie blokady w harmonogramie",
          "ROLE_CAN_ACCESS_ARCHIVED_TRUCK_DOCUMENTS": "Dostęp do archiwalnych dokumentów w sekcjach środka transportu",
          "ROLE_CAN_ACCESS_COMPANY_TRANSPORT_LIMIT": "Widoczność pól limitu transportów dla firmy",
          "ROLE_CAN_ACCESS_COURIERS_SECTION": "Dostęp do sekcji Kurierzy",
          "ROLE_CAN_ACCESS_TASK": "Widoczność etapu",
          "ROLE_CAN_ADD_CONFIRMATION_DATE_TASK": "Podanie daty potwierdzenia",
          "ROLE_CAN_ADD_HAS_TO_WAIT_FOR_RELATION": "Dodawanie powiązania relacji pomiędzy transportami",
          "ROLE_CAN_ADD_MOT": "Dodawanie środka transportu",
          "ROLE_CAN_ADD_ROUTE": "Dodawanie tras w cenniku",
          "ROLE_CAN_ADD_TRANSPORT_COMBINING_RELATION": "Dodawanie powiązania łączenia transportów",
          "ROLE_CAN_AUTOMATICALLY_CONFIRM_PREVIOUS_CHECKPOINTS": "Automatyczne potwierdzenie wszystkich poprzednich etapów",
          "ROLE_CAN_BLOCK_TASK": "Zablokowanie potwierdzenia etapu",
          "ROLE_CAN_CHOOSE_MORE_THAN_ONE_LOCATION": "Wybór więcej niż 1 opcji w polu \"Lokalizacja\"",
          "ROLE_CAN_CONFIRM_ROUTE_OFFER": "Zatwierdzanie oferty w \"Cenniki\"",
          "ROLE_CAN_CONFIRM_TASK": "Potwierdzenie etapu",
          "ROLE_CAN_CREATE_MAX_PALLET": "Możliwość ustawienia limitu palet",
          "ROLE_CAN_CREATE_ROUTE_OFFER": "Dodawanie oferty w cenniku",
          "ROLE_CAN_DELETE_RELATION": "Usuwanie powiązania łączenia transportów",
          "ROLE_CAN_DELETE_ROUTE": "Usuwanie tras w cennikach",
          "ROLE_CAN_DELETE_ROUTE_OFFER": "Usuwanie oferty z cennika",
          "ROLE_CAN_DOWNLOAD_ENTRY_PASS": "Pobieranie przepustki wjazdowej",
          "ROLE_CAN_DOWNLOAD_REGISTRY_OF_PERSONS_XLS": "Pobieranie ewidencji osób na terenie zakładu",
          "ROLE_CAN_EDIT_ADDRESSES": "Edycja adresów",
          "ROLE_CAN_EDIT_COMPANY_TRANSPORT_LIMIT": "Edycja pola z limitem transportów dla firmy",
          "ROLE_CAN_EDIT_COURIERS_SECTION": "Edycja pól w sekcji Kurierzy",
          "ROLE_CAN_EDIT_READY_TO_PLAN": "Edycja pola \"Gotowe do planowania\"",
          "ROLE_CAN_EDIT_ROUTE": "Edytowanie tras w cennikach",
          "ROLE_CAN_EDIT_ROUTE_OFFER": "Edycja oferty w cenniku",
          "ROLE_CAN_EXCEED_MAX_PALLET": "Awizowanie ponad limit palet",
          "ROLE_CAN_EXCEED_MAX_PALLET_ON_DEDICATED_WINDOWS": "Awizowanie ponad limit palet na oknach dedykowanych",
          "ROLE_CAN_GENERATE_FULL_SETTLEMENT_REPORT": "Pobieranie pełnego raportu rozliczeniowego do pliku Excel",
          "ROLE_CAN_GENERATE_LIST_OF_POINTS_REPORT": "Pobieranie listy punktów do pliku Excel.",
          "ROLE_CAN_GENERATE_SHORT_SETTLEMENT_REPORT": "Generowanie raportu awizacji",
          "ROLE_CAN_MOVE_WINDOW_OUT_OF_ASSORTMENT_GROUP": "Pozwala przeciągać okno czasowe poza grupę asortymentową",
          "ROLE_CAN_PLAN_ADVICE_IN_THE_PAST": "Planowanie awizacji w przeszłości",
          "ROLE_CAN_REJECT_TASK": "Odrzucanie etapu",
          "ROLE_CAN_REVOKE_TASK": "Cofanie etapu",
          "ROLE_CAN_SCAN_BARCODES": "Skanowanie kodów kreskowych",
          "ROLE_CAN_SEE_CURRENT_PLACE_IN_AUCTION": "Widoczność aktualnie zajmowanego miejsca w trwającej aukcji",
          "ROLE_CAN_SEND_EMAIL_ADVICE": "Awizacja mailowa - wysłanie prośby o dane awizacji",
          "ROLE_CAN_SEND_EMAIL_ADVICE_CF": "Awizacja mailowa - wysłanie prośby o uzupełnienie danych",
          "ROLE_CAN_SKIP_CHECKPOINT_CONFIRMATION": "Pominięcie potwierdzenia etapu",
          "ROLE_CAN_UPDATE_VEHICLE_MAIN_DATA_SECTION": "Edycja danych głównych środka transportu",
          "ROLE_CARRIERS_TAB": "Dostęp do zakładki przewoźnicy",
          "ROLE_CHANGE_WAREHOUSE": "Wybór magazynu w harmonogramie",
          "ROLE_CLASSIFICATION_TAB": "Dostęp do zakładki \"Klasyfikacja\"",
          "ROLE_COMPANIES_TAB": "Dostęp do zakładki firmy",
          "ROLE_CREATE_ASSORTMENT_GROUP": "Dodawanie grupy asortymentowej",
          "ROLE_CREATE_BLOCKED_WINDOWS": "Tworzenie szarych okien czasowych",
          "ROLE_CREATE_COMPANY": "Tworzenie nowej firmy",
          "ROLE_CREATE_CUSTOM_FIELD_DEFINITIONS": "Dostęp do ustawień własnych pól formularza",
          "ROLE_CREATE_DISCREPANCY_PROTOCOL": "Generowanie protokołu niezgodności",
          "ROLE_CREATE_DRIVER": "Dodawanie nowego kierowcy",
          "ROLE_CREATE_GUARD_ROLE_GROUP": "Tworzenie roli ochrony",
          "ROLE_CREATE_LDAP_USER": "Tworzenie użytkowników LDAP",
          "ROLE_CREATE_PLACE": "Tworzenie miejsc",
          "ROLE_CREATE_PRODUCT": "Tworzenie produktu",
          "ROLE_CREATE_QUEUE": "Tworzenie kolejek",
          "ROLE_CREATE_RAMP": "Tworzenie nowej rampy",
          "ROLE_CREATE_ROLE_GROUP": "Tworzenie nowej grupy ról",
          "ROLE_CREATE_SIMILAR": "Tworzenie zleceń podobnych",
          "ROLE_CREATE_SIMILAR_MULTIPLE": "Utwórz podobne - możliwość definiowania ilości kopii zlecenia",
          "ROLE_CREATE_SQUARE": "Tworzenie placów",
          "ROLE_CREATE_TRUCK": "Dodawanie nowego pojazdu",
          "ROLE_CREATE_USER": "Tworzenie nowego użytkownika",
          "ROLE_CREATE_WAREHOUSE": "Tworzenie nowego magazynu",
          "ROLE_CREATE_WAREHOUSE_ZONE": "Dodawanie strefy magazynowej",
          "ROLE_CREATE_WORKER": "Tworzenie nowego pracownika",
          "ROLE_DELETE_COMPANY": "Wyłączenie firmy",
          "ROLE_DELETE_DRIVER": "Usuwanie kierowcy",
          "ROLE_DELETE_INVITATION": "Usuwanie wysłanego zaproszenia",
          "ROLE_DELETE_MULTIPLE_PERIODIC_TRANSPORTS": "Usuwanie wielu awizacji okresowych",
          "ROLE_DELETE_PLACE": "Usuwanie placu",
          "ROLE_DELETE_PRODUCT": "Usunięcie produktu",
          "ROLE_DELETE_QUEUE": "Usuwanie kolejki",
          "ROLE_DELETE_RAMP": "Usuwanie rampy",
          "ROLE_DELETE_SQUARE": "Usuwanie miejsca",
          "ROLE_DELETE_TRUCK": "Usuwanie pojazdu",
          "ROLE_DELETE_USER": "Usunięcie użytkownika",
          "ROLE_DELETE_WAREHOUSE": "Usuwanie magazynu",
          "ROLE_DELETE_WORKER": "Usuwanie pracownika",
          "ROLE_DEV_TOOLS": "Dodatkowe narzędzia do testów",
          "ROLE_DISABLE_USER": "Wyłączenie użytkownika",
          "ROLE_EDIT_ALL_USERS": "Edycja wszystkich użytkowników",
          "ROLE_EDIT_ASSORTMENT_GROUP": "Edytowanie grupy asortymentowej",
          "ROLE_EDIT_COMPANY": "Edytowanie niektórych danych firmy",
          "ROLE_EDIT_GUS_DATA": "Edycja danych pobranych z GUS",
          "ROLE_EDIT_INCOMPLETE_LOADING": "Obsługa checkbox \"Niekompletny załadunek\"",
          "ROLE_EDIT_MULTIPLE_PERIODIC_TRANSPORTS": "Edycja wielu awizacji okresowych",
          "ROLE_EDIT_MY_NOTIFICATION": "Edycja własnych powiadomień",
          "ROLE_EDIT_MY_QUEUES": "Edycja własnych dostępów do placów i kolejek",
          "ROLE_EDIT_MY_RAMP": "Edycja własnych dostępów do ramp",
          "ROLE_EDIT_PLACE": "Edycja miejsc",
          "ROLE_EDIT_PRODUCT": "Edycja produktu",
          "ROLE_EDIT_QUEUE": "Edycja kolejek",
          "ROLE_EDIT_RAMP": "Edytowanie rampy",
          "ROLE_EDIT_ROLE_GROUP": "Zmiana roli użytkownika",
          "ROLE_EDIT_SQUARE": "Edycja placów",
          "ROLE_EDIT_USERS_MAIN_DATA": "Edycja danych głównych użytkownika",
          "ROLE_EDIT_USERS_NOTIFICATION": "Edycja powiadomień",
          "ROLE_EDIT_USERS_RAMP": "Edycja dostępu użytkownika do ramp",
          "ROLE_EDIT_WAREHOUSE": "Edytowanie magazynu",
          "ROLE_EDIT_WAREHOUSE_LOCATION": "Edycja pola \"Lokalizacja\".",
          "ROLE_EDIT_WAREHOUSE_ZONE": "Edytowanie strefy magazynowej",
          "ROLE_EDIT_WEIGHT": "Edycja wagi w sekcji \"Ważenie\"",
          "ROLE_EDIT_WORKER": "Edycja pracownika",
          "ROLE_EDIT_WORKER_FIELD": "Edycja pola \"Pracownicy\"",
          "ROLE_EDIT_WORKFLOW": "Edytowanie ustawień statusów",
          "ROLE_FILTER_ACTIVE_TRANSPORTS_TABLE": "Widoczność filtrów po kolumnach w tabeli zleceń aktywnych",
          "ROLE_FULL_EDIT_COMPANY": "Edytowanie wszystkich danych firmy",
          "ROLE_HIDE_TRANSPORTS_WITHOUT_ACTIVE_WAREHOUSE_STATUSES": "Ukrywanie transportów po potwierdzeniu ostatniego statusu w dostępnym magazynie",
          "ROLE_INVITATIONS_TAB": "Dostęp do zakładki Zaproszenia",
          "ROLE_INVITE_AS_TEMPORARY_USER_WHEN_ACCOUNT_LIMIT_EXCEEDED": "Zapraszanie użytkowników tymczasowych ponad limit firmy",
          "ROLE_INVOICE_COLUMN": "Wyświetlanie kolumny faktura",
          "ROLE_MANAGE_ADMIN_ANNOUNCEMENTS": "Zarządzanie ogłoszeniami administracyjnymi",
          "ROLE_MANAGE_ADVICE_VERIFICATION_MAIL": "Zarządzanie mailem z prośbą o weryfikację",
          "ROLE_MANAGE_ALLOWED_DOMAINS": "Dostęp do modyfikacji dozwolonych domen mailowych dla firmy",
          "ROLE_MANAGE_ANNOUNCEMENTS": "Możliwość zarządzania ogłoszeniami",
          "ROLE_MANAGE_APPLIES_SETTINGS": "Dostęp do zarządzania ustawieniami zgłoszeń",
          "ROLE_MANAGE_APPLY_SECTIONS": "Zarządzanie zgłoszeniami przewoźników",
          "ROLE_MANAGE_ARTR_SETTINGS": "Zarządzanie ustawieniami ARTR",
          "ROLE_MANAGE_AUCTION_SETTINGS": "Dostęp do zarządzania ustawieniami aukcji",
          "ROLE_MANAGE_AUTHORITIES": "Możliwość zarządzania uprawnieniami dla ról",
          "ROLE_MANAGE_CMR_SETTINGS": "Zarządzanie ustawieniami generowania dokumentu CMR",
          "ROLE_MANAGE_COLUMN_VISIBILITY": "Zarządzanie widocznością kolumn",
          "ROLE_MANAGE_COMPANY_PRICING_PLAN": "Edycja pakietu dla firmy",
          "ROLE_MANAGE_COMPANY_RAMP": "Dostęp do modyfikacji ramp dla firmy",
          "ROLE_MANAGE_DEDICATED_WINDOWS": "Zarządzanie oknami dedykowanymi",
          "ROLE_MANAGE_LOCAL_ADMINS": "Zarządzanie lokalnymi administratorami w firmach zewnętrznych",
          "ROLE_MANAGE_MAX_COMPANY_ACCOUNT_NUMBER": "Edycja maksymalnej ilości kont dla firmy",
          "ROLE_MANAGE_MOT_SECTIONS": "Zarządzanie środkami transportu",
          "ROLE_MANAGE_NOTIFICATIONS": "Zarządzanie powiadomieniami",
          "ROLE_MANAGE_PACKAGE_TYPE_TRANSPORT_TYPE": "Zarządzanie ustawieniami opakowań",
          "ROLE_MANAGE_PARTNERSHIP": "Zarządzanie powiązaniami kontrahentów",
          "ROLE_MANAGE_PRINCIPAL_DEDICATED_WINDOWS": "Tworzenie okien dedykowanych dla firmy klienta",
          "ROLE_MANAGE_QUEUES_ACCESSIBILITY": "Zarządzanie dostępem do placów i kolejek",
          "ROLE_MANAGE_SETTINGS": "Zarządzanie ustawieniami",
          "ROLE_MANAGE_TRANSPORT_TYPE": "Zarządzanie typami transportu",
          "ROLE_MANAGE_USER_GROUPS": "Zarządzanie grupami w ramach firmy kontrahenta",
          "ROLE_MANAGE_USER_ROLE_INVITATIONS": "Zarządzanie zaproszeniami ról użytkowników",
          "ROLE_MODE_OF_TRANSPORTATION_TAB": "Dostęp do zakładki \"Środki transportu\"",
          "ROLE_MOVE_BLOCKED_WINDOWS": "Zmiana położenia szarych okien czasowych",
          "ROLE_MOVE_DEDICATED_WINDOWS": "Zmienianie położenia dedykowanych okien czasowych",
          "ROLE_MOVE_WINDOWS_BETWEEN_WAREHOUSE_ZONES": "Przesuwanie okien czasowych pomiędzy strefami magazynowymi",
          "ROLE_OWN_COLUMNS_EXCEL": "Generowanie raportu na podstawie kolumn z tabeli",
          "ROLE_OWN_COMPANY_TAB": "Dostęp do zakładki firma na koncie kontrahenta",
          "ROLE_PERFORM_ACTION_IN_WAREHOUSE": "Wykonywanie akcji w magazynie",
          "ROLE_PRICE_LIST_TAB": "Dostęp do zakładki \"Cenniki\"",
          "ROLE_PRODUCTS_TAB": "Dostęp do listy produktów",
          "ROLE_QUEUES_TAB": "Dostęp do zakładki \"Kolejki\"",
          "ROLE_QUEUE_STATUS_TAB": "Dostęp do zakładki \"Stan kolejek\"",
          "ROLE_READ_WINDOW_NOTES": "Widoczność notatek na szarym oknie czasowym",
          "ROLE_REJECT_CHECKPOINT": "Odrzucanie statusu",
          "ROLE_RESIZE_BLOCKED_WINDOWS": "Zmiana rozmiaru szarych okien czasowych",
          "ROLE_RESIZE_DEDICATED_WINDOWS": "Zmienianie rozmiaru dedykowanych okien czasowych",
          "ROLE_RESTORE_ORDER_FROM_ARCHIVE": "Możliwość przywrócenia zlecenia z archiwum",
          "ROLE_REVOKE_CHECKPOINT": "Cofanie statusu",
          "ROLE_SEND_SMS_TO_ALL_DRIVERS_IN_QUEUE": "Możliwość wysyłania powiadomień SMS do wszystkich kierowców w danej kolejce",
          "ROLE_SETTINGS_TAB": "Dostęp do zakładki Ustawienia",
          "ROLE_SHOW_API_INTEGRATION_LOGS": "Dostęp do sekcji Integracja",
          "ROLE_SHOW_ARTR_API_LOGS": "Dostęp do sekcji Komunikaty ARTR",
          "ROLE_SHOW_AWAITING_INVITATIONS_TABLE": "Wyświetlanie listy oczekujących zaproszeń",
          "ROLE_SHOW_CUSTOM_FIELD_DEFINITIONS": "Obsługa własnych pól formularza",
          "ROLE_SHOW_PARTNERSHIP_TABLE": "Wyświetlanie listy powiązanych kontrahentów",
          "ROLE_SHOW_TRANSPORT_LOGS": "Dostęp do historii zlecenia",
          "ROLE_SORT_TRANSPORT_TABLE": "Sortowanie tabeli transportów",
          "ROLE_STATISTICS_REPORT_TAB": "Dodaje dostęp do zakładki \"Statystki\".",
          "ROLE_SUPER_USER": "Uprawnienia użytkownika specjalnego",
          "ROLE_TRANSPORT_TABLE": "Dostęp do tabeli transportów",
          "ROLE_USERS_TAB": "Dostęp do zakładki użytkownicy",
          "ROLE_WAREHOUSE_TAB": "Dostęp do zakładki Harmonogram",
          "SWAP_ADVICE_WINDOWS": "Zamiana miejscami okien czasowych awizacji",
          "UNLIMITED_ACCESS_TO_ARCHIVED_TRANSPORTS": "Nielimitowany dostęp do zleceń w archiwum",
          "UPDATE_ADVICE": "Edycja awizacji",
          "UPDATE_CARRIER_COMPANY_FIELD": "Edycja pola \"Przewoźnik\"",
          "UPDATE_CUSTOM_FIELD": "Edycja pola niestandardowego",
          "UPDATE_MAIN_DATA_SECTION": "Edycja głównych pól transportu",
          "UPDATE_SERVICE_COMPANY_FIELD": "Edycja pola \"Serwis\""
        },
        "authorityRangeLabel": "Zakres",
        "authorityRangeLabels": {
          "ACTIVE": "Do czasu archiwizacji zlecenia",
          "ACTIVE_AUCTION_BASED": "Zależne od aktywnej aukcji przypisanej do zlecenia.",
          "ADVICE_RESTRICTION_BASED": "W ramach wyznaczonego czasu na awizację",
          "AFTER_CHECKPOINT_BASED": "Po potwierdzeniu statusu",
          "BEFORE_CHECKPOINT_BASED": "Do momentu potwierdzenia statusu",
          "BLOCKED": "Akcja zablokowana.",
          "CHECKPOINT_BASED": "Związane ze statusem",
          "NO_ROUTE_OFFER": "Zależy od przyjęcia transportu przez przewoźnika po stawce z cennika.",
          "OWN_COMPANY_BASED": "Zależne od firmy twórcy zlecenia.",
          "TIME_BASED": "Zależne od daty",
          "UNLIMITED": "Bez dodatkowych warunków"
        },
        "authorityRangePhrases": {
          "ACTIVE": "do czasu archiwizacji zlecenia.",
          "ACTIVE_AUCTION_BASED": "gdy transport nie posiada aktywnej aukcji.",
          "ADVICE_RESTRICTION_BASED": "w ramach wyznaczonego czasu na awizację.",
          "AFTER_CHECKPOINT_BASED": "po potwierdzeniu statusu {checkpointName}.",
          "BEFORE_CHECKPOINT_BASED": "do momentu potwierdzenia statusu {checkpointName}.",
          "BLOCKED": "akcja zablokowana.",
          "CHECKPOINT_BASED": "związane ze statusem {checkpointName}.",
          "NO_ROUTE_OFFER": "w przypadku braku ofert preferowanych lub gdy przewoźnicy z ważnymi ofertami w cenniku nie przyjęli transportu do realizacji.",
          "OWN_COMPANY_BASED": "w ramach firmy twórcy.",
          "TIME_BASED": "najpóźniej {timeInterval} {afterOrBefore} {timeReference}.",
          "TIME_BASED_CURRENT_DATE_AFTER": "{timeInterval} po aktualnej dacie.",
          "TIME_BASED_CURRENT_DATE_BEFORE": "do {timeInterval} od aktualnej daty.",
          "UNLIMITED": "bez dodatkowych warunków."
        },
        "checkpoint": "Status",
        "conditions": "Warunki",
        "create": {
          "copyFrom": "Kopiuj uprawnienia z innej roli:",
          "info": "Poszczególne uprawnienia będzie można ustawić po zapisaniu roli",
          "isGuard": "Rola ochrony",
          "isGuardInfo": "Rola z uproszczonym potwierdzaniem statusów",
          "label": "Dodaj rolę",
          "nameEn": "Nazwa roli - EN",
          "namePl": "Nazwa roli",
          "noPartnershipInfo": "Brak partnerstwa spowoduje utworzenie roli pryncypała",
          "partnershipType": "Typ partnerstwa:"
        },
        "customFieldDefinition": "Pole niestandardowe",
        "dateAfterPhrase": "po dacie określającej",
        "dateBeforePhrase": "przed datą określającą",
        "deleteAllAuthoritiesTitle": "Przycisk usuwający wszystkie uprawnienia z grupy {title}",
        "generalAuthorityDescriptions": {
          "ACCESS_TO_TRANSPORT_DETAILS": "Włącza dostęp do szczegółów zlecenia",
          "ADVICE_ONLY_ON_DEDICATED_WINDOWS": "Uprawnienie ogranicza możliwość dodawania okna czasowego tylko w miejsce okna dedykowanego dostępnego dla użytkownika dodającego okno",
          "CAN_DELETE_APPLIES_FILES": "Możliwość usuwania dokumentów załączonych do zgłoszenia przewoźnika.",
          "CAN_DELETE_MOT_FILES": "Możliwość usuwania dokumentów załączonych do środka transportu.",
          "ROLE_ACCESS_ALL_DEDICATED_WINDOWS": "Pozwala na tworzenie awizacji w oknach dedykowanych dowolnej firmy",
          "ROLE_ACCESS_ALL_QUEUES": "Dostęp do wszystkich kolejek.",
          "ROLE_ACCESS_ALL_RAMPS": "Dostęp do wszystkich ramp/slotów w harmonogramie.",
          "ROLE_ACCESS_ALL_USER_GROUP_DEDICATED_WINDOWS": "Pozwala na tworzenie awizacji w oknach dedykowanych dowolnego działu",
          "ROLE_ACCESS_API": "Włącza dostęp do metod REST API dla użytkownika",
          "ROLE_ACCESS_PALLETS_SUMMARY": "Dostęp do informacji o limitach palet na rampach",
          "ROLE_ACCESS_PRODUCT": "Dostęp do szczegółów produktu.",
          "ROLE_ACCESS_QUEUES_ACCESSIBILITY": "Uprawnienie umożliwia zarządzaniem widocznością dostępu do placów oraz kolejek. Po włączeniu uprawnienia element jest widoczny w szczegółach użytkownika.",
          "ROLE_ACCESS_TASK_DATA": "Dostęp do danych tasku",
          "ROLE_ACCESS_TO_APPLY_HISTORY": "Dostęp do historii zgłoszenia przewoźnika.",
          "ROLE_ACCESS_TO_INSPECTION_BUTTON": "Widoczność przycisku do kontroli w tabeli transportów w kolumnie \"Kontrola\"",
          "ROLE_ACCESS_TO_INSPECTION_PANEL": "Dostęp do sekcji \"Kontrola\"",
          "ROLE_ACCESS_TO_LOCATION": "Widoczność pola 'Lokalizacja'",
          "ROLE_ACCESS_TO_NUMBER_OF_WINDOWS": "Włącza wyświetlanie licznika okien czasowych w Harmonogramie",
          "ROLE_ACCESS_TO_WAREHOUSE_TEMPLATES": "Umożliwia zarządzanie szablonami okien czasowych w harmonogramie.",
          "ROLE_ACCESS_TRANSPORT_TYPE_DATA": "Dostęp do danych typu transportu",
          "ROLE_ACCESS_TRUCKS": "Dostęp do listy ciągników",
          "ROLE_ACCESS_USERS_ACTIVITY": "Włącza dostęp do historii aktywności użytkowników",
          "ROLE_ACCESS_WAREHOUSE_DATA": "Dostęp do danych magazynu",
          "ROLE_ACCESS_WORKER_FIELD": "Uprawnienie pozwala na widoczność pola \"Pracownicy\" w formularzu tworzenia transportu",
          "ROLE_ACCESS_WORKFLOW_DATA": "Dostęp do danych workflow",
          "ROLE_ADD_CONTRACTOR_GROUP": "Możliwość dodania nowej grupy dla kontrahenta",
          "ROLE_ADD_NEW_ADDRESS_IN_COMPANY_DETAILS": "Umożliwia dodanie nowego adresu w szczegółach firmy",
          "ROLE_ADD_WINDOW_NOTES": "Włącza możliwość dodawania notatek na szarych oknach czasowych.",
          "ROLE_ADVICE_EXCEL": "Uprawnienie pozwala eksportować dane zleceń do pliku Excel.",
          "ROLE_ALLOW_CONCURRENT_SESSIONS": "Umożliwia logowanie na jednego użytkownika z wielu przeglądarek",
          "ROLE_ANNOUNCEMENTS_TAB": "Włącza dostęp do zakładki Ogłoszenia",
          "ROLE_API_MESSAGE_RESENDING": "Możliwość ponowienia wysyłki komunikatu API",
          "ROLE_APPROVE_MOT": "Możliwość zatwierdzenia środka transportu.",
          "ROLE_ARCHIVE_TAB": "Włącza dostęp do zakładki \"Archiwum\".",
          "ROLE_BLOCK_RAMPS": "Możliwość postawienia okna blokady w harmonogramie, jak również możliwość zablokowania całej rampy po kliknięciu w nagłówek rampy.",
          "ROLE_CAN_ACCESS_ARCHIVED_TRUCK_DOCUMENTS": "Włącza dostęp do archiwalnych dokumentów w szczegółach środka transportu znajdującego się w zakładce \"Środki transportu\".",
          "ROLE_CAN_ACCESS_COMPANY_TRANSPORT_LIMIT": "Włącza edycję pola z limitem transportów dla firmy",
          "ROLE_CAN_ACCESS_COURIERS_SECTION": "Ustawia dostęp do sekcji \"Kurierzy\" w formularzu transportu",
          "ROLE_CAN_ADD_HAS_TO_WAIT_FOR_RELATION": "Włącza możliwość dodania powiązania relacji między transportami",
          "ROLE_CAN_ADD_MOT": "Możliwość dodawania środka transportu.",
          "ROLE_CAN_ADD_ROUTE": "Możliwość dodania nowej trasy do cennika",
          "ROLE_CAN_ADD_TRANSPORT_COMBINING_RELATION": "Włącza możliwość powiązania łączenia transportów. Połączone transporty mają wspólną awizację, okno lub statusy.",
          "ROLE_CAN_AUTOMATICALLY_CONFIRM_PREVIOUS_CHECKPOINTS": "Użytkownik potwierdzając wskazany status automatycznie zatwierdza też wszystkie poprzednie niepotwierdzone statusy",
          "ROLE_CAN_CHOOSE_MORE_THAN_ONE_LOCATION": "Włącza możliwość wyboru więcej niż 1 wartości w polu \"Lokalizacja\" w szczegółach zlecenia. Wymaga też uprawnienia: \"Dostęp do typu transportu\"",
          "ROLE_CAN_CONFIRM_ROUTE_OFFER": "Włącza możliwość zatwierdzenia dodanej oferty do cennika",
          "ROLE_CAN_CREATE_MAX_PALLET": "Możliwość ustawienia limitu palet.",
          "ROLE_CAN_CREATE_ROUTE_OFFER": "Możliwość dodania nowej oferty do cennika",
          "ROLE_CAN_DELETE_RELATION": "Włącza możliwość usunięcia powiązania łączenia transportów. Uprawnienie pozwala odłączyć jeden z transportów.",
          "ROLE_CAN_DELETE_ROUTE": "Możliwość usuwania tras w cennikach",
          "ROLE_CAN_DELETE_ROUTE_OFFER": "Możliwość usuwania oferty z cennika",
          "ROLE_CAN_DOWNLOAD_ENTRY_PASS": "Możliwość pobierania przepustki wjazdowej",
          "ROLE_CAN_DOWNLOAD_REGISTRY_OF_PERSONS_XLS": "Uprawnienie pozwala eksportować raport ewidencji osób do pliku Excel",
          "ROLE_CAN_EDIT_ADDRESSES": "Możliwość edycji i usuwania adresów w zakładce \"Firma\".",
          "ROLE_CAN_EDIT_COMPANY_TRANSPORT_LIMIT": "Włącza widoczność pól związanych z limitem transportów dla firmy",
          "ROLE_CAN_EDIT_COURIERS_SECTION": "Umożliwia edycję pól znajdujących się w sekcji \"Kurierzy\" w formularzu transportu. Wymaga uprawnienia \"Dostęp do sekcji Kurierzy\"",
          "ROLE_CAN_EDIT_READY_TO_PLAN": "Włącza możliwość edycji pola \"Gotowe do planowania\" w szczegółach zlecenia. Wymaga też uprawnienia: \"Dostęp do typu transportu\".",
          "ROLE_CAN_EDIT_ROUTE": "Możliwość edycji trasy w cennikach",
          "ROLE_CAN_EDIT_ROUTE_OFFER": "Możliwość edycji oferty w cenniku",
          "ROLE_CAN_EXCEED_MAX_PALLET": "Możliwość awizacji ponad limit palet.",
          "ROLE_CAN_EXCEED_MAX_PALLET_ON_DEDICATED_WINDOWS": "Możliwość awizacji ponad limit palet w przypadku okien czasowych znajdujących się w całości na oknach dedykowanych",
          "ROLE_CAN_GENERATE_FULL_SETTLEMENT_REPORT": "Uprawnienie pozwala eksportować pełny raport rozliczeniowy do pliku Excel.",
          "ROLE_CAN_GENERATE_LIST_OF_POINTS_REPORT": "Uprawnienie pozwala eksportować listę punktów pliku Excel.",
          "ROLE_CAN_GENERATE_SHORT_SETTLEMENT_REPORT": "Uprawnienie umożliwia wygenerowanie raportu awizacji w postaci pliku Excel",
          "ROLE_CAN_MOVE_WINDOW_OUT_OF_ASSORTMENT_GROUP": "Pozwala przeciągać okno czasowe poza grupę asortymentową",
          "ROLE_CAN_PLAN_ADVICE_IN_THE_PAST": "Umożliwia planowanie awizacji w przeszłości",
          "ROLE_CAN_SEE_CURRENT_PLACE_IN_AUCTION": "Widoczność aktualnie zajmowanego miejsca w trwającej aukcji",
          "ROLE_CAN_SKIP_CHECKPOINT_CONFIRMATION": "Umożliwia pominięcie etapu pomimo wymaganej kolejności potwierdzeń poszczególnych etapów",
          "ROLE_CAN_UPDATE_VEHICLE_MAIN_DATA_SECTION": "Możliwość edycji danych znajdujących się w głównej sekcji środka transportu",
          "ROLE_CARRIERS_TAB": "Włącza dostęp do zakładki \"Przewoźnicy\".",
          "ROLE_CHANGE_WAREHOUSE": "Możliwość zmiany magazynu w harmonogramie.",
          "ROLE_CLASSIFICATION_TAB": "Włącza dostęp do zakładki \"Klasyfikacja\"",
          "ROLE_COMPANIES_TAB": "Włącza dostęp do zakładki \"Firmy\".",
          "ROLE_CREATE_ASSORTMENT_GROUP": "Możliwość stworzenia nowej grupy asortymentowej.",
          "ROLE_CREATE_BLOCKED_WINDOWS": "Włącza możliwość tworzenia szarych okien czasowych w widoku harmonogramu.",
          "ROLE_CREATE_COMPANY": "Uprawnienie umożliwia stworzenie nowej firmy bez wysyłania zaproszenia. ",
          "ROLE_CREATE_CUSTOM_FIELD_DEFINITIONS": "Uprawnienie daje dostęp do ustawień własnych pól formularzy. ",
          "ROLE_CREATE_DISCREPANCY_PROTOCOL": "Umożliwia wygenerowanie protokołu niezgodności",
          "ROLE_CREATE_DRIVER": "Możliwość dodania nowego kierowcy.",
          "ROLE_CREATE_GUARD_ROLE_GROUP": "Możliwość stworzenia roli ochrony",
          "ROLE_CREATE_LDAP_USER": "Możliwość tworzenia użytkowników korzystających z integracji LDAP",
          "ROLE_CREATE_PLACE": "Rola umożliwia stworzenie nowego miejsca",
          "ROLE_CREATE_PRODUCT": "Włącza możliwość dodania nowego produktu. Wymaga też uprawnienia: \"Dostęp do produktów\".",
          "ROLE_CREATE_QUEUE": "Rola umożliwia stworzenie nowej kolejki",
          "ROLE_CREATE_RAMP": "Możliwość dodania nowej rampy do istniejącego magazynu. Wymagana rola: \"Edytowanie magazynu\".",
          "ROLE_CREATE_ROLE_GROUP": "Umożliwia utworzenie nowej grupy ról",
          "ROLE_CREATE_SIMILAR": "Możliwość stworzenia zlecenia podobnego (kopia zlecenia).",
          "ROLE_CREATE_SIMILAR_MULTIPLE": "Włącza możliwość definiowania ilości zleceń, jakie się mają utworzyć",
          "ROLE_CREATE_SQUARE": "Rola umożliwia stworzenie nowego placu",
          "ROLE_CREATE_TRUCK": "Możliwość dodania nowego pojazdu.",
          "ROLE_CREATE_USER": "Uprawnienie umożliwia stworzenie nowego użytkownika bez wysyłania zaproszenia.",
          "ROLE_CREATE_WAREHOUSE": "Możliwość dodania nowego magazynu. Wymagana rola: \"Edytowanie magazynu\".",
          "ROLE_CREATE_WAREHOUSE_ZONE": "Możliwość stworzenia nowej strefy magazynowej.",
          "ROLE_CREATE_WORKER": "Możliwość utworzenia pracownika w podzakładce \"Lista pracowników\"",
          "ROLE_DELETE_COMPANY": "Możliwość wyłączenia firmy.",
          "ROLE_DELETE_DRIVER": "Możliwość usunięcia kierowcy.",
          "ROLE_DELETE_INVITATION": "Umożliwia usunięcie wysłanego zaproszenia",
          "ROLE_DELETE_MULTIPLE_PERIODIC_TRANSPORTS": "Możliwość usunięcia wielu awizacji okresowych",
          "ROLE_DELETE_PLACE": "Umożliwia usunięcie placu",
          "ROLE_DELETE_PRODUCT": "Użytkownik z tym uprawnieniem może usunąć istniejący produkt. Wymaga też uprawnienia: \"Dostęp do produktów\".",
          "ROLE_DELETE_QUEUE": "Umożliwia usunięcie kolejki",
          "ROLE_DELETE_RAMP": "Możliwość usunięcia rampy. Wymagana rola: \"Edytowanie magazynu\".",
          "ROLE_DELETE_SQUARE": "Umożliwia usunięcie miejsca",
          "ROLE_DELETE_TRUCK": "Możliwość usunięcia pojazdu.",
          "ROLE_DELETE_USER": "Możliwość usunięcia użytkownika. Użytkownik po usunięciu nie będzie miał dostępu do platformy.",
          "ROLE_DELETE_WAREHOUSE": "Możliwość  usunięcia magazynu. Wymagana rola: \"Edytowanie magazynu\".",
          "ROLE_DELETE_WORKER": "Możliwość usunięcia pracownika w podzakładce \"Lista pracowników\"",
          "ROLE_DEV_TOOLS": "Dodatkowe narzędzia do testów",
          "ROLE_DISABLE_USER": "Możliwość wyłączenia użytkownika. Wymaga uprawnienia \"Edycja danych głównych użytkownika\". W przypadku użytkowników firm trzecich dodatkowo wymagane uprawnienie \"Edycja wszystkich użytkowników\".",
          "ROLE_EDIT_ALL_USERS": "Możliwość edycji użytkowników innych firm. Wymaga jednego z uprawnień \"Edycja danych głównych użytkownika\", \"Edycja dostępu użytkownika do ramp\", \"Edycja powiadomień użytkownika\"",
          "ROLE_EDIT_ASSORTMENT_GROUP": "Możliwość edycji grupy asortymentowej.",
          "ROLE_EDIT_COMPANY": "Możliwość edycji pól skrócona nazwa, numer kontrahenta, dane dodatkowe",
          "ROLE_EDIT_GUS_DATA": "Możliwość edycji danych firmy, która podlegała weryfikacji Głównego Urzędu Statystycznego",
          "ROLE_EDIT_INCOMPLETE_LOADING": "Możliwość zaznaczania/odznaczania pola typu checbox \"Niekompletny załadunek\".",
          "ROLE_EDIT_MULTIPLE_PERIODIC_TRANSPORTS": "Możliwość edycji wielu awizacji okresowych",
          "ROLE_EDIT_MY_NOTIFICATION": "Edycja własnych powiadomień",
          "ROLE_EDIT_MY_QUEUES": "Edycja własnych dostępów do placów i kolejek",
          "ROLE_EDIT_MY_RAMP": "Edycja własnych dostępów do ramp",
          "ROLE_EDIT_PLACE": "Rola umożliwia edycję miejsca",
          "ROLE_EDIT_PRODUCT": "Umożliwia edycję istniejącego już produktu. Wymaga też uprawnienia: \"Dostęp do produktów\".",
          "ROLE_EDIT_QUEUE": "Rola umożliwia edycję kolejki",
          "ROLE_EDIT_RAMP": "Możliwość edycji rampy.",
          "ROLE_EDIT_ROLE_GROUP": "Możliwość zmiany roli w szczegółach użytkownika.",
          "ROLE_EDIT_SQUARE": "Rola umożliwia edycję placu",
          "ROLE_EDIT_USERS_MAIN_DATA": "Możliwość edycji imienia i nazwiska, adresu e-mail, telefonu oraz hasła",
          "ROLE_EDIT_USERS_NOTIFICATION": "Umożliwia użytkownikowi edycję otrzymywanych powiadomień",
          "ROLE_EDIT_USERS_RAMP": "Możliwość edycji dostępu użytkownika do ramp i magazynów",
          "ROLE_EDIT_WAREHOUSE": "Możliwość edycji danych magazynu.",
          "ROLE_EDIT_WAREHOUSE_ZONE": "Możliwość edycji strefy magazynowej.",
          "ROLE_EDIT_WORKER": "Możliwość edycji pracownika w podzakładce \"Lista pracowników\"",
          "ROLE_EDIT_WORKER_FIELD": "Uprawnienie pozwala na edycję pola \"Pracownicy\" w formularzu tworzenia transportu",
          "ROLE_EDIT_WORKFLOW": "Możliwość edycji szczegółowych ustawień statusów dla poszczególnych typów transportu.",
          "ROLE_FILTER_ACTIVE_TRANSPORTS_TABLE": "Włącza widoczność filtrów nad kolumnami tabeli zleceń aktywnych",
          "ROLE_FULL_EDIT_COMPANY": "Możliwość edycji wszystkich danych firmy kontrahenta.",
          "ROLE_HIDE_TRANSPORTS_WITHOUT_ACTIVE_WAREHOUSE_STATUSES": "Uprawnienie ukrywa transporty, które nie mają aktywnych statusów z magazynem użytkownika",
          "ROLE_INVITATIONS_TAB": "Włącza dostęp do zakładki \"Zaproszenia\".",
          "ROLE_INVITE_AS_TEMPORARY_USER_WHEN_ACCOUNT_LIMIT_EXCEEDED": "Zapraszanie użytkowników tymczasowych ponad limit firmy",
          "ROLE_INVOICE_COLUMN": "Wyświetlanie kolumny faktura.",
          "ROLE_MANAGE_ADMIN_ANNOUNCEMENTS": "Możliwość zarządzania ogłoszeniami administracyjnymi. Wymagane uprawnienia: \"Dostęp do zakładki Ogłoszenia\" oraz \"Możliwość zarządzania ogłoszeniami\"",
          "ROLE_MANAGE_ADVICE_VERIFICATION_MAIL": "Uprawnienie daje dostęp do włączenia/wyłączania powiadomienia mailowego z prośbą o weryfikację danych awizacji oraz umożliwia zmianę parametru czasu wysyłki maila. Po włączeniu uprawnienia element jest widoczny w szczegółach kontrahenta.",
          "ROLE_MANAGE_ALLOWED_DOMAINS": "Dostęp oraz możliwość zarządzania dozwolonymi domenami mailowymi w sekcji \"Dozwolone domeny\" w szczegółach kontrahenta.",
          "ROLE_MANAGE_ANNOUNCEMENTS": "Włącza możliwość zarządzania ogłoszeniami. Wymagane uprawnienie \"Dostęp do zakładki ogłoszenia\".",
          "ROLE_MANAGE_APPLIES_SETTINGS": "Dostęp do zarządzania ustawieniami zgłoszeń",
          "ROLE_MANAGE_APPLY_SECTIONS": "Zarządzanie zgłoszeniami przewoźników. Możliwość akceptacji i odrzucania poszczególnych sekcji zgłoszenia przewoźnika.",
          "ROLE_MANAGE_ARTR_SETTINGS": "Zarządzanie ustawieniami ARTR",
          "ROLE_MANAGE_AUCTION_SETTINGS": "Dostęp do zarządzania ustawieniami aukcji",
          "ROLE_MANAGE_AUTHORITIES": "Umożliwia tworzenie, edycję i usuwanie uprawnień dla ról (ogólnych oraz dotyczących typów transportu)",
          "ROLE_MANAGE_CMR_SETTINGS": "Zarządzanie ustawieniami generowania dokumentu CMR",
          "ROLE_MANAGE_COLUMN_VISIBILITY": "Umożliwia zarządzanie widocznością kolumn w widoku uprawnień zależnych od typu transportu.",
          "ROLE_MANAGE_COMPANY_PRICING_PLAN": "Uprawnienie pozwala edytować pakiet firmy",
          "ROLE_MANAGE_COMPANY_RAMP": "Włącza dostęp do sekcji \"Modyfikacja ramp\" w szczegółach kontrahenta",
          "ROLE_MANAGE_DEDICATED_WINDOWS": "Dodaje uprawnienie umożliwiające tworzenie oraz usuwanie okien dedykowanych.",
          "ROLE_MANAGE_LOCAL_ADMINS": "Możliwość przydzielenia roli lokalnego administratora dla użytkowników firm zewnętrznych",
          "ROLE_MANAGE_MAX_COMPANY_ACCOUNT_NUMBER": "Uprawnienie pozwala zmieniać ilość maksymalnej liczby kont dla firm",
          "ROLE_MANAGE_MOT_SECTIONS": "Zarządzanie środkami transportu. Możliwość akceptacji i odrzucania poszczególnych sekcji środka transportu.",
          "ROLE_MANAGE_NOTIFICATIONS": "Możliwość zarządzania powiadomieniami",
          "ROLE_MANAGE_PACKAGE_TYPE_TRANSPORT_TYPE": "Zarządzanie ustawieniami opakowań",
          "ROLE_MANAGE_PARTNERSHIP": "Pozwala dodawać i usuwać powiązania pomiędzy kontrahentami",
          "ROLE_MANAGE_PRINCIPAL_DEDICATED_WINDOWS": "Możliwość tworzenia okien dedykowanych dla firmy klienta. Użytkownik firmy klienta będzie mógł dodać na takie okno dedykowane dowolną awizację.",
          "ROLE_MANAGE_QUEUES_ACCESSIBILITY": "Uprawnienie daje dostęp do włączenia/wyłączania dostępu do placów oraz kolejek.",
          "ROLE_MANAGE_SETTINGS": "Umożliwia zarządzanie ustawieniami",
          "ROLE_MANAGE_TRANSPORT_TYPE": "Zarządzanie typami transportu.",
          "ROLE_MANAGE_USER_GROUPS": "Zarządzanie grupami w ramach firmy kontrahenta",
          "ROLE_MANAGE_USER_ROLE_INVITATIONS": "Umożliwia przypisanie dla konkretnej roli użytkownika listy ról, które będą możliwe do zaproszenia",
          "ROLE_MODE_OF_TRANSPORTATION_TAB": "Włącza dostęp do zakładki \"Środki transportu\"",
          "ROLE_MOVE_BLOCKED_WINDOWS": "Umożliwia przesuwanie szarych okien czasowych",
          "ROLE_MOVE_DEDICATED_WINDOWS": "Umożliwia przesuwanie dedykowanych okien czasowych.",
          "ROLE_MOVE_WINDOWS_BETWEEN_WAREHOUSE_ZONES": "Umożliwia przesuwanie okien czasowych pomiędzy strefami magazynowymi",
          "ROLE_OWN_COLUMNS_EXCEL": "Uprawnienie umożliwia wygenerowanie raportu odwzorowującego widoczność kolumn z tabeli transportów.",
          "ROLE_OWN_COMPANY_TAB": "Włącza dostęp do zakładki \"Firma\" na koncie kontrahenta.",
          "ROLE_PERFORM_ACTION_IN_WAREHOUSE": "Rola umożliwiająca wykonywanie działań w magazynie",
          "ROLE_PRICE_LIST_TAB": "Włącza dostęp do zakładki \"Cenniki\"",
          "ROLE_PRODUCTS_TAB": "Dodaje dostęp do zakładki związanej z produktami.",
          "ROLE_QUEUES_TAB": "Włącza dostęp do zakładki \"Kolejki\"",
          "ROLE_QUEUE_STATUS_TAB": "Włącza dostęp do zakładki \"Stan kolejek\"",
          "ROLE_READ_WINDOW_NOTES": "Włącza widoczność notatek na szarych oknach dla użytkowników z tym uprawnieniem.",
          "ROLE_REJECT_CHECKPOINT": "Możliwość odrzucenia statusu.",
          "ROLE_RESIZE_BLOCKED_WINDOWS": "Umożliwia zmianę rozmiaru szarych okien czasowych (okien blokady). Do prawidłowego działania dodatkowo wymagane jest uprawnienie \"Zmiana położenia szarych okien czasowych\".",
          "ROLE_RESIZE_DEDICATED_WINDOWS": "Umożliwia zmianę rozmiaru dedykowanych okien czasowych. Do prawidłowego działania wymagane jest też uprawnienie \"Zmienianie położenia dedykowanych okien czasowych\".",
          "ROLE_RESTORE_ORDER_FROM_ARCHIVE": "Możliwość przywrócenia zlecenia z archiwum po automatycznej archiwizacji z powodu opóźnienia",
          "ROLE_REVOKE_CHECKPOINT": "Możliwość cofnięcia statusu.",
          "ROLE_SEND_SMS_TO_ALL_DRIVERS_IN_QUEUE": "Włącza możliwość wysyłania powiadomień SMS do wszystkich kierowców w danej kolejce (zakładka Stan kolejek)",
          "ROLE_SETTINGS_TAB": "Włącza dostęp do zakładki \"Ustawienia\".",
          "ROLE_SHOW_API_INTEGRATION_LOGS": "Wyświetl sekcję \"Integracja\" w formularzu zlecenia.",
          "ROLE_SHOW_ARTR_API_LOGS": "Wyświetl sekcję \"Komunikaty ARTR\" w formularzu zlecenia.",
          "ROLE_SHOW_AWAITING_INVITATIONS_TABLE": "Uprawnienie to pozwala na podgląd listy oczekujących zaproszeń w szczegłółach danej firmy.",
          "ROLE_SHOW_CUSTOM_FIELD_DEFINITIONS": "Uprawnienie umożliwia dodawanie, edycję i usuwanie własnych pól formularza. Wymagane także uprawnienie \"Dostęp do ustawień własnych pól formularza\".",
          "ROLE_SHOW_PARTNERSHIP_TABLE": "Uprawnienie to pozwala na podgląd listy powiązanych kontrahentów w szczegłółach danej firmy. Powiązania generowane są poprzez zaproszenia.",
          "ROLE_SHOW_TRANSPORT_LOGS": "Wyświetl sekcję \"Historia\" w formularzu zlecenia.",
          "ROLE_SORT_TRANSPORT_TABLE": "Rola umożliwiająca sortowanie tabeli transportów po wybranej kolumnie",
          "ROLE_STATISTICS_REPORT_TAB": "Dodaje dostęp do zakładki \"Statystki\".",
          "ROLE_SUPER_USER": "Uprawnienia użytkownika specjalnego",
          "ROLE_TRANSPORT_TABLE": "Użytkownik z tą rolą ma dostęp do tabeli transportów.",
          "ROLE_USERS_TAB": "Włącza dostęp do zakładki \"Użytkownicy\".",
          "ROLE_WAREHOUSE_TAB": "Włącza dostęp do zakładki \"Harmonogram\".",
          "UNLIMITED_ACCESS_TO_ARCHIVED_TRANSPORTS": "Ustawia dostęp do zleceń bez ograniczeń czasowych"
        },
        "generalPermissions": "Uprawnienia podstawowe",
        "header": "Uprawnienia dla roli ",
        "invitationSection": "Użytkownik może zapraszać role",
        "invitationSettings": "Ustawienia zaproszeń",
        "label": "Ustawienia ról użytkowników",
        "mulitRolesLabel": "Przypisz uprawnienie",
        "noAuthorities": "Brak uprawnień.",
        "notificationSection": "Powiadomienia mailowe",
        "notificationSettings": "Powiadomienia mailowe",
        "permissions": "Uprawnienia",
        "pricingPlans": "dla firm z pakietem {pricingPlan}",
        "selectRoles": "Wybierz role",
        "successCreate": "Poprawnie utworzono rolę",
        "successDelete": "Poprawnie wyłączono uprawnienie",
        "successUpdate": "Poprawnie zapisano uprawnienie",
        "sureToDelete": "Czy jesteś pewien, że chcesz wyłączyć to uprawnienie?",
        "sureToDeleteAll": "Czy jesteś pewien, że chcesz wyłączyć wszystkie uprawnienia tej grupy?",
        "timeIntervalLabel": "Czas",
        "timeReferenceLabel": "Określenie czasu",
        "timeReferences": {
          "EARLIEST_TIME_WINDOW_CURRENT_DATE": "początek pierwszego okna czasowego w odniesieniu do aktualnej daty",
          "EARLIEST_TIME_WINDOW_START_AFTER_CHANGE": "początek pierwszego okna czasowego (po przesunięciu)",
          "EARLIEST_TIME_WINDOW_START_BEFORE_CHANGE": "początek pierwszego okna czasowego (przed przesunięciem)",
          "LATEST_TIME_WINDOW_STOP": "koniec ostatniego okna czasowego",
          "TRANSPORT_DATE_CREATED": "utworzenie transportu"
        },
        "usersList": "Lista użytkowników"
      },
      "transportTypes": {
        "addAuctionSetting": "Dodaj ustawienia aukcji",
        "addTransactionSetting": "Dodaj ustawienia przekazywania",
        "auctionSetting": "Ustawienia aukcji",
        "create": "Utwórz typ transportu",
        "englishName": "Nazwa angielska",
        "group": "Grupa",
        "invalidCustomFieldId": "Customfield o podanym ID nie isnieje",
        "label": "Ustawienia typów transportu",
        "name": "Nazwa",
        "noAuctionSetting": "Nie dodano jeszcze ustawień aukcji",
        "noTransactionSetting": "Nie dodano jeszcze ustawień przekazywania zlecenia do przewoźnika",
        "packageTypeSettings": "Ustawienia opakowań",
        "permissions": "Uprawnienia",
        "permissionsSaveSuccess": "Poprawnie zapisaliśmy ustawienia uprawnień.",
        "selectRoleGroups": "Wybierz role, które będą miały możliwość tworzenia transportów typu",
        "settings": "Ustawienia typu transportu",
        "settingsDescription": {
          "adviceRequiresPhoneNumber": "Określa czy pole: \"Numer telefonu kierowcy\" będzie obowiązkowe podczas tworzenia awizacji",
          "adviceRestrictionInEveryStep": "Określa, czy pole \"Zakres czasu na awizację\" jest widoczne w każdym etapie",
          "adviceSavedWithTransport": "Dane awizacji zapisywane będą już podczas tworzenia transportu, wpisywanie danych awizacji odbywać się będzie w formularzu tworzenia nowego transportu",
          "adviceSeparatedFromWindow": "Określa, czy dodanie okna ma być oddzielnym etapem od dodania danych awizacji",
          "allowLoadStepTypeChange": "Określa, czy można zmieniać typ stepu załadunkowego w formularzu transportu pomiędzy etapem w Magazynie a etapem u Kontrahenta",
          "allowUnloadStepTypeChange": "Określa, czy można zmieniać typ stepu rozładunkowego w formularzu transportu pomiędzy etapem w Magazynie a etapem u Kontrahenta",
          "allowsAltIdentifier": "Określa widoczność dodatkowego identyfikatora zlecenia (altIdentifier). Pole to pojawia się w formularzu transportu pod nazwą ustawioną w ustawieniu: \"Etykieta kolumny z numerem zlecenia.\"",
          "allowsCarRegistrationNumber": "Określa widoczność pola: \"Numer rejestracyjny pojazdu\"",
          "allowsCarrier": "Określa czy firma typu \"Przewoźnik\" uczestniczy w transporcie; Określa widoczność pola \"Przewoźnik\" w formularzu zlecenia",
          "allowsContractor": "Określa czy firma typu \"Dostawca\" uczestniczy w transporcie; Określa widoczność pola \"Dostawca\" w formularzu zlecenia",
          "allowsDriverDocumentNumber": "Określa widoczność pola \"Numer dokumentu kierowcy\"",
          "allowsDriverName": "Określa widoczność pola \"Imię oraz Nazwisko kierowcy\" podczas tworzenia awizacji",
          "allowsErpIdentifier": "Określa widoczność identyfikatora zlecenia (erpIdentifier). Pole to pojawia się w formularzu transportu pod nazwą ustawioną w ustawieniu: \"ERP_IDENTIFIER_LABEL.\"",
          "allowsPeriodicTransport": "Określa, czy można wybrać opcję awizacji okresowej. Opcja będzie dostępna tylko dla wskazanych dostawców/odbiorców.",
          "allowsPhoneNumber": "Określa widoczność pola \"Numer telefonu kierowcy\" podczas tworzenia awizacji",
          "allowsPriceList": "Określa, czy typ transportu posiada \"Cenniki\"",
          "allowsRodoCheckbox": "Określa, czy warunek związany z RODO jest widoczny i wymagany w formularzu awizacji",
          "allowsService": "Określa czy firma typu \"Serwis\" uczestniczy w transporcie; Określa widoczność pola \"Serwis\" w formularzu zlecenia",
          "allowsTrailer": "Określa czy pole: \"Numer rejestracyjny naczepy/przyczepy\" będzie obowiązkowe podczas tworzenia awizacji",
          "allowsTransportSupervisor": "Określa widoczność pola: \"Osoba kontaktowa\" w formularzu podczas tworzenia transportu",
          "altIdentifierFormat": "Określa format automatycznego numeru \"altIdentifier\". Zmienne: $sequence - automatycznie generowany kolejny numer sekwencji, DD/MM/YYYY - możliwość wprowadzenia daty. Przykład: \"IN/$sequence/MM/YY\".",
          "altIdentifierIsCreatedAutomatically": "Określa czy pole identyfikator zlecenia (altIdentifier) ma być tworzony automatycznie. Przy włączonej opcji nie będzie możliwości edycji identyfikatora w formularzu zlecenia.",
          "altIdentifierRegex": "Określa wyrażenie regularne, które będzie użyte do sprawdzenia poprawności, wartości wprowadzonej w polu altIdentifier",
          "altIdentifierSequenceName": "Określa sekwencję, która wskazuje kolejny numer do automatycznie generowanego pola \"altIdentifier\"",
          "alternativeStepsLayout": "Każdy step będzie w osobnym wierszu",
          "calculatingTimeWindowSizeFromAlgorithm": "Określa, czy korzystamy z algorytmu określonego w magazynie przy liczeniu wielkości okna czasowego",
          "carrierMustBeSelectedBeforeAdvice": "Dodanie awizacji będzie możliwe dopiero po przypisaniu przewoźnika do transportu",
          "dateFilterProperty": "Określa pole, po którym będą domyślnie filtrowane zlecenia w archiwum.",
          "defaultLoadCompanyId": "Domyślna firma na załadunku",
          "defaultLoadStepEndHour": "Domyślna godzina końca zakresu czasu w etapach z kontrahentami",
          "defaultLoadStepStartHour": "Domyślna godzina początku zakresu czasu w etapach z kontrahentami",
          "defaultServicemanCompany": "Wybrana firma serwisowa będzie automatycznie ustawiana podczas tworzenia nowego transportu",
          "defaultUnloadCompanyId": "Domyślna firma na rozładunku",
          "dimensionsTranslationKey": "Klucz tłumaczeń dla pola \"Wymiary\"",
          "dimensionsVisibleInContractorStep": "Określa widoczność pola: \"Wymiary\" w formularzu tworzenia transportu (etap bez magazynu)",
          "dimensionsVisibleInWarehouseStep": "Określa widoczność pola: \"Wymiary\" w formularzu tworzenia transportu (etap z magazynem)",
          "documentIdentifierTranslationKey": "Klucz tłumaczeń dla pola \"Numer WZ\"",
          "duplicateStepCountryFieldToNewSteps": "Określa, czy pole \"Kraj\" powinno być automatycznie wypełnione w nowo dodanym stepie na podstawie wartości z pierwszego stepu niemagazynowego",
          "generatingTransportWeightReceiptEnabled": "Określa, czy w typie powinien być generowany kwit wagowy",
          "generatingWeightReceiptEnabled": "Włącza możliwość pobrania kwitu wagowego dla transportu. Kwit można pobrać w formularzu transportu w sekcji \"Ważenie\". Dodatkowo konto z rolą Ochrony kwit pobrać może bezpośrednio z tabeli transportów",
          "guardCheckpointConfirmationSingleColumnLayout": "Określa, czy mamy generować widok gdzie przyciski oraz daty potwierdzeń statusów ze stepów widoczne są dla ochrony w pojedynczej kolumnie dla danego stepu",
          "hasCmrDocumentEnabled": "Określa czy typ transportu posiada dokument CMR, będzie on dostępny do pobrania w sekcji \"Dokumenty\" oraz w tabeli transportów w kolumnie dokumentów",
          "hasDiscrepancyReportEnabled": "Określa czy sekcja: \"Protokół Niezgodności\" jest widoczna",
          "hasEntrancePassDocumentEnabled": "Określa czy w typie transportu po dodaniu awizacji, utworzona zostanie przepustka wjazdowa, będzie on dostępny do pobrania w sekcji \"Dokumenty\" oraz w tabeli transportów w kolumnie dokumentów",
          "hasProductSpecificationDocumentEnabled": "Określa czy typ transportu posiada dokument \"Specyfikacja Produktowa\", będzie on dostępny do pobrania w sekcji \"Dokumenty\" oraz w tabeli transportów w kolumnie dokumentów",
          "hasVisibleSteps": "Określa, czy pola związane z etapami transportu będą widoczne w formularzu",
          "idx": "Decyduje o kolejności wyświetlania transportów na liście",
          "isContactDetailsFieldsRequired": "Obligatoryjność pól osoba kontaktowa, numer telefonu osoby kontaktowej",
          "isContactDetailsFieldsVisible": "Widoczność pól osoba kontaktowa, numer telefonu osoby kontaktowej w fromularzu zlecenia",
          "isInternalReleaseNumberVisibleInContractorStep": "Określa widoczność pola: \"Numer WZ\" w formularzu tworzenia transportu (etap bez magazynu)",
          "isInternalReleaseNumberVisibleInWarehouseStep": "Określa widoczność pola: \"Numer WZ\" w formularzu tworzenia transportu (etap z magazynem)",
          "isLoadStepInWarehouseByDefault": "Określa, czy podczas tworzenia transportu etap załadunkowy będzie domyślnie w magazynie (możliwość zmiany, gdy \"allowLoadStepTypeChange == true\")",
          "isReadyToPlanObligatory": "Obligatoryjność pola: \"Gotowe do planowania\" w formularzu zlecenia.",
          "isReadyToPlanVisible": "Widoczność pola: \"Gotowe do planowania\" w formularzu zlecenia,",
          "isUnloadStepInWarehouseByDefault": "Określa, czy podczas tworzenia transportu etap rozładunkowy będzie domyślnie w magazynie (możliwość zmiany, gdy \"allowUnloadStepTypeChange == true\")",
          "isVolumeVisibleInContractorStep": "Określa widoczność pola: \"Objętość\" w formularzu tworzenia transportu (etap bez magazynu)",
          "isVolumeVisibleInWarehouseStep": "Określa widoczność pola: \"Objętość\" w formularzu tworzenia transportu (etap z magazynem)",
          "isWarehouseLocationObligatory": "Obligatoryjność pola: \"Lokalizacja\" w formularzu zlecenia.",
          "isWarehouseLocationVisible": "Widoczność pola: \"Lokalizacja\" w formularzu zlecenia,",
          "isWarehouseZoneVisible": "Określa widoczność pola: \"Strefa/Asortyment\" w formularzu podczas tworzenia transportu",
          "isWeightVisibleInContractorStep": "Określa widoczność pola: \"Waga\" w formularzu tworzenia transportu (etap bez magazynu)",
          "isWeightVisibleInWarehouseStep": "Określa widoczność pola: \"Waga\" w formularzu tworzenia transportu (etap z magazynem)",
          "maximumPermissableVehicleWeight": "Określa widoczność komunikatu o przekroczeniu wagi podczas ważenia",
          "newAddressAsDefaultInLoadStep": "Po wyborze firmy w stepie załadunkowym zostanie wyświetlony widok dodawania nowego adresu",
          "newAddressAsDefaultInUnloadStep": "Po wyborze firmy w stepie rozładunkowym zostanie wyświetlony widok dodawania nowego adresu",
          "newAddressCityAutocompleteEnabled": "Podpowiadanie adresu w polu z miastem podczas dodawania nowego adresu w formularzu zlecenia",
          "numberOfDaysAddedToFirstStep": "Określa ilość dni dodawaną do planowanej daty lub zakresu czasu w formularzu zlecenia dla pierwszego etapu",
          "numberOfDaysAddedToFirstStepEnd": "Ilość dni dodawana do końca zakresu czasu w formularzu zlecenia dla pierwszego etapu:",
          "numberOfDaysAddedToNextSteps": "Określa ilość dni dodawaną do planowanej daty lub zakresu czasu w formularzu zlecenia dla drugiego i każdego kolejnego etapu",
          "numberOfDaysAddedToNextStepsEnd": "Ilość dni dodawana do końca zakresu czasu w formularzu zlecenia dla drugiego i każdego kolejnego etapu:",
          "participatesInCombiningTransports": "Określa czy ten typ transportu uczestniczy w trakcie tworzenia relacji transportów. Ten typ transportu będzie \"nadzleceniem\"",
          "pastingFromExcelEnabled": "Możliwość wklejania danych awizacji z pliku Excel (pole w formularzu awizacji)",
          "purchaseOrderNumberTranslationKey": "Klucz tłumaczeń dla pola \"Numer zamówienia\"",
          "purchaseOrderNumberVisibleInContractorStep": "Określa widoczność pola: \"Numer zamówienia\" w formularzu tworzenia transportu (etap bez magazynu)",
          "purchaseOrderNumberVisibleInWarehouseStep": "Określa widoczność pola: \"Numer zamówienia\" w formularzu tworzenia transportu (etap z magazynem)",
          "quantitySumCheckValue": "Określa liczbę palet, po której w formularzu transportu wyświetli się komunikat z ostrzeżeniem",
          "randomTransportInspection": "Określa, czy transport może być brany do kontroli",
          "requiresAddress": "Określa czy do etapu transportu niezwiązanego z magazynem trzeba dodać adres",
          "requiresAdvice": "Obligatoryjność danych awizacji - wyłączenie tej opcji oznacza, że formularz oraz dane awizacji pozostaną puste",
          "requiresAdviceDateRestrictions": "Wymaga podania zakresu dat oraz godzin na awizację",
          "requiresAltIdentifier": "Określa czy pole: \"altIdentifier\" będzie widoczne i obowiązkowe podczas tworzenia transportu. Pole to pojawia się w formularzu transportu pod nazwą ustawioną w ustawieniu: \"Etykieta kolumny z numerem zlecenia\"",
          "requiresArrivalAndDepartureDate": "Określa, czy pola \"Zlecona data odbioru\"/\"Zlecona data dostawy\" są widoczne w formularzu transportu",
          "requiresCarRegistrationNumber": "Określa czy pole: Numer rejestracyjny pojazdu będzie obowiązkowe",
          "requiresCarrier": "Określa czy pole: \"Przewoźnik\" będzie obowiązkowe podczas tworzenia transportu",
          "requiresContractor": "Do zapisania transportu wymagane będzie podanie danych Dostawcy/Odbiorcy. Jeżeli w zleceniu będą widoczne etapy (steps) nie związane z magazynem, kontrahenta będzie można wybrać w tej sekcji. W przeciwnym razie kontrahenta wybrać będzie można w głównej części formularza tworzenia transportu.",
          "requiresContractorStepCompanyGroup": "Określa czy pole: \"Grupa kontrahenta\" będzie obowiązkowe podczas tworzenia transportu",
          "requiresDriverDocumentNumber": "Określa czy pole: \"Numer dokumentu kierowcy\" będzie obowiązkowe podczas tworzenia awizacji",
          "requiresDriverLanguage": "Określa widoczność i obligatoryjność pola \"Język kierowcy\"",
          "requiresDriverName": "Określa czy pole: \"Imię oraz Nazwisko kierowcy\" będzie obowiązkowe podczas tworzenia awizacji",
          "requiresErpIdentifier": "Określa czy pole: \"erpIdentifier\" będzie widoczne i obowiązkowe podczas tworzenia transportu. Pole to pojawia się w formularzu transportu pod nazwą ustawioną w ustawieniu: \"ERP_IDENTIFIER_LABEL\"",
          "requiresModeOfTransportationVerification": "Określa czy podczas awizacji podpowiadane są wszystkie pojazdy przewoźnika, czy jedynie pojazdy zweryfikowane",
          "requiresService": "Określa czy pole: \"Serwis\" będzie obowiązkowe podczas tworzenia transportu",
          "requiresStepsWeight": "Określa czy pole: \"Waga\" będzie obowiązkowe podczas tworzenia transportu",
          "requiresTimeWindow": "Do zapisania transportu wymagane jest wybranie okna czasowego. Oznacza to, że po zapisaniu formularza transportu użytkownik bezpośrednio zostanie przeniesiony do harmonogramu w celu wybrania wolnego miejsca na okno czasowe.",
          "requiresTrailer": "Określa czy pole: \"Numer rejestracyjny naczepy/przyczepy\" będzie obowiązkowe podczas tworzenia awizacji",
          "requiresUniqueErpIdentifier": "Określa czy pole: \"erpIdentifier\" powinno mieć unikalne wartości. Przy sprawdzaniu unikalności nie są brane pod uwagę usunięte zlecenia.",
          "requiresWarehouse": "Do zapisania transportu wymagane jest wybranie magazynu. Zaznaczenie tej opcji \"Obligatoryjność wyboru okna czasowego przy tworzeniu transportu\" również spowoduje wymóg podania magazynu.",
          "requiresWorkers": "Obligatoryjność pola \"Pracownicy\"",
          "selectionOfWindowBeforeAdvice": "Określa, czy wybór okna ma być przed dodaniem danych awizacji",
          "shouldCopyAdviceOnTransportCopy": "Określa, czy dane awizacji mają być kopiowane przy opcji \"Utwórz podobne\"",
          "showAddressFieldsInContractorSteps": "Określa, czy pola adresowe powinny być widoczne w etapach z kontrahentami",
          "transportSupervisorTranslationKey": "Określa wyświetlaną nazwę pola \"Osoba kontaktowa\" w formularzu tworzenia transportu",
          "transportTypeName": "Określa nazwę typu transportu",
          "transportTypeNameEn": "Określa nazwę angielską typu transportu",
          "windowColorDependsOnWarehouse": "Określa, czy kolor okna czasowego zależy tylko od statusów związanych z danym oknem",
          "workerFieldTranslationKey": "Określa wyświetlaną nazwę pola \"Pracownik\" w formularzu tworzenia transportu"
        },
        "settingsGroup": {
          "advice": "Ustawienia związane z awizacją",
          "adviceForm": "Ustawienia związane z formularzem awizacji",
          "auctionMails": "Ustawienia związane z powiadomieniami mailowymi",
          "auctionOffers": "Ustawienia związane z wysokością ofert",
          "auctionPass": "Ustawienia związane z przekazywaniem zlecenia do przewoźnika",
          "general": "Ustawienia ogólne",
          "identifier": "Ustawienia związane z identyfikatorem transportu",
          "index": "Ustawienia związane z indeksami",
          "offerForm": "Ustawienia związane z formularzem składania ofert",
          "others": "Inne",
          "step": "Ustawienia związane z etapami transportu",
          "transaction": "Ustawienia związane z przekazywaniem do przewoźnika",
          "transport": "Ustawienia związane z transportem",
          "transportForm": "Ustawienia związane z formularzem transportu",
          "transportType": "Ustawienia związane z całym typem transportu"
        },
        "settingsLabel": {
          "adviceRequiresPhoneNumber": "Obligatoryjność pola: Numer telefonu kierowcy",
          "adviceRestrictionInEveryStep": "Pole \"Zakres czasu na awizację\" widoczne w każdym etapie",
          "adviceSavedWithTransport": "Dane awizacji zapisywane już podczas tworzenia transportu",
          "adviceSeparatedFromWindow": "Określa, czy dodanie okna ma być oddzielnym etapem od dodania danych awizacji",
          "allowLoadStepTypeChange": "Widoczność pola wyboru \"Magazyn\" w etapie załadunkowym",
          "allowUnloadStepTypeChange": "Widoczność pola wyboru \"Magazyn\" w etapie rozładunkowym",
          "allowsAltIdentifier": "Widoczność dodatkowego identyfikatora zlecenia \"altIdentifier\" w formularzu",
          "allowsCarRegistrationNumber": "Widoczność pola: \"Numer rejestracyjny pojazdu\"",
          "allowsCarrier": "Uwzględnij Przewoźnika w transporcie",
          "allowsContractor": "Uwzględnij Dostawcę w transporcie",
          "allowsDriverDocumentNumber": "Widoczność pola \"Numer dokumentu kierowcy\"",
          "allowsDriverName": "Widoczność pola \"Imię i nazwisko kierowcy\" w formularzu awizacji",
          "allowsErpIdentifier": "Widoczność identyfikatora zlecenia \"erpIdentifier\" w formularzu",
          "allowsPeriodicTransport": "Określa, czy można dodać awizacje okresowe",
          "allowsPhoneNumber": "Widoczność pola \"Numer telefonu kierowcy\" w formularzu awizacji",
          "allowsPriceList": "Określa, czy typ transportu posiada \"Cenniki\"",
          "allowsRodoCheckbox": "Włączenie warunku RODO",
          "allowsService": "Uwzględnij Serwis w transporcie",
          "allowsTrailer": "Widoczność pola: \"Numer rejestracyjny naczepy/przyczepy\"",
          "allowsTransportSupervisor": "Widoczność pola: \"Osoba kontaktowa\" w formularzu zlecenia",
          "altIdentifierFormat": "Format automatycznie generowanego numeru \"altIdentifier\"",
          "altIdentifierIsCreatedAutomatically": "Automatyczne tworzenie dodatkowego numeru zlecenia - \"altIdentifier\"",
          "altIdentifierRegex": "Wyrażenie regularne do walidacji pola \"altIdentifier\"",
          "altIdentifierSequenceName": "Nazwa sekwencji dla pola \"altIdentifier\"",
          "alternativeStepsLayout": "Alternatywny widok stepów w formularzu zlecenia",
          "auctionSetting": {
            "addFileToTransportAfterOfferAcceptation": "Automatycznie załącz plik do zlecenia po przyjęciu zlecenia przez przewoźnika",
            "addFileToTransportAfterOfferAcceptationName": "Nazwa pliku załączanego do zlecenia po przyjęciu zlecenia przez przewoźnika",
            "auctionEmailSubjectEn": "Temat powiadomienia mailowego o aukcji - język angielski",
            "auctionEmailSubjectPl": "Temat powiadomienia mailowego o aukcji",
            "auctionEndedWithoutOffersEmailFileName": "Wersja maila o zakończeniu aukcji bez ofert",
            "auctionProlongationOffsetInterval": "Czas o jaki ma zostać wydłużona aukcja w przypadku zmiany lidera",
            "canAcceptWithMinPrice": "Wygranie aukcji przez przewoźnika po osiągnięciu ceny minimalnej",
            "defaultAuctionFinishDelayInterval": "Domyślny czas trwania aukcji",
            "defaultCurrency": "Domyślna waluta aukcji",
            "exportAuctionInfoToExcel": "Eksportuj dane aukcji do pliku Excel ze zleceniami",
            "minOfferReductionSize": "Minimalna wartość o jaką należy obniżyć ofertę",
            "newAuctionEmailFileName": "Wersja maila o nowej aukcji",
            "reminderTimeAboutAuction": "Czas, który decyduje o momencie wysłania przypomienia o trwającej aukcji",
            "requireMaxPrice": "Wymagaj podania ceny maksymalnej przy tworzeniu aukcji",
            "requireMinOfferReductionSizeValidation": "Zastosuj walidację na minimalną wartość obniżenia ceny",
            "requireMinPrice": "Wymagaj podania ceny minimalnej przy tworzeniu aukcji",
            "requireReasonIfNotBestOfferIsChosen": "Wymagaj podania przyczyny w przypadku wyboru nie najlepszej oferty",
            "selectAllCarriersByDefault": "Domyślnie wybierz wszystkich przewoźników do aukcji",
            "shouldPassToCarrierAfterAuction": "Automatyczne przekazanie zlecenia do zwycięzcy aukcji",
            "showBlockedCompaniesSection": "Widoczność sekcji z zablokowanymi firmami przy tworzeniu aukcji",
            "showCompanyGroupsSection": "Widoczność sekcji z grupowaniem przewoźników przy tworzeniu aukcji",
            "showDescriptionSection": "Widoczność pola na notatki w formularzu składania ofert",
            "showFinishAuctionButton": "Widoczność przycisku \"Zakończ aukcję\" dla trwającej aukcji",
            "showPassToCarrierButton": "Widoczność przycisku \"Przekaż\"",
            "showPriceRangeFields": "Widoczność zakresu cen przy tworzeniu aukcji",
            "showQuantityFieldInOfferForm": "Widoczność ilości palet w formularzu składania ofert",
            "showRevokeOfferButton": "Widoczność przycisku umożliwiającego anulowanie złożonej oferty",
            "showStepDataInOfferForm": "Widoczność miejsc załadunku/rozładunku w formularzu składania ofert",
            "translationKey": "Klucz tłumaczenia"
          },
          "calculatingTimeWindowSizeFromAlgorithm": "Obliczanie wielkości okna na podstawie algorytmu",
          "carrierMustBeSelectedBeforeAdvice": "Określa, czy można dodać awizację bez wybrania Przewoźnika",
          "dateFilterProperty": "Domyślny filtr w archiwum: ",
          "defaultLoadCompanyId": "Domyślna firma na załadunku",
          "defaultLoadStepEndHour": "Domyślna godzina końca zakresu czasu w etapach z kontrahentami",
          "defaultLoadStepStartHour": "Domyślna godzina początku zakresu czasu w etapach z kontrahentami",
          "defaultServicemanCompany": "Domyślna firma typu serwis",
          "defaultUnloadCompanyId": "Domyślna firma na rozładunku",
          "dimensionsTranslationKey": "Klucz tłumaczeń dla pola \"Wymiary\" (DIMENSIONS)",
          "dimensionsVisibleInContractorStep": "Widoczność pola: \"Wymiary\" w formularzu zlecenia (etap bez magazynu)",
          "dimensionsVisibleInWarehouseStep": "Widoczność pola: \"Wymiary\" w formularzu zlecenia (etap z magazynem)",
          "documentIdentifierTranslationKey": "Klucz tłumaczeń dla pola \"Numer WZ\" (DELIVERY_NOTE_NUMBER, CUSTOMERS_ORDER_NUMBER, ISSUE_ORDER_NUMBER)",
          "duplicateStepCountryFieldToNewSteps": "Określa, czy pole \"Kraj\" powinno być automatycznie wypełnione w nowo dodanym stepie na podstawie wartości z pierwszego stepu niemagazynowego",
          "generatingTransportWeightReceiptEnabled": "Określa, czy w typie powinien być generowany kwit wagowy",
          "generatingWeightReceiptEnabled": "Możliwość wygenerowania kwitu wagowego",
          "guardCheckpointConfirmationSingleColumnLayout": "Określa, czy mamy generować widok gdzie przyciski oraz daty potwierdzeń statusów ze stepów widoczne są dla ochrony w pojedynczej kolumnie dla danego stepu",
          "hasCmrDocumentEnabled": "Określa czy typ transportu posiada dokument CMR",
          "hasDiscrepancyReportEnabled": "Widoczność sekcji: \"Protokół Niezgodności\" w formularzu zlecenia",
          "hasEntrancePassDocumentEnabled": "Określa czy po dodaniu awizacji, utworzona zostanie przepustka wjazdowa",
          "hasProductSpecificationDocumentEnabled": "Określa czy typ transportu posiada dokument \"Specyfikacja Produktowa\"",
          "hasVisibleSteps": "Widoczność sekcji etapów transportu w formularzu",
          "idx": "Idx:",
          "isContactDetailsFieldsRequired": "Obligatoryjność pól osoba kontaktowa, e-mail, numer telefonu osoby kontaktowej",
          "isContactDetailsFieldsVisible": "Widoczność pól osoba kontaktowa, e-mail, numer telefonu osoby kontaktowej w formularzu zlecenia",
          "isInternalReleaseNumberVisibleInContractorStep": "Widoczność pola: \"Numer WZ\" w formularzu zlecenia (etap bez magazynu)",
          "isInternalReleaseNumberVisibleInWarehouseStep": "Widoczność pola: \"Numer WZ\" w formularzu zlecenia (etap z magazynem)",
          "isLoadStepInWarehouseByDefault": "Etap załadunkowy domyślnie w magazynie",
          "isReadyToPlanObligatory": "Obligatoryjność pola: \"Gotowe do planowania\" w formularzu zlecenia.",
          "isReadyToPlanVisible": "Widoczność pola: \"Gotowe do planowania\" w formularzu zlecenia,",
          "isUnloadStepInWarehouseByDefault": "Etap rozładunkowy domyślnie w magazynie",
          "isVolumeVisibleInContractorStep": "Widoczność pola: \"Objętość\" w formularzu zlecenia (etap bez magazynu)",
          "isVolumeVisibleInWarehouseStep": "Widoczność pola: \"Objętość\" w formularzu zlecenia (etap z magazynem)",
          "isWarehouseLocationObligatory": "Obligatoryjność pola: \"Lokalizacja\" w formularzu zlecenia.",
          "isWarehouseLocationVisible": "Widoczność pola: \"Lokalizacja\" w formularzu zlecenia,",
          "isWarehouseZoneVisible": "Widoczność pola: \"Strefa/Asortyment\" w formularzu zlecenia",
          "isWeightVisibleInContractorStep": "Widoczność pola: \"Waga\" w formularzu zlecenia (etap bez magazynu)",
          "isWeightVisibleInWarehouseStep": "Widoczność pola: \"Waga\" w formularzu zlecenia (etap z magazynem)",
          "maximumPermissableVehicleWeight": "Wyświetlaj komunikat o przekroczeniu dopuszczalnej masy pojazdu",
          "newAddressAsDefaultInLoadStep": "Po wyborze firmy w stepie załadunkowym zostanie wyświetlony widok dodawania nowego adresu",
          "newAddressAsDefaultInUnloadStep": "Po wyborze firmy w stepie rozładunkowym zostanie wyświetlony widok dodawania nowego adresu",
          "newAddressCityAutocompleteEnabled": "Podpowiadanie adresu w polu z miastem podczas dodawania nowego adresu w formularzu zlecenia",
          "numberOfDaysAddedToFirstStep": "Ilość dni dodawana do planowanej daty lub zakresu czasu w formularzu zlecenia dla pierwszego etapu:",
          "numberOfDaysAddedToFirstStepEnd": "Ilość dni dodawana do końca zakresu czasu w formularzu zlecenia dla pierwszego etapu:",
          "numberOfDaysAddedToNextSteps": "Ilość dni dodawana do planowanej daty lub zakresu czasu w formularzu zlecenia dla drugiego i każdego kolejnego etapu:",
          "numberOfDaysAddedToNextStepsEnd": "Ilość dni dodawana do końca zakresu czasu w formularzu zlecenia dla drugiego i każdego kolejnego etapu:",
          "participatesInCombiningTransports": "Bierze udział w tworzeniu relacji transportów typu COMBINING",
          "pastingFromExcelEnabled": "Możliwość wklejania awizacji z Excel",
          "purchaseOrderNumberTranslationKey": "Klucz tłumaczeń dla pola \"Numer zamówienia\" (SD_NUMBER)",
          "purchaseOrderNumberVisibleInContractorStep": "Widoczność pola: \"Numer zamówienia\" w formularzu zlecenia (etap bez magazynu)",
          "purchaseOrderNumberVisibleInWarehouseStep": "Widoczność pola: \"Numer zamówienia\" w formularzu zlecenia (etap z magazynem)",
          "quantitySumCheckValue": "Ilość palet, po której zostanie wyświetlone ostrzeżenie: ",
          "randomTransportInspection": "Wymaga losowych kontroli",
          "requiresAddress": "Obligatoryjność podania adresu w przypadku etapu bez magazynu",
          "requiresAdvice": "Obligatoryjność danych awizacji",
          "requiresAdviceDateRestrictions": "Widoczność i obligatoryjność pola: Zakres czasu na awizację",
          "requiresAltIdentifier": "Obligatoryjność pola: \"altIdentifier\"",
          "requiresArrivalAndDepartureDate": "Widoczność i obligatoryjność pola: \"Zlecona data odbioru\"/\"Zlecona data dostawy\"",
          "requiresCarRegistrationNumber": "Obligatoryjność pola: Numer rejestracyjny pojazdu",
          "requiresCarrier": "Obligatoryjność pola: Przewoźnik",
          "requiresContractor": "Obligatoryjność danych: Dostawca/Odbiorca",
          "requiresContractorStepCompanyGroup": "Obligatoryjność pola: Grupa kontrahenta",
          "requiresDriverDocumentNumber": "Obligatoryjność pola: Numer dokumentu kierowcy",
          "requiresDriverLanguage": "Widoczność i obligatoryjność pola \"Język kierowcy\"",
          "requiresDriverName": "Obligatoryjność pola: Imię oraz Nazwisko kierowcy",
          "requiresErpIdentifier": "Obligatoryjność pola: \"erpIdentifier\"",
          "requiresModeOfTransportationVerification": "Weryfikacja środków transportu (funkcjonalność działa tylko z włączoną funkcjonalnością przekazywania do przewoźnika)",
          "requiresService": "Obligatoryjność pola: Serwis",
          "requiresStepsWeight": "Obligatoryjność pola: Waga",
          "requiresTimeWindow": "Obligatoryjność wyboru okna czasowego przy tworzeniu transportu",
          "requiresTrailer": "Obligatoryjność pola: Numer rejestracyjny naczepy/przyczepy",
          "requiresUniqueErpIdentifier": "Unikalność pola \"erpIdentifier\"",
          "requiresWarehouse": "Obligatoryjność wyboru magazynu przy tworzeniu transportu",
          "requiresWorkers": "Obligatoryjność pola \"Pracownicy\"",
          "selectionOfWindowBeforeAdvice": "Określa, czy wybór okna ma być przed dodaniem danych awizacji",
          "shouldCopyAdviceOnTransportCopy": "Określa, czy dane awizacji mają być kopiowane przy opcji \"Utwórz podobne\"",
          "showAddressFieldsInContractorSteps": "Widoczność pól adresowych w etapach z kontrahentami\"",
          "supplierFieldTranslationKey": "Klucz tłumaczeń dla pola \"Dostawca/odbiorca\" (CLIENT ,COMPANY, COURIER)",
          "transportSupervisorTranslationKey": "Klucz tłumaczeń dla pola \"Osoba kontaktowa\" (CONTACT_PERSON, INVITEE, ORDERER, PERSON_TO_NOTIFY, HOST_PERSON)",
          "transportTypeName": "Nazwa typu transportu",
          "transportTypeNameEn": "Nazwa angielska typu transportu",
          "windowColorDependsOnWarehouse": "Określa, czy kolor okna czasowego zależy tylko od statusów związanych z danym oknem",
          "workerFieldTranslationKey": "Klucz tłumaczeń dla pola \"Pracownicy\" (WORKERS, GUESTS, SERVICEMEN)"
        },
        "settingsSaveSuccess": "Poprawnie zapisaliśmy ustawienia typu transportu",
        "transactionSetting": "Ustawienia przekazywania",
        "workflow": "Etapy i statusy"
      },
      "updateSuccessMessage": "Poprawnie zapisaliśmy zmiany ustawień",
      "value": "Wartość"
    },
    "square": {
      "SQUARE": {
        "addQueue": "Dodaj kolejkę",
        "buffer": "Bufor",
        "carsInFrontOfSmelter": "Auta przed brakarnią",
        "classificationTab": "Klasyfikacja",
        "create": "Utwórz plac surowca",
        "createPlace": "Dodaj miejsce",
        "label": "Place surowców",
        "placeLabel": "Miejsce",
        "queueLabel": "Kolejka",
        "queueStatusTab": "Stan kolejek",
        "queuesStatus": "Stan kolejek (za brakarnią)",
        "queuesStatusTableHeader": "Podsumowanie aktualnego stanu kolejek",
        "queuesTab": "Kolejki",
        "square": "Plac",
        "unload": "Rozładunek"
      },
      "createQueueAfterSave": "Kolejki będzie można ustawić po zapisaniu placu.",
      "disableConfirm": "Na pewno chcesz wyłączyć ten plac?",
      "updateSuccessMessage": "Poprawnie zapisaliśmy zmiany ustawień placu {squareName}."
    },
    "squareSetting": {
      "bufferSecondTableColumnVisibility": "Widoczność kolumny (2 tabela) \"Bufor\"",
      "carRegistrationFirstTableColumnVisibility": "Widoczność kolumny (1 tabela) \"Numer rejestracyjny\"",
      "hdsFirstTableColumnVisibility": "Widoczność kolumny (1 tabela) \"HDS\"",
      "label": "Parametry placów i kolejek",
      "orderNumberFirstColumnVisibility": "Widoczność kolumny (1 tabela) \"Numer zlecenia\"",
      "queueFirstTableColumnVisibility": "Widoczność kolumny (1 tabela) \"Kolejka\"",
      "queueSecondTableColumnVisibility": "Widoczność kolumny (2 tabela) \"Kolejka\"",
      "squareFirstTableColumnVisibility": "Widoczność kolumny (1 tabela) \"Plac\"",
      "squareSecondTableColumnVisibility": "Widoczność kolumny (2 tabela) \"Plac\"",
      "translationKey": "Klucz tłumaczenia",
      "unloadSecondTableColumnVisibility": "Widoczność kolumny (2 tabela) \"Rozładunek\""
    },
    "statuses": {
      "deleted": "Usunięty",
      "finished": "Zakończony",
      "initial": "Zaawizowany",
      "movedToArchiveDueDelay": "Opóźniony",
      "waitingForConfirmation": "Oczekuje na potwierdzenie"
    },
    "statute": {
      "accept": {
        "disabled.title": "Proszę zaznaczyć oświadczenie akceptacji regulaminu.",
        "header": "Drogi użytkowniku",
        "inTouch": "Rozumiem, będziemy w kontakcie!",
        "informed": "Rozumiem, czuję się poinformowany.",
        "label": "Rozumiem, wyrażam na to zgodę.",
        "manual": "Przeczytałem, rozumiem i akceptuję Instrukcję bezpieczeństwa.",
        "paragraph_1": "1. LOGINTEGRA Spółka z ograniczoną odpowiedzialnością z siedzibą w Gdańsku, ul. Lęborska 3b, wpisaną do Rejestru Przedsiębiorców prowadzonego przez Sąd Rejonowy Gdańsk - Północ w Gdańsku, VII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000426238, NIP 584-272-80-64, REGON 221707056 jest administratorem Twoich danych osobowych, o których mowa w RODO, znajdujących się w tym Systemie. Dane kontaktowe administratora są następujące: tel.: +48 58 535 78 06, e-mail: info@logintegra.com. Dane te mogą być udostępnione innym użytkownikom zaproszonym do Systemu lub tym, od których zaproszenie zaakceptowali pracownicy Twojej firmy, a także innym podmiotom opisanym w poniższej informacji. ",
        "paragraph_10": "10. W przypadku jakichkolwiek pytań, wątpliwości lub prośby dotyczącej przetwarzania Twoich danych, najłatwiej kontaktować się z nami pisząc na adres e-mail: info@logintegra.com",
        "paragraph_2": "2. Twoje Dane osobowe będą przetwarzane, wyłącznie w celu wykonania umów zawartych pomiędzy Administratorem i innymi Użytkownikami, realizacji obowiązków i praw wiążących się z zawartymi umowami, realizacji poszczególnych funkcjonalności Systemu, oraz w celu realizacji obowiązków wynikających z przepisów prawa, a także w zakresie w jakim wyrazisz zgodę na przetwarzanie Twoich danych. ",
        "paragraph_3": "3. System, którego jesteś użytkownikiem służy do współpracy pomiędzy przedsiębiorstwami Użytkowników w ramach obsługi procesów logistycznych przedsiębiorstw Użytkowników, w związku z tym dane osobowe mogą być widoczne dla innych Użytkowników Systemu lub mogą być im wysyłane lub przekazywane. Twoje dane osobowe mogą również być przekazywane w innych przypadkach, przewidzianych przepisami prawa, w szczególności: ",
        "paragraph_3_1": "organom administracji skarbowej - w zakresie w jakim przekazanie danych osobowych stanowi obowiązek wynikający z przepisów podatkowych;",
        "paragraph_3_2": "organom administracji publicznej, w tym m.in. policji, sądom, prokuraturze, organom realizującym zadania z zakresu ubezpieczeń społecznych, w zakresie w jakim przekazanie danych osobowych stanowi obowiązek wynikający z przepisów prawa,",
        "paragraph_3_3": "zaufanym podwykonawcom, którzy wykonują w naszym imieniu obsługę pomocy technicznej lub świadczą usługi hostingowe;",
        "paragraph_3_4": "podmiotom świadczącym usługi księgowe, podatkowe oraz prawne",
        "paragraph_4": "4. Twoje dane osobowe będą przetwarzane przez okres niezbędny dla realizacji celów określonych w pkt 2 lub do czasu wycofania zgody. Niektóre kategorie danych mogą być przechowywane dłużej, jeśli obowiązek taki wynika z przepisów prawa, np. dane zawarte w wystawionych fakturach przechowywane będą przez administratora do czasu upływu ustawowych terminów przechowywania faktur dla celów podatkowych.",
        "paragraph_5": "5. Podanie przez Ciebie danych osobowych jest dobrowolne, ale korzystanie z systemu, z uwagi na jego specyfikę, wymaga podania pewnych kategorii danych. Jeśli nie podasz prawdziwych danych osobowych wymaganych w formularzu rejestracyjnym lub wycofasz zgodę na ich przetwarzanie, używanie Systemu nie będzie możliwe.",
        "paragraph_6": "6. Masz prawo do:",
        "paragraph_6_1": "dostępu do treści swoich danych;",
        "paragraph_6_2": "sprostowania swoich danych,",
        "paragraph_6_3": "usunięcia lub ograniczenia przetwarzania swoich danych,",
        "paragraph_6_4": "wniesienia sprzeciwu wobec przetwarzania swoich danych",
        "paragraph_6_5": "przenoszenia danych;",
        "paragraph_6_6": "cofnięcia zgody na przetwarzanie danych w dowolnym momencie, które pozostaje bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody, przed jej cofnięciem.",
        "paragraph_6_bottom": "Ponadto masz również prawo do wniesienia skargi do organu nadzorczego, gdy uznasz, że przetwarzanie Twoich danych osobowych narusza przepisy prawa. ",
        "paragraph_7": "7. Szczegółowe kwestie związane z ochroną danych są regulowane w naszych dokumentach:",
        "paragraph_7_1": "Polityce przetwarzania danych;",
        "paragraph_7_2": "Instrukcji bezpieczeństwa,",
        "paragraph_7_3": "Regulaminie używania Systemu.",
        "paragraph_7_bottom": "Aby uzyskać dostęp do dokumentów, kliknij na ich nazwę.",
        "paragraph_8": "8. Nasz System używa plików cookies (ciasteczek).",
        "paragraph_9": "9. Pamiętaj, że w systemie przetwarzane są dane osobowe. Wprowadzając do systemu dane osób trzecich. np. kierowców musisz zadbać o spełnienie obowiązków ciążących na administratorze danych osobowych określonych w RODO. Obowiązki te obejmują między innymi poinformowanie osoby, której dane są przetwarzane o:",
        "paragraph_9_1": "tożsamości administratora i jego danych kontaktowych;",
        "paragraph_9_2": "celach przetwarzania danych osobowych, oraz podstawie prawnej przetwarzania;",
        "paragraph_9_3": "informacjach o odbiorcach danych osobowych lub o kategoriach odbiorców, jeżeli istnieją;",
        "paragraph_9_4": "okresie, przez który dane osobowe będą przechowywane;",
        "paragraph_9_5": "o prawie do żądania od administratora dostępu do danych osobowych dotyczących osoby, której dane dotyczą, ich sprostowania, usunięcia lub ograniczenia przetwarzania lub o prawie do wniesienia sprzeciwu wobec przetwarzania, a także o prawie do przenoszenia danych;",
        "paragraph_9_6": "jeżeli przetwarzanie odbywa się na podstawie zgody osoby - informacji o prawie do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem;",
        "paragraph_9_7": "prawie wniesienia skargi do organu nadzorczego;",
        "paragraph_9_8": "tym, czy podanie danych osobowych jest wymogiem ustawowym lub umownym lub warunkiem zawarcia umowy oraz czy osoba, której dane dotyczą, jest zobowiązana do ich podania i jakie są ewentualne konsekwencje niepodania danych.",
        "paragraph_9_bottom": "Pamiętaj, aby zadbać o spełnienie obowiązków administratora. Jeśli dowiesz się o możliwym naruszeniu zasad przetwarzania danych osobowych - niezwłocznie nas poinformuj.",
        "policy": "Przeczytałem, rozumiem i akceptuję Politykę przetwarzania danych.",
        "statute": "Przeczytałem, rozumiem i akceptuję Regulamin używania Systemu",
        "subHeader": "W związku z tym, że wchodzą w życie przepisy rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych), opublikowanego w dzienniku urzędowym Unii Europejskiej seria L z 2016 r. Nr 119, str. 1. Dla zachowania przejrzystości treści będziemy to rozporządzenie nazywać skrótowo RODO. Wykonując obowiązki nałożone przez RODO na administratorów i przetwarzających dane osobowe, chcielibyśmy poinformować o kilku istotnych kwestiach z tym związanych. Prosimy o uważne zapoznanie się z poniższą informacją:"
      },
      "appliesManualLabel": "Otwórz instrukcję obsługi zgłoszeń",
      "label": "Regulamin",
      "label.ablative": "Regulaminem",
      "links": {
        "manual": "https://pl.logintegra.com/static/Instrukcja.pdf",
        "policy": "https://pl.logintegra.com/static/Polityka.pdf",
        "statute": "https://pl.logintegra.com/static/Regulamin-2018-05-24.pdf"
      },
      "openKnowledgeBase": "Otwórz bazę wiedzy",
      "policyLabel": "Polityka przetwarzania danych",
      "reject": {
        "button": "Rezygnuję (konto zostanie zablokowane)",
        "buttonForInvitations": "Rezygnuję",
        "confirmation": "Jesteś pewien? Twoje konto zostanie zablokowane."
      },
      "safetyManualLabel": "Instrukcja bezpieczeństwa"
    },
    "step": {
      "add": {
        "title": "Dodaj kolejny etap"
      },
      "address": "Adres",
      "canMoveLoadStep": "W ramach posiadanych uprawnień można przesuwać tylko etapy załadunkowe",
      "canMoveUnloadStep": "W ramach posiadanych uprawnień można przesuwać tylko etapy rozładunkowe",
      "companyGroup": "Grupa kontrahenta",
      "contactPerson": {
        "email": "Adres e-mail",
        "label": "Osoba kontaktowa",
        "phoneNumber": "Numer telefonu"
      },
      "delete": {
        "confirmMessage": "Czy na pewno chcesz usunąć ten etap transportu?",
        "confirmMessageWithIndexes": "Czy na pewno chcesz usunąć ten etap transportu? Wraz z etapem zostaną usunięte wszystkie powiązane z nim indeksy.",
        "title": "Usuń etap z tego transportu"
      },
      "dimensions": {
        "DEFAULT.label": "Wymiary",
        "DIMENSIONS.label": "Wymiary"
      },
      "documentIdentifier": {
        "CUSTOMERS_ORDER_NUMBER.label": "Numer zamówienia klienta",
        "DEFAULT.label": "Numer WZ",
        "DELIVERY_NOTE_NUMBER.label": "Numer WZ",
        "ISSUE_ORDER_NUMBER.label": "Numer polecenia wydania"
      },
      "errors": {
        "arrivalDate": {
          "isInPast": "Data dostawy nie może być w przeszłości."
        },
        "company": {
          "isRequired": "Ten typ transportu wymaga podania dostawcy. Jeśli nie widzisz żadnych dostawców - nawiąż partnerstwo."
        },
        "companyGroup": {
          "blank": "Musisz wybrac conajmniej jedną grupę kontrahenta",
          "wrongValue": "Wybrana grupa kontrahenta, nie jest jedna z grup wybranej firmy dostawcy."
        },
        "contactPersonEmail": {
          "invalidEmailAddress": "Proszę wprowadzić poprawny adres email"
        },
        "dimensions": {
          "length": "Wprowadzony tekst jest zbyt długi. Dopuszczalna długość to 255 znaków."
        },
        "maxAdviceDate": {
          "beforeWindowStop": "Nie można ustawić podanego zakresu, ponieważ dodane już okno czasowe z awizacją znalazłoby się poza nim. Proszę najpierw przesunąć okno czasowe.",
          "earlierThanMinAdviceDate": "Maksymalny możliwy czas awizacji nie może być ustawiony wcześniej niż czas minimalny.",
          "inThePast": "Koniec zakresu czasu na awizację musi znajdować się w przyszłości, by możliwe było dokonanie awizacji.",
          "timeHasPassed": "Niestety czas przewidziany na awizację zlecenia minął. Proszę skontaktować się z administratorem."
        },
        "minAdviceDate": {
          "afterWindowStart": "Nie można ustawić podanego zakresu, ponieważ dodane już okno czasowe z awizacją znalazłoby się poza nim. Proszę najpierw przesunąć okno czasowe."
        },
        "minQuantityValue": "Minimalna dopuszczalna ilość palet wynosi {minQuantityValue}",
        "outsideOfInterval": "Dopuszczalny interwał wynosi {windowInterval} minut, ponieważ o tyle można przesuwać okna czasowe w wybranym magazynie.",
        "outsideWarehouseWorkHours": "Wybrany zakres znajduje się poza godzinami pracy magazynu: {workStart}-{workEnd}.",
        "purchaseOrderNumber": {
          "length": "Wprowadzony tekst jest zbyt długi. Dopuszczalna długość to 255 znaków."
        },
        "warehouse": {
          "sameWarehousesInTransfer": "Nie możesz utworzyć przerzutu w obrębie jednego magazynu"
        }
      },
      "label": "Etap",
      "load": {
        "place": "Miejsce załadunku",
        "term": "Termin załadunku"
      },
      "maxAdviceDate": "Maksymalny możliwy czas awizacji",
      "minAdviceDate": "Minimalny możliwy czas awizacji",
      "orderInTransport": "Kolejność w zleceniu",
      "purchaseOrderNumber": {
        "DEFAULT.label": "Numer SD",
        "SD_NUMBER.label": "Numer SD"
      },
      "quantityLoadAndUnloadSumCheckMessage": "Czy jesteś pewien, że na rozładunkach/załadunkach awizujesz więcej niż jedną naczepę towaru?",
      "quantityLoadSum": "Ilość palet na załadunkach",
      "quantityLoadSumCheckMessage": "Czy jesteś pewnien, że na załadunkach awizujesz więcej niż jedną naczepę towaru?",
      "quantityUnloadSum": "Ilość palet na rozładunkach",
      "quantityUnloadSumCheckMessage": "Czy jesteś pewnien, że na rozładunkach awizujesz więcej niż jedną naczepę towaru?",
      "timeRangeForAdvice": "Zakres czasu na awizację",
      "timeRangeForAdviceDoesNotApply": "Tylko informacyjnie: Ciebie nie obowiązuje",
      "timeRangeForLoad": "Zakres czasu na załadunek",
      "timeRangeForUnload": "Zakres czasu na rozładunek",
      "timeWindow": "Okno czasowe",
      "unload": {
        "place": "Miejsce rozładunku",
        "term": "Termin rozładunku"
      },
      "weightLoadSum": "Suma wagi załadunku",
      "weightUnloadSum": "Suma wagi rozładunku"
    },
    "table": {
      "loadMore": "Załaduj więcej danych",
      "loading": "Wczytujemy transporty",
      "numberOfTransports": "{n} transportów",
      "scanBarcode": "Skanowanie kodu kreskowego",
      "scanBarcodeError": "Błąd podczas skanowania kodu kreskowego. Sprawdź urządzenie skanujące i spróbuj ponownie, lub skontaktuj się z administratorem.",
      "transports": {
        "scrollToType": "Przewiń do sekcji transportów tego typu.",
        "selectType": "Wybierz typ",
        "showAll": "Pokaż wszystkie transporty",
        "showAllActive": "Pokaż wszystkie aktywne zlecenia",
        "showLimited": "Pokaż ograniczoną listę zleceń",
        "showMine": "Pokaż tylko moje transporty",
        "showWithinGroup": "Pokaż transporty mojego działu"
      }
    },
    "tabs": {
      "announcements": "Ogłoszenia",
      "archive": "Archiwum",
      "carriers": "Przewoźnicy",
      "companies": "Firmy",
      "invitations": "Zaproszenia",
      "modeOfTransportation": "Środki transportu",
      "priceLists": "Cenniki",
      "report": "Statystyki",
      "schedule": "Harmonogram",
      "settings": "Ustawienia",
      "superAdmin": "ADMIN",
      "transports": "Transporty",
      "users": "Użytkownicy"
    },
    "task": {
      "alias": "Alias etapu",
      "altCheckpointColor": "Alternatywny kolor statusu",
      "altCheckpointName": "Alternatywna nazwa etapu PL",
      "altCheckpointNameEn": "Alternatywna nazwa etapu EN",
      "associations": {
        "CONTRACTOR": "Kontrahentem",
        "WAREHOUSE": "Magazynem",
        "WAREHOUSE_AND_CONTRACTOR": "Magazynem i Kontrahentem",
        "label": "Etap powiązany z"
      },
      "beforeConfirmation": {
        "willChangeColorTo": "a jego wiersz w tabeli oraz okno czasowe przyjmą kolor",
        "willChangeStateTo": "Do czasu potw. tego etapu, transport będzie się znajdował w statusie"
      },
      "blockade": {
        "customFieldsRestriction": "Status może być potwierdzony dopiero po wypełnieniu oraz zapisaniu pól w formularzu:",
        "documentRestriction": "Status może być potwierdzony dopiero po dodaniu dokumentów do transportu"
      },
      "canBeBlocked": "Status może być zablokowany (po zablokowaniu nie będzie możliwości potwierdzenia statusu, aż do odblokowania), wymaga także uprawnienia ROLE_CAN_BLOCK_TASK",
      "canBeConfirmAfterAddingDocumentsForTransport": "Możliwość potwierdzania po dodaniu dokumentów do transportu",
      "canBeConfirmedByGuard": "Możliwość potwierdzania przez ochronę",
      "canBeConfirmedWithSms": "Możliwość potwierdzenia statusu przez SMS",
      "canBeRejected": "Możliwość odrzucenia statusu",
      "canBeRevoked": "Możliwość cofnięcia statusu",
      "canConfirmPreviousConfirm": "Czy na pewno chcesz potwierdzić wyjazd dla tej dostawy? Nie zostały zatwierdzone statusy związane z rozładunkiem.",
      "checkpointName": "Nazwa etapu PL",
      "checkpointNameEn": "Nazwa etapu EN",
      "color": "Kolor statusu",
      "confirmCheckpointAfterCarrierSelect": "Automatyczne potwierdzenie po przydzieleniu przewoźnika",
      "confirmCheckpointAfterCourierDelivered": "Automatyczne potwierdzenie statusu po dostarczeniu przez kuriera",
      "confirmCheckpointAfterCourierPickup": "Automatyczne potwierdzenie statusu po odbiorze przez kuriera",
      "customFieldDefinition": "Etap powiązany z polem niestandardowym",
      "customFieldOption": "Powiązana opcja pola wyboru",
      "customModalComponentName": "Nazwa komponentu używana jako wyskakujące okienko, jeśli etap ma włączone ustawienie openCustomModal (patrz wyżej)",
      "defaultStagePrefix": "Potwierdzony",
      "disableConfirm": "Na pewno chcesz wyłączyć ten etap?",
      "editLabel": "Edycja etapu: {id} - {transportType}",
      "errors": {},
      "expectedDateMode": {
        "reference": "najpóźniej"
      },
      "expectedDateReference": "Referencja",
      "group": {
        "0": "Prace przygotowawcze",
        "1": "Załadunki i rozładunki",
        "2": "Zakończenie procesu"
      },
      "isDriverCheckInTask": "Flaga wspomagająca wyszukiwanie transportu dla Infokiosku. Transport którego szukamy nie może mieć zatwierdzonego tego statusu",
      "label": "Etap",
      "markIfWasDelayed": {
        "disabled": {
          "label": "Oznaczanie opóźnienia",
          "title": "Kliknij w ikonkę, by okno czasowe otrzymywało czerwoną ramkę po potwierdzeniu tego etapu po czasie."
        },
        "enabled": {
          "label": "Oznaczanie opóźnienia",
          "title": "Okno czasowe otrzyma czerwoną ramkę w przypadku potwierdzenia tego etapu po czasie. Kliknij w ikonkę, by zmienić ustawienia."
        }
      },
      "moveTransportToArchiveIfDelayed": "Automatyczne przeniesienie zlecenia do archiwum w przypadku opóźnienia",
      "newSubTasksCheckpoints": "Wygenerowane zostaną nowe etapy",
      "openCustomModal": "Do zatwierdzenia tego etapu wymagane jest potwierdzenie wyskakującego okienka",
      "recreateMasterTaskSubTasks": "Po potwierdzeniu etapu, stwórz ponownie wszystkie etapy dodatkowe etapu głównego (task główny :: {n})",
      "reference": {
        "after": {
          "earliestStepStart": "rozp. pierwszego zał./rozł.",
          "latestStepStop": "zakończ. ostatniego zał./rozł.",
          "minAdviceDate": "data \"od\" z zakresu daty do awizacji",
          "stepStart": "rozpoczęciu",
          "stepStop": "zakończeniu",
          "transportCreated": "utworzeniu transportu"
        },
        "before": {
          "earliestStepStart": "rozp. pierwszego zał./rozł.",
          "latestStepStop": "zakończ. ostatniego zał./rozł.",
          "minAdviceDate": "data \"od\" z zakresu daty do awizacji",
          "stepStart": "rozpoczęciem",
          "stepStop": "zakończeniem",
          "transportCreated": "utworzeniem transportu"
        }
      },
      "repeatSendApiMessage": "Ponawiaj wysyłkę komunikatu, w przypadku niepowodzenia",
      "requiresWarehouseLocation": "Wymaga podania lokalizacji do potwierdzenia etapu",
      "saveSubTasks": "Zapisz etapy dodatkowe",
      "sendApiMessage": "Wysyłanie komunikatu API",
      "sendDocumentDelayedEmail": "Wysyłanie powiadomienia mailowego o opóźnieniu w dodawaniu dokumentów",
      "settingsLabel": "Ustawienia etapu",
      "shouldBeApprovedWithAdvice": "Automatyczne potwierdzenie statusu po dodaniu danych awizacji",
      "shouldBeApprovedWithCFTypeEmailAdvice": "Automatyczne potwierdzenie statusu po uzupełnieniu danych z awizacji mailowej typu CF_DATA",
      "shouldBeApprovedWithTimeWindow": "Automatyczne potwierdzenie statusu po dodaniu okna czasowego",
      "shouldBeConfirmedBy": "powinien być potw.",
      "shouldBeSavedAsApproved": "Automatyczne potwierdzenie statusu w momencie utworzenia zlecenia",
      "shouldFindFreeSpotAfterDelay": "Przeniesienie okna czasowego w przypadku opóźnienia",
      "shouldGenerateDocument": "Automatyczne generowanie dokumentu dostawy",
      "showPunctualityAlertAfterConfirmation": "Wyświetlenie informacji o terminowości potwierdzenia statusu",
      "sign": "Przed/Po",
      "stageName": "Nazwa statusu przed potwierdzeniem etapu PL",
      "stageNameEn": "Nazwa statusu przed potwierdzeniem etapu EN",
      "stayAtTheFacility": "Etap związany z pobytem na terenie zakładu",
      "stepType": {
        "firstLoad": "pierwszego załadunku",
        "firstUnload": "pierwszego rozładunku",
        "lastLoad": "ostatniego załadunku",
        "lastUnload": "ostatniego rozładunku",
        "load": "załadunku",
        "placeholder": "Typ akcji",
        "unload": "rozładunku"
      },
      "taskIsNotSavedHint": "Ten etap jeszcze nie został zapisany, kliknij Zapisz aby móc edytować dodany etap.",
      "tracked": {
        "disabled": {
          "label": "",
          "title": "Oczekiwany czas potw. etapu będzie tylko informacją poglądową - w przypadku niezatwierdzenia etapu o czasie nie zmienimy koloru wiersza ani okna i nie będziemy wysyłać powiadomień o spóźnieniu. Kliknij w ikonkę, by zmienić ustawienia."
        },
        "enabled": {
          "label": "Nadzorowanie punktualności",
          "title": "W przypadku niezatwierdzenia etapu o czasie transport przejdzie w stan \"spóźnienia\" - wiersz w tabeli oraz okno czasowe zmienią kolor na czerwony oraz zostaną wysłane maile z informacją o spóźnieniu. Kliknij w ikonkę, by zmienić ustawienia."
        }
      },
      "unit": "Jednostka",
      "visibleInDeliveryDocument": "Widoczne na dokumencie dostawy",
      "weighingIncluded": "Etap związany z ważeniem"
    },
    "tasks": {
      "empty": "Nie ma tu żadnych zadań."
    },
    "temporaryAccountAlert": {
      "daysLeft": "Liczba dni pozostałych do blokady: {days}.",
      "limitReached": "Twoja firma osiągnęła limit użytkowników w ramach posiadanego pakietu, dlatego to konto zostało zakwalifikowane jako tymczasowe.",
      "pricingPlans": "cennikiem",
      "title": "Został osiągnięty limit użytkowników",
      "upgrade": "W celu zwiększenia limitu użytkowników należy wykupić większy pakiet. Zapraszamy do zapoznania się z"
    },
    "terms-of-service": {
      "errors": {
        "unchecked": "Aby wysłać prośbę o weryfikację wcześniej musisz potwierdzić OGÓLNE WARUNKI ŚWIADCZENIA USŁUG"
      },
      "label": {
        "acquiant": "Zapoznałem się z: ",
        "confirmed": "zostały zaakceptowane {confirmationDate} przez użytkownika {user}",
        "documents": "Dokumenty OWŚU:"
      },
      "name": "OGÓLNE WARUNKI ŚWIADCZENIA USŁUG",
      "shortName": "OWŚU"
    },
    "time-window": {
      "details": {
        "carRegistrationNumber": "Ciągnik",
        "erpIdentifier": "Nr zlecenia",
        "trailerRegistrationNumber": "Naczepa/przyczepa"
      },
      "errors": {
        "start": {
          "isInPast": "Nie możesz zaplanować transportu w przeszłości.",
          "outsideOfAdviceRange": "Nie możesz wybrać tej daty, ponieważ okno czasowe znalazłoby się poza zakresem czasu na awizację."
        },
        "stop": {
          "earlierThanStart": "Koniec okna czasowego musi się znajdować później niż jego początek.",
          "outsideOfAdviceRange": "Nie możesz wybrać tej daty, ponieważ okno czasowe znalazłoby się poza zakresem czasu na awizację."
        }
      },
      "start": "Godzina okna"
    },
    "timeWindow": {
      "cancelCreation": "Anuluj (popraw miejsce okna czasowego)",
      "errors": {
        "start": {
          "notConsistentWithWarehouseOffset": "Początek okna nie jest zgodny z ustawieniami magazynu. Możliwe wartości początku okna dla wybranej godziny: {possibleWindowStarts}",
          "outsideWarehouseWorkHours": "Wybrana data znajduje się poza godzinami pracy magazynu: {workStart}-{workEnd}.",
          "startsEarlierThenPreviousWindowStops": "Początek okna czasowego nie może być wcześniej niż koniec poprzedniego okna czasowego w transporcie."
        },
        "stop": {
          "notConsistentWithWarehouseOffset": "Koniec okna nie jest zgodny z ustawieniami magazynu. Możliwe wartości końca okna dla wybranej godziny: {possibleWindowStops}",
          "outsideWarehouseWorkHours": "Wybrana data znajduje się poza godzinami pracy magazynu: {workStart}-{workEnd}."
        },
        "time": {
          "outsideOfAdviceRange": "Nie możesz dodać w tym miejscu tego okna czasowego, ponieważ znalazłoby się poza zakresem czasu na awizację: {minAdviceDate} - {maxAdviceDate}."
        },
        "warehouseHasNoSpaceThisDay": "W dniu {expectedAdviceDate} nie ma wolnych okien czasowych. Prosimy o wybór innej daty.",
        "wrongWarehouse": "Nie możesz postawić okna czasowego w magazynie nieprzypisanym do transportu."
      },
      "ramp": "Rampa",
      "start": "Początek okna czasowego",
      "stop": "Koniec okna czasowego"
    },
    "transaction": {
      "accept": "Przyjmij",
      "autoPass": "Przekaż aut.",
      "autoPassConfirmation": "Czy na pewno chcesz włączyć automatyczne przekazywanie do kolejnych przewoźników dla tego transportu",
      "carrier": "Przewoźnik",
      "carrierMustAcceptTransport": "Przekazanie wymaga potwierdzenia przez przewoźnika",
      "carrierMustAcceptTransportHint": {
        "checked": "Po przekazaniu zlecenia wybrany przewoźnik będzie miał możliwość jego przyjęcia lub odrzucenia. Po potwierdzeniu przyjęcia przewoźnik zostanie przypisany do tego zlecenia.",
        "unchecked": "Wybrany przewoźnik zostanie natychmiast przydzielony do zlecenia."
      },
      "chooseCarrier": "Wybierz przewoźnika",
      "chooseCourier": "Wybierz kuriera",
      "courier": "Kurier",
      "currency": "Waluta",
      "dateCreated": "Data utworzenia",
      "errors": {
        "expectedConfirmationDate": {
          "isInPast": "Podana data nie może być w przeszłości"
        },
        "invoicePrice": {
          "wrongNumberFormat": "Fracht musi być liczbą"
        }
      },
      "expectedConfirmationDate": "Termin na potwierdzenie",
      "invoiceCurrency": "Waluta faktury",
      "invoiceNumber": "Numer faktury",
      "invoicePrice": "Fracht faktury",
      "isRejected": "Odrzucona",
      "label": "Transakcja",
      "notYetImplemented": "Ta funkcja nie została jeszcze zaimplementowana",
      "offer": "Oferta",
      "pass": "Przekaż",
      "price": "Fracht",
      "rate": "Stawka",
      "reject": "Odrzuć",
      "transport": "Transport",
      "updateDisabledInfo": {
        "route": "Stawka pochodzi z trasy {routeId}, więc nie możesz jej edytować."
      },
      "waitingForAcceptanceInfo": "Oczekuje na akceptację",
      "withInvoice": "z fakturą",
      "withoutInvoice": "bez faktury"
    },
    "transactionSetting": {
      "description": {
        "canChooseCarrierFromTransportTable": "Określa, czy klawisz \"Przekaż\" będzie dostępny w tabeli transportów w wierszu zlecenia (przy odpowiednio ustawionych opcjach AuthorityRestriction).",
        "defaultCurrency": "Określa domyślną walutę wybraną w formularzu przekazywania transportu do przewoźnika.",
        "defaultInvoiceCurrency": "Określa domyślną walutę wybraną w formularzu z fakturą.",
        "priceLabel": "Określa domyślną etykietę frachtu",
        "requireCarrierConfirmation": "Określa czy przy przekazaniu zlecenia do przewoźnika wymagane jest przez niego potwierdzenie przyjęcia. W przypadku gdy flaga \"Przekazanie wymaga potwierdzenia przez przewoźnika\" ma wartość PRAWDA, określa domyślną wartość pola \"Przekazanie wymaga potwierdzenia przez przewoźnika\".",
        "requiresReasonField": "Obligatoryjność pola \"Powód\"",
        "showCarrierConfirmationCheckbox": "Określa czy w formularzu przekazania powinien być widoczny checkbox: \"Przekazanie wymaga potwierdzenia przez przewoźnika.\"",
        "showReasonField": "Widoczność pola \"Powód\""
      },
      "label": {
        "canChooseCarrierFromTransportTable": "Pokaż klawisz \"Przekaż\" w tabeli transportów w wierszu zlecenia",
        "defaultCurrency": "Domyślna waluta transakcji",
        "defaultInvoiceCurrency": "Domyślna waluta faktury",
        "priceLabel": "Domyślna etykieta frachtu",
        "requireCarrierConfirmation": "Wymagaj potwierdzenia przyjęcia przez przewoźnika",
        "requiresReasonField": "Obligatoryjność pola \"Powód\"",
        "showCarrierConfirmationCheckbox": "Widoczność pola \"Przekazanie wymaga potwierdzenia przez przewoźnika\"",
        "showReasonField": "Widoczność pola \"Powód\""
      },
      "saveSuccess": "Poprawnie zapisaliśmy ustawienia przekazywania"
    },
    "transport": {
      "ARTR": "Status komunikacji z ARTR",
      "CARTONS": "Kartony",
      "CHEP_PALLETS": "Palety CHEP",
      "DISPOSABLE_PALLETS": "Palety jednorazowe",
      "EURO_PALLETS": "Palety EURO",
      "LOADING_PLACES": "Miejsca ładowne",
      "PALLETS": "Palety",
      "PALLET_PLACES": "Miejsca paletowe",
      "THE_NUMBER_OF_ITEMS": "Sztuki",
      "accountExpired": "Zawieszenie konta ze względu na brak aktywności",
      "actionType": "Typ uprawnienia",
      "addNewCompany": {
        "assignmentCompany": "Chcesz przypisać tę firmę jako wykonawcę transportu?",
        "cooperatingCompanies": "Współpracujesz już z firmą o podobnej nazwie",
        "emailInfo": "Na podany adres e-mail zostanie wysłane zaproszenie do współpracy.",
        "emailRequired": "Jeśli chcesz wysłać zaproszenie do współpracy musisz podać adres email",
        "existingCompany": "Posiadamy już w naszej bazie firmę o NIP-ie",
        "existingCompanyAndPartnership": "Firma o numerze NIP {taxIdNumber} jest już zarejestrowana w systemie i należy do grupy Twoich współpracowników",
        "label": "Dodaj firmę, której nie ma w systemie.",
        "sendInvitation": "Wyślij zaproszenie do współpracy",
        "wrongEmail": "Podany adres email jest błędny"
      },
      "advice": "Awizuj",
      "altIdentifier": "Alternatywny numer zlecenia",
      "archived": "Zarchiwizowano",
      "arrivalDate": "Zlecona data odbioru",
      "assetId": "Id obiektu",
      "authorityRange": "Zakres uprawnienia",
      "canNotEditAfterProcessStarts": "Możesz edytować tylko dane awizacji, które utworzyłeś oraz przed wjazdem kierowcy na teren zakładu.",
      "canNotEditBecauseOfOtherContractors": "Nie możesz edytować danych awizacji od wielu kontrahentów.",
      "carrier": {
        "label": "Przewoźnik"
      },
      "carrierCompany": "Przewoźnik",
      "carrierCompanyChooser": "Osoba, która wybrała przewoźnika",
      "carrierCompanySelectedBy": "Przewoźnik wybrany przez firmę {companyName}",
      "combinedWeight": "Waga łączna transportów: ",
      "company": "Dostawca",
      "company.autoCompletePlaceholder": "Zacznij pisać, by wyszukać firmę",
      "companyNotQualified": "Twoja firma nie spełnia warunków",
      "completed": "Zakończony",
      "courier": {
        "dateInPast": "Podana data odbioru jest z przeszłości. Aby przekazać transport do kuriera należy zaktualizować datę odbioru w szczegółach zlecenia.",
        "dhlMissingDocument": "Wybrany kraj/kraje są spoza EU i wymagają PLT. Aby przekazać transport do DHL Express dołącz numer, datę faktury oraz fakturę w formacie pdf do transportu w sekcji faktury za towar",
        "dhlMissingExportDeclaration": "Wybrany kraj/kraje są spoza EU. Aby przekazać transport do DHL Express dołącz numer oraz datę faktury do transportu w sekcji faktury za towar",
        "label": "Kurier",
        "loadCity.label": "Miejsce załadunku:",
        "missingContactDetails": "Nie można przekazać transportu do kuriera, jeśli w którymś z kroków brakuje informacji o osobie kontaktowej, jej numerze telefonu lub adresie e-mail.",
        "pickUpDate.label": "Data odbioru",
        "pickUpHour.label": "Godziny odbioru",
        "service.label": "Serwis",
        "unloadCity.label": "Miejsce dostawy:"
      },
      "courierInvoiceSection": {
        "button": "Dodaj fakturę",
        "date": "Data faktury",
        "file": "Załączona faktura",
        "label": "Faktura za towar",
        "number": "Numer faktury"
      },
      "courierSection": {
        "deliveryDate": "Data dostarczenia",
        "goodsValue": "Wartość towaru",
        "goodsValueCurrency": "Waluta",
        "incoterms": "Incoterms",
        "label": "Kurierzy",
        "orderBy": "Zamów przed",
        "serviceType": "Rodzaj serwisu",
        "totalPrice": "Łączna cena netto"
      },
      "createSimilarMultiple": "Tworzenie podobnych transportów",
      "createSimilarMultipleInProgress": "Kopia transportu w toku, okno zamknie się po utworzeniu wszystkich transportów",
      "createSimilarMultiplePlaceholder": "Ilość kopii",
      "createSimilarMultipleValue": "Liczba transportów do utworzenia:",
      "createTimeWindow": "Dodaj okno czasowe",
      "createTitle": "Zaplanuj dostawę",
      "createdAsCopy": "jako kopia zlecenia",
      "createdBy": "Utworzony przez",
      "createdMessage": "Utworzony {date} przez {person} ({company}).",
      "creator": "Twórca zlecenia",
      "dateCreated": "Data utworzenia",
      "dateOfAdvice": "Data dostawy",
      "dateOfArchiving": "Data archiwizacji",
      "dateOfDeletion": "Data usunięcia",
      "delete": "Usuń transport",
      "deleteTransport": "Czy na pewno chcesz usunąć transport?",
      "departureDate": "Zlecona data dostawy",
      "description": "Uwagi",
      "details": "Szczegóły dostawy",
      "documentType": "Typ dokumentu",
      "documents": {
        "dragAndDrop": "Przeciągnij i upuść dokumenty lub kliknij",
        "empty": "Do tej dostawy nie zostały jeszcze dodane dokumenty.",
        "header": "Dokumenty dla dostawy {identifier}",
        "label": "Dokumenty"
      },
      "earliestTimeWindowStart": "Rozpoczęcie najwcześniejszego okna czasowego",
      "edit": "Edytuj transport",
      "email": "Adres e-mail",
      "emailAdvice": "Wysłano awizacje mailową",
      "erpIdentifier": "Numer zlecenia",
      "erpIdentifier.placeholder": "np. 110011",
      "errors": {
        "altIdentifier": {
          "regex": "Wartość nie zgadza się z wyrażeniem regularnym podanym w Ustawieniach"
        },
        "carrierCompany": {
          "info": "Ten typ transportu wymaga podania firmy przewozowej. Jeśli nie widzisz żadnych firm - nawiąż partnerstwo."
        },
        "contractors": {
          "notUnique": "Proszę podać unikalną listę kontrahentów"
        },
        "dateOfAdvice": {
          "isInPast": "Nie możesz zaplanować transportu w przeszłości"
        },
        "erpIdentifier": {
          "minLength": "Numer zamówienia musi się składać z co najmniej trzech znaków"
        },
        "firstContractor": {
          "info": "Ten typ transportu wymaga podania kontrahenta. Jeśli nie widzisz żadnych firm - nawiąż partnerstwo."
        },
        "requiresWorker": {
          "info": "Proszę wybrać pracownika"
        },
        "serviceCompany": {
          "info": "Ten typ transportu wymaga podania firmy serwisowej. Jeśli nie widzisz żadnych firm - nawiąż partnerstwo."
        }
      },
      "forAcceptOrReject": " na przyjmowanie/odrzucanie zleconych transportów",
      "forAuction": " na udział w składaniu ofert w aukcjach.",
      "forProcess": " na dodawanie awizacji w tym procesie.",
      "generateLOPReport": "Pobierz raport z listą punktów",
      "getCourierQuotes": "Uzyskaj wyceny kurierów",
      "goodsValue": "Wartość towaru",
      "goodsValueCurrency": "Waluta",
      "group": "Grupa dostawy",
      "history": "Historia zleceń",
      "incompleteLoading": "Niekompletny załadunek",
      "incoterms": "Incoterms",
      "indexes": {
        "overwritePreviousIndexes": "Nadpisz poprzednie wpisy",
        "pasteFromExcel": "Wklej dane z pliku Excel"
      },
      "indicesTransportNumbers": {
        "label": "Rodzaj zamówienia"
      },
      "isCombinedTransport": "Wspólny transport z: ",
      "isRequiredFor": "Jest niezbędne dla: ",
      "isWaitingFor": "Czeka na: ",
      "label": "Transport",
      "lastLoginDate": "Logowanie do systemu",
      "latestTimeWindowStop": "Zakończenie najpóźniejszego okna czasowego",
      "load": "Załadunek",
      "logs": {
        "apiIntegration": "Integracja",
        "artr": "Komunikaty ARTR",
        "carrierCompany": "Przewoźnik",
        "dateOfArchiving": "Transport został przeniesiony do archiwum",
        "dateOfDeletion": "Transport został usunięty",
        "initialStartInfo": "Okno czasowe zostało dociągnięte z godziny {initialDate} na godzinę {startDate}",
        "label": "Historia zmian",
        "ramp": "Rampa",
        "start": "Początek okna czasowego",
        "stop": "Koniec okna czasowego"
      },
      "mobilePhoneNumber": "Numer telefonu",
      "mobilePhonePrefix": "Numer telefonu - prefix",
      "name": "Plik",
      "noFilesForOldBrowsers": "Funkcjonalność dodawania plików w tym widoku nie jest dostępna na Twojej przeglądarce. Plik można dodać z poziomu tabeli.",
      "option": "Wybrana opcja",
      "otherRequirements": "Inne wymagania",
      "packs": {
        "boxes": "Kartony",
        "label": "Opakowania",
        "pallets": "Palety",
        "pallets.abbr": "p."
      },
      "periodicTransport": "Awizacja okresowa",
      "periodicTransportInternalIndex": "Numer awizacji okresowej",
      "periodicTransportMessage": "Zlecenie powiązane z awizacją okresową numer {periodicTransportInternalIndex}.",
      "plan": "Zaplanuj",
      "print": "Widok wydruku",
      "quantities": {
        "CARTONS": "Ilość kartonów",
        "CHEP_PALLETS": "Ilość palet CHEP",
        "DISPOSABLE_PALLETS": "Ilość palet jednorazowych",
        "EURO_PALLETS": "Ilość palet EURO",
        "LOADING_PLACES": "Ilość miejsc ładownych",
        "PALLETS": "Ilość palet",
        "PALLET_PLACES": "Ilość miejsc paletowych",
        "THE_NUMBER_OF_ITEMS": "Ilość sztuk",
        "shortName": {
          "CARTONS": "Kartony",
          "CHEP_PALLETS": "CHEP",
          "DISPOSABLE_PALLETS": "Jedn.",
          "EURO_PALLETS": "EURO",
          "LOADING_PLACES": "Ładowność",
          "PALLETS": "Palety",
          "PALLET_PLACES": "Miejsca pale.",
          "THE_NUMBER_OF_ITEMS": "Sztuki"
        }
      },
      "quantity": "Ilość palet",
      "quantity.placeholder": "np. 33",
      "readyToPlan": "Gotowe do planowania",
      "recipient": "Odbiorca",
      "relations": {
        "bind": "Utwórz powiązanie",
        "combinedTransports": "Połączone transporty: ",
        "combining": "\"Łączenie transportów\" (TRANSPORT_COMBINING)",
        "defaultLabel": "Wybrane transporty możesz połączyć typem wiązania: ",
        "deletedSuccessfully": "Poprawnie usunięto relacje",
        "hasToWaitFor": "\"Transport czeka na\" (HAS_TO_WAIT_FOR)",
        "mainTransport": "Transport główny: ",
        "noTypeForSelection": "Przepraszamy, wybrane transporty nie pozwalają na żadne powiązania",
        "overweight": "Nie można połączyć transportów z powodu przekroczenia maksymalnej wagi",
        "relatedTransports": "Transporty zależne: ",
        "removeRelation": "Usuń powiązanie",
        "title": "Łączenie transportów"
      },
      "restoredSuccessfully": "Poprawnie przywrócono zlecenie",
      "roleGroup": "Rola użytkownika",
      "routeOffer": "Oferta w trasie",
      "scheduledDateOfAdvice": "Planowana data awizacji",
      "selectCountry": "Kraj",
      "selectSupplier": "Wybierz dostawcę \\\\ odbiorcę",
      "selectTransportType": "Wybierz rodzaj dostawy",
      "selectUnloadStartDate": "Min. data rozł.",
      "selectUnloadStartDateTitle": "Wybierz minimalną datę rozładunku.",
      "selectUnloadStopDate": "Maks. data rozł.",
      "selectUnloadStopDateTitle": "Wybierz maksymalną datę rozładunku.",
      "selectWarehouse": "Wybierz magazyn",
      "selectedWindows": "Okna przypisane do transportu {transportIdentifier}",
      "serviceCompany": "Serwis",
      "stepType": "Typ etapu",
      "sureToSaveMessage": "Czy na pewno chcesz zapisać zmiany w zleceniu? Niektóre z pól zostały zmodyfikowane przez innego użytkownika.",
      "task": "Etap",
      "title": "Dostawa {ident}",
      "tooManyCopies": "Nie możesz utworzyć więcej niż {maxValue} kopii transportu jednocześnie",
      "transportRelation": "Powiązanie",
      "transportSupervisor": {
        "autoCompletePlaceholder": "Zacznij pisać, by wyszukać osobą kontaktowa",
        "label": "Osoba kontaktowa"
      },
      "transportType": "Typ transportu",
      "truck": "Środek transportu",
      "type": {
        "ALL": "Wszystkie",
        "COLLECTION": "Odbiór",
        "DELIVERY": "Dostawa",
        "SERVICE": "Kurier, serwis, itp.",
        "SIMPLIFIED_DELIVERY": "Dostawa uproszczona",
        "TRANSFER_BETWEEN_WAREHOUSES": "Przerzuty międzymagazynowe",
        "TRANSPORT": "Transport"
      },
      "typeOfService": "Serwis kurierski",
      "unload": "Rozładunek",
      "value": "Wartość",
      "volume": "Objętość",
      "warehouse": "Magazyn",
      "wasMovedAutomatically": "Okno czasowe przeniesione automatycznie",
      "weight": "Waga",
      "weight.placeholder": "np. 23",
      "weightSection": {
        "downloadWeightReceipt": "Pobierz kwit wagowy",
        "entryWeight": "Ważenie 1",
        "errors": {
          "cannotGetWeight": "Niestety nie udało się pobrać wagi. Proszę spróbować ponownie. Jeżeli problem się powtórzy, proszę o kontakt z administratorem systemu.",
          "trySaveCheckpointWithoutWeight": "Potwierdzany etap wymaga ważenia. Niestety nie udało się pobrać wagi. Potwierdzić status z wagą 0?"
        },
        "exitWeight": "Ważenie 2",
        "label": "Ważenie",
        "netWeight": "Waga netto",
        "notSynchronized": "Waga niezsynchronizowana z serwerem",
        "overweightMessage": "Po załadunku pojazd przekroczy maksymalną wagę. Ten pojazd nie powinien być ładowany",
        "overweightNote": "Pojazd przekroczył ustawiony limit wagi!",
        "unit": "kg",
        "weighAgain": "Zważ ponownie",
        "weight": "waga"
      },
      "wzDocument": "Numery dok. WZ",
      "wzNumber": "nr WZ:"
    },
    "transport-type": {
      "errors": {
        "defaultLoadStepEndHour": {
          "regex": "Podana wartość musi być godziną w formacie HH:SS"
        },
        "defaultLoadStepStartHour": {
          "regex": "Podana wartość musi być godziną w formacie HH:SS"
        }
      }
    },
    "transportPanel": {
      "ADVICE": "Awizacja",
      "DISCREPANCY_REPORT": "Protokół rozbieżności",
      "DOCUMENTS": "Dokumenty",
      "INSPECTION": "Kontrola",
      "LOGS": "Historia zmian",
      "WEIGHT": "Ważenie",
      "selectPlaceholder": "Wybierz panel"
    },
    "transportSupervisorTranslationKeys": {
      "CONTACT_PERSON": "Osoba kontaktowa",
      "HOST_PERSON": "Osoba przyjmująca",
      "INVITEE": "Zapraszający",
      "ORDERER": "Zamawiający",
      "PERSON_TO_NOTIFY": "Osoba do powiadomienia"
    },
    "transportsToExcel": {
      "XLSGenerateMessage": "Raport zleceń po wygenerowaniu zostanie wysyłany na adres mailowy osoby generującej raport.",
      "activeArchiveType": "Zlecenia aktywne/archiwalne",
      "advancedExportType": "Zaawansowana",
      "advancedExportTypeDescription": "Wersja zaawansowana posiada dwa arkusze. W pierwszym arkuszu znajdują się dane takie jak w wersji Podstawowej oprócz informacji o statusach transportu. W drugim arkuszu znajdują się wszystkie informacje dotyczące potwierdzeń statusów.",
      "adviceReportLabel": "Wersja zawiera raport awizacji. Raport jest dostępny dla typów: ",
      "adviceReportLabelEmpty": "Brak dostępnych typów transportu",
      "adviceReportType": "Raport awizacyjny",
      "alsoExportActiveOrders": "Eksportuj także zlecenia aktywne",
      "basicExportType": "Podstawowa",
      "basicExportTypeDescription": "Wersja podstawowa posiada wszystkie informacje w jednym arkuszu.",
      "billingExportType": "Pełny raport rozliczeniowy",
      "billingExportTypeDescription": "Wersja zawiera dane rozliczeniowe",
      "errors": {
        "XLSGenerateEmailErrorMessage": "Raport zleceń wysyłany jest na adres mailowy osoby generującej raport, prosimy o uzupełnienie adresu mailowego w zakładce Moje konto",
        "XLSGenerateErrorMessage": "Błąd podczas generowania raportu Excel. Spróbuj ponownie za kilka minut lub skontaktuj się z info@logintegra.com"
      },
      "export": "Eksportuj",
      "exportType": "Wersja pliku",
      "noTypeChosen": "Proszę wybrać przynajmniej jeden typ transportu",
      "ownColumnExportType": "Kolumny z tabeli",
      "ownColumnExportTypeDescription": "Wersja kolumny z tabeli posiada tylko informacje widoczne w tabeli transportów",
      "title": "Parametry pliku Excel",
      "transportTypes": "Typy zleceń"
    },
    "truck": {
      "addTruck": "Dodaj ciągnik",
      "description": "Opis",
      "disableConfirm": "Na pewno chcesz usunąć pojazd?",
      "errors": {
        "registrationNumber": {
          "regex": "Numer rejestracyjny musi mieć między 4 a 50 znaków oraz może zawierać jedynie litery, cyfry oraz znaki . , ; / \\\\ & _"
        },
        "trailer": {
          "regex": "Numer rejestracyjny musi mieć między 4 a 50 znaków oraz może zawierać jedynie litery, cyfry oraz znaki . , ; / \\\\ & _"
        }
      },
      "hds": "HDS",
      "label": "Ciągniki",
      "lastUpdated": "Data ostatniej edycji",
      "registrationNumber": "Numer rejestracyjny",
      "timeWindow": "Okno czasowe",
      "trailer": "Naczepa/Przyczepa",
      "truckType": "Typ"
    },
    "trucks": {
      "label": "Tabor"
    },
    "unit": {
      "KG": "kg",
      "LITERS": "litry",
      "PIECES": "szt.",
      "label": "Jednostka",
      "placeholder": "Wybierz jednostkę"
    },
    "user": {
      "accountCreatedBy": "Konto utworzone przez",
      "accountUpdated": "Zmiany w ustawieniach konta zostały zapisane.",
      "activeNotifications": "Aktywne powiadomienia",
      "availableQueues": "Dostępne kolejki",
      "availableRamps": "Dostępne rampy",
      "availableWarehouses": "Available warehouses",
      "contractorGroupName": "Grupa",
      "createUser": "Dodaj użytkownika",
      "deleteConfirm": "Na pewno chcesz usunąć użytkownika?",
      "deletedMessage": "Usunięty {date} przez użytkownika {person} z firmy {company}.",
      "deletedUser": "Użytkownik został usunięty",
      "disableConfirm": "Na pewno chcesz wyłączyć tego użytkownika?",
      "disabledUser": "Użytkownik został wyłączony",
      "editUser": "Edycja użytkownika",
      "email": "Email",
      "errors": {
        "mobilePhoneNumberLengthError": "Numer telefonu powinien składać się z 9 cyfr.",
        "mobilePhoneNumberRegexError": "Numer telefonu może składać się tylko z cyfr."
      },
      "groupName": "Dział",
      "hasRoleAccessAllRamp": "Użytkownicy roli Administrator posiadają uprawnienie \"Dostęp do wszystkich ramp/slotów w harmonogramie\". Niezależnie od wyboru dostępnych ramp w tym widoku użytkownik będzie miał dostęp do wszystkich ramp.",
      "invitedBy": "Zaproszony: {date} przez {person}.",
      "lastLoginDate": "Data ostatniego logowania",
      "ldapUser": "Użytkownik LDAP",
      "limitedDataHint": "Widoczność i edycja niektórych szczegółów użytkownika {user} jest ograniczona, zarządzanie jest możliwe jedynie przez Administratorów firmy {company}.",
      "list": "Lista użytkowników",
      "localAdmin": "Lokalny administrator",
      "mobilePhoneNumber": "Telefon",
      "myAccount": "Moje konto",
      "name": "Imię i nazwisko",
      "noActiveNotifications": "Brak aktywnych powiadomień",
      "noAvailableRamps": "Brak dostępnych ramp",
      "registrationDate": "Data rejestracji",
      "roleDescription": "Opis roli",
      "selectRole": "Wybierz rolę",
      "status": {
        "ACTIVE": "Aktywny",
        "DELETED": "Usunięty",
        "EXPIRED": "Nieaktywny",
        "TEMPORARY": "Tymczasowy",
        "label": "Stan użytkownika"
      },
      "temporaryAccountDaysLeft": "Dni do blokady",
      "userGroup": {
        "allTransports": "Wszystkie transporty",
        "myGroupsTransports": "Transporty mojej grupy",
        "myTransports": "Moje transporty"
      },
      "username": "Login"
    },
    "userGroups": {
      "canSeeOtherGroupsTransports": "Dostęp do transportów innych grup",
      "identifier": "Identyfikator",
      "label": "Grupy użytkowników",
      "name": "Nazwa"
    },
    "users": {
      "activity": "Historia aktywności",
      "data": "Dane użytkownika",
      "label": "Użytkownicy",
      "notifications": "Powiadomienia",
      "queues": "Dostęp do kolejek",
      "ramps": "Dostęp do ramp/slotów"
    },
    "warehouse": {
      "addRamp": "Dodaj rampę",
      "adviceHasBeenSavedInfo": "Twoja awizacja została zapisana na godzinę: ",
      "autoCompletePlaceholder": "Zacznij pisać, by wyszukać magazyn, ulicę, itp",
      "backToDeliveries": "Lista dostaw",
      "configureAlgorithmAfterSave": "Algorytm obliczania długości okna czasowego będzie można ustawić po zapisaniu magazynu.",
      "configureAssortmentGroupsAfterSave": "Grupy asortymentowe będzie można ustawić po zapisaniu magazynu.",
      "confirmDeleteDedicateWindow": "Czy na pewno chcesz usunąć okno dedykowane dla firmy {company}?",
      "create": "Utwórz magazyn",
      "createRampsAfterSave": "Strefy magazynowe, lokalizacje i rampy będzie można ustawić po zapisaniu magazynu.",
      "createTransportInfo": "Wybierz wolne miejsce w harmonogramie, by utworzyć transport typu {transportTypeName}.",
      "disableConfirm": "Na pewno chcesz wyłączyć ten magazyn?",
      "disabledMessage": "Poprawnie usunęliśmy magazyn \"{warehouseName}\".",
      "enableConfirm": "Na pewno chcesz włączyć ten magazyn?",
      "errors": {
        "idx": {
          "invalidIdxNumber": "Numer porządkowy magazynu musi mieć wartość pomiędzy 0 a {numberOfWarehouses}"
        },
        "snapToClosestWindowInterval": {
          "info": "Proszę wprowadzić maksymalną odległość między krawędziami do automatycznego dociągnięcia okna czasowego. Możliwe do wprowadzenia wartości to 0, 15, 30, lub 60."
        },
        "timeWindowOffsetInterval": {
          "info": "Proszę wprowadzić ilość minut, o jakie można przesuwać okna czasowe."
        },
        "windowConstantTime": {
          "info": "Proszę podać stałą wartość w minutach dodawaną do długości rozładunku w oknach."
        },
        "workEndHour": {
          "endBeforeStart": "Koniec pracy magazynu musi się odbywać po rozpoczęciu pracy.",
          "invalidNumber": "Proszę wybrać liczbę z przedziału od 1 do 24."
        },
        "workStartHour": {
          "invalidNumber": "Proszę wybrać liczbę z przedziału od 0 do 23."
        }
      },
      "hdOff": "Wyłącz HD",
      "hint": "Aby wybrać termin dostawy, kliknij w wolne miejsce w harmonogramie.",
      "idx": "Numer porządkowy magazynu",
      "indexesDetailsTitle": "Kliknij, by wyświetlić indeksy",
      "label": "Magazyn",
      "numberOfRamps": "Liczba ramp",
      "reserveFreePlace": "Zarezerwuj wolne miejsce w harmonogramie na {initialStart}",
      "selectCompany": "Po kliknięciu w wolne pole utwórz okno dedykowane dla firmy",
      "selectCountry": "Wybierz kraj",
      "selectDepartment": "lub wybierz dział",
      "selectNewPlaceForWindow": "Wybierz nowe miejsce dla wybranego okna czasowego",
      "selectWindowInfo": "Wybierz wolne miejsce w harmonogramie, by dodać awizację do transportu {identifier}.",
      "selectWindowWarning": "Awizację możesz zaplanować najpóźniej na {date}.",
      "shouldFindFreeSpotAfterDelay": "Przeniesienie okna czasowego w przypadku opóźnienia na wskazanym etapie",
      "slider": {
        "title": "Zmień rozmiar wierszy w harmonogramie"
      },
      "snapToClosestWindowInterval": "Maksymalna odległość między krawędziami do automatycznego dociągnięcia okna czasowego. Dociągamy tylko jedno okno -> to które stawiamy jako nowe",
      "sumOfPallets": "Suma palet",
      "templates": {
        "copyFrom": "Skopiuj szablon z dnia",
        "datesWithErrorMessage": "Uwaga: z powodu zmiany czasu nie potrafiliśmy zapisać niektórych okien w dniach: {windows}.",
        "header": "Szablony okien czasowych dla magazynu",
        "label": "Szablony",
        "saveFor": "zapisz szablon dla",
        "savedMessage": "Poprawnie zapisaliśmy szablon."
      },
      "timeWindowDefaultLengthInMinutes": "Domyślna wielkość okna czasowego w minutach",
      "timeWindowLengthLongerThanWarehouseWorkingHours": "Okno czasowe może być dłuższe niż czas pracy magazynu",
      "timeWindowMustOccupyEntireRamp": "Okno czasowe musi zajmować całą rampę w wybranym dniu",
      "timeWindowMustOccupyEntireRampDisclaimer": "Okno czasowe w tym magazynie zajmie całą rampę",
      "timeWindowOffsetInterval": "Ilość minut, o jakie można przesuwać okna czasowe",
      "timeWindowSummaryInfo": "Wybierz wolne miejsce w harmonogramie, by dodać",
      "timeWindowSummaryInfoSubtitle": "Transport wymaga dodania {timeWindowsNumber} okien czasowych",
      "transportTypes": "Typy transportu, dla których dostępny będzie magazyn",
      "updateSuccessMessage": "Poprawnie zapisaliśmy zmiany ustawień magazynu {warehouseName}.",
      "warehouseLocationAdd": "Dodaj lokalizacje",
      "warehouseLocationLabel": "Lokalizacja",
      "warehouseLocationLabelPlural": "Lokalizacje",
      "warehouseLocationNewLocation": "Nowa lokalizacja",
      "warehouseLocationNextLocation": "Przejazd na inna strefę?",
      "weighingIncluded": "W tym magazynie odbywa się ważenie",
      "windowConstantTime": "Stała wartość w minutach dodawana do długości rozładunku w oknach",
      "windowIsLoad": "Z",
      "windowIsUnload": "R",
      "windowNoteLabel": "Treść notatki",
      "windowNoteRemoveWarning": "Czy chcesz usunąć okno z notatką: {note}?",
      "windowNoteTitle": "Wprowadź notatkę",
      "windowRemoveWarning": "Czy na pewno chcesz usunąć okno blokady?",
      "workEndHour": "Godzina zamknięcia",
      "workStartHour": "Godzina otwarcia",
      "workTime": "Godziny pracy magazynu",
      "youAreEditingThisWindow": "to okno edytujesz",
      "youAreInWarehouse": "Jesteś w magazynie",
      "youWereMovedToWarehouse": "Zostałeś przeniesiony do magazynu"
    },
    "warehouse-zone": {
      "errors": {}
    },
    "warehouseZone": {
      "addZone": "Dodaj strefę",
      "algorithmSettings": "Algorytm obliczania wielkości okna",
      "disableConfirm": "Na pewno chcesz wyłączyć tę strefę?",
      "englishName": "Angielska nazwa strefy",
      "errors": {},
      "hint": "{warehouseZoneLabel} — możesz ją zmienić przesuwając okno czasowe w harmonogramie.",
      "idx": "Kolejność strefy",
      "label": "Strefa",
      "noTransportTypesAssigned": "Brak przypisanych stref magazynowych. Należy dodać powiązanie w szczegółach strefy magazynowej.",
      "noTransportTypesSelected": "Czy na pewno chcesz zostawić strefę bez powiązania z typem transportu? Dodanie awizacji na takiej strefie nie będzie możliwe.",
      "selectWarehouse": "{warehouseZoneLabel} — proszę najpierw wybrać {warehouseLabel}",
      "settings": "Ustawienia strefy",
      "transportTypes": "Typy transportu, dla których ma być dostępna strefa magazynowa",
      "updateSuccessMessage": "Poprawnie zapisaliśmy zmiany ustawień strefy."
    },
    "warehouses": {
      "label": "Magazyny"
    },
    "warehousesToExcel": {
      "label": "Magazyny",
      "placeholder": "Wybierz magazyny do exportu",
      "title": "Parametry pliku Excel"
    },
    "weightLog": {
      "ENTRY_WEIGHT": "Ważenie 1",
      "ENTRY_WEIGHT_AGAIN": "Ważenie 1 (ponowne)",
      "EXIT_WEIGHT": "Ważenie 2",
      "EXIT_WEIGHT_AGAIN": "Ważenie 2 (ponowne)"
    },
    "worker": {
      "GUESTS": "Goście",
      "SERVICEMEN": "Serwisanci",
      "WORKERS": "Pracownicy",
      "addGuest": "Dodaj nowego gościa",
      "addServiceman": "Dodaj nowego serwisanta",
      "addWorker": "Dodaj nowego pracownika",
      "autoCompletePlaceholder": "Zacznij pisać, by wyszukać pracownika",
      "chooseWorkers": "Wybierz pracowników",
      "deleteWorkerMsg": "Czy na pewno chcesz usunąć tego pracownika?",
      "editGuest": "Edytuj gościa",
      "editServiceman": "Edytuj serwisanta",
      "editWorker": "Edytuj pracownika",
      "errors": {
        "phoneNumber": {
          "regex": "Numer musi zawierać od 9 do 12 cyfr"
        }
      },
      "name": "Imię i nazwisko",
      "phoneNumber": {
        "GUESTS": "Numer telefonu gościa",
        "SERVICEMEN": "Numer telefonu serwisanta",
        "WORKERS": "Numer telefonu pracownika"
      },
      "trained": "Przeszkolony",
      "workersList": "Lista pracowników"
    },
    "workflows": {
      "addSubTask": "Dodaj etap dodatkowy",
      "addTask": "Dodaj etap",
      "finalStageNamesLabel": "Po potwierdzeniu wszystkich etapów, transport powinien przejść w status",
      "label": "Ustawienia etapów i statusów",
      "noTasks": "Do tej części procesu nie został przypisany żaden etap",
      "saveSuccessMessage": "Poprawnie zapisaliśmy ustawienia etapów i statusów.",
      "updateDisclaimer": "Uwaga: zmiany dotyczące czasu zaczną obowiązywać tylko dla NOWYCH transportów."
    },
    "wrongUrl": "Brak dostępu lub strona o podanym adresie nie istnieje"
  };
  _exports.default = _default;
});