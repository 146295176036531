define("apollo/pods/transport-types/package-type-settings/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_MANAGE_PACKAGE_TYPE_TRANSPORT_TYPE'],
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      var transportTypeId = params.id;
      var transportType = this.store.findRecord('transport-type', transportTypeId);
      var packageTypeTransportTypes = this.store.query('packageTypeTransportType', {
        transportTypeId: transportTypeId
      });
      return Ember.RSVP.hash({
        transportType: transportType,
        packageTypeTransportTypes: packageTypeTransportTypes
      });
    }
  });

  _exports.default = _default;
});