define("apollo/pods/partnerships/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    //TODO: zabezpieczenia: showAwaitingInvitations, showPartnershipTable (rola + czy nie inny pryncypał)
    model: function model(params) {
      var companyId = params.companyId;
      return this.store.findRecord('company', companyId, {
        reload: true
      });
    }
  });

  _exports.default = _default;
});