define("apollo/pods/components/popup-message/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    defaultMargin: "2rem",
    didRender: function didRender() {
      Ember.$('.popup-message').css(this.get('position'), this.get('defaultMargin'));
    }
  });

  _exports.default = _default;
});