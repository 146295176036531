define("apollo/pods/invitations/show/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      var invitation = this.store.findRecord('invitation', params.id, {
        reload: true
      });
      var invitationRamps = this.store.query('invitation-ramp', {
        invitationId: params.id
      });
      return Ember.RSVP.hash({
        invitation: invitation,
        invitationRamps: invitationRamps
      });
    }
  });

  _exports.default = _default;
});