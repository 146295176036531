define("apollo/pods/companies/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: service('session-account'),
    access: ['ROLE_CREATE_COMPANY'],
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model() {
      var company = this.store.createRecord('company', {
        pricingPlan: 'BASIC'
      });
      var partnership = this.store.createRecord('partnership');
      company.set("partnershipWithCurrentCompany", partnership);
      return company;
    },
    actions: {
      willTransition: function willTransition() {
        this.get('controller').setProperties({
          verifiedCompanyName: null,
          gusHasProblem: false
        });
      }
    }
  });

  _exports.default = _default;
});