define("apollo/pods/notifications/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    access: ['ROLE_MANAGE_NOTIFICATIONS'],
    sessionAccount: Ember.inject.service('session-account'),
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        notification: this.store.findRecord('notification', params.id)
      });
    },
    renderTemplate: function renderTemplate() {
      this.render('notifications.create');
    },
    setupController: function setupController(controller, model) {
      this.controllerFor('notifications.create').setProperties({
        model: model
      });
    }
  });

  _exports.default = _default;
});