define("apollo/pods/components/show-notes/component", ["exports", "apollo/pods/components/show-notes/template", "apollo/pods/components/custom-popover/component"], function (_exports, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = _component.default.extend({
    layout: _template.default,
    sessionAccount: service('session-account'),
    canAddNote: Ember.computed('transport.transportType', function () {
      return this.get('transport').actionCanBePerformed('ADD_NOTES');
    }),
    actions: {
      showNotes: function showNotes(transport) {
        var _this = this;

        this.get('transport.notes').then(function () {
          _this.showPopover(_this.element, '.notes-button', '.notes-popover');

          $(_this.element).find('#noteInput').focus();

          _this.set("action", "markNotesAsReadOff");

          _this.sendAction('action', transport);
        });
      },
      addNote: function addNote() {
        var $el = $(this.element);
        var noteText = $el.find('#noteInput').val();
        var transport = this.get('transport');
        var id = transport.get('id');

        if (noteText) {
          this.set("action", "addNote");
          this.sendAction('action', noteText, id);
          this.destroyPopovers();
        }
      }
    }
  });

  _exports.default = _default;
});