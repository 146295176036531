define("apollo/pods/classification-resource/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    intl: Ember.inject.service(),
    STATE_NAMES: {
      CREATED: 'CREATED',
      HAS_QUEUE: 'HAS_QUEUE',
      HAS_PLACE: 'HAS_PLACE',
      COMPLETED: 'COMPLETED',
      RESERVED: 'RESERVED'
    },
    carRegistrationNumber: _emberData.default.attr('string'),
    hds: _emberData.default.attr('boolean'),
    transport: _emberData.default.belongsTo('transport', {
      async: true
    }),
    squareName: _emberData.default.attr('string'),
    queue: _emberData.default.belongsTo('queue', {
      async: true
    }),
    place: _emberData.default.belongsTo('place', {
      async: true
    }),
    state: _emberData.default.attr('string'),
    enabled: _emberData.default.attr('boolean'),
    lastUpdated: _emberData.default.attr('isodate'),
    classificationCheckpoints: _emberData.default.hasMany('checkpoints'),
    stateIdx: Ember.computed('state', function () {
      switch (this.get('state')) {
        case this.STATE_NAMES.HAS_PLACE:
          return 1;

        case this.STATE_NAMES.HAS_QUEUE:
          return 2;

        case this.STATE_NAMES.RESERVED:
          return 3;

        default:
          return 0;
      }
    }),
    assignQueueValidator: Ember.computed('queue', 'square', function () {
      var self = this;
      return this.get('store').createRecord('classification-resource/assign-queue-validator', {
        queue: self.get('queue'),
        square: self.get('square')
      });
    }),
    isAssigned: Ember.computed('queue', 'state', function () {
      return [this.STATE_NAMES.HAS_QUEUE, this.STATE_NAMES.HAS_PLACE].includes(this.get('state'));
    }),
    assignedSuccessMessage: Ember.computed('queue.nameInAppropriateLanguage', function () {
      return this.get('intl').t('classifications.successMessage', {
        registrationNumber: this.get('carRegistrationNumber'),
        squareName: this.get('squareName'),
        queueName: this.get('queue.nameInAppropriateLanguage')
      });
    }),

    /**
     * Nadpisujemy metodę toJSON aby przy wywołaniu JSON.stringify(data) nie tracić ID obiektu
     * https://stackoverflow.com/questions/29513240/id-is-lost-while-trying-to-json-stringify-ember-model
     * @returns {*}
     */
    toJSON: function toJSON() {
      return this._super({
        includeId: true
      });
    },
    classificationCheckpointsString: Ember.computed('classificationCheckpoints.@each.id', function () {
      var classificationCheckpoints = this.get('classificationCheckpoints');

      if (!classificationCheckpoints) {
        return "";
      }

      return "(" + classificationCheckpoints.getEach('classificationResourceString').join(", ") + ")";
    })
  });

  _exports.default = _default;
});