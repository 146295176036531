define("apollo/pods/components/create-transport-button/component", ["exports", "apollo/mixins/step-mixin"], function (_exports, _stepMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_stepMixin.default, {
    tagName: 'a',
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    warehouseZoneTransportTypes: Ember.computed('', function () {
      return this.get('store').peekAll('warehouse-zone-transport-type');
    }).readOnly(),
    handleDefaultCompanies: function handleDefaultCompanies(transport) {
      var _this = this;

      var config = {
        load: 'defaultLoadCompanyId',
        unload: 'defaultUnloadCompanyId'
      };

      var _loop = function _loop(stepType) {
        var property = config[stepType];
        var defaultCompanyId = transport.get("transportType.".concat(property));

        if (defaultCompanyId) {
          var steps = transport.get('steps').filter(function (s) {
            return s.get('stepTypeName') === stepType;
          });

          _this.get('store').findRecord('company', defaultCompanyId).then(function (c) {
            steps.forEach(function (s) {
              s.set('company', c);

              if (s.get('newAddressAsDefault')) {
                s.set('address', _this.get('store').createRecord('address', {
                  company: c
                }));
              }
            });
          });
        }
      };

      for (var stepType in config) {
        _loop(stepType);
      }
    },
    actions: {
      openTransportModal: function openTransportModal(transportType) {
        var store = this.get('store');
        var transport = store.createRecord('transport', {
          transportType: transportType
        });
        console.log('Otwieramy okienko z formularzem nowego transportu...');

        if (transportType.get('requiresTimeWindow') && !transportType.get('adviceSeparatedFromWindow') || transportType.get('adviceSavedWithTransport')) {
          var advice = store.createRecord('advice', {
            transport: transport
          });
          transport.set('advice', advice);
        }

        var warehouses = transportType.get('warehousesForCurrentUser');
        var isLoadStepInWarehouseByDefault = transportType.get('isLoadStepInWarehouseByDefault');
        var isUnloadStepInWarehouseByDefault = transportType.get('isUnloadStepInWarehouseByDefault');
        this.get('sessionAccount.currentUser.company').then(function (comp) {
          if (comp.get('partnershipType.name') === 'CARRIER') {
            transport.set('carrierCompany', comp);
          }
        });
        var TYPE_GROUPS = transportType.get('TYPE_GROUPS');

        if (transportType.get('typeGroup') === TYPE_GROUPS.COLLECTION) {
          // odbiór - pierwszy etap w magazynie, dowolna ilość rozładunków
          var loadStep = store.createRecord('step', {
            ownedByPrincipal: isLoadStepInWarehouseByDefault,
            shouldHaveContractor: !transportType.get('hasVisibleSteps'),
            stepTypeName: 'load',
            orderInTransport: 0
          });

          if (warehouses.get('length') === 1) {
            loadStep.set('warehouse', warehouses.get('firstObject'));
          }

          transport.get('steps').pushObject(loadStep);

          if (transportType.get('hasVisibleSteps')) {
            var unloadStep = store.createRecord('step', {
              ownedByPrincipal: isUnloadStepInWarehouseByDefault,
              shouldHaveContractor: true,
              stepTypeName: 'unload',
              orderInTransport: 1
            });
            transport.get('steps').pushObject(unloadStep);
          }
        } else if (transportType.get('typeGroup') === TYPE_GROUPS.DELIVERY) {
          // dostawa - ostatni etap w magazynie, dowolna ilość załadunków lub kurier - przyjazd do klienta, tylko jeden "załadunek"
          var _loadStep = store.createRecord('step', {
            ownedByPrincipal: isLoadStepInWarehouseByDefault,
            shouldHaveContractor: true,
            stepTypeName: 'load',
            orderInTransport: 0
          });

          transport.get('steps').pushObject(_loadStep);

          var _unloadStep = store.createRecord('step', {
            ownedByPrincipal: isUnloadStepInWarehouseByDefault,
            shouldHaveContractor: false,
            stepTypeName: 'unload',
            orderInTransport: 1
          });

          if (warehouses.get('length') === 1) {
            _unloadStep.set('warehouse', warehouses.get('firstObject'));
          }

          transport.get('steps').pushObject(_unloadStep);
        } else if (transportType.get('typeGroup') === TYPE_GROUPS.SIMPLIFIED_DELIVERY || transportType.get('typeGroup') === TYPE_GROUPS.SERVICE) {
          // Uproszczona dostawa - jeden etap - w magazynie
          var _unloadStep2 = store.createRecord('step', {
            ownedByPrincipal: isUnloadStepInWarehouseByDefault,
            shouldHaveContractor: transportType.get('allowsContractor'),
            stepTypeName: 'unload',
            orderInTransport: 0
          });

          if (warehouses.get('length') === 1) {
            _unloadStep2.set('warehouse', warehouses.get('firstObject'));
          }

          transport.get('steps').pushObject(_unloadStep2);
        } else if (transportType.get('typeGroup') === TYPE_GROUPS.TRANSPORT) {
          // zlecenie transportowe - dowolna ilość etapów, w dowolnej konfiguracji
          var _loadStep2 = store.createRecord('step', {
            ownedByPrincipal: isLoadStepInWarehouseByDefault,
            shouldHaveContractor: true,
            stepTypeName: 'load',
            orderInTransport: 0
          });

          transport.get('steps').pushObject(_loadStep2); // zakładamy, że najczęściej 'transport' będzie kończył się rozładunkiem w magazynie klienta;
          // użytkownik będzie mógł to zmienić podczas tworzenia transportu

          var _unloadStep3 = store.createRecord('step', {
            ownedByPrincipal: isUnloadStepInWarehouseByDefault,
            shouldHaveContractor: false,
            stepTypeName: 'unload',
            orderInTransport: 1
          });

          transport.get('steps').pushObject(_unloadStep3);
        } else if (transportType.get('typeGroup') === TYPE_GROUPS.TRANSFER_BETWEEN_WAREHOUSES) {
          // Przerzuty - dwa etapy - oba w magazynie
          var _loadStep3 = store.createRecord('step', {
            ownedByPrincipal: isLoadStepInWarehouseByDefault,
            shouldHaveContractor: false,
            stepTypeName: 'load',
            orderInTransport: 0
          });

          transport.get('steps').pushObject(_loadStep3);

          var _unloadStep4 = store.createRecord('step', {
            ownedByPrincipal: isUnloadStepInWarehouseByDefault,
            shouldHaveContractor: false,
            stepTypeName: 'unload',
            orderInTransport: 1
          });

          transport.get('steps').pushObject(_unloadStep4);
        } // Ustawiamy domyślny czas dostawy etapom, które nie znajdują się w magazynie -
        // tylko w przypadku transportów, w których można podawać etapy.


        if (transportType.get('hasVisibleSteps')) {
          transport.get('steps').filterBy('ownedByPrincipal', false).forEach(function (step) {
            // TODO: parametryzacja godziny - do zrobienia również w step-header/controller.js#addStep
            var numberOfDaysForFirstStep = transportType.get('numberOfDaysAddedToFirstStep') ? transportType.get('numberOfDaysAddedToFirstStep') : 0;
            var defaultArrivalDate = moment().startOf('day').add(numberOfDaysForFirstStep, 'day').add(18, 'hours').toDate();
            step.setProperties({
              arrivalDate: defaultArrivalDate
            });
          });
        } // Ustawiamy domyślny zakres czasu na awizację w przypadku transportów, które go wymagają.


        if (transportType.get('requiresAdviceDateRestrictions')) {
          transport.get('steps').filterBy('ownedByPrincipal').forEach(function (step) {
            step.setAdviceRestrictionDates();
          });
        }

        this.get('sessionAccount.currentUser.company').then(function (comp) {
          var firstStepForContractor = transport.get('steps').filterBy('ownedByPrincipal', false).get('firstObject') || transport.get('steps.firstObject');

          if (firstStepForContractor) {
            var contractor = comp.get('partnershipType.name') === 'SUPPLIER' || comp.get('partnershipType.name') === 'OPERATOR' ? comp : store.createRecord('company', {});
            firstStepForContractor.set('company', contractor);
          }
        });
        transport.get('transportType').get('customFieldDefinitions').filterBy('enabled').forEach(function (definition) {
          var customField = store.createRecord('customFieldValue', {
            definition: definition
          });
          transport.get('customFields').pushObject(customField);
        });
        transport.set('periodicTransport', this.get('store').createRecord('periodicTransport', {
          frequency: 'DAILY',
          startDate: moment().startOf('day').toDate()
        }));

        if (transportType.get('defaultServicemanCompanyId')) {
          store.findRecord('company', transportType.get('defaultServicemanCompanyId')).then(function (serviceCompany) {
            return transport.set('serviceCompany', serviceCompany);
          });
        } // tworzymy obiekty dla opakowań


        this.handleStepPackageTypesForTransport(transport); // ustawiamy domyślne firmy na załadunku i rozładunku - jeśli transport ma je ustawione

        this.handleDefaultCompanies(transport);
        this.set('action', 'showModal');
        this.sendAction('action', 'transports.modals.create-transport', transport);
      }
    }
  });

  _exports.default = _default;
});