define("apollo/pods/offers/modals/offer-form/controller", ["exports", "jquery", "apollo/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    offer: Ember.computed('model.offer', function () {
      return this.get('model.offer');
    }),
    auction: Ember.computed('offer.auction', function () {
      return this.get('offer.auction');
    }),
    transport: Ember.computed('auction.transport', function () {
      return this.get('auction.transport');
    }),
    title: Ember.computed('transport.identifier', 'transport.formattedCities', function () {
      return "".concat(this.get('transport.identifier'), ": ").concat(this.get('transport.formattedCities'));
    }),
    customFields: Ember.computed('model.customFields', function () {
      return this.get('model.customFields');
    }),
    buyNowConfirmationMsg: Ember.computed('transport.identifier', 'offer.price', 'auction.currency.symbol', function () {
      return this.get('intl').t('auction.buyNowConfirmation', {
        transportIdentifier: this.get('transport.identifier'),
        minPrice: this.get('offer.price'),
        currencySymbol: this.get('auction.currency.symbol')
      });
    }),
    auctionSetting: Ember.computed('transport.transportType.id', function () {
      return this.get('sessionAccount').getAuctionSetting(this.get('transport.transportType.id'));
    }),
    canAcceptWithMinPrice: Ember.computed('auction.minPrice', 'auctionSetting', function () {
      var auctionSetting = this.get('auctionSetting');

      if (!auctionSetting) {
        return true;
      }

      return this.get('auction.minPrice') && auctionSetting.get('canAcceptWithMinPrice');
    }),
    showQuantityFieldInOfferForm: Ember.computed('transport', 'transport.activeAuction', 'auctionSetting', function () {
      var auctionSetting = this.get('auctionSetting');

      if (!auctionSetting) {
        return false;
      }

      return this.get('transport.activeAuction') && auctionSetting.get('showQuantityFieldInOfferForm');
    }),
    showStepDataInOfferForm: Ember.computed('auctionSetting', function () {
      var auctionSetting = this.get('auctionSetting');

      if (!auctionSetting) {
        return true;
      }

      return auctionSetting.get('showStepDataInOfferForm');
    }),
    auctionRejectConfirmationText: Ember.computed('auctionSetting', function () {
      var auctionSetting = this.get('auctionSetting');

      if (!auctionSetting) {
        return this.get('intl').t('auction.AUCTION.reject.alert');
      }

      var key = auctionSetting.get('translationKey');
      return this.get('intl').t("auction.".concat(key, ".reject.alert"));
    }),
    showRevokeOfferButton: Ember.computed('auctionSetting', 'auction', 'auction.currentOfferForLoggedCompany', function () {
      var auctionSetting = this.get('auctionSetting');

      if (!auctionSetting) {
        return false;
      }

      var currentPrice = this.get('auction.currentOfferForLoggedCompany.formattedPrice');
      return currentPrice && auctionSetting.get('showRevokeOfferButton');
    }),
    showDescriptionSection: Ember.computed('auctionSetting', function () {
      var auctionSetting = this.get('auctionSetting');

      if (!auctionSetting) {
        return true;
      }

      return auctionSetting.get('showDescriptionSection');
    }),
    dateToPerformAction: '',
    actionPlanned: false,
    executeAt: function executeAt(timeString, func) {
      var time = moment(timeString).toDate();
      var currentTime = new Date().getTime();
      setTimeout(func, time - currentTime);
      return true;
    },
    actions: {
      performSaveAt: function performSaveAt(date) {
        var _this = this;

        this.executeAt(date, function () {
          $("[data-button-name='save-offer']").click();

          _this.set('actionPlanned', false);
        });
        this.set('actionPlanned', true);
      },
      buyNow: function buyNow() {
        var auction = this.get('auction');
        var currentPrice = this.get('offer.price');
        this.get('offer').setProperties({
          oldPrice: currentPrice,
          price: auction.get('minPrice'),
          isAcceptedWithMinPrice: true
        });
        this.send('save');
      },
      save: function save() {
        var _this2 = this;

        console.debug("Saving offer for auction ".concat(this.get('auction.id'), " of transport ").concat(this.get('transport.id'), ".."));

        if (this.get('canAcceptWithMinPrice') && this.get('offer.price') <= this.get('auction.minPrice') && !confirm(this.get('buyNowConfirmationMsg'))) {
          this.get('offer').setProperties({
            price: this.get('offer.oldPrice'),
            isAcceptedWithMinPrice: false
          });
          return;
        }

        var self = this;
        this.set('savingInProgress', true);

        if (!this.get('offer').validate()) {
          console.debug("Offer did not pass validation.");
          self.set('savingInProgress', false);
          return;
        }

        this.get('offer').save().then(function (savedOffer) {
          if (savedOffer.get('valid')) {
            console.debug("Offer successfully saved with id ".concat(savedOffer.get('id'), "!"));
            self.set('transport.transportType.forcedPollRequestTime', new Date().getTime());
            self.setProperties({
              savingInProgress: false,
              errors: []
            });
            self.send('hideModal');
          } else {
            var errorMessage = self.get('intl').t('offer.errors.price.higherThanMaximum'); // Po zapisaniu oferty z ceną wyższą niż cena maks. aukcji, musimy przygotować nowy model
            // - by można było poprawić ofertę.

            var offerModel = _this2.get('store').createRecord('offer', {
              auction: savedOffer.get('auction'),
              price: savedOffer.get('price')
            });

            var model = {
              offer: offerModel,
              customFields: self.get('customFields')
            };
            self.setProperties({
              model: model,
              savingInProgress: false,
              errors: [errorMessage]
            });
          }
        }).catch(function (response) {
          var status = response.errors ? response.errors[0].status : undefined;
          var errors = response.errors;

          if (status === '401' || status === '403') {
            var errorMsg = self.get('intl').t('offer.errors.auction.hasEnded');
            alert(errorMsg);
            errors = [];
            self.set('transport.transportType.forcedPollRequestTime', new Date().getTime());
            self.send('hideModal');
          }

          self.setProperties({
            savingInProgress: false,
            errors: errors
          });
        });
      },
      reject: function reject() {
        var self = this;
        var token = this.get('sessionAccount').get('token');
        var auctionSetting = this.get('auctionSetting');
        var translationKey = 'AUCTION';

        if (auctionSetting) {
          translationKey = auctionSetting.get('translationKey');
        }

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/auctions/' + this.get('auction.id') + '/reject',
          type: 'PUT',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (respond) {
          if (!respond.error) {
            self.send('hideModal');
            alert(self.get('intl').t("auction.".concat(translationKey, ".reject.success")));
          } else {
            alert(self.get('intl').t("auction.".concat(translationKey, ".reject.fail.") + respond.error));
          }
        });
      },
      revokeOffer: function revokeOffer() {
        var self = this;
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/auctions/' + this.get('auction.id') + '/revokeOffer',
          type: 'PUT',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (respond) {
          if (!respond.error) {
            self.send('hideModal');
            alert(self.get('intl').t("offer.revoke.success"));
          } else {
            alert(self.get('intl').t("offer.revoke.fail." + respond.error));
          }

          self.get('transport.activeAuction').reload();
        });
      },
      cancel: function cancel() {
        console.debug("Zamykamy okienko ofertacji...");

        if (!this.get('offer.id')) {
          this.get('auction.offers').removeObject(this.get('offer'));
          this.get('offer').unloadRecord();
        }

        this.set('errors', []);
      }
    }
  });

  _exports.default = _default;
});