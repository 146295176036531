define("apollo/pods/queues/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    createDummyClassificationResource: function createDummyClassificationResource() {
      return Ember.Object.create({
        transport: null
      });
    },
    sortProperties: ['stateIdx:asc', 'lastUpdated:asc'],
    sortedClassificationResources: Ember.computed.sort('model.classificationResources', 'sortProperties'),
    enabledSquares: Ember.computed('model.squares.@each.enabled', function () {
      return this.get('model.squares').filterBy('enabled');
    }),
    queuesArray: Ember.computed('selectedSquare', 'model.classificationResources.@each.state', function () {
      var _this = this;

      var self = this;
      var queuesArray = Ember.A();

      if (!self.get('selectedSquare.queues')) {
        return;
      }

      var queues = self.get('selectedSquare.queues').filter(function (q) {
        return self.get('sessionAccount.currentUser.accessibleQueues').getEach('id').includes(q.get('id'));
      });
      queues.forEach(function (queue) {
        var classificationResources = _this.get('sortedClassificationResources').filter(function (cr) {
          return cr.get('queue.id') === queue.get('id');
        });

        classificationResources.pushObject(self.createDummyClassificationResource());

        _this.setAdditionalProperties(classificationResources);

        var obj = Ember.Object.create({
          queue: queue,
          classificationResources: classificationResources
        });
        queuesArray.pushObject(obj);
      });
      return queuesArray;
    }),
    setAdditionalProperties: function setAdditionalProperties(classificationResources) {
      classificationResources.forEach(function (cr, index) {
        cr.set('ordinalNumber', "".concat(++index, "."));
        cr.set('active', false);
      });
      var firstWithoutPlace = classificationResources.find(function (cr) {
        return cr.get('place.id') === undefined;
      });

      if (firstWithoutPlace) {
        firstWithoutPlace.set('active', true);
      }
    },
    actions: {
      setSelectedSquare: function setSelectedSquare(square) {
        this.set('selectedSquare', square);
      }
    }
  });

  _exports.default = _default;
});