define("apollo/services/translation-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    getTranslation: function getTranslation(settingClass, labelName, transportType) {
      var setting = this.getSetting(settingClass, transportType);
      var translationKey = !setting.get('object') ? setting.get('defaultTranslationKey') : setting.get('object.translationKey');
      return this.get('intl').t("".concat(setting.get('classKey'), ".").concat(translationKey, ".").concat(labelName));
    },
    getSetting: function getSetting(settingClass, transportType) {
      var settingObject, defaultTranslationKey;

      switch (settingClass) {
        case 'auction':
          {
            settingObject = this.get('sessionAccount').getAuctionSetting(transportType.get('id'));
            defaultTranslationKey = 'AUCTION';
            break;
          }

        case 'index':
          {
            settingObject = this.get('sessionAccount').getIndexSetting(transportType.get('id'));
            defaultTranslationKey = 'INDEX';
            break;
          }

        case 'courier':
          {
            settingObject = this.get('sessionAccount').getCourierSetting(transportType.get('id'));
            defaultTranslationKey = 'COURIER_PACKAGE';
            break;
          }

        case 'products':
        case 'product':
          {
            settingObject = this.get('sessionAccount').getProductSetting();
            defaultTranslationKey = 'INDEX';
            break;
          }

        case 'square':
          {
            settingObject = this.get('sessionAccount').getSquareSetting();
            defaultTranslationKey = 'SQUARE';
            break;
          }

        default:
          settingObject = {};
          defaultTranslationKey = '';
          settingClass = '';
          break;
      }

      return Ember.Object.create({
        object: settingObject,
        classKey: settingClass,
        defaultTranslationKey: defaultTranslationKey
      });
    }
  });

  _exports.default = _default;
});