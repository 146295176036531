define("apollo/pods/components/field-error-message/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Component.extend({
    intl: service(),
    tagName: 'span',
    attributeBindings: ['customFieldName:data-field-name'],
    customFieldName: 'field-error-span',
    generalErrors: ['blank', 'blankSelect', 'blankCheckbox', 'tooShort', 'tooLong', 'positiveNumber', 'positiveInteger', 'noNegativeNumber', 'number', 'invalidDatesSequence', 'invalidQuantity'],
    messageKey: Ember.computed('modelName', 'error.attribute', 'error.message', function () {
      var _this = this;

      if (this.get('generalErrors').some(function (r) {
        return _this.get('error.message').indexOf(r) >= 0;
      })) {
        return "errors.".concat(this.get('error.message'));
      }

      return "".concat(this.get('modelName'), ".errors.").concat(this.get('error.attribute'), ".").concat(this.get('error.message'));
    }),
    errorMessage: Ember.computed('messageKey', 'error.message', function () {
      var key = this.get('messageKey');

      if (this.get('intl').exists(key)) {
        return this.get('intl').t(key);
      } else {
        return this.get('error.message');
      }
    })
  });

  _exports.default = _default;
});