define("apollo/pods/transport-types/auction-settings/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    errors: [],
    transportType: Ember.computed('model.transportType.id', function () {
      return this.get('model.transportType');
    }),
    auctionSetting: Ember.computed('model.auctionSetting', function () {
      return this.get('model.auctionSetting');
    }),
    currencies: Ember.computed('', function () {
      return this.get('store').peekAll('currency').getEach('symbol');
    }),
    rollbackChanges: function rollbackChanges() {
      this.set('errors', []);
      this.set('successMessage', null);

      if (this.get('auctionSetting.id')) {
        this.get('auctionSetting').rollbackAttributes();
      } else {
        var auctionSetting = this.get('auctionSetting');

        if (auctionSetting) {
          auctionSetting.unloadRecord();
        }

        this.set('auctionSetting', null);
      }
    },
    actions: {
      save: function save() {
        var _this = this;

        this.set('errors', []);
        this.get('auctionSetting').save().then(function () {
          _this.send('refreshModel');

          var msg = _this.get('intl').t('common.saveSuccessMessage');

          _this.set('successMessage', msg);

          (0, _jquery.default)("html, body").animate({
            scrollTop: 0
          }, "slow");
        }).catch(function (response) {
          _this.set('errors', response.errors);

          (0, _jquery.default)("html, body").animate({
            scrollTop: 0
          }, "slow");
        });
      },
      create: function create() {
        if (this.get('auctionSetting')) {
          return;
        }

        var transportType = this.get('transportType');
        var auctionSetting = this.get('store').createRecord('auction-setting', {
          transportType: transportType,
          company: transportType.get('company')
        });
        this.set('auctionSetting', auctionSetting);
      },
      cancel: function cancel() {
        this.rollbackChanges();
        this.transitionToRoute('settings.index');
      }
    }
  });

  _exports.default = _default;
});