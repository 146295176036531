define("apollo/utils/validators/registration-number-validator", ["exports", "apollo/utils/validators/regex-helper"], function (_exports, _regexHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function validate(value) {
    var pL = _regexHelper.default.pL();

    var regex = '^[$' + pL + '0-9 _.,;&/]{4,50}$';
    return new RegExp("".concat(regex)).test(value);
  }

  var _default = {
    validate: validate
  };
  _exports.default = _default;
});