define("apollo/pods/truck-type/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    sessionAccount: Ember.inject.service('session-account'),
    languageService: Ember.inject.service('language-service'),
    intl: Ember.inject.service(),
    truckTypeName: _emberData.default.attr('string'),
    truckTypeNameEn: _emberData.default.attr('string'),
    nameInAppropriateLanguage: Ember.computed('truckTypeName', 'truckTypeNameEn', function () {
      var propertyName = this.get('languageService').findProperLanguageVersion('truckTypeName', 'truckTypeNameEn');
      return this.get(propertyName);
    })
  });

  _exports.default = _default;
});