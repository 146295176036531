define("apollo/pods/warehouse-location/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    sessionAccount: Ember.inject.service('session-account'),
    languageService: Ember.inject.service('language-service'),
    warehouse: _emberData.default.belongsTo('warehouse', {
      async: true
    }),
    namePl: _emberData.default.attr('string'),
    nameEn: _emberData.default.attr('string'),
    shortNamePl: _emberData.default.attr('string'),
    shortNameEn: _emberData.default.attr('string'),
    enabled: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return true;
      }
    }),
    gate: _emberData.default.belongsTo('gate'),
    nameInAppropriateLanguage: Ember.computed('namePl', 'nameEn', function () {
      var propertyName = this.get('languageService').findProperLanguageVersion('namePl', 'nameEn');
      return this.get(propertyName);
    }).readOnly(),
    validations: {}
  });

  _exports.default = _default;
});