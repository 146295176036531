define("apollo/pods/warehouses/template/controller", ["exports", "apollo/mixins/schedule-mixin"], function (_exports, _scheduleMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_scheduleMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax-service'),
    scheduleController: Ember.inject.controller('warehouses/schedule'),
    isTemplate: true,
    isSaving: false,
    daysOfWeek: Ember.A([{
      idx: 1,
      checked: true
    }, {
      idx: 2,
      checked: true
    }, {
      idx: 3,
      checked: true
    }, {
      idx: 4,
      checked: true
    }, {
      idx: 5,
      checked: true
    }, {
      idx: 6,
      checked: false
    }, {
      idx: 7,
      checked: false
    }]),
    selectedDayIndexes: Ember.computed('daysOfWeek.@each.checked', function () {
      return this.get('daysOfWeek').filter(function (d) {
        return d.checked;
      }).map(function (checkedDay) {
        return checkedDay.idx;
      });
    }),
    dateRangeOption: 'M',
    dateRangeOptions: Ember.A(['M', 'Y', 'X']),
    dateRange: null,
    // eslint-disable-next-line ember/no-observers
    setDateRange: Ember.observer('dateRangeOption', function () {
      var format = this.get('dateFormat');
      var d = moment().format(format);
      this.set('dateRange', d);
    }).on('init'),
    dateFormat: Ember.computed('dateRangeOption', function () {
      return this.get('dateRangeOption') === 'M' ? 'MMMM YYYY' : 'YYYY';
    }),
    isCustomRangeSelected: Ember.computed('dateRangeOption', function () {
      return this.get('dateRangeOption') === 'X';
    }),
    dateRanges: Ember.computed('dateRangeOption', function () {
      var ranges = [];
      var rangeStartM = moment().startOf('month');
      var rangeEndM = moment(rangeStartM).add(2, 'years');
      var format = this.get('dateFormat');

      for (var m = rangeStartM; m.isBefore(rangeEndM); m = m.add(1, 'month')) {
        ranges.push(m.format(format));
      }

      return ranges.uniq();
    }),
    shownDays: Ember.computed('scheduleStartDate', function () {
      var daysOfWeek = [];
      var startDate = this.get('scheduleStartDate');

      for (var i = 0; moment(startDate).add(i, 'days') < this.get('scheduleEndDate'); i++) {
        daysOfWeek[i] = {
          date: moment(startDate).add(i, 'daysOfWeek'),
          name: moment(startDate).add(i, 'daysOfWeek').format('YYYY-MM-DD'),
          longName: moment(startDate).add(i, 'daysOfWeek').format('DD.MM.YYYY dddd')
        };
      }

      return daysOfWeek;
    }),
    showSelectDepartment: Ember.computed('model.userGroups.@each.length', function () {
      return this.get('model.userGroups.length') > 0;
    }).readOnly(),
    scheduleEndDate: Ember.computed('scheduleStartDate', function () {
      return moment(this.get('scheduleStartDate')).endOf('day').toDate();
    }),
    actions: {
      setCustomRangeStartDate: function setCustomRangeStartDate(date) {
        this.set('customDateStart', date[0]);
      },
      setCustomRangeEndDate: function setCustomRangeEndDate(date) {
        this.set('customDateStop', date[0]);
      },
      changeScheduleStartDate: function changeScheduleStartDate(date) {
        this.set('scheduleStartDate', date[0]);
      },
      toggleDay: function toggleDay(idx) {
        var day = this.get('daysOfWeek').filter(function (d) {
          return d.idx === idx;
        }).get('firstObject');
        Ember.set(day, 'checked', !day.checked);
      },
      selectWarehouse: function selectWarehouse(warehouse) {
        var scheduleStartString = moment(this.get('scheduleStartDate')).format('YYYY-MM-DD');
        this.set('defaultZoom', undefined);
        this.transitionToRoute('warehouses.template', warehouse.get('id'), scheduleStartString);
      },
      createWindow: function createWindow(start, stop, rampId) {
        if (this.get('sessionAccount').hasRole('ROLE_BLOCK_RAMPS')) {
          this.storeDedicatedWindow(start, stop, rampId);
        } else {
          console.log('Brak ROLE_BLOCK_RAMPS.');
        }
      },
      removeBlockedWindow: function removeBlockedWindow(window) {
        if (!this.get('sessionAccount').hasRole('ROLE_BLOCK_RAMPS')) {
          console.log('Brak ROLE_BLOCK_RAMPS.');
          return;
        }

        window.deleteRecord();
        this.get('windows').removeObject(window);
      },
      onDraggableStop: function onDraggableStop(event) {
        var self = this;
        var target = $(event.target);
        var windowId = target.data('schedule-window');
        var left = target.offset().left - $(document).scrollLeft();
        var top = target.offset().top - $(document).scrollTop();
        var bottom = target.height() + top;
        var isSupplement = target.data('is-supplement');
        this.set('inProgress', true);
        var windowModel = this.store.peekRecord('timeWindow', windowId);
        var oldRamp = windowModel.get('ramp');
        var oldStart = windowModel.get('start');
        var oldStop = windowModel.get('stop');
        var currentLengthInMS = windowModel.get('stop') - windowModel.get('start');
        var minutesDiv, $minutesDiv, newStart, newStop;

        if (!isSupplement) {
          minutesDiv = this.findMinutesDiv(left + 10, top + 2);
          $minutesDiv = $(minutesDiv);
          newStart = this.getDate($minutesDiv);
          newStop = moment(newStart).add(currentLengthInMS, 'milliseconds').toDate();
        }

        if (!minutesDiv) {
          minutesDiv = this.findMinutesDiv(left + 10, bottom + 2);
          $minutesDiv = $(minutesDiv);
          newStop = this.getDate($minutesDiv);
          newStart = moment(newStop).subtract(currentLengthInMS, 'milliseconds').toDate();
        }

        var rampId = this.getRampId($minutesDiv);

        if (newStart.getTime() === oldStart.getTime() && newStop.getTime() === oldStop.getTime() && oldRamp.get('id') === rampId.toString()) {
          console.log('brak zmian; przerywamy');
          windowModel.notifyPropertyChange('start');
          return;
        }

        if (!minutesDiv) {
          windowModel.setProperties({
            ramp: oldRamp,
            start: oldStart,
            stop: oldStop
          });
          windowModel.notifyPropertyChange('start');
          return;
        }

        self.get('windows').removeObject(windowModel);
        windowModel.setProperties({
          ramp: self.store.peekRecord('ramp', rampId),
          start: newStart,
          stop: newStop
        });
        var r = windowModel.get('ramp');
        console.log("Pr\xF3ba przesuni\u0119cia okna dedykowanego ".concat(windowId, ":\n                    [").concat(windowModel.get('start'), ", ").concat(oldRamp.get('name'), " (#").concat(oldRamp.get('id'), ")]\n                    -> [").concat(newStart, ", ").concat(r.get('name'), " (#").concat(r.get('id'), ")]"));
        var warehouseOpenTime = this.get('warehouse').getStartOnDay(this.get('scheduleStartDate'));
        var warehouseCloseTime = this.get('warehouse').getEndOnDay(this.get('scheduleStartDate'));

        if (this.modelOverlapsAnyWindow(windowModel) || newStart < warehouseOpenTime || newStop > warehouseCloseTime) {
          windowModel.setProperties({
            ramp: oldRamp,
            start: oldStart,
            stop: oldStop
          });
        }

        self.replaceTimeWindow(windowModel);
        self.send('removePopupDialog');
        self.set('inProgress', false);
      },
      saveWindows: function saveWindows() {
        var _this = this;

        var self = this;
        self.set('isSaving', true);
        $('.save-template-button').button('loading');
        var maxPallet = this.store.peekAll('maxPallet').find(function (p) {
          return p.get('warehouse.id') === _this.get('model.warehouse.id') && p.get('isTemplate');
        });
        var warehouseId = this.get('model.warehouse').get('id');
        var data = {
          warehouse: {
            id: warehouseId
          },
          windows: this.get('windows'),
          daysOfWeek: this.get('selectedDayIndexes'),
          dateRangeOption: this.get('dateRangeOption'),
          dateRange: moment(this.get('dateRange'), this.get('dateFormat')).format('YYYY-MM'),
          maxPalletQuantity: maxPallet ? maxPallet.get('quantity') : null,
          start: moment(this.get('customDateStart')).format('YYYY-MM-DD'),
          stop: moment(this.get('customDateStop')).format('YYYY-MM-DD')
        };
        var url = '/warehouses/' + warehouseId + '/template';
        this.get('ajaxService').send(url, 'POST', data, true).then(function (data) {
          self.setProperties({
            errors: [],
            isSaving: false
          });
          var successMessage = self.get('intl').t('warehouse.templates.savedMessage');

          if (data.datesWithErrorMessage) {
            successMessage += ' ' + self.get('intl').t('warehouse.templates.datesWithErrorMessage', {
              windows: data.datesWithErrorMessage
            });
          }

          self.get('scheduleController').setProperties({
            successMessage: successMessage
          });
          var dateString = moment(self.get('scheduleStartDate')[0]).format('YYYY-MM-DD');
          console.log('.. przechodzimy do widoku harmonogramu..'); // wyrzucamy z załadowanych dni te widoczne, żeby odświeżyć widok zgodnie z nowozapisanym szablonem

          self.get('shownDays').forEach(function (shownDay) {
            var day = shownDay.date.format('DD.MM.YYYY HH:mm');
            self.get('model.warehouse.loadedDays').removeObject(day);
          });
          self.transitionToRoute('warehouses.schedule', warehouseId, dateString, '1');
        }, function (error) {
          console.log('error: ' + error);
          self.setProperties({
            errors: [error.responseText],
            isSaving: false
          });
        });
      },
      cancel: function cancel() {
        this.set('errors', null);
        var warehouseId = this.get('model.warehouse').get('id');
        this.transitionToRoute('warehouses.schedule', warehouseId, this.get('scheduleStartDateFormatted'), '1');
      },
      openNoteWindow: function openNoteWindow(window) {
        window.isTemplateView = true;
        this.send('showModal', 'time-window.modals.window-note', window);
      },
      toggleWholeRampWindow: function toggleWholeRampWindow(time, rampId) {
        console.log('toggleWholeRampWindow');
        var start = this.get('warehouse').getStartOnDay(time);
        var stop = this.get('warehouse').getEndOnDay(time);
        this.storeDedicatedWindow(start, stop, rampId);
      },
      notifyWindowFocusOut: function notifyWindowFocusOut(window) {
        if (this.get('focusedWindowId') === window.id) {
          this.set('focusedWindowId', null);
        }
      }
    },
    setWindows: function setWindows() {
      if (!this.get('rampsIds.length')) {
        return;
      }

      console.log('setWindows');
      var self = this;
      var scheduleStartDate = this.get('scheduleStartDate');
      var startString = moment(scheduleStartDate).format('DD.MM.YYYY HH:mm');
      var data = {
        start: startString,
        isTemplate: true,
        rampsIds: this.get('rampsIds').join(',')
      };
      return this.store.query('timeWindow', data).then(function (results) {
        self.set('windows', results.toArray());
      });
    },
    storeDedicatedWindow: function storeDedicatedWindow(start, stop, rampId) {
      console.log("storeDedicatedWindow / start: ".concat(start, " / stop: ").concat(stop, " / rampId: ").concat(rampId));
      var self = this;
      var randomId = Math.floor(Math.random() * 10000 + 1);
      var windowData = {
        id: randomId,
        ramp: this.store.peekRecord('ramp', rampId),
        company: this.get('company'),
        userGroup: this.get('selectedUserGroup'),
        start: start.toDate(),
        stop: stop.toDate(),
        isTemplate: true,
        initialStart: start
      };

      if (this.overlapsAnyWindow(windowData)) {
        console.log('windows overlap');
        return;
      }

      var window = this.store.createRecord('timeWindow', windowData);
      self.get('windows').pushObject(window);
    }
  });

  _exports.default = _default;
});