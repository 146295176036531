define("apollo/pods/components/log-label/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    languageService: Ember.inject.service('language-service'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    label: Ember.computed('userActivityTransportLabel', 'log.transportId', 'log.className', function () {
      if (this.get('log.transportId')) {
        return this.get('userActivityTransportLabel');
      } else if (this.get('log.className') === 'Partnership') {
        return this.get('intl').t("log.partnership", {
          id: this.get('log.persistedObjectId')
        });
      } else {
        return "";
      }
    }),
    userActivityTransportLabel: Ember.computed('', function () {
      var transportTypeId = this.get('log.changes.firstObject.transportTypeId');
      var transportIdentifier = this.get('log.changes.firstObject.transportIdentifier');

      if (!transportTypeId) {
        return;
      }

      var propertyName = this.get('languageService').findProperLanguageVersion('name', 'englishName');
      var label = this.get('store').peekRecord('transportType', transportTypeId).get(propertyName);
      return [label, transportIdentifier].filter(function (o) {
        return o;
      }).join(" ");
    })
  });

  _exports.default = _default;
});