define("apollo/pods/worker/model", ["exports", "ember-data", "ember-data/model", "apollo/mixins/model-validator"], function (_exports, _emberData, _model, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = _model.default.extend(_modelValidator.default, {
    intl: Ember.inject.service(),
    name: attr('string'),
    phoneNumber: attr('string'),
    phoneNumberPrefix: attr('string', {
      defaultValue: '48'
    }),
    company: belongsTo('company', {
      async: true
    }),
    principalCompany: belongsTo('company'),
    enabled: attr('boolean'),
    trained: attr('boolean'),
    formattedPhoneNumber: Ember.computed('phoneNumberPrefix', 'phoneNumber', function () {
      if (!this.get('phoneNumberPrefix') || !this.get('phoneNumber')) {
        return null;
      }

      return '+' + this.get('phoneNumberPrefix') + ' ' + this.get('phoneNumber');
    }),
    validations: {
      name: {
        presence: {
          message: 'blank'
        }
      },
      phoneNumber: {
        custom: [{
          validation: function validation(key, value) {
            if (!value) {
              return true;
            }

            return /^[0-9]+$/.test(value) && value.length >= 9;
          },
          message: 'regex'
        }]
      }
    }
  });

  _exports.default = _default;
});