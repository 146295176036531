define("apollo/pods/components/logs-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    classNames: ['logs-div'],
    groupingLogService: Ember.inject.service('grouping-log-service'),
    ajaxService: Ember.inject.service('ajax-service'),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    isApiIntegrationLogs: Ember.computed(function () {
      return this.get('apiIntegration');
    }),
    isARTRLogs: Ember.computed(function () {
      return this.get('artrLogs');
    }),
    isSuperUser: Ember.computed('sessionAccount.isSuperUser', function () {
      return this.get('sessionAccount.isSuperUser');
    }),
    logs: Ember.computed('logsProxy', function () {
      if (this.get('isApiIntegrationLogs') || this.get('isARTRLogs')) {
        return this.get('logsProxy').sortBy('dateCreated').reverse();
      }

      return this.get('groupingLogService').groupLogs(this.get('logsProxy').sortBy('dateCreated').reverse());
    }),
    actions: {
      getIntegrationRequestLog: function getIntegrationRequestLog(log) {
        if (!log.request) {
          this.getIntegrationRequestLog(log);
        }
      },
      resend: function resend(log) {
        var _this = this;

        if (!confirm(this.get('intl').t('log.integration.sureToResend'))) {
          return;
        }

        this.get('ajaxService').send("/transports/resendApiMessage/".concat(log.get('id')), 'POST').then(function () {
          alert(_this.get('intl').t('log.integration.resendSuccess'));

          _this.refreshLogs(log.get('transport'));
        }).catch(function () {
          alert(_this.get('intl').t('log.integration.resendFailed'));
        });
      }
    }
  });

  _exports.default = _default;
});