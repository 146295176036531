define("apollo/utils/validators/windowFitsToScheduleValidator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function validate(windowCurrentTime, currentWindowOffset, workStartHour) {
    /**
     * @param  {Date} windowCurrentTime    Początek lub koniec okna czasowego. Czas musi być podany dla zegara 24-godzinnego.
     * @param  {number} currentWindowOffset  Obecna wartość siatki harmonogramu podana w minutach.
     * @param  {number} workStartHour        Godzina rozpoczęcia pracy magazynu (również 24-godzinny zegar).
     *
     * @return {Boolean}                     Czy okno pasuje do aktualnej siatki harmonogramu.
     *
     * Metoda sprawdza, czy stawiane okno czasowe pasuje do aktualnej siatki harmonogramu.
     *
     * Przykład:
     * Mamy siatkę 45-minutową w magazynie 24-godzinnym i próbujemy postawić okno na godzinę 3:15.
     * Sprawdzamy więc, czy wyrażenie `((windowCurrentTime.getHours() * 60 + windowCurrentTime.getMinutes()) - 0 * 60) / currentWindowOffset`
     * jest liczbą całkowitą: (3*60 + 15) / 45 ~= 4.3.
     * Wyrażenie (3*60 + 15) nie jest podzielne przez minimalny dopuszczalny rozmiar okna (45), więc okno nie może
     * zaczynać się o godzinie 3:15, wówczas metoda zwróci False.
     */
    var windowCurrentTimeHourInMinutes = windowCurrentTime.getHours() * 60;
    var windowCurrentTimeMinutes = windowCurrentTime.getMinutes();
    var windowCurrentTimeInMinutes = windowCurrentTimeHourInMinutes + windowCurrentTimeMinutes;
    var warehouseWorkStartHourInMinutes = workStartHour * 60;
    return Number.isInteger((windowCurrentTimeInMinutes - warehouseWorkStartHourInMinutes) / currentWindowOffset);
  }

  var _default = {
    validate: validate
  };
  _exports.default = _default;
});