define("apollo/pods/components/await-response-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: 'button',
    attributeBindings: ['type', 'data-loading-text', 'data-button-name', 'style', 'disabled'],
    type: 'button',
    'data-loading-text': Ember.computed('', function () {
      return this.get('intl').t('common.pleaseWait');
    }),
    'data-button-name': Ember.computed('', function () {
      return this.get('data-button-name');
    }),
    click: function click() {
      var self = this;
      $(self.element).prop('disabled', true);
      $(self.element).button('loading');
      var defer = Ember.RSVP.defer();
      defer.promise.then(function () {
        return $(self.element).button('reset');
      });
      self.submit(defer);
    }
  });

  _exports.default = _default;
});