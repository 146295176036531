define("apollo/pods/components/transports/transports-table/column-names/component", ["exports", "apollo/mixins/form-utils-mixin"], function (_exports, _formUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Component.extend(_formUtilsMixin.default, {
    sessionAccount: service('session-account'),
    // Używane w hbs.
    tagName: 'div',
    classNames: ['column-names', 'row', 'flex-position-start'],
    getTransportSupervisorTranslationKey: Ember.computed('transportType', function () {
      return this.get('transportType.transportSupervisorTranslationKey');
    }),
    getWorkerTranslationKey: Ember.computed('transportType', function () {
      return this.get('transportType.workerFieldTranslationKey');
    }),
    actions: {}
  });

  _exports.default = _default;
});