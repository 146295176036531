define("apollo/pods/companies/ramp-edition/controller", ["exports", "apollo/mixins/ramps-access-mixin", "jquery"], function (_exports, _rampsAccessMixin, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_rampsAccessMixin.default, {
    CHECKBOXES_DEFAULT_CHECKED: false,
    updateForAllUsers: true,
    store: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax-service'),
    intl: Ember.inject.service(),
    errors: [],
    successMessage: null,
    company: Ember.computed('model.company.id', function () {
      return this.get('model.company');
    }),
    principalCompany: Ember.computed(function () {
      var principalId = localStorage.getItem('principalId');
      return this.get('store').peekRecord('company', principalId);
    }),
    companyRamps: Ember.computed('model.companyRamps.[]', function () {
      return this.get('model.companyRamps');
    }),
    setProxiedWarehouses: Ember.observer('proxiedWarehouses.length', 'model', function () {
      var self = this;
      Ember.run.once(this, function () {
        self.checkWarehouses();
      });
    }),
    setProxiedRamps: Ember.observer('proxiedRamps.length', 'model', function () {
      var self = this;
      Ember.run.once(this, function () {
        self.checkRamps();
      });
    }),
    allRampsChecked: Ember.computed('proxiedRamps.length', 'checkedRamps.length', function () {
      return this.get('proxiedRamps.length') === this.get('checkedRamps.length');
    }),
    checkWarehouses: function checkWarehouses() {
      var proxiedWarehouses = this.get('proxiedWarehouses');
      var warehouseIds = this.get('companyRamps').getEach('ramp.warehouse.id').uniq();
      proxiedWarehouses.forEach(function (w) {
        return w.set('checked', warehouseIds.includes(w.get('id')));
      });
    },
    checkRamps: function checkRamps() {
      var proxiedRamps = this.get('proxiedRamps');
      var userRampsIds = this.get('companyRamps').getEach('ramp.id').uniq();
      proxiedRamps.forEach(function (r) {
        return r.set('checked', userRampsIds.includes(r.get('id')));
      });
    },
    setSuccessMessage: function setSuccessMessage() {
      this.set('successMessage', this.get('intl').t("common.saveSuccessMessage"));
      this.set('errors', []);
      (0, _jquery.default)("html, body").animate({
        scrollTop: 0
      }, 'slow');
      this.resetButton();
    },
    setErrorMessage: function setErrorMessage(message) {
      this.set('successMessage', null);
      this.set('errors', message);
      (0, _jquery.default)("html, body").animate({
        scrollTop: 0
      }, 'slow');
      this.resetButton();
    },
    resetButton: function resetButton() {
      var button = (0, _jquery.default)("button[data-button-name='save-ramps']");
      button.button('reset');
    },
    actions: {
      toggleAllRamps: function toggleAllRamps() {
        var shouldBeChecked = !this.get('allRampsChecked');
        this.get('proxiedWarehouses').forEach(function (w) {
          w.set('checked', shouldBeChecked);
          w.get('proxiedRamps').forEach(function (r) {
            return r.set('checked', shouldBeChecked);
          });
        });
      },
      save: function save() {
        var _this = this;

        var updateForAllUsers = this.get('updateForAllUsers');
        var hasUsers = this.get('company.users.length') > 0;

        if (updateForAllUsers && hasUsers && !confirm(this.get('intl').t("companyRamp.updateForAllUsersConfirmMsg"))) {
          this.resetButton();
          return;
        }

        var checkedRamps = this.get('proxiedRamps').filter(function (r) {
          return r.checked;
        }).getEach('id');
        var companyId = this.get('company.id');
        var principalCompanyId = this.get('principalCompany.id');
        var data = {
          ramps: checkedRamps,
          company: companyId,
          principalCompany: principalCompanyId,
          updateForAllUsers: updateForAllUsers
        };
        this.get('ajaxService').send("/company-ramps", "POST", data, true).then(function () {
          return _this.setSuccessMessage();
        }).catch(function (response) {
          return _this.setErrorMessage(JSON.parse(response.responseText).errors);
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('companies.show', this.get('company.id'));
      },
      refreshUsers: function refreshUsers() {
        var _this2 = this;

        this.store.query('user', {
          individualRampAccess: true,
          companyId: this.get('company.id')
        }).then(function (users) {
          Ember.set(_this2.get('model'), 'users', users);
        });
      }
    }
  });

  _exports.default = _default;
});