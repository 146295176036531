define("apollo/pods/workflows/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    settingsIndexController: Ember.inject.controller('settings/index'),
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    taskGroupIndexes: Ember.A([0, 1, 2]),
    tasks: Ember.computed('model.tasks', function () {
      return this.get('model.tasks');
    }),
    proxiedTaskGroups: Ember.A([]),
    errors: Ember.A([]),
    // eslint-disable-next-line ember/no-observers
    scheduleSetProxiedTaskGroups: Ember.observer('tasks.@each.idxInGroup', function () {
      Ember.run.once(this, 'setProxiedTaskGroups');
    }).on('init'),
    setProxiedTaskGroups: function setProxiedTaskGroups() {
      var _this = this;

      var self = this;

      if (!this.get('tasks')) {
        return;
      }

      this.get('tasks').then(function (loadedTasks) {
        var proxiedTaskGroups = _this.get('taskGroupIndexes').map(function (groupIdx) {
          var filteredTasks = loadedTasks.filter(function (task) {
            return task.get('taskGroupIdentifier') === groupIdx && !task.get('masterTask.id');
          });
          var references;

          if (groupIdx === 1) {
            references = ['stepStart', 'stepStop'];
          } else {
            references = ['transportCreated', 'earliestStepStart', 'latestStepStop'];

            if (self.get('model.transportType.requiresAdviceDateRestrictions')) {
              references.push('minAdviceDate');
            }
          }

          return Ember.ObjectProxy.create({
            content: groupIdx,
            tasks: filteredTasks.sortBy('idxInGroup'),
            references: references
          });
        });

        _this.set('proxiedTaskGroups', proxiedTaskGroups);
      });
    },
    destroyOrRollbackTask: function destroyOrRollbackTask(task) {
      if (!task) {
        return;
      }

      if (task.get('id')) {
        task.rollbackAttributes();
      } else {
        task.destroyRecord();
      }
    },
    destroyOrRollbackSubtasks: function destroyOrRollbackSubtasks(task) {
      var self = this;

      if (!task) {
        return;
      }

      if (task.get('subTasks.length') > 0) {
        task.get('subTasks').forEach(function (subTask) {
          self.destroyOrRollbackTask(subTask);
        });
      }
    },
    actions: {
      toggleTracked: function toggleTracked(task) {
        var tracked = task.get('tracked');
        task.set('tracked', !tracked);
      },
      toggleMarkIfWasDelayed: function toggleMarkIfWasDelayed(task) {
        var markIfWasDelayed = task.get('markIfWasDelayed');
        task.set('markIfWasDelayed', !markIfWasDelayed);
      },
      addTask: function addTask(identifier, idx) {
        var workflow = this.get('model');
        this.get('store').createRecord('task', {
          workflow: workflow,
          taskGroupIdentifier: identifier,
          idxInGroup: idx,
          expectedDateInterval: '30m'
        });
      },
      dragStart: function dragStart(object) {
        console.log('Drag Start', object);
      },
      sortEndAction: function sortEndAction() {
        this.get('proxiedTaskGroups').forEach(function (tg) {
          tg.get('tasks').forEach(function (t, idx) {
            t.set('idxInGroup', idx);
          });
        });
      },
      update: function update() {
        var _this2 = this;

        var self = this;

        if (!self.get('model').validate()) {
          return;
        }

        self.get('model').save().then(function (w) {
          console.log('Workflow ' + w.id + ' saved.');
          self.setProperties({
            success: true,
            errors: []
          });

          _this2.set('successMessage', self.get('intl').t('workflows.saveSuccessMessage'));

          alert(self.get('intl').t('workflows.saveSuccessMessage'));

          var taskToReload = _this2.get('store').peekAll('task').filter(function (task) {
            return task.get('id') === null;
          });

          taskToReload.forEach(function (task) {
            task.deleteRecord();
          });
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      },
      cancel: function cancel() {
        var self = this;
        this.get('model').rollbackAttributes();
        this.get('tasks').forEach(function (task) {
          self.destroyOrRollbackSubtasks(task);
          self.destroyOrRollbackTask(task);
        });
        this.transitionToRoute('settings.index');
      }
    }
  });

  _exports.default = _default;
});