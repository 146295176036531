define("apollo/pods/workflow/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    languageService: Ember.inject.service('language-service'),
    tasks: _emberData.default.hasMany('task', {
      async: true
    }),
    transportType: _emberData.default.belongsTo('transportType', {
      async: true
    }),
    name: _emberData.default.attr('string'),
    finalStageName: _emberData.default.attr('string'),
    finalStageNameEn: _emberData.default.attr('string'),
    finalColor: _emberData.default.attr('string'),
    dateCreated: _emberData.default.attr('date'),
    sortedTasks: function () {
      return this.get('tasks').sortBy('taskGroupIdentifier', 'idxInGroup').filterBy('enabled');
    }.property('tasks.@each.idxInGroup'),
    localisedFinalStageName: function () {
      var propertyName = this.get('languageService').findProperLanguageVersion('finalStageName', 'finalStageNameEn');
      return this.get(propertyName);
    }.property('finalStageName', 'finalStageNameEn')
  });

  _exports.default = _default;
});