define("apollo/mixins/persistence-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  // eslint-disable-next-line ember/no-new-mixins
  var _default = Ember.Mixin.create({
    session: Ember.inject.service('session'),
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax-service'),

    /**
     * Ember-data nie potrafi automatycznie usunąć obiektów z relacji, jeśli te zostaną
     * usunięte po stronie serwera - musimy więc to zrobić ręcznie.
     * Poniższa metoda zapyta serwer o aktualne relacje dla podanego modelu i usunie te
     * obiektu, które już nie istnieją.
     *
     * Uwaga: serwer musi potrafić obsłużyć konkretne zapytanie ajax.
     *
     * @param modelName - nazwa modelu; np. 'transport' lub 'timeWindow'
     * @param modelId - id modelu
     * @param childModelName - nazwa modelu relacji zdefiniowana jako DS.hasMany; np. 'checkpoint' lub 'timeWindow'
     */
    unloadDeletedRecords: function unloadDeletedRecords(modelName, modelId, childModelName) {
      var self = this;
      return new Ember.RSVP.Promise(function (resolve) {
        var store = self.store;

        if (!store.peekRecord) {
          store = self.get('store');
        }

        var model = store.peekRecord(modelName, modelId);

        if (!model) {
          resolve();
        } // TODO: Jakaś mądrzejsza pluralizacja, jeśli będzie potrzeba.


        var relationship = childModelName + 's';
        model.get(relationship).then(function (currentRecords) {
          self.get('ajaxService').send("/".concat(modelName, "s/") + modelId + "/".concat(relationship), 'GET').then(function (json) {
            currentRecords.map(function (r) {
              return parseInt(r.id);
            }).forEach(function (currentId) {
              if (!json[relationship].filterBy('id', currentId).length) {
                var r = store.peekRecord('checkpoint', currentId);
                model.get(relationship).removeObject(r);
                r.destroyRecord().then(function () {
                  r.unloadRecord();
                  console.debug("Unloaded ".concat(childModelName, " #").concat(r.id, " from ").concat(modelName, " #").concat(modelId, "."));
                });
              }
            });
            resolve();
          });
        });
      });
    },

    /**
     * Ember-data czasem wczytuje do sesji zduplikowane rekordy w przypadku relacji has-many.
     * Poniższa metoda usuwa nadmiarowe rekordy.
     *
     * @param parentModel
     * @param relationshipName
     */
    removeDuplicateRecords: function removeDuplicateRecords(parentModel, relationshipName) {
      var ids = [];
      var duplicatedModels = [];
      parentModel.get(relationshipName).forEach(function (m) {
        if (ids.includes(m.id)) {
          duplicatedModels.push(m);
        } else {
          ids.push(m.id);
        }
      });
      duplicatedModels.forEach(function (r) {
        console.debug("Deleted duplicated record: #".concat(r.id));
        parentModel.get(relationshipName).removeObject(r);
      });
    },
    // https://stackoverflow.com/questions/4465244/compare-2-json-objects
    deepEquals: function deepEquals(o1, o2) {
      var self = this;

      if (o1 === null || o2 === null) {
        return;
      }

      var k1 = Object.keys(o1).sort();
      var k2 = Object.keys(o2).sort();

      if (k1.length !== k2.length) {
        return false;
      }

      return k1.map(function (keyPair) {
        if (_typeof(o1[keyPair]) === 'object' && _typeof(o2[keyPair]) === 'object') {
          return self.deepEquals(o1[keyPair], o2[keyPair]);
        } else {
          if (o1[keyPair] !== o2[keyPair]) {
            return false;
          }

          return o1[keyPair] === o2[keyPair];
        }
      });
    },

    /**
     * Metoda, która zapamiętuje "brudne" custom field option
     * (podczas przeładowywania transportu zmiany były usuwane)
     */
    getPreviousCustomFieldValuesMap: function getPreviousCustomFieldValuesMap(transportId) {
      var prevCustomFieldValues = [];
      var transport = this.get('store').peekRecord('transport', transportId);

      if (transport === null) {
        return prevCustomFieldValues;
      }

      transport.get('customFields').forEach(function (cf) {
        prevCustomFieldValues.push({
          id: cf.get('id'),
          isSelect: cf.get('definition.isSelectType'),
          option: cf.get('option'),
          value: cf.get('value'),
          prevValue: cf.get('prevValue'),
          isDirty: cf.get('hasDirtyAttributes'),
          lastUpdated: cf.get('lastUpdated')
        });
      });
      return prevCustomFieldValues;
    },

    /**
     * Metoda, która przywraca usunięte podczas przeładowywania
     * custom field options
     */
    markDirtyCustomFieldValues: function markDirtyCustomFieldValues(transport, prevCustomFieldValues, newCustomFieldValues) {
      var _this = this;

      console.debug("Pr\xF3bujemy przywr\xF3ci\u0107 usuni\u0119te podczas prze\u0142adowywania (np. okna czasowego) custom fields w transporcie ".concat(transport.get('logString'), "..."));
      var currentCustomFields = transport.get('customFields');
      var newCustomFields = currentCustomFields.filter(function (ccf) {
        return !prevCustomFieldValues.map(function (pcf) {
          return pcf.id;
        }).includes(ccf.get('id'));
      });
      prevCustomFieldValues.forEach(function (dcf) {
        var cf = currentCustomFields.find(function (cf) {
          return cf.get('id') && cf.get('id') === dcf.id;
        });

        if (!cf) {
          var _prevValueString = dcf.isSelect ? dcf.option ? dcf.option.get('nameInAppropriateLanguage') : '-' : dcf.value;

          _this.setCfMessageAndPrevValue(cf, 'formChanges.valueRemoved', {
            value: _prevValueString
          }, _prevValueString, true);

          return;
        }

        var propertyName = dcf.isSelect ? 'option' : 'value';
        var prevValue = dcf.isSelect ? dcf.option ? dcf.option.get('id') : null : dcf.value;
        var correspondingValue = newCustomFieldValues.find(function (x) {
          return parseInt(x.id) === parseInt(dcf.id);
        });
        var newValue = dcf.isSelect ? cf.get('option.id') : correspondingValue ? correspondingValue.value : '';
        var hasBeenUpdated = dcf.lastUpdated !== cf.get('lastUpdated');
        var valueChanged = prevValue !== newValue;

        if (!valueChanged) {
          return;
        }

        var prevValueString = dcf.isSelect ? dcf.option ? dcf.option.get('nameInAppropriateLanguage') : '-' : dcf.value;
        var newValueString = dcf.isSelect ? cf.get('option.nameInAppropriateLanguage') : newValue;

        if (dcf.isDirty) {
          // przypadek, kiedy użytkownik zmieni wartość w CF i przyjdzie aktualizacja z serwera dotycząca tego pola:
          // - przywracamy zmiany wprowadzone przez użytkownika
          // - ustawiamy komunikat o równoczesnej edycji
          _this.setCfMessageAndPrevValue(cf, 'formChanges.dirtyValueChanged', {
            yourValue: prevValueString,
            newValue: newValueString
          }, prevValueString, hasBeenUpdated);

          if (hasBeenUpdated) {
            cf.set('transport.sureToSaveMessage', true);
          }

          cf.set(propertyName, dcf[propertyName]);
        } else {
          // przypadek, kiedy przyszła aktualizacja pola z serwera - ustawiamy komunikat z informacją
          _this.setCfMessageAndPrevValue(cf, 'formChanges.valueChanged', {
            oldValue: prevValueString,
            newValue: newValueString
          }, prevValueString, hasBeenUpdated);
        }
      });
      newCustomFields.forEach(function (ncf) {
        // nie mieliśmy tego CF, ale przyszedł update z serwera i ktoś go uzupełnił
        var newValue = ncf.get('definition.isSelectType') ? ncf.get('option.nameInAppropriateLanguage') : ncf.get('value');

        _this.setCfMessageAndPrevValue(ncf, 'formChanges.newValue', {
          value: newValue
        }, null, true);
      });
    },
    setCfMessageAndPrevValue: function setCfMessageAndPrevValue(cf, msgCode, msgArgs, prevValueString, hasBeenUpdated) {
      if (!cf || !hasBeenUpdated) {
        return;
      }

      var message = this.get('intl').t(msgCode, msgArgs);
      cf.set('prevValue', prevValueString);
      cf.set('changeMessage', message);
    },

    /**
     * Metoda, która usuwa stare obiekty `step` po edycji
     * -> ember-data nie nadpisuje stepów po otrzymaniu odpowiedzi z serwera tylko dodaje nowe, więc stare usuwamy ręcznie
     */
    handleStepsUpdate: function handleStepsUpdate(transport) {
      var stepsCopy = transport.get('steps').slice(0);
      stepsCopy.forEach(function (s) {
        s.set('didWarehouseChange', false);

        if (s !== undefined && s.get('address') !== undefined && s.get('address.content')) {
          if (!s.get('address.id') && s.get('address').unloadRecord) {
            console.log("Unloading redundant advice for newly saved transport ".concat(transport.get('id'), ".."));
            s.get('address').unloadRecord();
          }
        }

        if (s !== undefined && !s.get('id') && s.unloadRecord) {
          console.debug("Unloading redundant step for newly saved transport ".concat(transport.get('id'), ".."));
          transport.get('steps').removeObject(s);
          s.unloadRecord();
        }

        s.get('stepWarehouseLocations').forEach(function (swh) {
          if (swh !== undefined && !swh.get('id') && !swh.get('isFilled') && swh.unloadRecord) {
            swh.unloadRecord();
          }
        });
      });
    },
    removeUnsavedTimeWindows: function removeUnsavedTimeWindows(transport) {
      if (!transport.get('timeWindows.length')) {
        return;
      }

      var timeWindowsCopy = transport.get('timeWindows').slice(0);
      timeWindowsCopy.forEach(function (tw) {
        if (tw !== undefined && !tw.get('id') && tw.unloadRecord) {
          console.debug("Unloading redundant timeWindow for transport ".concat(transport.get('id'), ".."));
          tw.get('step').set('timeWindow', null);
          transport.get('timeWindows').removeObject(tw);
          tw.unloadRecord();
        }
      });
    }
  });

  _exports.default = _default;
});