define("apollo/services/time-window-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajaxService: Ember.inject.service('ajax-service'),
    getFirstPossibleWindowStart: function getFirstPossibleWindowStart(step, transport, selectedDate) {
      var warehouseId = step.get('warehouse.id');
      var self = this;
      return new Promise(function (resolve) {
        var data = {
          transportCommand: transport.serialize()
        };
        data.warehouse = {
          id: warehouseId
        };

        if (selectedDate) {
          data.selectedDate = selectedDate;
        }

        if (step.get('minAdviceDate')) {
          data.minDate = moment(step.get('minAdviceDate')).format();
          data.maxDate = moment(step.get('maxAdviceDate')).format();
        }

        self.get('ajaxService').send('/transports/firstPossibleStart', 'POST', data, true).then(function (response) {
          resolve(response);
        });
      });
    }
  });

  _exports.default = _default;
});