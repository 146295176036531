define("apollo/pods/role-groups/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: service('session-account'),
    access: ['ROLE_CREATE_ROLE_GROUP'],
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model() {
      var principalCompanyId = localStorage.getItem('principalId');
      var principalCompany = this.store.peekRecord('company', principalCompanyId);
      var isSuperAdmin = this.get('sessionAccount').get('currentUser.roleGroup.name') === 'SUPER_USER';
      var roleGroup = this.store.createRecord('roleGroup', {
        principalCompany: principalCompany
      });
      var roleGroupsToCopyFrom = this.store.peekAll('roleGroup').filterBy('principalCompany.id', principalCompanyId).filter(function (roleGroup) {
        return roleGroup.get('name') !== 'E-MAIL_ADVICE';
      });

      if (!isSuperAdmin) {
        roleGroupsToCopyFrom = roleGroupsToCopyFrom.filter(function (roleGroup) {
          return roleGroup.get('name') !== 'SUPER_USER';
        });
      }

      var partnershipTypes = this.store.peekAll('partnershipType');
      return Ember.RSVP.hash({
        roleGroup: roleGroup,
        roleGroupsToCopyFrom: roleGroupsToCopyFrom,
        partnershipTypes: partnershipTypes
      });
    },
    actions: {
      willTransition: function willTransition() {
        var controllerRoleGroupModel = this.controller.get('model.roleGroup');
        this.controllerFor('role-groups.create').setProperties({
          errors: []
        });

        if (controllerRoleGroupModel) {
          controllerRoleGroupModel.unloadRecord();
        }

        console.log('Użytkownik cofnął stronę, anulujemy process tworzenia nowej roli...');
        return true;
      }
    }
  });

  _exports.default = _default;
});