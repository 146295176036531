define("apollo/pods/companies/modals/add-worker/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Controller.extend({
    store: service(),
    sessionAccount: service('session-account'),
    worker: Ember.computed('model', function () {
      return this.get('model.worker') ? this.get('model.worker') : this.get('model');
    }),
    transport: Ember.computed('model', function () {
      return this.get('model.transport');
    }),
    workerLabel: function workerLabel(action) {
      var translationKey = this.transport ? this.get('transport').get('transportType.workerFieldTranslationKey') : null;

      if (action === 'add') {
        if (translationKey === 'GUESTS') return 'addGuest';
        if (translationKey === 'SERVICEMEN') return 'addServiceman';
        return 'addWorker';
      } else if (action === 'edit') {
        if (translationKey === 'GUESTS') return 'editGuest';
        if (translationKey === 'SERVICEMEN') return 'editServiceman';
        return 'editWorker';
      }
    },
    addWorkerLabel: Ember.computed('model', function () {
      return this.workerLabel('add');
    }),
    editWorkerLabel: Ember.computed('model', function () {
      return this.workerLabel('edit');
    }),
    actions: {
      saveOrUpdate: function saveOrUpdate() {
        var worker = this.get('worker');
        var transport = this.get('transport');
        var self = this;

        if (!worker.validate()) {
          return;
        }

        if (worker.get('phoneNumberPrefix') === null) {
          worker.set('phoneNumberPrefix', '48');
        }

        worker.save().then(function () {
          transport ? self.send('hideModal', 'companies.modals.add-worker') : self.send('hideModal');
          transport ? worker.reload() : self.send('refreshModel');

          if (transport) {
            transport.get('workers').pushObject(worker);
          }

          self.setProperties({
            errors: [],
            model: null
          });

          if (transport) {
            self.send('showModal', 'transports.modals.create-transport', transport);
          }
        });
      },
      cancel: function cancel() {
        console.debug('Zamykamy okienko edycji pracownika...');
        var transport = this.get('transport');

        if (!this.get('worker.id')) {
          this.get('worker').unloadRecord();
        } else if (this.get('worker')) {
          this.get('worker').rollbackAttributes();
        }

        this.setProperties({
          errors: [],
          model: null
        });
        this.send('hideModal', 'companies.modals.add-worker');

        if (transport) {
          this.send('showModal', 'transports.modals.create-transport', transport);
        }
      },
      setPhonePrefix: function setPhonePrefix(phonePrefix) {
        if (this.get('transport')) {
          this.set('model.worker.phoneNumberPrefix', phonePrefix);
        } else this.set('model.phoneNumberPrefix', phonePrefix);
      }
    }
  });

  _exports.default = _default;
});