define("apollo/pods/notifications/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    access: ['ROLE_MANAGE_NOTIFICATIONS'],
    sessionAccount: Ember.inject.service('session-account'),
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model() {
      var principalCompanyId = localStorage.getItem('principalId');
      var principalCompany = this.store.peekRecord('company', principalCompanyId);
      var notification = this.store.createRecord('notification', {
        active: true,
        principalCompany: principalCompany
      });
      return Ember.RSVP.hash({
        notification: notification
      });
    }
  });

  _exports.default = _default;
});