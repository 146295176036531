define("apollo/pods/super-admin/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4QjMPy1w",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"errorMessage\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"alert alert-danger\"],[9],[0,\"\\n            \"],[1,[21,\"errorMessage\"],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-group pull-right\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-md-12\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"super-admin.create\"],[[\"class\"],[\"btn btn-default\"]],{\"statements\":[[0,\"                \"],[1,[27,\"fa-icon\",[\"plus\"],null],false],[0,\" \"],[1,[27,\"t\",[\"administration.newPrincipal.create\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"form\"],[11,\"class\",\"form-inline\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n            \"],[7,\"label\"],[11,\"for\",\"username-field\"],[9],[0,\"\\n                \"],[1,[27,\"t\",[\"auth.impersonate\"],null],false],[0,\"\\n            \"],[10],[0,\":\\n\\n            \"],[1,[27,\"input\",null,[[\"id\",\"value\",\"placeholder\",\"class\"],[\"username-field\",[23,[\"username\"]],\"swisskrono@demo.pl\",\"form-control\"]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"username\"]]],null,{\"statements\":[[0,\"                \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[11,\"title\",\"impersonate\"],[11,\"type\",\"submit\"],[9],[0,\"\\n                    \"],[1,[27,\"t\",[\"auth.login\"],null],false],[0,\"\\n                \"],[3,\"action\",[[22,0,[]],\"impersonate\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/super-admin/index/template.hbs"
    }
  });

  _exports.default = _default;
});