define("apollo/mixins/step-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    findPackageTypeTransportTypes: function findPackageTypeTransportTypes(transportTypeId) {
      return this.get('store').peekAll('packageTypeTransportType').filter(function (i) {
        return i.get('transportType.id') === transportTypeId;
      });
    },
    handleStepPackageTypesForTransport: function handleStepPackageTypesForTransport(transport) {
      this.handleStepPackageTypes(transport.get('steps'), transport.get('transportType.id'));
    },
    handleStepPackageTypesForStep: function handleStepPackageTypesForStep(step) {
      this.handleStepPackageTypes([step], step.get('transport.transportType.id'));
    },
    handleStepPackageTypes: function handleStepPackageTypes(steps, transportTypeId) {
      var _this = this;

      var packageTypeTransportTypes = this.findPackageTypeTransportTypes(transportTypeId);
      var contractorSteps = steps.filter(function (s) {
        return !s.get('ownedByPrincipal');
      });
      var warehouseSteps = steps.filter(function (s) {
        return s.get('ownedByPrincipal');
      });
      packageTypeTransportTypes.forEach(function (pttt) {
        if (pttt.get('visibleInContractorStep')) {
          contractorSteps.forEach(function (s) {
            return _this.createStepPackageType(s, pttt);
          });
        }

        if (pttt.get('visibleInWarehouseStep')) {
          warehouseSteps.forEach(function (s) {
            return _this.createStepPackageType(s, pttt);
          });
        }
      });
    },
    createStepPackageType: function createStepPackageType(step, packageTypeTransportType) {
      var spt = this.get('store').createRecord('stepPackageType', {
        step: step,
        packageType: packageTypeTransportType.get('packageType'),
        multiplier: packageTypeTransportType.get('multiplier')
      });
      step.get('stepPackageTypes').pushObject(spt);
    }
  });

  _exports.default = _default;
});