define("apollo/pods/users/edit/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_EDIT_USERS_MAIN_DATA'],
    model: function model(params, transition) {
      var currentUser = this.get('sessionAccount').get('currentUser');
      var hasAnyOfTheRoles = this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'));
      var userRoleGroup = this.get('sessionAccount').get('currentUser.roleGroup');
      var roleGroupInvRestriction = this.store.query('role-group-invitation-restriction', {
        roleGroup: userRoleGroup.get('id')
      });
      var self = this;

      if (params.user_id === currentUser.get('id') || hasAnyOfTheRoles) {
        return new Promise(function (resolve) {
          self.store.findRecord('user', params.user_id).then(function (response) {
            resolve(Ember.RSVP.hash({
              user: response,
              roleGroupInvRestriction: roleGroupInvRestriction
            }));
          }, function () {
            return self.transitionTo('forbidden');
          });
        });
      } else {
        transition.abort();
        this.transitionTo('users.ramps', params.user_id);
      }
    },
    afterModel: function afterModel(model) {
      var _this = this;

      model.user.get('company').then(function (c) {
        if (c.get('isAuxiliaryPartner')) {
          console.log("Company ".concat(c.get('shortName'), " is a partner of one of contractors. Edition is disabled -> redirecting to user's details view."));

          _this.transitionTo('users.show', model.get('user.id'));
        }
      });
    },
    renderTemplate: function renderTemplate() {
      this.render('users.create');
    },
    setupController: function setupController(controller, model) {
      this.controllerFor('users.create').setProperties({
        model: model
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.controllerFor('users.create').setProperties({
          'successMessage': null,
          'model.user.changePassword': false,
          'model.user.password': '',
          'model.user.newPassword': '',
          'model.user.repeatedNewPassword': ''
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});