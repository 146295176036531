define("apollo/pods/weights/modals/overweight/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3o8RFBJx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"custom-modal\",null,[[\"title\",\"showCancelButton\",\"cancel\",\"classNames\"],[[23,[\"title\"]],true,[27,\"action\",[[22,0,[]],\"cancel\",[23,[\"checkpoint\"]]],null],[23,[\"classes\"]]]],{\"statements\":[[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"form-horizontal row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"form-group form-group-padding\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"col-xs-12 padding-bottom\"],[9],[0,\"\\n                \"],[7,\"div\"],[9],[1,[27,\"t\",[\"transport.weightSection.overweightMessage\"],null],false],[10],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"modal-footer form-buttons padding-top-big\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-sm btn-primary btn-wide\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"small\"],[9],[0,\"\\n                \"],[1,[27,\"t\",[\"common.ok\"],null],false],[0,\":\\n                (\"],[1,[23,[\"checkpoint\",\"step\",\"warehouse\",\"name\"]],false],[0,\")\\n            \"],[10],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"close\",[23,[\"checkpoint\"]]]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/weights/modals/overweight/template.hbs"
    }
  });

  _exports.default = _default;
});