define("apollo/pods/components/partnership-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    CARRIER: "CARRIER",
    SUPPLIER: "SUPPLIER",
    OPERATOR: "OPERATOR",
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax-service'),
    sessionAccount: Ember.inject.service('session-account'),
    newContractorType: Ember.computed('company.partnershipType.name', function () {
      var companyPartnershipType = this.get('company.partnershipType.name');
      return companyPartnershipType === "CARRIER" ? "SUPPLIER" : "CARRIER";
    }),
    filteredPartnerships: Ember.computed('partnerships.[]', function () {
      var _this = this;

      return this.get('partnerships').filter(function (p) {
        return p.get('principalCompany.id') === _this.get('company.id') || !p.get('principalCompany.isPrincipal');
      });
    }),
    findCurrentCompanyPartnershipSide: function findCurrentCompanyPartnershipSide() {
      var newContractorType = this.get("newContractorType");
      return newContractorType === "CARRIER" ? 'principalCompany' : 'contractorCompany';
    },
    actions: {
      openAddPartnershipModal: function openAddPartnershipModal() {
        var partnershipType = this.get('store').peekAll('partnershipType').find(function (pt) {
          return pt.get('name') === "CARRIER";
        });
        var currentCompanyPartnershipSide = this.findCurrentCompanyPartnershipSide();
        var newContractorType = this.get('newContractorType');
        var partnership = this.get('store').createRecord('partnership', {
          status: "NO_INVITATION",
          type: "DEFAULT"
        });
        var company = this.get('company');
        partnership.set('partnershipType', partnershipType);
        partnership.set(currentCompanyPartnershipSide, company);
        this.showModal('companies.modals.add-partnership', {
          partnership: partnership,
          company: company,
          newContractorType: newContractorType
        });
      },
      connectAllContractors: function connectAllContractors() {
        var _this2 = this;

        var company = this.get('company');
        var principalId = localStorage.getItem('principalId');
        var principalCompany = this.get('store').peekRecord('company', principalId);
        var contractorsType = company.get('partnershipType.name') === "CARRIER" ? "SUPPLIER" : "CARRIER";
        var settingKey = this.get('sessionAccount').getSettingValue("SUPPLIER_FIELD_IN_COMPANY_DETAILS_TRANSLATION_KEY");
        var contractorsTypeKey = settingKey ? "".concat(contractorsType, ".").concat(settingKey) : contractorsType;
        var fallbackKey = "partnership.connectAll.".concat(contractorsType);
        var key = "partnership.connectAll.".concat(contractorsTypeKey);
        var msg = this.get('intl').t('partnership.connectAll.cofirmMsg', {
          companyName: company.get('name'),
          contractorsType: this.get('intl').t(key, {
            default: fallbackKey
          }),
          principalCompanyName: principalCompany.get('name')
        });

        if (!confirm(msg)) {
          return;
        }

        var data = {
          principalCompany: {
            id: principalId
          },
          company: {
            id: company.get('id')
          },
          contractorsType: contractorsType
        };
        this.get('ajaxService').send("/partnerships/connectAll", "POST", data, true).then(function () {
          alert(_this2.get('intl').t("partnership.connectAll.success"));
          window.location.reload();
        }).catch(function (response) {
          alert(response.responseJSON["errors"]);
        });
      }
    }
  });

  _exports.default = _default;
});