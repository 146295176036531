define("apollo/pods/main-section/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      partnership: {
        embedded: 'save'
      }
    },
    serializeBelongsTo: function serializeBelongsTo(record, json, relationship) {
      var embeddedRecord, key;
      key = relationship.key;
      embeddedRecord = record.belongsTo(key);

      if (embeddedRecord && this.attrs[key] && this.attrs[key].embedded === "save") {
        var data = embeddedRecord.attributes();
        data.id = embeddedRecord.id;

        if (relationship.options.deepEmbedded) {
          relationship.options.deepEmbedded.forEach(function (deepKey) {
            if (embeddedRecord.belongsTo(deepKey)) {
              data[deepKey] = embeddedRecord.belongsTo(deepKey).serialize();
            }
          });
        }

        json[key] = data;
      } else {
        this._super(record, json, relationship);
      }
    }
  });

  _exports.default = _default;
});