define("apollo/pods/settings/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajaxService: Ember.inject.service('ajax-service'),
    intl: Ember.inject.service(),
    settingsIndexController: Ember.inject.controller('settings/index'),
    success: false,
    errors: [],
    setting: '',
    settings: Ember.computed('', function () {
      var _this = this;

      var settings = [];
      var settingsNames = this.get('model.settingsOptions');
      settingsNames.forEach(function (name) {
        settings.push({
          name: name,
          translation: _this.get('intl').t("settings.desc.".concat(name))
        });
      });
      return settings;
    }),
    actions: {
      save: function save() {
        var _this2 = this;

        var setting = this.get('model.setting');

        if (!setting.validate()) {
          return;
        }

        setting.save().then(function () {
          _this2.set('success', true);

          _this2.get('settingsIndexController').set('successMessage', _this2.get('intl').t('common.saveSuccessMessage'));

          _this2.transitionToRoute('settings.index');
        }).catch(function (response) {
          return _this2.set('errors', response.errors);
        });
      },
      cancel: function cancel() {
        this.get('model.setting').rollbackAttributes();
        this.transitionToRoute('settings.index');
      },
      setSettingName: function setSettingName(setting) {
        this.set('setting', setting);
        this.set('model.setting.name', setting.name);
      }
    }
  });

  _exports.default = _default;
});