define("apollo/pods/gates/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    gate: Ember.computed('model', function () {
      return this.get('model');
    }),
    actions: {
      save: function save() {
        var _this = this;

        var self = this;
        var gate = this.get('gate');

        if (!gate.validate()) {
          return;
        }

        gate.save().then(function () {
          self.setProperties({
            errors: []
          });

          _this.transitionToRoute('settings.index');
        }).catch(function (response) {
          console.log("B\u0142\u0105d przy zapisywaniu bramy: ".concat(response.errors));
          self.set('errors', response.errors);
        });
      },
      delete: function _delete() {
        var _this2 = this;

        var self = this;
        self.get('gate').destroyRecord().then(function () {
          self.setProperties({
            errors: []
          });

          _this2.transitionToRoute('settings.index');
        }).catch(function (response) {
          console.log("B\u0142\u0105d przy usuwaniu bramy: ".concat(response.errors));
          self.set('errors', response.errors);
        });
      },
      cancel: function cancel() {
        this.get('gate').unloadRecord();
        this.transitionToRoute('settings.index');
      }
    }
  });

  _exports.default = _default;
});