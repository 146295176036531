define("apollo/mixins/advice-utils-mixin", ["exports", "apollo/mixins/persistence-mixin"], function (_exports, _persistenceMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_persistenceMixin.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    setRodoValue: function setRodoValue(advice) {
      console.debug("Advice has changes in properties: ".concat(advice.get('isDirty'), "."));
      console.debug("Advice has changes in relationships: ".concat(advice.get('hasDirtyRelations'), ".")); // log naprawia A-1753

      if (advice.get('content')) {
        console.debug(advice.get('content').modelChanges());
      }

      if (advice.get('rodoAcceptanceChangedManually') || !advice.get('content')) {
        return;
      }

      if (!advice.get('id')) {
        advice.set('rodoAccepted', false);
        return;
      }

      var adviceModel = this.get('store').peekRecord('advice', advice.get('id'));

      if (!adviceModel) {
        return;
      }

      var didDriverLanguageChanged = adviceModel.get('didDriverLanguageChanged');
      var changes = adviceModel.modelChanges();
      var hasChanges = Object.keys(changes).filter(function (k) {
        return !['rodoAccepted', 'dateCreated', 'creator', 'driverLanguage'].includes(k);
      }).length > 0;
      advice.set('rodoAccepted', !(hasChanges || didDriverLanguageChanged));
    },
    removeWhiteSpacesAndSetUpperCase: function removeWhiteSpacesAndSetUpperCase(e) {
      var driverMobilePhoneNumberLength = e.get('driverMobilePhoneNumber.length');
      var carRegistrationNumberLength = e.get('carRegistrationNumber.length');
      var trailerRegistrationNumberLength = e.get('trailerRegistrationNumber.length');
      var documentNumberLength = e.get('documentNumber.length');

      if (driverMobilePhoneNumberLength > 0) {
        e.set('driverMobilePhoneNumber', e.get('driverMobilePhoneNumber').replace(/\s+/g, ''));
      }

      if (carRegistrationNumberLength > 0) {
        e.set('carRegistrationNumber', e.get('carRegistrationNumber').toUpperCase().replace(/\s+/g, ''));
      }

      if (trailerRegistrationNumberLength > 0) {
        e.set('trailerRegistrationNumber', e.get('trailerRegistrationNumber').toUpperCase().replace(/\s+/g, ''));
      }

      if (documentNumberLength > 0) {
        e.set('documentNumber', e.get('documentNumber').replace(/\s+/g, ''));
      }

      return e;
    },
    updateAllFuturePeriodicTransports: function updateAllFuturePeriodicTransports(transport) {
      return transport.get('id') && transport.get('periodicTransport.id') && this.get('sessionAccount').hasRole('ROLE_EDIT_MULTIPLE_PERIODIC_TRANSPORTS') && confirm(this.get('intl').t('periodicTransport.updateAllConfirmMsg'));
    },
    unloadRedundantTimeWindows: function unloadRedundantTimeWindows(transport) {
      var timeWindowsCopy = transport.get('timeWindows').slice(0);
      timeWindowsCopy.forEach(function (tw) {
        if (tw && !tw.get('id')) {
          console.debug("Unloading redundant time window for newly saved advice of transport ".concat(transport.get('id'), ".."));
          transport.get('timeWindows').removeObject(tw);
        }
      });
    },
    handleTransportReload: function handleTransportReload(transport, scheduleController) {
      this.unloadDeletedRecords('transport', transport.get('id'), 'checkpoint');
      this.handleStepsUpdate(transport);
      transport.get('timeWindows').forEach(function (tw) {
        if (!tw) {
          return;
        }

        scheduleController.pushTimeWindow(tw.get('id'));
      });
    }
  });

  _exports.default = _default;
});