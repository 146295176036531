define("apollo/pods/warehouse-locations/modals/form/controller", ["exports", "apollo/mixins/transports-mixin", "apollo/mixins/advice-utils-mixin"], function (_exports, _transportsMixin, _adviceUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_transportsMixin.default, _adviceUtilsMixin.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    ajaxService: Ember.inject.service('ajax-service'),
    errors: Ember.A(),
    selectedWarehouseLocations: Ember.A([]),
    checkpoint: Ember.computed('model.id', function () {
      return this.get('model');
    }),
    step: Ember.computed('checkpoint.step', function () {
      return this.get('checkpoint.step.content');
    }),
    transport: Ember.computed('checkpoint.transport.id', function () {
      var transportId = this.get('checkpoint.transport.id');
      return this.get('store').peekRecord('transport', transportId);
    }),
    title: Ember.computed('checkpoint.id', function () {
      return "".concat(this.get('intl').t('checkpoints.confirm'), ": ").concat(this.get('checkpoint.name'));
    }),
    isDuringConfirmation: Ember.computed('checkpoint.actionType', function () {
      return this.get('checkpoint.actionType') === this.get('checkpoint.ACTIONS').CONFIRM;
    }),
    firstUnconfirmedCheckpoint: Ember.computed('transport.firstUnconfirmedCheckpoint', function () {
      return this.get('transport.firstUnconfirmedCheckpoint');
    }),
    warehouseLocationDisabled: Ember.computed('transport.transportType.authorityRestrictions.@each.authorityRange', function () {
      return !this.get('transport').actionCanBePerformed('ROLE_EDIT_WAREHOUSE_LOCATION');
    }),
    actions: {
      confirmCheckpoint: function confirmCheckpoint(checkpoint) {
        var _this = this;

        var self = this;
        var step = this.get('step');
        var locations = this.get('selectedWarehouseLocations.length');

        if (locations < 1) {
          var msg = this.get('intl').t('errors.blankSelect');
          this.set('errors', [msg]);
          return console.log("WarehouseLocation :: ".concat(msg));
        }

        var newSWLs = {
          step: step.get('id'),
          warehouseLocations: this.get('selectedWarehouseLocations').map(function (location) {
            return location.wlId;
          })
        };
        this.get('ajaxService').send('/steps/saveMultipleStepWarehouseLocations', 'POST', newSWLs, true).then(function () {
          _this.set('errors', []);

          checkpoint.set('isDuringAction', true);

          _this.send('confirm', checkpoint);

          _this.reset(checkpoint);
        }).catch(function () {
          console.error('Błąd przy dodawaniu lokalizacji !');
          self.reset(checkpoint);
        });
      },
      cancel: function cancel(checkpoint) {
        var locations = checkpoint.get('step.stepWarehouseLocations.length') ? checkpoint.get('step.stepWarehouseLocations.length') : [];

        if (locations > 0) {
          this.set('checkpoint.step.stepWarehouseLocations', Ember.A([]));
        }

        this.reset(checkpoint);
      }
    },
    reset: function reset(checkpoint) {
      checkpoint.set('isDuringAction', false);
      this.set('selectedWarehouseLocations', Ember.A([]));
      this.send('hideModal');
    }
  });

  _exports.default = _default;
});