define("apollo/pods/components/transport/add-indexes-section/component", ["exports", "apollo/utils/parsers/numberParsers"], function (_exports, _numberParsers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sessionAccount: service('session-account'),
    overwritePreviousIndexes: Ember.computed('', function () {
      return false;
    }),
    disableIndexActionName: Ember.computed('', function () {
      return 'disableIndex';
    }),
    invalidIndexes: Ember.computed('transport.indexes.@each.hasErrors', function () {
      return this.get('transport.indexes').filterBy('hasErrors');
    }),
    defaultMapping: Ember.computed('', function () {
      return {
        properties: {
          globalNumber: 0,
          localNumber: 1,
          name: 2,
          documentNumber: 6,
          transportNumber: 7,
          notes: 10
        },
        quantities: {
          'Quantity': 3,
          'Number of pallets': 8,
          'Number of cartons': 9
        }
      };
    }).readOnly(),
    actions: {
      handlePastedContent: function handlePastedContent(pastedContent) {
        var self = this;

        if (!pastedContent || !pastedContent.trim()) {
          return;
        }

        this.get('store').queryRecord('companyConfig', {
          name: 'INDEXES_XLS_MAPPING'
        }).then(function (config) {
          var customMappingString = config.get('value');
          var mapping = customMappingString ? JSON.parse(customMappingString) : self.get('defaultMapping');
          self.send('importIndexes', pastedContent, mapping);
        });
      },

      /** @namespace mapping.properties */

      /** @namespace mapping.quantities */
      importIndexes: function importIndexes(pastedContent, mapping) {
        var _this = this;

        var self = this;

        if (!pastedContent || !pastedContent.trim()) {
          return;
        }

        if (this.get('overwritePreviousIndexes')) {
          this.get('transport.enabledIndexes').forEach(function (index) {
            self.sendAction('disableIndexActionName', index);
          });
        }

        var textMatrix = pastedContent.split(/\r?\n/).map(function (t) {
          return t.split('\t');
        });
        console.table(textMatrix);
        var propertyNames = ['globalNumber', 'localNumber', 'name', 'documentNumber', 'transportNumber', 'notes'];
        var propertyMapping = mapping.properties;
        textMatrix.forEach(function (row) {
          if (!row.filter(function (col) {
            return col.trim();
          }).get('length')) {
            console.debug("The row is empty. Skipping.");
            return true;
          }

          var newIndex = _this.get('store').createRecord('index', {
            transport: _this.get('transport'),
            enabled: true
          });

          propertyNames.forEach(function (n) {
            return newIndex.set(n, row[propertyMapping[n]]);
          });

          _this.get('transport.transportType.indexQuantityTypes').forEach(function (qt) {
            var quantityIdx = mapping.quantities[qt.get('nameEn')];
            var q = row[quantityIdx];

            _this.get('store').createRecord('indexQuantity', {
              indexQuantityType: qt,
              index: newIndex,
              quantity: _numberParsers.default.getParsableNumber(q)
            });
          });

          newIndex.validate();
          newIndex.get('quantities').forEach(function (q) {
            return q.validate();
          });

          _this.get('transport.indexes').addObject(newIndex);
        });
      },
      addIndex: function addIndex() {
        var _this2 = this;

        var newIndex = this.get('store').createRecord('index', {
          transport: this.get('transport')
        });
        this.get('transport.transportType.indexQuantityTypes').forEach(function (qt) {
          _this2.get('store').createRecord('indexQuantity', {
            indexQuantityType: qt,
            index: newIndex
          });
        });

        if (this.get('transport.contractors.length') === 1) {
          newIndex.set('step', this.get('transport.contractorSteps.firstObject'));
        }

        this.get('transport.indexes').addObject(newIndex);
        this.set("action", "showSubModal");
        this.sendAction('action', 'indexes.modals.index-form', newIndex);
      }
    }
  });

  _exports.default = _default;
});