define("apollo/pods/max-pallet/modals/create/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Controller.extend({
    store: service(),
    intl: service(),
    title: Ember.computed(function () {
      return this.get('intl').t('max-pallet.title');
    }),
    longNameDay: Ember.computed('model.longNameDay', function () {
      return this.get('model.longNameDay');
    }),
    maxPallet: Ember.computed('model.maxPallet', function () {
      return this.get('model.maxPallet');
    }),
    isTemplate: Ember.computed('model.maxPallet.isTemplate', function () {
      return this.get('model.maxPallet.isTemplate');
    }),
    actions: {
      save: function save() {
        var self = this;
        (0, _jquery.default)("#main-modal").data("skip-cancel-action", true);

        if (!this.get('maxPallet').validate()) {
          return;
        }

        if (this.get('isTemplate')) {
          this.send('hideModal');
          return;
        }

        this.get('maxPallet').save().then(function () {
          self.send('hideModal');
        });
      },
      cancel: function cancel() {
        console.debug("Zamykamy okienko ustawiania maksymalnej liczby palet...");

        if (this.get('maxPallet.id')) {
          this.get('maxPallet').rollback();
        } else if (this.get('isTemplate')) {
          var oldQuantity = this.get('maxPallet.oldQuantity');
          this.set('maxPallet.quantity', oldQuantity);
        } else {
          this.get('maxPallet').setProperties({
            isTemplate: false,
            quantity: 0
          });
        }
      }
    }
  });

  _exports.default = _default;
});