define("apollo/pods/components/transaction/create-transaction-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax-service'),
    TIME_TO_ACCEPT_TRANSPORT_INTERVAL: '3h',
    timeToAcceptTransport: Ember.computed('', function () {
      var setting = this.get('sessionAccount').getSettingValue('TIME_TO_ACCEPT_TRANSPORT_INTERVAL');
      return setting ? setting : this.TIME_TO_ACCEPT_TRANSPORT_INTERVAL;
    }),
    actions: {
      createTransactionAndOpenChooseCarrierModal: function createTransactionAndOpenChooseCarrierModal() {
        console.log("Przygotowujemy i pokazujemy okienko wyboru przewo\u017Anika...");
        var self = this;
        var transportType = this.get('transport.transportType');
        var transactionSetting = this.get('sessionAccount').getTransactionSetting(transportType);
        var currency;

        if (this.get('offer.auction')) {
          currency = this.get('offer.auction.currency');
        } else {
          var symbol = transactionSetting ? transactionSetting.get('defaultCurrency') : "PLN";
          currency = this.get('store').peekAll('currency').filterBy('symbol', symbol).get('firstObject');
        }

        var principalCompanyId = localStorage.getItem('principalId');
        var timeToAcceptTransport = this.get('timeToAcceptTransport');
        var intervalValue = timeToAcceptTransport.substring(0, timeToAcceptTransport.length - 1);
        var intervalUnit = timeToAcceptTransport.substring(timeToAcceptTransport.length - 1);
        var expectedConfirmationDate = moment(new Date()).add(intervalValue, intervalUnit).toDate();
        var expectedConfirmationTime = moment(expectedConfirmationDate).format('HH:mm');
        var carrierMustAcceptTransport = transactionSetting ? transactionSetting.get('requireCarrierConfirmation') : true;
        var transaction = this.get('store').createRecord('transaction', {
          transport: this.get('transport'),
          carrier: this.get('offer.company'),
          price: this.get('offer.price'),
          currency: currency,
          expectedConfirmationDate: expectedConfirmationDate,
          carrierMustAcceptTransport: carrierMustAcceptTransport,
          notTheBestOffer: this.get('notTheBestOffer')
        });
        this.set('action', 'showModal');
        self.get('store').query('company', {
          principalCompanyId: principalCompanyId,
          transportId: self.get('transport.id'),
          partnershipTypesNames: ['CARRIER', 'OPERATOR'],
          statuses: ['NO_INVITATION', 'ACCEPTED', 'UPDATED', 'BLOCKED']
        }).then(function (carriers) {
          var model = {
            transaction: transaction,
            expectedConfirmationTime: expectedConfirmationTime,
            expectedConfirmationDate: expectedConfirmationDate,
            carriers: carriers,
            disabledEdition: self.get('disabledEdition')
          };
          self.sendAction('action', 'transaction.modals.choose-carrier', model);
        });
      }
    }
  });

  _exports.default = _default;
});