define("apollo/helpers/warehouse-net-weight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params, nameArgs) {
      var warehouse = nameArgs.warehouse;
      var weightsArgs = nameArgs.weights;

      if (!warehouse || !weightsArgs) {
        return 'NaN';
      }

      var weights = weightsArgs.filter(function (w) {
        return w.get('warehouse.id') === warehouse.get('id');
      });

      if (weights.length < 2) {
        return 'NaN';
      } else {
        var firstWeight = weights.shift();
        return Math.abs(firstWeight.get('readOut') - weights.getEach('readOut').reduce(function (a, b) {
          return a + b;
        }, 0));
      }
    }
  });

  _exports.default = _default;
});