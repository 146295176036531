define("apollo/initializers/site-24-initializer", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    var integrationEnabled = _environment.default.APP.integrations.site24;
    var key = _environment.default.APP.integrations.site24Key;

    if (!integrationEnabled) {
      return console.log('Integracja z RUM Site24 jest wyłączona.');
    }

    console.log('Inicjujemy RUM Site24...'); // Skrypt pochodzi od Site24 -> https://www.site24x7.com/help/apm/rum/add-rum-monitor.html

    (function (w, d, s, r, k, h, m) {
      if (w.performance && w.performance.timing && w.performance.navigation) {
        w[r] = w[r] || function () {
          (w[r].q = w[r].q || []).push(arguments);
        };

        h = d.createElement('script');
        h.async = true;
        h.setAttribute('src', s + k);
        d.getElementsByTagName('head')[0].appendChild(h);
        m = window.onerror, window.onerror = function (b, c, d, f, g) {
          m && m(b, c, d, f, g), g || (g = new Error(b)), (w[r].q = w[r].q || []).push(['captureException', g]);
        };
      }
    })(window, document, '//static.site24x7rum.com/beacon/site24x7rum-min.js?appKey=', 's247r', key);
  }

  var _default = {
    name: 'site24Initializer',
    initialize: initialize
  };
  _exports.default = _default;
});