define("apollo/pods/components/product-auto-complete/component", ["exports", "apollo/pods/components/custom-auto-complete/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    valueProperty: 'id',
    urlAddress: '/products',
    field: 'index',
    options: [],
    suggestions: function () {
      return this.get('options');
    }.property('options.@each'),
    optionsToMatch: function () {
      if (!this.get('options')) {
        return [];
      }

      var caseInsensitiveOptions = [];
      this.get('options').forEach(function (item) {
        var value = item.index;
        caseInsensitiveOptions.push(value.toLowerCase());
      });
      return caseInsensitiveOptions;
    }.property('options.@each'),
    actions: {
      selectItem: function selectItem(item) {
        this.set('selectedFromList', true);
        this.set('selectedValue', item.index);
        this.sendAction('selectItem', item);
      }
    }
  });

  _exports.default = _default;
});