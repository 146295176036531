define("apollo/pods/notifications/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    notificationsIndexController: Ember.inject.controller('notifications/index'),
    commonMessageInputType: ['TRANSPORT_ID', 'CAR_REG_NUM', 'TRAILER_REG_NUM', 'COMPANY_SHORT_NAME', 'RAMP_NUMBER', 'SQUARE_NAME', 'QUEUE_NAME', 'PLACE_NAME', 'LOCATION', 'GATE', 'TIME_WINDOW_DATES', 'RAMP_MESSAGE_PL', 'RAMP_MESSAGE_EN', 'ADDRESS_CITY'],
    adviceUpdatedMessageInputType: ['OLD_REGISTRATION_NUMBER', 'OLD_ADVICE_DATE', 'NEW_ADVICE_DATE'],
    messageInputType: Ember.computed.union('commonMessageInputType', 'adviceUpdatedMessageInputType'),
    notification: Ember.computed('model.notification', function () {
      return this.get('model.notification');
    }),
    notificationTypes: Ember.computed(function () {
      return this.get('sessionAccount.notificationTypes');
    }),
    transportTypes: Ember.computed(function () {
      return this.get('store').peekAll('transportType').sortBy('idx');
    }),
    sortTaskProperties: ['taskGroupIdentifier:asc', 'idxInGroup:asc'],
    sortedTasks: Ember.computed.sort('tasks', 'sortTaskProperties'),
    tasks: Ember.computed('notification.transportType', function () {
      var transportTypeId = this.get('notification.transportType.id');
      return this.get('store').peekAll('task').filter(function (t) {
        return t.get('workflow.transportType.id') === transportTypeId;
      });
    }),
    actions: {
      setNotification: function setNotification(notificationName) {
        var notificationType = this.get('notification').getNotificationType(notificationName);
        this.get('model.notification').set('name', notificationName);
        this.get('model.notification').set('type', notificationType);
      },
      save: function save() {
        var _this = this;

        this.set('errors', []);
        var notification = this.get('notification');

        if (!notification.validate()) {
          return;
        }

        notification.save().then(function () {
          _this.get('notificationsIndexController').set('successMessage', _this.get('intl').t('common.saveSuccessMessage'));

          _this.transitionToRoute('notifications.index');
        }).catch(function (response) {
          _this.set('errors', response.errors);
        });
      },
      delete: function _delete() {
        var _this2 = this;

        this.get('notification').destroyRecord().then(function () {
          _this2.get('notificationsIndexController').set('successMessage', _this2.get('intl').t('common.saveSuccessMessage'));

          _this2.transitionToRoute('notifications.index');
        }).catch(function (response) {
          _this2.set('errors', response.errors);
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('notifications.index');
      }
    }
  });

  _exports.default = _default;
});