define("apollo/services/fresh-widget", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    loadWidget: function loadWidget() {
      try {
        var integrationEnabled = _environment.default.APP.integrations.freshWidget;

        if (!integrationEnabled) {
          return console.log("Integracja z Fresh Widget jest wy\u0142\u0105czona.");
        }

        if (typeof FreshWidget === 'undefined') {
          return console.log("Nie mamy dost\u0119pu do wtyczki FreshWidget, przerywamy inicjacj\u0119.");
        }

        console.log("Inicjujemy wtyczk\u0119 Fresh Widget...");
        FreshWidget.init("", {
          "queryString": "&widgetType=popup&formTitle=Wsparcie+techniczne&submitTitle=Wy%C5%9Blij&submitThanks=Dzi%C4%99kujemy+za+zg%C5%82oszenie&captcha=yes&searchArea=no",
          "utf8": "✓",
          "widgetType": "popup",
          "buttonType": "text",
          "buttonText": "Support",
          "buttonColor": "white",
          "buttonBg": "#f57004",
          "alignment": "2",
          "offset": "80%",
          "submitThanks": "Dziękujemy za zgłoszenie",
          "formHeight": "550px",
          "captcha": "yes",
          "url": "https://logintegra.freshdesk.com",
          "loadOnEvent": "immediate"
        });
      } catch (e) {
        console.error(e);
      }
    }
  });

  _exports.default = _default;
});