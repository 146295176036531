define("apollo/pods/components/transports/transports-table/transport-filters/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    // Używane w hbs.
    columnNameCfWidthClass: Ember.computed('tableColumnNames', function () {
      return this.tableColumnNames.includes('CUSTOM_FIELDS') ? 'column-name-cf-width' : '';
    }),
    tagName: 'div',
    classNames: ['transport-filters', 'row', 'flex-position-start'],
    actions: {}
  });

  _exports.default = _default;
});