define("apollo/pods/components/inspection-modal/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    errors: Ember.A([]),
    transport: Ember.computed('model', function () {
      return this.get('model');
    }),
    actions: {
      cancel: function cancel() {
        this.get('transport.customFields').forEach(function (cf) {
          cf.rollbackAttributes();
        });
      },
      save: function save(transport) {
        var self = this;

        if (!transport.validate()) {
          console.log('Błędy walidacji');
          return;
        }

        transport.save().then(function (_) {
          self.send('hideModal');
          self.set('errors', []);
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      }
    }
  });

  _exports.default = _default;
});