define("apollo/pods/components/company-representative/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('model.representativePhoneNumberPrefix')) {
        this.set('model.representativePhoneNumberPrefix', '48');
      }
    },
    shouldShowRepresentativeFields: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('COMPANY_FORM_SHOW_REPRESENTATIVE') === 'true';
    }),
    actions: {
      setPhonePrefix: function setPhonePrefix(phonePrefix) {
        this.get('model').set('representativePhoneNumberPrefix', phonePrefix);
      }
    }
  });

  _exports.default = _default;
});