define("apollo/pods/components/transport/custom-fields-section/component", ["exports", "apollo/mixins/transport-form-mixin"], function (_exports, _transportFormMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Component.extend(_transportFormMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    transport: Ember.computed(function () {
      return null;
    }),
    adviceSection: Ember.computed(function () {
      return false;
    }),

    /**
     * Metoda wykonująca się po zainicjowaniu komponentu.
     *
     * W niektórych typach transportu jest kilka takich komponentów w formularzu (sekcja lewa i prawa, protokół rozbieżności).
     * Chcemy więc wykonać inicjację custom-fields tylko raz po otwarciu formularza -- stąd flaga `hasCustomFieldsInitialized`.
     *
     * Wykonanie w trybie `once` służy głównie temu, by wykonać operację po wyrenderowaniu komponentu.
     * Moglibyśmy pewnie użyć run.next(), ale run.once() daje nam pewność (?), że operacja wykona się tylko raz w danym wywołaniu komponentu
     * (a więc i tak wykona się kilka razy dla każdego formularza, ale za to w odpowiednim momencie).
     */
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var self = this;
      var transport = this.get('transport');
      Ember.run.once(function () {
        if (_this.get('transport.id')) {
          if (transport.get('hasCustomFieldsInitialized')) {
            console.debug("Lista custom fields zosta\u0142a ju\u017C zainicjowana dla transportu ".concat(transport.get('logString'), "; nie robimy tego ponownie."));
            return;
          }

          self.initializeCustomFields(transport);
        } else {
          transport.get('customFields').then(function () {
            return self.createMissingCustomFieldValues(transport);
          });
        }

        transport.set('hasCustomFieldsInitialized', true);
      });
    },
    shouldTakePairsFromSection: Ember.computed('adviceSection', 'discrepancyReportSection', 'inspectionPanelSection', 'weightSection', function () {
      // Sekcje to są argumenty podane w template komponentu, któ©y wywołuje ten komponent np:
      // {{transport/custom-fields-section transport=transport withCurrentTaskBlockade=true weightSection=true isRequiredInCaseOfDelay=true}}
      // wyświetli wszystkie potrzebne cf z `withCurrentTaskBlockade`, 'weightSection' i `isRequiredInCaseOfDelay`
      var sections = Ember.A([this.get('adviceSection'), this.get('discrepancyReportSection'), this.get('inspectionPanelSection'), this.get('weightSection'), this.get('withCurrentTaskBlockade')]);
      return sections.includes(true);
    }),
    shouldTakePairsFromCurrentTaskBlockade: Ember.computed('transport.firstUnconfirmedCheckpoint.customFieldsPairsWithCurrentTaskBlockade', function () {
      return this.get('withCurrentTaskBlockade');
    }),
    customFieldPairs: Ember.computed('transport.customFieldPairs.@each.definition', 'transport.customFieldPairs.@each.value', function () {
      var self = this; // Zbieramy wszystkie potrzebne cfy i wyświetlamy je w jednym modalu

      var cfPairs = Ember.A();

      if (this.get('transport.customFieldPairs') === undefined) {
        return;
      }

      var customFieldPairs = this.get('transport.customFieldPairs').filter(function (pair) {
        return !pair.value || self.shouldBeVisible(pair);
      });

      if (this.shouldTakePairsFromSection || this.shouldTakePairsFromWeightBlockade) {
        if (this.get('adviceSection')) {
          var cfPairsFromAdviceSection = customFieldPairs.filter(function (field) {
            return field.definition.get('visibleInAdviceSection') === true;
          });
          cfPairs = Ember.A([].concat(_toConsumableArray(cfPairs), _toConsumableArray(cfPairsFromAdviceSection)));
        }

        if (this.get('discrepancyReportSection')) {
          var cfPairsFromDiscrepanyReportSection = customFieldPairs.filter(function (field) {
            return field.definition.get('visibleInDiscrepancyReportSection') === true;
          });
          cfPairs = Ember.A([].concat(_toConsumableArray(cfPairs), _toConsumableArray(cfPairsFromDiscrepanyReportSection)));
        }

        if (this.get('inspectionPanelSection')) {
          var cfPairsFromInspectionPanelSection;
          var transportPanelActive = customFieldPairs.any(function (field) {
            return field.value && field.value.get('option.allowsInspectionPanel');
          });

          if (!transportPanelActive) {
            // Jeśli nie uzupełniono CF z powiązaniem z sekcją "Kontrola", wyświetlamy tylko CF z tym powiązaniem
            cfPairsFromInspectionPanelSection = customFieldPairs.filter(function (field) {
              return field.definition.get('visibleInInspectionPanelSection') === true && field.definition.get('options').any(function (option) {
                return option.allowsInspectionPanel;
              });
            });
            cfPairs = Ember.A([].concat(_toConsumableArray(cfPairs), _toConsumableArray(cfPairsFromInspectionPanelSection)));
          }

          cfPairsFromInspectionPanelSection = customFieldPairs.filter(function (field) {
            return field.definition.get('visibleInInspectionPanelSection') === true;
          });
          cfPairs = Ember.A([].concat(_toConsumableArray(cfPairs), _toConsumableArray(cfPairsFromInspectionPanelSection)));
        }

        if (this.get('weightSection')) {
          var cfPairsFromWeightSection = customFieldPairs.filter(function (field) {
            return field.definition.get('visibleInWeightSection') === true;
          });
          cfPairs = Ember.A([].concat(_toConsumableArray(cfPairs), _toConsumableArray(cfPairsFromWeightSection)));
        }

        if (this.get('withCurrentTaskBlockade')) {
          var firstUnconfirmedCheckpoint = this.get('transport.firstUnconfirmedCheckpoint');
          var cfPairsWithCurrentTaskBlockade = firstUnconfirmedCheckpoint.get('customFieldsPairsWithCurrentTaskBlockade');
          cfPairs = Ember.A([].concat(_toConsumableArray(cfPairs), _toConsumableArray(cfPairsWithCurrentTaskBlockade)));
        }

        if (this.get('isRequiredInCaseOfDelay')) {
          var _firstUnconfirmedCheckpoint = this.get('transport.firstUnconfirmedCheckpoint');

          var cfFromDelay = customFieldPairs.filter(function (field) {
            return field.definition.get('requiredInDelayedTransport') === true && _firstUnconfirmedCheckpoint.get('task.id') === field.definition.get('delayedTask.id') && _firstUnconfirmedCheckpoint.get('isCustomFieldRequiredInDelayedCheckpoint') === true;
          });
          cfPairs = Ember.A([].concat(_toConsumableArray(cfPairs), _toConsumableArray(cfFromDelay)));
        }
      } else {
        var visibleInTransport = customFieldPairs.filter(function (field) {
          return field.definition.get('visibleInTransportForm') === true;
        });
        cfPairs = Ember.A([].concat(_toConsumableArray(cfPairs), _toConsumableArray(visibleInTransport)));
      }

      return cfPairs;
    }),
    shouldBeVisible: function shouldBeVisible(pair) {
      return pair.value.get('isVisibleBasedOnDefinition') && pair.value.get('isVisibleBasedOnAuthorityRestrictions');
    },
    leftCustomFieldPairs: Ember.computed('customFieldPairs', function () {
      return this.get('customFieldPairs').filter(function (pair) {
        return pair.definition.get('side') !== 'RIGHT';
      });
    }),
    rightCustomFieldPairs: Ember.computed('customFieldPairs', function () {
      return this.get('customFieldPairs').filter(function (pair) {
        return pair.definition.get('side') === 'RIGHT';
      });
    }),
    customFieldSplitValue: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('CUSTOM_FIELD_SPLIT_VALUE');
    }),
    actions: {}
  });

  _exports.default = _default;
});