define("apollo/services/transport-relations/relations-tracker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    currentTransports: Ember.A([]),
    addRelation: function addRelation(transport) {
      this.currentTransports.pushObject(transport);
    },
    removeRelation: function removeRelation(transport) {
      this.currentTransports.removeObject(transport);
    },
    findExisting: function findExisting(transport) {
      return this.currentTransports.find(function (relation) {
        return relation.get('id') === transport.get('id');
      });
    }
  });

  _exports.default = _default;
});