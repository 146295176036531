define("apollo/pods/invitations/show/controller", ["exports", "apollo/mixins/invitations-mixin"], function (_exports, _invitationsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_invitationsMixin.default, {
    invitationWarehousesRampMap: Ember.computed('model.invitationRamps.@each', function () {
      var warehousesRamp = {};
      this.get('model.invitationRamps').map(function (x) {
        if (warehousesRamp.hasOwnProperty(x.get('ramp.warehouse.name'))) {
          warehousesRamp[x.get('ramp.warehouse.name')].push(x.get('ramp.name'));
        } else {
          warehousesRamp[x.get('ramp.warehouse.name')] = [];
          warehousesRamp[x.get('ramp.warehouse.name')].push(x.get('ramp.name'));
        }
      });
      return warehousesRamp;
    })
  });

  _exports.default = _default;
});