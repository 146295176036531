define("apollo/pods/user-groups/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax-service'),
    errors: [],
    successMessage: null,
    company: Ember.computed('model.company.id', function () {
      return this.get('model.company');
    }),
    userGroups: Ember.computed('model.userGroups.@each.id', function () {
      return this.get('model.userGroups');
    }),
    actions: {}
  });

  _exports.default = _default;
});