define("apollo/pods/applies-settings/index/controller", ["exports", "apollo/mixins/response-util-mixin"], function (_exports, _responseUtilMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_responseUtilMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    appliesSetting: Ember.computed('model', function () {
      return this.get('model');
    }),
    actions: {
      save: function save() {
        var _this = this;

        this.set('errors', []);
        this.get('appliesSetting').save().then(function () {
          return _this.handleSuccessResponse(_this);
        }).catch(function (response) {
          return _this.handleErrorResponse(_this, response);
        });
      },
      create: function create() {
        if (this.get('appliesSetting')) {
          return;
        }

        var principalCompany = this.get('sessionAccount.principalCompany');
        this.set('appliesSetting', this.get('store').createRecord('applies-setting', {
          principalCompany: principalCompany
        }));
      },
      delete: function _delete() {
        var _this2 = this;

        this.set('errors', []);
        this.get('appliesSetting').destroyRecord().then(function () {
          return _this2.handleSuccessResponse(_this2);
        }).catch(function (response) {
          return _this2.handleErrorResponse(_this2, response);
        });
      },
      cancel: function cancel() {
        this.get('appliesSetting').rollbackAttributes();
        this.transitionToRoute('settings.index');
      }
    }
  });

  _exports.default = _default;
});