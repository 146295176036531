define("apollo/pods/components/user-nav-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userAccessService: Ember.inject.service('user-access-service'),
    showMainDataLink: Ember.computed('user.id', function () {
      return this.shouldShowTab("ROLE_EDIT_USERS_MAIN_DATA", null);
    }),
    showRampLink: Ember.computed('user.id', function () {
      return this.shouldShowTab("ROLE_EDIT_USERS_RAMP", "ROLE_EDIT_MY_RAMP");
    }),
    showNotificationLink: Ember.computed('user.id', function () {
      return this.shouldShowTab("ROLE_EDIT_USERS_NOTIFICATION", "ROLE_EDIT_MY_NOTIFICATION");
    }),
    showQueueLink: Ember.computed('user.id', function () {
      return this.shouldShowTab("ROLE_MANAGE_QUEUES_ACCESSIBILITY", "ROLE_EDIT_MY_QUEUES");
    }),
    showActivityLink: Ember.computed('user.id', function () {
      return this.shouldShowTab("ROLE_ACCESS_USERS_ACTIVITY", "ROLE_ACCESS_USERS_ACTIVITY");
    }),
    shouldShowTab: function shouldShowTab(generalRole, myAccountRole) {
      return this.get('userAccessService').canAccess(this.get('user.id'), generalRole, myAccountRole);
    }
  });

  _exports.default = _default;
});