define("apollo/pods/super-admin/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    access: ['ROLE_SUPER_USER'],
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model() {
      var company = this.store.createRecord("company", {
        enabled: true,
        isPrincipal: true,
        pricingPlan: 'CLIENT'
      });
      var user = this.store.createRecord("user", {
        enabled: true,
        statuteAccepted: true,
        ramps: [],
        notifications: []
      });
      var address = this.store.createRecord("address", {});
      return Ember.RSVP.hash({
        user: user,
        company: company,
        address: address
      });
    }
  });

  _exports.default = _default;
});