define("apollo/pods/square-settings/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    errors: [],
    successMessage: null,
    intl: Ember.inject.service(),
    setSuccessMessage: function setSuccessMessage() {
      this.set('successMessage', this.get('intl').t("common.saveSuccessMessage"));
      this.set('errors', []);
    },
    setErrorMessage: function setErrorMessage(message) {
      this.set('successMessage', null);
      this.set('errors', message);
    },
    actions: {
      save: function save() {
        var _this = this;

        this.get('model').save().then(function () {
          return _this.setSuccessMessage();
        }).catch(function (response) {
          return _this.setErrorMessage(response.errors);
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('settings.index');
      }
    }
  });

  _exports.default = _default;
});