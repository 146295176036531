define("apollo/pods/components/transport/warehouse-zone-section/component", ["exports", "apollo/mixins/form-utils-mixin"], function (_exports, _formUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Component.extend(_formUtilsMixin.default, {
    intl: service(),
    sessionAccount: service('session-account'),
    languageService: service('language-service'),
    store: service(),
    transport: Ember.computed('step.transport', function () {
      return this.get('step.transport');
    }),
    warehouse: Ember.computed('step.warehouse', function () {
      return this.get('step.warehouse');
    }),
    warehouseZones: Ember.computed('step.warehouseZones', function () {
      return this.get('step.warehouseZones').sortBy('idx');
    }),
    showWarehouseZoneHint: Ember.computed('', function () {
      return this.get('sessionAccount').hasRole('ROLE_MOVE_WINDOWS_BETWEEN_WAREHOUSE_ZONES');
    }),
    warehouseLabel: function () {
      var properKey = this.get('languageService').findProperLanguageVersion('WAREHOUSE_LABEL', 'WAREHOUSE_LABEL_EN');
      var setting = this.get('sessionAccount').getSettingValue(properKey);
      return setting ? setting : this.get('intl').t('transport.warehouse');
    }.property().readOnly(),
    actions: {
      warehouseZoneSelected: function warehouseZoneSelected(selectedZone, object) {
        this.get('step').set('warehouseZone', selectedZone);
        this.clearBootstrapError($("#" + object.uniqueId));
      }
    }
  });

  _exports.default = _default;
});