define("apollo/pods/company/serializer", ["exports", "ember-data", "apollo/mixins/serializer-mixin"], function (_exports, _emberData, _serializerMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, _serializerMixin.default, {
    attrs: {
      headquarter: {
        embedded: 'save'
      }
    },
    serializeIntoHash: function serializeIntoHash(data, type, record, options) {
      var object = this.serialize(record, options);

      for (var key in object) {
        data[key] = object[key];
      }

      var partnership = record._internalModel.getRecord().get('partnershipWithCurrentCompany');

      if (partnership) {
        data.partnership = partnership.serialize({
          includeId: true
        });
      }
    },
    serializeBelongsTo: function serializeBelongsTo(record, json, relationship) {
      var key = relationship.key;
      var embeddedRecord = record.belongsTo(key);

      if (embeddedRecord && this.attrs[key] && this.attrs[key].embedded === "save") {
        var data = embeddedRecord.attributes();
        data.id = embeddedRecord.id;

        if (relationship.options.deepEmbedded) {
          relationship.options.deepEmbedded.forEach(function (deepKey) {
            if (embeddedRecord.belongsTo(deepKey)) {
              data[deepKey] = {
                id: embeddedRecord.belongsTo(deepKey).id
              };
            }
          });
        }

        json[key] = data;
      } else {
        this._super(record, json, relationship);
      }
    }
  });

  _exports.default = _default;
});