define("apollo/pods/authorities/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    authorityGroupArray: Ember.computed('selectedTransportType', 'model.roleGroupAuthorities.length', function () {
      var _this = this;

      var arr = Ember.A();
      var authoritiesToGroup;

      if (!this.get('selectedTransportType')) {
        authoritiesToGroup = this.get('model.roleGroupAuthorities').filter(function (rga) {
          return !rga.get('authority.assetType');
        });
      } else {
        var transportType = this.get('selectedTransportType');
        authoritiesToGroup = this.get('store').peekAll('authorityRestriction').filterBy('roleGroup.id', this.get('roleGroup.id')).filter(function (ar) {
          return ar.get('authority.assetType') && transportType.get('connectedAssetIds').includes(Number(ar.get('assetId')));
        });
      }

      this.get('model.authorityGroups').sortBy('idx').forEach(function (group) {
        arr.pushObject(_this.createAuthorityGroupObject(authoritiesToGroup, group));
      });
      var othersGroup = this.get('model.authorityGroups').find(function (ag) {
        return ag.get('name') === 'OTHERS';
      });
      arr.pushObject(this.createAuthorityGroupObjectWithoutGroup(authoritiesToGroup, othersGroup));
      return arr;
    }),
    translationsPrefix: Ember.computed(function () {
      return "authorityGroup.";
    }),
    transportTypes: Ember.computed('model.transportTypes.@each', function () {
      return this.get('model.transportTypes');
    }),
    roleGroup: Ember.computed('model.roleGroup', function () {
      return this.get('model.roleGroup');
    }),
    showTableColumnSection: Ember.computed('selectedTransportType', function () {
      return this.get('sessionAccount').hasRole("ROLE_MANAGE_COLUMN_VISIBILITY") && this.get('selectedTransportType');
    }),
    actions: {
      setTransportType: function setTransportType(transportType) {
        this.set('selectedTransportType', transportType);
      },
      addPermission: function addPermission(transportType) {
        if (transportType) {
          this.transitionToRoute('authority-restrictions.create', {
            queryParams: {
              transportTypeId: transportType.get('id'),
              roleGroupId: this.get('roleGroup.id')
            }
          });
        } else {
          this.transitionToRoute('authorities.create', {
            queryParams: {
              roleGroupId: this.get('roleGroup.id')
            }
          });
        }
      },
      delete: function _delete(roleGroupAuthority) {
        var self = this;
        var roleGroupId = roleGroupAuthority.get('roleGroup.id');
        roleGroupAuthority.destroyRecord().then(function (a) {
          var msg = self.get('intl').t('settings.roleGroups.successDelete');
          self.set('successMessage', msg);
          self.transitionToRoute('authorities.index', roleGroupId);
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      },
      deleteAllAuthorities: function deleteAllAuthorities(roleGroupAuthorities) {
        for (var i = 0; i < roleGroupAuthorities.length; i++) {
          this.send('delete', roleGroupAuthorities[i]);
        }
      }
    },
    createAuthorityGroupObject: function createAuthorityGroupObject(authorities, group) {
      return Ember.Object.create({
        authorities: authorities.filter(function (a) {
          return [a.get('group.id'), a.get('authority.group.id')].includes(group.get('id'));
        }).sortBy('idx'),
        group: group
      });
    },
    createAuthorityGroupObjectWithoutGroup: function createAuthorityGroupObjectWithoutGroup(authorities, othersGroup) {
      return Ember.Object.create({
        authorities: authorities.filter(function (a) {
          return !a.get('group.id') && !a.get('authority.group.id');
        }).sortBy('idx'),
        group: othersGroup
      });
    }
  });

  _exports.default = _default;
});