define("apollo/pods/components/transports/transports-table/add-relations-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    relationsTrackerService: Ember.inject.service('transport-relations.relations-tracker'),
    sessionAccount: Ember.inject.service(),
    showButton: Ember.computed('canCombineTransports', 'relationsTrackerService.currentTransports.length', function () {
      var atLeastTwoTransportsSelected = this.get('relationsTrackerService.currentTransports.length') > 1;
      var userHasAuthorityToAddRelations = this.get('sessionAccount').hasRole('ROLE_CAN_ADD_TRANSPORT_COMBINING_RELATION') || this.get('sessionAccount').hasRole('ROLE_CAN_ADD_HAS_TO_WAIT_FOR_RELATION');
      return userHasAuthorityToAddRelations && atLeastTwoTransportsSelected;
    }),
    actions: {
      openRelationsModal: function openRelationsModal() {
        this.set('action', 'openRelationsModal');
        this.sendAction('action');
      }
    }
  });

  _exports.default = _default;
});