define("apollo/pods/components/schedule/current-hour-frame/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    borderWidth: Ember.computed(function () {
      var borderWidth = $('.time-slot').css('border-bottom-width').toString();
      return borderWidth ? parseInt(borderWidth) : 0;
    }).readOnly(),
    // Górna ramka będzie wyświetlana ponad komórką - dlatego przesuwamy ją o `borderWidth` w górę.
    currentHourFrameTopOffset: Ember.computed('cssHeight', 'currentHour', 'shownDays.[]', function () {
      var $currentHourDiv = $('.current-hour');

      if (!$currentHourDiv.length) {
        return 0;
      }

      return $currentHourDiv.offset().top - this.get('borderWidth');
    }).readOnly(),
    currentHourFrameBottomOffset: Ember.computed('currentHourFrameTopOffset', 'timeWindowOffsetIntervalSetting', function () {
      var $currentHourDiv = $('.current-hour');

      if (!$currentHourDiv.length) {
        return 0;
      }

      var scheduleBodyBottomPosition = $('.schedule-body')[0].getBoundingClientRect().bottom;
      var hourFrameBottomOffsetPosition = this.get('currentHourFrameTopOffset') + (this.get('borderWidth') + $currentHourDiv.height());

      if (scheduleBodyBottomPosition <= hourFrameBottomOffsetPosition) {
        return 0;
      }

      return hourFrameBottomOffsetPosition;
    }).readOnly()
  });

  _exports.default = _default;
});