define("apollo/pods/components/auction/auction-details-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      showAuctionDetailsForm: function showAuctionDetailsForm() {
        var transport = this.get('transport');
        this.set('action', 'showModal');
        this.sendAction('action', 'auctions.modals.auction-details-form', transport);
      }
    }
  });

  _exports.default = _default;
});