define("apollo/pods/companies/allowed-domains/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['lastUpdated'],
    intl: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax-service'),
    errors: [],
    successMessage: null,
    companyEmailDomainsList: [],
    companyEmailDomainsListObserver: Ember.observer('model.companyEmailDomains.@each.id', function () {
      var _this = this;

      this.set('companyEmailDomainsList', []);
      this.get('model.companyEmailDomains').forEach(function (ced) {
        return _this.get('companyEmailDomainsList').pushObject(ced);
      });
    }),
    company: Ember.computed('model.company.id', function () {
      return this.get('model.company');
    }),
    showSaveButton: Ember.computed('companyEmailDomainsList.@each.id', 'companyEmailDomainsList.length', function () {
      return this.get('companyEmailDomainsList').filter(function (ced) {
        return !ced.get('id');
      }).length > 0;
    }),
    actions: {
      save: function save() {
        var _this2 = this;

        var cedsToSave = this.get('companyEmailDomainsList').filter(function (ced) {
          return !ced.get('id');
        });

        if (cedsToSave.some(function (ced) {
          return !ced.validate();
        })) {
          return;
        }

        var serializedCeds = cedsToSave.map(function (ced) {
          return ced.serialize();
        });
        this.get('ajaxService').send('/company-email-domains', 'POST', {
          ceds: serializedCeds
        }, true).then(function () {
          _this2.set('successMessage', _this2.get('intl').t('company.allowedDomains.successSaveMsg'));

          _this2.set('lastUpdated', new Date());
        }).catch(function (response) {
          _this2.set('errors', response.responseJSON["errors"]);
        });
      },
      delete: function _delete(companyEmailDomain) {
        var _this3 = this;

        if (!companyEmailDomain.get('id')) {
          this.get('companyEmailDomainsList').removeObject(companyEmailDomain);
          companyEmailDomain.unloadRecord();
        } else {
          if (!confirm(this.get('intl').t('common.sure'))) {
            return;
          }

          companyEmailDomain.destroyRecord().then(function () {
            _this3.set('successMessage', _this3.get('intl').t('company.allowedDomains.successDeleteMsg'));
          }).catch(function (response) {
            _this3.set('errors', response.errors);
          });
        }
      },
      add: function add() {
        var company = this.get('company');
        this.get('companyEmailDomainsList').pushObject(this.get('store').createRecord('companyEmailDomain', {
          company: company
        }));
      }
    }
  });

  _exports.default = _default;
});