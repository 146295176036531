define("apollo/pods/components/trucks/trucks-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    columns: Ember.computed(function () {
      return [{
        propertyName: 'registrationNumber',
        title: this.get('intl').t('truck.registrationNumber'),
        className: 'column-registration-number'
      }, {
        propertyName: 'truckTypeId',
        title: this.get('intl').t('truck.truckType'),
        className: 'column-truck-type',
        filterWithSelect: true,
        predefinedFilterOptions: this.get('store').peekAll('truck-type').map(function (tt) {
          return {
            value: tt.get('id'),
            label: tt.get('nameInAppropriateLanguage')
          };
        })
      }, {
        propertyName: 'trailer',
        title: this.get('intl').t('truck.trailer'),
        className: 'column-trailer'
      }, {
        propertyName: 'description',
        title: this.get('intl').t('truck.description'),
        className: 'column-description'
      }, {
        propertyName: 'hds',
        title: this.get('intl').t('truck.hds'),
        className: 'column-hds',
        filterWithSelect: true,
        predefinedFilterOptions: [{
          value: 'true',
          label: this.get('intl').t('common.yes')
        }, {
          value: 'false',
          label: this.get('intl').t('common.no')
        }]
      }, {
        propertyName: 'lastUpdated',
        title: this.get('intl').t('truck.lastUpdated'),
        className: 'column-last-updated',
        componentForFilterCell: 'server-table/filters/date-filter'
      }];
    }),
    actions: {
      openAddTruckModal: function openAddTruckModal(company, truck) {
        if (!truck) {
          truck = this.get('store').createRecord('truck', {
            enabled: true,
            isModeOfTransportation: false,
            owner: company
          });
        }

        this.set('action', 'showModal');
        this.sendAction('action', 'companies.modals.add-truck', truck);
      }
    }
  });

  _exports.default = _default;
});