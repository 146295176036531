define("apollo/pods/auth/login/controller", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "apollo/config/environment"], function (_exports, _unauthenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Controller.extend(_unauthenticatedRouteMixin.default, {
    session: service('session'),
    intl: service(),
    queryParams: ['principalId'],
    principalId: null,
    logo: '',
    setLogo: function () {
      var previousPrincipalId = localStorage.getItem('previousPrincipalId');
      var self = this;

      if (self.get('principalId') || previousPrincipalId) {
        Ember.$.ajax({
          url: _environment.default.serverURL + '/loginPage',
          type: 'GET',
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          data: {
            principalId: self.get('principalId') ? self.get('principalId') : previousPrincipalId
          }
        }).then(function (resp) {
          if (resp.errors) {
            console.error(resp.errors);
          } else {
            self.set('logo', resp.logo);
          }
        });
      }
    }.observes('principalId'),
    getAnnouncement: Ember.on('init', function () {
      var self = this;
      var pathname = window.location.pathname.replace('/', '');

      if (this.get('alias')) {
        pathname = this.get('alias');
      }

      Ember.$.ajax({
        url: _environment.default.serverURL + '/announcements',
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        data: {
          location: "LOGIN_PAGE"
        }
      }).then(function (resp) {
        if (resp.errors) {
          console.error(resp.errors);
        } else if (resp.announcement) {
          self.set('announcement', self.get('store').createRecord('announcement', {
            msgContent: resp.announcement.msgContent,
            msgContentEn: resp.announcement.msgContentEn
          }));
        }
      }).catch(function () {
        self.set('serviceNotWorking', true);
      });
    }),
    actions: {
      authenticate: function authenticate(endpoint) {
        var self = this;
        self.set("errorMessage", null);
        this.set('authFailedReason', null);
        this.set('logoutByConcurrentSession', null);
        localStorage.removeItem('logoutByConcurrentSession');
        var credentials = this.getProperties('identification', 'password');

        if (credentials.identification) {
          credentials.identification = credentials.identification.trim();
        }

        if (credentials.password) {
          credentials.password = credentials.password.trim();
        }

        if (!credentials.identification || !credentials.password) {
          var message = this.get('intl').t('auth.noLoginAndPassword');
          this.set('authFailedReason', 'auth.noLoginAndPassword');
          this.set('errorMessage', message);
          return;
        }

        $(".login-progress").show();
        this.get('session').authenticate('authenticator:custom', credentials, endpoint).then(null, function (response) {
          var responseText = response.responseText,
              status = response.status;

          if (responseText !== undefined) {
            console.debug("Login request has been rejected with status ".concat(status, " and response \"").concat(responseText, "\"."));
          }

          self.set('password', null);
          $(".login-progress").hide();
          $(".password").focus();
          var message;

          if (status === 401) {
            var defaultMsgCode = 'auth.invalidLoginOrPassword';

            if (responseText) {
              var msgParams = JSON.parse(responseText);

              if (msgParams.message !== undefined) {
                self.set('errorMessage', msgParams.message);
                return;
              }

              var args = msgParams.args ? msgParams.args : {};
              var code = msgParams.code ? msgParams.code : defaultMsgCode;
              self.set('authFailedReason', code);
              message = self.get('intl').t(code, args);
            } else {
              self.set('authFailedReason', defaultMsgCode);
              message = self.get('intl').t(defaultMsgCode);
            }
          } else {
            message = self.get('intl').t('auth.serverError');
            self.set('authFailedReason', 'auth.serverError');
          }

          self.set('errorMessage', message);
        });
      }
    }
  });

  _exports.default = _default;
});