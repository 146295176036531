define("apollo/pods/driver/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    DOCUMENT_TYPES: {
      PASSPORT: 'PASSPORT',
      ID_CARD: 'ID_CARD'
    },
    owner: _emberData.default.belongsTo('company', {
      async: true
    }),
    country: _emberData.default.belongsTo('country', {
      async: true
    }),
    name: _emberData.default.attr('string'),
    documentNumber: _emberData.default.attr('string'),
    documentType: _emberData.default.attr('string'),
    phoneNumber: _emberData.default.attr('string'),
    phoneNumberPrefix: _emberData.default.attr('string', {
      defaultValue: '48'
    }),
    lastUpdated: _emberData.default.attr('isodate'),
    enabled: _emberData.default.attr('boolean'),
    validations: {
      name: {
        presence: {
          message: 'blank'
        }
      },
      phoneNumber: {
        presence: {
          message: 'blank'
        },
        custom: [{
          validation: function validation(key, value) {
            if (!value) {
              return true;
            }

            return /^[0-9]+$/.test(value) && value.length >= 9;
          },
          message: 'regex'
        }]
      },
      phoneNumberPrefix: {
        presence: {
          message: 'blank'
        },
        custom: [{
          validation: function validation(key, value) {
            if (!value) {
              return true;
            }

            return value.length <= 3;
          },
          message: 'blank'
        }]
      },
      documentNumber: {
        presence: {
          message: 'blank'
        }
      }
    }
  });

  _exports.default = _default;
});