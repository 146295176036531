define("apollo/pods/components/tasks-sortable-table/task-sortable-row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax-service'),
    intl: Ember.inject.service(),
    dateSigns: Ember.A(['before', 'after']),
    dateUnits: Ember.A(['h', 'm', 's']),
    stepTypes: Ember.A(['load', 'unload', 'firstLoad', 'lastLoad', 'firstUnload', 'lastUnload']),
    hasSubTasks: Ember.computed('task.subTasks.{@each.enabled,length}', function () {
      var subTasks = this.get('task.subTasks').filterBy('enabled');
      return subTasks && subTasks.length > 0;
    }),
    sortedSubTasks: Ember.computed('task.subTasks.@each.{idx,enabled}', function () {
      return this.get('task.subTasks').filterBy('enabled').sortBy('idxInGroup');
    }),
    reloadTasks: function reloadTasks() {
      var taskToReload = this.get('store').peekAll('task').filter(function (task) {
        return task.get('id') === null;
      });
      taskToReload.forEach(function (task) {
        task.deleteRecord();
      });
      this.get('task').reload();
    },
    actions: {
      sortEndAction: function sortEndAction() {
        this.get('task.subTasks').forEach(function (task, idx) {
          task.set('idxInGroup', idx);
        });
      },
      addSubTask: function addSubTask(masterTask, idx) {
        var subTask = this.get('store').createRecord('task', {
          workflow: masterTask.workflow,
          taskGroupIdentifier: masterTask.taskGroupIdentifier,
          idxInGroup: idx,
          masterTask: masterTask,
          expectedDateInterval: '30m',
          enabled: true
        });
        masterTask.get('subTasks').pushObject(subTask);
        console.debug('Utworzyliśmy task dodatkowy :: ' + subTask + ' :: przypisany do tasku głównego :: ' + masterTask + ' ::');
      },
      saveSubTasks: function saveSubTasks() {
        var self = this;
        var data = this.get('task');

        if (!data) {
          console.error('Błąd podczas próby zapisu tasków dodatkowych, masterTask :: ' + data);
        }

        this.get('ajaxService').send("/subTasks/".concat(this.get('task.id')), 'POST', data.serialize(), true).then(function (response) {
          self.set('successMessage', self.get('intl').t('workflows.saveSuccessMessage'));
          alert(self.get('intl').t('workflows.saveSuccessMessage'));
          self.reloadTasks();
          console.debug('Zapisalismy task i jego subtaski :: ' + response);
          self.transitionToRoute('workflows.edit', self.get('model.workflow.id'));
        }).catch(function (response) {
          alert(response.responseJSON.errors);
          self.reloadTasks();
        });
      },
      toggleMarkIfWasDelayed: function toggleMarkIfWasDelayed(task) {
        this.set('action', 'toggleMarkIfWasDelayed');
        this.sendAction('action', task);
      },
      toggleTracked: function toggleTracked(task) {
        this.set('action', 'toggleTracked');
        this.sendAction('action', task);
      }
    }
  });

  _exports.default = _default;
});