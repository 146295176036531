define("apollo/pods/warehouses/schedule/controller", ["exports", "apollo/mixins/schedule-mixin", "apollo/mixins/persistence-mixin", "apollo/mixins/attachment-mixin", "apollo/mixins/advice-utils-mixin", "apollo/mixins/transports-mixin", "apollo/mixins/transport-form-mixin", "jquery", "apollo/utils/parsers/numberParsers"], function (_exports, _scheduleMixin, _persistenceMixin, _attachmentMixin, _adviceUtilsMixin, _transportsMixin, _transportFormMixin, _jquery, _numberParsers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_scheduleMixin.default, _persistenceMixin.default, _attachmentMixin.default, _adviceUtilsMixin.default, _transportsMixin.default, _transportFormMixin.default, {
    session: Ember.inject.service('session'),
    intl: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax-service'),
    appTabActiveService: Ember.inject.service('application-tab-active-service'),
    schedulePollingService: Ember.inject.service('polling.schedule-service'),
    transportCombiner: Ember.inject.service('transport-relations.relations-combiner'),
    company: null,
    selectedUserGroup: null,
    routeName: Ember.computed('hdMode', function () {
      return this.get('hdMode') ? 'hd' : 'schedule';
    }),
    createTransport: Ember.inject.controller('transports/modals/create-transport'),
    applicationController: Ember.inject.controller('application'),
    errors: Ember.A(),
    editedTransportType: null,
    openTransportId: null,
    stepWithoutWindowCopy: null,
    initResizeObserver: Ember.on('init', function () {
      $(window).on('resize', function () {
        $('.popup-dialog').dialog('option', 'position', {
          my: 'right bottom',
          at: 'right bottom',
          of: window
        });
      });
    }),
    // eslint-disable-next-line ember/no-observers
    setupTimeWindowDescriptionScrollerObserver: Ember.observer('hdMode', function () {
      var _this = this;

      if (this.get('hdMode')) {
        (0, _jquery.default)(document).on('scroll', function () {
          return Ember.run.debounce({
            schedule: _this
          }, _this.recalculateTimeWindowsDescriptionPosition, 100);
        });
      } else {
        (0, _jquery.default)(document).off('scroll');
      }
    }),
    isDuringWindowSelect: Ember.computed('transportToHandle', 'dedicatedTimeWindowToHandle', function () {
      return !!this.get('transportToHandle') || !!this.get('dedicatedTimeWindowToHandle');
    }),
    closeMultipleTimeWindowsModal: function closeMultipleTimeWindowsModal() {
      this.set('showMultipleTimeWindowsModal', false);
      this.send('hideModal');
    },
    // https://jsfiddle.net/alvaroAV/svvz7tkn/
    isIE: Ember.computed('', function () {
      var ua = window.navigator.userAgent;
      return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
    }),
    recalculateTimeWindowsDescriptionPosition: function recalculateTimeWindowsDescriptionPosition() {
      var referenceElement = this.schedule.get('isIE') ? (0, _jquery.default)('.schedule-navigation') : (0, _jquery.default)('.ramp-label');

      if (!referenceElement.length) {
        return;
      }

      var referenceOffset = referenceElement.offset().top + referenceElement.height();
      this.schedule.moveWindowDescriptions(referenceOffset);
    },
    moveWindowDescriptions: function moveWindowDescriptions(referenceOffset) {
      var allWindows = (0, _jquery.default)('.window-state-with-transport');

      for (var i = 0; i < allWindows.length; i++) {
        var timeWindowElement = (0, _jquery.default)(allWindows[i]);
        var timeWindowTitle = timeWindowElement.find('.title');
        var timeWindowOffset = timeWindowElement.offset().top;
        var timeWindowHeight = timeWindowElement.height();
        var timeWindowTitleOffset = timeWindowTitle.offset().top;

        if (timeWindowOffset < referenceOffset && timeWindowOffset + timeWindowHeight > referenceOffset) {
          timeWindowTitle.offset({
            top: referenceOffset
          });
        } else if (timeWindowOffset !== timeWindowTitleOffset) {
          timeWindowTitle.offset({
            top: timeWindowOffset
          });
        }
      }
    },
    dragWindowAcrossScheduleDays: function dragWindowAcrossScheduleDays(step, days, target) {
      var isTransportWindow = target.parent('.window-state-with-transport').length;
      var isBlockadeWindow = target.parent('.window-state-disabled').length;
      var isDedicatedWindow = target.parent('.window-state-dedicated').length;
      var windowId = step.get('timeWindow.id') || target.parent().data('schedule-window');
      var windowModel = this.store.peekRecord('timeWindow', windowId);

      try {
        if (isTransportWindow) {
          var warehouseId = step.get('warehouse.id');

          var _window = step.get('timeWindow');

          this.set('warehouseStepToHandle', step);
          windowModel.lockForEditing();
          var windowStart = moment(_window.get('start')).add(days, 'days').format('YYYY-MM-DD'); // TODO: utworzyć i obsłużyć osobny modal tylko do przenoszenia samego okna czasowego
          // (teraz podpinamy się pod modal edytowania awizacji)

          this.transitionToRoute('warehouses.schedule', warehouseId, windowStart, '1');
        } else if (isBlockadeWindow || isDedicatedWindow) {
          var _windowStart = moment(windowModel.get('start')).add(days, 'days').format('YYYY-MM-DD');

          var _warehouseId = windowModel.get('ramp.warehouse.id');

          this.set('dedicatedTimeWindowToHandle', windowModel);
          this.set('editedWindow', windowModel);
          windowModel.lockForEditing();
          this.transitionToRoute('warehouses.schedule', _warehouseId, _windowStart, '1');
        }
      } catch (e) {
        console.log(e);
        windowModel.unlockForEditing();
      }
    },
    transportToHandle: Ember.computed('warehouseStepToHandle.transport', function () {
      return this.get('warehouseStepToHandle.transport');
    }),
    isDuringTransportSave: Ember.computed('transportToHandle.id', function () {
      var transportToHandle = this.get('transportToHandle');
      return transportToHandle && !transportToHandle.get('id');
    }),
    editedWindow: Ember.computed('warehouseStepToHandle.timeWindow', function () {
      return this.get('warehouseStepToHandle.timeWindow.id') ? this.get('warehouseStepToHandle.timeWindow') : null;
    }),
    attachments: Ember.A(),
    info: null,
    shownDaysContainCurrentDay: Ember.computed('shownDays', function () {
      return this.get('shownDays').filterBy('name', this.get('currentFormattedDate')).length;
    }).readOnly(),
    showSelectDepartment: Ember.computed('model.userGroups.@each.length', function () {
      return this.get('model.userGroups.length') > 0;
    }).readOnly(),
    currentMoment: Ember.computed('', function () {
      return moment();
    }),
    currentFormattedDate: Ember.computed('currentMoment', function () {
      return moment(this.get('currentMoment')).format('YYYY-MM-DD');
    }).readOnly(),
    showCurrentHourBackgroundColor: Ember.computed('timeWindowOffsetIntervalSetting', function () {
      var interval = this.get('timeWindowOffsetIntervalSetting');

      if (interval > 60) {
        return '';
      }

      return 'current-hour';
    }),
    currentHour: Ember.computed('currentMoment', function () {
      return moment(this.get('currentMoment')).format('HH:mm');
    }).readOnly(),
    scheduleEndDate: Ember.computed('scheduleStartDate', 'model.delta', function () {
      var delta = parseInt(this.get('model.delta'));
      return moment(this.get('scheduleStartDate')).add(delta - 1, 'days').endOf('day').toDate();
    }),
    // eslint-disable-next-line ember/no-observers
    scheduleDateSelected: Ember.observer('selectedDateString', function () {
      var selectedDate = this.get('selectedDateString');
      this.transitToDate(Ember.isArray(selectedDate) ? selectedDate[0] : selectedDate);
    }),
    viewedColumns: Ember.computed('ramps.length', 'scheduleEndDate', function () {
      return this.get('ramps.length') * this.get('viewedDays');
    }),
    viewedDays: Ember.computed('viewedColumns', function () {
      var start = moment(this.get('scheduleStartDate'));
      var end = moment(this.get('scheduleEndDate'));
      return end.diff(start, 'days') + 1;
    }),
    showLongName: Ember.computed('viewedColumns', function () {
      return this.get('viewedColumns') < 9;
    }),
    // eslint-disable-next-line ember/no-observers
    warehouseSelected: Ember.observer('model.warehouse.id', function () {
      var dateString = this.get('scheduleStartDateFormatted');
      var delta = this.get('model.delta');
      this.set('defaultZoom', undefined);
      this.transitionToRoute('warehouses.' + this.get('routeName'), this.get('model.warehouse.id'), dateString, delta);
    }),
    // eslint-disable-next-line ember/no-observers
    selectedUserGroupObserver: Ember.observer('selectedUserGroup', function () {
      if (this.get('selectedUserGroup')) {
        this.set('company', null);
      }
    }),
    // eslint-disable-next-line ember/no-observers
    selectedCompanyObserver: Ember.observer('company', function () {
      if (this.get('company')) {
        this.set('selectedUserGroup', null);
      }
    }),
    // eslint-disable-next-line ember/no-observers
    showWindowPopUpObserver: Ember.observer('focusedWindowId', function () {
      if (this.get('editedWindow.id')) {
        console.debug("Okno ".concat(this.get('editedWindow.id'), " jest w trakcie edycji. Nie wy\u015Bwietlamy popupu"));
        return;
      }

      Ember.run.debounce(this, this._showWindowPopUpObserver, 500);
    }),
    _showWindowPopUpObserver: function _showWindowPopUpObserver() {
      var self = this;

      if (self.get('focusedWindowId')) {
        var _window2 = self.store.peekRecord('timeWindow', self.get('focusedWindowId'));

        if (_window2 === null || !_window2.get('step.id')) {
          return;
        }

        var transportIdToFetch = _window2.get('relatedTransport.id') ? _window2.get('relatedTransport.id') : _window2.get('transport.id');
        self.get('store').findRecord('transport', transportIdToFetch).then(function (transport) {
          self.getStatuses(transport);
          var customFields = self.get('store').query('customFieldValue', {
            transportId: transport.get('id')
          });

          _window2.get('transport').set('customFields', customFields);

          self.send('showPopupDialog', 'dialogs.window-details-dialog', {
            window: _window2,
            transport: transport,
            position: self.get('position')
          });
        });
      } else {
        self.send('removePopupMessage');
      }
    },
    shouldSkipAssortmentGroupRestriction: function shouldSkipAssortmentGroupRestriction() {
      return !this.get('assortmentGroup.id') || this.get('assortmentGroup.id') && this.get('sessionAccount.currentUser').canDiscardAssortmentGroupRestrictions();
    },
    shouldSkipAdviceRangeRestriction: function shouldSkipAdviceRangeRestriction(minAdviceDate, transportTypeId) {
      return !minAdviceDate || minAdviceDate && this.get('sessionAccount.currentUser').canDiscardAdviceRestrictions(transportTypeId);
    },
    shouldShowAdviceRangeAutoBlockadeWindow: function shouldShowAdviceRangeAutoBlockadeWindow(warehouseStartDate, warehouseEndDate, minAdviceDate, maxAdviceDate) {
      return minAdviceDate > warehouseEndDate || maxAdviceDate < warehouseStartDate;
    },
    getMinRestrictionDate: function getMinRestrictionDate(warehouseStartDate, warehouseEndDate, minAdviceDate, maxAdviceDate, transportTypeId) {
      if (this.shouldSkipAssortmentGroupRestriction()) {
        return minAdviceDate < warehouseEndDate ? minAdviceDate : warehouseEndDate;
      }

      if (this.shouldShowAdviceRangeAutoBlockadeWindow(warehouseStartDate, warehouseEndDate, minAdviceDate, maxAdviceDate)) {
        return warehouseStartDate;
      }

      var minAssortmentGroupDate = moment(warehouseStartDate);
      minAssortmentGroupDate = minAssortmentGroupDate.set({
        h: this.get('assortmentGroup.adviceFromHours'),
        m: this.get('assortmentGroup.adviceFromMinutes')
      });

      if (this.shouldSkipAdviceRangeRestriction(minAdviceDate, transportTypeId)) {
        return minAssortmentGroupDate;
      }

      return minAssortmentGroupDate > minAdviceDate ? minAssortmentGroupDate : minAdviceDate;
    },
    getMaxRestrictionDate: function getMaxRestrictionDate(warehouseStartDate, warehouseEndDate, minAdviceDate, maxAdviceDate, transportTypeId) {
      if (this.shouldSkipAssortmentGroupRestriction()) {
        return maxAdviceDate;
      }

      if (this.shouldShowAdviceRangeAutoBlockadeWindow(warehouseStartDate, warehouseEndDate, minAdviceDate, maxAdviceDate)) {
        return warehouseStartDate;
      }

      var maxAssortmentGroupDate = moment(warehouseStartDate);
      maxAssortmentGroupDate = maxAssortmentGroupDate.set({
        h: this.get('assortmentGroup.adviceToHours'),
        m: this.get('assortmentGroup.adviceToMinutes')
      });

      if (this.shouldSkipAdviceRangeRestriction(minAdviceDate, transportTypeId)) {
        return maxAssortmentGroupDate;
      }

      return maxAssortmentGroupDate < maxAdviceDate ? maxAssortmentGroupDate : maxAdviceDate;
    },
    createAutoBlockadeWindow: function createAutoBlockadeWindow(start, stop, ramp) {
      var window = this.get('store').createRecord('timeWindow', {
        start: start,
        stop: stop,
        ramp: ramp,
        isAutoBlockade: true,
        isLockedForEditing: true
      });
      this.get('windows').pushObject(window);
    },
    shouldSeeAutoBlockadesWindows: function shouldSeeAutoBlockadesWindows(transportTypeId, minAdviceDate) {
      var assortmentGroupId = this.get('assortmentGroup.id');
      var userCanDiscardAdviceRestrictions = this.get('sessionAccount.currentUser').canDiscardAdviceRestrictions(transportTypeId);
      var userCanDiscardAssortmentGroupRestrictions = this.get('sessionAccount.currentUser').canDiscardAssortmentGroupRestrictions();
      var hasAdviceOnlyOnDedicatedWindowsRole = this.get('sessionAccount').hasRole('ADVICE_ONLY_ON_DEDICATED_WINDOWS');
      var assortmentGroupRestriction = assortmentGroupId && !userCanDiscardAssortmentGroupRestrictions;
      var adviceTimeRestriction = minAdviceDate && !userCanDiscardAdviceRestrictions;

      if (assortmentGroupRestriction) {
        return true;
      }

      if (adviceTimeRestriction) {
        return true;
      }

      if (hasAdviceOnlyOnDedicatedWindowsRole) {
        return true;
      }

      return false;
    },
    _setAutoBlockades: function _setAutoBlockades() {
      var _this2 = this;

      var transportTypeId = this.get('warehouseStepToHandle.transport.transportType.id');
      var minAdviceDate = this.get('warehouseStepToHandle.minAdviceDate') || undefined;
      var maxAdviceDate = this.get('warehouseStepToHandle.maxAdviceDate') || undefined;

      if (!this.shouldSeeAutoBlockadesWindows(transportTypeId, minAdviceDate) || !this.get('model.warehouse.id')) {
        return;
      }

      this.get('shownDays').forEach(function (d) {
        var warehouseStartDate = _this2.get('model.warehouse').getStartOnDay(d.date);

        var warehouseEndDate = _this2.get('model.warehouse').getEndOnDay(d.date);

        var topWindowStart = warehouseStartDate;

        var topWindowEnd = _this2.getMinRestrictionDate(warehouseStartDate, warehouseEndDate, minAdviceDate, maxAdviceDate, transportTypeId);

        var bottomWindowStart = _this2.getMaxRestrictionDate(warehouseStartDate, warehouseEndDate, minAdviceDate, maxAdviceDate, transportTypeId);

        var bottomWindowEnd = warehouseEndDate;
        var showTopWindow = topWindowEnd > warehouseStartDate && topWindowEnd < warehouseEndDate;
        var showBottomWindow = bottomWindowStart > warehouseStartDate && bottomWindowStart < warehouseEndDate;
        /*
        * Przypadek, gdy grupa asortymentowa ma ustawione godziny od np. 15:00 do 13:00 (kolejnego dnia).
        * W takim przypadku chcemy wyświetlić tylko jedno okno, które wyklucza
        * godziny poza grupą tj. (z przykładu wyżej: 13:00 - 15:00)
        * */

        if (_this2.get('assortmentGroup.id') && _this2.get('assortmentGroup.hasTurnedHours')) {
          topWindowStart = bottomWindowStart;
          showBottomWindow = false;
        }

        _this2.get('ramps').forEach(function (ramp) {
          var timeWindows = _this2.get('filteredWindows').sortBy('start');
          /*
              Tworzenie okien blokady w wolnych miejscach (bez okien czasowych),
              ale pod warunkiem, że użytkownik ma uprawnienie ADVICE_ONLY_ON_DEDICATED_WINDOWS.
          */


          var hasAdviceOnlyOnDedicatedWindowsRole = _this2.get('sessionAccount').hasRole('ADVICE_ONLY_ON_DEDICATED_WINDOWS');

          if (hasAdviceOnlyOnDedicatedWindowsRole) {
            var windowsArr = []; // Szukamy wszystkich okien na konkretnej rampie i wybieramy
            // tylko te, które mają `state` (czyli wszystkie poza szarymi)

            timeWindows.forEach(function (tw) {
              if (tw.get('start') >= moment(warehouseStartDate) && tw.get('stop') <= moment(warehouseEndDate) && tw.get('ramp.id') === ramp.get('id') && tw.get('state') !== undefined) {
                windowsArr.push(tw);
              }
            }); // jeśli nie znajedziemy okna na rampie to blokujemy ją całą

            if (windowsArr.length <= 0) {
              _this2.createAutoBlockadeWindow(warehouseStartDate, warehouseEndDate, ramp);

              return;
            }

            for (var i = 0; i <= windowsArr.length; i++) {
              // podczas pierwszej iteracji stawiamy okno blokady od początku rampy do początku pierwszego okna
              if (i === 0) {
                var stop = windowsArr[0].start ? windowsArr[0].start : '';

                _this2.createAutoBlockadeWindow(warehouseStartDate, moment(stop), ramp);
              } else if (i >= 1 && i < windowsArr.length) {
                // podczas następynch iteracji z wyjątkiem pierwszej i ostatniej
                // stawiamy okno blokady pomiędzy oknami z tabliczy `windowsArr`
                _this2.createAutoBlockadeWindow(windowsArr[i - 1].stop, windowsArr[i].start, ramp);
              } else {
                // pozostaje nam ostatnia iteracja, na której stawiamy
                // okno blokady pomiędzy ostatnim znalezionym oknem a końcem rampy
                _this2.createAutoBlockadeWindow(windowsArr[windowsArr.length - 1].stop, warehouseEndDate, ramp);
              }
            }
          }

          if (showTopWindow) {
            _this2.createAutoBlockadeWindow(topWindowStart, topWindowEnd, ramp);
          }

          if (showBottomWindow) {
            _this2.createAutoBlockadeWindow(bottomWindowStart, bottomWindowEnd, ramp);
          }

          if (!showTopWindow && !showBottomWindow && (minAdviceDate >= warehouseEndDate || maxAdviceDate <= warehouseStartDate)) {
            _this2.createAutoBlockadeWindow(warehouseStartDate, warehouseEndDate, ramp);
          }
        });
      });
    },
    // eslint-disable-next-line ember/no-observers
    removeAutoBlockades: Ember.observer('warehouseStepToHandle', function () {
      var _this3 = this;

      if (this.get('warehouseStepToHandle')) {
        return;
      }

      var autoBlockades = this.get('windows').filterBy('isAutoBlockade');
      autoBlockades.forEach(function (tw) {
        _this3.get('windows').removeObject(tw);
      });
    }),
    setWindows: function setWindows() {
      if (!this.get('rampsIds.length')) {
        return;
      }

      var transportIds = this.store.peekAll('transport').map(function (t) {
        return t.id;
      });
      var data = {
        loadedTransportIds: transportIds.join(', '),
        rampsIds: this.get('rampsIds').join(',')
      };
      this.loadWindows(0, data);
    },
    storeDisabledWindow: function storeDisabledWindow(start, stop, rampId) {
      var self = this;
      var selectedCompany = this.get('company');
      var selectedUserGroup = this.get('selectedUserGroup');
      var windowData = {
        ramp: this.store.peekRecord('ramp', rampId),
        company: selectedCompany,
        start: start,
        stop: stop,
        state: function () {
          if (selectedCompany || selectedUserGroup) {
            return 'state-dedicated';
          }

          return 'state-disabled';
        }(),
        initialStart: start,
        firstSavedStart: start,
        userGroup: selectedUserGroup
      };
      var window = this.store.createRecord('timeWindow', windowData);
      window.save().then(function (savedWindow) {
        self.send('onPoll');
        self.send('removePopupDialog');

        try {
          var windows = self.get('windows');
          windows.pushObject(savedWindow);
        } catch (e) {
          console.error('nie udal sie push okna / ' + e);
        }

        self.setProperties({
          errors: [],
          scheduleClicked: false
        });
      }).catch(function (response) {
        self.setProperties({
          errors: response.errors,
          scheduleClicked: false
        });
      });
    },

    /**
     * jeżeli na rampie znajdują się tylko okna blokad, usuniemy je wwszystkie.
     * @param windows
     */
    unblockRamp: function unblockRamp(windows) {
      console.log('unblockRamp');
      var self = this;
      var onlyDisabled = true;
      windows.forEach(function (window) {
        if (window.get('state') !== 'state-disabled') {
          onlyDisabled = false;
        }
      }); // wywala blad ale dziala
      // http://stackoverflow.com/questions/15167703/how-to-delete-all-data-from-ember-data-store

      if (onlyDisabled) {
        windows.forEach(function (window) {
          self.send('removeBlockedWindow', window);
        });
      }
    },
    _scheduleEvents: function _scheduleEvents() {
      if (this.get('hdMode') && this.get('model.delta') === 1) {
        Ember.run.later(this, function () {
          this.scroll();
        }, 1000);
        this.activateScrolling();

        if (this.get('timeToShiftSchedule')) {
          this.activateDayChanging();
        }
      } else {
        this.deactivateScrolling();
        this.deactivateDayChanging();
      }
    },
    // eslint-disable-next-line ember/no-observers
    scheduleEvents: Ember.observer('hdMode', 'model.delta', function () {
      Ember.run.once(this, '_scheduleEvents');
    }),
    activateScrolling: function activateScrolling() {
      console.debug("Aktywujemy scrollowanie co ".concat(this.get('scrollInterval'), " ms.."));
      this.set('scrollTimer', this.schedule(this.scroll));
    },
    deactivateScrolling: function deactivateScrolling() {
      console.debug('Wyłączamy scrollowanie..');
      Ember.run.cancel(this.get('scrollTimer'));
    },
    scrollInterval: Ember.computed(function () {
      return 60000;
    }).readOnly(),
    schedule: function schedule(f) {
      if (!this.get('hdMode') || this.get('model.delta') !== 1) {
        return;
      }

      return Ember.run.later(this, function () {
        f.apply(this);
        this.set('scrollTimer', this.schedule(f));
      }, this.get('scrollInterval'));
    },
    scroll: function scroll() {
      var now = moment();
      var format = 'DD.MM.YYYY';
      var currentScheduleDate = this.get('scheduleStartDate').format(format);

      if (currentScheduleDate !== now.format(format)) {
        console.debug('Harmonogram jest otwarty na innym dniu - nie scrollujemy do aktualnej godziny');
        return;
      }

      var h = now.format('HH');
      var m = now.format('mm');
      var roundedM = Math.floor(parseInt(m) / 15) * 15 % 60;
      var div = $("div[data-schedule-hour='".concat(h, "'] div[data-schedule-minute='").concat(roundedM, "']")).first(); // Jeżeli slot godzinny nie będzie podzielony na części (np. 15-min.), musimy wyszukiwać miejsce jedynie wg godziny.

      if (!div.length) {
        div = $("div[data-schedule-hour='".concat(h, "']")).first();
      } // Jeżeli nadal nie znajdziemy odpowiedniego elementu -- oznacza to, że magazyn nie pracuje w danej godzinie


      if (!div.length) {
        return;
      }

      $('html, body').animate({
        scrollTop: div.offset().top - 200
      }, 'slow');
    },
    activateDayChanging: function activateDayChanging() {
      if (this.get('dayChangeTimer')) {
        return;
      }

      console.debug('Aktywujemy zmienianie się dnia w widoku HD..');
      this.set('dayChangeTimer', this.scheduleDayChanging(this.changeDay));
    },
    deactivateDayChanging: function deactivateDayChanging() {
      console.debug('Wyłączamy zmienianie się dnia w widoku HD..');
      Ember.run.cancel(this.get('dayChangeTimer'));
    },
    timeToShiftSchedule: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('HD_MODE_NEXT_DAY_SHIFT_TIME');
    }),
    findDayChangeInterval: function findDayChangeInterval() {
      var settingValue = this.get('timeToShiftSchedule');
      var settingHours = settingValue.split(':')[0];
      var settingMinutes = settingValue.split(':')[1];
      var settingTime = moment().set({
        h: settingHours,
        m: settingMinutes,
        s: 0
      });

      if (settingTime.isBefore()) {
        settingTime.add(1, 'd');
      }

      var now = moment();
      return settingTime.diff(now);
    },
    scheduleDayChanging: function scheduleDayChanging(f) {
      var interval = this.findDayChangeInterval();
      return Ember.run.later(this, function () {
        if (interval > 0) {
          f.apply(this);
        }

        this.scheduleDayChanging(f);
      }, interval);
    },
    changeDay: function changeDay() {
      this.scroll();
      this.send('shiftScheduleRight');
    },
    clockInterval: Ember.computed(function () {
      var pageRenderedAt = this.currentMoment;
      var reminderToNextQuadrant = 15 - pageRenderedAt.minute() % 15;
      return reminderToNextQuadrant * 3600;
    }),
    setClockInterval: function setClockInterval() {
      var pageRenderedAt = this.currentMoment;
      var reminderToNextQuadrant = 15 - pageRenderedAt.minute() % 15;
      this.set('clockInterval', reminderToNextQuadrant * 3600);
    },
    initializeScheduleClock: Ember.on('init', function () {
      console.debug('Initializing clock..');
      this.set('clockTimer', this.scheduleClock(this.get('setClock')));
    }),
    scheduleClock: function scheduleClock(f) {
      return Ember.run.later(this, function () {
        f.apply(this);
        this.setClockInterval();
        this.set('timer', this.scheduleClock(f));
      }, this.get('clockInterval'));
    },
    setClock: function setClock() {
      this.set('currentMoment', moment());
    },
    transitToDate: function transitToDate(dateAsString) {
      var warehouseId = this.get('warehouse.id');
      var delta = this.get('model.delta');
      this.transitionToRoute('warehouses.' + this.get('routeName'), warehouseId, dateAsString, delta);
    },
    adviceRestrictionsValidate: function adviceRestrictionsValidate(start, stop) {
      var transportTypeId = this.get('warehouseStepToHandle.transport.transportType.id');

      if (this.get('sessionAccount.currentUser').canDiscardAdviceRestrictions(transportTypeId)) {
        return true;
      }

      var stepToHandle = this.get('warehouseStepToHandle');

      if (stepToHandle.get('minAdviceDate') && start < stepToHandle.get('minAdviceDate') || stepToHandle.get('maxAdviceDate') && stop > stepToHandle.get('maxAdviceDate')) {
        var msg = this.get('intl').t('timeWindow.errors.time.outsideOfAdviceRange', {
          minAdviceDate: moment(stepToHandle.get('minAdviceDate')).format('DD.MM.YYYY HH:mm'),
          maxAdviceDate: moment(stepToHandle.get('maxAdviceDate')).format('DD.MM.YYYY HH:mm')
        });
        this.set('warningMessage', msg);
        return false;
      }

      return true;
    },
    showLegend: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('SCHEDULE_LEGEND_ENABLED') === 'true';
    }),
    findMaxPalletClass: Ember.computed('hasAccessToMaxPallet', 'hasAccessToMaxPalletPerRamp', function () {
      var hasAccessToMaxPallet = this.get('hasAccessToMaxPallet');
      var hasAccessToMaxPalletPerRamp = this.get('hasAccessToMaxPalletPerRamp');

      if (hasAccessToMaxPallet && hasAccessToMaxPalletPerRamp) {
        return 'double-max-pallets';
      } else if (hasAccessToMaxPallet || hasAccessToMaxPalletPerRamp) {
        return 'max-pallets';
      } else {
        return '';
      }
    }),
    findWindowSize: function findWindowSize(stepWithoutWindow, transport) {
      if (!this.get('editedWindow.id')) {
        return this.getWindowSize(stepWithoutWindow, transport);
      } else {
        // w przypadku edycji okna mamy już jego rozmiar, więc nie musimy nikogo pytać
        return new Promise(function (resolve) {
          return resolve({});
        });
      }
    },
    findAlertDuringRemovingWindow: function findAlertDuringRemovingWindow(window) {
      if (window.get('isDedicated')) {
        return this.get('intl').t('warehouse.confirmDeleteDedicateWindow', {
          company: window.get('company.name')
        });
      }

      var note = window.get('note');

      if (note) {
        return this.get('intl').t('warehouse.windowNoteRemoveWarning', {
          note: note
        });
      } else if (this.get('sessionAccount').getSettingValue('ADDITIONAL_CONFIRMATION_DURING_REMOVING_GRAY_WINDOW') === 'true') {
        return this.get('intl').t('warehouse.windowRemoveWarning');
      } else {
        return null;
      }
    },
    onPollWrapper: function onPollWrapper() {
      this.send('onPoll');
    },
    shouldLegendHaveFixedPosition: Ember.computed('model', function () {
      return this.get('sessionAccount').getSettingValue('SET_SCHEDULE_LEGEND_TO_FIXED_POSITION') === 'true';
    }),
    actions: {
      closeMultipleTimeWindowsModal: function closeMultipleTimeWindowsModal() {
        this.closeMultipleTimeWindowsModal();
      },
      dragWindowAcrossScheduleDays: function dragWindowAcrossScheduleDays(step, days, target) {
        this.dragWindowAcrossScheduleDays(step, days, target);
      },
      switchOffHD: function switchOffHD(warehouseId, date, delta) {
        window.scrollTo(0, 0);
        Ember.run.debounce({
          schedule: this
        }, this.recalculateTimeWindowsDescriptionPosition, 0);
        this.transitionToRoute('warehouses.schedule', warehouseId, date, delta);
      },
      poll: function poll() {
        this.send('onPoll');
      },

      /** @namespace json.timeWindowIds */
      onPoll: function onPoll() {
        var socket = this.get('websocket.isReconnecting') ? null : this.get('websocket').getSocket();
        var params = {
          warehouseId: this.get('warehouse.id'),
          currentRampsIds: this.get('rampsIds')
        };
        var self = this;

        if (socket && socket.connected) {
          this.get('schedulePollingService').useSockets(params.warehouseId, self);
        } else {
          this.get('schedulePollingService').usePolling(params, self);
        }
      },
      onDraggableStop: function onDraggableStop(event) {
        var _this4 = this;

        var Window = Ember.Object.extend({
          // eslint-disable-next-line ember/require-return-from-computed
          model: Ember.computed(function () {}),
          isLock: Ember.computed(function () {
            return false;
          }),
          currentLengthInMS: Ember.computed('model', function () {
            return this.get('model.stop') - this.get('model.start');
          })
        });
        var self = this;
        var target = $(event.target);
        var windowId = target.data('schedule-window');
        var left = target.offset().left - $(document).scrollLeft();
        var top = target.offset().top - $(document).scrollTop();
        var bottom = target.height() + top;
        var isSupplement = target.data('is-supplement');
        target.removeClass('noclick');
        this.set('inProgress', true);
        var windowModel = this.store.peekRecord('timeWindow', windowId);

        if (windowModel.get('isLockedForEditing')) {
          console.log('Okno jest już w trakcie edycji - nie można na razie wykonywać na nim dodatkowych operacji!');
          return;
        }

        var sourceWindow = Window.create({
          model: windowModel,
          oldStart: windowModel.get('start'),
          oldStop: windowModel.get('stop'),
          oldRamp: windowModel.get('ramp')
        });
        var minutesDiv, $minutesDiv, newStart, newStop;

        if (!isSupplement) {
          minutesDiv = this.findMinutesDiv(left + 10, top + 2);
          $minutesDiv = $(minutesDiv);
          newStart = this.getDate($minutesDiv);
          newStop = moment(newStart).add(sourceWindow.get('currentLengthInMS'), 'milliseconds').toDate();
        }

        if (!minutesDiv) {
          minutesDiv = this.findMinutesDiv(left + 10, bottom + 2);
          $minutesDiv = $(minutesDiv);
          newStop = this.getDate($minutesDiv);
          newStart = moment(newStop).subtract(sourceWindow.get('currentLengthInMS'), 'milliseconds').toDate();
        }

        if (!minutesDiv) {
          console.debug('Użytkownik próbował postawić okno poza obszarem harmonogramu; przerywamy operację.');
          self.restorePreviousWindowPlace(sourceWindow);
          return;
        }

        var rampId = this.getRampId($minutesDiv);
        var ramp = self.store.peekRecord('ramp', rampId);

        if (newStart.getTime() === sourceWindow.get('model.start').getTime() && newStop.getTime() === sourceWindow.get('model.stop').getTime() && sourceWindow.get('model.ramp.id') === rampId.toString()) {
          console.log('brak zmian; przerywamy');
          sourceWindow.get('model').notifyPropertyChange('start');
          return;
        } // eslint-disable-next-line array-callback-return


        var targetWindowCandidateList = this.get('windows').filter(function (w) {
          if (parseInt(w.get('id')) !== parseInt(sourceWindow.get('model.id')) && parseInt(w.get('ramp').get('id')) === parseInt(rampId) && !(w.get('stop') <= newStart || w.get('start') >= newStop)) {
            return w;
          }
        });
        var targetWindowCandidate;

        if (targetWindowCandidateList.length > 1) {
          targetWindowCandidate = targetWindowCandidateList.filter(function (w) {
            return !w.get('isDedicated');
          })[0];
        } else {
          targetWindowCandidate = targetWindowCandidateList[0];
        }

        var targetWindow = Window.create({
          model: targetWindowCandidate
        });
        targetWindow.setProperties({
          oldStart: targetWindow.get('model.start'),
          oldStop: targetWindow.get('model.stop'),
          oldRamp: targetWindow.get('model.ramp')
        });
        sourceWindow.setProperties({
          oldStart: windowModel.get('start'),
          oldStop: windowModel.get('stop'),
          oldRamp: windowModel.get('ramp')
        });
        sourceWindow.get('model').setProperties({
          start: newStart,
          stop: newStop,
          ramp: ramp
        });
        console.log("Pr\xF3ba przesuni\u0119cia okna ".concat(windowId, ":\n                    [").concat(sourceWindow.get('oldStart'), ", ").concat(sourceWindow.get('oldRamp.name'), " (#").concat(sourceWindow.get('oldRamp.id'), ")]\n                    -> [").concat(newStart, ", ").concat(ramp.get('name'), " (#").concat(ramp.get('id'), ")]"));
        var warehouseOpenTime = this.get('warehouse').getStartOnDay(this.get('scheduleStartDate'));
        var warehouseCloseTime = this.get('warehouse').getEndOnDay(this.get('scheduleEndDate'));

        if (targetWindow.get('model') && (this.windowsSwappingBlocked(sourceWindow, targetWindow) || newStart < warehouseOpenTime || newStop > warehouseCloseTime)) {
          var message = this.get('intl').t('ramp.errors.cannotMoveWindowException');
          this.send('showPopupDialog', 'dialogs.message-popup-dialog', sourceWindow.set('detail', message));
          this.restorePreviousWindowPlace(sourceWindow);
          this.restorePreviousWindowPlace(targetWindow);
          return false;
        }

        if (this.get('windowSwapping')) {
          console.debug('Jesteśmy w trakcie zamiany okien. Przerywamy operację przesuwania.');
          return;
        }

        windowModel.lockForEditing();
        var transport = sourceWindow.get('model.transport');

        if (transport && transport.get('id')) {
          sourceWindow.set('model.updateAllFuturePeriodicWindows', this.updateAllFuturePeriodicTransports(transport));
        }

        sourceWindow.get('model').save().then(function (w) {
          self.send('removePopupDialog');
          self.setProperties({
            inProgress: false,
            errors: []
          });

          if (w.get('transport.id')) {
            w.get('transport').then(function (t) {
              t.reload().then(function () {
                self.unloadDeletedRecords('transport', t.id, 'checkpoint');
                windowModel.unlockForEditing();
                console.debug("Zako\u0144czyli\u015Bmy aktualizacj\u0119 okna ".concat(windowId, " z transportem ").concat(t.get('logString'), "."));
              });
            });
          } else {
            windowModel.unlockForEditing();
            console.debug("Zako\u0144czyli\u015Bmy aktualizacj\u0119 okna ".concat(windowId, "."));
          }

          _this4.send('onPoll');
        }).catch(function (response) {
          _this4.restorePreviousWindowPlace(sourceWindow);

          self.setProperties({
            inProgress: false,
            errors: response.errors
          });
          windowModel.unlockForEditing();
        });
      },
      createWindow: function createWindow(start, stop, rampId, initialStart) {
        if (this.get('scheduleClicked')) {
          return;
        }

        this.set('scheduleClicked', true);

        if (this.get('transportToHandle')) {
          this.send('handleClickWithTransport', start, stop, rampId, initialStart);
        } else {
          this.send('createBlockedWindow', start, stop, rampId);
        }
      },
      handleClickDedicatedWindow: function handleClickDedicatedWindow(start, stop, rampId, initialStart) {
        if (this.get('scheduleClicked')) {
          return;
        }

        this.set('dedicatedTimeWindowToHandle', '');
        var ramp = this.store.peekRecord('ramp', rampId);

        if (this.get('editedWindow.id')) {
          var editedWindow = this.get('editedWindow');
          var editedWindowModel = this.store.peekRecord('timeWindow', editedWindow.get('id'));
          console.log("Edited window: [".concat(editedWindow.get('formattedTime'), "]"));
          editedWindow.setProperties({
            oldStart: editedWindow.get('start'),
            oldStop: editedWindow.get('stop'),
            oldRamp: editedWindow.get('ramp'),
            initialStart: initialStart.toDate(),
            start: start.toDate(),
            stop: stop.toDate(),
            ramp: ramp
          });
          editedWindowModel.unlockForEditing();
          editedWindow.save().then(function () {
            console.debug("Zapisano okno czasowe ".concat(editedWindow.id, " w zakresie ").concat(start, " - ").concat(stop));
          }).catch(function (e) {
            console.log(e);
          });
        }
      },
      handleClickWithTransport: function handleClickWithTransport(start, stop, rampId, initialStart) {
        var _this5 = this;

        if (this.get('scheduleClicked')) {
          return;
        }

        this.set('scheduleClicked', true);
        var self = this;
        this.set('warningMessage', null);
        var transport = this.get('transportToHandle');
        console.debug("Trying to handle click on ".concat(start, " with transport ").concat(transport.get('id'), ".."));
        var ramp = this.store.peekRecord('ramp', rampId);

        if (!this.transportCanBeCreatedInThisWarehouse(transport, ramp)) {
          var msg = this.get('intl').t('timeWindow.errors.wrongWarehouse');
          this.set('warningMessage', msg);
          return;
        }

        var stepWithoutWindow = transport.get('firstWarehouseStepWithoutWindow') ? transport.get('firstWarehouseStepWithoutWindow') : this.get('stepWithoutWindowCopy'); // Tworzymy kopię na potrzeby obsługi błędu w przypadku stawiania okna bez awizacji

        this.set('stepWithoutWindowCopy', stepWithoutWindow);
        this.findWindowSize(stepWithoutWindow, transport).then(function (response) {
          var estimatedLengthInMinutes = response.estimatedWindowLengthInMinutes;

          if (estimatedLengthInMinutes) {
            stop = moment(start).add(estimatedLengthInMinutes, 'minutes').toDate();
          }

          if (!_this5.adviceRestrictionsValidate(start, stop)) {
            _this5.set('scheduleClicked', false);

            return;
          }

          if (_this5.get('editedWindow.id')) {
            var editedWindow = _this5.get('editedWindow');

            var editedWindowModel = _this5.store.peekRecord('timeWindow', editedWindow.get('id'));

            editedWindow.setProperties({
              oldStart: editedWindow.get('start'),
              oldStop: editedWindow.get('stop'),
              oldRamp: editedWindow.get('ramp'),
              initialStart: initialStart.toDate(),
              start: start.toDate(),
              stop: stop.toDate(),
              ramp: ramp
            });
            editedWindowModel.unlockForEditing();
            transport.set('editedTimeWindow', editedWindow);

            _this5.send('processTransportWindowCreation', transport, start);
          } else {
            var tmpWindow = self.store.createRecord('timeWindow', {
              start: start,
              stop: stop,
              ramp: ramp,
              initialStart: initialStart,
              step: stepWithoutWindow
            });
            stepWithoutWindow.set('timeWindow', tmpWindow);
            transport.get('timeWindows').pushObject(tmpWindow);
            transport.set('editedTimeWindow', tmpWindow);
            self.send('processTransportWindowCreation', transport, start);
          }
        });
      },
      processTransportWindowCreation: function processTransportWindowCreation(transport, start) {
        var self = this;
        var nextStepWithoutWindow = transport.get('firstWarehouseStepWithoutWindow');
        var nextStepWithChangedWarehouse = transport.get('firstChangedWarehouseStep');

        if (nextStepWithoutWindow || nextStepWithChangedWarehouse) {
          var warehouse = nextStepWithoutWindow ? nextStepWithoutWindow.get('warehouse') : nextStepWithChangedWarehouse.get('warehouse');
          var minAdviceDateOrToday = nextStepWithoutWindow.get('minAdviceDate') !== null ? moment(nextStepWithoutWindow.get('minAdviceDate')).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
          var scheduleDateString = nextStepWithoutWindow ? minAdviceDateOrToday : moment(new Date()).format('YYYY-MM-DD');
          var stepToHandle = nextStepWithoutWindow || nextStepWithChangedWarehouse;
          self.set('warehouseStepToHandle', stepToHandle);
          self.set('scheduleClicked', false); // jeśli nie jesteśmy w magazynie lub jesteśmy w innym magazynie niż ten, w którym chcemy postawić okno - wykonujemy przekierowanie

          if (this.get('routeName') !== 'schedule' || this.get('warehouse.id') !== warehouse.get('id')) {
            console.log("Transport ".concat(transport.get('id'), " wymaga wybrania kolejnego okna czasowego - przenosimy si\u0119 do magazynu ").concat(warehouse.get('name'), ".."));

            if (transport.get('stepsWithWarehouse').length > 1) {
              self.set('showMultipleTimeWindowsModal', true);
            }

            return self.transitionToRoute('warehouses.schedule', warehouse.get('id'), scheduleDateString, '1');
          }
        }

        var transportToHandle = self.get('transportToHandle');
        var transportToHandleId = self.get('transportToHandle.id');

        if (transportToHandleId) {
          self.initializeCustomFields(transportToHandle).then(function () {
            var transport = self.get('transportToHandle.content') ? self.get('transportToHandle.content') : self.get('transportToHandle');
            self.get('ajaxService').send("/transports/".concat(transportToHandleId, "/validate"), 'PUT', transport.serialize(), true).then(function (response) {
              var errors = [];

              if (response.errors) {
                errors = response.errors.uniq();
              }

              var responseMeta = errors.get('firstObject.meta');

              if (responseMeta) {
                var exceptionInformation = Ember.ObjectProxy.create({
                  initialStart: responseMeta.initialStart,
                  windowSizeInMinutes: responseMeta.windowSizeInMinutes,
                  rampName: responseMeta.rampName,
                  whId: responseMeta.warehouseId
                });
                transport.set('exceptionInformation', exceptionInformation);
              }

              self.set('scheduleClicked', false);

              if (transport.get('transportType.selectionOfWindowBeforeAdvice')) {
                if (transport.get('stepsWithWarehouse').length > 1) {
                  self.send('showModal', 'time-windows-summary-modal', {
                    transportToHandle: transportToHandle
                  });
                }

                if (errors.length > 0) {
                  self.set('errors', errors[0]);

                  if (errors[0].meta.exceptionType !== 'WINDOWS_OVERLAP_EXCEPTION') {
                    transport.get('timeWindows').forEach(function (timeWindow) {
                      timeWindow.rollbackAttributes();
                    });
                  }

                  self.send('openCreateAdviceModal', errors, transport);
                  return;
                }

                self.send('createTimeWindow', transport);
              } else {
                self.send('openCreateAdviceModal', errors, transport);
              }
            });
          });
        } else {
          self.send('createAdvice');
        }
      },
      createTimeWindow: function createTimeWindow(transport) {
        var self = this;
        transport.validate();

        if (transport.get('hasErrors')) {
          return;
        }

        transport.save().then(function () {
          console.log("Window for transport ".concat(transport.get('id'), " has been saved."));
          self.unloadRedundantTimeWindows(transport);
          transport.reload().then(function () {
            return self.handleTransportReload(transport, self);
          });
          var info = self.get('intl').t('warehouse.adviceHasBeenSavedInfo') + moment(transport.get('earliestTimeWindowStart')).format('HH:mm');
          self.set('info', info);
          self.set('warehouseStepToHandle', null);
          self.set('model.errors', []);
        }).catch(function (response) {
          var errors = response.errors;
          self.set('model.errors', errors);
        });
      },
      openCreateAdviceModal: function openCreateAdviceModal(errors, transport) {
        var self = this;
        transport.get('advice').then(function (a) {
          if (!a && !transport.get('transportType.selectionOfWindowBeforeAdvice')) {
            self.send('setAdviceForTransport', transport);
          }

          var hasErrors = errors.length >= 1;
          var exceptionType = null;

          if (hasErrors) {
            if (errors[0].meta) {
              exceptionType = errors[0].meta.exceptionType;
            }
          }

          self.send('showModal', 'advice.modals.create-advice', {
            transport: transport,
            errors: errors || [],
            showSearchSpotsButton: self.EXCEPTIONS_THAT_ALLOWS_SPOTS_SEARCHING.includes(exceptionType)
          });
        });
      },
      setAdviceForTransport: function setAdviceForTransport(transport) {
        var settingValue = this.get('sessionAccount').getSettingValue('SHOW_TERMS_OF_CONDITIONS_ACCEPTED');
        var advice = this.get('store').createRecord('advice', {
          requiresAcceptanceOfTermsOfCondition: settingValue === 'true'
        });
        transport.set('advice', advice);
      },

      /**
       * metoda wykonywana po kliknięciu w nagłówek rampy.
       * Jeżeli na rampie nie ma okien, utworzymy blokade na całym dniu.
       * W przeciwnym razie, jeżeli są tylko okna blokady, usuniemy je, por. {@link unblockRamp}.
       * @param time
       * @param rampId
       */
      toggleWholeRampWindow: function toggleWholeRampWindow(time, rampId) {
        console.log('toggleWholeRampWindow');
        var self = this;

        if (this.get('sessionAccount').hasRole('ROLE_BLOCK_RAMPS')) {
          var start = this.get('warehouse').getStartOnDay(time);
          var stop = this.get('warehouse').getEndOnDay(time);
          var windowsOnRamp = self.get('windows').filter(function (window) {
            return window.get('ramp.id') === rampId && window.get('start') >= start && window.get('stop') <= stop && !window.get('deleted') && !window.get('isAutoBlockade');
          });

          if (windowsOnRamp.get('firstObject') == null) {
            self.storeDisabledWindow(start, stop, rampId);
          } else {
            self.unblockRamp(windowsOnRamp);
          }
        } else {
          console.log('without producer role: ramp click');
        }
      },
      createAdvice: function createAdvice() {
        var self = this;
        var transport = this.get('transportToSave');
        transport.set('advice', self.removeWhiteSpacesAndSetUpperCase(transport.get('advice')));
        transport.get('customFields').filterBy('definition.isNumberType').forEach(function (pair) {
          if (pair.get('value')) {
            pair.set('value', _numberParsers.default.getParsableNumber(pair.get('value')));
          }
        });
        var principalCompany = this.store.peekRecord('company', localStorage.getItem('principalId'));

        if (!transport.get('carrierCompany.name')) {
          transport.set('carrierCompany', null);
        }

        transport.set('principalCompany', principalCompany);
        transport.save().then(function () {
          var transportId = transport.id;
          console.log("We successfully saved transport ".concat(transport.get('logString'), "!"));
          self.set('scheduleClicked', false);
          self.send('removePopupDialog');
          var token = self.get('sessionAccount').get('token');

          if (self.attachments) {
            console.debug("Saving files for ".concat(transportId));
            self.uploadAttachment(transportId, token);
          }

          var steps = transport.get('steps');
          var stepsArray = steps.toArray();
          stepsArray.forEach(function (s) {
            if (s === undefined || !s.id) {
              steps.removeObject(s);

              if (s !== undefined) {
                console.debug("Unloading redundant step for newly saved transport ".concat(transportId, ".."));
                s.unloadRecord();
              }
            }
          });
          var customFields = transport.get('customFields');
          var customFieldsArray = customFields.toArray(); // eslint-disable-next-line array-callback-return

          customFieldsArray.map(function (cf) {
            if (cf === undefined || !cf.id) {
              customFields.removeObject(cf);

              if (cf !== undefined) {
                console.debug("Unloading redundant custom field for newly saved transport ".concat(transportId, ".."));
                cf.unloadRecord();
              }
            }
          });
          transport.get('timeWindows').then(function (timeWindows) {
            var timeWindowsCopy = timeWindows.slice(0);
            timeWindowsCopy.forEach(function (tw) {
              if (tw !== undefined && !tw.get('id')) {
                timeWindows.removeObject(tw);
                console.debug("Unloading redundant time window for newly saved transport ".concat(transportId, ".."));
                tw.unloadRecord();
              }
            });
          });
          transport.get('indexes').then(function (indexes) {
            var indexesCopy = indexes.slice(0);
            indexesCopy.forEach(function (index) {
              if (index !== undefined && !index.get('id')) {
                indexes.removeObject(index);
                console.debug("Unloading redundant index for newly saved transport ".concat(transportId, ".."));
                index.unloadRecord();
              }
            });
          });
          transport.get('courierPackages').then(function (cps) {
            var cpCopy = cps.slice(0);
            cpCopy.forEach(function (cp) {
              if (cp !== undefined && !cp.get('id')) {
                cps.removeObject(cp);
                console.debug("Unloading redundant courierPackages for newly saved transport ".concat(transportId, ".."));
                cp.unloadRecord();
              }
            });
          });
          transport.get('transportType').set('forcedPollRequestTime', new Date().getTime());
          self.send('onPoll');
          var createTransportController = self.get('createTransport');
          createTransportController.setProperties({
            model: null,
            errors: [],
            attachments: []
          });
          self.send('hideModal');
          self.setProperties({
            info: self.get('intl').t('warehouse.adviceHasBeenSavedInfo') + moment(transport.get('earliestTimeWindowStart')).format('HH:mm'),
            warningMessage: null,
            attachments: [],
            transportToSave: null,
            warehouseStepToHandle: null,
            scheduleClicked: false
          });
        }).catch(function (response) {
          var errors = response.errors;
          var responseMeta = errors[0].meta;
          var exceptionType = responseMeta ? responseMeta.exceptionType : null;

          if (responseMeta) {
            var exceptionInformation = Ember.ObjectProxy.create({
              initialStart: responseMeta.initialStart,
              windowSizeInMinutes: responseMeta.windowSizeInMinutes,
              rampName: responseMeta.rampName
            });
            transport.set('exceptionInformation', exceptionInformation);
          }

          self.set('scheduleClicked', false);
          self.get('createTransport').setProperties({
            errors: errors,
            showSearchSpotsButton: self.EXCEPTIONS_THAT_ALLOWS_SPOTS_SEARCHING.includes(exceptionType)
          });
          transport.get('steps').filterBy('timeWindow.start').forEach(function (s) {
            s.set('timeWindow', null);
          });
          transport.get('timeWindows').filterBy('id', null).forEach(function (tw) {
            tw.setProperties({
              transport: null,
              step: null,
              deleted: null
            });
          });
          self.send('showTransportDetails', transport);
        });
      },
      showTransportDetails: function showTransportDetails(transport) {
        console.debug("Otwieramy okienko ze szczeg\xF3\u0142ami transportu ".concat(transport.get('logString'), " z poziomu harmonogramu..."));
        this.set('openTransportId', transport.get('id'));
        this.set('editedTransportType', transport.get('transportType'));
        this.send('showModal', 'transports.modals.create-transport', transport);
      },
      showLoadingModal: function showLoadingModal() {
        this.send('showModal', 'transports.modals.loading-data');
      },
      createBlockedWindow: function createBlockedWindow(start, stop, rampId) {
        this.set('scheduleClicked', true);
        var isDedicatedWindow = !!this.get('company');

        if (isDedicatedWindow && !this.get('sessionAccount').hasRole('ROLE_MANAGE_DEDICATED_WINDOWS')) {
          console.log('User tried to save a dedicated window, but does not have an authority to do that.');
          this.set('scheduleClicked', false);
          return;
        } else if (!isDedicatedWindow && !this.get('sessionAccount').hasRole('ROLE_BLOCK_RAMPS')) {
          console.log('User tried to save a blockade, but does not have an authority to do that.');
          this.set('scheduleClicked', false);
          return;
        }

        var laterWindows = this.get('windows').filter(function (w) {
          return parseInt(w.get('ramp.id')) === rampId && w.get('start') > start;
        }); // System will try to fit a blockade window if there is not enough space to save it with default length,
        // i.e. user tries to save a 60-min. window at 14:00, but there is already other window at 14:30 -
        // in this case, instead of throwing an error, system will save a 30-min. window.

        if (!this.get('warehouse.timeWindowMustOccupyEntireRamp') && laterWindows.length) {
          var laterWindow = laterWindows.sortBy('start').get('firstObject');
          var laterWindowStart = laterWindow.get('start');
          stop = laterWindowStart < stop ? laterWindowStart : stop;
        } // [A-10607] Gdy okno blokady ustawiamy na koniec dnia magazynu, w przypadku magazynów działających przez 24h
        // odejmujemy sekundę, wtedy okno, które zapiszemy będzie kończyć się tego samego dnia o godzinie 23:59:59
        // (a nie dnia następnego o 00:00:00)


        if (!(start instanceof moment)) {
          start = moment(start);
        }

        if (!(stop instanceof moment)) {
          stop = moment(stop);
        }

        if (start.get('date') < stop.get('date') && stop.get('hour') === 0) {
          stop.subtract(1, 'seconds');
        }

        this.storeDisabledWindow(start, stop, rampId);
      },
      removeBlockedWindow: function removeBlockedWindow(window) {
        var isDedicatedWindow = window.get('isDedicated');

        if (isDedicatedWindow && !this.get('sessionAccount').hasRole('ROLE_MANAGE_DEDICATED_WINDOWS')) {
          console.log('User tried to remove a dedicated window, but does not have an authority to do that.');
          this.set('scheduleClicked', false);
          return;
        } else if (!isDedicatedWindow && !this.get('sessionAccount').hasRole('ROLE_BLOCK_RAMPS')) {
          console.log('User tried to remove a blockade, but does not have an authority to do that.');
          this.set('scheduleClicked', false);
          return;
        }

        var removingAlert = this.findAlertDuringRemovingWindow(window);

        if (removingAlert && !confirm(removingAlert)) {
          return;
        }

        this.send('destroyWindow', window);
      },
      destroyWindow: function destroyWindow(window) {
        var self = this;
        window.deleteRecord();
        window.save().then(function (w) {
          self.send('removePopupDialog');
          self.get('windows').removeObject(w);
          self.get('store').unloadRecord(window);
          self.set('errors', null);
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      },
      shiftScheduleLeft: function shiftScheduleLeft() {
        var days = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
        var newStartAsString = this.get('scheduleStartDate').subtract(days, 'days').format('YYYY-MM-DD');
        this.transitToDate(newStartAsString);
      },
      shiftScheduleRight: function shiftScheduleRight() {
        var days = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
        var newStartAsString = this.get('scheduleStartDate').add(days, 'days').format('YYYY-MM-DD');
        this.transitToDate(newStartAsString);
      },
      today: function today() {
        this.transitToDate(moment().startOf('day').format('YYYY-MM-DD'));
      },
      transitToDelta: function transitToDelta() {
        var delta = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
        var warehouseId = this.get('warehouse.id');
        this.get('applicationController').setLocalDelta(delta);
        var dateString = this.get('scheduleStartDate').format('YYYY-MM-DD');
        this.transitionToRoute('warehouses.' + this.get('routeName'), warehouseId, dateString, delta);
      },
      showTransportToSave: function showTransportToSave() {
        (0, _jquery.default)('.modal').show();
        $('body').addClass('modal-open');
      },
      openNoteWindow: function openNoteWindow(window) {
        this.send('showModal', 'time-window.modals.window-note', window);
      },
      openIndexesDetailsWindow: function openIndexesDetailsWindow(transport) {
        this.send('showModal', 'indexes.modals.indexes-details', transport);
      },
      cancelTransportCreation: function cancelTransportCreation() {
        var self = this;
        var editedWindow = this.get('editedWindow');

        if (editedWindow) {
          var editedWindowModel = this.store.peekRecord('timeWindow', editedWindow.get('id'));
          editedWindowModel.unlockForEditing();

          if (editedWindow.content) {
            editedWindow.content.restorePlace();
          } else {
            if (this.get('dedicatedTimeWindowToHandle')) {
              this.set('dedicatedTimeWindowToHandle', '');
            }

            editedWindow.restorePlace();
          }
        } else {
          self.handleStepsUpdate(this.get('transportToHandle'));
          self.removeUnsavedTimeWindows(this.get('transportToHandle'));
        }

        this.transportCombiner.decombineAllRelatedTransports(this.get('transportToHandle'));
        this.setProperties({
          warningMessage: null,
          transportToSave: null,
          warehouseStepToHandle: null
        });
      },
      setSelectedDateString: function setSelectedDateString(date) {
        this.set('selectedDateString', moment(date[0]).local().format('YYYY-MM-DD'));
      },
      setWarehouse: function setWarehouse(warehouse) {
        var chosenWarehouse = this.get('store').peekRecord('warehouse', warehouse.get('id'));
        this.set('model.warehouse', chosenWarehouse);

        if (this.get('warehouseStepToHandle')) {
          this.set('warehouseStepToHandle.warehouse', chosenWarehouse);
        }
      }
    }
  });

  _exports.default = _default;
});