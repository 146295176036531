define("apollo/pods/contractors-group-type/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = _emberData.default.Model.extend({
    intl: service(),
    contractorsGroups: _emberData.default.hasMany('contractorsGroup', {
      async: true
    }),
    name: _emberData.default.attr('string'),
    idx: _emberData.default.attr('number'),
    lastUpdated: _emberData.default.attr('isodate'),
    requiresContractorsGroup: _emberData.default.attr('boolean'),
    canSelectOnlyOneContractorsGroup: _emberData.default.attr('boolean'),
    nameInAppropriateLanguage: Ember.computed('name', function () {
      var self = this;
      return this.get('intl').t('contractors-group-type.' + self.get('name'));
    }).readOnly()
  });

  _exports.default = _default;
});