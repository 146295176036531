define("apollo/pods/components/task-custom-modals/mowi-conditional-tasks-modal/controller", ["exports", "apollo/mixins/transports-mixin", "apollo/mixins/advice-utils-mixin"], function (_exports, _transportsMixin, _adviceUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_transportsMixin.default, _adviceUtilsMixin.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    ajaxService: Ember.inject.service('ajax-service'),
    errors: Ember.A(),
    getYesNoFields: Ember.computed('', function () {
      return [this.get('intl').t('common.yes'), this.get('intl').t('common.no')];
    }).readOnly(),
    selectedZone: Ember.computed('model.nextZone', function () {
      return this.get('model.nextZone') ? this.get('intl').t('common.yes') : this.get('intl').t('common.no');
    }),
    checkpoint: Ember.computed('model.id', function () {
      return this.get('model');
    }),
    step: Ember.computed('checkpoint.step', function () {
      return this.get('checkpoint.step.content');
    }),
    transport: Ember.computed('checkpoint.transport.id', function () {
      var transportId = this.get('checkpoint.transport.id');
      return this.get('store').peekRecord('transport', transportId);
    }),
    title: Ember.computed('checkpoint.{id,name}', function () {
      return "".concat(this.get('intl').t('checkpoints.confirmStatus'), ": ").concat(this.get('checkpoint.name'));
    }),
    alertText: Ember.computed('checkpoint.name', function () {
      return "Czy na pewno chcesz zablokowa\u0107 status: ".concat(this.get('checkpoint.name'));
    }),
    isDuringConfirmation: Ember.computed('checkpoint.actionType', function () {
      return this.get('checkpoint.actionType') === this.get('checkpoint.ACTIONS').CONFIRM;
    }),
    firstUnconfirmedCheckpoint: Ember.computed('transport.firstUnconfirmedCheckpoint', function () {
      return this.get('transport.firstUnconfirmedCheckpoint');
    }),
    sortedSubTasks: Ember.computed('checkpoint.task.{masterTask.subTasks.@each.idxInGroup,subTasks.@each.idxInGroup}', function () {
      if (this.get('checkpoint.task.subTasks.length') === 0 && this.get('checkpoint.task.masterTask')) {
        return this.get('checkpoint.task.masterTask.subTasks').filterBy('enabled').sortBy('idxInGroup');
      }

      return this.get('checkpoint.task.subTasks').filterBy('enabled').sortBy('idxInGroup');
    }),
    buttonAction: Ember.computed('model.nextZone', function () {
      if (this.get('model.nextZone')) {
        return 'confirmCheckpoint';
      }

      return 'confirmNoSubtasks';
    }),
    actions: {
      confirmCheckpoint: function confirmCheckpoint(checkpoint) {
        var _this = this;

        var self = this;
        var step = this.get('step');
        var location = this.get('model.selectedWarehouseLocation');

        if (!step || !location) {
          var msg = this.get('intl').t('errors.blankSelect');
          this.set('errors', [msg]);
          return console.log("WarehouseLocation :: ".concat(msg));
        }

        var whl = {
          warehouseLocation: location.wlId,
          step: step.get('id')
        };
        this.get('ajaxService').send('/steps/saveStepWarehouseLocation/', 'POST', whl, true).then(function () {
          _this.set('errors', []);

          checkpoint.set('isDuringAction', true);
          self.send('confirm', checkpoint);
          self.reset(checkpoint);
        }).catch(function () {
          console.error('Błąd przy dodawaniu lokalizacji !');
          self.reset(checkpoint);
        });
      },
      confirmNoSubtasks: function confirmNoSubtasks(checkpoint) {
        checkpoint.skipGeneratingSubtasks = true;
        this.send('confirm', checkpoint);
        this.reset(checkpoint);
      },
      cancel: function cancel(checkpoint) {
        this.reset(checkpoint);
      },
      setNextZoneFlag: function setNextZoneFlag(nextZoneFlag) {
        if (nextZoneFlag === this.get('intl').t('common.yes')) {
          this.set('model.nextZone', true);
        } else {
          this.set('model.nextZone', false);
        }
      }
    },
    reset: function reset(checkpoint) {
      checkpoint.set('isDuringAction', false);
      this.send('hideModal');
    }
  });

  _exports.default = _default;
});