define("apollo/helpers/checkpoint-confirmator-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params, nameArgs) {
      var checkpoint = nameArgs.checkpoint;
      var confirmatorName = checkpoint.get('confirmator.name');
      var confirmatorRoleGroupName = checkpoint.get('confirmator.roleGroup').get('name'); // W przypadku statusu potwierdzanego przez awizację mailową, podajemy adres e-mail użytkownik przypisanego do awizacji mailowej.
      // Zakładamy również, że bierzemy pod uwage ostatnio wysłaną awizacje, bo w momencie zatwierdzania statusu
      // ostatnia musi byc aktywna, a dzieki temu gdu transport będzie zakończony (wszystkie tokeny nieaktwne) bedzie to rownie dzialac
      // Na razie mamy tylko 2 rodzaje EmailAdvice, po zadaniu A-10351 można ew. dodać tu warunek na sprawdzanie nowej flagi dla CF_DATA

      if (confirmatorRoleGroupName === 'E-MAIL_ADVICE') {
        var emailAdviceEmails = '';

        if (checkpoint.get('task.shouldBeApprovedWithAdvice')) {
          emailAdviceEmails = checkpoint.get('transport').get('emailAdvices').filter(function (ea) {
            return ea && ea.get('type') === 'ADVICE_DATA';
          });
        } else {
          emailAdviceEmails = checkpoint.get('transport').get('emailAdvices').filter(function (ea) {
            return ea && ea.get('type') === 'CF_DATA';
          });
        }

        var emailAdviceEmail = emailAdviceEmails.sortBy('dateCreated').reverse().get('firstObject').get('email');
        return this.get('intl').t('checkpoints.confirmedMessageBy', {
          person: emailAdviceEmail
        });
      }

      if (confirmatorRoleGroupName === 'SUPER_USER' && checkpoint.get('task.canBeConfirmedWithSms')) {
        // Potwierdzenie statusu przez SMS - jako osobę potiwerdzającą przypisujemy super-admina danego pryncypała,
        // ale chcemy wyświetlić imię oraz nazwisko kierowcy
        return this.get('intl').t('checkpoints.confirmedMessageBy', {
          person: checkpoint.get('transport.advice.driverName')
        });
      }

      return this.get('intl').t('checkpoints.confirmedMessageBy', {
        person: confirmatorName
      });
    }
  });

  _exports.default = _default;
});