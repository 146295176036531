define("apollo/pods/components/transport-types/package-type-settings-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    packageTypes: Ember.computed(function () {
      return this.get('store').peekAll('packageType');
    }),
    actions: {
      save: function save() {
        var _this = this;

        var pttt = this.get('packageTypeTransportType');

        if (!pttt.validate()) {
          return;
        }

        pttt.save().then(function () {
          _this.set('successMessage', _this.get('intl').t('common.saveSuccessMessage'));
        }).catch(function (response) {
          _this.set('errors', response.errors);
        });
      },
      sendDelete: function sendDelete() {
        this.delete(this.get('packageTypeTransportType'));
      },
      sendCancel: function sendCancel() {
        this.cancel(this.get('packageTypeTransportType'));
      }
    }
  });

  _exports.default = _default;
});