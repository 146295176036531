define("apollo/pods/gates/create/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    model: function model() {
      var principalCompanyId = localStorage.getItem('principalId');
      var principalCompany = this.get('store').peekRecord('company', principalCompanyId);
      return this.get('store').createRecord('gate', {
        principalCompany: principalCompany
      });
    }
  });

  _exports.default = _default;
});