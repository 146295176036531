define("apollo/pods/assortment-group/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var _Ember = Ember,
      service = _Ember.inject.service;

  var _default = Model.extend(_modelValidator.default, {
    intl: service(),
    sessionAccount: service('session-account'),
    languageService: service('language-service'),
    transportTypes: _emberData.default.hasMany('transportType', {
      async: true
    }),

    /**
     * id typów transportu, które zostały zaznaczone podczas tworzenia, lub edycji.
     * Zmienna służy do wysłania danych na backend, nie przychodzi z serwera.
     */
    checkedTransportTypesIds: _emberData.default.attr(),
    warehouse: _emberData.default.belongsTo('warehouse'),
    namePl: _emberData.default.attr('string'),
    nameEn: _emberData.default.attr('string'),
    shortNamePl: _emberData.default.attr('string'),
    shortNameEn: _emberData.default.attr('string'),
    adviceFromTime: _emberData.default.attr('string'),
    adviceToTime: _emberData.default.attr('string'),
    enabled: _emberData.default.attr('boolean'),
    validations: {
      namePl: {
        custom: {
          validation: function validation(key, value) {
            return value && value.length >= 3;
          },
          message: 'minLength'
        }
      },
      nameEn: {
        custom: {
          validation: function validation(key, value) {
            return value && value.length >= 3;
          },
          message: 'minLength'
        }
      },
      shortNamePl: {
        presence: {
          message: 'blank'
        }
      },
      shortNameEn: {
        presence: {
          message: 'blank'
        }
      },
      adviceFromTime: {
        custom: {
          validation: function validation(key, value, model) {
            if (!model.get('adviceFromHours') || !model.get('adviceFromMinutes')) {
              return false;
            }

            var hour = Number(model.get('adviceFromHours'));
            var minute = Number(model.get('adviceFromMinutes'));

            if (minute % model.get('warehouse.timeWindowOffsetInterval') !== 0) {
              return false;
            }

            return hour <= model.get('warehouse.workEndHour') && hour >= model.get('warehouse.workStartHour') && minute >= 0 && minute <= 60;
          },
          message: 'invalidAssortmentGroupAdviceFromTime'
        }
      },
      adviceToTime: {
        custom: {
          validation: function validation(key, value, model) {
            if (!model.get('adviceToHours') || !model.get('adviceToMinutes')) {
              return false;
            }

            var hour = Number(model.get('adviceToHours'));
            var minute = Number(model.get('adviceToMinutes'));

            if (minute % model.get('warehouse.timeWindowOffsetInterval') !== 0) {
              return false;
            }

            return hour <= model.get('warehouse.workEndHour') && hour >= model.get('warehouse.workStartHour') && minute >= 0 && minute <= 60;
          },
          message: 'invalidAssortmentGroupAdviceToTime'
        }
      }
    },
    transportTypeIds: Ember.computed('transportTypes.@each', function () {
      return this.hasMany('transportTypes').ids();
    }),
    nameInAppropriateLanguage: Ember.computed('namePl', 'nameEn', function () {
      var propertyName = this.get('languageService').findProperLanguageVersion('namePl', 'nameEn');
      return this.get(propertyName);
    }).readOnly(),
    adviceFromHours: Ember.computed('adviceFromTime', function () {
      if (this.get('adviceFromTime')) {
        return this.get('adviceFromTime').split(':')[0];
      }

      return this.get('warehouse.workStartHour');
    }),
    adviceFromMinutes: Ember.computed('adviceFromTime', function () {
      if (this.get('adviceFromTime')) {
        return this.get('adviceFromTime').split(':')[1];
      }

      return '0';
    }),
    adviceToHours: Ember.computed('adviceToTime', function () {
      if (this.get('adviceToTime')) {
        return this.get('adviceToTime').split(':')[0];
      }

      return this.get('warehouse.workEndHour');
    }),
    adviceToMinutes: Ember.computed('adviceToTime', function () {
      if (this.get('adviceToTime')) {
        return this.get('adviceToTime').split(':')[1];
      }

      return '0';
    }),
    hasTurnedHours: Ember.computed('adviceToTime', 'adviceFromTime', function () {
      var fromTime = moment();
      fromTime = fromTime.set({
        h: this.get('adviceFromHours'),
        m: this.get('adviceFromMinutes')
      });
      var toTime = moment();
      toTime = toTime.set({
        h: this.get('adviceToHours'),
        m: this.get('adviceToMinutes')
      });
      return fromTime > toTime;
    })
  });

  _exports.default = _default;
});