define("apollo/pods/components/transport/export-indexes-button/component", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Component.extend({
    sessionAccount: service('session-account'),
    tagName: 'button',
    classNames: ['btn', 'btn-default'],
    attributeBindings: ['title', 'type', 'disabled'],
    type: Ember.computed('', function () {
      return 'button';
    }),
    click: function click() {
      this.generateXLS();
    },
    generateXLS: function generateXLS() {
      Ember.run.once(this, function () {
        var params = {
          token: this.get('sessionAccount.token')
        };
        var transportId = this.get('transport.id');
        window.location = "".concat(_environment.default.serverURL, "/transports/").concat(transportId, "/indices/xls?").concat($.param(params));
      });
    }
  });

  _exports.default = _default;
});