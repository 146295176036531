define("apollo/pods/components/companies/apply-form/main-data-section/component", ["exports", "apollo/mixins/section-mixin", "apollo/config/environment"], function (_exports, _sectionMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sectionMixin.default, {
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    init: function init() {
      this._super.apply(this, arguments);

      var self = this;

      if (!this.get('company.headquarter.content')) {
        var newAddress = this.get('store').createRecord('address', {
          isHeadquarters: true,
          enabled: true,
          company: self.get('company'),
          recipient: self.get('company.name')
        });
        this.get('company').set('headquarter', newAddress);
      }

      this.get('partnership').reload();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var companyObject = this.get('company').rollback ? this.get('company') : this.get('company.content');
      companyObject.rollback();

      if (this.get('section.content')) {
        this.get('section.content').rollback();
      }

      companyObject.reload();
    },
    tosFileHref: Ember.computed('', function () {
      var principalId = localStorage.getItem('principalId');
      var token = this.get("sessionAccount").get("token");
      return "".concat(_environment.default.serverURL, "/files/tos?token=").concat(token, "&principalId=").concat(principalId);
    }),
    additionalDetailsDisabled: Ember.computed('', function () {
      return !this.get('sessionAccount').hasRole('ROLE_EDIT_COMPANY');
    }),
    countries: Ember.computed(function () {
      return this.get('store').peekAll('country');
    }),
    isTOSConfirmed: Ember.computed('partnership.termsOfService.isConfirmed', function () {
      return this.get('partnership.termsOfService.isConfirmed');
    }),
    actions: {
      showModal: function showModal(templateName, model) {
        this.set('action', 'showModal');
        this.sendAction('action', templateName, model);
      },
      toggleTOSConfirmed: function toggleTOSConfirmed() {
        this.set('isTOSConfirmed', !this.get('isTOSConfirmed'));
        this.set('partnership.termsOfService.isConfirmed', true);
      }
    }
  });

  _exports.default = _default;
});