define("apollo/mixins/transports-mixin", ["exports", "apollo/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/no-new-mixins
  var _default = Ember.Mixin.create({
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax-service'),
    dateUtils: Ember.inject.service('date-utils'),
    timeWindowService: Ember.inject.service('time-window-service'),
    checkpointService: Ember.inject.service(),
    scheduleController: Ember.inject.controller('warehouses/schedule'),
    EXCEPTIONS_THAT_ALLOWS_SPOTS_SEARCHING: Ember.A(['WINDOWS_OVERLAP_EXCEPTION']),
    freeSubsequentSpots: Ember.A(),
    interval: Ember.computed('', function () {
      return 10000; // Time between polls (in ms)
    }).readOnly(),
    openCreateAdviceModal: function openCreateAdviceModal(transport) {
      return this.send('openCreateAdviceModal', transport);
    },
    findPunctualityAlert: function findPunctualityAlert(checkpoint, executionDate) {
      var interval = this.get('sessionAccount').getSettingValue('DRIVER_PUNCTUALITY_INTERVAL');
      var showAlert = checkpoint ? checkpoint.get('task.showPunctualityAlertAfterConfirmation') : null;
      var timeWindowStart = checkpoint ? checkpoint.get('step.timeWindow.start') : null;

      if (!interval || !showAlert || !timeWindowStart) {
        return;
      }

      var beforeStart = this.get('dateUtils').calculateDateFromInterval(new Date(executionDate), interval);
      var afterStart = this.get('dateUtils').calculateDateFromInterval(new Date(executionDate), "-".concat(interval));

      if (beforeStart < timeWindowStart) {
        return this.get('intl').t('checkpoints.confirmBeforeTime');
      } else if (afterStart > timeWindowStart) {
        return this.get('intl').t('checkpoints.confirmAfterTime');
      } else {
        return null;
      }
    },
    actions: {
      searchForFreeSubsequentSpots: function searchForFreeSubsequentSpots(transport) {
        var self = this;
        var warehouseId = transport.get('exceptionInformation.whId') || transport.get('warehouse.id');
        self.set('freeSubsequentSpots', Ember.A());
        var data = {
          windowStart: transport.get('exceptionInformation.initialStart'),
          windowSizeInMinutes: transport.get('exceptionInformation.windowSizeInMinutes'),
          warehouseId: warehouseId,
          chosenRampName: transport.get('exceptionInformation.rampName')
        };
        self.get('sessionAccount.currentUser.accessibleRamps').filterBy('warehouse.id', warehouseId).forEach(function (ramp) {
          self.get('ajaxService').send("/ramps/".concat(ramp.get('id'), "/findFreeSubsequentSpot"), 'POST', data, true).then(function (response) {
            if (response.timeWindowCandidate) {
              self.get('freeSubsequentSpots').pushObject(response.timeWindowCandidate);
            }
          }).catch(function (response) {
            (0, _jquery.default)('.transport-modal').scrollTop(0);
            self.set('errors', response.responseJSON.errors);
            (0, _jquery.default)('.show-search-spots-btn').button('reset');
          });
        });
        (0, _jquery.default)('.show-search-spots-btn').button('reset');
      },
      setFilterMode: function setFilterMode(mode) {
        this.set('filterMode', mode);
      },
      confirm: function confirm(checkpoint) {
        if (checkpoint.get('content') ? !checkpoint.get('content').validate() : !checkpoint.validate()) {
          console.log('Status nie przeszedł walidacji..');
          return;
        }

        if (checkpoint.get('task.weighingIncluded') && checkpoint.get('step.warehouse.weighingIncluded') && checkpoint.get('transport.hasAutoWeight')) {
          // eslint-disable-next-line ember/new-module-imports
          var defer = Ember.RSVP.defer();
          var self = this;
          defer.promise.then(function () {
            self.send('sendCheckpoint', checkpoint);
          }, function () {});
          this.send('weigh', checkpoint, defer);
        } else {
          this.send('sendCheckpoint', checkpoint);
        }
      },
      sendCheckpoint: function sendCheckpoint(checkpoint) {
        var self = this;
        var checkpointId = checkpoint.get('id');
        var executionDate = checkpoint.get('executionDate');
        var url = _environment.default.serverURL + '/checkpoints/' + checkpointId + '/confirm';
        var token = this.get('sessionAccount').get('token');

        if (executionDate) {
          var params = {
            executionDate: moment(executionDate).format()
          };
          url += "?".concat(_jquery.default.param(params));
        }

        if (checkpoint.get('skipGeneratingSubtasks')) {
          var _params = {
            skipGeneratingSubtasks: true
          };
          url += "?".concat(_jquery.default.param(_params));
        }

        _jquery.default.ajax({
          url: url,
          type: 'PUT',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          self.store.findRecord('checkpoint', checkpointId).then(function (data) {
            data.get('transport').then(function (t) {
              t.get('transportType').set('forcedPollRequestTime', new Date().getTime());

              if (t.get('isCombinedTransport')) {
                t.get('allCombinedTransports').forEach(function (transport) {
                  transport.get('transportType').set('forcedPollRequestTime', new Date().getTime());
                });
              }

              var punctualityAlert = self.findPunctualityAlert(checkpoint, executionDate || new Date());

              if (punctualityAlert) {
                alert(punctualityAlert);
              }
            });
          });
        });
      },
      revoke: function revoke(checkpoint) {
        var checkpointId = checkpoint.get('id');
        var token = this.get('sessionAccount').get('token');
        var self = this;

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/checkpoints/' + checkpointId + '/revoke',
          type: 'PUT',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          self.store.findRecord('checkpoint', checkpointId).then(function (data) {
            data.reload();
            data.get('transport').reload();
          });
        });
      },
      reject: function reject(checkpoint) {
        this.checkpointService.reject(checkpoint);
      },
      weigh: function weigh(checkpoint, defer) {
        var self = this;
        var url = this.get('sessionAccount').getSettingValue('WEIGHT_URL');
        var selector = this.get('sessionAccount').getSettingValue('WEIGHT_SELECTOR');
        var token = null;

        if (url.includes('api/weights/weightMock')) {
          console.log('Weight Mock detected, adding token');
          token = this.get('sessionAccount').get('token') ? this.get('sessionAccount').get('token') : '';
        }

        console.log("Trying to get weight from ".concat(url, ".."));

        _jquery.default.ajax({
          url: url,
          type: 'GET',
          cache: false,
          timeout: 15000,
          beforeSend: function beforeSend(xhr) {
            if (token) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            }
          }
        }).then(function (data) {
          var weight = 0;

          if (token) {
            weight = Number(data[selector]);
          } else {
            var html = _jquery.default.parseHTML(data);

            var weightHTML = (0, _jquery.default)(html).find(selector).html();
            var weightText = weightHTML.split(' ')[0];
            weight = Number(weightText);
          }

          var readOut = weight * 1000;
          self.trySaveWeight(checkpoint, readOut, defer);
        }).catch(function () {
          console.error('Could not get weight');

          if (defer == null) {
            alert(self.get('intl').t('transport.weightSection.errors.cannotGetWeight'));
          } else {
            if (confirm(self.get('intl').t('transport.weightSection.errors.trySaveCheckpointWithoutWeight'))) {
              self.trySaveWeight(checkpoint, 0, defer);
            } else {
              defer.reject();
            }
          }
        });
      },
      generateWeightReceipt: function generateWeightReceipt(transportId) {
        // eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
        Ember.run.once(this, function () {
          window.open(_environment.default.serverURL + '/transports/' + transportId + '/weightReceipt?token=' + this.get('sessionAccount').get('token'), '_blank');
        });
      },
      addNote: function addNote(noteContent, transportId) {
        console.log('addNote: ' + noteContent + ', transportId: ' + transportId);
        var self = this;
        var transport = this.store.peekRecord('transport', transportId);
        var note = self.store.createRecord('note', {
          transport: transport,
          note: noteContent
        });
        note.save().then(function (n) {
          transport.get('notes').pushObject(n);
        });
      },
      markNotesAsReadOff: function markNotesAsReadOff(transport) {
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/' + transport.id + '/markNotesAsReadOff',
          type: 'PUT',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          transport.get('notes').then(function (notes) {
            notes.reload().then(function () {
              transport.notifyPropertyChange('notes');
            });
          });
        });
      },

      /**
       * Metoda przenosi użytkownika do magazynu.
       *
       * Dzień, do którego trzeba przenieść użytkownika, to
       * <ul>
       *  <li>dzień okna czasowego - jeśli zostało już dodane,
       *  <li>dzień początku zakresu czasu na awizację - jeśli nie znajduje się on w przeszłości,
       *  <li>aktualny dzień - w pozostałych przypadkach.
       * </ul>
       *
       * @param step
       */
      goToWarehouse: function goToWarehouse(step) {
        var self = this;
        var adviceButton = (0, _jquery.default)('.advice-button-' + step.get('transport.id'));
        adviceButton.button('loading');

        if (!step.get('isInWarehouse')) {
          this.openCreateAdviceModal(step.get('transport'));
          adviceButton.button('reset');
          return;
        }

        var warehouseId = step.get('warehouse.id');
        var editedWindow = step.get('timeWindow');

        if (editedWindow.get('id')) {
          editedWindow.setProperties({
            oldStart: editedWindow.get('start'),
            oldStop: editedWindow.get('stop'),
            oldRamp: editedWindow.get('ramp')
          });
        }

        if (editedWindow.get('start')) {
          this.get('scheduleController').setProperties({
            warehouseStepToHandle: step
          });
          var dateString = moment(editedWindow.get('start')).format('YYYY-MM-DD');
          this.transitionToRoute('warehouses.schedule', warehouseId, dateString, '1');
          adviceButton.button('reset');
          return;
        }

        var transport = this.get('store').peekRecord('transport', step.get('transport.id'));
        this.getFirstPossibleWindowStart(step, transport).then(function (response) {
          var firstPossibleStart = response.firstPossibleStart;

          if (!firstPossibleStart) {
            alert(response.error);
            adviceButton.button('reset');
            return;
          }

          self.get('scheduleController').setProperties({
            warehouseStepToHandle: step,
            info: null
          });
          var dateString = moment(firstPossibleStart).format('YYYY-MM-DD');
          self.transitionToRoute('warehouses.schedule', warehouseId, dateString, '1');
          adviceButton.button('reset');
        });
      },
      openCreateAdviceModal: function openCreateAdviceModal(transport) {
        transport.set('hasCustomFieldsInitialized', false);
        var scheduleController = this.get('scheduleController');
        scheduleController.setProperties({
          warehouseStepToHandle: transport.get('firstWarehouseStepWithoutWindow')
        });
        scheduleController.send('openCreateAdviceModal', [], transport);
      },
      scrollPage: function scrollPage(transportTypeId) {
        (0, _jquery.default)(window).scrollTop((0, _jquery.default)('#' + transportTypeId).offset().top - 125);
      },
      fileUploadSuccess: function fileUploadSuccess(transportId) {
        console.log('fileUploadSuccess: ' + transportId);
        this.store.findRecord('transport', transportId).then(function (data) {
          data.reload();
        });
      },
      deleteFile: function deleteFile(file) {
        console.log('deleteFile: ' + file.id);
        var transport = file.get('transport');
        file.destroyRecord().then(function () {
          transport.then(function (t) {
            return t.reload();
          });
        });
      },
      fileUploadFail: function fileUploadFail(transportId) {
        console.log('fileUploadFail: ' + transportId);
      }
    },

    /** @namespace response.firstPossibleStart */
    getFirstPossibleWindowStart: function getFirstPossibleWindowStart(step, transport, selectedDate) {
      var self = this;
      return new Promise(function (resolve) {
        self.timeWindowService.getFirstPossibleWindowStart(step, transport, selectedDate).then(function (response) {
          resolve(response);
        });
      });
    },
    getStatuses: function getStatuses(transport) {
      if (!transport) {
        console.error('Próba pobrania statusów nie powiodła się - brak transportu!');
        return;
      }

      var self = this;
      var token = this.get('sessionAccount').get('token');

      _jquery.default.ajax({
        url: _environment.default.serverURL + '/transports/' + transport.get('id') + '/checkpoints',
        type: 'GET',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        }
      }).then(function (json) {
        self.get('store').pushPayload(json);
        json.checkpoints.forEach(function (checkpoint) {
          var c = self.get('store').peekRecord('checkpoint', checkpoint.id);

          if (self.get('transport.checkpoints') && !self.get('transport.checkpoints').includes(c)) {
            console.debug("Pushing checkpoint ".concat(c.get('id'), " into transport ").concat(self.get('transport.id'), ".."));
            self.get('transport.checkpoints').pushObject(c);
          }
        });
      });
    },
    synchronizeWeights: function synchronizeWeights() {
      var _this = this;

      var weightKeys = [];
      var weightObjects = [];
      var token = this.get('sessionAccount').get('token');
      var self = this;

      for (var i = 0; i < localStorage.length; i++) {
        var key = localStorage.key(i);

        if (key.startsWith('weight')) {
          weightKeys.pushObject(key);
          weightObjects.pushObject(localStorage[key]);
        }
      }

      if (weightObjects.length === 0) {
        return;
      }

      _jquery.default.ajax({
        url: _environment.default.serverURL + '/synchronizeWeights',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(weightObjects),
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        }
      }).then(function () {
        console.debug('Zsynchronizowaliśmy wagi');
        weightObjects.forEach(function (weightString) {
          var weight = JSON.parse(weightString);
          self.store.findRecord('transport', weight.transport).then(function (transport) {
            transport.get('weights').reload();
            transport.get('checkpoints').filterBy('hasUnsynchronizedWeight').forEach(function (checkpoint) {
              checkpoint.set('hasUnsychronizedWeight', false);
            });
            transport.get('weights').filter(function (w) {
              return !w.get('id');
            }).forEach(function (w) {
              w.destroyRecord();
            });
          });
        });
        weightKeys.forEach(function (key) {
          localStorage.removeItem(key);
        });
        self.set('weightSynchronizingActivated', false);
      }).catch(function (response) {
        console.debug('Nie udało się przesłać wagi - planujemy następną próbę');
        return Ember.run.later(_this, function () {
          this.synchronizeWeights();
        }, _this.get('interval'));
      });
    },
    trySaveWeight: function trySaveWeight(checkpoint, readOut, defer) {
      var self = this;
      var currentWeight = checkpoint.get('currentWeight');

      if (currentWeight) {
        currentWeight.set('enabled', false);
      }

      var weightObject = self.store.createRecord('weight', {
        task: checkpoint.get('task'),
        warehouse: checkpoint.get('step.warehouse'),
        transport: checkpoint.get('transport'),
        enabled: true,
        readOut: readOut,
        dateCreated: new Date(),
        confirmator: self.get('sessionAccount.currentUser')
      });
      weightObject.save().then(function () {
        console.debug('Zapisaliśmy wagę: ' + weightObject.get('readOut'));
        checkpoint.get('transport').then(function (transport) {
          transport.get('weights').reload();
        });
      }).catch(function (response) {
        console.debug("Serwer zwr\xF3ci\u0142 b\u0142\u0105d ".concat(response, " - zapisujemy wag\u0119 w localStorage.."));
        var key = 'weight_'.concat(weightObject.get('task.id'), '_', weightObject.get('transport.id'));
        localStorage[key] = JSON.stringify(weightObject);

        if (currentWeight) {
          currentWeight.set('enabled', false);
        }

        checkpoint.set('hasUnsynchronizedWeight', true);

        if (!self.get('weightSynchronizingActivated')) {
          self.set('weightSynchronizingActivated', true);
          return Ember.run.later(self, function () {
            self.synchronizeWeights();
          }, self.get('interval'));
        }
      }).finally(function () {
        if (defer != null) {
          defer.resolve();
        }
      });
    },
    printErrors: function printErrors(transport) {
      try {
        console.log("Transport ".concat(transport.get('logString'), " zawiera b\u0142\u0119dy..."));
        transport.get('errors').forEach(function (error) {
          return console.log(error);
        });
        transport.get('steps').filter(function (s) {
          return s.get('numberOfErrors') > 0;
        }).forEach(function (s) {
          s.get('errors').forEach(function (se) {
            return console.log(se);
          });
        });
        transport.get('customFields').filter(function (cf) {
          return cf.get('numberOfErrors') > 0;
        }).forEach(function (cf) {
          cf.get('errors').forEach(function (cfe) {
            return console.log("".concat(cf.get('definition.name'), " - ").concat(cfe.message));
          });
        });
        transport.get('indexes').filter(function (cf) {
          return cf.get('hasErrors');
        }).forEach(function (index) {
          index.get('errors').forEach(function (error) {
            return console.log(error);
          });
        });
      } catch (e) {
        console.error(e);
      }
    }
  });

  _exports.default = _default;
});