define("apollo/pods/invitations/handle/controller", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    loginController: Ember.inject.controller('auth/login'),
    intl: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax-service'),
    statuteAccepted: false,
    statute: Ember.computed('model.statutes.firstObject', function () {
      return this.get('model.statutes').get('firstObject');
    }),
    showRegistrationForm: Ember.computed('model.invitation.company.id', 'model.invitation.isToJoinCompany', function () {
      return this.get('model.invitation.company.id') || this.get('model.invitation.isToJoinCompany');
    }),
    showExistingCompanyForm: Ember.computed('', function () {
      return false;
    }),
    showInvalidTaxIdNumberForm: Ember.computed('', function () {
      return false;
    }),
    invalidTaxIdForm: Ember.computed('showInvalidTaxIdNumberForm', 'showExistingCompanyForm', function () {
      return this.get('showExistingCompanyForm') || this.get('showInvalidTaxIdNumberForm');
    }),
    message: Ember.computed('model.company.taxIdNumber', function () {
      return Ember.Object.create({
        email: '',
        phoneNumber: '',
        content: '',
        taxIdNumber: this.get('model.company.taxIdNumber'),
        invitationId: this.get('model.invitation.id'),
        isValidTaxIdNumber: true
      });
    }),
    actions: {
      join: function join() {
        var _this = this;

        var self = this;
        var user = this.get('model.user');
        var company = this.get('model.company');
        var data = user.serialize();
        data.company = company.serialize();
        var invitationId = this.get('model.invitation.id');
        Ember.$.ajax({
          url: _environment.default.serverURL + "/invitations/".concat(invitationId, "/join"),
          type: 'POST',
          data: JSON.stringify(data),
          contentType: 'application/json'
        }).then(function (jsonResponse) {
          if (jsonResponse.errors) {
            self.set('errors', jsonResponse.errors);
            $("html, body").animate({
              scrollTop: 0
            }, "slow");
            return;
          }

          self.setProperties({
            inProgress: false,
            errors: []
          });
          var msg = _this.get('model.invitation.company.isLdapCompany') ? self.get('intl').t('invitation.registrationCompleteLdap', {
            username: jsonResponse.user.username
          }) : self.get('intl').t('invitation.registrationComplete', {
            username: jsonResponse.user.username
          });
          self.get('loginController').set('message', msg);
          self.get('store').peekAll('partnership').forEach(function (p) {
            p.unloadRecord();
          });
          self.transitionToRoute('auth.login');
        }, function (response) {
          self.set('errors', response.errors);
        });
      },
      approveAllPoints: function approveAllPoints() {
        this.set('statuteAccepted', true);
      },
      unApproveSomePoints: function unApproveSomePoints() {
        this.set('statuteAccepted', false);
      },
      validateTaxIdNumber: function validateTaxIdNumber() {
        var self = this;
        var company = this.get('model.company');
        var invitation = this.get('model.invitation');
        var data = company.serialize();
        data.id = invitation.get('company.id');
        data.invitationTaxIdNumber = invitation.get('taxIdNumber');
        Ember.$.ajax({
          url: _environment.default.serverURL + "/invitations/validateTaxIdNumber",
          type: 'POST',
          data: JSON.stringify(data),
          contentType: 'application/json'
        }).then(function (jsonResponse) {
          if (jsonResponse.errors) {
            self.set('errors', jsonResponse.errors);
            return;
          }

          if (jsonResponse.companyExist) {
            self.set('showExistingCompanyForm', true);
            return;
          }

          if (jsonResponse.invalidTaxIdNumber) {
            self.set('showInvalidTaxIdNumberForm', true);
            self.set('message.isValidTaxIdNumber', false);
            return;
          }

          self.setProperties({
            inProgress: false,
            errors: [],
            showRegistrationForm: true
          });
        }, function (response) {
          self.set('errors', response.errors);
        });
      },
      sendMessage: function sendMessage() {
        var self = this;
        var data = self.get('message');
        Ember.$.ajax({
          url: _environment.default.serverURL + "/invitations/sendMessage",
          type: 'POST',
          data: JSON.stringify(data),
          contentType: 'application/json'
        }).then(function () {
          self.set('showExistingCompanyForm', false);
          self.set('showInvalidTaxIdNumberForm', false);
          self.transitionToRoute('auth.login');
        }, function (response) {
          self.set('errors', JSON.parse(response.responseText).errors);
        });
      },
      rejectStatute: function rejectStatute() {
        var self = this;
        var token = this.get('model.token');
        self.get('ajaxService').send("/rejectStatute?token=".concat(token));
        self.transitionToRoute('auth.login');
        self.get('store').peekAll('partnership').forEach(function (p) {
          p.unloadRecord();
        });
      }
    }
  });

  _exports.default = _default;
});