define("apollo/pods/courier-setting/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    transportType: _emberData.default.belongsTo('transportType', {
      async: true
    }),
    isGetCourierQuotesFieldVisibility: _emberData.default.attr('boolean'),
    shouldGetCourierQuotesByDefault: _emberData.default.attr('boolean'),
    translationKey: _emberData.default.attr('string', {
      defaultValue: function defaultValue() {
        return "COURIER_PACKAGE";
      }
    }),
    disableConfirmation: Ember.computed('', function () {
      return this.getTranslation('disableConfirm');
    }),
    sectionLabel: Ember.computed('', function () {
      return this.getTranslation('sectionLabel');
    }),
    packageTypePlaceholder: Ember.computed('', function () {
      return this.getTranslation('packageTypePlaceholder');
    }),
    packageIdPlaceholder: Ember.computed('', function () {
      return this.getTranslation('packageIdPlaceholder');
    }),
    getTranslation: function getTranslation(propertyName) {
      var transportType = this.get('transportType');
      var cpSetting = this.get('sessionAccount').getCourierSetting(transportType.get('id'));

      if (!cpSetting) {
        return this.get('intl').t("courier.COURIER_PACKAGE.".concat(propertyName));
      }

      var key = cpSetting.get('translationKey');
      return this.get('intl').t("courier.".concat(key, ".").concat(propertyName));
    }
  });

  _exports.default = _default;
});