define("apollo/pods/index/model", ["exports", "ember-data", "ember-data-copyable", "apollo/mixins/model-validator"], function (_exports, _emberData, _emberDataCopyable, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, _emberDataCopyable.default, {
    changeTracker: {
      auto: true
    },
    intl: Ember.inject.service(),
    product: _emberData.default.belongsTo('product', {
      async: true
    }),
    transport: _emberData.default.belongsTo('transport', {
      async: false
    }),
    quantities: _emberData.default.hasMany('indexQuantity', {
      async: true
    }),
    globalNumber: _emberData.default.attr('string'),
    localNumber: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    shortName: _emberData.default.attr('string'),
    documentNumber: _emberData.default.attr('string'),
    transportNumber: _emberData.default.attr('string'),
    notes: _emberData.default.attr('string'),
    step: _emberData.default.belongsTo('step', {
      async: true
    }),
    enabled: _emberData.default.attr('boolean'),
    validations: {
      product: {
        custom: [{
          validation: function validation(key, value, model) {
            return !!value || !model.get('indexSetting.hasProductsEnabled');
          },
          message: 'blank'
        }]
      },
      globalNumber: {
        custom: [{
          validation: function validation(key, value, model) {
            return !!value || !model.get('indexSetting.requiresGlobalNumber');
          },
          message: 'blank'
        }]
      },
      localNumber: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!model.get('indexSetting.showLocalNumber')) {
              return true;
            }

            return !!value || model.get('indexSetting.hasProductsEnabled');
          },
          message: 'blank'
        }]
      },
      name: {
        custom: [{
          validation: function validation(key, value, model) {
            if (model.get('indexSetting.hasProductsEnabled')) {
              return true;
            }

            return !!value || !model.get('indexSetting.requiresName');
          },
          message: 'blank'
        }]
      },
      step: {
        custom: [{
          validation: function validation(key, value, model) {
            return !!value.content;
          },
          message: 'blank'
        }]
      }
    },
    transportType: Ember.computed('transport.transportType', function () {
      return this.get('transport.transportType');
    }).readOnly(),
    ordinalNumber: Ember.computed('idx', function () {
      return this.get('idx') + 1;
    }).readOnly(),
    proxiedQuantities: Ember.computed('transportType.indexQuantityTypes.@each', 'quantities.@each.indexQuantityType', function () {
      var quantities = this.get('quantities');
      return this.get('transportType.indexQuantityTypes').map(function (qt) {
        return Ember.ObjectProxy.create({
          content: qt,
          indexQuantity: quantities.filterBy('indexQuantityType.id', qt.get('id')).get('firstObject')
        });
      });
    }).readOnly(),
    hasErrors: Ember.computed('errors.length', 'quantities.@each.numberOfErrors', function () {
      return this.get('errors.length') || this.get('quantities').filterBy('numberOfErrors').get('length');
    }).readOnly(),
    sortValue: Ember.computed('id', function () {
      return this.get('id') || Number.MAX_SAFE_INTEGER.toString();
    }),
    hasChanges: Ember.computed('hasDirtyAttributes', 'quantities.@each.hasDirtyAttributes', function () {
      return this.get('hasDirtyAttributes') || this.get('quantities').filterBy('hasDirtyAttributes').get('length') > 0;
    }),
    label: Ember.computed('shortName', 'name', function () {
      return this.get('shortName') || this.get('name') || this.get('globalNumber');
    }),
    indexSetting: Ember.computed('transport.transportType.indexSetting', function () {
      return this.get('transport.transportType.indexSetting');
    }),
    handleGlobalNumber: Ember.computed('product', {
      get: function get(key) {
        var product = this.get('product');
        return product.get('index') ? product.get('index') : this.get('globalNumber');
      },
      set: function set(key, value) {
        return this.set('globalNumber', value);
      }
    }),
    getProductUnit: Ember.computed('product.unit', function () {
      var unit = this.get('product.unit');
      return unit ? this.get('intl').t("unit.".concat(unit)) : "";
    })
  });

  _exports.default = _default;
});