define("apollo/pods/components/transport/advice-date-restrictions-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    init: function init() {
      this._super.apply(this, arguments);

      var step = this.get('step');

      if (step.get('warehouse.content') !== null) {
        return;
      }

      if (step.get('transport').get('id')) {
        return;
      }

      var tt = step.get('transport').get('transportType');
      var defaultStartHour = tt.get('defaultLoadStepStartHour');
      var defaultEndHour = tt.get('defaultLoadStepEndHour'); // Defaultowo ustawialiśmy 00:00, po dodaniu tych nowych ustawień,
      // jeśli jakiś klient tego nie uzupełni, dalej powinien mieć 00:00

      if (defaultStartHour === "" || defaultStartHour === null || defaultStartHour === undefined) {
        defaultStartHour = "00:00";
      }

      if (defaultEndHour === "" || defaultEndHour === null || defaultEndHour === undefined) {
        defaultEndHour = "00:00";
      }

      var startTimeArray = defaultStartHour.split(":");
      var endTimeArray = defaultEndHour.split(":");
      var startHour = Number(startTimeArray[0]);
      var startMinute = Number(startTimeArray[1]);
      var endHour = Number(endTimeArray[0]);
      var endMinute = Number(endTimeArray[1]);
      var newStartDate = moment(this.get('step.minAdviceDate')).set('hour', startHour).set('minute', startMinute);
      var newEndDate = moment(this.get('step.maxAdviceDate')).set('hour', endHour).set('minute', endMinute);
      Ember.run.next(function () {
        step.set('minAdviceDate', new Date(newStartDate));
        step.set('maxAdviceDate', new Date(newEndDate));
      });
    },
    timeWindowOffsetInterval: function () {
      return this.get('step.warehouse.timeWindowOffsetInterval') ? this.get('step.warehouse.timeWindowOffsetInterval') : 15;
    }.property('step.warehouse.id'),
    formattedMinAdviceDate: Ember.computed('step.minAdviceDate', function () {
      return moment(this.get('step').get('minAdviceDate')).format('YYYY-MM-DD HH:mm');
    }),
    formattedMaxAdviceDate: Ember.computed('step.maxAdviceDate', function () {
      return moment(this.get('step').get('maxAdviceDate')).format('YYYY-MM-DD HH:mm');
    }),
    warehouseOpenHoursMinAdviceDateObserver: Ember.observer('step.warehouse', function () {
      this.adjustAdviceRestrictionHour('step.minAdviceDate', 'workStartHour');
    }),
    warehouseOpenHoursMaxAdviceDateObserver: Ember.observer('step.warehouse', function () {
      this.adjustAdviceRestrictionHour('step.maxAdviceDate', 'workEndHour');
    }),
    adjustAdviceRestrictionHour: function adjustAdviceRestrictionHour(dateName, warehousePropertyName) {
      var currentAdviceDate = this.get(dateName);

      if (!currentAdviceDate || !this.get('step.warehouse.id')) {
        return;
      }

      var currentAdviceHour = moment(currentAdviceDate).hours();
      var warehouseStart = this.get('step.warehouse.workStartHour');
      var warehouseEnd = this.get('step.warehouse.workEndHour');

      if (warehouseStart <= currentAdviceHour && currentAdviceHour <= warehouseEnd) {
        return;
      }

      var correctHour = this.get("step.warehouse.".concat(warehousePropertyName));
      var correctDate = moment(currentAdviceDate).hours(correctHour).toDate();
      this.set(dateName, correctDate);
    },
    actions: {
      setMinAdviceDate: function setMinAdviceDate(step, dateValues) {
        Ember.run.next(function () {
          var oldMinAdviceDate = step.get('minAdviceDate');
          var oldMaxAdviceDate = step.get('maxAdviceDate');
          var newMinAdviceDate = dateValues[0];
          var newMaxAdviceDate = null;

          if (oldMinAdviceDate) {
            var difference = newMinAdviceDate.getTime() - oldMinAdviceDate.getTime();
            newMaxAdviceDate = moment(oldMaxAdviceDate.getTime() + difference);
          } else {
            newMaxAdviceDate = moment(newMinAdviceDate).add(1, 'days');
          }

          step.set('minAdviceDate', newMinAdviceDate);
          step.set('maxAdviceDate', new Date(newMaxAdviceDate));
        });
      },
      setMaxAdviceDate: function setMaxAdviceDate(step, dateValues) {
        Ember.run.next(function () {
          step.set('maxAdviceDate', dateValues[0]);
        });
      }
    }
  });

  _exports.default = _default;
});