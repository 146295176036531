define("apollo/mixins/schedule-mixin", ["exports", "apollo/config/environment", "apollo/mixins/persistence-mixin", "apollo/mixins/advice-utils-mixin", "jquery"], function (_exports, _environment, _persistenceMixin, _adviceUtilsMixin, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  // eslint-disable-next-line ember/no-new-mixins
  var _default = Ember.Mixin.create(_persistenceMixin.default, _adviceUtilsMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    dateUtils: Ember.inject.service('date-utils'),
    windows: Ember.A([]),
    showMultipleTimeWindowsModal: Ember.computed('transportToHandle', {
      get: function get(key) {
        return !!this.get('transportToHandle') && this.get('transportToHandle.stepsWithWarehouse').length > 1;
      },
      set: function set(key, value) {
        return value;
      }
    }),
    scheduleStartDateFormatted: Ember.computed('scheduleStartDate', function () {
      return moment(this.get('scheduleStartDate')).format('YYYY-MM-DD');
    }),
    // eslint-disable-next-line ember/no-observers
    showErrors: Ember.observer('errors', function () {
      if (this.get('errors.length')) {
        this.send('showPopupDialog', 'dialogs.message-popup-dialog', this.get('errors'));
      }
    }),
    // eslint-disable-next-line ember/no-observers
    hideErrors: Ember.observer('warehouse.id', function () {
      try {
        this.send('removePopupDialog');
      } catch (e) {
        console.debug(e);
      }
    }),
    // eslint-disable-next-line ember/no-observers
    pollRequested: Ember.observer('editedTransportType.forcedPollRequestTime', function () {
      if (this.get('editedTransportType.forcedPollRequestTime')) {
        console.debug("Sending poll request for transport type ".concat(this.get('transportType.name'), ".."));
        this.send('poll');
      }

      this.get('editedTransportType').set('forcedPollRequestTime', null);
    }),
    // eslint-disable-next-line ember/no-observers
    selectedUserGroupObserver: Ember.observer('selectedUserGroup', function () {
      if (this.get('selectedUserGroup')) {
        this.set('company', null);
      }
    }),
    // eslint-disable-next-line ember/no-observers
    selectedCompanyObserver: Ember.observer('company', function () {
      if (this.get('company')) {
        this.set('selectedUserGroup', null);
      }
    }),
    shownDays: Ember.computed('scheduleStartDate', 'scheduleEndDate', function () {
      var days = Ember.A();

      for (var i = 0; moment(this.scheduleStartDate).add(i, 'days') < this.get('scheduleEndDate'); i++) {
        var day = Ember.Object.create({
          date: moment(this.scheduleStartDate).add(i, 'days'),
          name: moment(this.scheduleStartDate).add(i, 'days').format('YYYY-MM-DD'),
          longName: moment(this.scheduleStartDate).add(i, 'days').format('DD.MM.YYYY dddd')
        });
        days.pushObject(day);
      }

      return days;
    }),
    warehouse: Ember.computed('model.warehouse', function () {
      return this.get('model.warehouse');
    }),
    userRamps: Ember.computed('warehouse', 'sessionAccount.currentUser.accessibleRamps.[]', function () {
      return this.get('sessionAccount.currentUser.accessibleRamps').filterBy('warehouse.id', this.get('warehouse.id'));
    }),
    userRampsIds: Ember.computed.map('userRamps', function (model) {
      return model.get('id');
    }),
    userWarehouseZones: Ember.computed('userRamps.@each.warehouseZone', function () {
      return this.get('userRamps').getEach('warehouseZone').uniqBy('id');
    }),
    warehouseZones: Ember.computed('userWarehouseZones.[]', 'warehouseStepToHandle.warehouseZone', function () {
      var zones = this.get('userWarehouseZones');
      var stepToHandle = this.get('warehouseStepToHandle');
      var transportZoneId = stepToHandle ? stepToHandle.get('warehouseZone.id') : null;

      if (stepToHandle && transportZoneId && zones.filterBy('id', transportZoneId).get('length')) {
        zones = zones.filterBy('id', transportZoneId);
      }

      return zones.sortBy('idx');
    }),
    ramps: Ember.computed('userRamps.[]', 'warehouseZones.@each.ramps', function () {
      var userRamps = this.get('userRamps');
      var ramps = [];
      this.get('warehouseZones').forEach(function (zone) {
        if (zone.get('id')) {
          zone.get('enabledRamps').forEach(function (r) {
            if (userRamps.includes(r)) {
              ramps.push(r);
            }
          });
        }
      });
      return ramps;
    }),
    rampsIds: Ember.computed('ramps.@each.id', function () {
      var ids = [];
      this.get('ramps').forEach(function (r) {
        ids.push(r.get('id'));
      });
      return ids;
    }),
    maxDate: Ember.computed(function () {
      return moment().startOf('day').add(14, 'days');
    }),
    rampStyle: Ember.computed('rampWidth', function () {
      var width = this.get('rampWidth') + '%';
      return Ember.String.htmlSafe('width: ' + width);
    }),
    rampWidth: Ember.computed('ramps.length', function () {
      return '' + 100 / this.get('ramps.length');
    }),
    dayStyle: Ember.computed('dayWidth', function () {
      var width = this.get('dayWidth') + '%';
      return Ember.String.htmlSafe('width: ' + width);
    }),
    dayWidth: Ember.computed('scheduleStartDate', 'scheduleEndDate', function () {
      return 100 / this.get('shownDays.length');
    }),
    weekStyle: Ember.computed('ramps', 'shownDays', function () {
      var width = 100 + '%';

      if (this.get('ramps.length') > 8 && this.get('shownDays.length') > 4) {
        width = 200 + '%';
      }

      return Ember.String.htmlSafe('width: ' + width);
    }),
    selectedDateISO: Ember.computed('scheduleStartDate', function () {
      return moment(this.get('scheduleStartDate')).format('YYYY-MM-DD');
    }),

    /**
     * Chcemy, żeby w harmonogramie najpierw dodawane były div-y z oknami dedykowanymi
     * - żeby nie przykrywały one okien awizacji. Sortowanie po `state` daje taki efekt,
     * bo statusy okien dedykowanych (`state-dedicated`, `state-dedicated-template`) znajdą się przed
     * statusami okien awizacji (`state-with-transport`) i jest wydajne, bo `state` jest właściwością `timeWindow`.
     *
     * Jeżeli kiedyś dojdą nowe statusy, być może trzeba będzie zmienić to sortowanie.
     */
    filteredWindows: Ember.computed('scheduleStartDate', 'scheduleEndDate', 'windows.[]', function () {
      var self = this;
      var warehouseId = self.get('model.warehouse.id');
      return this.get('windows').filter(function (w) {
        return w.get('ramp.warehouse.id') === warehouseId && (w.get('start') >= self.get('scheduleStartDate') && w.get('start') < self.get('scheduleEndDate') || w.get('stop') > self.get('scheduleStartDate') && w.get('stop') <= self.get('scheduleEndDate') || w.get('start') <= self.get('scheduleStartDate') && w.get('stop') >= self.get('scheduleEndDate'));
      }).sortBy('state');
    }),
    getStartDate: function getStartDate(div) {
      var $div = (0, _jquery.default)(div);
      var $hourDiv = $div.parent();
      var $dayDiv = $hourDiv.parent();
      var dateString = $dayDiv.parent().data('schedule-date');
      var hours = $hourDiv.data('schedule-hour');
      var minutes = $div.data('schedule-minute');
      var time = moment("".concat(dateString, " ").concat(hours, " ").concat(minutes), 'YYYY-MM-DD HH:mm');
      return time;
    },
    timeWindowOffsetIntervalSetting: Ember.computed('model.warehouse.timeWindowOffsetInterval', function () {
      var s = this.get('model.warehouse.timeWindowOffsetInterval');

      if (!s) {
        s = this.get('sessionAccount').getSettingValue('TIME_WINDOW_OFFSET_INTERVAL');
      }

      return parseInt(s);
    }).readOnly(),
    defaultGridHeight: function defaultGridHeight() {
      var slotsPerHour = this.get('timeWindowOffsetIntervalSetting') ? 60 / this.get('timeWindowOffsetIntervalSetting') : 1;
      var hdMultiplier = this.get('hdMode') ? 3 : 1;
      return this.defaultCssHeight() / slotsPerHour * hdMultiplier;
    },
    defaultCssHeight: function defaultCssHeight() {
      if (this.get('timeWindowOffsetIntervalSetting') === 15) {
        return 80;
      } else if (this.get('timeWindowOffsetIntervalSetting') === 30) {
        return 60;
      } else if (this.get('timeWindowOffsetIntervalSetting') === 60) {
        return 48;
      } else {
        return 48;
      }
    },
    cssHeight: Ember.computed('timeWindowOffsetIntervalSetting', function () {
      return this.defaultCssHeight();
    }),
    timeSlotIntervalClass: Ember.computed('timeWindowOffsetIntervalSetting', function () {
      var interval = this.get('timeWindowOffsetIntervalSetting');

      switch (interval) {
        case 15:
          return 'slot-height-four-slots-per-hour';

        case 30:
          return 'slot-height-two-slots-per-hour';

        case 45:
          return 'slot-height-three-quarters-slots-per-hour';

        case 90:
          return 'slot-height-one-slot-per-one-and-half-hour';

        case 120:
          return 'slot-height-one-slot-per-two-hours';

        case 150:
          return 'slot-height-one-slot-per-two-and-half-hour';

        case 180:
          return 'slot-height-one-slots-per-three-hour';

        case 240:
          return 'slot-height-one-slots-per-four-hour';

        case 300:
          return 'slot-height-one-slots-per-five-hour';

        default:
          return '';
      }
    }),
    // eslint-disable-next-line ember/no-observers
    setTimeObjects: Ember.observer('timeWindowOffsetIntervalSetting', 'scheduleStartHour', 'scheduleEndHour', function () {
      var s = this.get('timeWindowOffsetIntervalSetting');
      var self = this; // eslint-disable-next-line ember/no-new-mixins

      var TimeObject = Ember.Object.extend({
        // eslint-disable-next-line ember/require-return-from-computed
        hour: Ember.computed('', function () {}),
        // eslint-disable-next-line ember/require-return-from-computed
        minutes: Ember.computed('', function () {})
      });
      self.set('timeObjects', []);
      var start = moment().startOf('day').add(this.get('scheduleStartHour'), 'hours');
      var stop = moment().startOf('day').add(this.get('scheduleEndHour'), 'hours');
      var numberOfHour = moment.duration(stop.diff(start)).asHours();
      var numberOfMinutes = numberOfHour * 60;
      var numberOfSteps = Math.floor(numberOfMinutes / s);

      for (var i = 0; i < numberOfSteps; i++) {
        var timeObject = TimeObject.create({
          hour: start.format('HH'),
          minutes: start.format('mm')
        });
        start.add(s, 'minutes');
        self.get('timeObjects').pushObject(timeObject);
      }
    }).on('init'),
    // eslint-disable-next-line ember/no-observers
    scheduleSetWindows: Ember.observer('shownDays.[]', 'ramps.length', 'scheduleStartDate', 'model.isTemplate', 'warehouseStepToHandle', function () {
      var webSocket = this.get('websocket').getSocket();
      var isTemplate = this.get('model.isTemplate');

      if (!isTemplate && webSocket && webSocket.connected) {
        return;
      }

      Ember.run.once(this, 'setWindows');
    }),
    hasAccessToMaxPallet: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('HAS_ACCESS_TO_MAX_PALLET_PER_DAY') === 'true';
    }),
    hasAccessToMaxPalletPerRamp: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('HAS_ACCESS_TO_MAX_PALLET_PER_RAMP') === 'true';
    }),
    hasAccessToNumberOfWindows: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('HAS_ACCESS_TO_NUMBER_OF_WINDOWS') === 'true' && this.get('sessionAccount').hasRole('ROLE_ACCESS_TO_NUMBER_OF_WINDOWS');
    }),
    assortmentGroup: Ember.computed('warehouseStepToHandle.assortmentGroup', function () {
      return this.get('warehouseStepToHandle.assortmentGroup');
    }),
    adviceFromTime: Ember.computed('assortmentGroup', function () {
      return parseInt(this.get('assortmentGroup.adviceFromTime'));
    }),
    adviceToTime: Ember.computed('assortmentGroup', function () {
      return parseInt(this.get('assortmentGroup.adviceToTime'));
    }),
    scheduleStartHour: Ember.computed('warehouse.workStartHour', function () {
      return parseInt(this.get('warehouse.workStartHour'));
    }),
    scheduleEndHour: Ember.computed('warehouse.workEndHour', function () {
      return parseInt(this.get('warehouse.workEndHour'));
    }),
    hours: Ember.computed('scheduleStartHour', 'scheduleEndHour', function () {
      return this.hoursArray(1);
    }),

    /**
     * Zwraca tablicę godzin jakie należy wyświetlić w harmonogramie
     * scheduleStartHour - początek pracy magazynu
     * scheduleEndHour - koniec pracy magazynu
     */
    hoursArray: function hoursArray(interval) {
      var hoursArray = [];

      for (var i = this.get('scheduleStartHour'); i < this.get('scheduleEndHour'); i += interval) {
        hoursArray.push(i);
      }

      return hoursArray;
    },
    loadWindows: function loadWindows(idx, data) {
      this.set('loadingInProgress', true);
      var days = this.get('shownDays');

      if (idx >= days.length) {
        this.set('loadingInProgress', false); // Po załadowaniu wszystkich okien w dniach widocznych w harmonogrmie odpalamy
        // metodę nakładającą blokady związnae z grupami asortymentowymi oraz oknami dedykowanymi

        this._setAutoBlockades();

        return;
      }

      var self = this;
      var d = days[idx];
      var start = d.date.format('DD.MM.YYYY HH:mm');
      self.setPalletSummary(d);
      self.setNumberOfWindows(d);
      var lessOrEqualsTransportsThenWindows = data.loadedTransportIds.split(',').length <= this.get('windows').filter(function (w) {
        return w.get('transport.id');
      }).length;

      if (this.get('warehouse').get('loadedDays').includes(start) && lessOrEqualsTransportsThenWindows) {
        return self.loadWindows(idx + 1, data);
      }

      data.start = start;
      var token = this.get('sessionAccount').get('token');

      _jquery.default.ajax({
        url: _environment.default.serverURL + '/time-windows/render',
        data: JSON.stringify(data),
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        }
      }).then(function (results) {
        self.get('store').pushPayload(results);
        var windows = [];
        results.timeWindows.forEach(function (w) {
          windows.pushObject(self.get('store').peekRecord('time-window', w.id));
        }); // A-8699 :: Okna dublowały się podczas zmiany okresu wyświetlania transportów w harmonogramie

        var savedWindows = self.get('windows');
        savedWindows.pushObjects(windows);

        var uniqueWindows = _toConsumableArray(new Set(savedWindows));

        self.set('windows', uniqueWindows);
        self.get('warehouse').get('loadedDays').pushObject(start);
        self.loadWindows(idx + 1, data);
      });
    },
    setPalletSummary: function setPalletSummary(day) {
      var _this = this;

      if (!this.get('hasAccessToMaxPallet') && !this.get('hasAccessToMaxPalletPerRamp')) {
        return;
      }

      this.getPalletSummary(day).then(function (response) {
        if (_this.get('hasAccessToMaxPallet')) {
          day.set('numberOfPallets', response.numberOfPallets);
        }

        if (_this.get('hasAccessToMaxPalletPerRamp')) {
          day.set('numberOfPalletsPerRamps', response);
        }
      }).catch(function (e) {
        console.error(e);
      });
    },
    getPalletSummary: function getPalletSummary(day) {
      var token = this.get('sessionAccount').get('token');
      var warehouseId = this.get('warehouse.id');
      return new Ember.RSVP.Promise(function (resolve) {
        var data = {
          day: day.get('name')
        };

        _jquery.default.ajax({
          url: "".concat(_environment.default.serverURL, "/warehouses/").concat(warehouseId, "/palletSummary"),
          type: 'POST',
          crossDomain: true,
          data: JSON.stringify(data),
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (response) {
          resolve(response);
        });
      });
    },
    setNumberOfWindows: function setNumberOfWindows(day) {
      if (!this.get('hasAccessToNumberOfWindows')) {
        return;
      }

      this.getNumberOfWindows(day).then(function (response) {
        return day.set('numberOfWindows', response.numberOfWindows);
      });
    },
    getNumberOfWindows: function getNumberOfWindows(day) {
      var token = this.get('sessionAccount').get('token');
      var warehouseId = this.get('warehouse.id');
      return new Ember.RSVP.Promise(function (resolve) {
        var data = {
          day: day.get('name')
        };

        _jquery.default.ajax({
          url: "".concat(_environment.default.serverURL, "/warehouses/").concat(warehouseId, "/numberOfWindows"),
          type: 'POST',
          crossDomain: true,
          data: JSON.stringify(data),
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (response) {
          resolve(response);
        });
      });
    },
    transportCanBeCreatedInThisWarehouse: function transportCanBeCreatedInThisWarehouse(transport, ramp) {
      var allTransportWarehousesId = transport.get('steps').getEach('warehouse.id');
      var warehouseId = ramp.get('warehouse.id');
      return allTransportWarehousesId.includes(warehouseId);
    },
    handleGridSize: function handleGridSize(newGridHeight) {
      this.set('gridHeight', newGridHeight);
      var gridHeightFormatted = "".concat(newGridHeight, "px");
      var timeLabel = (0, _jquery.default)('.time-label');
      timeLabel.css('height', gridHeightFormatted);
      timeLabel.css('lineHeight', gridHeightFormatted);
      (0, _jquery.default)('.time-slot').css('height', gridHeightFormatted);
      (0, _jquery.default)('.minutes-slot ').css('height', gridHeightFormatted);
    },
    actions: {
      timeBarRendered: function timeBarRendered() {
        // Jeżeli użytkownik ręcznie zmienił ustawienia przybliżenia (poprzez slider), musimy tę wartość używać
        // przy każdym renderowaniu harmonogramu -- po zmianie dnia itp.
        if (this.get('hasZoomBeenSetManually')) {
          var delta = jQuery('#slider').slider('option', 'value');
          this.send('handleGridChange', delta, true);
          return;
        }

        this.send('resetGrid');
      },
      handleGridChange: function handleGridChange() {
        var zoomInPercent = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 100;
        var hasBeenSetManually = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var defaultGridHeight = this.defaultGridHeight();
        var defaultCssHeight = this.defaultCssHeight();
        var newCssHeight = zoomInPercent / 100 * defaultCssHeight;
        var newGridHeight = zoomInPercent / 100 * defaultGridHeight;
        console.debug("Ustawiamy wysoko\u015B\u0107 wierszy na ".concat(zoomInPercent, "% (").concat(newCssHeight, "px, ").concat(newGridHeight, "px); zmiana manualna: ").concat(hasBeenSetManually, "..."));
        this.set('hasZoomBeenSetManually', hasBeenSetManually);
        this.set('cssHeight', newCssHeight);
        this.handleGridSize(newGridHeight);
      },
      resetGrid: function resetGrid() {
        // Liczba slotów -- 14:00, 14:15 itp.
        var numberOfTimeSlots = (0, _jquery.default)('.time-label').length; // Domyślna wysokość slotu -- liczona niestety trochę na piechotę

        var gridHeight = this.defaultGridHeight(); // Wysokość harmonogramu gdybyśmy nie wykonali żadnej akcji

        var defaultScheduleHeight = gridHeight * numberOfTimeSlots;
        var scheduleOffsetTop = (0, _jquery.default)('.schedule-calendar').offset().top;
        var legendHeight = (0, _jquery.default)('.legend').outerHeight() || 0;
        var footerHeight = (0, _jquery.default)('.footer').outerHeight() || 0; // Sekcja "Po kliknięciu w wolne pole..."

        var scheduleFooterHeight = (0, _jquery.default)('.schedule-footer').height() || 0; // Margines sprawiający, że harmonogram nie zajmie jednak 100% widocznego ekranu -- ta wartość nie jest
        // w żaden sposób obliczana, ustalamy ją "na oko"

        var offsetHeight = 15; // Wysokość dostępnego ekranu to miejsce, w którym mogą wyświetlić się okienka -- a więc bez nagłówków, stopek, legend itp.

        var availableDisplayHeight = (0, _jquery.default)(window).height() - scheduleOffsetTop - legendHeight - footerHeight - scheduleFooterHeight - offsetHeight; // Jeżeli wyświetlony domyślnie harmonogram będzie dłuższy niż wyświetlacz -- pojawi się scroll
        // -- nie ruszamy go, nie chcemy go skracać, bo sloty staną się zbyt niskie

        if (defaultScheduleHeight > availableDisplayHeight) {
          this.set('defaultZoom', 100);
          this.send('setDefaultZoom');
          return;
        } // Raz określony poziom przybliżenia (zoom) zachowujemy, żebyśmy mogli do niego resetować slider,
        // jeżeli użytkownik kliknie "Resetuj"


        if (this.get('defaultZoom')) {
          this.send('setDefaultZoom');
          return;
        }

        console.debug('Zwiększamy powiększenie harmonogramu, żeby wypełnił całą dostępną przestrzeń...'); // Jakie musi być przybliżenie, żeby sloty zajęły pełen dostępny obszar wyświetlacza?
        // gridHeight -- 100%
        //       zoom -- x%

        var zoom = availableDisplayHeight / numberOfTimeSlots * 100 / gridHeight;
        this.set('defaultZoom', zoom);
        this.send('setSlider', zoom);
      },
      setDefaultZoom: function setDefaultZoom() {
        this.send('setSlider', this.get('defaultZoom'));
      },
      setSlider: function setSlider(value) {
        jQuery('#slider').slider('option', 'value', value);
      },
      spotClicked: function spotClicked(d, r, h, m) {
        console.debug("Spot ".concat(d, ", ").concat(r, ", ").concat(h, ", ").concat(m, " clicked."));
        var selector = "div[data-schedule-date='".concat(d, "'] div[data-schedule-ramp='").concat(r, "'] div[data-schedule-hour='").concat(h, "'] div[data-schedule-minute='").concat(m, "']");
        var $div = (0, _jquery.default)(selector);
        this.send('handleClick', $div);
      },
      dedicatedSpotClicked: function dedicatedSpotClicked(event) {
        var $dedicatedWindowDiv = (0, _jquery.default)(document.elementFromPoint(event.clientX, event.clientY));

        if (!$dedicatedWindowDiv.closest('.window-state-dedicated')) {
          return;
        }

        $dedicatedWindowDiv.hide();
        var $spotDiv = this.findMinutesDiv(event.clientX, event.clientY);
        $dedicatedWindowDiv.show();
        this.send('handleClick', $spotDiv);
      },

      /**
       * Obsługa kliknięcia w puste miejsce w harmonogramie (podczas tworzenia awizacji albo blokady).
       *
       * @param $div - div, w który kliknął użytkownik
       */
      handleClick: function handleClick($div) {
        if (!$div.hasClass('minutes-' + this.get('timeWindowOffsetIntervalSetting'))) {
          return;
        }

        var isTransportWindow = $div.find('.window-state-with-transport').length;
        var isBlockadeWindow = $div.find('.window-state-disabled').length;
        var isDedicatedWindow = $div.find('.window-state-dedicated').length || $div.find('.window-state-dedicated-template').length;
        var transportToHandle = this.get('transportToHandle');
        var dedicatedTimeWindowToHandle = this.get('dedicatedTimeWindowToHandle'); // kliknięcie w okno dedykowane tworzy awizację tylko, gdy jesteśmy w trakcie zapisywania awizacji;
        // w przeciwnym razie, okno dedykowane powinno zostać usunięte (po potwierdzeniu przez użytkownika).

        if (isDedicatedWindow && !transportToHandle) {
          return;
        } // FIXME:
        // na urządzeniach dotykowych, gdy naciśniemy na okno blokady
        // lub okno z transportem to zostaje wywołana akcja `spotClicked` na slocie czasowym
        // pod tym naciśniętym oknem blokady lub transportu
        // przez co aplikacja myśli, że chcemy utworzyć blokadę okna na istniejącym już oknie
        // wynika to prawdopodobnie z tego, że div'y time-slot są zawsze nad oknami w DOM-ie.
        // Trzeba byłoby to w przyszłości poprawić
        // (zarówno poniższy kod jak i hierarchię renderowania tych elementów w DOM-ie).


        if (isBlockadeWindow || isTransportWindow) {
          return;
        }

        var warehouse = this.get('warehouse');
        var timeWindowMustOccupyEntireRamp = warehouse.get('timeWindowMustOccupyEntireRamp');
        var start = this.getStartDate($div);
        var stop;

        if (timeWindowMustOccupyEntireRamp) {
          start = warehouse.getStartOnDay(start);
          stop = warehouse.getEndOnDay(start);
        } else {
          var defaultWindowLength = warehouse.get('timeWindowDefaultLengthInMinutes');
          var windowLength = this.get('editedWindow.id') ? this.get('editedWindow.durationInMinutes') : defaultWindowLength;
          stop = moment(start).add(windowLength, 'minutes');
        }

        var rampId = this.getRampId($div);
        var initialStart = this.getStartDate($div);

        if (transportToHandle) {
          this.send('handleClickWithTransport', start, stop, rampId, initialStart);
        } else if (dedicatedTimeWindowToHandle) {
          this.send('handleClickDedicatedWindow', start, stop, rampId, initialStart);
        } else {
          this.send('createWindow', start, stop, rampId, initialStart);
        }
      },
      onResize: function onResize(event, ui) {
        var self = this;
        var element = (0, _jquery.default)(ui.element);
        var windowId = element.data('schedule-window');
        var left = element.offset().left - (0, _jquery.default)(document).scrollLeft();
        var top = element.offset().top + element.outerHeight() - (0, _jquery.default)(document).scrollTop();
        var windowModel = this.store.peekRecord('timeWindow', windowId);
        var div = this.findMinutesDiv(left + 10, top + 2);
        var newStop;

        if (div) {
          newStop = this.getDate(div);
        } else {
          newStop = moment(windowModel.get('formattedStop') + this.get('warehouse').get('workEndHour'), this.dateUtils.getBaseDateFormat() + ' ' + this.dateUtils.getBaseHourFormat());
        }

        var oldStop = windowModel.get('stop');
        windowModel.set('stop', newStop);
        console.log("Pr\xF3ba rozci\u0105gni\u0119cia okna ".concat(windowId, ": ").concat(oldStop, " -> ").concat(newStop, ".."));
        var canResize = false;

        if (windowModel.get('isDedicated') && this.get('sessionAccount').hasRole('ROLE_RESIZE_DEDICATED_WINDOWS')) {
          canResize = true;
        } else if (windowModel.get('isBlocked') && this.get('sessionAccount').hasRole('ROLE_RESIZE_BLOCKED_WINDOWS')) {
          canResize = true;
        } else if (windowModel.get('transport.content')) {
          canResize = windowModel.get('transport.content').actionCanBePerformed('RESIZE_ADVICE_WINDOW');
        }

        if (!canResize) {
          windowModel.set('stop', oldStop);
          self.replaceTimeWindow(windowModel);
          return;
        }

        if (self.get('isTemplate')) {
          if (this.modelOverlapsAnyWindow(windowModel)) {
            console.log('windows overlap');
            windowModel.set('stop', oldStop);
          }

          self.replaceTimeWindow(windowModel);
          self.send('removePopupDialog');
          self.set('inProgress', false);
          return;
        }

        windowModel.lockForEditing();
        var transport = windowModel.get('transport');

        if (transport && transport.get('id')) {
          windowModel.set('updateAllFuturePeriodicWindows', this.updateAllFuturePeriodicTransports(transport));
        }

        windowModel.save().then(function (w) {
          self.send('removePopupDialog');
          self.reloadTimeWindow(windowId);

          if (w.get('transport.id')) {
            w.get('transport').then(function (t) {
              t.reload().then(function () {
                self.unloadDeletedRecords('transport', t.id, 'checkpoint');
                windowModel.unlockForEditing();
                console.debug("Zako\u0144czyli\u015Bmy aktualizacj\u0119 okna ".concat(windowId, " z transportem ").concat(t.get('logString'), "."));
              });
            });
          } else {
            windowModel.unlockForEditing();
            console.debug("Zako\u0144czyli\u015Bmy aktualizacj\u0119 okna ".concat(windowId, "."));
          }
        }, function (error) {
          windowModel.rollbackAttributes();
          windowModel.save();
          self.reloadTimeWindow(windowId);
          self.setProperties({
            inProgress: false,
            errors: error.errors
          });
          windowModel.unlockForEditing();
        });
      },
      onStart: function onStart(event) {
        // to jest potrzebne do unikania klikania na okno w momencie gdy jest przeciagane
        (0, _jquery.default)(event.target).addClass('noclick');
      },
      setCompany: function setCompany(item) {
        var self = this;
        this.store.findRecord('company', item.id).then(function (companyPayload) {
          self.set('company', companyPayload);
        });
      },
      clearDedicatedSelects: function clearDedicatedSelects() {
        this.setProperties({
          company: null,
          selectedUserGroup: null
        });
      },
      notifyWindowFocusIn: function notifyWindowFocusIn(window, position) {
        this.set('position', position);
        this.set('focusedWindowId', window.id);
      },
      notifyWindowFocusOut: function notifyWindowFocusOut(window) {
        if (this.get('focusedWindowId') === window.id) {
          this.set('focusedWindowId', null);
        }
      }
    },
    modelOverlapsAnyWindow: function modelOverlapsAnyWindow(windowModel) {
      var windowData = {
        id: windowModel.get('id'),
        start: windowModel.get('start'),
        stop: windowModel.get('stop'),
        ramp: {
          id: windowModel.get('ramp').get('id')
        }
      };
      return this.overlapsAnyWindow(windowData);
    },
    overlapsAnyWindow: function overlapsAnyWindow(window) {
      var rampId = window.ramp.id;
      var newStart = window.start;
      var newStop = window.stop;
      var overlappingWindows = this.get('windows').filter(function (w) {
        return parseInt(w.get('id')) !== parseInt(window.id) && parseInt(w.get('ramp').get('id')) === parseInt(rampId) && !(w.get('stop') <= newStart || w.get('start') >= newStop);
      });
      return overlappingWindows.length > 0;
    },
    windowsSwappingBlocked: function windowsSwappingBlocked(sourceWindow, targetWindow) {
      if (targetWindow && targetWindow.get('model') && !targetWindow.get('model').get('isDedicated')) {
        if (parseInt(sourceWindow.get('model.transport.carrierCompany.id')) === parseInt(targetWindow.get('model.transport.carrierCompany.id'))) {
          this.swapWindowPosition(sourceWindow, targetWindow);
          return false;
        }

        return true;
      }

      return false;
    },
    restorePreviousWindowPlace: function restorePreviousWindowPlace(window) {
      var windowModel = window.get('model');
      windowModel.rollbackAttributes();
      windowModel.unlockForEditing();
      windowModel.setProperties({
        ramp: window.get('oldRamp')
      });
    },
    replaceTimeWindow: function replaceTimeWindow(result) {
      var existingWindows = this.get('windows').filter(function (w) {
        return w.id === result.id;
      });

      if (existingWindows) {
        this.get('windows').removeObjects(existingWindows);
      }

      this.get('windows').pushObject(result);
    },
    reloadTimeWindow: function reloadTimeWindow(windowId) {
      console.debug("Reloading window ".concat(windowId, ".."));
      var self = this;
      this.store.findRecord('timeWindow', windowId, {
        reload: true
      }).then(function (data) {
        var changeIdx = self.get('windows').indexOf(data);
        self.get('windows').replace(changeIdx, 1, [data]);
        self.set('inProgress', false);
        console.log("after reload / id: ".concat(data.get('id'), " / changeIdx: ").concat(changeIdx, " / start: ").concat(data.get('start'), " / stop: ").concat(data.get('stop'), " / ramp: ").concat(data.get('ramp').get('name')));
      });
    },
    pushTimeWindow: function pushTimeWindow(windowId) {
      console.debug("Pushing window ".concat(windowId, ".."));
      var self = this;
      this.store.findRecord('timeWindow', windowId, {
        reload: true
      }).then(function (data) {
        var changeIdx = self.get('windows').indexOf(data);

        if (changeIdx >= 0) {
          self.get('windows').replace(changeIdx, 1, [data]);
          self.set('inProgress', false);
          console.log("After reload / id: ".concat(data.get('id'), " / changeIdx: ").concat(changeIdx, " / start: ").concat(data.get('start'), " / stop: ").concat(data.get('stop'), " / ramp: ").concat(data.get('ramp').get('name')));
        } else {
          console.log("Adding window ".concat(data.get('id'), ": start: ").concat(data.get('start'), " / stop: ").concat(data.get('stop'), " / ramp: ").concat(data.get('ramp').get('name'), ".."));

          if (!data.get('step.id')) {
            self.get('windows').pushObject(data);
            return;
          }

          data.get('step').then(function (s) {
            console.debug("Step ".concat(s.get('id'), " loaded."));
            s.get('transport').then(function (t) {
              t.reload().then(function (reloadedTransport) {
                console.debug("Transport ".concat(reloadedTransport.get('id'), " reloaded."));
                self.get('windows').pushObject(data);
              });
            });
          });
        }
      });
    },
    findMinutesDiv: function findMinutesDiv(left, top) {
      try {
        if (left < 50) {
          left += 50; // zabezpieczenie przed dojechaniem do lewej krawedzi ekranu
        }

        var topElement = (0, _jquery.default)(document.elementFromPoint(left, top));
        var visibility = topElement.css('visibility');

        if (topElement.hasClass('ember-application') || topElement.hasClass('schedule-container') || topElement.hasClass('footer')) {
          return null; // okno wyjechalo poza harmonogram, nie chce wywalac bledu, zeby wykonaly sie wszystkie
          // topElement.css('visibility', visibility);
        }

        if (!topElement.hasClass('minutes-slot')) {
          topElement.css('visibility', 'hidden'); // zabezpiecznie przed memory leak

          if (top < 0) {
            return null;
          }

          var bottomElement = this.findMinutesDiv(left, top);
          topElement.css('visibility', visibility);
          topElement = bottomElement;
        }

        return topElement;
      } catch (e) {
        console.error(e);
        return null;
      }
    },
    getDate: function getDate(div) {
      var $minuteDiv = (0, _jquery.default)(div);
      var $hourDiv = $minuteDiv.parent();
      var $rampDiv = $hourDiv.parent();
      var $dayDiv = $rampDiv.parent();
      var dateString = $dayDiv.data('schedule-date');
      var h = $hourDiv.data('schedule-hour');
      var m = $minuteDiv.data('schedule-minute');
      var date = moment("".concat(dateString, " ").concat(h, " ").concat(m), 'YYYY-MM-DD HH:mm');
      return date.toDate();
    },
    getRampId: function getRampId(div) {
      return (0, _jquery.default)(div).closest('.ramp').data('schedule-ramp');
    },
    swapWindowPosition: function swapWindowPosition(sourceWindow, targetWindow) {
      var _this2 = this;

      var self = this;
      this.set('windowSwapping', true);

      if (targetWindow.get('model.stop') - targetWindow.get('model.start') !== sourceWindow.get('currentLengthInMS')) {
        var _message = this.get('intl').t('ramp.errors.wrongSwappedWindowSize');

        this.send('showPopupDialog', 'dialogs.message-popup-dialog', sourceWindow.set('detail', _message));
        this.restorePreviousWindowPlace(sourceWindow);
        this.set('windowSwapping', false);
        return;
      }

      var message = this.get('intl').t('ramp.wantToSwapWindow');

      if (confirm(message)) {
        sourceWindow.get('model').setProperties({
          'isSwapped': true,
          isLockedForEditing: true
        });
        targetWindow.get('model').setProperties({
          ramp: sourceWindow.get('oldRamp'),
          start: sourceWindow.get('oldStart'),
          stop: sourceWindow.get('oldStop'),
          isSwapped: true,
          swappedWindowId: sourceWindow.get('model.id'),
          isLockedForEditing: true
        });
        targetWindow.get('model').save().then(function () {
          self.set('windowSwapping', false);
          sourceWindow.get('model').setProperties({
            isSwapped: false,
            swappedWindowId: null,
            isLockedForEditing: false
          }); // wymuszenie odswieżenia danych o drugim oknie

          sourceWindow = _this2.get('store').findRecord('time-window', targetWindow.get('model.swappedWindowId'));
          targetWindow.get('model').setProperties({
            swappedWindowId: null,
            isSwapped: false,
            isLockedForEditing: false
          });
          console.log('Zamiana okien wykonana poprawnie.');
        }, function (response) {
          sourceWindow.get('model').setProperties({
            isSwapped: false,
            swappedWindowId: null,
            isLockedForEditing: false
          });
          targetWindow.get('model').setProperties({
            swappedWindowId: null,
            isSwapped: false,
            isLockedForEditing: false
          });
          self.restorePreviousWindowPlace(sourceWindow);
          self.restorePreviousWindowPlace(targetWindow);
          self.setProperties({
            inProgress: false,
            windowSwapping: false,
            errors: response.errors
          });
        });
      } else {
        this.restorePreviousWindowPlace(sourceWindow);
      }
    },

    /** @namespace response.estimatedWindowLengthInMinutes */
    getWindowSize: function getWindowSize(step, transport) {
      var warehouseZoneId = step.get('warehouseZone.id');

      if (!warehouseZoneId) {
        // TODO: Powinniśmy się pozbyć tego przypisywania pierwszej strefy z brzegu po zrobieniu A-5676
        var firstZoneForStep = step.get('warehouseZones.firstObject');

        if (firstZoneForStep) {
          console.log("Step ".concat(step.get('orderInTransport'), " transportu ").concat(transport.get('logString'), " nie ma ani okna, ani przypisanej strefy -- zwracamy pierwsz\u0105 dost\u0119pn\u0105, czyli ").concat(firstZoneForStep.get('name'), "..."));
          warehouseZoneId = firstZoneForStep.get('id');
        } else {
          console.error("Step ".concat(step.get('orderInTransport'), " transportu ").concat(transport.get('logString'), " nie ma w og\xF3le powi\u0105zanych stref -- nie mo\u017Cemy zapisa\u0107 okna czasowego!"));
        }
      }

      var token = this.get('sessionAccount').get('token');
      return new Ember.RSVP.Promise(function (resolve) {
        var data = {
          transportCommand: transport.get('content') ? transport.get('content').serialize() : transport.serialize()
        };
        data.transportCommand.id = transport.get('id');
        data.warehouseZone = {
          id: warehouseZoneId
        };

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/estimatedWindowLengthInMinutes',
          type: 'POST',
          crossDomain: true,
          data: JSON.stringify(data),
          contentType: 'application/json; charset=utf-8',
          dataType: 'json',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (response) {
          resolve(response);
        });
      });
    }
  });

  _exports.default = _default;
});