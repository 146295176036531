define("apollo/pods/companies/invite/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Route.extend({
    sessionAccount: service('session-account'),
    model: function model(params) {
      var self = this;
      var origin = window.location.origin + '#';
      var partnershipType = this.get('store').peekAll('partnership-type').filterBy('name', params.partnershipType).get('firstObject');
      var principalCompanyId = localStorage.getItem('principalId');
      var roleGroups = this.store.peekRecord('company', principalCompanyId).get('roleGroups');
      var userRoleGroup = this.get('sessionAccount').get('currentUser.roleGroup');
      var roleGroupInvRestriction = this.store.query('role-group-invitation-restriction', {
        roleGroup: userRoleGroup.get('id')
      });
      var companyId = params.company_id;
      return new Promise(function (resolve) {
        self.store.query('company-ramp', {
          companyId: companyId
        }).then(function (companyRamps) {
          var hasCompanyRamps = !!companyRamps.get('length');
          var defaultCompanyRamps = hasCompanyRamps ? self.get('store').query('ramp', {
            rampIds: companyRamps.getEach('ramp.id')
          }) : null;
          var defaultCompanyWarehouses = hasCompanyRamps ? self.get('store').query('warehouse', {
            warehouseIds: companyRamps.getEach('warehouse.id')
          }) : null;
          resolve(Ember.RSVP.hash({
            invitation: self.store.createRecord('invitation', {
              enabled: true,
              origin: origin,
              partnershipType: partnershipType
            }),
            companyId: companyId,
            partnershipType: partnershipType,
            roleGroups: roleGroups,
            roleGroupInvRestriction: roleGroupInvRestriction,
            defaultCompanyRamps: defaultCompanyRamps,
            defaultCompanyWarehouses: defaultCompanyWarehouses
          }));
        });
      });
    },
    renderTemplate: function renderTemplate() {
      this.render('invitations.create');
    },
    setupController: function setupController(controller, model) {
      if (model.companyId) {
        this.get('store').findRecord('company', model.companyId).then(function (company) {
          model.invitation.set('company', company);
          model.invitation.set('taxIdNumber', company.get('taxIdNumber'));
          model.invitation.set('country', company.get('country'));
        });
      }

      this.controllerFor('invitations.create').setProperties({
        model: model
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.controllerFor('invitations.create').setProperties({
          errors: [],
          invitationSuccess: null
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});