define("apollo/mixins/invitations-mixin", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Mixin.create({
    sessionAccount: service('session-account'),
    actions: {
      accept: function accept(id) {
        var self = this;
        var token = this.get('sessionAccount').get('token');
        Ember.$.ajax({
          url: _environment.default.serverURL + "/invitations/".concat(id, "/accept"),
          type: 'PUT',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          console.log("Invitation ".concat(id, " accepted."));
          self.get('store').findRecord('invitation', id).then(function () {
            self.get('sessionAccount').get('currentUser').reload();
            self.transitionToRoute('invitations.show', id);
          });
        });
      },
      reject: function reject(id) {
        var self = this;
        var token = this.get('sessionAccount').get('token');
        Ember.$.ajax({
          url: _environment.default.serverURL + "/invitations/".concat(id, "/reject"),
          type: 'PUT',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          console.log("Invitation ".concat(id, " rejected."));
          self.get('store').findRecord('invitation', id).then(function () {
            self.get('sessionAccount').get('currentUser').reload();
            self.transitionToRoute('invitations.show', id);
          });
        });
      },
      delete: function _delete(invitation) {
        var self = this;
        invitation.destroyRecord().then(function () {
          self.transitionToRoute('invitations');
        });
      }
    }
  });

  _exports.default = _default;
});