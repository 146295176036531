define("apollo/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "apollo/config/environment"], function (_exports, _emberData, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  $.support.cors = true;
  var _Ember = Ember,
      service = _Ember.inject.service;

  var _default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, _emberData.default.BuildURLMixin, {
    sessionAccount: service('session-account'),
    host: _environment.default.serverURL,
    authorize: function authorize(xhr) {
      var token = this.get('sessionAccount').get('token');

      if (!token) {
        return;
      }

      xhr.setRequestHeader('Authorization', "Bearer ".concat(token));
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
      return this.ajax(url, "PUT", {
        data: data
      });
    }
  });

  _exports.default = _default;
});