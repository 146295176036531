define("apollo/pods/components/time-window/component", ["exports", "apollo/pods/mixins/jqui-widget", "apollo/mixins/touch-mixin"], function (_exports, _jquiWidget, _touchMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Component.extend(_jquiWidget.default, _touchMixin.default, {
    sessionAccount: service('session-account'),
    languageService: service('language-service'),
    store: service(),
    intl: service(),
    SECTIONS: {
      ERP_SECTION: 'ERP_SECTION',
      ALT_SECTION: 'ALT_SECTION',
      INTERNAL_INDEX_SECTION: 'INTERNAL_INDEX_SECTION',
      REG_NUM_SECTION: 'REG_NUM_SECTION',
      CARRIER_SECTION: 'CARRIER_SECTION',
      INDEX_NAME_SECTION: 'INDEX_NAME_SECTION',
      INDEX_DOCUMENT_NUMBER_SECTION: 'INDEX_DOCUMENT_NUMBER_SECTION',
      CONTRACTOR_SECTION: 'CONTRACTOR_SECTION',
      QUANTITY_SECTION: 'QUANTITY_SECTION',
      TRAILER_REG_NUM_SECTION: 'TRAILER_REG_NUM_SECTION',
      STEP_TYPE_ARROW: 'STEP_TYPE_ARROW',
      CUSTOM_FIELDS_SECTION: 'CUSTOM_FIELDS_SECTION',
      DRIVER_NAME: 'DRIVER_NAME',
      INDEXES_DETAILS: 'INDEXES_DETAILS',
      STEP_TYPE_SYMBOL: 'STEP_TYPE_SYMBOL'
    },
    uiWidgets: ['draggable', 'resizable'],
    uiOptions: {
      draggable: ['disabled', 'addClasses', 'appendTo', 'axis', 'cancel', 'connectToSortable', 'containment', 'cursor', 'cursorAt', 'delay', 'distance', 'grid', 'handle', 'snap', 'snapMode', 'stack'],
      resizable: ['alsoResize', 'animate', 'animateDuration', 'animateEasing', 'aspectRatio', 'autoHide', 'cancel', 'containment', 'delay', 'disabled', 'distance', 'ghost', 'grid', 'handles', 'helper', 'maxHeight', 'maxWidth', 'minHeight', 'minWidth']
    },
    'draggable-containment': 'schedule-calendar',
    'draggable-start': 'onStart',
    'draggable-stop': 'onDraggableStop',
    'resizable-start': 'onStart',
    'resizable-stop': 'onResize',
    uiEvents: {
      draggable: ['create', 'start', 'drag', 'stop'],
      resizable: ['create', 'start', 'stop', 'resize']
    },
    tagName: "div",
    classNames: ['time-window-content'],
    classNameBindings: ['windowStateClass', 'autoBlockadeClass', 'lockedClass'],
    // Powoduje dociąganie okienka do wyznaczonych obszarów ('slotów') i zapobiega blokowaniu np. pomiędzy rampami.
    'draggable-snap': '.minutes-slot',
    "resizable-grid": function () {
      var gridSize = this.get('cssHeight') / (60 / this.get('timeWindowOffsetInterval'));
      return this.get('isHD') ? gridSize * 3 : gridSize;
    }.property('timeWindowOffsetInterval', 'cssHeight', 'isHD'),
    attributeBindings: ['data-schedule-window', 'data-is-supplement', 'style', 'data-window-type', 'data-window-length', 'data-transport-id'],
    style: function () {
      return Ember.String.htmlSafe("height: ".concat(this.get('height'), "px; position: absolute; width: 100%;"));
    }.property('height'),
    combinigMark: Ember.computed('window.wasDelayedOnMarkedTask', 'hasTransportCombiningRelation', function () {
      var delayed = this.get('window.wasDelayedOnMarkedTask');
      var hasCombining = this.get('hasTransportCombiningRelation');

      if (delayed && hasCombining) {
        return Ember.String.htmlSafe('mark-delayed mark-has-transport-combining-with-delayed');
      } else {
        if (delayed) {
          return Ember.String.htmlSafe('mark-delayed');
        }

        if (hasCombining) {
          return Ember.String.htmlSafe('mark-has-transport-combining');
        }
      }

      return Ember.String.htmlSafe('');
    }),
    height: function () {
      var cssHeight = this.get('cssHeight');
      var slotHeight = this.get('isHD') ? cssHeight * 3 : cssHeight;
      var minSlotHeight = slotHeight / 4;
      var numberOfMinSlotsInWindow = this.get('window').get('durationInMinutes') / 15;

      if (this.get('isSupplement')) {
        if (this.get('isLast')) {
          var warehouseStart = moment(this.window.get('stop')).set({
            h: this.window.get('ramp.warehouse.workStartHour'),
            m: 0
          });
          var windowStop = moment(this.window.get('stop'));
          numberOfMinSlotsInWindow = windowStop.diff(warehouseStart, 'minutes');
        } else {
          var windowDay = moment(this.window.get('start')).add(this.get('shift'), 'days');

          var _warehouseStart = moment(windowDay).set({
            h: this.window.get('ramp.warehouse.workStartHour'),
            m: 0
          });

          var warehouseStop = moment(windowDay).set({
            h: this.window.get('ramp.warehouse.workEndHour'),
            m: 0
          });
          numberOfMinSlotsInWindow = warehouseStop.diff(_warehouseStart, 'minutes');
        }

        numberOfMinSlotsInWindow /= 15;
      }

      var height = numberOfMinSlotsInWindow * minSlotHeight; // W przypadku pół godzinnych okien, kończących się w połowie godziny,
      // kolejne okno w harmonogramie przysłaniało handle do zmiany wielkości okna [A-10608].
      // Odjęcie 1px od wysokości okna rozwiązuje ten problem.

      if (numberOfMinSlotsInWindow === 2 && this.window.get('stop').getMinutes() === 30) {
        height -= 1;
      }

      return height;
    }.property('window.durationInMinutes', 'isHD', 'cssHeight'),
    'data-schedule-window': function () {
      return "" + this.window.id;
    }.property('window.id'),
    'data-transport-id': Ember.computed('window.transport.id', function () {
      return '' + this.get('window.transport.id');
    }),
    'data-window-type': function () {
      return this.get('window.computedState');
    }.property('window.computedState'),
    'data-window-length': function () {
      return "" + this.get('window.durationInMinutes');
    }.property('window.durationInMinutes'),
    'data-is-supplement': function () {
      return this.get('isSupplement') === true;
    }.property('isSupplement'),
    isDedicated: function () {
      return !!this.get("window").get("company").get("id") || !!this.get("window").get("userGroup.id");
    }.property('window.company.id', 'window.userGroup.id'),
    'resizable-handles': "s",
    'resizable-containment': ".schedule-body",
    windowStateClass: function () {
      return "window-" + this.get('window.computedState');
    }.property('window.computedState'),
    autoBlockadeClass: function () {
      return this.get('window.isAutoBlockade') ? 'auto-blockade' : null;
    }.property('window.isAutoBlockade'),
    lockedClass: Ember.computed('window.isLockedForEditing', function () {
      return this.get('window.isLockedForEditing') ? 'is-locked' : null;
    }),
    color: function () {
      return this.get('window.color');
    }.property('window.color'),
    grabberColor: Ember.computed('window.color', function () {
      return this.get('window.grabberColor');
    }),
    isOnDisabledState: function () {
      return this.get("window.isDisabled");
    }.property('window.isDisabled'),
    canSeeNote: function () {
      return this.get('isOnDisabledState') && !this.get('isDedicated') && !this.get('window.isAutoBlockade') && this.get('sessionAccount').hasRole('ROLE_READ_WINDOW_NOTES');
    }.property('isOnDisabledState', 'isDedicated', 'window.isAutoBlockade'),
    removeBlockedWindow: 'removeBlockedWindow',
    showTransportDetails: 'showTransportDetails',
    showLoadingModal: 'showLoadingModal',
    dedicatedSpotClicked: 'dedicatedSpotClicked',
    createWindow: 'createWindow',
    transportType: Ember.computed('window.transportType', function () {
      return this.get('window.transportType');
    }),
    scheduleSetting: Ember.computed('transportType.scheduleSetting', function () {
      return this.get('transportType.scheduleSetting');
    }),
    isOneDayView: Ember.computed('viewedDays', function () {
      return this.get('viewedDays') === 1;
    }),
    shouldShowLeftAreaSection: Ember.computed('isOneDayView', 'leftSideAreaSection', function () {
      return this.get('leftSideAreaSection') && this.get('isOneDayView');
    }),
    windowContentWidth: Ember.computed('shouldShowLeftAreaSection', 'isHD', function () {
      if (this.get('shouldShowLeftAreaSection')) {
        return 'width-90';
      }

      return '';
    }),
    showMiddleSection: function () {
      if (this.get('window.durationInMinutes') >= 60) {
        return true;
      }

      if (this.get('viewedColumns') >= 7) {
        return false;
      }

      if (!this.get('topLeftSection')) {
        return true;
      }

      if (this.get('scheduleSetting.topMiddle') === this.get('SECTIONS.CARRIER_SECTION')) {
        return this.get('window.companyShownInSchedule.id') !== this.get('window.transport.carrierCompany.id');
      }

      return true;
    }.property('viewedColumns', 'topLeftSection', 'window.durationInMinutes', 'scheduleSetting.topMiddle'),
    contractorsGroups: Ember.computed('window.companyShownInSchedule.partnershipWithPrincipal.contractorsGroups', function () {
      return this.get('window.companyShownInSchedule.partnershipWithPrincipal.contractorsGroups');
    }),
    topLeftSection: Ember.computed('scheduleSetting.topLeft', 'window.lastUpdated', function () {
      var s = this.get('scheduleSetting.id') ? this.get('scheduleSetting.topLeft') : this.get('SECTIONS.CONTRACTOR_SECTION');
      return this.getSectionContent(s);
    }),
    topMiddleSection: Ember.computed('scheduleSetting.topMiddle', 'window.lastUpdated', function () {
      var s = this.get('scheduleSetting.id') ? this.get('scheduleSetting.topMiddle') : this.get('SECTIONS.CARRIER_SECTION');
      return this.getSectionContent(s);
    }),
    topRightSection: Ember.computed('scheduleSetting.topRight', 'window.lastUpdated', function () {
      var s = this.get('scheduleSetting.id') ? this.get('scheduleSetting.topRight') : this.get('SECTIONS.REG_NUM_SECTION');
      return this.getSectionContent(s);
    }),
    bottomLeftSection: Ember.computed('scheduleSetting.bottomLeft', 'window.lastUpdated', function () {
      var s = this.get('scheduleSetting.id') ? this.get('scheduleSetting.bottomLeft') : this.get('SECTIONS.QUANTITY_SECTION');
      return this.getSectionContent(s);
    }),
    bottomMiddleSection: Ember.computed('scheduleSetting.bottomMiddle', 'window.lastUpdated', function () {
      return this.getSectionContent(this.get('scheduleSetting.bottomMiddle'));
    }),
    bottomRightSection: Ember.computed('scheduleSetting.bottomRight', 'window.lastUpdated', function () {
      var s = this.get('scheduleSetting.id') ? this.get('scheduleSetting.bottomRight') : this.get('SECTIONS.ERP_SECTION');
      return this.getSectionContent(s);
    }),
    leftSideAreaSection: Ember.computed('scheduleSetting.leftSideArea', 'window.lastUpdated', function () {
      return this.getSectionContent(this.get('scheduleSetting.leftSideArea'));
    }),
    transportCombiningSymbol: Ember.computed('scheduleSetting.transportCombiningSymbol', 'window.lastUpdate', function () {
      return this.get('scheduleSetting.transportCombiningSymbol');
    }),
    hasRelatedTransports: Ember.computed('window.transport.relatedToTransports.length', function () {
      return this.get('window.hasAnyTransportRelation');
    }),
    hasTransportCombiningRelation: Ember.computed('window.transport.@each.relatedTo', function () {
      return this.get('window.hasAnyTransportCombiningRelation');
    }),
    CONTRACTOR_SECTION: Ember.computed('window.{CONTRACTOR_COUNTRY_SECTION,CONTRACTOR_NAME_SECTION,CONTRACTOR_GROUP_SECTION}', function () {
      var s = "";
      s += this.get('window.CONTRACTOR_COUNTRY_SECTION');

      if (this.get('showLongName')) {
        s += " ".concat(this.get('window.CONTRACTOR_NAME_SECTION'));
      } else {
        s += this.get('window.CONTRACTOR_NAME_SECTION') ? " ".concat(this.get('window.CONTRACTOR_NAME_SECTION').slice(0, 5)) : "";
      }

      if (this.get('window.CONTRACTOR_GROUP_SECTION')) {
        s += " ".concat(this.get('window.CONTRACTOR_GROUP_SECTION'));
      }

      return s;
    }),
    STEP_TYPE_SECTION: Ember.computed('window.STEP_TYPE_ARROW', function () {
      if (this.get('window.STEP_TYPE_ARROW') === "unload") {
        return Ember.String.htmlSafe('<i class="fa fa-long-arrow-down step-type-arrow"></i>');
      }

      return Ember.String.htmlSafe('<i class="fa fa-long-arrow-up step-type-arrow"></i>');
    }),
    STEP_TYPE_SECTION_SYMBOL: Ember.computed('window.STEP_TYPE_SYMBOL', function () {
      var load_symbol = "<i class=\"".concat(this.get('scheduleSetting.stepTypeSymbolLoad'), " margin-left\"></i>");
      var unload_symbol = "<i class=\"".concat(this.get('scheduleSetting.stepTypeSymbolUnload'), " margin-left\"></i>");

      if (this.get('window.STEP_TYPE_SYMBOL') === "load") {
        return Ember.String.htmlSafe(load_symbol);
      }

      return Ember.String.htmlSafe(unload_symbol);
    }),
    CUSTOM_FIELDS_SECTION: Ember.computed('window.CUSTOM_FIELDS_SECTION', function () {
      var jsonArray = JSON.parse(this.get('window.CUSTOM_FIELDS_SECTION'));
      var propertyName = this.get('languageService').findProperLanguageVersion('local', 'english');
      return jsonArray.map(function (o) {
        return o[propertyName];
      }).filter(function (o) {
        return o;
      }).join(", ");
    }),
    getSectionContent: function getSectionContent(s) {
      if (!s) {
        return "";
      }

      if (s === this.get('SECTIONS.CONTRACTOR_SECTION')) {
        return this.get('CONTRACTOR_SECTION');
      }

      if (s === this.get('SECTIONS.STEP_TYPE_ARROW')) {
        return this.get('STEP_TYPE_SECTION');
      }

      if (s === this.get('SECTIONS.STEP_TYPE_SYMBOL')) {
        return this.get('STEP_TYPE_SECTION_SYMBOL');
      }

      if (s === this.get('SECTIONS.INDEXES_DETAILS')) {
        return this.get('SECTIONS.INDEXES_DETAILS');
      }

      if (s === this.get('SECTIONS.CUSTOM_FIELDS_SECTION')) {
        return this.get('CUSTOM_FIELDS_SECTION');
      }

      return this.get('window').get(s);
    },
    showContent: function () {
      if (this.get('isOnDisabledState')) {
        return false;
      }

      if (this.window.get('durationInMinutes') >= 60) {
        return true;
      }

      return this.window.get('durationInMinutes') >= 30 && this.get('viewedColumns') < 15;
    }.property('window.durationInMinutes', 'viewedColumns', 'isOnDisabledState'),
    toggleTimeWindowGrabber: function toggleTimeWindowGrabber(target) {
      target[0].style.setProperty('--grabber-color', this.get('grabberColor'));
      target.parent().css('overflow', 'hidden');
      target.toggleClass('time-window-grabber');
    },
    handleOpenWindowDetails: function handleOpenWindowDetails() {
      var _this = this;

      if (!this.get('sessionAccount').hasRole('ACCESS_TO_TRANSPORT_DETAILS') || !this.get('transportType.canBeSeenByRoleGroup')) {
        console.debug('Brak dostępu do szczegółów transportów');
        alert(this.get('intl').t('common.noAuthority'));
        return;
      }

      this.sendAction('showLoadingModal');
      var transportIdToFetch = this.window.get('relatedTransport.id') ? this.window.get('relatedTransport.id') : this.window.get('transport.id');
      this.get('store').findRecord('transport', transportIdToFetch).then(function (transport) {
        transport.get('periodicTransport').then(function () {
          _this.sendAction('showTransportDetails', transport);
        });
      });
    },
    handleWindowClick: function handleWindowClick(event) {
      var transport = this.get('transportToHandle');

      if (transport) {
        var userGroupId = this.get('window.userGroup.id');

        if (userGroupId && this.get('sessionAccount').get('currentUser.userGroup.id') !== userGroupId) {
          return false;
        }

        if (this.canTransportAccessDedicatedWindow(transport)) {
          this.sendAction('dedicatedSpotClicked', event);
        }
      } else if (this.get('isOnDisabledState') || this.get('isDedicated') === true) {
        this.sendAction('removeBlockedWindow', this.window);
      } else if (this.window.get('step.id')) {
        this.handleOpenWindowDetails();
      }
    },
    handleClickForTouchDevice: function handleClickForTouchDevice(event) {
      var _this2 = this;

      this.handleTap(event, function () {
        _this2.handleWindowClick(event);
      }, function () {
        var target = $(event.target).next('.ui-resizable-handle');

        if (target.length) {
          _this2.toggleTimeWindowGrabber(target);
        }
      });
    },
    click: function click(event) {
      if (this.isTouchDevice) {
        this.handleClickForTouchDevice(event);
        return event.stopPropagation();
      }

      if (!this.isTouchDevice) {
        if ($(event.target).hasClass('is-locked') || $(event.target).parents('.is-locked').length) {
          console.debug('Okno jest zablokowane do edycji.');
          return false;
        }

        if ($(event.target).hasClass('noclick') || $(event.target).parent().hasClass('noclick')) {
          $(event.target).removeClass('noclick');
          $(event.target).parent().removeClass('noclick');
        } else {
          this.handleWindowClick(event);
        }

        event.stopPropagation();
      }
    },
    timer: '',
    touchMove: function touchMove(event) {
      var _this3 = this;

      var timer = 0;
      var boundOffset = 20;
      var leftBound = boundOffset;
      var rightBound = window.screen.width - boundOffset;
      var windowPositionX = event.changedTouches[0].clientX;

      if (windowPositionX > rightBound) {
        clearTimeout(timer); // zmiana dnia harmonogramu zadziała tylko wówczas gdy
        // będziemy trzymać okno przy krawędzi dłużej niż 1s
        // ma to zapobiec przypadkowemu przesunięciu okna akcją `onDraggableStop`

        timer = setTimeout(function () {
          _this3.get('dragWindowAcrossScheduleDays')(_this3.window.get('step'), 1, $(event.target));
        }, 1000);
      } else if (windowPositionX < leftBound) {
        timer = setTimeout(function () {
          _this3.get('dragWindowAcrossScheduleDays')(_this3.window.get('step'), -1, $(event.target));
        }, 1000);
      }

      $(event.target).mouseup(function () {
        clearTimeout(timer);
      });
    },
    dragStart: function dragStart(event) {
      if ($(event.target).hasClass('is-locked') || $(event.target).parents('.is-locked').length) {
        console.debug("Okno jest zablokowane do edycji.");
        return false;
      }

      clearTimeout(this.timer);
    },
    dragEnd: function dragEnd() {
      if (this.isDestroyed) {
        return;
      }
    },
    canTransportAccessDedicatedWindow: function canTransportAccessDedicatedWindow(transport) {
      console.debug("Sprawdzamy, czy transport ".concat(transport.get('logString'), " ma prawo dost\u0119pu do okna dedykowanego ").concat(this.get('window.logString'), ".."));

      if (this.get('isDedicated')) {
        if (this.get('window.userGroup.id')) {
          return true;
        }

        var companyId = this.get('window.company.id');

        if (transport.get('carrierCompany') && transport.get('carrierCompany.id') === companyId) {
          console.debug("Okno jest dedykowane dla przewo\u017Anika ".concat(transport.get('carrierCompany.shortName'), ", kt\xF3ry jest przypisany do transportu; dost\u0119p udzielony."));
          return true;
        }

        var contractorIds = transport.get('contractorIds').filter(function (contractorId) {
          return contractorId === companyId;
        });

        if (contractorIds.length > 0) {
          console.debug("Okno jest dedykowane dla dostawcy, kt\xF3ry jest przypisany do transportu; dost\u0119p udzielony.");
          return true;
        } else {
          console.log("Transport ".concat(transport.get('logString'), " nie mo\u017Ce zosta\u0107 zapisany w oknie ").concat(this.get('window.logString'), "; odmowa dost\u0119pu."));
          return false;
        }
      } else {
        console.debug("Wybrane okno nie jest oknem dedykowanym - nie mo\u017Cna tu utworzy\u0107 awizacji.");
        return false;
      }
    },
    scheduleRender: function () {
      if ($('.minutes-slot').length > 0) {
        Ember.run.scheduleOnce('afterRender', this, this.afterRenderEvent);
      } else {
        console.debug('Harmonogram nie jest jeszcze w pełni wygenerowany - spróbujemy renderować okna za chwilę.');
        Ember.run.next(this, function () {
          this.scheduleRender();
        });
      }
    }.on('didReceiveAttrs').observes('window.ramp', 'window.start', 'window.stop', 'window.isSwapped'),
    afterRenderEvent: function afterRenderEvent() {
      try {
        var rampId = this.window.get('ramp.id');
        var startSelector;
        var startDay;
        var isSupplement = this.get('isSupplement'); // supplement - to 'końcówka' okna, która zaczyna się innego dnia (por. schedule/template.hbs); jego początek to zawsze pierwsza dostępna godzina na danej rampie

        if (isSupplement) {
          var warehouseStartHour = moment().set({
            h: this.window.get('ramp.warehouse.workStartHour'),
            m: 0
          }).format("HH");

          if (this.get('isLast')) {
            var stopAfterWarehouseStart = moment(this.window.get('stop')).format('HH:mm') > this.window.get('ramp.warehouse.workStartTime');
            if (!stopAfterWarehouseStart && !this.window.get('ramp.warehouse.isWarehouse24h')) return;
            startDay = moment(this.window.get('stop')).format('YYYY-MM-DD');
            startSelector = "slot-".concat(rampId, "-").concat(startDay, "-").concat(warehouseStartHour, "-00");
          } else {
            startDay = moment(this.window.get('start')).add(this.get('shift'), 'days').format('YYYY-MM-DD');
            startSelector = "slot-".concat(rampId, "-").concat(startDay, "-").concat(warehouseStartHour, "-00");
          }
        } else {
          startDay = moment(this.window.get('start')).format('YYYY-MM-DD-HH-mm');
          startSelector = "slot-".concat(rampId, "-").concat(startDay);
        }

        var startSlot = document.getElementById("".concat(startSelector));

        if (startSlot) {
          var $this = this.$();

          if ($this) {
            $this.appendTo(startSlot);
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.$().off('mouseenter mouseleave');
      $(this.element).remove(); // TODO: [pawel] nie wiem, czy to jest poprawne podejście
    },
    mouseEnter: function mouseEnter() {
      var position = event.clientX / event.currentTarget.clientWidth > 0.7 ? "left" : "right";
      this.notifyWindowFocusIn(this.window, position);
    },
    notifyWindowFocusIn: function notifyWindowFocusIn() {},
    mouseLeave: function mouseLeave() {
      this.notifyWindowFocusOut(this.window);
    },
    notifyWindowFocusOut: function notifyWindowFocusOut() {},
    trimTopLeft: Ember.computed('scheduleSetting.trimTopLeft', 'window.lastUpdated', function () {
      return this.get('scheduleSetting.trimTopLeft');
    }),
    trimTopMiddle: Ember.computed('scheduleSetting.trimTopMiddle', 'window.lastUpdated', function () {
      return this.get('scheduleSetting.trimTopMiddle');
    }),
    trimTopRight: Ember.computed('scheduleSetting.trimTopRight', 'window.lastUpdated', function () {
      return this.get('scheduleSetting.trimTopRight');
    }),
    trimBottomLeft: Ember.computed('scheduleSetting.trimBottomLeft', 'window.lastUpdated', function () {
      return this.get('scheduleSetting.trimBottomLeft');
    }),
    trimBottomMiddle: Ember.computed('scheduleSetting.trimBottomMiddle', 'window.lastUpdated', function () {
      return this.get('scheduleSetting.trimBottomMiddle');
    }),
    trimBottomRight: Ember.computed('scheduleSetting.trimBottomRight', 'window.lastUpdated', function () {
      return this.get('scheduleSetting.trimBottomRight');
    }),
    topLeftHellip: Ember.computed('scheduleSetting.topLeftHellip', 'window.lastUpdated', function () {
      return this.get('scheduleSetting.topLeftHellip');
    }),
    topMiddleHellip: Ember.computed('scheduleSetting.topMiddleHellip', 'window.lastUpdated', function () {
      return this.get('scheduleSetting.topMiddleHellip');
    }),
    topRightHellip: Ember.computed('scheduleSetting.topRightHellip', 'window.lastUpdated', function () {
      return this.get('scheduleSetting.topRightHellip');
    }),
    bottomLeftHellip: Ember.computed('scheduleSetting.bottomLeftHellip', 'window.lastUpdated', function () {
      return this.get('scheduleSetting.bottomLeftHellip');
    }),
    bottomMiddleHellip: Ember.computed('scheduleSetting.bottomMiddleHellip', 'window.lastUpdated', function () {
      return this.get('scheduleSetting.bottomMiddleHellip');
    }),
    bottomRightHellip: Ember.computed('scheduleSetting.bottomRightHellip', 'window.lastUpdated', function () {
      return this.get('scheduleSetting.bottomRightHellip');
    }),
    actions: {
      openNoteWindow: function openNoteWindow() {
        this.window.oldNote = this.window.get('note');
        this.openNoteWindow(this.window);
      },
      openIndexesDetailsWindow: function openIndexesDetailsWindow() {
        this.openIndexesDetailsWindow(this.get('window.transport'));
      }
    }
  });

  _exports.default = _default;
});