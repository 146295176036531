define("apollo/pods/components/table-column-settings/component", ["exports", "apollo/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    tableColumns: Ember.computed('tableColumn.[]', function () {
      return this.get('store').peekAll('tableColumn');
    }),
    labelForTransportSupervisor: Ember.computed('tableColumn.[]', function () {
      var translationKey = this.get('transportType.transportSupervisorTranslationKey');
      return this.get('intl').t('transportSupervisorTranslationKeys.' + translationKey);
    }),
    labelForWorkerPhoneNumber: Ember.computed('tableColumn.[]', 'transportType.id', function () {
      var translationKey = this.get('transportType.workerFieldTranslationKey');
      return this.get('intl').t('worker.phoneNumber.' + translationKey);
    }),
    availableColumns: Ember.computed('transportTypeRoleGroupTableColumns.[]', 'roleGroup.id', 'transportType.id', function () {
      var self = this;
      var transportTypeRoleGroupTableColumns = this.get('transportTypeRoleGroupTableColumns').filter(function (transportTypeRoleColumn) {
        return transportTypeRoleColumn.get('transportType.id') === self.get('transportType.id') && transportTypeRoleColumn.get('roleGroup.id') === self.get('roleGroup.id');
      });
      return transportTypeRoleGroupTableColumns.map(function (transportTypeRoleColumn) {
        return transportTypeRoleColumn.get('tableColumn');
      });
    }),
    availableColumnIds: Ember.computed('availableColumns.[]', function () {
      return this.get('availableColumns').map(function (c) {
        return c.get('id');
      });
    }),
    actions: {
      toggleTableColumn: function toggleTableColumn(tc) {
        var tableColumn = this.get('availableColumns').find(function (ac) {
          return ac.get('id') === tc.get('id');
        });

        if (tableColumn !== undefined) {
          this.get('availableColumns').removeObject(tableColumn);
        } else {
          this.get('availableColumns').addObject(tc);
        }
      },
      save: function save() {
        var self = this;
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          url: "".concat(_environment.default.serverURL, "/transport-type-role-group-table-columns"),
          data: JSON.stringify({
            tableColumns: self.get('availableColumnIds'),
            roleGroup: self.get('roleGroup.id'),
            transportType: self.get('transportType.id')
          }),
          type: 'PUT',
          contentType: 'application/json',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          self.set('successMessage', "".concat(self.get('intl').t('common.saveSuccessMessage') + '. ' + self.get('intl').t('common.saveSuccessAdditionalMessage')));
        }, function (response) {
          self.set('successMessage', null);
          self.set('errors', response.errors);
        });
      }
    }
  });

  _exports.default = _default;
});