define("apollo/pods/warehouse/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var Model = _emberData.default.Model,
      PromiseArray = _emberData.default.PromiseArray,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend(_modelValidator.default, {
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    users: hasMany('user', {
      async: true
    }),
    usersWithAccess: hasMany('user', {
      async: true
    }),
    ramps: hasMany('ramp', {
      async: true
    }),
    company: belongsTo('company', {
      async: true
    }),
    address: belongsTo('address', {
      async: true,
      deepEmbedded: ['country']
    }),
    warehouseZones: hasMany('warehouseZone', {
      async: true
    }),
    warehouseLocations: hasMany('warehouseLocation', {
      async: true
    }),
    assortmentGroups: hasMany('assortmentGroup', {
      async: true
    }),
    transportTypes: hasMany('transportType', {
      async: true
    }),
    idx: attr('number'),
    checkedTransportTypesIds: attr(),
    name: attr('string'),
    workStartHour: attr('number'),
    workEndHour: attr('number'),
    windowConstantTime: attr('number'),
    timeWindowOffsetInterval: attr('number', {
      defaultValue: function defaultValue() {
        return 15;
      }
    }),
    timeWindowDefaultLengthInMinutes: attr('number'),
    timeWindowMustOccupyEntireRamp: attr('boolean'),
    snapToClosestWindowInterval: attr('number', {
      defaultValue: function defaultValue() {
        return 0;
      }
    }),
    shouldFindFreeSpotAfterDelay: attr('boolean'),
    enabled: attr('boolean'),
    weighingIncluded: attr('boolean'),
    timeWindowLengthLongerThanWarehouseWorkingHours: attr('boolean'),
    validations: {
      name: {
        presence: {
          message: 'blank'
        }
      },
      windowConstantTime: {
        presence: {
          message: 'info'
        }
      },
      timeWindowOffsetInterval: {
        presence: {
          message: 'info'
        }
      },
      snapToClosestWindowInterval: {
        presence: {
          message: 'info'
        }
      },
      timeWindowDefaultLengthInMinutes: {
        presence: {
          message: 'blankSelect'
        }
      },
      timeWindowMustOccupyEntireRamp: {
        presence: {
          message: 'blank'
        }
      },
      timeWindowLengthLongerThanWarehouseWorkingHours: {
        presence: {
          message: 'blank'
        }
      },
      workStartHour: {
        numericality: {
          allowBlank: false,
          greaterThanOrEqualTo: 0,
          lessThanOrEqualTo: 23,
          onlyInteger: true,
          message: 'invalidNumber'
        }
      },
      workEndHour: {
        numericality: {
          allowBlank: false,
          greaterThanOrEqualTo: 1,
          lessThanOrEqualTo: 24,
          onlyInteger: true,
          message: 'invalidNumber'
        },
        custom: [{
          validation: function validation(key, value, model) {
            return parseInt(model.get('workStartHour')) <= parseInt(value);
          },
          message: 'endBeforeStart'
        }]
      },
      address: {
        relations: ['belongsTo']
      },
      idx: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!model.get('id')) {
              return true;
            }

            return !Ember.isEmpty(value) && value >= 0 && value < model.store.peekAll('warehouse').length;
          },
          message: function message(key, _message, self) {
            return self.get('intl').t('warehouse.errors.idx.invalidIdxNumber', {
              numberOfWarehouses: this.store.peekAll('warehouse').length - 1
            }).toString();
          }
        }]
      }
    },
    workStartTime: Ember.computed('workStartHour', function () {
      return moment(this.getStartOnDay(new Date())).format('HH:mm');
    }),
    workEndTime: Ember.computed('workEndHour', function () {
      return moment(this.getEndOnDay(new Date())).format('kk:mm');
    }),
    formattedWorkTime: Ember.computed('workStartTime', 'workEndTime', function () {
      return "".concat(this.get('workStartTime'), "-").concat(this.get('workEndTime'));
    }),
    minutes: Ember.computed('', function () {
      return [0, 15, 30, 45];
    }),
    getStartOnDay: function getStartOnDay(date) {
      var dateString = moment(date).format('YYYY-MM-DD');
      var h = this.get('workStartHour');
      return moment("".concat(dateString, " ").concat(h, ":0"), 'YYYY-MM-DD HH:mm');
    },
    getEndOnDay: function getEndOnDay(date) {
      var dateString = moment(date).format('YYYY-MM-DD');
      var h = this.get('workEndHour');
      return moment("".concat(dateString, " ").concat(h, ":0"), 'YYYY-MM-DD HH:mm');
    },
    enabledRamps: Ember.computed('ramps.@each.enabled', function () {
      return PromiseArray.create({
        promise: this.get('ramps').then(function (loadedRamps) {
          return loadedRamps.filterBy('enabled', true);
        })
      });
    }),
    sortedRamps: Ember.computed('enabledRamps.@each.idx', function () {
      return this.get('ramps').sortBy('idx:desc');
    }),
    sortedZones: Ember.computed('warehouseZones.[]', function () {
      return this.get('warehouseZones').filterBy('enabled', true).sortBy('idx');
    }),
    enabledRampsForCurrentUser: Ember.computed('sessionAccount.currentUser.ramps', function () {
      var self = this;
      return PromiseArray.create({
        promise: self.get('sessionAccount.currentUser.ramps').then(function (loadedRamps) {
          return loadedRamps.filterBy('warehouse.id', self.get('id'));
        })
      });
    }),
    loadedDays: Ember.computed('', function () {
      return [];
    }),
    nameTrimmed: Ember.computed('name', function () {
      return this.get('name').replace(/ /g, '-');
    }),
    hasAssortmentGroups: Ember.computed('assortmentGroups.[]', function () {
      return this.get('assortmentGroups.length') > 0;
    }),
    isWarehouse24h: Ember.computed('', function () {
      var d1 = new Date();
      var d2 = new Date();
      d1.setHours.apply(d1, _toConsumableArray(this.get('workStartTime').split(':')));
      d2.setHours.apply(d2, _toConsumableArray(this.get('workEndTime').split(':')));
      return Math.abs((d1 - d2) / 1000 / 3600) === 24;
    })
  });

  _exports.default = _default;
});