define("apollo/pods/warehouses/template/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service('session-account'),
    access: Ember.A(['ROLE_ACCESS_TO_WAREHOUSE_TEMPLATES']),
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      var self = this;
      var currentUser = self.get('sessionAccount').get('currentUser');
      var warehouseId = params.warehouse_id;
      var date = moment(params.date_string);
      var warehouses = currentUser.get('warehouses').filter(function (w) {
        return w.get('company.id') === localStorage.getItem('principalId');
      });
      var ramps = currentUser.get('ramps').filterBy('warehouse.id', warehouseId).sortBy('idx');
      return Ember.RSVP.hash({
        warehouses: warehouses,
        warehouse: self.store.peekRecord('warehouse', warehouseId),
        ramps: ramps,
        date: date,
        userGroups: this.get('store').query('userGroup', {
          principalCompanyId: localStorage.getItem('principalId')
        }),
        isTemplate: true
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model,
        scheduleStartDate: model.date
      });
    },
    actions: {
      willTransition: function willTransition(transition) {
        var self = this;
        self.get('controller').set('windows', null);

        if (transition.targetName !== 'warehouses.template') {
          // eslint-disable-next-line ember/no-controller-access-in-routes
          this.get('controller').setProperties({
            info: null,
            hasZoomBeenSetManually: false
          });
        } // eslint-disable-next-line array-callback-return


        self.store.peekAll('timeWindow').map(function (tw) {
          if (tw.get('isNew')) {
            self.store.unloadRecord(tw);
          }
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});