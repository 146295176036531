define("apollo/pods/components/transports/transports-table/transport-row/guard-checkpoint-fields/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service(),
    tagName: '',
    actions: {
      confirm: function confirm(checkpoint) {
        this.set('action', 'confirm');
        this.sendAction('action', checkpoint);
      },
      reject: function reject(checkpoint) {
        this.set('action', 'reject');
        this.sendAction('action', checkpoint);
      },
      showModal: function showModal(templateName, model) {
        this.set('action', 'showModal');
        this.sendAction('action', templateName, model);
      }
    }
  });

  _exports.default = _default;
});