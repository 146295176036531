define("apollo/pods/settings/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "apollo/mixins/menu-mixin"], function (_exports, _authenticatedRouteMixin, _menuMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _menuMixin.default, {
    sessionAccount: service('session-account'),
    access: ['ROLE_SETTINGS_TAB'],
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model() {
      var _this = this;

      var principalCompanyId = localStorage.getItem('principalId');
      var userRoleGroupName = this.get('sessionAccount.currentUser.roleGroup.name');
      var roleGroups = this.store.peekAll('roleGroup').filterBy('principalCompany.id', principalCompanyId);

      if (!this.get('sessionAccount').hasRole('ROLE_MANAGE_AUTHORITIES') || userRoleGroupName !== 'SUPER_USER') {
        roleGroups = roleGroups.filter(function (roleGroup) {
          return roleGroup.get('name') !== 'SUPER_USER' && roleGroup.get('name') !== 'E-MAIL_ADVICE';
        });
      }

      var gates = this.store.findAll('gate');
      var squares = this.store.findAll('square');
      return Ember.RSVP.hash({
        principalCompany: this.store.findRecord('company', principalCompanyId),
        settings: this.store.findAll('setting', {
          reload: true
        }).then(function (data) {
          if (_this.get('sessionAccount').hasRole('ROLE_MANAGE_SETTINGS')) {
            return data;
          } else {
            return data.filterBy('editable');
          }
        }),
        warehouses: this.store.findAll('warehouse', {
          reload: true
        }).then(function (data) {
          return data.sortBy('name');
        }),
        workflows: this.store.findAll('workflow').then(function (data) {
          return data;
        }),
        roleGroups: roleGroups,
        squares: squares,
        gates: gates
      });
    },
    activate: function activate() {
      this.activateCurrentTab('SETTINGS_TAB');
    },
    actions: {
      willTransition: function willTransition() {
        this.get('controller').setProperties({
          info: null,
          errors: null,
          successMessage: null
        });
      }
    }
  });

  _exports.default = _default;
});