define("apollo/pods/auth/activate-account/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "peGHmo5p",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"font-big\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"pull-right col-lg-5 col-md-5 col-sm-7 col-xs-12 login-nav transparent-background\"],[9],[0,\"\\n\\n        \"],[1,[21,\"login-page-logo-section\"],false],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"form-group text-center\"],[9],[0,\"\\n\\n                \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[11,\"data-button-name\",\"activate-account-button\"],[11,\"type\",\"button\"],[9],[0,\"\\n                    \"],[1,[27,\"t\",[\"auth.activateAccount\"],null],false],[0,\"\\n                \"],[3,\"action\",[[22,0,[]],\"activateButton\"]],[10],[0,\"\\n\\n            \"],[10],[0,\"\\n\\n            \"],[1,[27,\"errors-alert\",null,[[\"errors\"],[[23,[\"errors\"]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/auth/activate-account/template.hbs"
    }
  });

  _exports.default = _default;
});