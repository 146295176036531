define("apollo/utils/objects/AggregatedAuthority", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getGroupedAuthorities = getGroupedAuthorities;
  _exports.default = void 0;
  var AggregatedAuthority = Ember.Object.extend({
    authorityId: null,
    authorityName: null,
    roleGroupId: null,
    authorityRestrictionRange: null,
    authorityRestrictionId: null,
    authorityRestrictionExplanation: null,
    fromResponse: function fromResponse(response) {
      this.authorityId = response.authorityId;
      this.authorityName = response.authorityName;
      this.roleGroupId = response.roleGroupId;
      this.authorityRestrictionId = response.authorityRestrictionId;
      this.authorityRestrictionRange = response.authorityRestrictionRange;
      this.authorityRestrictionExplanation = response.authorityRestrictionExplanation ? response.authorityRestrictionExplanation.toUpperCase() : null;
      return this;
    },
    toRequest: function toRequest(roleGroupId, authorityId, authorityRestrictionId) {
      this.roleGroupId = roleGroupId;
      this.authorityId = authorityId;
      this.authorityRestrictionId = authorityRestrictionId;
      return this;
    },
    compare: function compare(b) {
      return Object.entries(this).toString() === Object.entries(b).toString();
    }
  });
  /**
   *
   * @param {Array<{@link AggregatedAuthority}>} aggregatedAuthorities
   * @returns {Object} groupedByAuthorityNameAndRange
   * @returns {Object} groupedByAuthorityNameAndRange.AUTHORITY_NAME
   * @returns {string} groupedByAuthorityNameAndRange['AUTHORITY_NAME']['AUTHORITY_RESTRICTION_RANGE']
   * @returns {Array<{@link AggregatedAuthority}>} groupedByAuthorityNameAndRange['AUTHORITY_NAME']['AUTHORITY_RESTRICTION_RANGE'][0]
   */

  function getGroupedAuthorities(aggregatedAuthorities) {
    var groupedByAuthorityNameAndRange = Object.groupBy(aggregatedAuthorities, function (_ref) {
      var authorityName = _ref.authorityName;
      return authorityName;
    });

    for (var prop in groupedByAuthorityNameAndRange) {
      groupedByAuthorityNameAndRange[prop] = Object.groupBy(groupedByAuthorityNameAndRange[prop], function (_ref2) {
        var authorityRestrictionRange = _ref2.authorityRestrictionRange;
        return authorityRestrictionRange;
      });
    }

    return groupedByAuthorityNameAndRange;
  }

  var _default = AggregatedAuthority;
  _exports.default = _default;
});