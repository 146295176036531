define("apollo/pods/transaction-setting/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    transportType: _emberData.default.belongsTo('transportType', {
      async: true
    }),
    showCarrierConfirmationCheckbox: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return true;
      }
    }),
    requireCarrierConfirmation: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return true;
      }
    }),
    canChooseCarrierFromTransportTable: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return true;
      }
    }),
    defaultCurrency: _emberData.default.attr('string', {
      defaultValue: function defaultValue() {
        return "PLN";
      }
    }),
    defaultInvoiceCurrency: _emberData.default.attr('string', {
      defaultValue: function defaultValue() {
        return "PLN";
      }
    }),
    priceLabel: _emberData.default.attr('string', {
      defaultValue: function defaultValue() {
        return "Fracht";
      }
    }),
    showReasonField: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    requiresReasonField: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    })
  });

  _exports.default = _default;
});