define("apollo/pods/price-list/modals/rate-form/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    isExistingRouteOffer: Ember.computed('model.routeOffer', function () {
      return this.get('routeOffer.dateCreated') !== undefined && this.get('creator') !== null;
    }),
    route: Ember.computed('model.route', function () {
      return this.get('model.route');
    }),
    routeOffer: Ember.computed('model.routeOffer', function () {
      return this.get('model.routeOffer');
    }),
    actions: {
      setContractorCompanyData: function setContractorCompanyData(item) {
        var self = this;
        this.get('store').findRecord('company', item.id).then(function (company) {
          self.get('routeOffer').set('company', company);
        });
      },
      removeContractorCompany: function removeContractorCompany() {
        this.get('routeOffer').set('company', null);
      },
      setValidityDateStart: function setValidityDateStart(dateValues) {
        this.get('routeOffer').set('validityDateStart', dateValues[0]);
      },
      setValidityDateEnd: function setValidityDateEnd(dateValues) {
        this.get('routeOffer').set('validityDateEnd', dateValues[0]);
      },
      saveNewRate: function saveNewRate() {
        var self = this;
        this.set('routeOffer.creator', this.get('sessionAccount.currentUser'));
        this.set('routeOffer.route', this.get('route'));

        if (!this.get('routeOffer').validate()) {
          return;
        }

        this.get('routeOffer').save().then(function (savedRouteOffer) {
          console.debug("Zapisali\u015Bmy stawk\u0119 ".concat(savedRouteOffer.toJSON()));
          self.send('hideModal');
        }).catch(function (response) {
          self.setProperties({
            errors: response.errors
          });
          self.get('routeOffer').rollbackAttributes();
        });
      },
      update: function update() {
        var routeOffer = this.get('routeOffer');

        if (!routeOffer.validate()) {
          return;
        }

        var self = this;
        routeOffer.save().then(function (updatedRouteOffer) {
          console.debug("Zaktualizowali\u015Bmy stawk\u0119 ".concat(updatedRouteOffer.toJSON()));
          self.send('hideModal');
        }).catch(function (response) {
          self.setProperties({
            errors: response.errors
          });
          self.get('routeOffer').rollbackAttributes();
        });
      },
      cancel: function cancel() {
        this.send('close');
      },
      close: function close() {
        this.get('routeOffer').rollbackAttributes();
        this.set('errors', null);
        this.send('hideModal');
      }
    }
  });

  _exports.default = _default;
});