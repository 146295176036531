define("apollo/pods/components/transport/time-window-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Component.extend({
    sessionAccount: service('session-account'),
    hasWindowStartErrors: Ember.computed('step.timeWindow.errors.start', 'step.errors.minAdviceDate', function () {
      return this.get('step.timeWindow.errors.start') || this.get('step.errors.minAdviceDate');
    }),
    hasWindowStopErrors: Ember.computed('step.timeWindow.errors.stop', 'step.errors.maxAdviceDate', function () {
      return this.get('step.timeWindow.errors.stop') || this.get('step.errors.maxAdviceDate');
    }),
    timeWindowOffsetInterval: function () {
      return this.get('step.warehouse.timeWindowOffsetInterval') ? this.get('step.warehouse.timeWindowOffsetInterval') : 15;
    }.property('step.warehouse.id'),
    startCanBeUpdated: Ember.computed('step.transport', function () {
      var transport = this.get('step.transport');

      if (transport.get('periodicTransport.id')) {
        return false;
      }

      return transport.get('content').actionCanBePerformed('MOVE_ADVICE_WINDOW');
    }),
    stopCanBeUpdated: Ember.computed('step.transport', function () {
      var transport = this.get('step.transport');

      if (transport.get('periodicTransport.id')) {
        return false;
      }

      var canMoveWindow = transport.get('content').actionCanBePerformed('MOVE_ADVICE_WINDOW');
      var canResizeWindow = transport.get('content').actionCanBePerformed('RESIZE_ADVICE_WINDOW');
      return canMoveWindow || canResizeWindow;
    }),
    actions: {
      setTimeWindowStart: function setTimeWindowStart(timeWindow, dateValues) {
        var _this = this;

        Ember.run.next(function () {
          var minAdviceDate = _this.get('step.minAdviceDate');

          var maxAdviceDate = _this.get('step.maxAdviceDate');

          if (minAdviceDate && dateValues[0] < minAdviceDate) {
            timeWindow.set('start', minAdviceDate);
          } else if (maxAdviceDate && dateValues[0] > maxAdviceDate) {
            timeWindow.set('start', maxAdviceDate);
          } else {
            if (!timeWindow.get('initialStart')) {
              timeWindow.set('initialStart', timeWindow.get('start'));
            }

            timeWindow.set('start', dateValues[0]);
          }
        });
      },
      setTimeWindowStop: function setTimeWindowStop(timeWindow, dateValues) {
        var _this2 = this;

        Ember.run.next(function () {
          var minAdviceDate = _this2.get('step.minAdviceDate');

          var maxAdviceDate = _this2.get('step.maxAdviceDate');

          if (minAdviceDate && dateValues[0] < minAdviceDate) {
            timeWindow.set('stop', minAdviceDate);
          } else if (maxAdviceDate && dateValues[0] > maxAdviceDate) {
            timeWindow.set('stop', maxAdviceDate);
          } else {
            timeWindow.set('stop', dateValues[0]);
          }
        });
      },
      setAdviceRange: function setAdviceRange(timeWindow, dateValues, flatpickrInstance) {
        var _this3 = this;

        Ember.run.next(function () {
          if (_this3.get('step.minAdviceDate')) {
            flatpickrInstance.set('minDate', _this3.get('step.minAdviceDate'));
          }

          if (_this3.get('step.maxAdviceDate')) {
            flatpickrInstance.set('maxDate', _this3.get('step.maxAdviceDate'));
          }
        });
      }
    }
  });

  _exports.default = _default;
});