define("apollo/pods/custom-field-definitions/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    UNIT_OPTIONS: Ember.A(['s', 'm', 'h', 'd']),
    transportTypesEditController: Ember.inject.controller('transportTypes/edit'),

    /**
     * Zmienna przechowująca informację o widoczności pola w tabeli przed wprowadzeniem zmian.
     * Jest nam potrzebna do podjęcia decyzji o tym, czy trzeba czyścić listę transportów po zapisaniu
     * definicji (por. z metodą save).
     *
     * Ponieważ kontrolery w ember.js są singletonami, zmienna `initialVisibleInTable` jest czyszczona po wyjściu
     * z aktualnej ścieżki - `willTransition` w `route.js`.
     */
    setInitialVisibleInTable: Ember.observer('model.id', function () {
      if (!this.get('initialVisibleInTable')) {
        this.set('initialVisibleInTable', this.get('model.visibleInTable'));
      }
    }),
    automaticGenerateDocumentActive: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('AUTOMATIC_GENERATE_DOCUMENT');
    }),
    accessToDiscrepancyReportSection: Ember.computed('model.id', function () {
      return this.get('model.transportType.hasDiscrepancyReportEnabled');
    }),
    accessToInspectionPanelSection: Ember.computed('model.id', function () {
      return this.get('model.transportType.randomTransportInspection');
    }),
    customFieldSplitValue: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('CUSTOM_FIELD_SPLIT_VALUE');
    }),
    availableOptions: Ember.computed('model.transportType.workflow.sortedTasks', function () {
      return this.get('model.transportType.workflow.sortedTasks');
    }),
    showTaskHint: Ember.computed('model.task', function () {
      return this.get('model.task');
    }),
    actions: {
      save: function save() {
        var self = this;

        if (!this.get('model.showDependencySection')) {
          this.get('model').setProperties({
            visibilityDependsOnDefinition: null,
            visibilityDependsOnOption: null
          });
        }

        if (!this.get('model.requiredInDelayedTransport')) {
          this.get('model').setProperties({
            delayedTask: null,
            timeIntervalValue: null,
            timeIntervalUnit: null
          });
        }

        this.get('model').validate();

        if (this.get('model.options')) {
          this.get('model.options').forEach(function (opt) {
            opt.validate();
          });
        }

        if (this.get('model.hasErrors')) {
          console.debug('The model contains errors. Aborting.');
          $('html, body').animate({
            scrollTop: 0
          }, 'slow');
          return;
        }

        var options = this.get('model.options');
        self.get('model').save().then(function (fieldDefinition) {
          self.setProperties({
            success: true,
            errors: []
          });
          var optionsCopy = options.slice(0);
          optionsCopy.forEach(function (opt) {
            if (opt && !opt.get('id')) {
              self.get('model.options').removeObject(opt);
              opt.unloadRecord();
            }
          }); // Pola formularza widoczne w tabeli wysyłane są w mapie transportów z serwera.
          // Stąd po włączeniu widoczności musimy usunąć ze store wszystkie załadowane transporty -
          // tak, by zostały ponowne pobrane z serwera po powrocie do tabeli.
          //
          // Jeżeli użytkownik wyłącza widoczność, nie musimy czyścić transportów -
          // kolumna wybranego pola nie zostanie wyświetlona.

          if (!self.get('initialVisibleInTable') && fieldDefinition.get('visibleInTable')) {
            var transportType = fieldDefinition.get('transportType');
            console.debug("Visibility in table has been turned on for the definition ".concat(fieldDefinition.get('name'), ".\n                        We're removing from the store all transports with the type ").concat(transportType.get('name'), ".."));
            self.get('store').peekAll('transport').filterBy('transportType.id', transportType.get('id')).forEach(function (t) {
              t.unloadRecord();
            });
          }

          console.debug("Definition ".concat(fieldDefinition.get('id'), " has been saved successfully."));
          var msg = fieldDefinition.get('visibleOnTimeWindow') ? self.get('intl').t('custom-field-definition.saveSuccessMessageForTimeWindow') : self.get('intl').t('custom-field-definition.saveSuccessMessage');
          self.set('successMessage', msg);
          $('html, body').animate({
            scrollTop: 0
          }, 'slow');
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      },
      delete: function _delete(cfd) {
        var self = this;
        var transportTypeId = cfd.get('transportType.id');
        cfd.destroyRecord().then(function () {
          self.setProperties({
            success: true,
            errors: []
          });
          var msg = self.get('intl').t('custom-field-definition.deleteSuccessMessage');
          self.get('transportTypesEditController').set('successMessage', msg);
          self.transitionToRoute('transport-types.edit', transportTypeId);
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      },
      cancel: function cancel() {
        this.get('model').rollback();
        window.history.back();
      },
      setSide: function setSide(side) {
        this.set('model.side', side);
      },
      showDecimalFormatHint: function showDecimalFormatHint() {
        this.send('showModal', 'custom-field-definitions.modals.decimal-format-hint');
      }
    }
  });

  _exports.default = _default;
});