define("apollo/pods/transports/index/controller", ["exports", "apollo/mixins/transports-mixin"], function (_exports, _transportsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Controller.extend(_transportsMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    relationsTrackerService: Ember.inject.service('transport-relations.relations-tracker'),
    relationsCombiner: Ember.inject.service('transport-relations.relations-combiner'),
    queryParams: Ember.A(['query']),
    canCombineTransports: Ember.computed('canAddCombiningTransportsRelation', 'canAddHasToWaitForRelation', function () {
      return this.get('canAddCombiningTransportsRelation') || this.get('canAddHasToWaitForRelation');
    }),
    canAddCombiningTransportsRelation: Ember.computed(function () {
      return this.get('sessionAccount').hasRole('ROLE_CAN_ADD_TRANSPORT_COMBINING_RELATION');
    }),
    canAddHasToWaitForRelation: Ember.computed(function () {
      return this.get('sessionAccount').hasRole('ROLE_CAN_ADD_HAS_TO_WAIT_FOR_RELATION');
    }),
    searchParams: Ember.computed('model', 'query', 'filterMode', 'daysLimitEnabled', function () {
      var query = this.get('query') || '';
      var filterMode = this.get('filterMode') || 'user';
      var params = {
        query: query,
        filters: Ember.A(),
        daysLimitEnabled: this.get('daysLimitEnabled')
      };

      if (this.get('sessionAccount').isGroupFilterEnabled()) {
        params.filterMode = filterMode;
        return params;
      }

      return params;
    }),
    userAndGroupFilterEnabled: Ember.computed('', function () {
      return this.get('sessionAccount').isGroupFilterEnabled();
    }),
    availableTransportTypes: Ember.computed('model.availableTransportTypes', function () {
      return this.get('model.availableTransportTypes').sortBy('idx');
    }).readOnly(),

    /**
     *  - ALL - jeśli trzeba wyświetlić wszystkie typy,
     *  - alias typu (TRANSPORT_ORDER, CONTAINER, itp) - w przypadku wyświetlania wybranego typu.
     */
    transportTypeMode: Ember.computed('model.transportTypeMode', function () {
      return this.get('model.transportTypeMode');
    }).readOnly(),
    transportTypesToRender: Ember.computed('availableTransportTypes', 'query', function () {
      if (this.get('transportTypeMode') === 'ALL' || this.get('query')) {
        return this.get('availableTransportTypes');
      }

      return this.get('availableTransportTypes').filterBy('alias', this.get('transportTypeMode'));
    }).readOnly(),
    transportTypesReadyToLoad: Ember.computed('transportTypesToRender.@each.loadingCompleted', function () {
      if (!this.get('transportTypesToRender').filterBy('loadingCompleted').get('length')) {
        return [this.get('transportTypesToRender').get('firstObject')];
      }

      var loadedTypes = this.get('transportTypesToRender').filterBy('loadingCompleted');
      var firstUnloadedType = this.get('transportTypesToRender').filterBy('loadingCompleted', false).get('firstObject');

      if (firstUnloadedType) {
        return [].concat(_toConsumableArray(loadedTypes), [firstUnloadedType]);
      }

      return loadedTypes;
    }),
    // eslint-disable-next-line ember/no-observers
    notifyAboutTransportTypeModeSelection: Ember.observer('model.transportTypeMode', function () {
      this.send('setTransportTypeModeSelection', this.get('model.transportTypeMode'));
    }),
    hasFiltersEnabled: Ember.computed('daysLimitSettingEnabled', 'userAndGroupFilterEnabled', function () {
      return this.get('daysLimitSettingEnabled') || this.get('userAndGroupFilterEnabled');
    }),
    actions: {
      setTransportTypeMode: function setTransportTypeMode(mode) {
        this.transitionToRoute('transports.index', mode);
      },
      setQueryParams: function setQueryParams(query) {
        this.setProperties({
          query: query
        });
      },
      setDaysLimitEnabled: function setDaysLimitEnabled(daysLimitEnabled) {
        this.setProperties({
          daysLimitEnabled: daysLimitEnabled
        });
      },
      clearQueryParams: function clearQueryParams() {
        this.setProperties({
          query: undefined
        });
        this.get('availableTransportTypes').forEach(function (tt) {
          return tt.set('transportsTabVisited', false);
        });
      },
      openRelationsModal: function openRelationsModal() {
        var transports = this.get('relationsTrackerService.currentTransports');
        var allRelations = this.get('availableRelations');
        var firstTransport = this.get('relationsTrackerService.currentTransports.firstObject');
        var firstTransportType = firstTransport.get('transportType');
        var firstTypeTransports = transports.filter(function (transport) {
          return transport.get('transportType.id') === firstTransportType.get('id');
        });
        var secondTypeTransports = transports.filter(function (transport) {
          return transport.get('transportType.id') !== firstTransportType.get('id');
        });
        var currentPossibleRelations = Ember.A([]);
        var chosenTransportsAreTheSameTransportTypes = !secondTypeTransports || secondTypeTransports.length < 1; // Ustalamy dostepne relacje

        if (chosenTransportsAreTheSameTransportTypes) {
          var allTransports = firstTypeTransports.concat(secondTypeTransports);
          allRelations.forEach(function (relation) {
            if (!(relation.get('primaryTransportType.id') === firstTransportType.get('id') && relation.get('subordinateTransportType.id') === firstTransportType.get('id'))) {
              // Wszystkie transporty mają ten sam typ, wiec relacje musza miec te same typy w obu parametrach
              return;
            } // TODO walidacja na istnienie super typu transportu


            if (relation.get('alias') === 'TRANSPORT_COMBINING') {
              if (relation.get('requiresTheSameWarehouseInTheSameStepType')) {
                var loadWhId = allTransports.get('firstObject.loadWarehouses.firstObject.id');
                var unloadWhId = allTransports.get('firstObject.unloadWarehouses.firstObject.id');
                var isTheSameWarehouseInTheSameStepType = allTransports.every(function (transport) {
                  var loadWhs = transport.get('loadWarehouses');
                  var unloadWhs = transport.get('unloadWarehouses');
                  return loadWhs.some(function (lwh) {
                    return lwh.get('id') === loadWhId;
                  }) || unloadWhs.some(function (uwh) {
                    return uwh.get('id') === unloadWhId;
                  });
                });

                if (!isTheSameWarehouseInTheSameStepType) {
                  return;
                }
              }
            }

            var possibleRelation = {
              masters: [allTransports.shift()],
              slaves: allTransports,
              type: relation.get('alias'),
              relation: relation
            };
            currentPossibleRelations.pushObject(possibleRelation);
          });
        } else {
          // Różne typy transportów, mamy je podzielone na 2 grupy i sprawdzamy, czy są dostępne jakieś relacje w każdej konfiguracji
          allRelations.forEach(function (relation) {
            var masters = Ember.A([]);
            var slaves = Ember.A([]);

            if (firstTypeTransports.every(function (t) {
              return relation.get('primaryTransportType.id') === t.get('transportType.id');
            }) && secondTypeTransports.every(function (t) {
              return relation.get('subordinateTransportType.id') === t.get('transportType.id');
            })) {
              masters = firstTypeTransports;
              slaves = secondTypeTransports;
            } else if (secondTypeTransports.every(function (t) {
              return relation.get('primaryTransportType.id') === t.get('transportType.id');
            }) && firstTypeTransports.every(function (t) {
              return relation.get('subordinateTransportType.id') === t.get('transportType.id');
            })) {
              slaves = firstTypeTransports;
              masters = secondTypeTransports;
            } else {
              return;
            }

            if (relation.get('alias') === 'TRANSPORT_COMBINING') {
              if (relation.get('requiresTheSameWarehouseInTheSameStepType')) {
                if (!masters.every(function (master) {
                  var masterLoadWhIds = master.get('loadWarehouses').map(function (wh) {
                    return wh.get('id');
                  });
                  var masterUnloadWhIds = master.get('unloadWarehouses').map(function (wh) {
                    return wh.get('id');
                  });
                  return slaves.every(function (slave) {
                    var slaveLoadWhIds = slave.get('loadWarehouses').map(function (wh) {
                      return wh.get('id');
                    });
                    var slaveUnloadWhIds = slave.get('unloadWarehouses').map(function (wh) {
                      return wh.get('id');
                    });
                    return slaveLoadWhIds.some(function (swh) {
                      return masterLoadWhIds.includes(swh);
                    }) || slaveUnloadWhIds.some(function (swh) {
                      return masterUnloadWhIds.includes(swh);
                    });
                  });
                })) {
                  return;
                }
              }
            }

            if (relation.get('alias') === 'HAS_TO_WAIT_FOR') {
              if (relation.get('requiresTheSameWarehouseInDifferentStepType')) {
                if (!masters.every(function (master) {
                  var masterLoadWhIds = master.get('loadWarehouses').map(function (wh) {
                    return wh.get('id');
                  });
                  var masterUnloadWhIds = master.get('unloadWarehouses').map(function (wh) {
                    return wh.get('id');
                  });
                  return slaves.every(function (slave) {
                    var slaveLoadWhIds = slave.get('loadWarehouses').map(function (wh) {
                      return wh.get('id');
                    });
                    var slaveUnloadWhIds = slave.get('unloadWarehouses').map(function (wh) {
                      return wh.get('id');
                    });
                    return slaveLoadWhIds.some(function (swh) {
                      return masterUnloadWhIds.includes(swh);
                    }) || slaveUnloadWhIds.some(function (swh) {
                      return masterLoadWhIds.includes(swh);
                    });
                  });
                })) {
                  return;
                }
              }
            }

            var possibleRelation = {
              masters: masters,
              slaves: slaves,
              type: relation.get('alias'),
              relation: relation
            };
            currentPossibleRelations.pushObject(possibleRelation);
          });
        }

        if (!currentPossibleRelations) {
          return;
        }

        if (currentPossibleRelations.length === 1 && this.get('sessionAccount').getSettingValue('SHOW_TRANSPORT_COMBINING_MODAL_ON_SINGLE_OPTION') !== 'true') {
          console.debug('Jest tylko jedna możliwość łączenia oraz ' + 'SHOW_TRANSPORT_COMBINING_MODAL_ON_SINGLE_OPTION jest false, ' + 'nie otwieramy modalu łączenia transportów');
          this.get('relationsCombiner').combine(currentPossibleRelations.get('firstObject'));
          return;
        }

        var model = {
          currentPossibleRelations: currentPossibleRelations
        };
        console.debug('Otwieramy modal łączenia transportów');
        this.send('showModal', 'transports.modals.transport-relations', model);
      }
    }
  });

  _exports.default = _default;
});