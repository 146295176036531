define("apollo/pods/custom-field-definitions/modals/form/controller", ["exports", "apollo/mixins/transports-mixin", "apollo/mixins/advice-utils-mixin"], function (_exports, _transportsMixin, _adviceUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_transportsMixin.default, _adviceUtilsMixin.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    createTransportController: Ember.inject.controller('transports/modals/create-transport'),
    errors: [],
    checkpoint: Ember.computed('model.id', function () {
      return this.get('model');
    }),
    transport: Ember.computed('checkpoint.transport.id', function () {
      var transportId = this.get('checkpoint.transport.id');
      return this.get('store').peekRecord('transport', transportId);
    }),
    title: Ember.computed('checkpoint.id', function () {
      return "".concat(this.get('intl').t('checkpoints.confirm'), ": ").concat(this.get('checkpoint.name'));
    }),
    isDuringRejection: Ember.computed('checkpoint.actionType', function () {
      return this.get('checkpoint.actionType') === this.get('checkpoint.ACTIONS').REJECT;
    }),
    isDuringConfirmation: Ember.computed('checkpoint.actionType', function () {
      return this.get('checkpoint.actionType') === this.get('checkpoint.ACTIONS').CONFIRM;
    }),
    firstUnconfirmedCheckpoint: Ember.computed('transport.firstUnconfirmedCheckpoint', function () {
      return this.get('transport.firstUnconfirmedCheckpoint');
    }),
    visibleCustomFieldsPairsWithCurrentTaskBlockade: Ember.computed('firstUnconfirmedCheckpoint.customFieldsPairsWithCurrentTaskBlockade', function () {
      var self = this;
      var customFieldsPairsWithCurrentTaskBlockade = self.get('firstUnconfirmedCheckpoint.customFieldsPairsWithCurrentTaskBlockade');
      return customFieldsPairsWithCurrentTaskBlockade.filter(function (pair) {
        return !pair.value || self.shouldBeVisible(pair);
      });
    }),
    classes: Ember.computed('visibleCustomFieldsPairsWithCurrentTaskBlockade.length', function () {
      var customFieldsLength = this.get('visibleCustomFieldsPairsWithCurrentTaskBlockade.length');
      return customFieldsLength > Number.parseInt(this.get('customFieldSplitValue')) ? 'super-wide-modal' : '';
    }),
    customFieldSplitValue: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('CUSTOM_FIELD_SPLIT_VALUE');
    }),
    shouldBeVisible: function shouldBeVisible(pair) {
      return pair.value.get('isVisibleBasedOnDefinition') && pair.value.get('isVisibleBasedOnAuthorityRestrictions');
    },
    actions: {
      confirmCheckpoint: function confirmCheckpoint(checkpoint) {
        var self = this;
        var transport = self.get('transport');
        this.executeAction(self, checkpoint, transport, function () {
          self.send('confirm', checkpoint);
        });
      },
      rejectCheckpoint: function rejectCheckpoint(checkpoint) {
        var _this = this;

        var self = this;
        self.get('transport').reload().then(function (transport) {
          _this.executeAction(self, checkpoint, transport, function () {
            self.send('reject', checkpoint);
          });
        });
      },
      cancel: function cancel(checkpoint) {
        if (checkpoint.get('task.weighingIncluded')) {
          this.get('checkpoint.currentWeight').rollbackAttributes();
        }

        this.reset(checkpoint);
      }
    },
    executeAction: function executeAction(self, checkpoint, transport, action) {
      var createTransportController = self.get('createTransportController');
      createTransportController.setProperties({
        model: transport
      });
      self.setRodoValue(transport.get('advice'));
      checkpoint.set('isDuringAction', true);

      if (!createTransportController.prepareTransportForSaving(transport)) {
        return;
      }

      transport.save().then(function () {
        createTransportController.handleSavedTransport(transport, createTransportController);
        action();
      }).catch(function (response) {
        self.set('errors', response.errors);
      }).finally(function () {
        self.reset(checkpoint);
      });
    },
    reset: function reset(checkpoint) {
      checkpoint.set('isDuringAction', false);
      this.send('hideModal');
    }
  });

  _exports.default = _default;
});