define("apollo/services/date-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    formatDate: function formatDate(date, format) {
      format = format || this.getBaseFullFormat();
      return moment(date).format(format);
    },
    calculateDateFromInterval: function calculateDateFromInterval(date, interval) {
      var negative, duration;

      if (this.isNegative(interval)) {
        negative = true;
        duration = interval.slice(1, -1);
      } else {
        duration = interval.slice(0, -1);
      }

      var timeUnit = interval.slice(-1);
      var durationInSeconds = this.convertToSeconds(duration, timeUnit);
      var dateCopy = new Date(date.getTime());

      if (negative) {
        dateCopy.setSeconds(date.getSeconds() - durationInSeconds);
      } else {
        dateCopy.setSeconds(date.getSeconds() + durationInSeconds);
      }

      return dateCopy;
    },
    convertToSeconds: function convertToSeconds(duration, timeUnit) {
      return duration * this.findMultiplier(timeUnit);
    },
    findMultiplier: function findMultiplier(timeUnit) {
      switch (timeUnit) {
        case 's':
          return 1;

        case 'm':
          return 60;

        case 'h':
          return 60 * 60;

        case 'd':
          return 60 * 60 * 24;

        case 'w':
          return 60 * 60 * 24 * 7;
      }
    },
    isNegative: function isNegative(interval) {
      return interval.search("-") > -1;
    },
    getAbsoluteValue: function getAbsoluteValue(interval) {
      return this.isNegative(interval) ? interval.slice(1) : interval;
    },
    getBaseFullFormat: function getBaseFullFormat() {
      return 'DD-MM-YYYY HH:mm';
    },
    getBaseDateFormat: function getBaseDateFormat() {
      return 'DD-MM-YYYY';
    },
    getBaseHourFormat: function getBaseHourFormat() {
      return 'HH:mm';
    }
  });

  _exports.default = _default;
});