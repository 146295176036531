define("apollo/pods/transport-types/auction-settings/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_MANAGE_AUCTION_SETTINGS'],
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      var transportTypeId = params.id;
      var transportType = this.store.peekRecord('transport-type', transportTypeId);
      var auctionSetting = this.store.queryRecord('auction-setting', {
        transportTypeId: transportTypeId
      });
      return Ember.RSVP.hash({
        transportType: transportType,
        auctionSetting: auctionSetting
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.get('controller').rollbackChanges();
      },
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});