define("apollo/mixins/response-util-mixin", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    handleSuccessResponse: function handleSuccessResponse(self) {
      self.send('refreshModel');
      var msg = self.get('intl').t('common.saveSuccessMessage');
      self.set('successMessage', msg);
      (0, _jquery.default)("html, body").animate({
        scrollTop: 0
      }, "slow");
    },
    handleErrorResponse: function handleErrorResponse(self, response) {
      self.set('errors', response.errors);
      (0, _jquery.default)("html, body").animate({
        scrollTop: 0
      }, "slow");
    }
  });

  _exports.default = _default;
});