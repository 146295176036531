define("apollo/pods/main-section/model", ["exports", "ember-data", "apollo/pods/section/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    partnership: _emberData.default.belongsTo('partnership', {
      async: true,
      deepEmbedded: ['contractorCompany']
    }),
    krsFile: _emberData.default.belongsTo('file', {
      async: true
    }),
    regonFile: _emberData.default.belongsTo('file', {
      async: true
    }),
    nipFile: _emberData.default.belongsTo('file', {
      async: true
    }),
    confirmToS: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    certificates: _emberData.default.attr('string'),
    fleetDescription: _emberData.default.attr('string'),
    validations: {
      'company': {
        custom: [{
          validation: function validation(key, value, model) {
            return model.get('partnership.contractorCompany.content').validate();
          }
        }]
      },
      'isToSConfirmed': {
        custom: [{
          validation: function validation(key, value, model) {
            if (model.get('partnership.termsOfService.content').validate()) {
              model.set('confirmToS', true);
              return true;
            } else {
              model.set('confirmToS', false);
              return false;
            }
          }
        }]
      },
      partnership: {
        custom: [{
          validation: function validation(key, value, model) {
            return model.get('partnership.content').validate({
              only: "representativeEmail,representativePhoneNumber,representativePhoneNumberPrefix,additionalDetails"
            });
          }
        }]
      }
    }
  });

  _exports.default = _default;
});