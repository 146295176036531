define("apollo/pods/contractors-group/modals/create-contractors-group/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: service(),
    contractorsGroup: Ember.computed('model.contractorsGroup', function () {
      return this.get('model.contractorsGroup');
    }),
    title: function () {
      return this.get('intl').t('contractors-group.addGroup');
    }.property('transport.title', 'transport.internalIndex'),
    actions: {
      save: function save() {
        var _this = this;

        var contractorsGroup = this.get('contractorsGroup');

        if (!contractorsGroup.validate()) {
          return;
        }

        var self = this;
        contractorsGroup.save().then(function () {
          self.get('model.groupType').notifyPropertyChange('lastUpdated');
          self.get('model.partnership').notifyPropertyChange('partnershipContractorsGroups');
          $('.save-group-button').button('reset');

          _this.send('hideModal');
        }, function (errorResponse) {
          self.set('errors', errorResponse.errors);
        });
      },
      cancel: function cancel() {
        console.debug("Zamykamy okienko dodawania grupy...");
        this.get('contractorsGroup').rollback();
      }
    }
  });

  _exports.default = _default;
});