define("apollo/pods/authority-restriction/model", ["exports", "ember-data", "ember-data/model", "apollo/mixins/model-validator"], function (_exports, _emberData, _model, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;

  var _default = _model.default.extend(_modelValidator.default, {
    CHECKPOINT_BASED_AUTHORITY_RESTRICTIONS: Ember.A(['CHECKPOINT_BASED', 'BEFORE_CHECKPOINT_BASED', 'AFTER_CHECKPOINT_BASED']),
    dateUtils: Ember.inject.service('date-utils'),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    authority: belongsTo('authority'),
    task: belongsTo('task'),
    roleGroup: belongsTo('roleGroup'),
    authorityRange: attr('string'),
    assetId: attr('number'),
    pricingPlan: attr('string'),
    timeInterval: attr('string'),
    timeReference: attr('string'),
    validations: {
      authority: {
        presence: {
          message: 'blankSelect'
        }
      },
      authorityRange: {
        presence: {
          message: 'blankSelect'
        }
      },
      task: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!model.CHECKPOINT_BASED_AUTHORITY_RESTRICTIONS.includes(model.get('authorityRange'))) {
              return true;
            }

            return value && value.get('id');
          },
          message: 'blankSelect'
        }]
      },
      timeReference: {
        custom: [{
          validation: function validation(key, value, model) {
            if (model.get('authorityRange') !== 'TIME_BASED') {
              return true;
            }

            return !!value;
          },
          message: 'blankSelect'
        }]
      },
      timeIntervalSign: {
        custom: [{
          validation: function validation(key, value, model) {
            if (model.get('authorityRange') !== 'TIME_BASED') {
              return true;
            }

            return !!value;
          },
          message: 'blankSelect'
        }]
      },
      timeIntervalValue: {
        custom: [{
          validation: function validation(key, value, model) {
            if (model.get('authorityRange') !== 'TIME_BASED') {
              return true;
            }

            return !!value;
          },
          message: 'blank'
        }, {
          validation: function validation(key, value, model) {
            if (model.get('authorityRange') !== 'TIME_BASED') {
              return true;
            }

            return value && /[0-9]*/.test(value);
          },
          message: 'number'
        }]
      },
      timeIntervalUnit: {
        custom: [{
          validation: function validation(key, value, model) {
            if (model.get('authorityRange') !== 'TIME_BASED') {
              return true;
            }

            return !!value;
          },
          message: 'blankSelect'
        }]
      },
      assetId: {
        custom: [{
          validation: function validation(key, value, model) {
            return !model.get('authority.assetType') || value;
          },
          message: 'blankSelect'
        }]
      }
    },
    timeIntervalSign: Ember.computed('timeInterval', function () {
      if (!this.get('timeInterval')) {
        return null;
      }

      return this.get('dateUtils').isNegative(this.get('timeInterval')) ? '-' : '+';
    }),
    timeIntervalValue: Ember.computed('timeInterval', function () {
      return this.get('timeInterval') ? this.get('dateUtils').getAbsoluteValue(this.get('timeInterval')).slice(0, -1) : null;
    }),
    timeIntervalUnit: Ember.computed('timeInterval', function () {
      return this.get('timeInterval') ? this.get('dateUtils').getAbsoluteValue(this.get('timeInterval')).slice(-1) : null;
    }),
    conditionIsFulfilled: function conditionIsFulfilled(transport) {
      var currentPricingPlan = this.get('sessionAccount.currentUser.company.pricingPlan');

      if (this.get('pricingPlan') && this.get('pricingPlan') !== currentPricingPlan) {
        return false;
      }

      switch (this.get('authorityRange')) {
        case 'UNLIMITED':
          return true;

        case 'ACTIVE':
          return transport.get('active');

        case 'TIME_BASED':
          return this.timeBasedConditionIsFulfilled(transport);

        case 'BEFORE_CHECKPOINT_BASED':
          return this.beforeCheckpointBasedConditionIsFulfilled(transport);

        case 'AFTER_CHECKPOINT_BASED':
          return this.afterCheckpointBasedConditionIsFulfilled(transport);

        case 'ADVICE_RESTRICTION_BASED':
          return this.adviceRestrictionBasedConditionIsFulfilled(transport);

        case 'ACTIVE_AUCTION_BASED':
          return this.activeAuctionBasedConditionIsFulfilled(transport);

        case 'OWN_COMPANY_BASED':
          return this.ownCompanyBasedConditionIsFulfilled(transport);

        case 'NO_ROUTE_OFFER':
          return this.noRouteOfferBasedConditionIsFulfilled(transport);
      }
    },
    timeBasedConditionIsFulfilled: function timeBasedConditionIsFulfilled(transport) {
      var referenceDate = this.findReferenceDate(transport);

      if (!referenceDate) {
        return true;
      }

      var latestPossibleDate = this.get('dateUtils').calculateDateFromInterval(referenceDate, this.get('timeInterval'));
      return latestPossibleDate > new Date();
    },
    findConfirmedLocalTaskIds: function findConfirmedLocalTaskIds(transport) {
      if (transport.get('id') === null) {
        return [];
      }

      return this.get('store').peekAll('checkpoint').filterBy('transport.id', transport.get('id')).filterBy('executionDate').getEach('task.id');
    },
    beforeCheckpointBasedConditionIsFulfilled: function beforeCheckpointBasedConditionIsFulfilled(transport) {
      var confirmedLocalTaskIds = this.findConfirmedLocalTaskIds(transport);
      var taskId = this.get('task.id');
      return !confirmedLocalTaskIds.includes(taskId);
    },
    afterCheckpointBasedConditionIsFulfilled: function afterCheckpointBasedConditionIsFulfilled(transport) {
      var confirmedLocalTaskIds = this.findConfirmedLocalTaskIds(transport);
      var taskId = this.get('task.id');
      return confirmedLocalTaskIds.includes(taskId);
    },
    adviceRestrictionBasedConditionIsFulfilled: function adviceRestrictionBasedConditionIsFulfilled(transport) {
      var minAdviceDate = transport.get('minAdviceDate');
      var maxAdviceDate = transport.get('maxAdviceDate');

      if (!minAdviceDate || !maxAdviceDate) {
        return true;
      }

      var earliestWindowStart = transport.get('steps').filterBy('timeWindow.start').getEach('timeWindow.start').sort()[0];
      var latestWindowStop = transport.get('steps').filterBy('timeWindow.stop').getEach('timeWindow.stop').sort().reverse()[0];

      if (!earliestWindowStart || !latestWindowStop) {
        return true;
      }

      return minAdviceDate <= earliestWindowStart && maxAdviceDate >= latestWindowStop;
    },
    activeAuctionBasedConditionIsFulfilled: function activeAuctionBasedConditionIsFulfilled(transport) {
      return !transport.get('activeAuction');
    },
    noRouteOfferBasedConditionIsFulfilled: function noRouteOfferBasedConditionIsFulfilled(transport) {
      var hasRoutes = transport.get('hasConfirmedRouteOffers');
      var autoRouteOfferPassNotAccepted = transport.get('transaction') ? transport.get('lastTransactionAutoPassNotAccepted') : false;
      return !hasRoutes || autoRouteOfferPassNotAccepted;
    },
    ownCompanyBasedConditionIsFulfilled: function ownCompanyBasedConditionIsFulfilled(transport) {
      if (!transport.get('id')) {
        return true;
      }

      var transportCreatorCompanyId = transport.get('creatorCompanyId');
      var currentUserCompanyId = this.get('sessionAccount.currentUser.company.id');
      return transportCreatorCompanyId === currentUserCompanyId;
    },
    findReferenceDate: function findReferenceDate(transport) {
      switch (this.get('timeReference')) {
        case 'TRANSPORT_DATE_CREATED':
          return transport.get('dateCreated');

        case 'EARLIEST_TIME_WINDOW_START_BEFORE_CHANGE':
        case 'EARLIEST_TIME_WINDOW_START_AFTER_CHANGE':
        case 'EARLIEST_TIME_WINDOW_CURRENT_DATE':
          return transport.get('earliestTimeWindowStart');

        case 'LATEST_TIME_WINDOW_STOP':
          return transport.get('latestTimeWindowStop');

        case 'MIN_ADVICE_DATE':
          {
            var stepsWithAdviceDateRestrictions = transport.get('sortedStep').filterBy('minAdviceDate');
            return stepsWithAdviceDateRestrictions.get('length') > 0 ? stepsWithAdviceDateRestrictions.get('firstObject') : null;
          }
      }
    },
    name: Ember.computed('authority.name', function () {
      return this.get('authority.name');
    }),
    description: Ember.computed('authority.name', 'authorityRangePhrase', function () {
      var actionTypeName = this.get('intl').t("settings.roleGroups.authorityLabels.".concat(this.get('authority.name')));
      var isPossiblePhrase = this.get('authorityRange') === 'BLOCKED' || this.get('authorityRange') === 'CHECKPOINT_BASED' ? '' : this.get('intl').t('settings.roleGroups.actionIsPossible');
      var pricingPlanPhrase = this.get('pricingPlan') ? this.get('intl').t('settings.roleGroups.pricingPlans', {
        pricingPlan: this.get('pricingPlan')
      }) : '';
      var authorityRangePhrase = this.get('authorityRangePhrase');
      return "".concat(actionTypeName, " - ").concat(pricingPlanPhrase, " ").concat(isPossiblePhrase, " ").concat(authorityRangePhrase);
    }),
    authorityRangePhrase: Ember.computed('authorityRange', 'task', 'timeReference', 'timeInterval', function () {
      var params = {};

      switch (this.get('authorityRange')) {
        case 'TIME_BASED':
          params.timeInterval = this.get('dateUtils').getAbsoluteValue(this.get('timeInterval'));
          params.afterOrBefore = this.get('dateUtils').isNegative(this.get('timeInterval')) ? this.get('intl').t('settings.roleGroups.dateBeforePhrase') : this.get('intl').t('settings.roleGroups.dateAfterPhrase');
          params.timeReference = this.get('intl').t("settings.roleGroups.timeReferences.".concat(this.get('timeReference')));
          break;

        case 'CHECKPOINT_BASED':
        case 'BEFORE_CHECKPOINT_BASED':
        case 'AFTER_CHECKPOINT_BASED':
          params.checkpointName = this.get('task.checkpointNameInAppropriateLanguage');

          if (this.get('task.masterTask.id')) {
            params.checkpointName += ' :: SUBTASK';
          }

          break;
      }

      var rangePhrase;

      if (this.get('timeReference') === 'EARLIEST_TIME_WINDOW_CURRENT_DATE') {
        if (this.get('dateUtils').isNegative(this.get('timeInterval'))) {
          rangePhrase = 'TIME_BASED_CURRENT_DATE_BEFORE';
        } else {
          rangePhrase = 'TIME_BASED_CURRENT_DATE_AFTER';
        }
      } else {
        rangePhrase = this.get('authorityRange');
      }

      return this.get('intl').t("settings.roleGroups.authorityRangePhrases.".concat(rangePhrase), params);
    }),
    authorityRangePhraseInUpperCase: Ember.computed('authorityRangePhrase', function () {
      return this.get('authorityRangePhrase').toString().toUpperCase();
    }),
    transportType: Ember.computed('authority.assetType', 'assetId', function () {
      var assetType = this.get('authority.assetType');

      if (!assetType) {
        return null;
      }

      var assetId = this.get('assetId');

      switch (assetType) {
        case 'TRANSPORT_TYPE':
          return this.get('store').peekRecord('transport-type', assetId);

        case 'CUSTOM_FIELD':
          return this.get('store').peekRecord('custom-field-definition', assetId).get('transportType');

        default:
          return null;
      }
    }),
    customFieldDefinition: Ember.computed('authority.assetType', 'assetId', function () {
      var assetType = this.get('authority.assetType');
      var assetId = this.get('assetId');

      if (!assetType || assetType !== 'CUSTOM_FIELD' || !assetId) {
        return null;
      }

      return this.get('store').peekRecord('custom-field-definition', assetId);
    })
  });

  _exports.default = _default;
});