define("apollo/pods/components/companies/apply-form/contractors-group-section/component", ["exports", "apollo/mixins/error-utils-mixin", "apollo/config/environment"], function (_exports, _errorUtilsMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Component.extend(_errorUtilsMixin.default, {
    store: service(),
    intl: service(),
    sessionAccount: service('session-account'),
    classNames: ['padding-left'],
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var self = this;
      this.get('contractorsGroupTypes').then(function (groupTypes) {
        groupTypes.forEach(function (gt) {
          gt.get('contractorsGroups').forEach(function (group) {
            var pcg = _this.get('partnership.partnershipContractorsGroups').filterBy('contractorsGroup.id', group.get('id')).get('firstObject');

            if (!pcg) {
              _this.get('store').createRecord('partnershipContractorsGroup', {
                partnership: self.get('partnership'),
                contractorsGroup: group,
                enabled: false
              });
            }
          });
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.send('rollbackChanges');
    },
    showGroupTypeTitle: Ember.computed('contractorsGroupTypes.length', function () {
      return this.get('contractorsGroupTypes.length') > 1;
    }),
    showAddNewGroupButton: Ember.computed('', function () {
      return this.get('sessionAccount').hasRole('ROLE_ADD_CONTRACTOR_GROUP');
    }),
    proxiedGroupsConfiguration: Ember.computed('contractorsGroupTypes.@each', 'contractorsGroupTypes.@each.contractorsGroups', 'partnership.partnershipContractorsGroups.@each', 'partnership.partnershipContractorsGroups.@each.enabled', 'partnership.partnershipContractorsGroups.@each.errorMessages', 'partnership.contractorCompany.headquarter.country', function () {
      var partnership = this.get('partnership');
      var partnershipContractorsGroups = this.get('partnership.partnershipContractorsGroups');
      return this.get('contractorsGroupTypes').map(function (gt) {
        var contractorsGroups = gt.get('contractorsGroups').filter(function (cg) {
          return cg !== null;
        });
        return Ember.ObjectProxy.create({
          groupType: gt,
          groups: contractorsGroups.map(function (g) {
            var pcg = partnershipContractorsGroups.find(function (pcg) {
              return pcg.get('contractorsGroup.id') === g.get('id');
            });
            return Ember.ObjectProxy.create({
              content: g,
              pcg: pcg,
              disabled: g.shouldBeDisabled(partnership)
            });
          })
        });
      });
    }),
    actions: {
      toggleGroup: function toggleGroup(groupId) {
        var group = this.get('store').peekRecord('contractorsGroup', groupId);
        var pcg = this.get('partnership.partnershipContractorsGroups').filterBy('contractorsGroup.id', group.get('id')).get('firstObject');

        if (group.get('name') === 'ADR' && !confirm(this.get('intl').t(!pcg.get('enabled') ? 'contractors-group.selectADRInfo' : 'contractors-group.unSelectADRInfo'))) {
          return;
        }

        var shouldBeChecked = !this.get('partnership.contractorsGroupsIds').includes(group.get('id'));

        if (pcg) {
          pcg.set('enabled', shouldBeChecked);
        } else if (shouldBeChecked) {
          this.get('store').createRecord('partnershipContractorsGroup', {
            partnership: this.get('partnership'),
            contractorsGroup: group,
            enabled: true
          });
        }
      },
      save: function save() {
        var self = this;
        self.set('successMessage', '');
        var token = this.get('sessionAccount').get('token');
        var partnership = this.get('partnership');
        var groupsData = partnership.get('contractorsGroups').map(function (g) {
          return g.get('id');
        });
        self.get('store').peekAll('contractorsGroup').map(function (g) {
          g.get('errors').clear();
          g.get('groupType.errors').clear();
        });
        Ember.$.ajax({
          url: "".concat(_environment.default.serverURL, "/partnerships/").concat(partnership.get('id'), "/groups"),
          data: JSON.stringify({
            partnership: partnership.get('id'),
            groups: groupsData
          }),
          type: 'PUT',
          contentType: 'application/json',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          var partnershipContractorsGroupsCopy = self.get('partnership.partnershipContractorsGroups').slice(0);
          partnershipContractorsGroupsCopy.forEach(function (pcg) {
            if (pcg !== undefined && !pcg.get('id') && pcg.unloadRecord) {
              console.debug("Unloading redundant partnership group for group ".concat(pcg.get('contractorsGroup.name'), ".."));
              self.get('partnership.partnershipContractorsGroups').removeObject(pcg);
              pcg.unloadRecord();
            }
          });
          self.get('partnership').reload().then(function () {
            self.set('successMessage', self.get('intl').t('section.successMessage'));
          });
        }, function (response) {
          var errors = JSON.parse(response.responseText).errors;
          self.handleErrors(errors.contractorsGroup, 'contractors-group');
          self.handleErrors(errors.contractorsGroupType, 'contractors-group-type');
        });
      },
      cancel: function cancel() {
        this.send('rollbackChanges');
      },
      showAddGroupModal: function showAddGroupModal(groupType) {
        var self = this;
        var principalId = localStorage.getItem('principalId');
        var principalCompany = self.get('store').peekRecord('company', principalId);
        var model = {
          contractorsGroup: self.get('store').createRecord('contractorsGroup', {
            principalCompany: principalCompany,
            groupType: groupType
          }),
          groupType: groupType,
          partnership: self.get('partnership')
        };
        self.set('action', 'showModal');
        self.sendAction('action', 'contractors-group.modals.create-contractors-group', model);
      },
      rollbackChanges: function rollbackChanges() {
        this.get('partnership').rollbackAttributes();
        var groups = this.get('partnership.partnershipContractorsGroups').map(function (g) {
          return g;
        });
        groups.forEach(function (g) {
          if (g && g.rollback) {
            g.rollback();
          }
        });
      }
    }
  });

  _exports.default = _default;
});