define("apollo/pods/components/sections/section-state/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNameBindings: ['stateClass'],
    stateClass: Ember.computed('section.state', function () {
      return "section-state-".concat(this.get('section.state'));
    })
  });

  _exports.default = _default;
});