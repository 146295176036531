define("apollo/pods/components/transport/documents-section/component", ["exports", "apollo/mixins/attachment-mixin", "apollo/mixins/document-mixin"], function (_exports, _attachmentMixin, _documentMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Component.extend(_attachmentMixin.default, _documentMixin.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    tagName: 'div',
    classNames: ['drop-leave'],
    dragEnter: function dragEnter(event) {
      event.stopPropagation();
      var dropZone = $('.dropzone').parent();

      if (!dropZone.hasClass('dragover')) {
        dropZone.addClass('dragover');
      }
    },
    dragLeave: function dragLeave(event) {
      event.stopPropagation();
      var dropZone = $('.dropzone').parent();

      if (dropZone.hasClass('dragover') && event.target.classList.contains('drop-leave')) {
        dropZone.removeClass('dragover');
      }
    },
    drop: function drop(event) {
      if (!this.get('transport.id')) {
        event.preventDefault();
        var attachments = this.attachments;
        var self = this;
        var dataFromDrop = Ember.A(event.dataTransfer.items);
        var token = this.get('sessionAccount').get('token');
        var files = Ember.A();
        Ember.A(_toConsumableArray(dataFromDrop)).forEach(function (data) {
          if (data.kind === 'file') {
            files.pushObject(data.getAsFile());
          }
        });
        files.forEach(function (file) {
          if (!attachments.findBy('name', file.name)) {
            attachments.pushObject(file);
          }
        });

        if (attachments) {
          self.uploadAttachment(this.get('transport.id'), token).then(function () {
            self.attachments.clear();
          });
        }
      }

      var dropZone = $('.dropzone').parent();

      if (dropZone.hasClass('dragover')) {
        dropZone.removeClass('dragover');
      }
    },
    actions: {
      showNoAuthorityAlert: function showNoAuthorityAlert() {
        alert(this.get('intl').t('common.noAuthority'));
      }
    }
  });

  _exports.default = _default;
});