define("apollo/pods/users/notifications/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    generalAccess: 'ROLE_EDIT_USERS_NOTIFICATION',
    myAccountAccess: 'ROLE_EDIT_MY_NOTIFICATION',
    userAccessService: Ember.inject.service('user-access-service'),
    model: function model(params, transition) {
      var canAccess = this.get('userAccessService').canAccess(params.user_id, this.generalAccess, this.myAccountAccess);

      if (canAccess) {
        return Ember.RSVP.hash({
          user: this.store.findRecord('user', params.user_id)
        });
      } else {
        transition.abort();
        this.transitionTo('users.queues', params.user_id);
      }
    },
    actions: {
      willTransition: function willTransition() {
        this.get('controller').setProperties({
          'successMessage': null
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});