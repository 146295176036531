define("apollo/pods/products/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Controller.extend({
    sessionAccount: service('session-account'),
    queryParams: ['query', 'page', 'size'],
    canEditProducts: Ember.computed('', function () {
      return this.get('sessionAccount').hasAnyOfTheRoles(['ROLE_ACCESS_PRODUCT', 'ROLE_EDIT_PRODUCT', 'ROLE_DELETE_PRODUCT']);
    }),
    currentPage: Ember.computed('page', function () {
      return this.get('page') || '1';
    }),
    actions: {
      editProduct: function editProduct(product) {
        this.transitionToRoute('products.edit', product.get('id'));
      }
    }
  });

  _exports.default = _default;
});