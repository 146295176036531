define("apollo/pods/mixins/jqui-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Create a new mixin for jQuery UI widgets using the Ember
  // mixin syntax.
  // http://stackoverflow.com/a/11385155/2224598
  var _default = Ember.Mixin.create({
    // When Ember creates the view's DOM element, it will call this
    // method.
    setup: function () {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', function () {
        var ui = {};

        var widgets = _this.get('uiWidgets');

        for (var i = 0; i < widgets.length; i++) {
          var widget = widgets[i]; // Make jQuery UI options available as Ember properties

          var options = _this._gatherOptions(widget); // Make sure that jQuery UI events trigger methods on this view.


          _this._gatherEvents(options, widget); // Create a new instance of the jQuery UI widget based on its `uiType`
          // and the current element.


          ui[widget] = Ember.$.ui[widget](options, _this.get('element'));
        } // Save off the instance of the jQuery UI widget as the `ui` property
        // on this Ember view.


        _this.set('ui', ui);
      });
    }.on('didInsertElement'),
    // When Ember tears down the view's DOM element, it will call
    // this method.
    tearDown: function () {
      var ui = this.get('ui');

      if (ui) {
        // Tear down any observers that were created to make jQuery UI
        // options available as Ember properties.
        var observers = this._observers;

        for (var prop in observers) {
          if (observers.hasOwnProperty(prop)) {
            this.removeObserver(prop, observers[prop]);
          }
        }

        for (var u in ui) {
          ui[u]._destroy();
        }
      }
    }.on('willDestroyElement'),
    // Each jQuery UI widget has a series of options that can be configured.
    // For instance, to disable a button, you call
    // `button.options('disabled', true)` in jQuery UI. To make this compatible
    // with Ember bindings, any time the Ember property for a
    // given jQuery UI option changes, we update the jQuery UI widget.
    _gatherOptions: function _gatherOptions(widget) {
      var options = {};
      var uiOptionsMap = this.get('uiOptions');
      var uiOptions = uiOptionsMap[widget]; // The view can specify a list of jQuery UI options that should be treated
      // as Ember properties.

      uiOptions.forEach(function (key) {
        options[key] = this.get(widget + "-" + key); // Set up an observer on the Ember property. When it changes,
        // call jQuery UI's `option` method to reflect the property onto
        // the jQuery UI widget.

        var observer = function observer() {
          var value = this.get(widget + "-" + key);
          this.get('ui')[widget].option(key, value);
        };

        this.addObserver(widget + "-" + key, observer); // Insert the observer in a Hash so we can remove it later.

        this._observers = this._observers || {};
        this._observers[widget + "-" + key] = observer;
      }, this);
      return options;
    },
    // Each jQuery UI widget has a number of custom events that they can
    // trigger. For instance, the progressbar widget triggers a `complete`
    // event when the progress bar finishes. Make these events behave like
    // normal Ember events. For instance, a subclass of JQ.ProgressBar
    // could implement the `complete` method to be notified when the jQuery
    // UI widget triggered the event.
    _gatherEvents: function _gatherEvents(options, widget) {
      var self = this;
      var uiEventsMap = this.get('uiEvents');
      var uiEvents = uiEventsMap[widget];
      uiEvents.forEach(function (eventName) {
        var callback = self.uiActions && self.uiActions[widget + '-' + eventName]; // You can register a handler for a jQuery UI event by passing
        // it in along with the creation options. Update the options hash
        // to include any event callbacks.

        var _widget = widget;

        options[eventName] = function (event, ui) {
          if (callback) {
            callback.call(self, event, ui);
          }

          self.sendAction(_widget + '-' + eventName, event, ui);
        };
      });
    }
  });

  _exports.default = _default;
});