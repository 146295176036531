define("apollo/helpers/hour-is-between", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hourIsBetween = hourIsBetween;
  _exports.default = void 0;

  function hourIsBetween(params) {
    var currentHour = moment(params[0], 'HH:mm').add(1, 's');
    var startTime = moment({
      hour: params[1].hour,
      minute: params[1].minutes
    });
    var endTime = moment({
      hour: params[1].hour,
      minute: params[1].minutes
    }).add(params[2], 'minutes');
    return currentHour.isBetween(startTime, endTime);
  }

  var _default = Ember.Helper.helper(hourIsBetween);

  _exports.default = _default;
});