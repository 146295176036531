define("apollo/pods/workers/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    columns: Ember.computed('', function () {
      var columns = [{
        propertyName: 'name',
        title: this.get('intl').t('worker.name'),
        className: 'column-name'
      }, {
        propertyName: 'phoneNumber',
        title: this.get('intl').t('worker.phoneNumber.WORKERS'),
        className: 'column-name'
      }, {
        propertyName: 'trained',
        title: this.get('intl').t('worker.trained'),
        className: 'column-trained',
        filterWithSelect: true,
        predefinedFilterOptions: [{
          value: 'true',
          label: this.get('intl').t('common.yes')
        }, {
          value: 'false',
          label: this.get('intl').t('common.no')
        }]
      }];
      return columns;
    }),
    actions: {
      openAddWorkerModal: function openAddWorkerModal(worker) {
        if (!worker) {
          var principalCompanyId = localStorage.getItem('principalId');
          var principalCompany = this.get('store').peekRecord('company', principalCompanyId);
          worker = this.get('store').createRecord('worker', {
            company: this.get('model.company'),
            enabled: true,
            principalCompany: principalCompany
          });
        }

        this.send('showModal', 'companies.modals.add-worker', worker);
      },
      delete: function _delete(worker) {
        var self = this;
        worker.destroyRecord().then(function () {
          self.setProperties({
            errors: []
          });
        }).catch(function (response) {
          console.log("B\u0142\u0105d przy usuwaniu pracownika: ".concat(response.errors));
          self.set('errors', response.errors);
        });
      }
    }
  });

  _exports.default = _default;
});