define("apollo/helpers/match-translation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(params, nameArgs) {
      var self = this;
      var key = nameArgs.key;
      var propertyName = nameArgs.propertyName;
      return function (option, searchTerm) {
        if (!option) {
          return -1;
        }

        var propertyKey = option.get(propertyName);
        var translation = self.get('intl').t("".concat(key, ".").concat(propertyKey)).toLowerCase();
        return translation.indexOf(searchTerm.toLowerCase());
      };
    }
  });

  _exports.default = _default;
});