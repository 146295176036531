define("apollo/pods/courier-pricing-success/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    changeTracker: {
      auto: true
    },
    intl: Ember.inject.service(),
    serviceName: _emberData.default.attr('string'),
    priceFuel: _emberData.default.attr('number'),
    priceSumNetto: _emberData.default.attr('number'),
    priceSumVat: _emberData.default.attr('number'),
    price: _emberData.default.attr('number'),
    priceBase: _emberData.default.attr('number'),
    priceBaseVat: _emberData.default.attr('number'),
    pricePeak: _emberData.default.attr('number'),
    priceMultiplePackaging: _emberData.default.attr('number'),
    priceCurrency: _emberData.default.belongsTo('currency'),
    dhlPricePremium: _emberData.default.attr('number'),
    dhlPriceEs: _emberData.default.attr('number'),
    dhlPickupCapabilities: _emberData.default.attr('string'),
    dhlDeliveryCapabilities: _emberData.default.attr('string'),
    courierPricing: _emberData.default.belongsTo('courierPricing'),
    validations: {}
  });

  _exports.default = _default;
});