define("apollo/pods/weights/modals/form/controller", ["exports", "apollo/mixins/transports-mixin", "apollo/mixins/advice-utils-mixin"], function (_exports, _transportsMixin, _adviceUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_transportsMixin.default, _adviceUtilsMixin.default, {
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    createTransportController: Ember.inject.controller('transports/modals/create-transport'),
    checkpoint: Ember.computed('model.id', function () {
      return this.get('model');
    }),
    transport: Ember.computed('checkpoint.transport.id', function () {
      var transportId = this.get('checkpoint.transport.id');
      return this.get('store').peekRecord('transport', transportId);
    }),
    title: Ember.computed('checkpoint.id', function () {
      return "".concat(this.get('intl').t('checkpoints.confirm'), ": ").concat(this.get('checkpoint.name'));
    }),
    isDuringConfirmation: Ember.computed('checkpoint.actionType', function () {
      return this.get('checkpoint.actionType') === this.get('checkpoint.ACTIONS').CONFIRM;
    }),
    firstUnconfirmedCheckpoint: Ember.computed('transport.firstUnconfirmedCheckpoint', function () {
      return this.get('transport.firstUnconfirmedCheckpoint');
    }),
    canEditWeight: Ember.computed('transport.hasAutoWeight', function () {
      if (this.get('transport.hasAutoWeight')) {
        return true;
      } else {
        return !this.get('transport').actionCanBePerformed('ROLE_EDIT_WEIGHT');
      }
    }),
    actions: {
      confirmCheckpoint: function confirmCheckpoint(checkpoint) {
        var self = this;
        var weight = this.store.createRecord('weight', checkpoint.get('currentWeight'));

        if (!weight.get('readOut')) {
          return console.log('Brak odczytu wagi!');
        }

        weight.save().then(function () {
          checkpoint.set('isDuringAction', true);
          self.send('confirm', checkpoint);
          self.reset(checkpoint);
        }).catch(function (err) {
          console.error(err); // TODO: rollback weight
        });
      },
      cancel: function cancel(checkpoint) {
        checkpoint.set('isDuringAction', false);
        this.get('checkpoint.currentWeight').deleteRecord();
        this.send('hideModal');
      }
    },
    reset: function reset(checkpoint) {
      checkpoint.set('isDuringAction', false);
      this.send('hideModal');
    }
  });

  _exports.default = _default;
});