define("apollo/pods/routes/modals/route-form/show/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    route: Ember.computed('model', function () {
      return this.get('model');
    }),
    currencies: Ember.computed('', function () {
      return this.get('store').peekAll('currency');
    }),
    actions: {
      rollbackNewChanges: function rollbackNewChanges() {
        var _this = this;

        this.get('route.routeAddresses').filterBy('isNew', true).forEach(function (item) {
          return _this.store.deleteRecord(item);
        });
        this.get('route.routeCustomFields').filterBy('isNew', true).forEach(function (item) {
          return _this.store.deleteRecord(item);
        });
      },
      save: function save() {
        var self = this;

        if (!this.get('route').validate()) {
          return;
        }

        this.get('route').save().then(function () {
          self.send('rollbackNewChanges');
          self.send('hideModal');
          self.send('refreshModel');
        }).catch(function (response) {
          self.get('route').rollbackAttributes();
        });
      },
      edit: function edit(route) {
        var self = this;

        if (!route.validate()) {
          return;
        }

        route.save().then(function (updatedRoute) {
          console.debug('Zaktualizowaliśmy trasę o id ' + updatedRoute.id);
          self.send('rollbackNewChanges');
          self.send('hideModal');
        }).catch(function (response) {
          self.get('route').rollbackAttributes();
        });
      },
      handleAddressesOrder: function handleAddressesOrder() {
        console.log('TODO: Drag&Drop handleAddressesOrder...');
      },
      close: function close() {
        this.send('rollbackNewChanges');
        this.send('hideModal');
      },
      cancel: function cancel() {
        console.debug('Zamykamy formularz trasy...');
        this.send('rollbackNewChanges');
        this.set('errors', []);
      }
    }
  });

  _exports.default = _default;
});