define("apollo/pods/note/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    transport: _emberData.default.belongsTo('transport', {
      async: true,
      embedded: 'save'
    }),
    creator: _emberData.default.belongsTo('user', {
      async: true
    }),
    readOffBy: _emberData.default.hasMany('user', {
      async: true
    }),
    note: _emberData.default.attr('string'),
    date: _emberData.default.attr('date'),
    dateCreated: _emberData.default.attr('date'),
    autoGenerated: _emberData.default.attr('boolean'),
    hasBeenReadBy: function hasBeenReadBy(account) {
      var userId = account.get('currentUser.id');
      return this.get('readOffBy').filter(function (u) {
        return u.id === userId;
      }).length > 0;
    },
    noteParts: Ember.computed('note', function () {
      if (!this.get('note')) {
        return null;
      }

      return this.get('note').split('\n');
    })
  });

  _exports.default = _default;
});