define("apollo/services/ajax-service", ["exports", "apollo/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    sessionAccount: Ember.inject.service('session-account'),
    send: function send(url, actionType, data, parseData) {
      var token = this.get('sessionAccount').get('token') ? this.get('sessionAccount').get('token') : this.get('session.data.authenticated.token');
      if (token === undefined) token = '';
      var parsedData = parseData ? JSON.stringify(data) : data;
      return _jquery.default.ajax({
        url: _environment.default.serverURL + url,
        type: actionType,
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
        data: parsedData,
        beforeSend: function beforeSend(xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer ' + token);
        }
      });
    },

    /*
     * sequence wykonuje obietnice(Promise) sekwencyjnie.
     * @param {funcs} Tablica funkcji, które zwracają obietnice.
     * @example
     * const promises = [() => new Promise(...), () => new Promise(...)]
     * sequence(promises).then(...)
     */
    sequence: function sequence(funcs) {
      return funcs.reduce(function (promise, func) {
        return promise.then(func);
      }, Promise.resolve());
    }
  });

  _exports.default = _default;
});