define("apollo/pods/transports/modals/transport-types-to-excel/controller", ["exports", "apollo/mixins/attachment-mixin"], function (_exports, _attachmentMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_attachmentMixin.default, {
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    EXPORT_TYPES: Object.freeze({
      BASIC: 'BASIC',
      ADVANCED: 'ADVANCED',
      BILLING: 'BILLING',
      ADVICE: 'ADVICE',
      OWN_COLUMNS: 'OWN_COLUMNS'
    }),
    // eslint-disable-next-line ember/no-observers
    resetProperties: Ember.observer('model.modelOpenTime', function () {
      this.set('exportActiveOrders', this.get('model.archived'));
    }),
    searchParams: Ember.computed('model.searchParams', function () {
      return this.get('model.searchParams');
    }),
    archived: Ember.computed('model.archived', function () {
      return this.get('model.archived');
    }),
    checkedTransportTypes: Ember.computed('model.checkedTransportTypes', function () {
      return this.get('model.checkedTransportTypes');
    }),
    availableTransportTypes: Ember.computed('model.availableTransportTypes', function () {
      return this.get('model.availableTransportTypes');
    }),
    autoAdviceReportLabel: Ember.computed('model.availableTransportTypes', function () {
      var txt = this.get('intl').t('transportsToExcel.adviceReportLabel');
      var nameList = '';
      var locale = this.get('sessionAccount.language');
      this.get('availableTransportTypes').forEach(function (it) {
        if (it.get('isAvailableInAdviceReport')) {
          if (locale === 'pl') {
            nameList += it.name + ', ';
          } else {
            nameList += it.englishName + ', ';
          }
        }
      });

      if (nameList.length > 2) {
        txt = txt + nameList.substring(0, nameList.length - 2);
      } else {
        txt = this.get('intl').t('transportsToExcel.adviceReportLabelEmpty');
      }

      return txt;
    }),
    disabledInAdviceReport: Ember.computed('model.availableTransportTypes', 'exportType', function () {
      var exportType = this.get('exportType');
      var tts = this.get('model.availableTransportTypes');

      if (exportType === this.get('EXPORT_TYPES').ADVICE) {
        tts = tts.filter(function (it) {
          return !it.isAvailableInAdviceReport;
        });
        return tts;
      } else {
        return [];
      }
    }),
    disableExportButton: Ember.computed('exportType', function () {
      var exportType = this.get('exportType');
      return exportType === undefined;
    }),
    actions: {
      toggleCheckedTransportType: function toggleCheckedTransportType(transportType) {
        var checkedTransportTypes = this.get('checkedTransportTypes');

        if (checkedTransportTypes.includes(transportType)) {
          checkedTransportTypes.removeObject(transportType);
        } else {
          checkedTransportTypes.pushObject(transportType);
        }
      },
      setBasicExportType: function setBasicExportType() {
        this.set('exportType', this.get('EXPORT_TYPES').BASIC);
      },
      setAdvancedExportType: function setAdvancedExportType() {
        this.set('exportType', this.get('EXPORT_TYPES').ADVANCED);
      },
      setBillingExportType: function setBillingExportType() {
        this.set('exportType', this.get('EXPORT_TYPES').BILLING);
      },
      setAdviceExportType: function setAdviceExportType() {
        var checkedTransportTypes = this.get('model.checkedTransportTypes');

        if (checkedTransportTypes.length > 0) {
          checkedTransportTypes = checkedTransportTypes.filter(function (it) {
            return it.isAvailableInAdviceReport;
          });
          this.set('model.checkedTransportTypes', checkedTransportTypes);
        }

        this.set('exportType', this.get('EXPORT_TYPES').ADVICE);
      },
      setOwnColumnExportType: function setOwnColumnExportType() {
        this.set('exportType', this.get('EXPORT_TYPES').OWN_COLUMNS);
      },
      cancel: function cancel() {
        this.send('hideModal');
      }
    }
  });

  _exports.default = _default;
});