define("apollo/pods/documents/modals/document-list-modal/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    documents: Ember.computed('model.documents', function () {
      return this.get('model.documents');
    }),
    title: Ember.computed('document.id', 'model.documentType.name', 'model.groupName', function () {
      var typeName = this.get('model.documentType.name') || this.get('model.groupName');
      var typeLabel = this.get('intl').t("sectionType.".concat(typeName));
      return "".concat(typeLabel, " - Archiwum");
    })
  });

  _exports.default = _default;
});