define("apollo/pods/companies/show/controller", ["exports", "apollo/mixins/company-mixin", "jquery", "apollo/config/environment"], function (_exports, _companyMixin, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_companyMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    companiesIndexController: Ember.inject.controller('companies/index'),
    company: Ember.computed('model.company', function () {
      return this.get('model.company');
    }),
    additionalDetailsDisabled: Ember.computed('company.partnershipWithCurrentCompany', function () {
      return !this.get('sessionAccount').hasRole('ROLE_EDIT_COMPANY');
    }),
    principalCompany: Ember.computed(function () {
      var principalCompanyId = localStorage.getItem('principalId');
      return this.get('store').peekRecord('company', principalCompanyId);
    }),
    showApply: Ember.computed('company.partnershipWithPrincipal.partnershipType.name', function () {
      return (this.get('company.partnershipWithPrincipal.partnershipType.name') === 'CARRIER' || this.get('company.partnershipWithPrincipal.partnershipType.name') === 'OPERATOR') && this.get('sessionAccount').getSettingValue('HAS_ACCESS_TO_APPLIES');
    }),
    showAdviceVerificationSection: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('ADVICE_VERIFICATION_EMAIL_ENABLED') === 'true';
    }),
    showVoivodeshipField: Ember.computed('company.headquarter.country.ibanSymbol', function () {
      return this.get('sessionAccount').getSettingValue('SHOW_COMPANY_VOIVODESHIP_FIELD') === 'true' && this.get('company.headquarter.country.ibanSymbol') === 'PL';
    }),
    isEditing: Ember.computed('company.id', function () {
      return this.get('company').get('id') != null;
    }),
    showNewAddressForm: Ember.computed(function () {
      return false;
    }),
    countries: Ember.computed(function () {
      return this.store.peekAll('country');
    }),
    availableTypes: Ember.computed(function () {
      return this.store.peekAll('partnershipType');
    }),
    showAdviceDaysLimitSection: Ember.computed(function () {
      var principalCompanyId = localStorage.getItem('principalId');
      var setting = this.get('store').peekAll('setting').filterBy('company.id', principalCompanyId).filterBy('name', 'DEFAULT_ADVICE_FUTURE_DAYS_LIMIT').get('firstObject');
      return !!setting;
    }),
    showPatron: Ember.computed('company.partnershipType.name', function () {
      return this.get('sessionAccount').getSettingValue('PATRON_IN_CONTRACTOR_DETAILS_ENABLED') === 'true' && (this.get('company.partnershipType.name') === 'SUPPLIER' || this.get('company.partnershipType.name') === 'OPERATOR');
    }),
    contractorsGroupTypes: Ember.computed('company.partnershipWithPrincipal.partnershipType.name', function () {
      var self = this;

      if (!this.get('company.partnershipWithPrincipal.partnershipType.name')) {
        return;
      }

      return this.get('store').query('contractorsGroupType', {
        principalCompanyId: localStorage.getItem('principalId'),
        partnershipTypeName: self.get('company.partnershipWithPrincipal.partnershipType.name')
      });
    }),
    showContractorsGroupSection: Ember.computed('contractorsGroupTypes.length', function () {
      return this.get('contractorsGroupTypes.length') > 0;
    }),
    showAllowsPeriodicTransportFlag: Ember.computed('', function () {
      return this.get('store').peekAll('transportType').any(function (tt) {
        return tt.get('allowsPeriodicTransport');
      });
    }),
    _findPatrons: function _findPatrons(item) {
      var self = this;
      var currentPatron = self.get('company.partnershipWithPrincipal.patron');
      return this.get('store').findRecord('user', item.id).then(function (person) {
        self.set('company.partnershipWithPrincipal.prevPatron', currentPatron);
        self.set('company.partnershipWithPrincipal.patron', person);
      });
    },
    actions: {
      save: function save() {
        var self = this;
        var token = this.get('sessionAccount').get('token');
        var companyModel = this.get('company');
        this.trimCompany(companyModel);
        companyModel.set('changeTaxIdNumber', !!companyModel.changedAttributes().taxIdNumber);
        companyModel.validate();
        companyModel.get('partnershipWithPrincipal').validate();

        if (companyModel.get('errors.length') || companyModel.get('partnershipWithPrincipal').get('errors.length')) {
          return;
        }

        if (companyModel.get('partnershipWithPrincipal').didChange('patron')) {
          _jquery.default.ajax({
            url: _environment.default.serverURL + '/company/patronsTransports',
            type: 'GET',
            data: {
              companyId: companyModel.get('id')
            },
            beforeSend: function beforeSend(xhr) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            }
          }).then(function (data) {
            if (Ember.isEmpty(data.transportIds) || confirm(self.get('intl').t('company.patronWillChange', {
              transportIds: data.transportIds
            }))) {
              self.send('saveCompany', companyModel);
            }
          });
        } else {
          this.send('saveCompany', companyModel);
        }
      },
      saveCompany: function saveCompany(companyModel) {
        var _this = this;

        var self = this;
        var headquarter = companyModel.get('headquarter');

        if (headquarter.get('id')) {
          headquarter.set('idToDisable', headquarter.get('id'));
        }

        companyModel.save().then(function (company) {
          console.debug("Company ".concat(company.get('name'), " has been updated."));
          self.setProperties({
            errors: []
          });

          if (headquarter.get('content')) {
            headquarter.get('content').unloadRecord();
          }

          self.send('refreshModel');
          companyModel.get('partnershipWithPrincipal').reload();
          var msg = self.get('intl').t('company.saveSuccessMessage');
          self.set('successMessage', msg);

          if (self.get('isEditing')) {
            self.transitionToRoute('companies.show', company.id);
          } else {
            self.transitionToRoute('companies.index');
          }
        }).catch(function (response) {
          self.set('successMessage', null);

          if (response.errors.some(function (e) {
            return e.status === '403';
          })) {
            var msg = _this.get('intl').t('common.noAuthority');

            self.set('errors', [msg]);
          } else {
            self.set('errors', response.errors);
          }
        });
      },
      disable: function disable() {
        var _this2 = this;

        var companyName = this.get('company.name');
        this.get('company').destroyRecord().then(function () {
          var msg = _this2.get('intl').t('company.deletedMessage', {
            companyName: companyName
          });

          _this2.set('companiesIndexController.successMessage', msg);

          _this2.transitionToRoute('companies.index');
        }).catch(function (response) {
          _this2.set('errors', response.errors);
        });
      },
      cancel: function cancel() {
        this.get('company').rollbackAttributes();
        this.get('company.partnershipWithPrincipal').rollbackAttributes();

        if (this.get('company.partnershipWithPrincipal.prevPatron')) {
          this.get('company.partnershipWithPrincipal').setProperties({
            patron: this.get('company.partnershipWithPrincipal.prevPatron'),
            prevPatron: null
          });
        }

        if (this.get('company.headquarter.content')) {
          if (this.get('company.headquarter.id')) {
            this.get('company.headquarter').rollbackAttributes();
          } else {
            this.get('company.headquarter.content').unloadRecord();
            this.get('company').set('headquarter', null);
          }
        }

        this.transitionToRoute('companies.index');
      },
      addHeadquarter: function addHeadquarter() {
        var address = this.get('store').createRecord('address', {
          company: this.get('company'),
          enabled: true,
          isHeadquarters: true,
          recipient: this.get('company.name')
        });
        this.get('company').set('headquarter', address);
      },
      showMainModal: function showMainModal() {
        var templateName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'transports.modals.create-transport';
        var model = arguments.length > 1 ? arguments[1] : undefined;
        this.send('showModal', templateName, model);
      },
      setPatron: function setPatron(item) {
        Ember.run.once(this, this._findPatrons(item));
      },
      removePatron: function removePatron() {
        this.set('company.partnershipWithPrincipal.patron', null);
      }
    }
  });

  _exports.default = _default;
});