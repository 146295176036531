define("apollo/helpers/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.add = add;
  _exports.default = void 0;

  function add(params) {
    var number = params[0];
    var increment = params[1] || 1;
    return number + increment;
  }

  var _default = Ember.Helper.helper(add);

  _exports.default = _default;
});