define("apollo/pods/notification-log/model", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    intl: Ember.inject.service(),
    transport: _emberData.default.belongsTo('transport', {
      async: true
    }),
    subject: _emberData.default.attr('string'),
    message: _emberData.default.attr('string'),
    recipients: _emberData.default.attr('string'),
    dateCreated: _emberData.default.attr('date'),
    newValue: _emberData.default.attr('string'),
    propertyName: _emberData.default.attr('string'),
    className: _emberData.default.attr('string'),
    actor: _emberData.default.attr('string'),
    newValueFormat: Ember.computed('newValue', function () {
      return this.get('newValue');
    }),
    eventLabel: Ember.computed('propertyName', function () {
      return this.get('intl').t("logs.".concat(this.get('propertyName')));
    })
  });

  _exports.default = _default;
});