define("apollo/pods/components/route/form/route-address-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    companyToFindAddress: Ember.computed('ra.route.transportType', function () {
      return this.get('ra.stepType') === 'load' ? this.get('ra.route.transportType.defaultLoadCompanyId') : this.get('ra.route.transportType.defaultUnloadCompanyId');
    }),
    // TODO: Możemy to przenieść do address/model.js (`addressSummary` używamy w kilku miejscach)
    addressSummary: Ember.computed('ra.address.{recipient,city,postal,street}', function () {
      if (this.get('ra.address.recipient')) {
        return this.get('ra.address.recipient');
      }

      if (this.get('ra.address.street')) {
        return "".concat(this.get('ra.address.street'), ", ").concat(this.get('ra.address.city'), ", ").concat(this.get('ra.address.postal'));
      }

      return '';
    }).readOnly(),
    actions: {
      recalculateRouteAddressesIdx: function recalculateRouteAddressesIdx(routeAddresses, newRouteAddressIdx) {
        routeAddresses.filter(function (routeAddress) {
          return routeAddress.get('idx') >= newRouteAddressIdx;
        }).forEach(function (filteredRouteAddress) {
          filteredRouteAddress.set('idx', filteredRouteAddress.get('idx') + 1);
        });
      },
      recalculateStepTypeIdx: function recalculateStepTypeIdx(stepTypeAddresses, newStepTypeIdx) {
        stepTypeAddresses.filter(function (routeAddress) {
          return routeAddress.get('stepTypeIdx') >= newStepTypeIdx;
        }).forEach(function (filteredRouteAddress) {
          filteredRouteAddress.set('stepTypeIdx', filteredRouteAddress.get('stepTypeIdx') + 1);
        });
      },
      addRouteAddress: function addRouteAddress(routeAddress, stepType) {
        var route = this.get('ra.route');
        var routeAddresses = route.get('routeAddresses');
        var stepTypeRouteAddresses = routeAddresses.filter(function (ra) {
          return ra.stepType === stepType;
        });
        var previousOrSameStep = routeAddresses.filter(function (ra) {
          return ra.get('idx') <= routeAddress.get('idx') && ra.get('stepType') === stepType;
        }).reverse().get('firstObject');
        var newStepTypeIdx = previousOrSameStep ? previousOrSameStep.get('stepTypeIdx') + 1 : 0;
        this.send('recalculateStepTypeIdx', stepTypeRouteAddresses, newStepTypeIdx);
        var newRouteAddressIdx = routeAddress.get('idx') + 1;
        this.send('recalculateRouteAddressesIdx', routeAddresses, newRouteAddressIdx);
        var newRouteAddress = this.get('store').createRecord('routeAddress', {
          stepType: stepType,
          idx: newRouteAddressIdx,
          stepTypeIdx: newStepTypeIdx
        });
        routeAddresses.addObject(newRouteAddress);
        route.set('routeAddresses', route.get('routeAddresses').sortBy('idx'));
      },
      setAddressData: function setAddressData(routeAddress, address) {
        if (address.id) {
          this.get('store').findRecord('address', address.id).then(function (addressModel) {
            routeAddress.set('address', addressModel);
            routeAddress.set('company', addressModel.get('company'));
          });
        } else {
          routeAddress.get('address').setProperties({
            street: address.street,
            postal: address.postal,
            city: address.city
          });
          this.get('store').findRecord('country', address.country).then(function (countryModel) {
            routeAddress.set('country', countryModel);
          });
          this.get('store').findRecord('company', address.company).then(function (companyModel) {
            routeAddress.set('company', companyModel);
          });
        }
      },
      removeAddress: function removeAddress() {
        this.get('ra').set('address', null);
      },
      beforeDrag: function beforeDrag(address, event) {
        console.log("Pr\xF3bujemy przesun\u0105\u0107 adres ".concat(address.get('idx'), "..."));

        if (!address.get('canBeMoved')) {
          event.preventDefault();
        }
      },

      /**
       *  potrzebne do nadpisania domyślnego stylowania draggable-object
       */
      dragStartHook: function dragStartHook() {}
    }
  });

  _exports.default = _default;
});