define("apollo/pods/terms/review/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Controller.extend({
    session: service('session'),
    sessionAccount: service('session-account'),
    statuteAccepted: false,
    actions: {
      acceptStatute: function acceptStatute() {
        var self = this;
        var currentUser = this.get('sessionAccount.currentUser');
        currentUser.set('statuteAccepted', true);
        currentUser.save().then(function (user) {
          console.log("User ".concat(user.get('username'), " accepted Terms."));
          self.transitionToRoute('defaultTransports');
        });
      },
      rejectStatute: function rejectStatute() {
        var self = this;
        this.get('sessionAccount.currentUser').save().then(function (user) {
          console.log("User ".concat(user.get('username'), " rejected Terms and must be logged-out."));
          self.get('session').invalidate();
        });
      },
      approveAllPoints: function approveAllPoints() {
        this.set('statuteAccepted', true);
      },
      unApproveSomePoints: function unApproveSomePoints() {
        this.set('statuteAccepted', false);
      }
    }
  });

  _exports.default = _default;
});