define("apollo/pods/transport-types/transaction-settings/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    errors: [],
    transportType: Ember.computed('model.transportType.id', function () {
      return this.get('model.transportType');
    }),
    transactionSetting: Ember.computed('model.transactionSetting.id', function () {
      return this.get('model.transactionSetting');
    }),
    currencies: Ember.computed('', function () {
      return this.get('store').peekAll('currency').getEach('symbol');
    }),
    actions: {
      save: function save() {
        var self = this;
        self.set('errors', []);
        var transactionSetting = self.get('store').createRecord("transactionSetting", {
          transportType: self.get('transportType')
        });
        transactionSetting.save().then(function () {
          self.send('refreshModel');
          var msg = self.get('intl').t('transactionSetting.saveSuccess');
          self.set('successMessage', msg);
          $("html, body").animate({
            scrollTop: 0
          }, "slow");
        }).catch(function (response) {
          self.set('errors', response.errors);
          $("html, body").animate({
            scrollTop: 0
          }, "slow");
        });
      },
      update: function update() {
        var self = this;
        self.set('errors', []);
        self.get('transactionSetting').save().then(function () {
          self.send('refreshModel');
          var msg = self.get('intl').t('transactionSetting.saveSuccess');
          self.set('successMessage', msg);
          $("html, body").animate({
            scrollTop: 0
          }, "slow");
        }).catch(function (response) {
          self.set('errors', response.errors);
          $("html, body").animate({
            scrollTop: 0
          }, "slow");
        });
      },
      cancel: function cancel() {
        this.get('transactionSetting').rollbackAttributes();
        this.transitionToRoute('settings.index');
      },
      willTransition: function willTransition() {
        this.get('controller').setProperties({
          errors: [],
          successMessage: null
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});