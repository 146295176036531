define("apollo/pods/trucks/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      max: {
        refreshModel: true
      }
    },
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_COMPANIES_TAB', 'ROLE_OWN_COMPANY_TAB'],
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      var _this = this;

      var companyId = params.companyId;
      params.max = params.max || 10;
      params.page = params.page || 1;
      return new Promise(function (resolve) {
        _this.store.query('truck', params).then(function (response) {
          resolve(Ember.RSVP.hash({
            trucks: response,
            company: _this.store.findRecord('company', companyId)
          }));
        }, function () {
          _this.transitionTo('forbidden');
        });
      });
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});