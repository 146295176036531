define("apollo/pods/setting/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    intl: Ember.inject.service(),
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    name: _emberData.default.attr('string'),
    value: _emberData.default.attr('string'),
    editable: _emberData.default.attr('boolean'),
    dateCreated: _emberData.default.attr('date'),
    lastUpdated: _emberData.default.attr('date'),
    validations: {
      name: {
        custom: [{
          validation: function validation(key, value) {
            if (!value) {
              return false;
            }

            return value;
          },
          message: 'blankSelect'
        }]
      }
    },
    settingValueType: function () {
      var settingValue = this.get('value');

      if (settingValue === 'true' || settingValue === 'false') {
        return 'boolean';
      }
    }.property('value'),
    isChecked: function () {
      var settingValue = this.get('value');
      return settingValue === 'true';
    }.property('value'),
    translatedName: Ember.computed('name', function () {
      return this.get('intl').t("settings.desc.".concat(this.get('name')));
    }),
    translatedNameString: Ember.computed('name', function () {
      return this.get('intl').t("settings.desc.".concat(this.get('name'))).toString();
    })
  });

  _exports.default = _default;
});