define("apollo/pods/products/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Controller.extend({
    intl: service(),
    indexController: Ember.inject.controller('products/index'),
    units: Ember.computed('', function () {
      return ['KG', 'LITERS', 'PIECES'];
    }),
    actions: {
      save: function save() {
        var _this = this;

        if (!this.get('model').validate()) {
          return;
        }

        var self = this;
        this.get('model').set('enabled', true);
        var isUpdating = !!this.get('model.id');
        this.get('model').save().then(function () {
          var messageKey = isUpdating ? 'product.updated' : 'product.saved';
          var msg = self.get('intl').t(messageKey, {
            index: _this.get('model.index')
          });
          self.get('indexController').set('successMessage', msg);
          self.transitionToRoute('products.index');
        });
      },
      disable: function disable() {
        var _this2 = this;

        var self = this;
        this.get('model').set('enabled', false);
        this.get('model').save().then(function () {
          var msg = self.get('intl').t('product.disabled', {
            index: _this2.get('model.index')
          });
          self.get('indexController').set('successMessage', msg);
          self.transitionToRoute('products.index');
        });
      },
      cancel: function cancel() {
        this.get('model').rollbackAttributes();
        this.transitionToRoute('products.index');
      }
    }
  });

  _exports.default = _default;
});