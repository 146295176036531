define("apollo/services/session-account", ["exports", "apollo/mixins/persistence-mixin", "apollo/config/environment"], function (_exports, _persistenceMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_persistenceMixin.default, {
    session: Ember.inject.service('session'),
    ajaxService: Ember.inject.service('ajax-service'),
    store: Ember.inject.service(),
    errors: Ember.A([]),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    authoritiesDependentOnPricingPlan: {},
    loadCurrentUser: function loadCurrentUser() {
      var self = this;
      var username = this.get('session.data.authenticated.username');
      var token = this.get('session.data.authenticated.token');

      if (Ember.isEmpty(username)) {
        return;
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        jQuery.ajax({
          url: _environment.default.serverURL + '/users/account/' + username,
          type: 'GET',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (response) {
          if (Ember.isEmpty(response.user)) {
            reject(new Error('No user provided in response.'));
          }

          Ember.run.next(function () {
            if (response.meta) {
              self.get('session').set('backendVersion', response.meta.appVersion);
            }

            if (!self.get('store').hasRecordForId('user', response.user.id)) {
              self.get('store').pushPayload(response);
            }

            var user = self.get('store').peekRecord('user', response.user.id);
            self.set('token', token);
            self.set('notificationTypes', response.notificationTypes);
            self.set('authoritiesDependentOnPricingPlan', response.authoritiesDependentOnPricingPlan);
            self.set('actionTypes', response.actionTypes);
            self.set('pricingPlans', response.pricingPlans);
            self.set('principalCompanyId', response.user.principalCompanyId);
            self.set('resetPasswordIsDisabled', response.user.resetPasswordIsDisabled);
            self.set('statuteAccepted', user.get('statuteAccepted'));
            self.set('currentUser', user);
            self.set('authorityRestrictions', self.get('store').peekAll('authority-restriction').filter(function (ar) {
              return ar.get('roleGroup.id') === user.get('roleGroup.id') || ar.get('pricingPlan') === user.get('company.pricingPlan');
            }));
            self.get('store').peekAll('transportType').forEach(function (tt) {
              var authorityRestrictions = self.getAllPositiveAuthorityRestrictionsForGivenTransportType(tt);
              tt.set('authorityRestrictions', authorityRestrictions);
            }); // Poniższy get służy załadowaniu ramp do sesji ember-data.
            // Bez niego zdarzało się, że rampy dublowały się po wczytaniu szczegółów
            // innego użytkownika (por. A-329, A-392).

            user.get('ramps');
            self.removeDuplicateRecords(user.get('roleGroup'), 'notifications');
            self.removeDuplicateRecords(user, 'ramps');
            var columnNames = user.get('tableColumns').map(function (c) {
              return c.get('name');
            });
            self.set('columnNames', columnNames);
            localStorage.removeItem('principalAlias');
            var principalCompany = self.get('store').peekRecord('company', self.get('principalCompanyId'));

            if (principalCompany) {
              localStorage.setItem('principalAlias', principalCompany.get('alias'));
            }

            self.set('principalsWhereUserHasRole', self.get('store').peekAll('company').filter(function (company) {
              return response.principalsWhereUserHasRole.includes(Number(company.get('id')));
            }));
            localStorage.setItem('principalId', self.get('principalCompanyId'));
            var partnershipType = self.get('store').peekAll('partnershipType').find(function (pt) {
              return Number(pt.get('id')) === response.company.partnershipType;
            });
            self.set('currentUser.company.partnershipType', partnershipType);
            resolve(user);
          });
        }, function () {
          self.get('session').invalidate();
        });
      });
    },
    isHTTPS: function isHTTPS() {
      return location.protocol === 'https:';
    },
    currentCompany: Ember.computed('currentUser.company', function () {
      return this.get('currentUser.company');
    }),
    principalCompany: Ember.computed('principalCompanyId', function () {
      return this.get('store').peekRecord('company', this.get('principalCompanyId'));
    }),
    isPrincipal: Ember.computed('currentUser.isPrincipal', function () {
      return this.get('currentUser').get('isPrincipal');
    }),
    hasRoleTransportTable: Ember.computed('currentUser.roles', function () {
      return this.hasRole('ROLE_TRANSPORT_TABLE');
    }),
    isSuperUser: Ember.computed('currentUser.roleGroup.name', function () {
      return this.get('currentUser.roleGroup.name') === 'SUPER_USER';
    }),
    hasAnyOfTheRoles: function hasAnyOfTheRoles(roles) {
      var self = this;
      var hasAnyOfTheRoles = false;
      roles.forEach(function (role) {
        if (self.hasRole(role)) {
          hasAnyOfTheRoles = true;
          return false;
        }
      });
      return hasAnyOfTheRoles;
    },
    hasRole: function hasRole(name) {
      if (!this.get('session').get('isAuthenticated')) {
        return false;
      }

      return this.get('currentUser').get('roleNames').includes(name);
    },
    getSettingValue: function getSettingValue(name) {
      var principalCompanyId = localStorage.getItem('principalId');
      return this.get('store').peekAll('setting').filterBy('company.id', principalCompanyId).filterBy('name', name).get('firstObject.value');
    },
    getParameterValue: function getParameterValue(name) {
      return this.get('store').peekAll('parameter').filterBy('name', name).get('firstObject.value');
    },
    getTransactionSetting: function getTransactionSetting(transportType) {
      return this.get('store').peekAll('transaction-setting').filterBy('transportType.id', transportType.get('id')).get('firstObject');
    },
    getAuctionSetting: function getAuctionSetting(transportTypeId) {
      var principalCompanyId = localStorage.getItem('principalId');
      return this.get('store').peekAll('auction-setting').filterBy('company.id', principalCompanyId).filterBy('transportType.id', transportTypeId).get('firstObject');
    },
    getAppliesSetting: function getAppliesSetting() {
      var principalCompanyId = localStorage.getItem('principalId');
      return this.get('store').peekAll('applies-setting').filterBy('principalCompany.id', principalCompanyId).get('firstObject');
    },
    getProductSetting: function getProductSetting() {
      var principalCompanyId = localStorage.getItem('principalId');
      return this.get('store').peekAll('product-setting').filterBy('company.id', principalCompanyId).get('firstObject');
    },
    getIndexSetting: function getIndexSetting(transportTypeId) {
      if (!transportTypeId) {
        return;
      }

      return this.get('store').peekAll('index-setting').filterBy('transportType.id', transportTypeId).get('firstObject');
    },
    getCourierSetting: function getCourierSetting(transportTypeId) {
      if (!transportTypeId) {
        return;
      }

      return this.get('store').peekAll('courier-setting').filterBy('transportType.id', transportTypeId).get('firstObject');
    },
    getSquareSetting: function getSquareSetting() {
      var principalCompanyId = localStorage.getItem('principalId');
      return this.get('store').peekAll('square-setting').find(function (ss) {
        return ss.get('company.id') === principalCompanyId;
      });
    },
    getPackageTypeTransportTypeForTransportType: function getPackageTypeTransportTypeForTransportType(transportTypeId) {
      return this.get('store').peekAll('packageTypeTransportType').filter(function (pttt) {
        return pttt.get('transportType.id') === transportTypeId;
      });
    },
    getPackageTypeTransportType: function getPackageTypeTransportType(packageTypeId, transportTypeId) {
      return this.get('store').peekAll('packageTypeTransportType').find(function (pttt) {
        return pttt.get('transportType.id') === transportTypeId && pttt.get('packageType.id') === packageTypeId;
      });
    },
    isGroupFilterEnabled: function isGroupFilterEnabled() {
      var setting = this.get('sessionAccount').getSettingValue('ROLES_WITH_ENABLED_USER_AND_GROUP_FILTER');
      return setting ? setting.search(this.get('currentUser.roleGroup.name')) > -1 : false;
    },
    isGroupFilterEnabledForUser: function isGroupFilterEnabledForUser(user) {
      var setting = this.get('sessionAccount').getSettingValue('ROLES_WITH_ENABLED_USER_AND_GROUP_FILTER');
      return setting ? setting.search(user.get('roleGroup.name')) > -1 : false;
    },
    getAllPositiveAuthorityRestrictionsForGivenTransportType: function getAllPositiveAuthorityRestrictionsForGivenTransportType(transportType) {
      var _this = this;

      var assetIds = transportType.get('connectedAssetIds').map(function (id) {
        return Number(id);
      });
      return this.get('authorityRestrictions').filter(function (ar) {
        return assetIds.includes(ar.get('assetId')) && ar.get('authorityRange') !== 'BLOCKED' && (!ar.get('pricingPlan') || ar.get('pricingPlan') === _this.get('currentUser.company.pricingPlan'));
      });
    },
    // TYLKO POWIĄZANE Z TYPEM TRANSPORTU
    getPositiveAuthorityRestrictionsByTransportTypeId: function getPositiveAuthorityRestrictionsByTransportTypeId(transportTypeId) {
      var _this2 = this;

      return this.get('authorityRestrictions').filter(function (ar) {
        return ar.get('assetId') === Number(transportTypeId) && ar.get('authorityRange') !== 'BLOCKED' && (!ar.get('pricingPlan') || ar.get('pricingPlan') === _this2.get('currentUser.company.pricingPlan'));
      });
    },
    canAccessAnyTransportType: Ember.computed('currentUser.roles', function () {
      return this.hasAuthorityAtAll('ACCESS_TRANSPORT');
    }),
    hasAuthorityAtAll: function hasAuthorityAtAll(actionName) {
      var _this3 = this;

      var authorityRestrictions = this.get('authorityRestrictions');
      return authorityRestrictions.some(function (ar) {
        var authority = _this3.get('currentUser.roles').find(function (a) {
          return a && a.get('id') === ar.get('authority.id');
        });

        return authority.get('name') === actionName && ar.get('authorityRange') !== 'BLOCKED' && (!ar.get('pricingPlan') || ar.get('pricingPlan') === _this3.get('currentUser.company.pricingPlan'));
      });
    },
    hasAuthorityInTransportType: function hasAuthorityInTransportType(actionName, transportTypeId) {
      return this.getPositiveAuthorityRestrictionsByTransportTypeId(Number(transportTypeId)).find(function (ar) {
        return ar.get('name') === actionName;
      });
    },
    hasAuthorityInTransportTypeByAlias: function hasAuthorityInTransportTypeByAlias(actionName, transportTypeAlias) {
      var transportType = this.get('store').peekAll('transportType').filterBy('alias', transportTypeAlias)[0];

      if (!transportType) {
        console.debug('Nie znaleziono typu transportu :: ' + transportTypeAlias);
        return false;
      }

      return this.hasAuthorityInTransportType(actionName, transportType.get('id'));
    }
  });

  _exports.default = _default;
});