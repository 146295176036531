define("apollo/pods/transaction/modals/invoice/controller", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Controller.extend({
    intl: service(),
    store: service(),
    sessionAccount: service('session-account'),
    currencies: function () {
      return this.get('store').peekAll('currency');
    }.property(),
    transaction: function () {
      return this.get('model');
    }.property('model'),
    actions: {
      save: function save(transaction) {
        var _this = this;

        var self = this;
        this.set('transaction.isInvoiceForm', true);

        if (!this.get('transaction.content').validate()) {
          return;
        }

        var token = this.get('sessionAccount').get('token');
        Ember.$.ajax({
          url: _environment.default.serverURL + '/transactions/' + transaction.get('id') + '/saveInvoice',
          type: 'PUT',
          data: JSON.stringify(transaction.get('content')),
          contentType: 'application/json; charset=utf-8',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          self.set('transaction.content.isInvoiceForm', false);

          _this.get('store').findRecord('transport', _this.get('model.transport.id')).then(function (transport) {
            transport.reload();
            self.send('hideModal');
          });
        });
      },
      close: function close() {
        this.send('hideModal');
      },
      cancel: function cancel() {
        console.debug("Zamykamy okienko dodawania faktury...");
        this.get('transaction.content').rollback();
      }
    }
  });

  _exports.default = _default;
});