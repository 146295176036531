define("apollo/pods/components/companies/apply-form/licences-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    groupName: Ember.computed('', function () {
      return 'LICENCE';
    }),
    documentTypes: Ember.computed('other', function () {
      var countryIban = this.get('partnership.contractorCompany.country.ibanSymbol');
      return this.get('store').peekAll('documentType').filterBy('groupName', this.get('groupName')).filter(function (dt) {
        return !dt.get('ibanRestriction') || dt.get('ibanRestriction') === countryIban;
      });
    }),
    actions: {
      showModal: function showModal(templateName, model) {
        this.set('action', 'showModal');
        this.sendAction('action', templateName, model);
      },
      refresh: function refresh() {
        this.get('partnership').reload().then(function (p) {
          return p.get('documents').reload();
        });
      },
      showArchived: function showArchived() {
        this.set('action', 'showModal');
        var model = {
          groupName: this.get('groupName'),
          documentTypes: this.get('documentTypes'),
          documents: this.get('partnership.archivedLicences')
        };
        this.sendAction('action', 'documents.modals.document-list-modal', model);
      }
    }
  });

  _exports.default = _default;
});