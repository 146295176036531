define("apollo/pods/transport/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: Object.freeze({
      steps: {
        embedded: 'save'
      },
      advice: {
        embedded: 'save'
      },
      customFields: {
        embedded: 'save'
      },
      indexes: {
        embedded: 'save'
      },
      courierPackages: {
        embedded: 'save'
      },
      periodicTransport: {
        embedded: 'save'
      },
      courierInvoice: {
        embedded: 'save'
      },
      weights: {
        embedded: 'save'
      }
    }),
    serializeIntoHash: function serializeIntoHash(data, type, record, options) {
      var object = this.serialize(record, options);

      for (var key in object) {
        data[key] = object[key];
      }
    },

    /**
     * Ember-data ma bug, który powoduje powielanie rekordów po ich zapisaniu.
     * W przypadku `steps` akurat wystarczyło usuwać ostatni element z listy (por. `hasManyRecords.pop()`).
     *
     * Jedyną cechą charakterystyczną powielonych rekordów, którą zauważyłem, było to, że nie mają id
     * oraz mają `_bfsId > 0`.
     *
     * Można się zapoznać z wątkiem https://github.com/emberjs/data/issues/1829,
     * chociaż akurat nie było tam poniższego rozwiązania.
     * W ogóle chyba nie udało się znaleźć konkretnej łaty.
     *
     * @param records
     */
    removeDuplicatedRecords: function removeDuplicatedRecords(records) {
      try {
        records.filter(function (r) {
          return !r.id && r._internalModel._bfsId > 0;
        }).forEach(function (r) {
          console.debug("Removing duplicated has-many record of type ".concat(r.modelName, ".."));
          records.splice(records.indexOf(r), 1);
        });
      } catch (e) {
        console.error(e);
      }
    },
    serializeHasMany: function serializeHasMany(record, json, relationship) {
      var self = this;
      var DEFAULT_FORMAT_DATE = 'DD.MM.YYYY HH:mm';
      var key = relationship.key;
      var hasManyRecords = record.hasMany(key); // po edycji transportu zaraz po jego utworzeniu ember dubluje jeden ze stepsów jest on zawsze ostatni w tablicy record.hasMany(key) więc go usuwamy

      var transportId = json.id || record.id;
      var transport = transportId ? this.store.peekRecord('transport', transportId) : null;

      if (transport && key === 'steps') {
        if (hasManyRecords.length > transport.get('steps.length')) {
          hasManyRecords.pop();
        }
      }

      if (hasManyRecords && this.attrs[key] && this.attrs[key].embedded === 'save') {
        json[key] = [];
        self.removeDuplicatedRecords(hasManyRecords);
        hasManyRecords.forEach(function (item) {
          var data = item.attributes();
          data.id = item.id;

          if (relationship.options.deepEmbedded) {
            relationship.options.deepEmbedded.forEach(function (deepKey) {
              if (item.belongsTo(deepKey)) {
                var deepRecord = item.belongsTo(deepKey);

                if (deepKey === 'company' && !deepRecord.attr('name')) {
                  return true;
                }

                var deepRecordIsUnsaved = !deepRecord.id;

                if (deepRecordIsUnsaved) {
                  data[deepKey] = deepRecord.attributes();
                  data[deepKey].id = deepRecord.id;
                } else {
                  data[deepKey] = {
                    id: deepRecord.id
                  };
                }

                if (deepKey === 'timeWindow') {
                  data[deepKey].start = moment(deepRecord.attr('start')).format(DEFAULT_FORMAT_DATE);
                  data[deepKey].stop = moment(deepRecord.attr('stop')).format(DEFAULT_FORMAT_DATE);
                  data[deepKey].initialStart = moment(deepRecord.attr('initialStart')).format(DEFAULT_FORMAT_DATE);
                  data[deepKey].firstSavedStart = moment(deepRecord.attr('firstSavedStart')).format(DEFAULT_FORMAT_DATE);
                  data[deepKey].ramp = deepRecord.belongsTo('ramp') ? {
                    id: deepRecord.belongsTo('ramp').id
                  } : null;
                } else if (deepKey === 'company' && deepRecordIsUnsaved) {
                  data[deepKey].country = deepRecord.belongsTo('country') ? {
                    id: deepRecord.belongsTo('country').id
                  } : null;
                } else if (deepKey === 'address' && deepRecordIsUnsaved) {
                  data[deepKey].country = deepRecord.belongsTo('country') ? {
                    id: deepRecord.belongsTo('country').id
                  } : null;
                  var companyId = deepRecord.belongsTo('company') ? deepRecord.belongsTo('company').id : data.company.id;
                  data[deepKey].company = companyId ? {
                    id: companyId
                  } : null;
                }
              }
            });
          }

          if (item.modelName === 'step') {
            if (data.arrivalDate) {
              data.arrivalDate = moment(data.arrivalDate).format(DEFAULT_FORMAT_DATE);
            }

            if (data.minAdviceDate) {
              data.minAdviceDate = moment(data.minAdviceDate).format(DEFAULT_FORMAT_DATE);
            }

            if (data.maxAdviceDate) {
              data.maxAdviceDate = moment(data.maxAdviceDate).format(DEFAULT_FORMAT_DATE);
            }

            if (item.hasMany('stepWarehouseLocations')) {
              data.stepWarehouseLocations = [];
              item.hasMany('stepWarehouseLocations').forEach(function (wl) {
                var d = wl.serialize();
                d.id = wl.id;
                d.step = item.id;
                data.stepWarehouseLocations.push(d);
              });
            }

            if (item.belongsTo('warehouseZone') && item.belongsTo('warehouseZone').id) {
              data.warehouseZone = {
                id: item.belongsTo('warehouseZone').id
              };
            }

            if (item.hasMany('stepPackageTypes')) {
              data.stepPackageTypes = [];
              item.hasMany('stepPackageTypes').forEach(function (spt) {
                var d = spt.attributes();
                d.id = spt.id;
                d.packageType = {
                  id: spt.belongsTo('packageType').id
                };
                d.step = {
                  id: spt.belongsTo('step').id
                };
                data.stepPackageTypes.push(d);
              });
            }
          }

          if (item.modelName === 'index' && item.hasMany('quantities')) {
            var relatedStep = item.belongsTo('step');

            if (relatedStep && relatedStep.attributes()) {
              data.stepOrderInTransport = relatedStep.attributes().orderInTransport;
            }

            data.quantities = [];
            item.hasMany('quantities').forEach(function (quantity) {
              var d = quantity.attributes();
              d.id = quantity.id;
              d.indexQuantityType = {
                id: quantity.belongsTo('indexQuantityType').id
              };
              data.quantities.push(d);
            });
          }

          if (item.modelName === 'weight') {
            if (data.enabled) {
              data = item.serialize();
            } else {
              return;
            }
          }

          json[key].push(data);
        });
      } else {
        this._super(record, json, relationship);
      }
    },
    serializeBelongsTo: function serializeBelongsTo(record, json, relationship) {
      var DEFAULT_FORMAT_DATE = 'DD.MM.YYYY HH:mm';
      var key = relationship.key;
      var embeddedRecord = record.belongsTo(key);

      if (embeddedRecord && this.attrs[key] && this.attrs[key].embedded === 'save') {
        var data = embeddedRecord.attributes();
        data.id = embeddedRecord.id;

        if (relationship.options.deepEmbedded) {
          relationship.options.deepEmbedded.forEach(function (deepKey) {
            if (embeddedRecord.belongsTo(deepKey)) {
              data[deepKey] = {
                id: embeddedRecord.belongsTo(deepKey).id
              };
            }
          });
        }

        if (relationship.type === 'periodic-transport') {
          if (data.startDate) {
            data.startDate = moment(data.startDate).format(DEFAULT_FORMAT_DATE);
          }

          if (data.endDate) {
            data.endDate = moment(data.endDate).format(DEFAULT_FORMAT_DATE);
          }
        }

        json[key] = data;
      } else {
        this._super(record, json, relationship);
      }
    }
  });

  _exports.default = _default;
});