define("apollo/pods/offer/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend(_modelValidator.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    auction: _emberData.default.belongsTo('auction'),
    company: _emberData.default.belongsTo('company'),
    creator: _emberData.default.belongsTo('user'),
    price: _emberData.default.attr('number'),
    enabled: _emberData.default.attr('boolean'),
    valid: _emberData.default.attr('boolean'),
    hasWon: _emberData.default.attr('boolean'),
    deleted: _emberData.default.attr('boolean'),
    place: _emberData.default.attr('number'),
    description: _emberData.default.attr('string'),
    dateCreated: _emberData.default.attr('isodate'),
    descriptionTrimValue: 23,
    validations: {
      price: {
        custom: [{
          validation: function validation(key, value) {
            return value && parseFloat(value) >= 1;
          },
          message: 'nonPositiveNumber'
        }, {
          validation: function validation(key, value, model) {
            if (model.get('isAcceptedWithMinPrice')) {
              return true;
            }

            if (!value || value < 1) {
              return true;
            }

            var auctionSetting = model.get('auctionSetting');

            if (!auctionSetting || !auctionSetting.get('requireMinOfferReductionSizeValidation')) {
              return true;
            }

            var suggestedPrice = model.get('auction.suggestedPriceForLoggedCompany');

            if (!suggestedPrice) {
              return true;
            }

            return parseFloat(value) <= suggestedPrice;
          },
          message: function message(key, value, self) {
            var principalCompanyName = self.get('auction.transport.principalCompany.shortName');
            var minReductionSize = self.get('auction.minReductionSize');
            return self.get('intl').t('offer.errors.price.reductionNotMet', {
              principalCompanyName: principalCompanyName,
              minReductionSize: minReductionSize,
              currencySymbol: self.get('currency.symbol')
            }).toString();
          }
        }, {
          validation: function validation(key, value, model) {
            var auctionSetting = model.get('auctionSetting');

            if (!auctionSetting) {
              return true;
            }

            if (!auctionSetting.get('canAcceptWithMinPrice')) {
              return model.get('auction.minPrice') <= parseFloat(value);
            }

            return true;
          },
          message: 'lowerThanMinimum'
        }]
      }
    },
    auctionSetting: Ember.computed('auction.transport.transportType.id', function () {
      return this.get('sessionAccount').getAuctionSetting(this.get('auction.transport.transportType.id'));
    }),
    currency: Ember.computed('auction.currency', function () {
      return this.get('auction.currency');
    }),
    formattedPrice: Ember.computed('price', 'currency.symbol', function () {
      if (!this.get('price')) {
        return '';
      }

      return "".concat(this.get('price'), " ").concat(this.get('currency.symbol'));
    }),
    displayPassToCarrierButton: Ember.computed('auction.transport.auctions.@each.offers.length', function () {
      var self = this;

      if (!this.get('enabled')) {
        return false;
      }

      var transport = this.get('store').peekRecord('transport', this.get('auction').get('transport.id'));
      var showButton = true;
      var sortedAuctions = transport.get('auctions').sortBy("dateCreated").reverse();

      for (var i = 0; i < sortedAuctions.length; i++) {
        if (sortedAuctions[i].get('id') === self.get('auction.id')) {
          break;
        }

        var offers = sortedAuctions[i].get('offers').sortBy('company');

        for (var j = 0; j < offers.length; j++) {
          if (offers[j].get('company.id') === self.get('company.id') && offers[j].get('id') !== self.get('id')) {
            showButton = false;
            break;
          }
        }
      }

      return showButton;
    }),
    isAcceptedWithMinPrice: Ember.computed('', {
      get: function get(key) {
        return this.get('model.isAcceptedWithMinPrice') ? this.get('model.isAcceptedWithMinPrice') : false;
      },
      set: function set(key, value) {
        return value;
      }
    })
  });

  _exports.default = _default;
});