define("apollo/pods/transport-transport-relation/model", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = _model.default.extend({
    transportRelation: belongsTo('transport-relation', {
      async: true,
      inverse: null
    }),
    masterTransport: belongsTo('transport', {
      async: true,
      inverse: null
    }),
    relatedTransport: belongsTo('transport', {
      async: true,
      inverse: null
    }),
    alias: attr('string'),
    relationIdentifier: attr('string')
  });

  _exports.default = _default;
});