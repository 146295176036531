define("apollo/pods/contractors-group/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = _emberData.default.Model.extend(_modelValidator.default, {
    intl: service(),
    sessionAccount: service('session-account'),
    languageService: service('language-service'),
    changeTracker: {
      auto: true,
      enableIsDirty: true
    },
    documentValidations: _emberData.default.hasMany('documentValidation', {
      async: true
    }),
    principalCompany: _emberData.default.belongsTo('company', {
      async: true
    }),
    groupType: _emberData.default.belongsTo('contractorsGroupType', {
      async: true
    }),
    name: _emberData.default.attr('string'),
    nameEn: _emberData.default.attr('string'),
    shortName: _emberData.default.attr('string'),
    shortNameEn: _emberData.default.attr('string'),
    activeWhenCountryIn: _emberData.default.attr('string'),
    validations: {
      name: {
        presence: {
          message: 'blank'
        }
      }
    },
    nameInAppropriateLanguage: Ember.computed('name', 'nameEn', function () {
      var propertyName = this.get('languageService').findProperLanguageVersion('name', 'nameEn');
      return this.get(propertyName);
    }).readOnly(),
    shortNameInAppropriateLanguage: Ember.computed('nameInAppropriateLanguage', 'shortName', 'shortNameEn', function () {
      var propertyName = this.get('languageService').findProperLanguageVersion('shortName', 'shortNameEn');
      return this.get(propertyName) || this.get('nameInAppropriateLanguage');
    }).readOnly(),
    obligatoryDocuments: Ember.computed('documentValidations.@each', function () {
      return this.get('documentValidations').filterBy('obligatory', true);
    }),
    optionalDocuments: Ember.computed('documentValidations.@each', function () {
      return this.get('documentValidations').filterBy('obligatory', false);
    }),
    idsOfObligatoryDocumentsTypes: Ember.computed('obligatoryDocuments.@each', function () {
      return this.get('obligatoryDocuments').map(function (d) {
        return d.get('documentType.id');
      });
    }).readOnly(),
    idsOfOptionalDocumentsTypes: Ember.computed('optionalDocuments.@each', function () {
      return this.get('optionalDocuments').map(function (d) {
        return d.get('documentType.id');
      });
    }).readOnly(),
    obligatoryDocumentsNames: Ember.computed('obligatoryDocuments.@each', function () {
      var self = this;
      return this.get('obligatoryDocuments').map(function (d) {
        return self.get('intl').t('sectionType.' + d.get('documentType.name'));
      }).join(', ');
    }).readOnly(),
    optionalDocumentsNames: Ember.computed('optionalDocuments.@each', function () {
      var self = this;
      return this.get('optionalDocuments').map(function (d) {
        return self.get('intl').t('sectionType.' + d.get('documentType.name'));
      }).join(', ');
    }).readOnly(),
    shouldHaveTheObligatoryDocuments: Ember.computed('obligatoryDocuments', function () {
      return this.get('obligatoryDocuments.length') > 0;
    }).readOnly(),
    shouldHaveTheOptionalDocuments: Ember.computed('optionalDocuments', function () {
      return this.get('optionalDocuments.length') > 0;
    }).readOnly(),
    hasErrors: Ember.computed('errors.length', function () {
      return this.get('errors.length') > 0;
    }).readOnly(),

    /**
     * Określa, czy grupa powinna być widoczna, ale zablokowana do edycji
     */
    shouldBeDisabled: function shouldBeDisabled(partnership) {
      if (!this.get('activeWhenCountryIn')) {
        return false;
      }

      var companyCountry = partnership.get('contractorCompany.country.ibanSymbol');
      return !companyCountry || this.get('activeWhenCountryIn').search(companyCountry) === -1;
    }
  });

  _exports.default = _default;
});