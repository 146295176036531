define("apollo/pods/transport-types/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Controller.extend({
    sessionAccount: service('session-account'),
    intl: service(),
    store: service(),
    transportType: Ember.computed('model', function () {
      return this.get('model');
    }),
    typeGroups: Ember.computed('transportType', function () {
      return Object.keys(this.get('transportType.TYPE_GROUPS'));
    }),
    actions: {
      cancel: function cancel() {
        this.get('transportType').unloadRecord();
        window.history.back();
      },
      save: function save() {
        var _this = this;

        var self = this;

        if (!this.get('transportType').validate()) {
          return;
        }

        this.get('transportType').save().then(function (transportType) {
          self.setProperties({
            success: true,
            errors: []
          });
          var userRoleGroupId = self.get('sessionAccount.currentUser.roleGroup.id');

          _this.store.query('authority-restriction', {
            roleGroupId: userRoleGroupId
          }).then(function (data) {
            transportType.get('workflow').reload().then(function () {
              var transportTypeId = transportType.get('id');
              var authorityRestrictions = data.filter(function (aR) {
                return aR.assetId === Number(transportTypeId);
              });

              _this.get('sessionAccount').set('authorityRestrictions', data);

              transportType.set('authorityRestrictions', authorityRestrictions);
              transportType.reload().then(function () {
                var msg = self.get('intl').t('settings.transportTypes.settingsSaveSuccess');
                self.set('successMessage', msg);
              });
            });
          });
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      }
    }
  });

  _exports.default = _default;
});