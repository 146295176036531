define("apollo/pods/authority-restrictions/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Controller.extend({
    authoritiesIndexController: Ember.inject.controller('authorities/index'),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    ajaxService: Ember.inject.service('ajax-service'),
    tasksArray: Ember.computed('model.transportType', function () {
      return _toConsumableArray(this.get('model.transportType.workflow.sortedTasks'));
    }),
    cfArray: Ember.computed('model.transportType', function () {
      return _toConsumableArray(this.get('model.transportType.sortedFieldDefinitions'));
    }),
    tasksToSave: Ember.computed('model.authorityRestriction.{id,task}', function () {
      if (this.get('model.authorityRestriction.id')) {
        return [this.get('model.authorityRestriction.task')];
      } else {
        return [];
      }
    }),
    cfsToSave: Ember.computed('model.authorityRestriction.{id,customFieldDefinition}', function () {
      if (this.get('model.authorityRestriction.id')) {
        return [this.get('model.authorityRestriction.customFieldDefinition')];
      } else {
        return [];
      }
    }),
    errors: Ember.A([]),
    saveSingle: false,
    AUTHORITY_RANGES: Ember.A(['UNLIMITED', 'ACTIVE', 'CHECKPOINT_BASED', 'BEFORE_CHECKPOINT_BASED', 'AFTER_CHECKPOINT_BASED', 'TIME_BASED', 'ADVICE_RESTRICTION_BASED', 'ACTIVE_AUCTION_BASED', 'NO_ROUTE_OFFER', 'OWN_COMPANY_BASED']),
    TIME_REFERENCES: Ember.A(['TRANSPORT_DATE_CREATED', 'EARLIEST_TIME_WINDOW_START_BEFORE_CHANGE', 'EARLIEST_TIME_WINDOW_START_AFTER_CHANGE', 'LATEST_TIME_WINDOW_STOP', 'EARLIEST_TIME_WINDOW_CURRENT_DATE']),
    SIGN_OPTIONS: Ember.A(['-', '+']),
    UNIT_OPTIONS: Ember.A(['s', 'm', 'h', 'd']),
    actionTypes: Ember.computed('sessionAccount.isSuperUser', function () {
      var _this = this;

      var authorities = this.get('store').peekAll('authority').filter(function (a) {
        return a.get('assetType');
      });

      if (this.get('sessionAccount.isSuperUser')) {
        return authorities;
      }

      return authorities.filter(function (a) {
        return _this.get('sessionAccount.actionTypes').includes(a.get('name'));
      });
    }),
    allRequiredFieldsCompleted: Ember.computed('model.authorityRestriction.{authority,authorityRange,timeInterval,timeReference,task}', function () {
      if (!this.get('model.authorityRestriction.authority') || !this.get('model.authorityRestriction.authorityRange')) {
        return false;
      }

      switch (this.get('model.authorityRestriction.authorityRange')) {
        case 'BEFORE_CHECKPOINT_BASED':
        case 'AFTER_CHECKPOINT_BASED':
          return this.get('model.authorityRestriction.task');

        case 'TIME_BASED':
          return this.get('model.authorityRestriction.timeInterval') && this.get('model.authorityRestriction.timeReference');
      }

      return true;
    }),
    // eslint-disable-next-line ember/no-observers
    setTimeInterval: Ember.observer('model.authorityRestriction.{timeIntervalSign,timeIntervalValue,timeIntervalUnit}', function () {
      var sign = this.get('model.authorityRestriction.timeIntervalSign');
      var value = this.get('model.authorityRestriction.timeIntervalValue');
      var unit = this.get('model.authorityRestriction.timeIntervalUnit');

      if (!sign || !value || !unit) {
        return;
      }

      if (sign === '+') {
        sign = '';
      }

      this.get('model.authorityRestriction').set('timeInterval', "".concat(sign).concat(value).concat(unit));
    }),
    customFieldActionType: Ember.computed('model.authorityRestriction.authority', function () {
      return ['ACCESS_CUSTOM_FIELD', 'UPDATE_CUSTOM_FIELD'].includes(this.get('model.authorityRestriction.authority.name'));
    }),
    dependsOnSpecificAsset: Ember.computed('model.authorityRestriction.authority.assetType', function () {
      var assetType = this.get('model.authorityRestriction.authority.assetType');
      return assetType && assetType !== 'TRANSPORT_TYPE';
    }),
    findAssetOptions: Ember.computed('model.{authorityRestriction.authority.assetType,transportType.id}', function () {
      var assetType = this.get('model.authorityRestriction.authority.assetType');

      if (!assetType) {
        return [];
      }

      var transportTypeId = this.get('model.transportType.id');

      switch (assetType) {
        case 'CUSTOM_FIELD_DEFINITION':
          return this.get('store').peekAll('custom-field-definition').filter(function (cf) {
            return cf.get('transportType.id') === transportTypeId;
          });

        default:
          return [];
      }
    }),
    prepareAuthorityRestrictionToSave: function prepareAuthorityRestrictionToSave(ar) {
      if (!ar) {
        ar = this.get('model.authorityRestriction');
      }

      var authorityRestriction = ar;

      if (authorityRestriction.get('authority.assetType') === 'TRANSPORT_TYPE' && !authorityRestriction.get('assetId')) {
        var ttId = this.get('model.transportType.id');
        authorityRestriction.set('assetId', ttId);
      }

      if (!authorityRestriction.validate()) {
        console.log('Authority restriction nie przeszło walidacji..');
        return false;
      }

      return authorityRestriction;
    },
    actions: {
      setAssetId: function setAssetId(item) {
        if (item && item.id) {
          this.set('model.authorityRestriction.assetId', item.id);
        }
      },
      save: function save() {
        var self = this;
        var tasksToSave = this.get('tasksToSave');
        var cfsToSave = this.get('cfsToSave');

        if (cfsToSave.length > 0 && cfsToSave.get(0) !== null && !this.get('saveSingle')) {
          self.send('saveMultipleCfs', cfsToSave);
          self.set('cfsToSave', []);
          return;
        }

        if (tasksToSave.length > 0) {
          return self.send('saveMultipleTasks', tasksToSave);
        }

        var authorityRestriction = self.prepareAuthorityRestrictionToSave();

        if (!authorityRestriction) {
          return;
        }

        var roleGroupId = authorityRestriction.get('roleGroup.id');
        var oldId = this.get('model.authorityRestriction.id');
        var url = "/authority-restrictions/".concat(oldId || '');
        var method = oldId ? 'PUT' : 'POST';
        this.get('ajaxService').send(url, method, authorityRestriction.serialize(), true).then(function (response) {
          authorityRestriction.unloadRecord();
          self.get('store').pushPayload(response);
          self.setProperties({
            success: true,
            errors: []
          });
          var msg = self.get('intl').t('settings.roleGroups.successUpdate');
          self.get('authoritiesIndexController').set('successMessage', msg);
          self.transitionToRoute('authorities.index', roleGroupId);
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      },
      saveMultipleCfs: function saveMultipleCfs(cfsToSave) {
        var self = this;
        this.set('saveSingle', true);
        cfsToSave.forEach(function (cf) {
          self.send('setAssetId', cf);
          self.send('save');
        });
        this.set('saveSingle', false);
      },
      saveMultipleTasks: function saveMultipleTasks(tasksToSave) {
        var _this2 = this;

        var self = this;
        var roleGroupId = '';
        var preparedAuthorityRestrictionsToSave = tasksToSave.map(function (task) {
          var oAR = self.get('model.authorityRestriction');
          var ar = self.store.createRecord('authority-restriction', {
            task: task,
            authorityRange: oAR.get('authorityRange'),
            authority: oAR.get('authority'),
            roleGroup: oAR.get('roleGroup'),
            assetId: oAR.get('assetId')
          });
          return self.prepareAuthorityRestrictionToSave(ar);
        });

        if (!preparedAuthorityRestrictionsToSave) {
          return;
        }

        var promiseFuncs = preparedAuthorityRestrictionsToSave.map(function (authorityRestriction) {
          return function () {
            roleGroupId = authorityRestriction.get('roleGroup.id');

            var oldId = _this2.get('model.authorityRestriction.id');

            var url = "/authority-restrictions/".concat(oldId || '');
            var method = oldId ? 'PUT' : 'POST';
            return self.get('ajaxService').send(url, method, authorityRestriction.serialize(), true);
          };
        });
        this.get('ajaxService').sequence(promiseFuncs).then(function (response) {
          self.get('store').pushPayload(response);
          self.setProperties({
            success: true,
            errors: []
          });
          var msg = self.get('intl').t('settings.roleGroups.successUpdate');
          self.get('authoritiesIndexController').set('successMessage', msg);
          self.get('store').peekAll('authority-restriction').filterBy('id', null).forEach(function (ar) {
            ar.unloadRecord();
          });

          var oldId = _this2.get('model.authorityRestriction.id');

          if (oldId) {
            var oldAr = self.get('store').peekRecord('authority-restriction', oldId);

            if (oldAr) {
              oldAr.unloadRecord();
            }
          }

          self.transitionToRoute('authorities.index', roleGroupId);
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      },
      cancel: function cancel() {
        var roleGroupId = this.get('model.authorityRestriction.roleGroup.id');
        this.get('model.authorityRestriction').unloadRecord();
        this.set('saveMultipleCfs', []);
        this.set('cfsToSave', []);
        this.transitionToRoute('authorities.index', roleGroupId);
      },
      delete: function _delete() {
        var self = this;
        var authorityRestriction = this.get('model.authorityRestriction');
        var roleGroupId = authorityRestriction.get('roleGroup.id');
        authorityRestriction.destroyRecord().then(function (a) {
          var msg = self.get('intl').t('settings.roleGroups.successDelete');
          self.get('authoritiesIndexController').set('successMessage', msg);
          self.transitionToRoute('authorities.index', roleGroupId);
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      }
    }
  });

  _exports.default = _default;
});