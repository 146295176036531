define("apollo/pods/mode-of-transportation/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    showCurrentStatus: Ember.computed('model.truck.status', function () {
      return this.get('model.truck').get('status');
    }),
    actions: {
      transitionTo: function transitionTo(route) {
        var id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

        if (id !== null) {
          this.transitionToRoute(route, id);
        } else {
          this.transitionToRoute(route);
        }
      }
    }
  });

  _exports.default = _default;
});