define("apollo/pods/components/max-pallet/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Component.extend({
    store: service(),
    sessionAccount: service('session-account'),
    classNames: ['clickable'],
    init: function init() {
      var self = this;

      self._super();

      self.get('store').queryRecord('maxPallet', {
        warehouseId: self.get('warehouse.id'),
        day: self.get('day.date').toDate()
      }).then(function (mp) {
        if (mp && !self.get('isTemplate')) {
          self.set('maxPallet', mp);
        } else {
          var maxPallet = self.get('store').createRecord('maxPallet', {
            warehouse: self.get('warehouse'),
            day: self.get('day.date').toDate(),
            quantity: 0
          });
          self.set('maxPallet', maxPallet);
        }
      });
    },
    willDestroy: function willDestroy() {
      var self = this;

      self._super();

      if (!self.get('maxPallet.id')) {
        self.get('maxPallet').unloadRecord();
      }
    },
    maxPallet: Ember.computed(function () {}),
    click: function click() {
      var self = this;

      if (!this.get('sessionAccount').hasRole('ROLE_CAN_CREATE_MAX_PALLET')) {
        return;
      }

      if (self.get('isTemplate')) {
        self.set('maxPallet.oldQuantity', self.get('maxPallet.quantity'));
        self.set('maxPallet.isTemplate', self.get('isTemplate'));
      }

      var model = Ember.Object.create({
        longNameDay: this.get('day.longName'),
        maxPallet: this.get('maxPallet')
      });
      self.set('action', 'showModal');
      this.sendAction('action', 'max-pallet.modals.create', model);
    }
  });

  _exports.default = _default;
});