define("apollo/pods/gate/model", ["exports", "ember-data", "ember-data/model", "apollo/mixins/model-validator"], function (_exports, _emberData, _model, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  var GateTypes = Object.freeze({
    ENTRY_GATE: 'ENTRY_GATE',
    EXIT_GATE: 'ENTRY_GATE'
  });

  var _default = _model.default.extend(_modelValidator.default, {
    intl: Ember.inject.service(),
    name: attr('string'),
    artrIdentifier: attr('string'),
    gateType: attr('string', {
      defaultValue: function defaultValue() {
        return GateTypes.ENTRY_GATE;
      }
    }),
    principalCompany: belongsTo('company'),
    gateTypes: Ember.computed('', function () {
      return Object.keys(GateTypes);
    }),
    gateTypeTranslation: Ember.computed('gateType', function () {
      var gateType = this.get('gateType');
      return gateType ? this.get('intl').t("gates.gateTypes.".concat(gateType)) : this.get('intl').t('gates.gateTypes.UNKNOWN');
    }),
    isEntryGate: Ember.computed('gateType', function () {
      return this.get('gateType') === GateTypes.ENTRY_GATE;
    }),
    isExitGate: Ember.computed('gateType', function () {
      return this.get('gateType') === GateTypes.EXIT_GATE;
    }),
    validations: {
      name: {
        presence: {
          message: 'blank'
        }
      },
      gateType: {
        custom: {
          validation: function validation(key, value) {
            return value && value in GateTypes;
          },
          message: 'blank'
        }
      }
    }
  });

  _exports.default = _default;
});