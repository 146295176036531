define("apollo/pods/transport-type/model", ["exports", "ember-data", "ember-data/model", "apollo/mixins/model-validator"], function (_exports, _emberData, _model, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;

  var _default = _model.default.extend(_modelValidator.default, {
    sessionAccount: Ember.inject.service('session-account'),
    languageService: Ember.inject.service('language-service'),
    intl: Ember.inject.service(),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    TYPE_GROUPS: {
      TRANSPORT: 'TRANSPORT',
      SERVICE: 'SERVICE',
      DELIVERY: 'DELIVERY',
      COLLECTION: 'COLLECTION',
      SIMPLIFIED_DELIVERY: 'SIMPLIFIED_DELIVERY',
      TRANSFER_BETWEEN_WAREHOUSES: 'TRANSFER_BETWEEN_WAREHOUSES'
    },
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    DATE_FILTER_PROPERTIES: ['DATE_CREATED', 'TIME_WINDOW_START', 'TIME_WINDOW_STOP', 'TIME_WINDOW_DATES', 'TIME_RANGE_FOR_ADVICE_START', 'TIME_RANGE_FOR_ADVICE_STOP', 'DEPARTURE_DATE', 'LOAD_DATE', 'UNLOAD_DATE'],
    packageTypeTransportType: hasMany('packageTypeTransportType', {
      async: true
    }),
    customFieldDefinitions: hasMany('customFieldDefinition', {
      async: true
    }),
    warehouses: hasMany('warehouse', {
      async: true
    }),
    company: belongsTo('company', {
      async: true
    }),
    workflow: belongsTo('workflow'),
    scheduleSetting: belongsTo('scheduleSetting'),
    name: attr('string'),
    englishName: attr('string'),
    alias: attr('string'),
    typeGroup: attr('string'),
    idx: attr('number'),
    quantitySumCheckValue: attr('number'),
    defaultLoadStepStartHour: attr('string'),
    defaultLoadStepEndHour: attr('string'),
    numberOfDaysAddedToFirstStep: attr('number'),
    numberOfDaysAddedToFirstStepEnd: attr('number'),
    numberOfDaysAddedToNextSteps: attr('number'),
    numberOfDaysAddedToNextStepsEnd: attr('number'),
    dateFilterProperty: attr('string'),
    requiresWarehouse: attr('boolean'),
    requiresTimeWindow: attr('boolean'),
    adviceSavedWithTransport: attr('boolean'),
    requiresErpIdentifier: attr('boolean'),
    requiresUniqueErpIdentifier: attr('boolean'),
    allowsErpIdentifier: attr('boolean'),
    allowsContractor: attr('boolean'),
    requiresContractor: attr('boolean'),
    calculatingTimeWindowSizeFromAlgorithm: attr('boolean'),
    requiresStepsWeight: attr('boolean'),
    allowsTransportSupervisor: attr('boolean'),
    hasVisibleSteps: attr('boolean'),
    requiresAddress: attr('boolean'),
    allowsCarrier: attr('boolean'),
    requiresCarrier: attr('boolean'),
    allowsService: attr('boolean'),
    requiresService: attr('boolean'),
    requiresContractorStepCompanyGroup: attr('boolean'),
    requiresTrailer: attr('boolean'),
    allowsTrailer: attr('boolean'),
    adviceRequiresPhoneNumber: attr('boolean'),
    altIdentifierIsCreatedAutomatically: attr('boolean'),
    carrierMustBeSelectedBeforeAdvice: attr('boolean'),
    maximumPermissableVehicleWeight: attr('boolean'),
    isWeightVisibleInContractorStep: attr('boolean'),
    isVolumeVisibleInContractorStep: attr('boolean'),
    isInternalReleaseNumberVisibleInContractorStep: attr('boolean'),
    isWeightVisibleInWarehouseStep: attr('boolean'),
    isVolumeVisibleInWarehouseStep: attr('boolean'),
    isInternalReleaseNumberVisibleInWarehouseStep: attr('boolean'),
    canBeEditedByRoleGroup: attr('boolean'),
    requiresAdviceDateRestrictions: attr('boolean'),
    hasDiscrepancyReportEnabled: attr('boolean'),
    allowsAltIdentifier: attr('boolean'),
    requiresAltIdentifier: attr('boolean'),
    altIdentifierSequenceName: attr('string'),
    altIdentifierFormat: attr('string'),
    altIdentifierRegex: attr('string'),
    requiresAdvice: attr('boolean'),
    pastingFromExcelEnabled: attr('boolean'),
    requiresDriverDocumentNumber: attr('boolean'),
    requiresDriverName: attr('boolean'),
    generatingWeightReceiptEnabled: attr('boolean'),
    isWarehouseZoneVisible: attr('boolean'),
    isWarehouseLocationVisible: attr('boolean'),
    isWarehouseLocationObligatory: attr('boolean'),
    adviceRestrictionInEveryStep: attr('boolean'),
    allowLoadStepTypeChange: attr('boolean'),
    allowUnloadStepTypeChange: attr('boolean'),
    isLoadStepInWarehouseByDefault: attr('boolean'),
    isUnloadStepInWarehouseByDefault: attr('boolean'),
    hasCmrDocumentEnabled: attr('boolean'),
    hasEntrancePassDocumentEnabled: attr('boolean'),
    hasProductSpecificationDocumentEnabled: attr('boolean'),
    allowsDriverName: attr('boolean'),
    allowsPhoneNumber: attr('boolean'),
    allowsRodoCheckbox: attr('boolean'),
    requiresCarRegistrationNumber: attr('boolean'),
    allowsPeriodicTransport: attr('boolean'),
    requiresArrivalAndDepartureDate: attr('boolean'),
    showAddressFieldsInContractorSteps: attr('boolean'),
    windowColorDependsOnWarehouse: attr('boolean'),
    requiresDriverLanguage: attr('boolean'),
    allowsDriverDocumentNumber: attr('boolean'),
    allowsCarRegistrationNumber: attr('boolean'),
    requiresModeOfTransportationVerification: attr('boolean'),
    adviceSeparatedFromWindow: attr('boolean'),
    selectionOfWindowBeforeAdvice: attr('boolean'),
    newAddressAsDefaultInLoadStep: attr('boolean'),
    newAddressAsDefaultInUnloadStep: attr('boolean'),
    newAddressCityAutocompleteEnabled: attr('boolean'),
    isAvailableInAdviceReport: attr('boolean'),
    documentIdentifierTranslationKey: attr('string'),
    workerFieldTranslationKey: attr('string'),
    transportSupervisorTranslationKey: attr('string'),
    allowsPriceList: attr('boolean'),
    duplicateStepCountryFieldToNewSteps: attr('boolean'),
    randomTransportInspection: attr('boolean'),
    isChoosingWorkerObligatory: attr('boolean'),
    defaultLoadCompanyId: attr('number'),
    defaultUnloadCompanyId: attr('number'),
    defaultServicemanCompanyId: attr('number'),
    participatesInCombiningTransports: attr('boolean'),
    shouldGenerateTransportWeightReceipt: attr('boolean'),
    shouldCopyAdviceOnTransportCopy: attr('boolean'),
    loadingCompleted: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    readyToLoad: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    unhandledTransportIds: attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    isContactDetailsFieldsVisible: attr('boolean'),
    isContactDetailsFieldsRequired: attr('boolean'),
    purchaseOrderNumberTranslationKey: attr('string'),
    purchaseOrderNumberVisibleInContractorStep: attr('boolean'),
    purchaseOrderNumberVisibleInWarehouseStep: attr('boolean'),
    dimensionsTranslationKey: attr('string'),
    dimensionsVisibleInContractorStep: attr('boolean'),
    dimensionsVisibleInWarehouseStep: attr('boolean'),
    alternativeStepsLayout: attr('boolean'),
    supplierFieldTranslationKey: attr('string'),
    guardCheckpointConfirmationSingleColumnLayout: attr('boolean'),
    validations: {
      defaultLoadStepStartHour: {
        custom: [{
          validation: function validation(key, value) {
            if (!value) {
              return true;
            }

            return /^([01]?\d|2[0-3]):([0-5]\d)$/.test(value);
          },
          message: 'regex'
        }]
      },
      defaultLoadStepEndHour: {
        custom: [{
          validation: function validation(key, value) {
            if (!value) {
              return true;
            }

            return /^([01]?\d|2[0-3]):([0-5]\d)$/.test(value);
          },
          message: 'regex'
        }]
      },
      name: {
        presence: {
          message: 'blank'
        }
      },
      englishName: {
        presence: {
          message: 'blank'
        }
      },
      quantitySumCheckValue: {
        numericality: {
          allowBlank: true,
          greaterThanOrEqualTo: 0,
          onlyInteger: true,
          message: 'invalidQuantity'
        }
      },
      altIdentifierFormat: {
        custom: [{
          validation: function validation(key, value, model) {
            var cfdString = 'cfd_';

            if (value && value.includes(cfdString)) {
              var cfdId = value.split('$').find(function (v) {
                return v.includes(cfdString);
              }).replace(/\D/g, '');
              return model.get('sortedFieldDefinitions').some(function (cfd) {
                return cfd.get('id') === cfdId;
              });
            }

            return true;
          },
          message: function message(key, value, model) {
            return model.get('intl').t('settings.transportTypes.invalidCustomFieldId');
          }
        }]
      }
    },
    // ustawiane w sessionAccount.js
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    authorityRestrictions: [],
    label: Ember.computed('name', function () {
      return this.get('nameInAppropriateLanguage');
    }).readOnly(),
    sortedFieldDefinitions: Ember.computed('customFieldDefinitions.@each.{id,enabled}', function () {
      return this.get('customFieldDefinitions').filterBy('enabled').sortBy('idx');
    }),
    // TODO: Zmienić `filterInArchive` na `filterInTable`
    customFieldFiltersInTable: Ember.computed('sortedFieldDefinitions.@each.{filterInArchive,hiddenInTable}', function () {
      return this.get('sortedFieldDefinitions').filterBy('filterInArchive').filterBy('hiddenInTable');
    }),
    customFieldDefinitionsVisibleInTable: Ember.computed('sortedFieldDefinitions.@each.visibleInTable', function () {
      return this.get('sortedFieldDefinitions').filterBy('visibleInTable').filterBy('canBeViewed');
    }),
    customFieldDefinitionsIncludedInPriceList: Ember.computed('sortedFieldDefinitions.@each.includedInPriceList', function () {
      return this.get('sortedFieldDefinitions').filterBy('includedInPriceList');
    }),
    warehousesForCurrentUser: Ember.computed('warehouses.@each.id', 'sessionAccount.currentUser.warehouses.@each.id', function () {
      var userWarehouses = this.get('sessionAccount.currentUser.accessibleWarehouses');
      var userWarehousesIds = userWarehouses.map(function (w) {
        return w.get('id');
      });
      var transportTypeWarehouseIds = this.hasMany('warehouses').ids();
      var warehouseIdsIntersection = userWarehousesIds.filter(function (id) {
        return transportTypeWarehouseIds.includes(id);
      });
      return this.get('store').peekAll('warehouse').filter(function (w) {
        return warehouseIdsIntersection.includes(w.id);
      }).sortBy('idx');
    }).readOnly(),
    guardUniqueSortedTasks: Ember.computed('guardSortedTasks.{[],@each.alias}', function () {
      var guardTasks = this.get('guardSortedTasks');
      var uniqueGuardTasks = Ember.A([]);

      if (!guardTasks) {
        return uniqueGuardTasks;
      }

      guardTasks.sortBy('masterTask.idxInGroup', 'taskGroupIdentifier', 'idxInGroup').forEach(function (task) {
        if (uniqueGuardTasks.some(function (uTask) {
          return uTask.get('alias') === task.get('alias');
        })) {
          return;
        }

        uniqueGuardTasks.pushObject(task);
      });
      return uniqueGuardTasks;
    }),
    guardSortedTasks: Ember.computed('workflow.sortedTasks.@each.canBeConfirmedByGuard', function () {
      return this.get('workflow.sortedTasks').filterBy('canBeConfirmedByGuard');
    }).readOnly(),
    indexQuantityTypes: Ember.computed('', function () {
      return this.get('store').peekAll('indexQuantityType').filterBy('transportType.id', this.get('id')).sortBy('idx');
    }).readOnly(),
    conditionallyObligatoryIndexQuantityTypes: Ember.computed('indexQuantityTypes.@each.conditionallyObligatory', function () {
      return this.get('indexQuantityTypes').filterBy('conditionallyObligatory');
    }),
    weighingOnSave: Ember.computed('tasks.@each.weighingIncluded', function () {
      var self = this;
      var tasksSavedWithTransport = this.get('store').peekAll('task').filter(function (task) {
        return task.get('workflow.id') === self.get('workflow.id') && task.get('shouldBeSavedAsApproved');
      }).sortBy('taskGroupIdentifier', 'idxInGroup');

      if (tasksSavedWithTransport.get('length') === 0) {
        return false;
      }

      return tasksSavedWithTransport.get('lastObject.weighingIncluded');
    }),
    allowTimeWindow: Ember.computed('typeGroup', function () {
      return this.get('typeGroup') !== this.TYPE_GROUPS.SERVICE;
    }).readOnly(),
    findPositiveAuthorityRestrictionsByActionType: function findPositiveAuthorityRestrictionsByActionType(actionType, assetId) {
      var _this = this;

      var currentPricingPlan = this.get('sessionAccount.currentUser.company.pricingPlan');
      return this.get('sessionAccount.authorityRestrictions').filter(function (ar) {
        var referenceId = assetId ? Number(assetId) : Number(_this.get('id'));

        if (ar.get('assetId') !== referenceId) {
          return false;
        }

        if (ar.get('pricingPlan') && ar.get('pricingPlan') !== currentPricingPlan) {
          return false;
        }

        var authority = _this.get('sessionAccount.currentUser.roles').find(function (a) {
          return a.get('name') === actionType;
        });

        return authority && ar.get('authority.id') === authority.get('id');
      });
    },
    findPositiveAuthorityRestrictionsByActionNameAndCustomFieldDefinition: function findPositiveAuthorityRestrictionsByActionNameAndCustomFieldDefinition(actionType, customFieldDefinitionId) {
      return this.findPositiveAuthorityRestrictionsByActionType(actionType, customFieldDefinitionId);
    },
    findPositiveAuthorityRestrictionsByActionTypes: function findPositiveAuthorityRestrictionsByActionTypes(actionTypes) {
      return this.get('authorityRestrictions').filter(function (actionType) {
        return actionTypes.includes(actionType.get('name'));
      });
    },
    canBeCreatedByRoleGroup: Ember.computed('authorityRestrictions', function () {
      return this.findPositiveAuthorityRestrictionsByActionType('CREATE_TRANSPORT').get('length') > 0;
    }),
    canBeSeenByRoleGroup: Ember.computed('authorityRestrictions', function () {
      return this.findPositiveAuthorityRestrictionsByActionType('ACCESS_TRANSPORT').get('length') > 0;
    }),
    canBeSeenInArchiveByRoleGroup: Ember.computed('authorityRestrictions', function () {
      return this.findPositiveAuthorityRestrictionsByActionType('ACCESS_TRANSPORT').filterBy('authorityRange', 'UNLIMITED').get('length') > 0;
    }),
    nameInAppropriateLanguage: Ember.computed('name', 'englishName', function () {
      var propertyName = this.get('languageService').findProperLanguageVersion('name', 'englishName');
      return this.get(propertyName);
    }),
    indexSetting: Ember.computed('', function () {
      var self = this;
      return this.get('store').peekAll('index-setting').find(function (is) {
        return is.get('transportType.id') === self.get('id');
      });
    }),
    courierSetting: Ember.computed('', function () {
      var self = this;
      return this.get('store').peekAll('courier-setting').find(function (is) {
        return is.get('transportType.id') === self.get('id');
      });
    }),
    connectedAssetIds: Ember.computed('id', 'customFieldDefinitions.@each.id', function () {
      return [this.get('id')].concat(this.get('customFieldDefinitions').getEach('id')).map(function (id) {
        return Number(id);
      });
    })
  });

  _exports.default = _default;
});