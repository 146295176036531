define("apollo/pods/components/sections/document-section/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PhSv1KNn",
    "block": "{\"symbols\":[\"document\"],\"statements\":[[4,\"expandable-panel\",null,[[\"objectId\",\"componentName\",\"title\",\"titleClass\",\"customIconClass\",\"defaultOpen\"],[[23,[\"documentType\",\"id\"]],[23,[\"documentType\",\"name\"]],[27,\"t\",[[27,\"concat\",[\"documentType.\",[23,[\"documentType\",\"translationKey\"]],\".name\"],null]],null],\"bold\",[27,\"document-icon\",null,[[\"documentTypeName\"],[[23,[\"documentType\",\"name\"]]]]],true]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,[\"activeDocumentsOfType\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"row\"],[12,\"data-document-id\",[22,1,[\"id\"]]],[9],[0,\"\\n            \"],[1,[27,\"sections/document-section/document-form\",null,[[\"documentType\",\"document\",\"modal\"],[[23,[\"documentType\"]],[22,1,[]],false]]],false],[0,\"\\n            \"],[1,[27,\"sections/document-section/document-state-buttons\",null,[[\"document\",\"modelObject\"],[[22,1,[]],[23,[\"modelObject\"]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"hr\"],[9],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[1,[27,\"sections/document-section/document-section-manager\",null,[[\"modelObject\",\"documentType\"],[[23,[\"modelObject\"]],[23,[\"documentType\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/sections/document-section/template.hbs"
    }
  });

  _exports.default = _default;
});