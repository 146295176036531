define("apollo/pods/statistics-report/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "apollo/mixins/menu-mixin"], function (_exports, _authenticatedRouteMixin, _menuMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _menuMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    cookies: Ember.inject.service(),
    access: Ember.A(['ROLE_STATISTICS_REPORT_TAB']),
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model() {
      var statisticsReports = this.store.findAll('statistics-report');
      var currentReport = statisticsReports.get('firstObject');
      return Ember.RSVP.hash({
        statisticsReports: statisticsReports,
        currentReport: currentReport
      });
    },
    activate: function activate() {
      this.activateCurrentTab('STATISTICS_REPORT_TAB');
    }
  });

  _exports.default = _default;
});