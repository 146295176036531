define("apollo/pods/components/companies/apply-form/insurance-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    groupName: Ember.computed('', function () {
      return 'INSURANCE';
    }),
    archivedInsurances: Ember.computed('documentType.id', 'partnership.archivedInsurances', function () {
      return this.get('partnership.archivedInsurances').filterBy('documentType.id', this.get('documentType.id'));
    }),
    actions: {
      showModal: function showModal(templateName, model) {
        this.set('action', 'showModal');
        this.sendAction('action', templateName, model);
      },
      refresh: function refresh() {
        this.get('partnership').reload().then(function (p) {
          return p.get('documents').reload();
        });
      },
      showArchived: function showArchived() {
        this.set('action', 'showModal');
        var model = {
          groupName: this.get('groupName'),
          documentType: this.get('documentType'),
          documents: this.get('archivedInsurances')
        };
        this.sendAction('action', 'documents.modals.document-list-modal', model);
      }
    }
  });

  _exports.default = _default;
});