define("apollo/pods/components/queue-status/modals/send-sms/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajaxService: Ember.inject.service('ajax-service'),
    intl: Ember.inject.service(),
    errors: [],
    clearComponent: function clearComponent() {
      this.set('errors', []);
      this.set('message', null);
    },
    actions: {
      sendSms: function sendSms(queue, message) {
        var self = this;

        if (!message) {
          self.set('errors', [this.get('intl').t('errors.blank')]);
          return;
        }

        var data = {
          queueId: queue.get('id'),
          message: message
        };
        this.get('ajaxService').send('/notifications/sms', 'PUT', data, true).then(function () {
          jQuery("#".concat(queue.get('id'))).modal('hide');
          self.clearComponent();
        }).catch(function (response) {
          self.set('errors', response.responseJSON["errors"]);
        });
      },
      close: function close(queue) {
        jQuery("#".concat(queue.get('id'))).modal('hide');
        this.clearComponent();
      }
    }
  });

  _exports.default = _default;
});