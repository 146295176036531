define("apollo/pods/auctions/modals/auction-form/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    language: Ember.computed(function () {
      return this.get('intl.locale')[0];
    }),
    currencies: Ember.computed('', function () {
      return this.get('store').peekAll('currency');
    }),
    carriers: Ember.computed('model.carriers', function () {
      return this.get('model.carriers').filter(function (c) {
        return c.get('disabled') === false;
      }).sortBy('shortName');
    }),
    blockedCarriers: Ember.computed('model.carriers', function () {
      return this.get('model.carriers').filter(function (c) {
        return c.get('disabled') === true;
      }).sortBy('shortName');
    }),
    auctionSetting: Ember.computed('transport.transportType.id', function () {
      return this.get('sessionAccount').getAuctionSetting(this.get('transport.transportType.id'));
    }),
    showBlockedCompaniesSection: Ember.computed('auctionSetting', function () {
      var auctionSetting = this.get('auctionSetting');
      return !!auctionSetting && auctionSetting.get('showBlockedCompaniesSection');
    }),
    showCompanyGroupsSection: Ember.computed('auctionSetting', function () {
      var auctionSetting = this.get('auctionSetting');
      return !!auctionSetting && auctionSetting.get('showCompanyGroupsSection');
    }),
    sortedCarriers: Ember.computed('carriers.@each.shortName', function () {
      return this.get('carriers').sortBy('shortName');
    }),
    auction: Ember.computed('model', function () {
      return this.get('model');
    }),
    transport: Ember.computed('auction.transport', function () {
      return this.get('auction.transport');
    }),
    title: Ember.computed('transport.identifier', 'transport.formattedCities', function () {
      return "".concat(this.get('transport.identifier'), ": ").concat(this.get('transport.formattedCities'));
    }),
    showPriceRangeFields: Ember.computed('auctionSetting', function () {
      var auctionSetting = this.get('auctionSetting');

      if (!auctionSetting) {
        return true;
      }

      return auctionSetting.get('showPriceRangeFields');
    }),
    canAcceptWithMinPrice: Ember.computed('auctionSetting', function () {
      var auctionSetting = this.get('auctionSetting');

      if (!auctionSetting) {
        return true;
      }

      return auctionSetting.get('canAcceptWithMinPrice');
    }),
    // Carriers' checkboxes
    proxiedCarriers: Ember.computed('sortedCarriers.@each', 'auction', 'auctionSetting', function () {
      var auctionSetting = this.get('auctionSetting');
      var defaultValue = auctionSetting ? auctionSetting.get('selectAllCarriersByDefault') : false;
      return this.get('sortedCarriers').map(function (carrier) {
        return Ember.ObjectProxy.create({
          content: carrier,
          checked: defaultValue
        });
      });
    }),
    sortedGroups: Ember.computed('model.carriersGroups.@each', function () {
      return this.get('model.carriersGroups').sortBy('nameInAppropriateLanguage');
    }),
    proxiedGroups: Ember.computed('sortedGroups.@each', 'auction', function () {
      return this.get('sortedGroups').map(function (group) {
        return Ember.ObjectProxy.create({
          content: group,
          checked: false
        });
      });
    }),
    proxiedCheckedCarriers: Ember.computed.filterBy('proxiedCarriers', 'checked', true),
    checkedCarriers: Ember.computed.mapBy('proxiedCheckedCarriers', 'content'),
    allCarriersSelected: Ember.computed('carriers.length', 'checkedCarriers.length', function () {
      return this.get('carriers.length') === this.get('checkedCarriers.length');
    }),
    proxiedCheckedGroups: Ember.computed.filterBy('proxiedGroups', 'checked', true),
    checkedGroups: Ember.computed.mapBy('proxiedCheckedGroups', 'content'),
    allGroupsSelected: Ember.computed('sortedGroups.length', 'checkedGroups.length', function () {
      return this.get('sortedGroups.length') === this.get('checkedGroups.length');
    }),
    actions: {
      setFinishDate: function setFinishDate(dateValues) {
        var _this = this;

        Ember.run.next(function () {
          _this.get('model').set('plannedFinishTime', dateValues[0]);
        });
      },
      toggleAllCarriers: function toggleAllCarriers() {
        var shouldBeChecked = !this.get('allCarriersSelected');
        this.get('proxiedCarriers').forEach(function (proxyCarrier) {
          proxyCarrier.set('checked', shouldBeChecked);
        });
      },
      toggleAllGroups: function toggleAllGroups() {
        var self = this;
        var shouldBeChecked = !this.get('allGroupsSelected');
        this.get('proxiedGroups').forEach(function (proxyGroup) {
          self.send('toggleGroup', proxyGroup, shouldBeChecked);
        });
      },
      toggleGroup: function toggleGroup(group, shouldBeChecked) {
        var self = this;
        shouldBeChecked = shouldBeChecked ? shouldBeChecked : !group.get('checked');
        group.set('checked', shouldBeChecked);
        var checkedGroupIds = self.get('checkedGroups').getEach('id');
        var carriersWhoShouldBe = this.get('proxiedCarriers').filter(function (pc) {
          var contractorsGroupIds = pc.get('partnershipWithPrincipal.contractorsGroups').getEach('id');
          return contractorsGroupIds.some(function (id) {
            return checkedGroupIds.includes(id);
          });
        });
        carriersWhoShouldBe.forEach(function (proxyCarrier) {
          proxyCarrier.set('checked', true);
        });
        this.get('proxiedCarriers').filter(function (pc) {
          return !carriersWhoShouldBe.getEach('id').includes(pc.get('id'));
        }).forEach(function (proxyCarrier) {
          proxyCarrier.set('checked', false);
        });
      },
      save: function save() {
        var _this2 = this;

        console.log("Saving auction for transport ".concat(this.get('transport.id'), ".."));
        var self = this;
        this.set('savingInProgress', true);
        this.get('auction.companies').clear();
        this.get('checkedCarriers').forEach(function (carrier) {
          _this2.get('auction.companies').pushObject(carrier);
        });

        if (!this.get('auction').validate()) {
          console.log("Auction did not pass validation.");
          self.set('savingInProgress', false);
          return;
        }

        this.get('auction').save().then(function (savedAuction) {
          console.log("Auction successfully saved with id ".concat(savedAuction.get('id'), "!"));
          self.set('transport.transportType.forcedPollRequestTime', new Date().getTime());
          self.setProperties({
            savingInProgress: false,
            errors: []
          });
          self.send('hideModal');
        }).catch(function (response) {
          self.setProperties({
            savingInProgress: false,
            errors: response.errors
          });
        });
      },
      close: function close() {
        this.send('hideModal');
      },
      cancel: function cancel() {
        console.debug("Wycofujemy zmiany z okienka aukcji...");

        if (!this.get('auction.id')) {
          this.get('transport.auctions').removeObject(this.get('auction'));
        }

        this.set('errors', []);
      }
    }
  });

  _exports.default = _default;
});