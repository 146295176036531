define("apollo/pods/transport-types/package-type-settings/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    errors: [],
    packageTypeTransportTypes: Ember.computed('model.packageTypeTransportTypes', function () {
      return this.get('model.packageTypeTransportTypes').sortBy('idx');
    }),
    referencePackage: Ember.computed('model.packageTypeTransportTypes', function () {
      var refPckg = null;
      var pt = this.get('store').peekAll('packageTypeTransportType').find(function (pt) {
        return pt.get('referencePackage');
      });

      if (pt) {
        refPckg = pt.get('packageType');
      }

      return refPckg;
    }),
    selectedPackageTypeTransportType: Ember.computed('packageTypeTransportTypes.{firstObject,length}', function () {
      return this.get('packageTypeTransportTypes.firstObject');
    }),
    actions: {
      setPackageTypeTransportType: function setPackageTypeTransportType(pttt) {
        this.set('selectedPackageTypeTransportType', pttt);
        this.set('errors', []);
        this.set('successMessage', null);
      },
      add: function add() {
        var pttt = this.get('store').createRecord('packageTypeTransportType', {
          transportType: this.get('model.transportType')
        });
        this.get('packageTypeTransportTypes').pushObject(pttt);
        this.set('selectedPackageTypeTransportType', pttt);
      },
      cancel: function cancel(pttt) {
        this.get('packageTypeTransportTypes').removeObject(pttt);
        pttt.unloadRecord();
        this.set('selectedPackageTypeTransportType', this.get('packageTypeTransportTypes.firstObject'));
        this.set('errors', []);
        this.set('successMessage', null);
      },
      delete: function _delete(pttt) {
        var _this = this;

        var ptttId = pttt.get('id');
        pttt.destroyRecord().then(function () {
          var objectToRemove = _this.get('packageTypeTransportTypes').find(function (pttt) {
            return pttt.get('id') === ptttId;
          });

          _this.get('packageTypeTransportTypes').removeObject(objectToRemove);

          _this.set('selectedPackageTypeTransportType', _this.get('packageTypeTransportTypes.firstObject'));
        }).catch(function (response) {
          _this.set('errors', response.errors);
        });
      }
    }
  });

  _exports.default = _default;
});