define("apollo/pods/task/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: Object.freeze({
      subTasks: {
        embedded: 'save'
      }
    }),
    serializeHasMany: function serializeHasMany(record, json, relationship) {
      var key = relationship.key;
      var hasManyRecords = record.hasMany(key);

      if (hasManyRecords && this.attrs[key] && this.attrs[key].embedded === 'save') {
        json[key] = [];
        hasManyRecords.forEach(function (item) {
          var data = item.attributes();
          data.id = item.id;
          json[key].push(data);
        });
      } else {
        this._super(record, json, relationship);
      }
    }
  });

  _exports.default = _default;
});