define("apollo/pods/auth/reset-password/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    queryParams: {
      hash: {
        refreshModel: true
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        hash: params.hash
      });
    },
    renderTemplate: function renderTemplate(controller, model) {
      this.render('auth/reset-password', {
        into: 'application',
        outlet: 'reset-password',
        controller: controller,
        model: model
      });
    }
  });

  _exports.default = _default;
});