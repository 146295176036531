define("apollo/pods/components/phone-prefix/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    selectedPhonePrefix: Ember.computed('advice.driverMobilePhonePrefix', 'existingPhonePrefix', function () {
      var _this = this;

      var defaultPrefix = this.get('store').peekAll('country').find(function (country) {
        return country.ibanSymbol === 'PL';
      });
      var existingPrefix = this.get('store').peekAll('country').find(function (country) {
        return country.phonePrefix === _this.get('existingPhonePrefix');
      });
      return existingPrefix || defaultPrefix;
    }),
    disabled: Ember.computed(function () {
      return this.get('advice.disabled') || this.get('shouldBeDisabled');
    }),
    phonePrefixes: Ember.computed(function () {
      var sortedPhonePrefixes = this.get('store').peekAll('country').map(function (country) {
        return {
          phonePrefix: country.phonePrefix,
          ibanSymbol: country.ibanSymbol
        };
      }).sortBy('ibanSymbol');
      var polishPhonePrefix = sortedPhonePrefixes.find(function (element) {
        return element.ibanSymbol === 'PL';
      });
      var index = sortedPhonePrefixes.indexOf(polishPhonePrefix); // chcemy mieć polski kierunkowy na pierwszym miejscu

      sortedPhonePrefixes.splice(index, 1);
      sortedPhonePrefixes.unshift(polishPhonePrefix);
      return sortedPhonePrefixes;
    }),
    searchForPhonePrefix: function searchForPhonePrefix(term, obj) {
      var phonePrefixes = obj.options;
      return phonePrefixes.filter(function (element) {
        return element.ibanSymbol.includes(term) || element.phonePrefix.includes(term);
      });
    },
    actions: {
      setPhonePrefix: function setPhonePrefix(phonePrefix) {
        this.set('selectedPhonePrefix', phonePrefix);
        this.setPhonePrefix(phonePrefix.phonePrefix);
      }
    }
  });

  _exports.default = _default;
});