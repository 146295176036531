define("apollo/pods/components/schedule/time-bar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['time-bar'],
    didInsertElement: function didInsertElement() {
      var self = this;
      var $el = self.$();
      var leftOffset = parseInt($el.offset().left);
      $(window).scroll(function () {
        $el.css({
          'left': $(this).scrollLeft() + leftOffset
        });
      });
      this.send('notifyTimeBarRendered');
    },
    numberOfTimeObjectsChanged: Ember.observer('timeObjects.length', function () {
      var self = this;
      Ember.run.scheduleOnce('afterRender', function () {
        self.send('notifyTimeBarRendered');
      });
    }),
    actions: {
      notifyTimeBarRendered: function notifyTimeBarRendered() {
        this.set('action', 'timeBarRendered');
        this.sendAction('action');
      }
    }
  });

  _exports.default = _default;
});