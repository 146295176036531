define("apollo/pods/warehouse-locations/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: service('session-account'),
    model: function model(params) {
      var warehouse = this.get('store').peekRecord('warehouse', params.warehouse_id);
      var warehouseLocation = this.store.createRecord('warehouseLocation', {
        enabled: true,
        warehouse: warehouse
      });
      var gates = this.get('store').findAll('gate');
      return Ember.RSVP.hash({
        warehouseLocation: warehouseLocation,
        gates: gates
      });
    }
  });

  _exports.default = _default;
});