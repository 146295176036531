define("apollo/pods/warehouse-zone-transport-type/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    transportType: _emberData.default.belongsTo('transportType'),
    warehouseZone: _emberData.default.belongsTo('warehouseZone')
  });

  _exports.default = _default;
});