define("apollo/pods/components/price-list/route-offers-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    classNames: ['route-offers-table'],
    ajaxService: Ember.inject.service('ajax-service'),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    numberOfAllOffers: Ember.computed('offers.length', function () {
      return this.get('offers.length');
    }),
    defaultDisplayLimit: Ember.computed('sessionAccount', function () {
      var DEFAULT_LIMIT = 6;
      var limit = this.get('sessionAccount').getSettingValue('DEFAULT_NUMBER_OF_DISPLAYED_OFFERS') || DEFAULT_LIMIT;
      return parseInt(limit);
    }),
    displayLimit: Ember.computed('defaultDisplayLimit', function () {
      return this.get('defaultDisplayLimit');
    }),
    slicedOffers: Ember.computed('offers', 'displayLimit', function () {
      return this.get('offers').slice(0, this.get('displayLimit'));
    }),
    numberOfDisplayedOffers: Ember.computed('slicedOffers.length', function () {
      return this.get('slicedOffers.length');
    }),
    lengthOfNextSlice: Ember.computed('numberOfAllOffers', 'numberOfDisplayedOffers', 'defaultDisplayLimit', function () {
      var numberOfRemainingOffers = this.get('numberOfAllOffers') - this.get('numberOfDisplayedOffers');
      return Math.min(numberOfRemainingOffers, this.get('defaultDisplayLimit'));
    }),
    showMoreShouldBeVisible: Ember.computed('numberOfAllOffers', 'numberOfDisplayedOffers', function () {
      return this.get('numberOfDisplayedOffers') < this.get('numberOfAllOffers');
    }),
    actions: {
      increaseDisplayLimit: function increaseDisplayLimit() {
        var newLimit = this.get('displayLimit') + this.get('defaultDisplayLimit');
        this.set('displayLimit', newLimit);
      },
      confirm: function (_confirm) {
        function confirm(_x) {
          return _confirm.apply(this, arguments);
        }

        confirm.toString = function () {
          return _confirm.toString();
        };

        return confirm;
      }(function (offer) {
        var _this = this;

        console.log("confirm ".concat(offer, "..."));

        if (!confirm(this.get('intl').t('route.offer.confirmation.message'))) {
          return;
        }

        this.get('ajaxService').send("/route-offers/".concat(offer.id, "/confirmation/"), 'POST').then(function () {
          console.log(" > Potwierdzili\u015Bmy ofert\u0119 ".concat(offer));
          offer.reload();
        }).catch(function () {
          alert(_this.get('intl').t('route.offer.confirmation.failure'));
        });
      }),
      edit: function edit(offer) {
        this.openEditRateModal(offer);
      },
      delete: function _delete(offer) {
        var hasAuth = this.get('sessionAccount').hasAuthorityInTransportType('ROLE_CAN_DELETE_ROUTE_OFFER', offer.get('route.transportType.id'));

        if (!hasAuth) {
          console.error('Użytkownik nie ma uprawnień do usuwania ofert');
          return;
        }

        if (!confirm(this.get('intl').t('route.offer.deleteMessage'))) {
          return;
        }

        offer.deleteRecord();
        offer.save().then(function (deletedOffer) {
          console.debug("Usuni\u0119to ofert\u0119 ".concat(deletedOffer.id));
        });
      }
    }
  });

  _exports.default = _default;
});