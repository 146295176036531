define("apollo/pods/transport-types/edit/controller", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Controller.extend({
    sessionAccount: service('session-account'),
    actions: {
      cancel: function cancel() {
        this.transitionToRoute('settings.index');
      },
      sortEndAction: function sortEndAction() {
        var dataToSend = [];
        this.get('model.sortedFieldDefinitions').forEach(function (cfd, idx) {
          cfd.set('idx', idx);
          dataToSend.pushObject({
            id: cfd.get('id'),
            idx: cfd.get('idx')
          });
        });
        var token = this.get('sessionAccount').get('token');
        Ember.$.ajax({
          url: _environment.default.serverURL + '/custom-field-definitions/update-ordering',
          type: 'PUT',
          data: JSON.stringify(dataToSend),
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          console.log('Zedytowano kolejność custom fieldów');
        });
      }
    }
  });

  _exports.default = _default;
});