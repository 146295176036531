define("apollo/mixins/menu-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    activateCurrentTab: function activateCurrentTab(tabName) {
      $("#navbar li").removeClass("menu-element-active");
      $("#navbar").find("[data-tab-name='" + tabName + "']").addClass("menu-element-active");
    }
  });

  _exports.default = _default;
});