define("apollo/pods/role-groups/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    settingsIndexController: Ember.inject.controller('settings/index'),
    IS_GUARD_OPTIONS: Ember.computed(function () {
      return [this.get('intl').t('common.yes'), this.get('intl').t('common.no')];
    }).readOnly('true'),
    getIsGuardValue: Ember.computed('model.roleGroup.isGuard', function () {
      if (this.get('model.roleGroup.isGuard') !== undefined) {
        return this.get('model.roleGroup.isGuard') ? this.get('intl').t('common.yes') : this.get('intl').t('common.no');
      }

      return this.get('intl').t('common.no');
    }),
    actions: {
      save: function save() {
        var self = this;
        var roleGroup = this.get('model.roleGroup');

        if (!roleGroup.validate()) {
          console.log('Nowa rola nie przeszła walidacji..');
          return;
        }

        roleGroup.save().then(function (roleGroup) {
          self.setProperties({
            errors: []
          });
          var msg = self.get('intl').t('settings.roleGroups.successCreate');
          self.get('settingsIndexController').set('successMessage', msg);
          self.transitionToRoute('settings.index');
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      },
      cancel: function cancel() {
        this.get('model.roleGroup').unloadRecord();
        this.transitionToRoute('settings.index');
      },
      setIsGuardFlag: function setIsGuardFlag(guardFlag) {
        if (guardFlag === this.get('intl').t('common.yes')) {
          this.set('model.roleGroup.isGuard', true);
        } else {
          this.set('model.roleGroup.isGuard', false);
        }
      }
    }
  });

  _exports.default = _default;
});