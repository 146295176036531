define("apollo/pods/components/tasks-sortable-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      sortEndAction: function sortEndAction() {
        this.set('action', 'sortEndAction');
        this.sendAction('action');
      },
      addTask: function addTask(identifier, idx) {
        this.set('action', 'addTask');
        this.sendAction('action', identifier, idx);
      },
      dragStart: function dragStart(object) {
        this.set('action', 'dragStart');
        this.sendAction('action', object);
      },
      toggleMarkIfWasDelayed: function toggleMarkIfWasDelayed(task) {
        this.set('action', 'toggleMarkIfWasDelayed');
        this.sendAction('action', task);
      },
      toggleTracked: function toggleTracked(task) {
        this.set('action', 'toggleTracked');
        this.sendAction('action', task);
      }
    }
  });

  _exports.default = _default;
});