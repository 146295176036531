define("apollo/pods/transports/archive/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "apollo/mixins/menu-mixin"], function (_exports, _authenticatedRouteMixin, _menuMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _menuMixin.default, {
    sessionAccount: service('session-account'),
    access: ['ROLE_ARCHIVE_TAB'],
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      params.page = params.page || 1;
      return this.store.findRecord('transportType', params.type);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.setProperties({
        model: model,
        archive: true
      });
    },
    activate: function activate() {
      this.activateCurrentTab('ARCHIVE_TAB');
    }
  });

  _exports.default = _default;
});