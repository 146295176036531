define("apollo/mixins/touch-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/no-new-mixins
  var _default = Ember.Mixin.create({
    touchstartX: 0,
    touchendX: 0,
    numOfClicks: 0,
    isTouchDevice: Ember.computed('', function () {
      // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
      var hasTouchScreen = false;

      if ('maxTouchPoints' in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0;
      } else if ('msMaxTouchPoints' in navigator) {
        hasTouchScreen = navigator.msMaxTouchPoints > 0;
      } else {
        var mQ = window.matchMedia && matchMedia('(pointer:coarse)');

        if (mQ && mQ.media === '(pointer:coarse)') {
          hasTouchScreen = !!mQ.matches;
        } else if ('orientation' in window) {
          hasTouchScreen = true; // deprecated, but good fallback
        } else {
          // Only as a last resort, fall back to user agent sniffing
          var UA = navigator.userAgent;
          hasTouchScreen = /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
        }
      }

      return hasTouchScreen;
    }),
    touchstart: function touchstart(event, callback) {
      this.touchstartX = event.changedTouches[0].screenX;

      if (callback) {
        callback();
      }
    },
    touchend: function touchend(event, callback) {
      this.touchendX = event.changedTouches[0].screenX;

      if (callback) {
        callback();
      }
    },
    handleSwipeX: function handleSwipeX(swipeLeftCallback, swipeRightCallback) {
      if (this.touchendX < this.touchstartX) {
        swipeLeftCallback();
      }

      if (this.touchendX > this.touchstartX) {
        swipeRightCallback();
      }
    },
    handleTap: function handleTap(event, singleClickCallback, doubleClickCallback) {
      var _this = this;

      var TOUCH_TIME_DELAY = 1000;
      this.numOfClicks++;
      var clickedOnce = this.numOfClicks === 1;
      var clickedTwice = this.numOfClicks === 2;

      if (clickedOnce) {
        this.clickTimer = setTimeout(function () {
          _this.numOfClicks = 0;
          singleClickCallback();
        }, TOUCH_TIME_DELAY);
      } else if (clickedTwice) {
        clearTimeout(this.clickTimer);
        this.numOfClicks = 0;
        doubleClickCallback();
      }
    }
  });

  _exports.default = _default;
});