define("apollo/services/language-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    findProperLanguageVersion: function findProperLanguageVersion(localVersion, englishVersion) {
      var companyLanguage = this.get('sessionAccount').getSettingValue("LOCAL_PRINCIPAL_LANGUAGE") || "pl";
      var browserLanguage = this.get('intl.locale')[0];
      var isLocalLanguage = companyLanguage === browserLanguage;
      var isEnglish = [companyLanguage, browserLanguage].some(function (l) {
        return l === 'en';
      });

      if (isLocalLanguage && !isEnglish) {
        return localVersion;
      } else {
        return englishVersion;
      }
    }
  });

  _exports.default = _default;
});