define("apollo/pods/components/schedule-legend/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Component.extend({
    store: service(),
    intl: service(),
    specialWindows: Ember.computed('', function () {
      return [{
        label: this.get('intl').t('common.grayWindow'),
        color: '#444'
      }, {
        label: this.get('intl').t('common.lateWindow'),
        color: 'red'
      }, {
        label: this.get('intl').t('common.dedicatedWindow'),
        color: 'rgba(223,223,203,.7)'
      }, {
        label: this.get('intl').t('common.finishedWindow'),
        color: 'silver'
      }, {
        label: this.get('intl').t('common.transportCombiningIndicator'),
        color: '#34ebb7'
      }];
    }),
    tasks: Ember.computed('', function () {
      return this.get('store').peekAll('task');
    }),
    columns: Ember.computed('legendObjects', function () {
      var legendObjects = this.get('legendObjects');
      var length = legendObjects.get('length');
      var partIndex = length / 2;
      return [legendObjects.slice(0, partIndex), legendObjects.slice(partIndex, length)];
    }),
    legendObjects: Ember.computed('tasks', function () {
      var _this = this;

      var tasks = this.get('tasks');
      var uniqueColors = tasks.getEach('color').uniq();
      var legendObjects = uniqueColors.map(function (color) {
        return _this.mapToLegendObjects(color, tasks);
      });
      this.get('specialWindows').forEach(function (sw) {
        return legendObjects.push(sw);
      });
      return legendObjects.sort(function (a, b) {
        return a.label.length - b.label.length;
      });
    }),
    mapToLegendObjects: function mapToLegendObjects(color, tasks) {
      var _this2 = this;

      var label = tasks.filter(function (task) {
        return task.get('color') === color;
      }).map(function (task) {
        return _this2.findAppropriateLegendString(task);
      }).uniq().join(", ");
      return {
        label: label,
        color: color
      };
    },
    findAppropriateLegendString: function findAppropriateLegendString(task) {
      var taskWithSameNameAndDifferentColorExists = this.get('tasks').filter(function (t) {
        return t.get('stageNameInAppropriateLanguage') === task.get('stageNameInAppropriateLanguage') && t.get('color') !== task.get('color');
      }).get('length') !== 0;
      return taskWithSameNameAndDifferentColorExists ? task.get('legendString') : task.get('stageNameInAppropriateLanguage');
    }
  });

  _exports.default = _default;
});