define("apollo/pods/transaction/modals/choose-carrier/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    carriersList: Ember.computed('model.carriers.@each.{shortName,routeOfferPrice,disabled}', function () {
      return this.get('model.carriers');
    }),
    // eslint-disable-next-line
    sortedCarriersProperties: ["hasRouteOffer:desc", "routeOfferPrice:asc", "disabled:asc", "shortName:asc"],
    sortedCarriers: Ember.computed.sort('carriersList', "sortedCarriersProperties"),
    title: Ember.computed('', function () {
      return this.get('intl').t('transaction.chooseCarrier');
    }),
    hint: Ember.computed('transaction.carrierMustAcceptTransport', function () {
      if (this.get('transaction.carrierMustAcceptTransport')) {
        return this.get('intl').t('transaction.carrierMustAcceptTransportHint.checked');
      } else {
        return this.get('intl').t('transaction.carrierMustAcceptTransportHint.unchecked');
      }
    }),
    transaction: Ember.computed('model', function () {
      return this.get('model.transaction');
    }),
    currencies: Ember.computed('', function () {
      return this.get('store').peekAll('currency');
    }),
    priceUpdateDisabled: Ember.computed('model.disabledEdition', 'transaction.carrier.routeOffer.id', function () {
      return this.get('model.disabledEdition') || !!this.get('transaction.carrier.routeOffer.id');
    }),
    expectedConfirmationDateChanged: function () {
      var self = this;
      Ember.run.once(this, function () {
        var expectedConfirmationTime = self.get('model.expectedConfirmationTime');
        var h = expectedConfirmationTime ? expectedConfirmationTime.split(':')[0] : '0';
        var m = expectedConfirmationTime ? expectedConfirmationTime.split(':')[1] : '0'; // ember-flatpickr wysyła nam listę dat -- dlatego musimy wziąć pierwszy element ([0])

        var expectedConfirmationDate = self.get('model.expectedConfirmationDate')[0] ? self.get('model.expectedConfirmationDate')[0] : self.get('transaction.expectedConfirmationDate');
        var date = moment(expectedConfirmationDate ? expectedConfirmationDate : new Date()).startOf('day').add(h, 'hours').add(m, 'minutes').toDate();

        if (self.get('transaction.expectedConfirmationDate') !== date) {
          self.get('transaction').set('expectedConfirmationDate', date);
        }
      });
    }.observes('model.expectedConfirmationTime', 'model.expectedConfirmationDate'),
    showCarrierMustAcceptTransportCheckbox: Ember.computed('', function () {
      var transportType = this.get('model.transaction.transport.transportType');
      var transactionSetting = this.get('sessionAccount').getTransactionSetting(transportType);
      return transactionSetting ? transactionSetting.get('showCarrierConfirmationCheckbox') : true;
    }),
    choosingReasonList: Ember.computed('model.transaction.transport.activeAuction', function () {
      var _this = this;

      var choosingReasonList;

      if (this.get('model.transaction.transport.activeAuction')) {
        choosingReasonList = this.get('store').peekAll('specialFieldOption').filter(function (sfo) {
          return sfo.get('field') === "AUCTION_CHOOSING_REASON";
        });
      } else {
        choosingReasonList = this.get('store').peekAll('specialFieldOption').filter(function (sfo) {
          return sfo.get('field') === "MANUAL_PASS_CHOOSING_REASON";
        });
      }

      return choosingReasonList.filter(function (sfo) {
        return sfo.get('transportType.id') === _this.get('model.transaction.transport.transportType.id');
      });
    }),
    actions: {
      setTransactionCompany: function setTransactionCompany(company) {
        var routeOffer = company.get('routeOffer');
        var transportType = this.get('transaction.transport.transportType');
        var transactionSetting = this.get('sessionAccount').getTransactionSetting(transportType);
        var defaultCurrencySymbol = transactionSetting ? transactionSetting.get('defaultCurrency') : "PLN";
        var defaultCurrency = this.get('store').peekAll('currency').filterBy('symbol', defaultCurrencySymbol).get('firstObject');
        console.log("Wybieramy przewo\u017Anika ".concat(company.get('shortName'), " z ofert\u0105 ").concat(routeOffer.get('price'), "..."));
        this.get('transaction').setProperties({
          carrier: company,
          routeOffer: routeOffer,
          currency: routeOffer.get('currency') !== undefined ? routeOffer.get('currency') : defaultCurrency,
          price: routeOffer.get('price')
        });
      },
      saveTransaction: function saveTransaction() {
        var self = this;

        if (!this.get('transaction').validate()) {
          return;
        }

        this.get('transaction').save().then(function () {
          (0, _jquery.default)("#main-modal").data("skip-cancel-action", true);
          self.send('hideModal');

          if (!self.get('transaction.carrierMustAcceptTransport')) {
            self.get('transaction.transport').set('carrierCompany', self.get('transaction.carrier'));
          }
        });
      },
      cancel: function cancel() {
        console.debug("Wycofujemy zmiany w okienku przekazywania zlecenia do przewo\u017Anika...");
        this.get('transaction').destroyRecord();
      },
      close: function close() {
        this.send('hideModal');
      }
    }
  });

  _exports.default = _default;
});