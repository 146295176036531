define("apollo/pods/components/paste-area/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    didInsertElement: function didInsertElement() {
      this._super();

      var self = this;
      var BACKSPACE_CODE = 8; // Pozwalamy tylko wklejać i usuwać tekst w polu tekstowym.

      this.$('textarea').on('keydown', function (event) {
        return event.ctrlKey || event.keyCode === BACKSPACE_CODE;
      });
      this.$('textarea').on('paste', function () {
        Ember.run.debounce(self, self.notifyAboutPastedContent, 100);
      });
    },
    notifyAboutPastedContent: function notifyAboutPastedContent() {
      var content = this.$('textarea').val();
      this.get('onPaste')(content);
      this.$('textarea').val('');
    },
    actions: {
      clearContent: function clearContent() {
        this.$('textarea').val('');
      }
    }
  });

  _exports.default = _default;
});