define("apollo/pods/components/sections/document-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    activeDocumentsOfType: Ember.computed('documentType.id', 'modelObject.activeDocuments.length', function () {
      var self = this;
      return this.get('modelObject.activeDocuments').filter(function (doc) {
        return doc.get('documentType.id') === self.get('documentType.id');
      });
    }),
    actions: {
      showModal: function showModal(templateName, model) {
        this.set('action', 'showModal');
        this.sendAction('action', templateName, model);
      }
    }
  });

  _exports.default = _default;
});