define("apollo/mixins/company-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    trimCompany: function trimCompany(company) {
      ["taxIdNumber", "name", "shortName", "partnershipWithPrincipal.contractorNumber", "partnershipWithPrincipal.contractorShortName"].forEach(function (property) {
        if (company.get(property)) {
          company.set(property, company.get(property).trim());

          if (property === "taxIdNumber") {
            company.set(property, company.get(property).replaceAll(' ', ''));
          }
        }
      });
    }
  });

  _exports.default = _default;
});