define("apollo/pods/authorities/aggregate/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        transportTypes: this.store.findAll('transportType').then(function (data) {
          return data.sortBy('idx');
        }),
        authorityGroups: this.store.findAll('authorityGroup')
      });
    }
  });

  _exports.default = _default;
});