define("apollo/pods/queues/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "apollo/mixins/menu-mixin"], function (_exports, _authenticatedRouteMixin, _menuMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _menuMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    poolingService: Ember.inject.service('classification-resource-pooling-service'),
    access: ['ROLE_QUEUES_TAB'],
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        classificationResources: this.store.findAll('classification-resource'),
        squares: this.store.findAll('square'),
        queues: this.store.findAll('queue')
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      this.get('controller').setProperties({
        model: model,
        selectedSquare: model.squares.get('firstObject')
      });
      this.get('poolingService').init(this.pollUpdatesInterval());
    },
    resetController: function resetController() {
      this._super.apply(this, arguments);

      this.get('poolingService').cancel();
    },
    activate: function activate() {
      this.activateCurrentTab('QUEUES_TAB');
    },
    pollUpdatesInterval: function pollUpdatesInterval() {
      var pollIntervalString = this.get('sessionAccount').getSettingValue('QUEUES_TAB_POLL_INTERVAL');
      return pollIntervalString ? parseInt(pollIntervalString) : 10000;
    }
  });

  _exports.default = _default;
});