define("apollo/pods/user-groups/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax-service'),
    userGroupsIndexController: Ember.inject.controller("user-groups/index"),
    errors: [],
    userGroup: Ember.computed('model.userGroup', function () {
      return this.get('model.userGroup');
    }),
    canDeleteUserGroup: Ember.computed('userGroup.{id,mainGroup}', function () {
      return this.get('userGroup.id') && !this.get('userGroup.mainGroup');
    }),
    handleSuccess: function handleSuccess() {
      var successMsg = this.get('intl').t("common.saveSuccessMessage");
      this.get("userGroupsIndexController").set("successMessage", successMsg);
      this.transitionToRoute("user-groups.index", this.get('userGroup.company.id'));
    },
    handleFailure: function handleFailure(response) {
      this.set('errors', response.errors);
    },
    actions: {
      save: function save() {
        var _this = this;

        this.get('userGroup').save().then(function () {
          return _this.handleSuccess();
        }).catch(function (response) {
          return _this.handleFailure(response);
        });
      },
      delete: function _delete() {
        var _this2 = this;

        this.get('userGroup').destroyRecord().then(function () {
          return _this2.handleSuccess();
        }).catch(function (response) {
          return _this2.handleFailure(response);
        });
      },
      cancel: function cancel() {
        this.transitionToRoute("user-groups.index", this.get('userGroup.company.id'));
      }
    }
  });

  _exports.default = _default;
});