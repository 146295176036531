define("apollo/pods/transport-log/model", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    AUTHORITY_RESTRICTION_CLASSES: ['ActionAuthority', 'ActiveAuctionRestriction', 'UnlimitedRestriction', 'TimeBasedRestriction', 'OwnCompanyRestriction', 'BlockedRestriction', 'AuthorityRestriction', 'AfterCheckpointBasedRestriction', 'BeforeCheckpointBasedRestriction', 'ActiveRestriction', 'AdviceDatesRestriction', 'NoRouteOfferRestriction'],
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    languageService: Ember.inject.service('language-service'),
    store: Ember.inject.service('store'),
    SPECIAL_VALUE_FUNCTION: {
      orderInTransport: function orderInTransport(i) {
        return Number(i) + 1;
      }
    },
    transport: _emberData.default.belongsTo('transport', {
      async: true
    }),
    persistedObjectId: _emberData.default.attr('string'),
    persistedObjectVersion: _emberData.default.attr('number'),
    newValue: _emberData.default.attr('string'),
    oldValue: _emberData.default.attr('string'),
    dateCreated: _emberData.default.attr('date'),
    propertyName: _emberData.default.attr('string'),
    actor: _emberData.default.attr('string'),
    className: _emberData.default.attr('string'),
    eventName: _emberData.default.attr('string'),
    propertyNameType: _emberData.default.attr('string'),
    transportTypeId: _emberData.default.attr('string'),
    transportIdentifier: _emberData.default.attr('string'),
    transportId: _emberData.default.attr('string'),
    customFieldDefinitionId: _emberData.default.attr('number'),
    newValueFormat: function () {
      return this.findValueFormat('newValue');
    }.property('propertyNameType', 'newValue'),
    oldValueFormat: function () {
      return this.findValueFormat('oldValue');
    }.property('propertyNameType', 'oldValue'),
    findValueFormat: function findValueFormat(valueIdentifier) {
      if (this.get('propertyName') === 'lastLoginDate' || this.get('oldValue') === this.get('newValue')) {
        return '';
      } else if (this.get('className') === 'PersonNotification') {
        return this.findPersonNotificationFormat(valueIdentifier);
      } else if (this.get('className') === 'PersonRamp') {
        return this.findPersonRampFormat(valueIdentifier);
      } else if (this.get('className') === 'CompanyRamp') {
        return this.findCompanyRampFormat(valueIdentifier);
      } else if (this.get('className') === 'RoleGroupAuthority') {
        return this.findRoleGroupAuthorityFormat(valueIdentifier);
      } else if (this.AUTHORITY_RESTRICTION_CLASSES.includes(this.get('className'))) {
        return this.findAuthorityRestrictionFormat(valueIdentifier);
      } else if (this.get('eventName') === 'STATUS_CHANGED') {
        return this.get('intl').t("company.partnershipStatus.".concat(this.get(valueIdentifier)));
      } else if (this.get('propertyNameType') == 'java.util.Date') {
        var date = this.get(valueIdentifier) ? this.get(valueIdentifier).replace(/CET|CEST/, '') : '';
        return date ? moment(date).format('DD.MM.YYYY HH:mm') : 'X';
      } else if (this.get('propertyNameType') == 'java.lang.Boolean') {
        return this.get(valueIdentifier) === 'true' ? '✓' : 'x';
      } else if (this.SPECIAL_VALUE_FUNCTION[this.get('propertyName')]) {
        var specialFunction = this.SPECIAL_VALUE_FUNCTION[this.get('propertyName')];
        return specialFunction(this.get(valueIdentifier));
      } else if (this.get('propertyName') === 'termsOfConditionsAccepted') {
        return this.get(valueIdentifier) === 'true' ? '✓' : this.get(valueIdentifier) !== null ? 'x' : '--';
      } else {
        return Ember.isEmpty(this.get(valueIdentifier)) ? '-' : this.get(valueIdentifier);
      }
    },
    findPersonRampFormat: function findPersonRampFormat(valueIdentifier) {
      var stringValue = this.get(valueIdentifier);

      if (!stringValue) {
        return '';
      }

      if (stringValue === 'false') {
        return '';
      }

      var identifier = this.get('persistedObjectId');
      var username = identifier.split(':')[0];
      var rampId = identifier.split(':')[1];
      var rampLabel = this.get('store').peekRecord('ramp', rampId).get('name');
      var warehouseLabel = this.get('store').peekRecord('ramp', rampId).get('warehouse.name');
      return "".concat(username, " - ").concat(rampLabel, " [").concat(warehouseLabel, "]");
    },
    findCompanyRampFormat: function findCompanyRampFormat(valueIdentifier) {
      var stringValue = this.get(valueIdentifier);

      if (!stringValue) {
        return '';
      }

      if (stringValue === 'false') {
        return '';
      }

      var identifier = this.get('persistedObjectId');
      var company = identifier.split(':')[0];
      var rampId = identifier.split(':')[1];
      var rampLabel = this.get('store').peekRecord('ramp', rampId).get('name');
      var warehouseLabel = this.get('store').peekRecord('ramp', rampId).get('warehouse.name');
      return "".concat(company, " - ").concat(rampLabel, " [").concat(warehouseLabel, "]");
    },
    findRoleGroupAuthorityFormat: function findRoleGroupAuthorityFormat(valueIdentifier) {
      var stringValue = this.get(valueIdentifier);

      if (!stringValue) {
        return '';
      }

      if (stringValue === 'false') {
        return '';
      }

      var identifiers = this.get('persistedObjectId').split(':');
      var roleGroupName = this.get('languageService').findProperLanguageVersion(identifiers[0], identifiers[1]);
      var authority = this.get('intl').t('settings.roleGroups.authorityLabels.' + identifiers[2]);
      return "".concat(roleGroupName, " - ").concat(authority);
    },
    findAuthorityRestrictionFormat: function findAuthorityRestrictionFormat(valueIdentifier) {
      var stringValue = this.get(valueIdentifier);

      if (!stringValue) {
        return '';
      }

      if (stringValue === 'false') {
        return '';
      }

      var identifiers = this.get('persistedObjectId').split(':');
      var roleGroupLabel = this.get('languageService').findProperLanguageVersion(identifiers[3], identifiers[2]);
      var actionTypeLabel = this.get('intl').t('settings.roleGroups.authorityLabels.' + identifiers[4]);
      var authorityRangeLabel = this.get('intl').t('settings.roleGroups.authorityRangeLabels.' + identifiers[5]);
      var propertyNameLabelMap = {
        'assetId': identifiers[1],
        'roleGroup': roleGroupLabel,
        'actionType': actionTypeLabel,
        'authorityRange': authorityRangeLabel
      };
      return propertyNameLabelMap[this.get('propertyName')];
    },
    findPersonNotificationFormat: function findPersonNotificationFormat(valueIdentifier) {
      var stringValue = this.get(valueIdentifier);

      if (!stringValue) {
        return '';
      }

      var identifier = this.get('persistedObjectId');
      var username = identifier.split(':')[0];
      var notificationId = identifier.split(':')[1];
      var notificationLabel = this.get('store').peekRecord('notification', notificationId).get('label');
      return "".concat(username, ": ").concat(notificationLabel);
    },
    eventLabel: Ember.computed('className', 'eventName', function () {
      var additionalText = '';

      if (this.AUTHORITY_RESTRICTION_CLASSES.includes(this.get('className'))) {
        var additionalInfo = this.get('persistedObjectId').split(':');
        var assetType = additionalInfo[0] === 'CUSTOM_FIELD' ? 'customFieldDefinition' : 'transportType';
        var assetId = additionalInfo[1];

        if (!assetId) {
          return '';
        }

        var assetName = this.get('store').peekRecord(assetType, assetId).get('nameInAppropriateLanguage');
        var authorityName = this.get('intl').t("settings.roleGroups.authorityLabels.".concat(additionalInfo[4]));
        var authorityRange = this.get('intl').t("settings.roleGroups.authorityRangeLabels.".concat(additionalInfo[5]));
        additionalText += " - ".concat(authorityName, ", ").concat(authorityRange, " (").concat(assetName, ")");
      }

      if (this.get('className') === 'Partnership') {
        return this.get('intl').t("partnership.logs.".concat(this.get('eventName'))) + additionalText;
      } else if (this.get('className') === 'EmailAdvice') {
        var identifier = this.get('persistedObjectId');
        var adviceType = this.get('store').peekRecord('emailAdvice', identifier).get('type');
        return this.get('intl').t('emailAdvice.logLabel.' + adviceType);
      } else {
        return this.get('intl').t("logs.".concat(this.get('eventName'), ".").concat(this.get('className'))) + additionalText;
      }
    })
  });

  _exports.default = _default;
});