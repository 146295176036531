define("apollo/pods/routes/modals/route-form/template/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    tagName: '',
    routeLabel: Ember.computed('', function () {
      return this.get('route.id') ? "".concat(this.get('intl').t('route.label'), ":").concat(this.get('route.id')) : this.get('intl').t('route.newRoute');
    }),
    actions: {
      saveRoute: function saveRoute() {
        this.saveRoute();
      },
      editRoute: function editRoute() {
        this.editRoute();
      },
      close: function close() {
        this.close();
      },
      cancel: function cancel() {
        this.cancel();
      }
    }
  });

  _exports.default = _default;
});