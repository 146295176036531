define("apollo/pods/companies/modals/add-partnership/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    errors: [],
    selectedContractor: null,
    partnership: Ember.computed('model.partnership', function () {
      return this.get('model.partnership');
    }),
    company: Ember.computed('model.company', function () {
      return this.get('model.company');
    }),
    newContractorType: Ember.computed('model.newContractorType', function () {
      return this.get('model.newContractorType');
    }),
    contractorPropertyName: Ember.computed('company.partnershipType.name', function () {
      var currentCompanyPartnershipName = this.get('company.partnershipType.name');

      if (currentCompanyPartnershipName === 'CARRIER') {
        return 'principalCompany';
      } else {
        return 'contractorCompany';
      }
    }),
    actions: {
      setContractor: function setContractor(item) {
        var _this = this;

        this.get('store').findRecord('company', item.id).then(function (contractor) {
          _this.set('selectedContractor', contractor);

          _this.set("partnership.".concat(_this.get('contractorPropertyName')), contractor);
        });
      },
      removeContractor: function removeContractor() {
        this.set('selectedContractor', null);
        this.set("partnership.".concat(this.get('contractorPropertyName')), null);
      },
      save: function save() {
        var _this2 = this;

        var partnership = this.get('partnership');

        if (!partnership.validate()) {
          return;
        }

        partnership.save().then(function () {
          _this2.send('hideModal');

          window.location.reload();
        }).catch(function (response) {
          _this2.set('errors', response.errors);
        });
      },
      cancel: function cancel() {
        this.set('selectedContractor', null);
        this.get('partnership').unloadRecord();
        this.set('errors', []);
      }
    }
  });

  _exports.default = _default;
});