define("apollo/pods/price-list/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "apollo/mixins/menu-mixin"], function (_exports, _authenticatedRouteMixin, _menuMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _menuMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    access: Ember.A(['ROLE_PRICE_LIST_TAB']),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    queryParams: {
      page: {
        refreshModel: true
      },
      max: {
        refreshModel: true
      }
    },
    model: function model(params) {
      params.max = localStorage.getItem('MAX_ROWS_IN_PRICE_LIST_TABLE') || 10;
      params.page = params.page || 1;
      var transportTypeMode = params.type;
      console.log("Wczytujemy zak\u0142adk\u0119 Cennik\xF3w dla typu ".concat(transportTypeMode, "..."));
      var routes = transportTypeMode === 'UNAVAILABLE' ? [] : this.store.query('route', {
        transportTypeMode: transportTypeMode,
        max: params.max,
        page: params.page
      });
      var routeAddresses = this.store.findAll('route-address');
      return Ember.RSVP.hash({
        availableTransportTypes: this.get('store').peekAll('transportType').filter(function (transportType) {
          return transportType.canBeSeenByRoleGroup && transportType.allowsPriceList;
        }),
        transportTypeMode: transportTypeMode,
        routes: routes,
        routeAddresses: routeAddresses
      });
    },
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }

      var userTransportTypes = this.get('sessionAccount.currentUser.transportTypes');
      var enteredTypeName = transition.params['price-list.index'].type;

      if (enteredTypeName !== 'UNAVAILABLE' && !userTransportTypes.getEach('alias').includes(enteredTypeName)) {
        console.error("User entered ".concat(enteredTypeName, " in params, but doesn't have access to it (or the type doesn't exist)."));
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    setupController: function setupController(controller, model) {
      // eslint-disable-next-line ember/no-controller-access-in-routes
      this.get('controller').setProperties({
        model: model,
        totalPages: model.routes.get('meta.totalPages')
      });
    },
    activate: function activate() {
      this.activateCurrentTab('PRICE_LIST_TAB');
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (transition.targetName === 'price-list.index') {
          return;
        }

        console.log("transition.targetName: ".concat(transition.targetName)); // eslint-disable-next-line ember/no-controller-access-in-routes

        this.get('controller').setProperties({
          page: 1,
          totalPages: 0
        });
      },
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});