define("apollo/pods/custom-field-definitions/modals/decimal-format-hint/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    title: Ember.computed('', function () {
      return this.get('intl').t("custom-field-definition.numberDecimalFormat.label");
    }),
    actions: {
      close: function close() {
        this.send('hideModal');
      }
    }
  });

  _exports.default = _default;
});