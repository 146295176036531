define("apollo/pods/authorities/aggregate/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    superUser: Ember.computed(function () {
      var principalCompanyId = localStorage.getItem('principalId');
      var roleGroups = this.store.peekAll('roleGroup').filterBy('principalCompany.id', principalCompanyId);
      return roleGroups.filterBy('name', 'SUPER_USER');
    }),
    actions: {
      addPermission: function addPermission() {
        var self = this;
        self.transitionToRoute('authorities.create', {
          queryParams: {
            multiSelect: true
          }
        });
      }
    }
  });

  _exports.default = _default;
});