define("apollo/pods/transaction/modals/choose-courier/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    activeServiceList: [],
    courierList: Ember.computed('model.couriers', function () {
      return this.get('model.couriers');
    }),
    serviceList: Ember.computed('model.services', 'transaction.carrier', function () {
      var _this = this;

      var list = this.get('model.services');
      var names = [];
      list.forEach(function (it) {
        if (it.get('courierPricing.courierCompany.name') === _this.get('transaction.carrier.name')) {
          var price = it.get('courierPricing.courierCompany.name') === 'DHL Express' && it.price ? it.price : it.priceSumNetto != null ? it.priceSumNetto : it.price;
          names.push(price + ' ' + it.priceCurrency.get('symbol') + ' | ' + it.serviceName);
        }
      });
      return names;
    }),
    title: Ember.computed('', function () {
      return this.get('intl').t('transaction.chooseCourier');
    }),
    transaction: Ember.computed('model', function () {
      return this.get('model.transaction');
    }),
    contactDetailsPresent: Ember.computed('model.transaction.transport.steps', function () {
      var steps = this.get('model.transaction.transport.steps');
      var present = true;
      steps.forEach(function (step) {
        if (!step.contactPerson || !step.contactPersonMobilePhoneNumber || !step.contactPersonMobilePhonePrefix || !step.contactPersonEmail) {
          present = false;
        }
      });
      return present;
    }),
    dhlInvoiceDetailsPresent: Ember.computed('transaction.carrier.name', 'model.transaction.transport.courierInvoice', 'model.transaction.transport.needExportDeclaration', 'model.transaction.transport.needPLT', function () {
      var present = true;

      if (!(this.get('transaction.carrier.name') === 'DHL Express')) {
        return present;
      }

      var invoice = this.get('model.transaction.transport.courierInvoice');
      var needExportDeclaration = this.get('model.transaction.transport.needExportDeclaration');
      var needPLT = this.get('model.transaction.transport.needPLT');

      if (needPLT) {
        if (invoice.get('base64') === null || invoice.get('base64') === "" || invoice.get('base64') === undefined) {
          present = false;
        }
      }

      if (needExportDeclaration) {
        if (invoice.get('number') === null || invoice.get('number') === "" || invoice.get('number') === undefined || invoice.get('date') === null || invoice.get('date') === "" || invoice.get('date') === undefined) {
          present = false;
        }
      }

      return present;
    }),
    selectedService: Ember.computed('transaction.typeOfService', function () {
      return this.get('transaction.typeOfService');
    }),
    expectedConfirmationDate: function expectedConfirmationDate() {
      var expectedConfirmationDateHour = this.get('model.courierHourMax') ? this.get('model.courierHourMax').split(':')[0] : '0';
      var expectedConfirmationDateMinutes = this.get('model.courierHourMax') ? this.get('model.courierHourMax').split(':')[1] : '0';
      return moment(this.get('model.courierDate')).startOf('day').add(expectedConfirmationDateHour, 'hours').add(expectedConfirmationDateMinutes, 'minutes').toDate();
    },
    isInPast: Ember.computed('model', function () {
      var currentDate = this.expectedConfirmationDate();
      return currentDate < new Date();
    }),
    actions: {
      saveTransaction: function saveTransaction() {
        var _this2 = this;

        var self = this;
        var currentDate = new Date();
        var chosenCarrier = this.get('courierList').find(function (carrier) {
          return carrier.get('name') === _this2.get('transaction.carrier.name');
        });
        var chosenService = this.get('model.services').find(function (service) {
          var longTos = _this2.get('transaction.typeOfService').toString();

          var tos = longTos.substring(longTos.indexOf("|") + 2);
          return service.get('courierPricing.courierCompany.name') === chosenCarrier.get('name') && service.get('serviceName') === tos;
        });
        var expectedConfirmationDate = this.expectedConfirmationDate();
        this.set('transaction.typeOfService', chosenService.get('serviceName'));
        var price = chosenService.get("priceSumNetto") != null ? chosenService.get("priceSumNetto") : chosenService.get("price");
        this.set('transaction.price', price);
        this.set('transaction.expectedConfirmationDate', expectedConfirmationDate);
        this.set('transaction.currency', chosenService.get('priceCurrency'));
        this.set('transaction.carrierMustAcceptTransport', false);

        if (!this.get('transaction').validate()) {
          console.debug(this.get('transaction.errors'));
          return;
        }

        this.get('transaction').save().then(function () {
          (0, _jquery.default)("#main-modal").data("skip-cancel-action", true);
          self.send('hideModal');
          self.get('transaction.transport').set('carrierCompany', self.get('transaction.carrier'));
          console.debug('Poprawnie zapisano transakcje');
        }).catch(function (response) {
          console.debug(response.errors);
        });
      },
      cancel: function cancel() {
        console.debug("Wycofujemy zmiany w okienku przekazywania zlecenia do kuriera...");
        this.get('transaction').destroyRecord();
      },
      close: function close() {
        this.send('hideModal');
      },
      selectCourierAndClearService: function selectCourierAndClearService(courier) {
        this.set('transaction.carrier', courier);
        this.set('transaction.typeOfService', null);
      }
    }
  });

  _exports.default = _default;
});