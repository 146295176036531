define("apollo/services/scanner-input-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    LETTER_INPUT_TIMEOUT_MS: 200,
    ENTER_KEY_CODE: 13,
    BARCODE_LENGTH: 8,
    verifyChecksum: function verifyChecksum(value) {
      if (!value || value.length < this.BARCODE_LENGTH) {
        return false;
      }

      var checksumNumber = Number(value[7]);
      var evens = Number(value[1]) + Number(value[3]) + Number(value[5]);
      var odds = (Number(value[0]) + Number(value[2]) + Number(value[4]) + Number(value[6])) * 3;
      var mod = (evens + odds) % 10;
      var final = 10 - Number(mod);

      if (final === 10) {
        final = 0;
      }

      return Number(final) === Number(checksumNumber);
    }
  });

  _exports.default = _default;
});