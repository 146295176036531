define("apollo/pods/tasks/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    errors: [],
    ASSOCIATIONS: ['WAREHOUSE', 'CONTRACTOR', 'WAREHOUSE_AND_CONTRACTOR'],
    movingDelayedTransportToArchiveActive: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('DELAY_TIME_TO_MOVE_ORDER_TO_ARCHIVE_AFTER_TASK_TIMEOUT');
    }),
    automaticGenerateDocumentActive: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('AUTOMATIC_GENERATE_DOCUMENT');
    }),
    customFieldDefinitions: Ember.computed('model.workflow.transportType', function () {
      return this.get('model.workflow.transportType').get('customFieldDefinitions');
    }),
    selectedCustomFieldDefinition: Ember.computed('model.customFieldOption', function () {
      return this.get('model.customFieldOption.definition');
    }),
    customFieldOptions: Ember.computed('customFieldDefinitions', 'selectedCustomFieldDefinition', function () {
      return this.get('selectedCustomFieldDefinition').get('options');
    }),
    actions: {
      save: function save() {
        var self = this;
        var taskModel = this.get('model');

        if (!taskModel.validate()) {
          return;
        }

        taskModel.save().then(function (task) {
          self.set('errors', []);
          self.transitionToRoute('workflows.edit', self.get('model.workflow.id'));
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('workflows.edit', this.get('model.workflow.id'));
      },
      disable: function disable() {
        var self = this;
        var taskModel = this.get('model');
        taskModel.set('enabled', false);
        taskModel.save().then(function () {
          self.set('errors', []);
          self.transitionToRoute('workflows.edit', self.get('model.workflow.id'));
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      }
    }
  });

  _exports.default = _default;
});