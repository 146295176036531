define("apollo/pods/components/log-api-request/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    formattedLog: Ember.computed('log', function () {
      var log = this.get('log.request');
      return JSON.stringify(JSON.parse(log), undefined, 2);
    })
  });

  _exports.default = _default;
});