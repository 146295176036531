define("apollo/pods/section/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend(_modelValidator.default, {
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    changeTracker: {
      auto: true,
      enableIsDirty: true
    },
    STATES: {
      PENDING: 'PENDING',
      ACCEPTED: 'ACCEPTED',
      REJECTED: 'REJECTED',
      UPDATED: 'UPDATED',
      EXPIRED: 'EXPIRED',
      DELETED: 'DELETED',
      ARCHIVED: 'ARCHIVED',
      DOCUMENT_ADDED: 'DOCUMENT_ADDED'
    },
    actions: _emberData.default.hasMany('section-action', {
      async: true
    }),
    partnership: _emberData.default.belongsTo('partnership', {
      async: true
    }),
    truck: _emberData.default.belongsTo('truck', {
      async: true
    }),
    name: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    reaction: _emberData.default.attr('string'),
    deleted: _emberData.default.attr('boolean'),
    dateCreated: _emberData.default.attr('isodate'),
    lastUpdated: _emberData.default.attr('isodate'),
    modelObject: Ember.computed('partnership.id', 'truck.id', function () {
      return this.get('partnership.id') ? this.get('partnership') : this.get('truck');
    }),
    manageSectionRole: Ember.computed(function () {
      if (this.get('partnership.id')) {
        return 'ROLE_MANAGE_APPLY_SECTIONS';
      } else {
        return 'ROLE_MANAGE_MOT_SECTIONS';
      }
    }),
    deleteFileFromSectionRole: Ember.computed(function () {
      if (this.get('partnership.id')) {
        return 'CAN_DELETE_APPLIES_FILES';
      } else {
        return 'CAN_DELETE_MOT_FILES';
      }
    }),
    isOwner: Ember.computed('partnership.contractorCompany.id', 'truck.owner.id', function () {
      if (this.get('partnership.id')) {
        return this.get('partnership.contractorCompany.id') === this.get('sessionAccount.currentUser.company.id');
      } else {
        return this.get('truck.owner.id') === this.get('sessionAccount.currentUser.company.id');
      }
    }),
    isAccepted: Ember.computed('state', function () {
      return this.get('state') === this.get('STATES').ACCEPTED;
    }),
    isActive: Ember.computed('state', function () {
      return ![this.get('STATES').ARCHIVED, this.get('STATES').DELETED].includes(this.get('state'));
    }),
    isRejected: Ember.computed('state', function () {
      return [this.get('STATES').REJECTED].includes(this.get('state'));
    }),
    hasBeenUpdated: Ember.computed('partnership.registrationCompleted', 'truck.verificationCompleted', 'state', function () {
      var properState = [this.get('STATES').PENDING, this.get('STATES').UPDATED, this.get('STATES').DOCUMENT_ADDED].includes(this.get('state'));

      if (this.get('partnership.id')) {
        return this.get('partnership.registrationCompleted') && this.get('partnership.status') !== this.get('partnership.STATUS.REGISTRATION_IS_IN_PROGRESS') && properState;
      }

      return this.get('truck.verificationCompleted') && properState;
    }),
    canBeConfirmed: Ember.computed('state', 'manageSectionRole', 'modelObject', function () {
      var roleToConfirm = this.get('manageSectionRole');
      var hasRoleToConfirm = this.get('sessionAccount').hasRole(roleToConfirm);
      var sectionCanBeConfirmed = [this.get('STATES').PENDING, this.get('STATES').UPDATED, this.get('STATES').DOCUMENT_ADDED, this.get('STATES').REJECTED].includes(this.get('state'));
      var modelObjectCanBeConfirmed = [this.get('modelObject.STATUS.ACCEPTED'), this.get('modelObject.STATUS.REJECTED'), this.get('modelObject.STATUS.UPDATED'), this.get('modelObject.STATUS.AWAITING_VERIFICATION'), this.get('modelObject.STATUS.BLOCKED')].includes(this.get('modelObject.status'));
      return sectionCanBeConfirmed && modelObjectCanBeConfirmed && hasRoleToConfirm;
    }),
    canBeRejected: Ember.computed('state', 'name', 'manageSectionRole', 'modelObject', function () {
      var roleToReject = this.get('manageSectionRole');
      var hasRoleToReject = this.get('sessionAccount').hasRole(roleToReject);
      var canBeRejected = this.get('documentType.canBeRejected') || this.get('name') === 'ANTI_CORRUPTION_TYPE';
      var sectionCanBeRejected = [this.get('STATES').PENDING, this.get('STATES').UPDATED, this.get('STATES').DOCUMENT_ADDED, this.get('STATES').ACCEPTED].includes(this.get('state'));
      var modelObjectCanBeRejected = [this.get('modelObject.STATUS.ACCEPTED'), this.get('modelObject.STATUS.REJECTED'), this.get('modelObject.STATUS.UPDATED'), this.get('modelObject.STATUS.AWAITING_VERIFICATION'), this.get('modelObject.STATUS.BLOCKED')].includes(this.get('modelObject.status'));
      return sectionCanBeRejected && modelObjectCanBeRejected && hasRoleToReject && canBeRejected;
    }),
    canBeUpdated: Ember.computed('state', 'isOwner', 'manageSectionRole', function () {
      var roleToUpdate = this.get('manageSectionRole');
      var hasRoleToUpdate = this.get('sessionAccount').hasRole(roleToUpdate);
      var sectionCanBeUpdated = this.get('name') === 'MAIN_DATA_TYPE' || [this.get('STATES').PENDING, this.get('STATES').UPDATED, this.get('STATES').DOCUMENT_ADDED, this.get('STATES').REJECTED].includes(this.get('state'));
      return sectionCanBeUpdated && (hasRoleToUpdate || this.get('isOwner'));
    }),
    canBeDisabled: Ember.computed('state', 'manageSectionRole', function () {
      var roleToDisable = this.get('manageSectionRole');
      return this.get('sessionAccount').hasRole(roleToDisable);
    }),
    activeAction: Ember.computed('actions.@each.active', function () {
      return this.get('actions').filterBy('active').get('firstObject');
    }),
    shouldBeExpanded: Ember.computed('state', function () {
      return this.get('state') !== this.get('STATES').ACCEPTED;
    }),
    hasChangesApartFromActions: function hasChangesApartFromActions() {
      var changes = this.modelChanges();
      return Object.keys(changes).filter(function (k) {
        return k !== 'actions';
      }).length > 0;
    },
    translatedName: Ember.computed('name', function () {
      return this.get('intl').t("sectionType.".concat(this.get('name')));
    })
  });

  _exports.default = _default;
});