define("apollo/pods/assortment-groups/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Controller.extend({
    sessionAccount: service('session-account'),
    intl: service(),
    assortmentGroup: Ember.computed('model', function () {
      return this.get('model');
    }),
    transportTypes: Ember.computed('assortmentGroup', 'sessionAccount.currentUser.transportTypes', function () {
      return this.get('sessionAccount.currentUser.transportTypes');
    }),
    // http://www.poeticsystems.com/blog/ember-checkboxes-and-you
    proxiedTransportTypes: Ember.computed('assortmentGroup.transportTypes', 'transportTypes', function () {
      var self = this;
      return this.get('transportTypes').map(function (tt) {
        return Ember.ObjectProxy.create({
          content: tt,
          checked: self.get('assortmentGroup.transportTypeIds').includes(tt.get('id'))
        });
      });
    }),
    actions: {
      save: function save() {
        var self = this;
        this.get('assortmentGroup').set('adviceFromTime', "".concat(this.get('assortmentGroup.adviceFromHours'), ":").concat(this.get('assortmentGroup.adviceFromMinutes')));
        this.get('assortmentGroup').set('adviceToTime', "".concat(this.get('assortmentGroup.adviceToHours'), ":").concat(this.get('assortmentGroup.adviceToMinutes')));
        var checkedTransportTypes = this.get('proxiedTransportTypes').filterBy('checked', true);
        var checkedTransportTypesIds = checkedTransportTypes.map(function (tt) {
          return tt.get('content.id');
        });
        this.get('assortmentGroup').set('checkedTransportTypesIds', checkedTransportTypesIds);

        if (!this.get('assortmentGroup').validate()) {
          return;
        }

        var confirmText = self.get('intl').t('assortmentGroup.confirmEdition');

        if (self.showConfirmEdition() && !confirm(confirmText)) {
          return;
        }

        this.get('assortmentGroup').save().then(function () {
          self.setProperties({
            errors: []
          });
          self.transitionToRoute('warehouses.edit', self.get('assortmentGroup.warehouse.id'));
        }).catch(function (response) {
          console.log("B\u0142\u0105d przy zapisywaniu grupy asortymentowej: ".concat(response.errors));
          self.set('errors', response.errors);
        });
      },
      delete: function _delete() {
        var self = this;
        self.get('assortmentGroup').destroyRecord().then(function () {
          self.setProperties({
            errors: []
          });
          self.transitionToRoute('warehouses.edit', self.get('assortmentGroup.warehouse.id'));
        }).catch(function (response) {
          console.log("B\u0142\u0105d przy usuwaniu grupy asortymentowej: ".concat(response.errors));
          self.set('errors', response.errors);
        });
      },
      cancel: function cancel() {
        var warehouseId = this.get('assortmentGroup.warehouse.id');
        this.get('assortmentGroup').unloadRecord();
        this.transitionToRoute('warehouses.edit', warehouseId);
      }
    },
    showConfirmEdition: function showConfirmEdition() {
      return this.get('assortmentGroup.id') && (this.get('assortmentGroup').changedAttributes().adviceFromTime || this.get('assortmentGroup').changedAttributes().adviceToTime);
    }
  });

  _exports.default = _default;
});