define("apollo/pods/components/warehouse/warehouse-location-multi-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Kj4gVols",
    "block": "{\"symbols\":[\"swl\"],\"statements\":[[4,\"power-select-multiple\",null,[[\"id\",\"dropdownClass\",\"placeholder\",\"selected\",\"options\",\"disabled\",\"searchEnabled\",\"renderInPlace\",\"onchange\"],[\"warehouse-location\",[27,\"if\",[[23,[\"warehouseLocationDropdownHidden\"]],\"hidden\"],null],[27,\"t\",[\"warehouse.warehouseLocationLabel\"],null],[23,[\"warehouseLocationsNames\"]],[23,[\"step\",\"stepWarehouseLocationsLabels\"]],[23,[\"disabled\"]],false,true,[27,\"action\",[[22,0,[]],\"setWarehouseLocations\"],null]]],{\"statements\":[[0,\"    \"],[1,[22,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[1,[27,\"render-field-errors\",null,[[\"model\",\"fieldName\",\"className\"],[[23,[\"step\"]],\"stepWarehouseLocations\",\"has-error\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/warehouse/warehouse-location-multi-select/template.hbs"
    }
  });

  _exports.default = _default;
});