define("apollo/pods/components/log-change/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    tagName: 'span',
    PROPERTIES_WITH_CLASS_INFORMATION: ['dateCreated'],
    PROPERTIES_WITH_ADDITIONAL_PROPERTIES: ['orderInTransport'],
    fieldErrors: Ember.computed('model.errors.[]', function () {
      var fieldName = this.get('fieldName');
      return this.get('model.errors.' + fieldName);
    }),
    className: Ember.computed('log.className', function () {
      var modelName = this.get('log.className');
      return modelName ? modelName.charAt(0).toLowerCase() + modelName.substr(1) : '';
    }),
    classTranslationName: Ember.computed('className', function () {
      if (this.get('className') === 'personNotification' || this.get('className') === 'roleGroupAuthority' || !this.PROPERTIES_WITH_CLASS_INFORMATION.includes(this.get('log.propertyName'))) {
        return '';
      }

      if (this.get('className') === 'auction') {
        return "(".concat(this.getAuctionSettingTranslation('label'), ")");
      } else if (this.get('className') === 'index') {
        return "(".concat(this.getIndexSettingTranslation('label'), ")");
      }

      var key = "".concat(this.get('className'), ".label");
      return "(".concat(this.get('intl').t(key), ")");
    }),
    additionalDetails: Ember.computed('log.propertyName', function () {
      if (!this.PROPERTIES_WITH_ADDITIONAL_PROPERTIES.includes(this.get('log.propertyName'))) {
        return '';
      }

      var object = this.get('store').peekRecord(this.get('className'), this.get('log.persistedObjectId'));
      return object ? "[".concat(object.get('stringLabel'), "]") : '';
    }),
    label: Ember.computed('className', 'log.propertyName', 'fallbackLabel', function () {
      if (this.get('className') === 'auction') {
        return this.getAuctionSettingTranslation(this.get('log.propertyName'));
      } else if (this.get('className') === 'index') {
        return this.getIndexSettingTranslation(this.get('log.propertyName'));
      } else if (this.get('className') === 'indexQuantity') {
        return this.get('store').peekRecord('index-quantity', this.get('log.persistedObjectId')).get('indexQuantityType.name');
      } else if (this.get('className') === 'personNotification') {
        return '';
      } else if (this.get('className') === 'personRamp') {
        return '';
      } else if (this.get('className') === 'companyRamp') {
        return '';
      } else if (this.get('className') === 'roleGroupAuthority') {
        return '';
      } else if (this.get('className') === 'file') {
        return '';
      }

      var classKey = this.get('className') === 'partnership' ? 'partnership' : 'transport';
      var key = "".concat(this.get('className'), ".").concat(this.get('log.propertyName'));
      var fallbackTransportKey = "".concat(classKey, ".").concat(this.get('log.propertyName'));
      return this.get('intl').t(key, {
        default: fallbackTransportKey
      });
    }),
    getAuctionSettingTranslation: function getAuctionSettingTranslation(propertyName) {
      var auctionSetting = this.get('sessionAccount').getAuctionSetting(this.get('log.transportTypeId'));
      var translationKey = auctionSetting.get('translationKey');
      var fallbackAuctionKey = "auction.".concat(propertyName);
      var fallbackTransportKey = "transport.".concat(propertyName);
      var key = "".concat(this.get('className'), ".").concat(translationKey, ".").concat(propertyName);
      return this.get('intl').t(key, {
        default: [fallbackAuctionKey, fallbackTransportKey]
      });
    },
    getIndexSettingTranslation: function getIndexSettingTranslation(propertyName) {
      var indexSetting = this.get('sessionAccount').getIndexSetting(this.get('log.transportTypeId'));
      var translationKey = indexSetting.get('translationKey');
      var fallbackIndexKey = "index.".concat(propertyName);
      var fallbackTransportKey = "transport.".concat(propertyName);
      var key = "".concat(this.get('className'), ".").concat(translationKey, ".").concat(propertyName);
      return this.get('intl').t(key, {
        default: [fallbackIndexKey, fallbackTransportKey]
      });
    }
  });

  _exports.default = _default;
});