define("apollo/pods/address/model", ["exports", "ember-data-copyable", "apollo/mixins/model-validator", "ember-data", "ember-data/model"], function (_exports, _emberDataCopyable, _modelValidator, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;

  var _default = _model.default.extend(_modelValidator.default, _emberDataCopyable.default, {
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    changeTracker: {
      only: ['country'],
      auto: true,
      enableIsDirty: true
    },
    sessionAccount: Ember.inject.service('session-account'),
    country: belongsTo('country'),
    company: belongsTo('company', {
      inverse: null
    }),
    recipient: attr('string'),
    city: attr('string'),
    postal: attr('string'),
    street: attr('string'),
    principal: attr('number'),
    province: attr('string'),
    voivodeship: belongsTo('voivodeship'),
    isHeadquarters: attr('boolean'),
    enabled: attr('boolean'),
    idToDisable: attr('number'),
    validations: {
      recipient: {
        presence: {
          message: 'blank'
        }
      },
      street: {
        presence: {
          message: 'blank'
        }
      },
      postal: {
        custom: [{
          validation: function validation(key, value, model) {
            var provinceValue = model.get('province');

            if (model.get('country.isWithoutPostalCode')) {
              return !!(value || provinceValue);
            }

            return value;
          },
          message: 'blank'
        }]
      },
      city: {
        presence: {
          message: 'blank'
        }
      },
      country: {
        custom: [{
          validation: function validation(key, value) {
            if (!value.content) {
              return false;
            }

            return value.content;
          },
          message: 'blankSelect'
        }]
      },
      province: {
        custom: [{
          validation: function validation(key, value, model) {
            var postalValue = model.get('postal');

            if (model.get('country.isWithoutPostalCode')) {
              return !!(value || postalValue);
            }

            return true;
          },
          message: 'blank'
        }]
      }
    },
    toAddressString: Ember.computed('street', 'postal', 'city', function () {
      return "".concat(this.get('street'), ", ").concat(this.get('postal'), " ").concat(this.get('city'));
    })
  });

  _exports.default = _default;
});