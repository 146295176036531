define("apollo/pods/artr-setting/model", ["exports", "ember-data", "apollo/mixins/model-validator", "ember-data/model"], function (_exports, _emberData, _modelValidator, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;

  var _default = _model.default.extend(_modelValidator.default, {
    transportType: belongsTo('transportType'),
    entryWhiteListCheckpointAlias: attr('string'),
    entryGateCheckpointAlias: attr('string'),
    exitWhiteListCheckpointAlias: attr('string'),
    exitGateCheckpointAlias: attr('string'),
    expirationTimeInHours: attr('number')
  });

  _exports.default = _default;
});