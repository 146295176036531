define("apollo/pods/components/multiple-time-windows-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    numberOfTransportTimeWindowsToHandle: Ember.computed('transportToHandle', function () {
      return this.get('transportToHandle') ? this.get('transportToHandle.stepsWithWarehouse').length : 0;
    }),
    numberOfCurrentlyHandledTimeWindow: Ember.computed('transportToHandle', function () {
      // numerowanie zaczynamy od 1
      return this.get('transportToHandle.stepsWithWindow').length + 1;
    }),
    canDiscardAdviceRestrictions: Ember.computed('transportToHandle.transportType', function () {
      var transportTypeId = this.get('transportToHandle.transportType.id');
      return this.get('sessionAccount.currentUser').canDiscardAdviceRestrictions(transportTypeId);
    }),
    modalTitle: Ember.computed('', function () {
      return 'Transport #' + this.get('transportToHandle.identifier') + " " + this.get('intl').t('step.timeWindow') + " " + this.get('numberOfCurrentlyHandledTimeWindow') + " " + this.get('intl').t('common.outOf') + " " + this.get('numberOfTransportTimeWindowsToHandle');
    }),
    actions: {
      cancelTransportCreation: function cancelTransportCreation() {
        this.set('hideModalAction', 'hideModal');
        this.set('cancelTransportAction', 'cancelTransportCreation');
        this.sendAction('hideModalAction');
        this.sendAction('cancelTransportAction');
      },
      close: function close() {
        this.set('actionName', 'closeMultipleTimeWindowsModal');
        this.sendAction('actionName');
      }
    }
  });

  _exports.default = _default;
});