define("apollo/pods/transports/modals/create-similar-multiple/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    ajaxService: Ember.inject.service('ajax-service'),
    DEFAULT_MAXIMUM_NUMBER_OF_ORDERS_TO_COPY: "30",
    saving: Ember.computed('model.saving', function () {
      return this.get('model.saving');
    }),
    title: Ember.computed('', function () {
      return this.get('intl').t("transport.createSimilarMultiple");
    }),
    actions: {
      createCopies: function createCopies() {
        var _this = this;

        var maxValue = this.get('sessionAccount').getSettingValue('MAXIMUM_NUMBER_OF_ORDERS_TO_COPY') ? this.get('sessionAccount').getSettingValue('MAXIMUM_NUMBER_OF_ORDERS_TO_COPY') : this.get('DEFAULT_MAXIMUM_NUMBER_OF_ORDERS_TO_COPY');
        var transport = this.get('model.transport');
        var count = this.get('model.count');

        if (count === "" || Number(count) > Number(maxValue)) {
          var msg = this.get('intl').t('transport.tooManyCopies', {
            maxValue: maxValue
          });
          this.set('errors', [msg]);
          return;
        } else {
          this.set('errors', []);
        }

        var params = {
          count: count,
          id: transport.get('id')
        };
        this.set('model.saving', true);
        this.get("ajaxService").send("/transports/createMultipleCopies", "POST", params, true).then(function () {
          _this.set('model.saving', false);

          _this.send('hideModal');
        }).catch(function (response) {
          _this.set('model.saving', false);

          _this.set('errors', JSON.parse(response.responseText).errors);
        });
      },
      close: function close() {
        this.send('hideModal');
      }
    }
  });

  _exports.default = _default;
});