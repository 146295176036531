define("apollo/pods/squares/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Controller.extend({
    sessionAccount: service('session-account'),
    intl: service(),
    store: service(),
    ajaxService: service('ajax-service'),
    actions: {
      save: function save() {
        var self = this;
        var square = this.get('model');
        square.validate();

        if (square.get('errors.length')) {
          return;
        }

        square.save().then(function (response) {
          var msg = self.get('intl').t('square.updateSuccessMessage', {
            squareName: square.get('name')
          });
          self.set('successMessage', msg);
          self.transitionToRoute('squares.edit', response.id);
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      },
      disable: function disable() {
        var self = this;
        var model = this.get('model');
        this.get('ajaxService').send('/squares/' + model.get('id') + '/disable', 'PUT', model, true).then(function () {
          model.set('enabled', false);
          self.transitionToRoute('settings.index');
        });
      },
      enable: function enable() {
        var model = this.get('model');
        this.get('ajaxService').send('/squares/' + model.get('id') + '/enable', 'PUT', model, true).then(function () {
          model.set('enabled', true);
        });
      },
      cancel: function cancel() {
        this.get('model').rollbackAttributes();
        this.transitionToRoute('settings.index');
      }
    }
  });

  _exports.default = _default;
});