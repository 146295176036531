define("apollo/pods/components/related-to-labels/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    actions: {
      showTransportModal: function showTransportModal(id) {
        var _this = this;

        this.get('store').findRecord('transport', id).then(function (transport) {
          transport.get('customFields').forEach(function (cf) {
            return cf.set('changeMessage', null);
          });

          _this.set('openTransportId', transport.get('id'));

          _this.set('action', 'showModal');

          _this.sendAction('action', transport);
        });
      }
    }
  });

  _exports.default = _default;
});