define("apollo/pods/components/custom-popover/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    destroyPopovers: function destroyPopovers() {
      this.set('isOpened', false);
      $('.popover').each(function () {
        $(this).popover('destroy');
      });
    },
    showPopover: function showPopover(element, class1, class2) {
      this.destroyPopovers();
      var $el = $(element);
      this.set('isOpened', true); // popover('destroy') wykonuje się asynchronicznie, co jest trochę zaskakujące,
      // i czasem nie zdążył się wykonać, gdy próbowaliśmy utworzyć nowy popover;
      // na razie wydaje mi się, że rozwiązanie z setTimeout jest faktycznie najlepsze
      // http://stackoverflow.com/a/27345242/2224598

      setTimeout(function () {
        var $popover = $el.find(class1).popover({
          html: true,
          content: function content() {
            return $el.find(class2).html();
          }
        });
        $el.find(class1).popover("show");

        if ($popover.find(".window-details").length) {
          //dostosowanie położenie elementu dla szczegółów okien czasowych
          var _$popover = $('.popover');

          var $arrow = $('.arrow');

          if (_$popover.position()) {
            _$popover.css('left', _$popover.position().left + $arrow.position().left - _$popover.outerWidth(true) / 2);

            $arrow.css('left', 200);
          }
        }
      }, 50);
    }
  });

  _exports.default = _default;
});