define("apollo/pods/indexes/modals/indexes-details/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Controller.extend({
    intl: service(),
    ajaxService: service('ajax-service'),
    successMessage: null,
    errors: [],
    transport: Ember.computed('model', function () {
      return this.get('model');
    }),
    indexSetting: Ember.computed('transport.transportType.indexSetting', function () {
      return this.get('transport.transportType.indexSetting');
    }),
    modalTitle: Ember.computed('indexSetting', function () {
      return this.get('indexSetting').getTranslation('sectionLabel');
    }),
    columns: Ember.computed('', function () {
      return ['index', 'quantities'];
    }),
    canSaveIndexes: Ember.computed(function () {
      if (!this.get('transport.content')) {
        return false;
      }

      return this.get('transport.content').actionCanBePerformed('EDIT_INDEX');
    }),
    actions: {
      cancel: function cancel() {
        this.set('errors', []);
        this.set('successMessage', null);
        var transportModel = this.get('transport');

        if (transportModel.get('indexes.length')) {
          transportModel.get('indexes').forEach(function (index) {
            if (index) {
              index.rollback();
              index.get('quantities').forEach(function (q) {
                return q.rollback();
              });
            }
          });
        }
      },
      save: function save() {
        var _this = this;

        var quantities = [];
        this.get('transport.enabledIndexes').forEach(function (i) {
          i.get('quantities').filter(function (q) {
            return q.get('indexQuantityType.editableViaSchedule');
          }).forEach(function (q) {
            quantities.push(q);
          });
        });

        if (quantities.some(function (q) {
          return !q.validate();
        })) {
          console.debug('Błędy podczas walidacji indeksów!');
          return;
        }

        var data = quantities.map(function (q) {
          return q.customSerialize();
        });
        var transportId = this.get('transport.id');
        this.get('ajaxService').send("/index-quantities?transportId=".concat(transportId), "POST", data, true).then(function () {
          _this.set('successMessage', _this.get('intl').t("common.saveSuccessMessage"));
        }).catch(function (response) {
          _this.set('errors', JSON.parse(response.responseText).errors);
        });
      }
    }
  });

  _exports.default = _default;
});