define("apollo/mixins/transport-form-mixin", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    openPanel: function openPanel(panelClass) {
      var transportPanel = (0, _jquery.default)(".".concat(panelClass));

      if (transportPanel.hasClass('collapsed') && !transportPanel.hasClass('in')) {
        transportPanel.collapse('show');
      }
    },
    initializeDiscrepancyReport: function initializeDiscrepancyReport(transport) {
      var discrepancyReportId = transport.get('discrepancyReport.id');

      if (discrepancyReportId) {
        this.get('store').findRecord('discrepancyReport', discrepancyReportId);
      }
    },
    initializeCustomFields: function initializeCustomFields(transport) {
      var self = this;
      console.debug("Inicjalizujemy custom fields dla transportu ".concat(transport.get('logString'), "..."));
      return self.get('store').query('customFieldValue', {
        transportId: transport.get('id')
      }).then(function (customFields) {
        self.removeCustomFields(transport, customFields);
      }).then(function () {
        self.createMissingCustomFieldValues(transport);
      }).then(function () {
        transport.get('customFields').forEach(function (cf) {
          return cf.set("changeMessage", null);
        });
      });
    },

    /*
     * Usuwa z transportu custom field-y, które nie przyszły z backendu, ponieważ zostały usunięte podczas edycji transportu (nie podano ich wartości)
     */
    removeCustomFields: function removeCustomFields(transport, customFields) {
      // Tworzymy kopię customFields, ponieważ nie możemy usuwać elementów z listy,
      // po której iterujemy (czyli bezpośrednio z transport.get('customFields'))
      var customFieldsCopy = transport.get('customFields').slice(0);
      customFieldsCopy.map(function (cf) {
        if (!customFields.includes(cf)) {
          // console.debug(`Usuwamy z transportu ${transport.get('logString')} custom field ${cf.get('logString')}...`);
          transport.get('customFields').removeObject(cf);
        }
      });
    },

    /*
     * tworzy obiekty customFieldValue dla transportu, jeżeli jeszcze nie istnieją
     * (sytuacja występuje dla transportów utworzonych przed dodaniem nowego customFieldDefinition lub gdy nie uzupełniono wartości customField-u)
     */
    createMissingCustomFieldValues: function createMissingCustomFieldValues(transport) {
      var self = this;
      var currentPairs = transport ? transport.get('customFieldPairs') : this.get('transport.customFieldPairs');
      currentPairs.forEach(function (pair) {
        var definition = pair.definition;

        if (!pair.value) {
          console.debug("Dodajemy do transportu ".concat(transport.get('logString'), " puste/nowe custom field ").concat(definition.get('name'), "..."));
          self.get('store').createRecord('customFieldValue', {
            transport: transport,
            definition: definition
          });
        }
      });
    }
  });

  _exports.default = _default;
});