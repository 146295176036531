define("apollo/pods/components/transport/custom-fields-section/custom-field/component", ["exports", "apollo/mixins/transport-form-mixin"], function (_exports, _transportFormMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_transportFormMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    enabledOptionsWithDependency: Ember.computed('', function () {
      var pairs = this.get('transport.customFieldPairs');
      var options = this.get('pair.definition.enabledOptions');
      var avialableOptions = [];
      options.forEach(function (option) {
        var relatedDef = option.get('relatedDefinition.content');

        if (relatedDef === null) {
          avialableOptions.push(option);
        } else {
          pairs.forEach(function (pair) {
            if (pair.definition === relatedDef) {
              var value = pair.value.get('option');
              option.get('relatedOptions').forEach(function (rOpt) {
                if (rOpt === value.content) {
                  avialableOptions.push(option);
                }
              });
            }
          });
        }
      });
      return avialableOptions;
    }),
    actions: {
      setOption: function setOption(pair, option) {
        var self = this;
        pair.value.set('option', option);
        pair.value.set('hasDirtyAttributes', true); // Żeby znikały czerwone ramki, gdy nie są już potrzebne.

        if (pair.value.get('errors.length') > 0) {
          pair.value.validate();
        }

        if (pair.definition.get('isSelectType') && pair.value.get('option.transportPanel')) {
          var transportPanelClass = pair.definition.get('TRANSPORT_PANEL_CLASS')[pair.value.get('option.transportPanel')];
          self.openPanel(transportPanelClass);
        }

        this.get('transport').notifyPropertyChange('customFieldPairs');
      },
      setCustomFieldData: function setCustomFieldData(pair, option) {
        pair.value.set('value', option.value || option);
      },
      removeCustomField: function removeCustomField(pair) {
        if (!pair.value) {
          return;
        }

        pair.value.set('value', null);
      }
    }
  });

  _exports.default = _default;
});