define("apollo/pods/step/model", ["exports", "ember-data", "ember-data/model", "ember-data-copyable", "apollo/mixins/model-validator"], function (_exports, _emberData, _model, _emberDataCopyable, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      attr = _emberData.default.attr;

  var _default = _model.default.extend(_modelValidator.default, _emberDataCopyable.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    validationService: Ember.inject.service('validation-service'),
    STEP_TYPES: Object.freeze({
      LOAD: 'load',
      UNLOAD: 'unload'
    }),
    transport: belongsTo('transport', {
      async: true
    }),
    timeWindow: belongsTo('timeWindow', {
      async: true
    }),
    company: belongsTo('company', {
      async: true
    }),
    companyGroup: belongsTo('userGroup', {
      async: true
    }),
    address: belongsTo('address', {
      async: true
    }),
    warehouse: belongsTo('warehouse', {
      async: true
    }),
    warehouseZone: belongsTo('warehouseZone', {
      defaultValue: function defaultValue() {
        return null;
      }
    }),
    assortmentGroup: belongsTo('assortmentGroup', {
      async: true
    }),
    stepWarehouseLocations: hasMany('stepWarehouseLocation', {
      async: false
    }),
    stepTypeName: attr('string'),
    orderInTransport: attr('number'),
    documentIdentifier: attr(),
    arrivalDate: attr('pldate'),
    minAdviceDate: attr('pldate'),
    maxAdviceDate: attr('pldate'),
    weight: attr('number'),
    volume: attr('number'),
    stepPackageTypes: hasMany('stepPackageType', {
      async: true
    }),
    enabled: attr('boolean', {
      defaultValue: function defaultValue() {
        return true;
      }
    }),
    didWarehouseChange: false,
    contactPerson: attr('string'),
    contactPersonMobilePhonePrefix: attr('string'),
    contactPersonMobilePhoneNumber: attr('string'),
    contactPersonEmail: attr('string'),
    purchaseOrderNumber: attr('string'),
    dimensions: attr('string'),
    validations: {
      company: {
        custom: [{
          validation: function validation(key, value, model) {
            // validacja obowiązuje tylko gdy w formularzu sa widoczne stepy
            if (!model.get('transport.transportType.hasVisibleSteps')) {
              return true;
            } // pole jest wymagane tylko dla stepów które nie mają magazynu, podczas tworzenia transpotru mówi o tym zmienna ownedByPrincipal
            // eslint-disable-next-line no-mixed-operators


            if ((!model.get('ownedByPrincipal') && !model.get('id') || !model.get('warehouse.id')) && !value.get('id') && model.get('transport.transportType.requiresContractor')) {
              return false;
            }

            return true;
          },
          message: 'isRequired'
        }]
      },
      address: {
        custom: [{
          validation: function validation(key, value, model) {
            // validacja nie obowiązuje jeśli typ transportu nie wymaga adresu i go nie podamy
            if (!model.get('transport.transportType.requiresAddress') && !value.get('content')) {
              return true;
            } // Walidacja obowiązuje tylko gdy w formularzu są widoczne etapy


            if (!model.get('transport.transportType.hasVisibleSteps')) {
              return true;
            } // Pole może być wymagane tylko dla etapów, które nie mają magazynu; podczas tworzenia transportu mówi o tym zmienna ownedByPrincipal


            if (model.get('ownedByPrincipal') || model.get('warehouse.id')) {
              return true;
            } // Jeśli podczas tworzenia/edycji transportu chcemy dodać nowy adres musimy wypełnić pola ulica, miasto itp.


            if (!value.get('id') && value.get('content')) {
              return value.get('content').validate();
            }

            return value && (value.get('id') || value.get('recipient'));
          },
          message: 'blank'
        }]
      },
      arrivalDate: {
        custom: [{
          validation: function validation(key, value, model) {
            // walidacja nie obowiązuje, jeśli pola nie są widoczne w formularzu
            if (!model.get('transport.transportType.requiresArrivalAndDepartureDate')) {
              return true;
            } // validacja obowiązuje tylko gdy w formularzu sa widoczne stepy


            if (!model.get('transport.transportType.hasVisibleSteps')) {
              return true;
            } // pole jest wymagane tylko dla stepów które nie mają magazynu, podczas tworzenia transpotru mówi o tym zmienna ownedByPrincipal


            if (!model.get('belongsToWarehouse') && !value) {
              return false;
            }

            return true;
          },
          message: 'blankSelect'
        }, {
          validation: function validation(key, value, model) {
            // validacja obowiązuje tylko gdy w formularzu sa widoczne stepy
            if (!model.get('transport.transportType.hasVisibleSteps')) {
              return true;
            } // walidacja obowiązuje tylko wtedy, gdy pryncypał ma ją ustawioną


            if (model.get('sessionAccount').getSettingValue('CAN_ARRIVAL_DATE_BE_IN_THE_PAST') === 'true') {
              return true;
            } // pole jest wymagane tylko dla stepów które nie mają magazynu, podczas tworzenia transpotru mówi o tym zmienna ownedByPrincipal


            if (!model.get('belongsToWarehouse') && model.changedAttributes().arrivalDate !== undefined) {
              return value >= new Date();
            }

            return true;
          },
          message: 'isInPast'
        }]
      },
      minAdviceDate: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!model.get('transport.transportType.requiresAdviceDateRestrictions') || !model.get('isInWarehouse')) {
              return true;
            }

            return !!value;
          },
          message: 'blankSelect'
        }, {
          validation: function validation(key, value, model) {
            if (!value || !model.get('timeWindow.id')) {
              return true;
            }

            return value <= model.get('timeWindow.start');
          },
          message: 'afterWindowStart'
        }, {
          validation: function validation(key, value, model) {
            if (!value || !model.get('warehouse.id')) {
              return true;
            }

            if (model.get('transport.isRelatedTransportInTransportCombining')) {
              return true;
            }

            var windowInterval = model.get('warehouse.timeWindowOffsetInterval');
            var selectedMinutes = parseInt(moment(value).format('mm'));
            return selectedMinutes % windowInterval === 0;
          },
          message: function message(key, value, self) {
            var windowInterval = self.get('warehouse.timeWindowOffsetInterval');
            return self.get('intl').t('step.errors.outsideOfInterval', {
              windowInterval: windowInterval
            }).toString();
          }
        }, {
          validation: function validation(key, value, model) {
            if (!value || !model.get('warehouse.id')) {
              return true;
            }

            var workStartHour = model.get('warehouse.workStartHour');
            var workEndHour = model.get('warehouse.workEndHour');
            var workStartOnMaxDateDay = moment(value).startOf('day').add(workStartHour, 'hours').toDate();
            var workEndOnMaxDateDay = moment(value).startOf('day').add(workEndHour, 'hours').toDate();
            var dateValue = new Date(value);
            return dateValue >= workStartOnMaxDateDay && dateValue <= workEndOnMaxDateDay;
          },
          message: function message(key, value, self) {
            var workStartHour = self.get('warehouse.workStartHour');
            var workStartDate = moment(value).startOf('day').add(workStartHour, 'hours').toDate();
            var workEndHour = self.get('warehouse.workEndHour');
            var workEndDate = moment(value).startOf('day').add(workEndHour, 'hours').toDate();
            return self.get('intl').t('step.errors.outsideWarehouseWorkHours', {
              workStart: moment(workStartDate).format('HH:mm'),
              workEnd: workEndHour === 24 ? '24:00' : moment(workEndDate).format('HH:mm')
            }).toString();
          }
        }]
      },
      maxAdviceDate: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!model.get('transport.transportType.requiresAdviceDateRestrictions') || !model.get('isInWarehouse')) {
              return true;
            }

            return !!value;
          },
          message: 'blankSelect'
        }, {
          validation: function validation(key, value, model) {
            if (!value || !model.get('minAdviceDate')) {
              return true;
            }

            return new Date(value) >= new Date(model.get('minAdviceDate'));
          },
          message: 'earlierThanMinAdviceDate'
        }, {
          validation: function validation(key, value, model) {
            // Wymóg podania daty "do" w przyszłości powinien obowiązywać tylko
            // do czasu wybrania okna czasowego - później walidacja zakresu będzie
            // związana z wybranym oknem.
            if (!value || model.get('timeWindow.id')) {
              return true;
            }

            if (model.isLoad && model.changedAttributes().maxAdviceDate !== undefined) {
              var oldMaxAdviceDateValue = moment(model.changedAttributes().maxAdviceDate.get(0)).format('DD/MM/YYYY HH:mm');
              var newMaxAdviceDateValue = moment(model.changedAttributes().maxAdviceDate.get(1)).format('DD/MM/YYYY HH:mm');

              if (oldMaxAdviceDateValue === newMaxAdviceDateValue) {
                return true;
              }
            }

            if (model.changedAttributes().maxAdviceDate === undefined) {
              return true;
            }

            return new Date(value) > new Date();
          },
          message: 'inThePast'
        }, {
          validation: function validation(key, value, model) {
            if (!value || !model.get('minAdviceDate') || !model.get('warehouse.id')) {
              return true;
            }

            var workStartHour = model.get('warehouse.workStartHour');
            var workEndHour = model.get('warehouse.workEndHour');
            var validatedValue = moment(value).add(-1, 'seconds');
            var workStartOnMaxDateDay = moment(validatedValue).startOf('day').add(workStartHour, 'hours').toDate();
            var workEndOnMaxDateDay = moment(validatedValue).startOf('day').add(workEndHour, 'hours').toDate();
            return validatedValue >= workStartOnMaxDateDay && validatedValue <= workEndOnMaxDateDay;
          },
          message: function message(key, value, self) {
            var workStartHour = self.get('warehouse.workStartHour');
            var workStartDate = moment(value).startOf('day').add(workStartHour, 'hours').toDate();
            var workEndHour = self.get('warehouse.workEndHour');
            var workEndDate = moment(value).startOf('day').add(workEndHour, 'hours').toDate();
            return self.get('intl').t('step.errors.outsideWarehouseWorkHours', {
              workStart: moment(workStartDate).format('HH:mm'),
              workEnd: workEndHour === 24 ? '24:00' : moment(workEndDate).format('HH:mm')
            }).toString();
          }
        }, {
          validation: function validation(key, value, model) {
            if (!value || !model.get('timeWindow.id')) {
              return true;
            }

            return value >= model.get('timeWindow.stop');
          },
          message: 'beforeWindowStop'
        }, {
          validation: function validation(key, value, model) {
            if (!value || !model.get('warehouse.id')) {
              return true;
            }

            if (model.get('transport.isRelatedTransportInTransportCombining')) {
              return true;
            }

            var windowInterval = model.get('warehouse.timeWindowOffsetInterval');
            var selectedMinutes = parseInt(moment(value).format('mm'));
            return selectedMinutes % windowInterval === 0;
          },
          message: function message(key, value, self) {
            var windowInterval = self.get('warehouse.timeWindowOffsetInterval');
            return self.get('intl').t('step.errors.outsideOfInterval', {
              windowInterval: windowInterval
            }).toString();
          }
        }]
      },
      warehouse: {
        custom: [{
          validation: function validation(key, value, model) {
            // Walidacja obowiązuje tylko gdy w formularzu są widoczne stepy
            if (!model.get('transport.transportType.hasVisibleSteps')) {
              return true;
            } // Pole jest wymagane tylko dla stepów, które mają magazyn.
            // Podczas tworzenia transportu mówi o tym zmienna `ownedByPrincipal`


            return value.get('id') || !model.get('ownedByPrincipal') || model.get('id');
          },
          message: 'blankSelect'
        }, {
          validation: function validation(key, value, model) {
            // Nie wybranie magazynu powinno skutkować innym błędem
            if (!value || !value.get('id')) {
              return true;
            }

            var stepsWithWarehouse = model.get('transport.stepsWithWarehouse');

            if (stepsWithWarehouse.get('length') <= 1) {
              return true;
            }

            var currentStepTypeName = model.get('stepTypeName'); // Nie chcemy, żeby błąd wyświetlał się przy obu magazynach - wystarczy na rozładunku

            if (currentStepTypeName === 'load') {
              return true;
            }

            var sameWarehouses = stepsWithWarehouse.filter(function (s) {
              return s.get('warehouse.id') === value.get('id');
            });
            console.debug("Number of the same warehouses: ".concat(sameWarehouses.get('length')));
            return !sameWarehouses || sameWarehouses.get('length') === 1;
          },
          message: 'sameWarehousesInTransfer'
        }]
      },
      assortmentGroup: {
        custom: [{
          validation: function validation(key, value, model) {
            // Jeśli step nie jest magazynowy, to nie ma potrzeby sprawdzania tej walidacji
            if (!model.get('warehouse.id')) {
              return true;
            }

            var assortmentGroups = model.get('store').peekAll('assortmentGroup').filter(function (ag) {
              return ag.get('transportTypeIds').includes(model.get('transport.transportType.id')) && ag.get('warehouse.id') === model.get('transport.warehouse.id') && ag.get('enabled') === true;
            }); // Walidacja obowiązuje tylko gdy do magazynu i typu transportu są przypisane grupy

            if (assortmentGroups.length === 0) {
              return true;
            }

            return !!value.get('id');
          },
          message: 'blankSelect'
        }]
      },
      stepWarehouseLocations: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!model.get('belongsToWarehouse')) {
              return true;
            }

            var fieldVisible = model.get('transport.transportType.isWarehouseLocationVisible');
            var fieldObligatory = model.get('transport.transportType.isWarehouseLocationObligatory');
            return !(fieldVisible && fieldObligatory && model.get('stepWarehouseLocations').length < 1);
          },
          message: 'blankSelect'
        }]
      },
      weight: {
        custom: [{
          validation: function validation(key, value, model) {
            var isFieldVisible = false;

            if (model.get('belongsToWarehouse')) {
              isFieldVisible = model.get('transport.transportType.isWeightVisibleInWarehouseStep');
            } else {
              isFieldVisible = model.get('transport.transportType.isWeightVisibleInContractorStep');
            }

            if (!isFieldVisible || !model.get('transport.transportType.requiresStepsWeight')) {
              return true;
            }

            return !Ember.isEmpty(value);
          },
          message: 'blank'
        }, {
          validation: function validation(key, value, model) {
            return model.get('validationService').isPositiveNumber(value);
          },
          message: 'noNegativeNumber'
        }]
      },
      volume: {
        custom: [{
          validation: function validation(key, value, model) {
            return model.get('validationService').isPositiveNumber(value);
          },
          message: 'noNegativeNumber'
        }]
      },
      warehouseZone: {
        custom: [{
          validation: function validation(key, value, model) {
            // Walidacja powinna obowiązywać tylko podczas tworzenia transportu
            // i dopiero po wybraniu magazynu.
            if (model.get('id') || !model.get('warehouse.id')) {
              return true;
            } // Można nie podać tej wartości w przypadku typów, które nie wymagają okna czasowego.


            if (!model.get('transport.transportType.requiresTimeWindow')) {
              return true;
            } // Nie powinniśmy walidować strefy, jeśli nie jest ona widoczna w formularzu.


            if (!model.get('transport.transportType.isWarehouseZoneVisible')) {
              return true;
            }

            if (model.get('warehouse.warehouseZones.length') <= 1) {
              var warehouseName = model.get('warehouse.name');
              console.debug("Magazyn ".concat(warehouseName, " posiada najwy\u017Cej jedn\u0105 stref\u0119 - nie przeprowadzamy walidacji."));
              return true;
            }

            return value && value.get('id');
          },
          message: 'blank'
        }]
      },
      contactPerson: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!model.get('transport.transportType.isContactDetailsFieldsVisible')) {
              return true;
            }

            return !(model.get('transport.transportType.isContactDetailsFieldsRequired') && !value);
          },
          message: 'blank'
        }]
      },
      contactPersonMobilePhoneNumber: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!model.get('transport.transportType.isContactDetailsFieldsRequired') || !model.get('transport.transportType.isContactDetailsFieldsVisible')) {
              return true;
            }

            return /^[0-9]+$/.test(value) && value.length >= 9;
          },
          message: 'blank'
        }]
      },
      contactPersonEmail: {
        custom: [{
          validation: function validation(key, value, model) {
            var emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

            if (!model.get('transport.transportType.isContactDetailsFieldsVisible')) {
              return true;
            }

            if (value !== null && !value.match(emailFormat)) {
              return false;
            }

            return !(model.get('transport.transportType.isContactDetailsFieldsRequired') && !value);
          },
          message: 'invalidEmailAddress'
        }]
      },
      purchaseOrderNumber: {
        length: {
          maximum: {
            value: 255,
            message: 'length',
            allowBlank: true
          }
        }
      },
      dimensions: {
        length: {
          maximum: {
            value: 255,
            message: 'length',
            allowBlank: true
          }
        }
      },
      companyGroup: {
        custom: [{
          validation: function validation(key, value, model) {
            if (model.get('belongsToWarehouse')) {
              return true;
            }

            if (!model.get('companyGroup.id')) {
              return true;
            }

            var company = model.get('company');

            if (!company.get('userGroups').find(function (ug) {
              return ug.get('id') === value.get('id');
            })) {
              return false;
            }

            return true;
          },
          message: 'wrongValue'
        }, {
          validation: function validation(key, value, model) {
            if (model.get('belongsToWarehouse') && model.get('transport.sortedSteps.length') !== 1) {
              return true;
            }

            if (!model.get('transport.transportType.requiresContractorStepCompanyGroup')) {
              return true;
            }

            var company = model.get('company');
            var userGroups = company.get('nonDefaultUserGroups');

            if (userGroups.length === 0) {
              return true;
            }

            return !!model.get('companyGroup.id');
          },
          message: 'blank'
        }]
      }
    },
    companyLabel: Ember.computed('company.shortName', 'companyGroup.{id,name}', function () {
      var label = this.get('company.shortName');

      if (this.get('companyGroup.id')) {
        label += " (".concat(this.get('companyGroup.name'), ")");
      }

      return label;
    }),
    sortProperty: Ember.computed('start', function () {
      return this.get('start') ? this.get('start').getTime() : '';
    }),
    start: Ember.computed('timeWindow.start', function () {
      return this.get('timeWindow').get('start');
    }),
    isInWarehouse: Ember.computed('warehouse.id', 'ownedByPrincipal', function () {
      return this.get('warehouse.id') || this.get('ownedByPrincipal');
    }),
    isLoad: Ember.computed('stepTypeName', function () {
      return this.get('stepTypeName') === this.get('STEP_TYPES').LOAD;
    }),
    isUnload: Ember.computed('stepTypeName', function () {
      return this.get('stepTypeName') === this.get('STEP_TYPES').UNLOAD;
    }),
    companyName: Ember.computed('company', function () {
      return this.get('company.name');
    }),
    country: Ember.computed('company', function () {
      return this.get('company.country');
    }),
    belongsToWarehouse: Ember.computed('warehouse.id', 'id', 'ownedByPrincipal', function () {
      return Boolean(this.get('warehouse.id')) || !this.get('id') && !!this.get('ownedByPrincipal');
    }),
    formattedAdviceTimeRange: Ember.computed('minAdviceDate', 'maxAdviceDate', function () {
      return moment(this.get('minAdviceDate')).format('DD.MM.YYYY HH:mm').toString() + ' - ' + moment(this.get('maxAdviceDate')).format('DD.MM.YYYY HH:mm').toString();
    }),
    postal: Ember.computed('address.postal', 'warehouse.address.postal', function () {
      return this.get('address.postal') || this.get('warehouse.address.postal');
    }),
    city: Ember.computed('address.city', 'warehouse.address.city', function () {
      return this.get('address.city') || this.get('warehouse.address.city');
    }),
    indexes: Ember.computed('id', 'store', function () {
      var _this = this;

      return this.get('store').peekAll('index').filter(function (i) {
        return i.get('step.id') === _this.get('id');
      });
    }),
    indexSearchField: Ember.computed('company.shortName', 'address.recipient', function () {
      if (!this.get('company.shortName')) {
        return this.get('intl').t('common.noData');
      }

      var addressRecipient = this.get('address.recipient') ? ', ' + this.get('address.recipient') : '';
      return "".concat(this.get('company.shortName')).concat(addressRecipient);
    }),
    // eslint-disable-next-line ember/require-return-from-computed
    formattedTerm: Ember.computed('arrivalDate', 'formattedAdviceTimeRange', 'showAdviceDateRestrictions', 'timeWindow.{formattedTime,id}', 'warehouse.formattedWorkTime', function () {
      if (this.get('timeWindow.id') || this.get('showAdviceDateRestrictions')) {
        return this.get('timeWindow.formattedTime') || this.get('formattedAdviceTimeRange');
      } else if (this.get('arrivalDate')) {
        return moment(this.get('arrivalDate')).format('DD.MM.YYYY HH:mm');
      }
    }),
    numberOfErrors: Ember.computed('errors.length', function () {
      return this.get('errors.length');
    }),
    showWarehouseCheckbox: Ember.computed('', function () {
      return this.get('isLoad') ? this.get('transport.transportType.allowLoadStepTypeChange') : this.get('transport.transportType.allowUnloadStepTypeChange');
    }),
    showAdviceDateRestrictions: Ember.computed('warehouse', 'ownedByPrincipal', 'transport.transportType.requiresAdviceDateRestrictions', function () {
      return this.get('isInWarehouse') && this.get('transport.transportType.requiresAdviceDateRestrictions');
    }),
    showAdviceDateRestrictionInEveryStep: Ember.computed('transport', function () {
      if (this.get('transport.transportType.adviceRestrictionInEveryStep')) {
        if (!this.get('transport.id')) {
          this.setAdviceRestrictionDates();
        }

        return true;
      }

      return false;
    }),
    setAdviceRestrictionDates: function setAdviceRestrictionDates() {
      var numberOfDaysAddedToFirstStep = this.get('transport.transportType.numberOfDaysAddedToFirstStep');
      var numberOfDaysAddedToFirstStepEnd = this.get('transport.transportType.numberOfDaysAddedToFirstStepEnd');
      var numberOfDaysAddedToNextSteps = this.get('transport.transportType.numberOfDaysAddedToNextSteps');
      var numberOfDaysAddedToNextStepsEnd = this.get('transport.transportType.numberOfDaysAddedToNextStepsEnd');
      var numberOfDaysForFirstStep = numberOfDaysAddedToFirstStep || 0;
      var numberOfDaysForFirstStepEnd = numberOfDaysAddedToFirstStepEnd || 0;
      var numberOfDaysForNextSteps = numberOfDaysAddedToNextSteps || 0;
      var numberOfDaysForNextStepsEnd = numberOfDaysAddedToNextStepsEnd || 0;
      var minAdviceDateForFirstStep = moment().startOf('day').add(numberOfDaysForFirstStep, 'day').toDate();
      var maxAdviceDateForFirstStep = moment().startOf('day').add(numberOfDaysForFirstStepEnd, 'day').toDate();
      var minAdviceDateForNextSteps = moment().startOf('day').add(numberOfDaysForNextSteps, 'day').toDate();
      var maxAdviceDateForNextSteps = moment().startOf('day').add(numberOfDaysForNextStepsEnd, 'day').toDate();
      var minAdviceDate = null;
      var maxAdviceDate = null;

      if (this.get('orderInTransport') === 0) {
        minAdviceDate = minAdviceDateForFirstStep;
        maxAdviceDate = maxAdviceDateForFirstStep;
      } else {
        minAdviceDate = minAdviceDateForNextSteps;
        maxAdviceDate = maxAdviceDateForNextSteps;
      }

      if (this.get('warehouse')) {
        var warehouseWorkStartHour = this.get('warehouse.workStartHour');
        var warehouseWorkEndHour = this.get('warehouse.workEndHour');
        minAdviceDate = moment(minAdviceDate).add(warehouseWorkStartHour, 'hours').toDate();
        maxAdviceDate = moment(maxAdviceDate).add(warehouseWorkEndHour, 'hours').toDate();
      }

      this.setProperties({
        minAdviceDate: minAdviceDate,
        maxAdviceDate: maxAdviceDate
      });
    },
    requiresArrivalAndDepartureDate: Ember.computed('transport', function () {
      return this.get('transport.transportType.requiresArrivalAndDepartureDate');
    }),
    showAddressFieldsInContractorSteps: Ember.computed('transport.transportType.showAddressFieldsInContractorSteps', function () {
      return this.get('transport.transportType.showAddressFieldsInContractorSteps');
    }),
    showWeightFieldInOfferForm: Ember.computed('transport', 'transport.activeAuction', 'showWeightField', function () {
      return this.get('transport.activeAuction') && this.get('showWeightField');
    }),
    showWeightField: Ember.computed('transport', function () {
      if (this.get('belongsToWarehouse')) {
        return this.get('transport.transportType.isWeightVisibleInWarehouseStep');
      }

      return this.get('transport.transportType.isWeightVisibleInContractorStep');
    }),
    showVolumeField: Ember.computed('transport', function () {
      if (this.get('belongsToWarehouse')) {
        return this.get('transport.transportType.isVolumeVisibleInWarehouseStep');
      }

      return this.get('transport.transportType.isVolumeVisibleInContractorStep');
    }),
    showInternalReleaseNumberField: Ember.computed('transport', function () {
      if (this.get('belongsToWarehouse')) {
        return this.get('transport.transportType.isInternalReleaseNumberVisibleInWarehouseStep');
      }

      return this.get('transport.transportType.isInternalReleaseNumberVisibleInContractorStep');
    }),
    showPurchaseOrderNumberField: Ember.computed('transport', function () {
      if (this.get('belongsToWarehouse')) {
        return this.get('transport.transportType.purchaseOrderNumberVisibleInWarehouseStep');
      }

      return this.get('transport.transportType.purchaseOrderNumberVisibleInContractorStep');
    }),
    showDimensionsField: Ember.computed('transport', function () {
      if (this.get('belongsToWarehouse')) {
        return this.get('transport.transportType.dimensionsVisibleInWarehouseStep');
      }

      return this.get('transport.transportType.dimensionsVisibleInContractorStep');
    }),
    // Chciałam użyć nasłuchiwacz na `transport.checkpoints.@each.isConfirmed`, ale generowało to problemy
    // ponieważ przy zapisie checkpointy są usuwane i generowane na nowo, przez co ember nie może
    // czasem dodać nasłuchiwacza.
    // Po potwierdzeniu statusu zawsze aktualizuje się transport.lastUpdated, więc efekt jest zgodny z oczekiwaniem.
    canMoveLoadSteps: Ember.computed('transport.{id,transportType,lastUpdated}', function () {
      return this.get('transport.content').actionCanBePerformed('MOVE_LOAD_STEPS');
    }),

    /**
     * `transport.lastUpdated` -- zob. komentarz dla `canMoveLoadSteps`
     */
    canMoveUnloadSteps: Ember.computed('transport.{id,transportType,lastUpdated}', function () {
      return this.get('transport.content').actionCanBePerformed('MOVE_UNLOAD_STEPS');
    }),

    /**
     * Step można przesunąć, jeśli:
     * - użytkownik ma uprawnienia na przesuwanie i załadunków, i rozładunków,
     * - do czasu potwierdzenia statusu 'Potwierdzenie kolejności rozładunków'
     * - lub step ma chociaż jednego sąsiada tego samego typu (do którego ma uprawnienie użytkownik)
     */
    canBeMoved: Ember.computed('stepTypeName', 'canMoveLoadSteps', 'canMoveUnloadSteps', 'transport.{steps.length,canBeUpdated}', function () {
      if (this.get('canMoveLoadSteps') && this.get('canMoveUnloadSteps')) {
        return true;
      }

      var stepType = this.get('stepTypeName');
      return stepType === this.get('STEP_TYPES').LOAD ? this.get('canMoveLoadSteps') : this.get('canMoveUnloadSteps');
    }),
    // eslint-disable-next-line ember/require-return-from-computed
    title: Ember.computed('stepTypeName', 'canMoveLoadSteps', 'canMoveUnloadSteps', function () {
      if (!this.get('canBeMoved')) {
        if (!this.get('canMoveLoadSteps') && !this.get('canMoveUnloadSteps')) {
          return;
        }

        return this.get('canMoveLoadSteps') ? this.get('intl').t('step.canMoveLoadStep') : this.get('intl').t('step.canMoveUnloadStep');
      }
    }),
    stringLabel: Ember.computed('address.{recipient,country.ibanSymbol}', 'warehouse.name', function () {
      return this.get('warehouse.id') ? "".concat(this.get('warehouse.name')) : "".concat(this.get('address.recipient'), " (").concat(this.get('address.country.ibanSymbol'), ")");
    }),
    ramp: Ember.computed('timeWindow.ramp', function () {
      return this.get('timeWindow.ramp');
    }),
    userRamps: Ember.computed('warehouse', 'sessionAccount.currentUser.ramps.[]', function () {
      return this.get('sessionAccount.currentUser.ramps').filterBy('warehouse.id', this.get('warehouse.id'));
    }),
    warehouseZoneTransportTypes: Ember.computed('', function () {
      return this.get('store').peekAll('warehouse-zone-transport-type');
    }).readOnly(),
    warehouseZones: Ember.computed('userRamps.@each.warehouseZone', 'warehouseZoneTransportTypes.[]', 'transport.transportType.id', function () {
      var self = this;
      var warehouseZonesIds = this.get('userRamps').getEach('warehouseZone.id').uniq();
      var transportTypeId = self.get('transport.transportType.id');
      var filteredWarehouseZoneTransportTypes = this.get('warehouseZoneTransportTypes').filter(function (it) {
        return it.get('transportType.id') === transportTypeId;
      }).filter(function (wztt) {
        return warehouseZonesIds.includes(wztt.get('warehouseZone.id'));
      });
      return filteredWarehouseZoneTransportTypes.getEach('warehouseZone');
    }),
    // eslint-disable-next-line ember/no-observers
    warehouseChanged: Ember.observer('warehouse', 'warehouseZones', 'transport.transportType', function () {
      var warehouseZones = this.get('warehouseZones');
      var transportType = this.get('transport.transportType');

      if (!this.get('warehouseZone.id')) {
        if (warehouseZones.get('length') === 1) {
          console.debug("Do magazynu ".concat(this.get('warehouse.name'), " przypisana jest tylko jedna strefa -- ").concat(warehouseZones.get('firstObject.name'), " -- ustawiamy j\u0105 w stepie..."));
          this.set('warehouseZone', warehouseZones.get('firstObject'));
        } else if (transportType && !transportType.get('isWarehouseZoneVisible')) {
          console.debug("Do magazynu ".concat(this.get('warehouse.name'), " przypisanych jest kilka stref, ale ich wyb\xF3r nie jest mo\u017Cliwy w formularzu -- wybieramy pierwsz\u0105 z nich (").concat(warehouseZones.get('firstObject.name'), ")..."));
          this.set('warehouseZone', warehouseZones.get('firstObject'));
        }
      }

      if (this.get('warehouseZone.id') && this.get('warehouseZone.warehouse.id') !== this.get('warehouse.id')) {
        console.debug("W stepie ".concat(this.get('orderInTransport'), " jest nowy magazyn -- ").concat(this.get('warehouse.name'), "; czy\u015Bcimy wyb\xF3r strefy..."));
        this.set('warehouseZone', null);

        if (this.get('transport.id')) {
          console.debug('Został zmieniony magazyn w istniejącym zleceniu, ustawiam flagę didWarehouseChange na true');
          this.set('didWarehouseChange', true);
        }
      }
    }),
    quantitySum: Ember.computed('stepPackageTypes.@each.{quantity,multiplier}', function () {
      var sum = 0;
      this.get('stepPackageTypes').forEach(function (spt) {
        var asReferenceQuantity = spt.get('asReferenceQuantity');
        sum += asReferenceQuantity || 0;
      });
      return sum;
    }),
    palletsQuantity: Ember.computed('stepPackageTypes.@each.quantity', function () {
      return this.get('stepPackageTypes').filter(function (spt) {
        return spt.get('packageType.name').includes('PALLETS');
      }).map(function (spt) {
        return spt.get('quantity') ? spt.get('quantity') : 0;
      }).reduce(function (a, b) {
        return Number(a) + Number(b);
      }, 0);
    }),
    // eslint-disable-next-line ember/require-return-from-computed
    newAddressAsDefault: Ember.computed('stepTypeName', 'company.id', 'transport.transportType.id', function () {
      var stepTypeName = this.get('stepTypeName');
      var stepTypes = this.get('STEP_TYPES');
      var transportType = this.get('transport.transportType');

      if (stepTypeName === stepTypes.LOAD) {
        return transportType.get('newAddressAsDefaultInLoadStep');
      } else if (stepTypeName === stepTypes.UNLOAD) {
        return transportType.get('newAddressAsDefaultInUnloadStep');
      }
    }),
    shouldAutoSaveCompanyGroup: Ember.computed('transport.sortedSteps.length', 'belongsToWarehouse', 'companyGroup.content', 'company.nonDefaultUserGroups.length', function () {
      return (this.get('transport.sortedSteps.length') === 1 || !this.get('belongsToWarehouse')) && !this.get('companyGroup.content') && this.get('company.nonDefaultUserGroups.length') === 1;
    }),
    stepWarehouseLocationsLabels: Ember.computed('warehouse.warehouseLocations.@each.nameInAppropriateLanguage', function () {
      var list = [];
      this.get('warehouse.warehouseLocations').filterBy('enabled').forEach(function (whl) {
        var obj = {
          name: whl.get('nameInAppropriateLanguage'),
          wlId: whl.get('id')
        };
        list.pushObject(obj);
      });
      return list;
    })
  });

  _exports.default = _default;
});