define("apollo/mixins/section-mixin", ["exports", "apollo/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax-service'),
    errors: [],
    actions: {
      save: function save(section) {
        var route = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
        this.send('update', section, route);
      },
      update: function update(section) {
        var _this = this;

        var route = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
        console.log("Updating section ".concat(section.get('id'), ".."));
        var self = this;
        section = section.get('content') ? section.get('content') : section;

        if (!section.validate()) {
          self.set('successMessage', '');
          return;
        }

        section.save().then(function (response) {
          self.send('refreshSection', section);
          self.set('errors', '');
          self.set('successMessage', _this.get('intl').t('section.successMessage'));

          if (route !== null) {
            // todo ups truck.id zostało dodane z rozpędu, oczywiście nie może tak być w tak ogólnej metodzie
            self.transitionTo(route, response.get('truck.id'));
          }
        }).catch(function (response) {
          self.set('successMessage', '');
          self.set('errors', response.errors);
          self.send('refreshSection', section);
        });
      },
      confirm: function confirm(section) {
        if (section.hasChangesApartFromActions ? section.hasChangesApartFromActions() : section.content.hasChangesApartFromActions()) {
          var self = this;
          section = section.get('content') ? section.get('content') : section;
          section.save().then(function () {
            return self.send('executeAction', 'sections', section, 'confirm');
          });
        } else {
          this.send('executeAction', 'sections', section, 'confirm');
        }
      },
      reject: function reject(section, comment) {
        if (section.hasChangesApartFromActions ? section.hasChangesApartFromActions() : section.content.hasChangesApartFromActions()) {
          var self = this;
          section = section.get('content') ? section.get('content') : section;
          section.save().then(function () {
            return self.send('executeAction', 'sections', section, 'reject', comment);
          });
        } else {
          this.send('executeAction', 'sections', section, 'reject', comment);
        }
      },
      disable: function disable(section) {
        this.send('executeAction', 'documents', section, 'disable');
      },
      executeAction: function executeAction(realm, section, actionName, comment) {
        var self = this;
        var token = this.get('sessionAccount').get('token');
        self.set('operationInProgress', true);

        _jquery.default.ajax({
          url: "".concat(_environment.default.serverURL, "/").concat(realm, "/").concat(section.get('id'), "/").concat(actionName),
          type: 'POST',
          data: {
            comment: comment
          },
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          console.log("Section ".concat(section.get('id'), " has been ").concat(actionName, "ed."));
          self.set('operationInProgress', false);
          self.send('refreshSection', section);
          self.set('errors', []);
        }).catch(function (response) {
          self.set('errors', JSON.parse(response.responseText).errors);
        });
      },
      refreshSection: function refreshSection(section) {
        var modelName = section.get('constructor.modelName') ? section.get('constructor.modelName') : section.get('_internalModel.modelName');
        this.get('store').findRecord(modelName, section.get('id'), {
          reload: true
        }).then(function (s) {
          if (s.get('partnership.id')) {
            s.get('partnership').then(function (p) {
              return p.reload();
            });
          } else {
            s.get('truck').then(function (t) {
              return t.reload();
            });
          }

          if (s.get('activeAction')) {
            s.get('activeAction').reload();
          }
        });
      }
    }
  });

  _exports.default = _default;
});