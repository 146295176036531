define("apollo/authenticators/custom", ["exports", "ember-simple-auth/authenticators/base", "apollo/config/environment", "apollo/app"], function (_exports, _base, _environment, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = _base.default.extend({
    sessionAccount: service('session-account'),
    session: service('session'),
    store: service(),
    restore: function restore(data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(data.token)) {
          Ember.run(function () {
            resolve({
              token: data.token,
              username: data.username
            });
          });
        } else {
          reject();
        }
      });
    },
    authenticate: function authenticate(credentials, endpoint) {
      var tokenEndpoint = _environment.default.serverURL + endpoint;
      var data = {
        username: credentials.identification,
        password: credentials.password
      };
      return new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.$.support.cors = true;
        Ember.$.ajax({
          url: tokenEndpoint,
          type: 'POST',
          data: JSON.stringify(data),
          contentType: 'application/json'
        }).then(function (response) {
          Ember.run(function () {
            resolve({
              token: response.access_token,
              username: response.username
            });
          });
        }, function (xhr) {
          var responseText = xhr.responseText || xhr.statusText;
          var status = xhr.status;
          Ember.run(function () {
            reject({
              responseText: responseText,
              status: status
            });
          });
        });
      });
    },
    invalidate: function invalidate() {
      var self = this;
      var token = this.get('sessionAccount').get('token');
      var currentUser = this.get('sessionAccount').get('currentUser');
      var username = currentUser ? currentUser.get('username') : '';
      var logoutEndpoint = _environment.default.serverURL + "/logout?currentUser=".concat(username);
      return new Ember.RSVP.Promise(function (resolve) {
        Ember.$.ajax({
          url: logoutEndpoint,
          type: 'POST',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          self.clearPrincipalCookie();
          resolve();
        }, function (response) {
          self.clearPrincipalCookie();

          if (response.status === 403) {
            localStorage.setItem('logoutByConcurrentSession', 'true');
          } else if (response.status === 404) {
            localStorage.setItem('logoutByService', 'true');
          }

          resolve();
        });
      });
    },
    clearPrincipalCookie: function clearPrincipalCookie() {
      localStorage.removeItem('previousPrincipalId');
      localStorage.setItem('previousPrincipalId', localStorage.getItem('principalId'));
      localStorage.removeItem('principalId');
      console.log("A 'principalId' cleared.");
    },
    actions: {
      sessionAuthenticationFailed: function sessionAuthenticationFailed(error) {
        console.log("sessionAuthenticationFailed..");
        this.controllerFor('application').set('loginErrorMessage', error.message);
      }
    }
  });

  _exports.default = _default;
});