define("apollo/pods/transport-types/courier-settings/controller", ["exports", "apollo/mixins/response-util-mixin"], function (_exports, _responseUtilMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_responseUtilMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    transportType: Ember.computed('model', function () {
      return this.get('model.transportType');
    }),
    courierSettings: Ember.computed('model', function () {
      return this.get('model.courierSettings');
    }),
    transportTypes: Ember.computed('sessionAccount.currentUser.transportTypes', function () {
      return this.get('sessionAccount.currentUser.transportTypes');
    }),
    actions: {
      save: function save() {
        var _this = this;

        this.set('errors', []);
        this.get('courierSettings').save().then(function () {
          return _this.handleSuccessResponse(_this);
        }).catch(function (response) {
          return _this.handleErrorResponse(_this, response);
        });
      },
      create: function create() {
        if (this.get('courierSettings')) {
          return;
        }

        this.set('courierSettings', this.get('store').createRecord('courier-setting', {
          transportType: this.get('transportType')
        }));
      },
      delete: function _delete() {
        var _this2 = this;

        this.set('errors', []);
        this.get('courierSettings').destroyRecord().then(function () {
          return _this2.handleSuccessResponse(_this2);
        }).catch(function (response) {
          return _this2.handleErrorResponse(_this2, response);
        });
      },
      cancel: function cancel() {
        this.get('courierSettings').rollbackAttributes();
        this.transitionToRoute('settings.index');
      }
    }
  });

  _exports.default = _default;
});