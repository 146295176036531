define("apollo/helpers/statute-url", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.statueUrl = statueUrl;
  _exports.default = void 0;

  function statueUrl() {
    return "".concat(_environment.default.serverURL, "/statutes/current");
  }

  var _default = Ember.Helper.helper(statueUrl);

  _exports.default = _default;
});