define("apollo/pods/transport/model", ["exports", "ember-data/model", "ember-data", "apollo/mixins/model-validator", "ember-data-copyable", "jquery"], function (_exports, _model, _emberData, _modelValidator, _emberDataCopyable, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;

  var _default = _model.default.extend(_modelValidator.default, _emberDataCopyable.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    creator: belongsTo('user', {
      async: true
    }),
    transportSupervisor: belongsTo('user', {
      async: true
    }),
    principalCompany: belongsTo('company', {
      async: true
    }),
    carrierCompany: belongsTo('company', {
      async: true,
      deepEmbedded: ['country']
    }),
    carrierCompanyChooser: belongsTo('user', {
      async: true
    }),
    serviceCompany: belongsTo('company', {
      async: true,
      deepEmbedded: ['country', 'workers']
    }),
    workers: hasMany('worker', {
      async: true
    }),
    advice: belongsTo('advice', {
      async: true,
      deepEmbedded: ['creator', 'driverLanguage', 'truck']
    }),
    transaction: belongsTo('transaction', {
      async: true
    }),
    discrepancyReport: belongsTo('discrepancyReport', {
      async: true
    }),
    firstUnconfirmedCheckpoint: belongsTo('checkpoint', {
      async: true
    }),
    transportType: belongsTo('transportType', {
      async: true
    }),
    auctions: hasMany('auction', {
      async: true
    }),
    steps: hasMany('step', {
      async: true,
      deepEmbedded: ['timeWindow', 'company', 'address', 'warehouse', 'assortmentGroup', 'companyGroup']
    }),
    checkpoints: hasMany('checkpoint', {
      async: true
    }),
    notes: hasMany('note', {
      async: true
    }),
    files: hasMany('file', {
      async: true
    }),
    transportWeightReceipts: hasMany('file', {
      async: true,
      inverse: null
    }),
    discrepancyReports: hasMany('discrepancyReport', {
      async: true,
      inverse: null
    }),
    timeWindows: hasMany('timeWindow', {
      async: true
    }),
    transportLogs: hasMany('transportLog', {
      async: true
    }),
    apiIntegrationLogs: hasMany('apiIntegrationLog', {
      async: true
    }),
    artrApiLogs: hasMany('artrApiLog', {
      async: true
    }),
    notificationLogs: hasMany('notificationLog', {
      async: true
    }),
    weightLogs: hasMany('weightLog', {
      async: true
    }),
    customFields: hasMany('customFieldValue', {
      async: true,
      deepEmbedded: ['definition', 'option']
    }),
    indexes: hasMany('index', {
      async: true,
      deepEmbedded: ['step', 'product']
    }),
    courierPackages: hasMany('courierPackage', {
      async: true,
      deepEmbedded: ['packageType']
    }),
    courierInvoice: belongsTo('courierInvoice', {
      async: true
    }),
    courierPricings: hasMany('courierPricing', {
      async: true,
      deepEmbedded: ['courierPricingSuccess', 'courierPricingError']
    }),
    courierOrder: belongsTo('courierOrder', {
      async: true
    }),
    courierOrderError: belongsTo('courierOrderError', {
      async: true
    }),
    weights: hasMany('weight', {
      async: true
    }),
    emailAdvices: hasMany('emailAdvice', {
      async: true
    }),
    internalIndex: attr('number'),
    erpIdentifier: attr('string'),
    altIdentifier: attr('string'),
    creatorName: attr('string'),
    creatorCompanyId: attr('string'),
    completed: attr('boolean'),
    archived: attr('boolean'),
    dateOfArchiving: attr('isodate'),
    deleted: attr('boolean'),
    dateOfDeletion: attr('isodate'),
    earliestTimeWindowStart: attr('pldate'),
    latestTimeWindowStop: attr('pldate'),
    dateCreated: attr('date'),
    lastUpdated: attr('date'),
    description: attr('string'),
    isLate: attr('boolean'),
    incompleteLoading: attr('boolean'),
    originalTransportId: attr('number'),
    products: attr('list'),
    periodicTransport: belongsTo('periodicTransport', {
      async: true,
      inverse: null
    }),
    periodicTransportInternalIndex: attr('number'),
    // pola dla kurierów
    goodsValue: attr('string'),
    goodsValueCurrency: attr('string'),
    incoterms: attr('string'),
    getCouriersQuotes: attr('boolean'),
    needExportDeclaration: attr('boolean'),
    needPLT: attr('boolean'),
    relatedTo: hasMany('transportTransportRelation', {
      async: true,
      inverse: null
    }),
    relatedToTransports: hasMany('transport', {
      async: true,
      inverse: null
    }),
    masterTransports: hasMany('transport', {
      async: true,
      inverse: null
    }),
    hasToWaitRelations: hasMany('transportTransportRelation', {
      inverse: null
    }),
    relatedHasToWaitForTransports: hasMany('transport', {
      async: true,
      inverse: null
    }),
    relatedHasToWaitForNotCreatedTransports: attr(),
    masterHasToWaitForTransports: hasMany('transport', {
      async: true,
      inverse: null
    }),
    combinedMasterTransport: belongsTo('transport', {
      async: true,
      inverse: null
    }),
    isRelatedTransportInTransportCombining: attr('boolean'),
    isMasterTransportInTransportCombining: attr('boolean'),
    isRelatedTransportInHasToWaitFor: attr('boolean'),
    isMasterTransportInHasToWaitFor: attr('boolean'),
    allCombinedTransports: hasMany('transport', {
      async: true,
      inverse: null
    }),
    transportRelationIdentifier: attr('string'),
    readyToPlan: attr('boolean'),
    hasConfirmedRouteOffers: attr('boolean'),
    lastTransactionAutoPassNotAccepted: attr('boolean'),
    requiresInspection: attr('boolean'),
    isDuringAddingOfAdvice: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    periodicTransportActive: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    updateAllFuturePeriodicTransports: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    updateAllFuturePeriodicWindows: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    hasCustomFieldsInitialized: false,
    isHighlighted: false,
    manualNotification: belongsTo('manualNotification', {
      async: true
    }),
    validations: {
      courierPackages: {
        custom: [{
          validation: function validation(key, value, model) {
            if (model.getCouriersQuotes) {
              var pckgs = model.courierPackages.filterBy('enabled');

              if (pckgs.length < 1) {
                return false;
              }

              var valid = true;
              pckgs.forEach(function (cp) {
                if (!cp.validate()) {
                  valid = false;
                }
              });
              return valid;
            }

            return true;
          },
          message: function message(key, value, model) {
            return model.get('intl').t('courier.validation.minimumOnePackage');
          }
        }]
      },
      erpIdentifier: {
        custom: [{
          validation: function validation(key, value, model) {
            // Można nie podać tej wartości w przypadku typów, które nie wymagają numeru zlecenia.
            return value && value.length >= 3 || !model.get('transportType').get('requiresErpIdentifier');
          },
          message: 'minLength'
        }]
      },
      altIdentifier: {
        custom: [{
          validation: function validation(key, value, model) {
            return value || !model.get('transportType').get('requiresAltIdentifier');
          },
          message: 'blank'
        }, {
          validation: function validation(key, value, model) {
            var regex = model.get('transportType').get('altIdentifierRegex');

            if (regex === '' || regex === null || regex === undefined) {
              return true;
            } else {
              if (value === undefined) {
                value = '';
              }

              var matcher = new RegExp(regex);
              return matcher.test(value);
            }
          },
          message: 'regex'
        }]
      },
      advice: {
        relations: ['belongsTo']
      },
      customFields: {
        relations: ['hasMany']
      },
      warehouse: {
        custom: [{
          validation: function validation(key, value, model) {
            // Walidacja powinna obowiązywać tylko podczas tworzenia transportu.
            if (model.get('id')) {
              return true;
            } // Można nie podać tej wartości w przypadku typów, które nie wymagają
            // ani okna czasowego, ani magazynu.


            return value && value.get('id') || !model.get('transportType').get('requiresTimeWindow') && !model.get('transportType').get('requiresWarehouse');
          },
          message: 'blank'
        }]
      },
      carrierCompany: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!model.get('sessionAccount.currentUser.company.isPrincipal')) {
              return true;
            }

            var transportType = model.get('transportType');
            console.debug("Transport type ".concat(transportType.get('name'), ", group ").concat(transportType.get('typeGroup'), " :: allows carrier: ").concat(transportType.get('allowsCarrier'), ", requires carrier: ").concat(transportType.get('requiresCarrier'), ".")); // walidacja nie może obowiązywać gdy pole nie jest widoczne w formularzu lub nie jest wymagane

            if (!transportType.get('allowsCarrier') || !transportType.get('requiresCarrier')) {
              return true;
            }

            return value.get('id');
          },
          message: 'info'
        }]
      },
      serviceCompany: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!model.get('sessionAccount.currentUser.company.isPrincipal')) {
              return true;
            }

            var transportType = model.get('transportType');
            console.debug("Transport type ".concat(transportType.get('name'), ", group ").concat(transportType.get('typeGroup'), " :: allows service: ").concat(transportType.get('allowsService'), ", requires service: ").concat(transportType.get('requiresService'), "."));

            if (!transportType.get('allowsService') || !transportType.get('requiresService')) {
              return true;
            }

            return value.get('id');
          },
          message: 'info'
        }]
      },
      firstContractor: {
        custom: [{
          validation: function validation(key, value, model) {
            return value || !model.get('transportType.requiresContractor');
          },
          message: 'info'
        }]
      },
      incoterms: {
        custom: [{
          validation: function validation(key, value, model) {
            return !(!value && model.get('getCouriersQuotes'));
          },
          message: 'blank'
        }]
      },
      goodsValue: {
        custom: [{
          validation: function validation(key, value, model) {
            return !(!value && model.get('getCouriersQuotes'));
          },
          message: 'blank'
        }]
      },
      goodsValueCurrency: {
        custom: [{
          validation: function validation(key, value, model) {
            return !(!value && model.get('getCouriersQuotes'));
          },
          message: 'blank'
        }]
      },
      requiresWorker: {
        custom: [{
          validation: function validation(key, value, model) {
            var fieldObligatory = model.get('transportType.isChoosingWorkerObligatory');
            return !(fieldObligatory && model.get('workers').length < 1);
          },
          message: 'info'
        }]
      }
    },
    STATE_DELETED_NAME: Ember.computed(function () {
      return this.get('intl').t('statuses.deleted');
    }).readOnly(),
    STATE_FINISHED_NAME: Ember.computed(function () {
      return this.get('intl').t('statuses.finished');
    }).readOnly(),
    STATE_MOVED_TO_ARCHIVE_DUE_DELAY: Ember.computed('', function () {
      return this.get('intl').t('statuses.movedToArchiveDueDelay');
    }),
    name: Ember.computed('internalIndex', function () {
      return this.get('internalIndex') ? 'Transport nr ' + this.get('internalIndex') : 'Nowy transport';
    }).readOnly(),
    identifier: Ember.computed('erpIdentifier', 'internalIndex', function () {
      return this.get('erpIdentifier') || this.get('internalIndex');
    }),
    showAdviceDateRangeOnLoad: Ember.computed('advice.id', function () {
      return !this.get('advice.id') && this.get('loadSteps').filterBy('minAdviceDate').get('length') > 0;
    }),
    showAdviceDateRangeOnUnload: Ember.computed('advice.id', function () {
      return !this.get('advice.id') && this.get('unloadSteps').filterBy('minAdviceDate').get('length') > 0;
    }),
    color: Ember.computed('lastUpdated', 'deleted', 'isLate', 'firstUnconfirmedCheckpoint.task.color', 'transportType.workflow.finalColor', function () {
      if (this.get('deleted')) {
        return 'darkgray'; // TODO: [pawel] umożliwić użytkownikowi ustalenie tego koloru w ustawieniach
      }

      if (this.get('archived')) {
        return this.get('transportType.workflow.finalColor'); // TODO: [pawel] umożliwić użytkownikowi ustalenie tego koloru w ustawieniach
      }

      if (this.get('isLate')) {
        return 'red';
      }

      var point = this.get('firstUnconfirmedCheckpoint');
      return point && point.get('id') ? point.get('task.color') : this.get('transportType.workflow.finalColor');
    }).readOnly(),
    backgroundColorStyle: Ember.computed('color', function () {
      return Ember.String.htmlSafe('background-color: ' + this.get('color') + ';');
    }).readOnly(),
    textColorStyle: Ember.computed('color', function () {
      return Ember.String.htmlSafe('color: ' + this.get('color') + ';');
    }).readOnly(),
    stageName: Ember.computed('firstUnconfirmedCheckpoint.stageName', 'transportType.workflow.localisedFinalStageName', function () {
      if (this.get('deleted')) {
        return this.get('STATE_DELETED_NAME');
      } else if (this.get('archived')) {
        if (this.get('completed')) {
          return this.get('STATE_FINISHED_NAME');
        } else {
          return this.get('STATE_MOVED_TO_ARCHIVE_DUE_DELAY');
        }
      }

      var point = this.get('firstUnconfirmedCheckpoint');
      return point && point.get('id') ? point.get('stageName') : this.get('transportType.workflow.localisedFinalStageName');
    }).readOnly(),
    stageNameEnTrimmed: Ember.computed('firstUnconfirmedCheckpoint.stageNameEnTrimmed', function () {
      if (this.get('deleted')) {
        return 'deleted';
      }

      var point = this.get('firstUnconfirmedCheckpoint');

      if (point && point.get('id')) {
        return point.get('stageNameEnTrimmed').toLowerCase();
      }

      return this.get('transportType.workflow.finalStageNameEn') ? this.get('transportType.workflow.finalStageNameEn').replace(/ /g, '-').toLowerCase() : 'process-ended';
    }),
    sortProperty: Ember.computed('earliestTimeWindowStart', 'dateOfArchiving', function () {
      if (this.get('archived')) {
        return this.get('dateOfArchiving');
      } else {
        return this.get('earliestTimeWindowStart');
      }
    }).readOnly(),
    firstStep: Ember.computed('steps.{@each.id,@each.orderInTransport}', function () {
      return this.get('sortedSteps').get('firstObject');
    }).readOnly(),
    lastStep: Ember.computed('steps.{@each.id,@each.orderInTransport}', function () {
      return this.get('sortedSteps').get('lastObject');
    }).readOnly(),
    minAdviceDate: Ember.computed('steps.@each.minAdviceDate', function () {
      return this.get('steps').filterBy('minAdviceDate').sortBy('minAdviceDate').get('firstObject.minAdviceDate');
    }),
    maxAdviceDate: Ember.computed('steps.@each.maxAdviceDate', function () {
      return this.get('steps').filterBy('maxAdviceDate').sortBy('maxAdviceDate').reverse().get('firstObject.maxAdviceDate');
    }),
    entryWeight: Ember.computed('weights.@each.{readOut,enabled}', function () {
      // TODO do ewentualnego wywalenia
      return this.get('weights').filter(function (weight) {
        return weight.get('enabled') && !weight.get('task.isLastWeighingTask');
      }).sortBy('dateCreated').get('firstObject');
    }),
    netWeight: Ember.computed('weights.@each.enabled', function () {
      var weights = this.get('weights').filterBy('enabled');

      if (weights.length > 1) {
        var firstWeight = weights.shift();
        return Math.abs(firstWeight.get('readOut') - weights.getEach('readOut').reduce(function (a, b) {
          return a + b;
        }, 0));
      }

      return null;
    }),
    enabledWeights: Ember.computed('isRelatedTransportInTransportCombining', 'weights', 'weights.@each.enabled', 'masterTransports.firstObject.weights.@each.enabled', function () {
      if (!this.get('isRelatedTransportInTransportCombining')) {
        return this.get('weights').filterBy('enabled');
      }

      var enabledRelatedWeights = Ember.A();
      this.get('masterTransports.firstObject.weights').forEach(function (w) {
        if (w.get('id') && w.get('enabled')) {
          enabledRelatedWeights.pushObject(w);
        }
      });
      return enabledRelatedWeights;
    }),
    hasAutoWeight: Ember.computed('', function () {
      var weightAccess = this.get('sessionAccount').getSettingValue('AUTOMATIC_WEIGHING') === 'true';
      var weightUrl = !!this.get('sessionAccount').getSettingValue('WEIGHT_URL');
      var weightElement = !!this.get('sessionAccount').getSettingValue('WEIGHT_SELECTOR');
      return weightAccess && weightUrl && weightElement;
    }),

    /**
     * Zwraca etap, do którego powinien być przypisany magazyn - o ile jest tylko jeden w zleceniu (a prawie zawsze jest).
     *
     * W przypadku dostaw, serwisów, itp. będzie to ostatni etap - proces kończy się przyjazdem do magazynu klienta.
     * W przypadku pozostałych odbiorów będzie to pierwszy etap - proces rozpoczyna się załadunkiem w magazynie.
     */
    warehouseStep: Ember.computed('firstStep', 'lastStep', 'transportType.typeGroup', function () {
      var transportType = this.get('transportType');

      if (transportType.get('typeGroup') === transportType.get('TYPE_GROUPS.SIMPLIFIED_DELIVERY') || transportType.get('typeGroup') === transportType.get('TYPE_GROUPS.DELIVERY')) {
        return this.get('lastStep.belongsToWarehouse') ? this.get('lastStep') : null;
      } else {
        return this.get('firstStep.belongsToWarehouse') ? this.get('firstStep') : null;
      }
    }),
    stepsWithWindow: Ember.computed('steps.@each.timeWindow', function () {
      return this.get('steps').filterBy('timeWindow.ramp');
    }).readOnly(),
    stepsWithWarehouse: Ember.computed('sortedSteps.@each.belongsToWarehouse', function () {
      return this.get('sortedSteps').filterBy('belongsToWarehouse');
    }),
    stepWarehouses: Ember.computed('stepsWithWarehouse.@each.warehouse', function () {
      return this.get('stepsWithWarehouse').getEach('warehouse');
    }),
    warehousesWithWeighingIncluded: Ember.computed('stepWarehouses.@each.weighingIncluded', function () {
      return this.get('stepWarehouses').filterBy('weighingIncluded', true);
    }).readOnly(),
    weighingTasks: Ember.computed('checkpoints.@each.task', function () {
      return this.get('checkpoints').filterBy('task.weighingIncluded');
    }),
    firstWarehouseStepWithoutWindow: Ember.computed('stepsWithWarehouse.{[],@each.timeWindow}', function () {
      return this.get('stepsWithWarehouse').filter(function (s) {
        return !s.get('timeWindow.start');
      }).get('firstObject');
    }).readOnly(),
    firstChangedWarehouseStep: Ember.computed('stepsWithWarehouse.{[],@each.didWarehouseChange}', function () {
      return this.get('stepsWithWarehouse').filter(function (step) {
        return step.get('didWarehouseChange');
      }).get('firstObject');
    }).readOnly(),
    contractorSteps: Ember.computed('steps.{@each.shouldHaveContractor,@each.company}', function () {
      return this.get('steps').filter(function (step) {
        return step.get('shouldHaveContractor') || step.get('company.id');
      });
    }),
    contractorIds: Ember.computed('steps.@each.company', function () {
      return this.get('contractors').getEach('id');
    }),
    contractors: Ember.computed('steps.{length,@each.company,@each.warehouse}', function () {
      // w typie SIMPLE_DELIVERY mamy jeden step, który zawiera i firmę i magazyn
      if (this.get('steps.length') === 1) {
        return this.get('steps').filterBy('company.id').getEach('company').uniqBy('id');
      } // musimy odfiltrować stepy z magazynem ponieważ w nich uzupełniamy company pryncypałem
      // nie możemy zrobić isPrincipal == false ponieważ contractorem może być też pryncypal np aflofarm


      return this.get('steps').filterBy('company.id').filterBy('warehouse.id', undefined).getEach('company').uniqBy('id');
    }).readOnly(),
    firstContractor: Ember.computed('contractors.firstObject', function () {
      return this.get('contractors').get('firstObject');
    }),
    contractorNames: Ember.computed('contractors.@each.name', function () {
      return this.get('contractors').filterBy('name').getEach('name');
    }).readOnly(),
    sortedSteps: Ember.computed('steps.{[],@each.orderInTransport}', function () {
      var company = this.get('sessionAccount.currentUser.company'); // Jeśli firma jest i dostawcą i przewoźnikiem tzn. że jest operatorem, więc pomijamy ten węzeł.
      // Dzięki temu operator widzi wszystkie stepy w transporcie, a nie tylko te przypisane do jego firmy

      if (company.get('isSupplier') && !company.get('isCarrier')) {
        return this.get('steps').filter(function (s) {
          return s.get('enabled') && (s.get('company.id') === company.get('id') || s.get('belongsToWarehouse'));
        }).sortBy('orderInTransport');
      }

      return this.get('steps').sortBy('orderInTransport');
    }),
    loadSteps: Ember.computed('sortedSteps.{[],@each.isLoad}', function () {
      return this.get('sortedSteps').filterBy('isLoad');
    }).readOnly(),
    loadWarehouses: Ember.computed('stepsWithWarehouse.@each.{isLoad,warehouse}', function () {
      return this.get('stepsWithWarehouse').filterBy('isLoad').getEach('warehouse').uniqBy('id');
    }),
    loadTimeWindows: Ember.computed('timeWindows.@each.isLoad', function () {
      return this.get('timeWindows').filterBy('isLoad');
    }),
    unloadSteps: Ember.computed('sortedSteps.{[],@each.isUnload}', function () {
      return this.get('sortedSteps').filterBy('isUnload');
    }).readOnly(),
    unloadWarehouses: Ember.computed('stepsWithWarehouse.{@each.isUnload,@each.warehouse}', function () {
      return this.get('stepsWithWarehouse').filterBy('isUnload').getEach('warehouse').uniqBy('id');
    }),
    unloadTimeWindows: Ember.computed('timeWindows.@each.isUnload', function () {
      return this.get('timeWindows').filterBy('isUnload');
    }),
    warehouse: Ember.computed('stepsWithWarehouse.firstObject.warehouse', function () {
      return this.get('stepsWithWarehouse.firstObject.warehouse');
    }),
    customFieldPairs: Ember.computed('customFields.@each.definition', 'transportType.customFieldDefinitions.@each.id', function () {
      var definitions = this.get('transportType.customFieldDefinitions').filterBy('id').filterBy('enabled').filterBy('canBeViewed').sortBy('idx');
      var values = this.get('customFields');
      var pairs = [];

      var _loop = function _loop(i) {
        var definition = definitions.objectAt(i);
        var value = values.find(function (v) {
          return v.get('definition.id') === definition.get('id');
        });
        pairs[i] = {
          value: value,
          definition: definition
        };
      };

      for (var i = 0; i < definitions.get('length'); i++) {
        _loop(i);
      }

      return pairs.sortBy('definition.idx');
    }).readOnly(),
    customFieldOptions: Ember.computed('customFields.@each.option', function () {
      return this.get('customFields').getEach('option');
    }),
    hasDiscrepancyOptionsActive: Ember.computed('customFieldOptions.@each.allowsDiscrepancyReport', function () {
      return this.get('customFieldOptions').filterBy('allowsDiscrepancyReport').length > 0;
    }),
    hasInspectionPanelActive: Ember.computed('customFieldOptions.@each.allowsDiscrepancyReport', function () {
      return this.get('customFieldOptions').filterBy('allowsInspectionPanel').length > 0;
    }),
    browserOld: Ember.computed(function () {
      return (0, _jquery.default)('html').is('.old');
    }).readOnly(),
    belongsToContractors: function belongsToContractors(company) {
      return !!this.get('contractors').findBy('id', company.get('id'));
    },
    lastConfirmedCheckpoint: Ember.computed('checkpoints.@each.executionDate', function () {
      return this.get('checkpoints').filterBy('executionDate').sortBy('idx').get('lastObject');
    }),
    lastConfirmedCheckpointWithWeighingIncluded: Ember.computed('checkpoints.@each.executionDate', function () {
      var self = this;
      var weighingCheckpoints = this.get('store').peekAll('checkpoint').filter(function (checkpoint) {
        return checkpoint.get('transport.id') === self.get('id') && checkpoint.get('task.weighingIncluded');
      }).filterBy('executionDate').sortBy('idx');

      if (weighingCheckpoints.get('length') === 0) {
        return null;
      }

      return weighingCheckpoints.get('lastObject');
    }),
    title: Ember.computed('archived', function () {
      return this.get('transportType').get('label');
    }).readOnly(),
    formattedCities: Ember.computed('sortedSteps.@each.city', function () {
      return this.get('sortedSteps').getEach('city').join(' - ');
    }),
    sortedCheckpoints: Ember.computed('checkpoints.@each.{idx,isRejected}', function () {
      return this.get('checkpoints').sortBy('idx');
    }).readOnly(),
    checkpointsThatCanBeConfirmedByGuard: Ember.computed('sortedCheckpoints.{[],@each.step}', function () {
      var warehouseIds = this.get('sessionAccount.currentUser.warehouses').map(function (w) {
        return w.get('id');
      });
      return this.get('sortedCheckpoints').filterBy('task.canBeConfirmedByGuard').filter(function (c) {
        return warehouseIds.includes(c.get('step.warehouse.id'));
      });
    }).readOnly(),
    checkpointsThatCanBeConfirmedByGuardRole: Ember.computed('sortedCheckpoints.{[],@each.step}', function () {
      return this.get('sortedCheckpoints').filterBy('task.canBeConfirmedByGuard');
    }).readOnly(),

    /**
     * Numery WZ
     */
    documentIdentifiers: Ember.computed('steps.@each.documentIdentifier', function () {
      return this.get('steps').filterBy('documentIdentifier').getEach('documentIdentifier').join(', ');
    }).readOnly(),
    creatorNameToDisplay: Ember.computed('creatorName', 'creator.name', function () {
      return this.get('creatorName') || this.get('creator.name');
    }).readOnly(),
    hasDiscrepancyReportCustomFieldsErrors: Ember.computed('customFields.@each.numberOfErrors', function () {
      var customFields = this.get('customFields').filter(function (field) {
        return field.definition.get('visibleInDiscrepancyReportSection') === true;
      });
      return customFields.filterBy('numberOfErrors').get('length') > 0;
    }),
    hasErrors: Ember.computed('errors.length', 'advice.errors.length', 'customFields.@each.numberOfErrors', 'indexes.@each.hasErrors', 'courierPackages.@each.hasErrors', 'steps.@each.numberOfErrors', 'timeWindows.@each.numberOfErrors', function () {
      if (this.get('errors.length') > 0 || this.get('advice.errors.length') > 0) {
        return true;
      }

      this.get('customFields').filterBy('numberOfErrors').forEach(function (f) {
        console.debug("Custom field \"".concat(f.get('definition.name'), "\" has validation errors."));
      });

      if (this.get('customFields').filterBy('numberOfErrors').get('length') > 0) {
        return true;
      }

      if (this.get('indexes').filterBy('hasErrors').get('length') > 0) {
        return true;
      }

      if (this.get('courierPackages').filterBy('enabled').filterBy('hasErrors').get('length') > 0) {
        return true;
      }

      if (this.get('steps').filterBy('numberOfErrors').get('length') > 0) {
        return true;
      }

      var stepPackageTypes = this.get('steps').getEach('stepPackageTypes').reduce(function (a, b) {
        return a.concat(b);
      }, []);

      if (stepPackageTypes.filterBy('numberOfErrors').get('length') > 0) {
        return true;
      }

      return this.get('timeWindows').filterBy('numberOfErrors').get('length') > 0;
    }).readOnly(),
    activeAuction: Ember.computed('auctions.@each.finished', function () {
      return this.get('auctions').filterBy('finished', false).sortBy('id').get('lastObject');
    }),
    previouslyActiveAuction: Ember.computed('auctions.@each.finished', function () {
      var activeAuction = this.get('activeAuction');
      return this.get('auctions').filterBy('finished', false).filter(function (a) {
        return a.get('id') !== activeAuction.get('id');
      }).sortBy('id').get('lastObject');
    }),
    showPrice: Ember.computed('transaction.{id,carrier,isRejected}', 'sessionAccount.currentUser.company', function () {
      var company = this.get('sessionAccount.currentUser.company');
      var transactionCreated = !!this.get('transaction.id');

      if (this.get('transaction.isRejected')) {
        return false;
      }

      if (company.get('isPrincipal') || this.get('transaction.carrier.id') === company.get('id')) {
        return transactionCreated;
      }

      return false;
    }),
    // TODO: dodawać transakcję przy kończeniu aukcji?
    priceWithCurrency: Ember.computed('transaction.{price,currency.symbol}', function () {
      var price = this.get('transaction.price');
      var currency = this.get('transaction.currency.symbol');

      if (price === undefined || price === null) {
        return '';
      }

      return price + ' ' + currency;
    }),
    active: Ember.computed('archived', 'deleted', function () {
      return !this.get('archived') && !this.get('deleted');
    }),
    summaryQuantity: Ember.computed('steps.@each.quantity', function () {
      return this.get('steps').filterBy('quantity').getEach('quantity').reduce(function (acc, q) {
        return acc + q;
      }, 0);
    }),
    enabledIndexes: Ember.computed('indexes.@each.{enabled,sortValue}', function () {
      return this.get('indexes').filterBy('enabled').sortBy('sortValue');
    }),
    enabledPackages: Ember.computed('courierPackages.@each.enabled', 'packages.@each.sortValue', function () {
      return this.get('courierPackages').filterBy('enabled').sortBy('sortValue');
    }),
    enabledPackagesCount: Ember.computed('courierPackages.@each.{enabled,numberOfPackages}', function () {
      var pckgs = this.get('courierPackages').filterBy('enabled');
      var count = 0;
      pckgs.forEach(function (it) {
        count += Number(it.numberOfPackages);
      });

      if (count === 0) {
        return '-';
      } else {
        return count;
      }
    }),
    enabledPackagesWeight: Ember.computed('courierPackages.@each.{enabled,packageWeight,numberOfPackages}', function () {
      var pckgs = this.get('courierPackages').filterBy('enabled');
      var count = 0.0;
      pckgs.forEach(function (it) {
        count += Number(Number(it.packageWeight) * Number(it.numberOfPackages));
      });

      if (count === 0) {
        return '-';
      } else {
        return count;
      }
    }),
    enabledPackagesValue: Ember.computed('enabledPackages.@each.totalValue', function () {
      var totalValue = this.get('enabledPackages').getEach('totalValue').reduce(function (a, b) {
        return parseFloat(a) + parseFloat(b);
      }, 0);
      return totalValue.toFixed(2) || 0;
    }),
    indexesTransportNumbersAsString: Ember.computed('indexes.@each.{transportNumber,enabled}', function () {
      return this.get('indexes').filterBy('transportNumber').getEach('transportNumber').uniq().join(', ');
    }),
    indexesDocumentNumbersAsString: Ember.computed('indexes.@each.{documentNumber,enabled}', function () {
      return this.get('indexes').filterBy('documentNumber').getEach('documentNumber').uniq().join(', ');
    }),
    numberOfNotes: Ember.computed('notes.length', function () {
      return this.hasMany('notes').ids().length;
    }),
    numberOfFiles: Ember.computed('files.length', function () {
      return this.hasMany('files').ids().length;
    }),
    numberOfDiscrepancyReports: Ember.computed('discrepancyReports.length', function () {
      return this.hasMany('discrepancyReports').ids().length;
    }),
    numberOfAllDocuments: Ember.computed('numberOfFiles', 'numberOfDiscrepancyReports', function () {
      return this.get('numberOfFiles') + this.get('numberOfDiscrepancyReports');
    }),
    showQuantitySection: Ember.computed('transportType.{hasVisibleSteps,isQuantityVisibleInContractorStep}', 'steps.@each.id', function () {
      var _this = this;

      if (this.get('transportType.hasVisibleSteps')) {
        return false;
      }

      return this.get('store').peekAll('packageTypeTransportType').filter(function (pttt) {
        return pttt.get('transportType.id') === _this.get('transportType.id');
      }).some(function (pttt) {
        return pttt.get('visibleInContractorStep') || pttt.get('visibleInWarehouseStep');
      });
    }),
    showWeightField: Ember.computed('transportType.{hasVisibleSteps,isWeightVisibleInContractorStep}', 'steps.@each.id', function () {
      if (this.get('transportType.hasVisibleSteps')) {
        return false;
      }

      return this.get('transportType.isWeightVisibleInContractorStep');
    }),
    showClientInWindowDetailsDialog: Ember.computed('sortedSteps.@each.company', 'customFieldPairs.@each.definition', function () {
      return this.get('sortedSteps').some(function (step) {
        return step.get('company.id');
      }) || this.get('customFieldPairs').some(function (customField) {
        return customField.definition.get('name') === 'Incoterms';
      });
    }),
    hasAnyWzNumbers: Ember.computed('', function () {
      return this.get('unloadSteps').some(function (step) {
        return step.get('documentIdentifier');
      });
    }),
    canBeDeleted: Ember.computed('transportType.authorityRestrictions.@each.authorityRange', 'earliestTimeWindowStart', 'firstUnconfirmedCheckpoint', function () {
      return this.actionCanBePerformed('DELETE_TRANSPORT');
    }),
    canBeUpdated: Ember.computed('id', 'transportType.authorityRestrictions.@each.authorityRange', 'earliestTimeWindowStart', 'firstUnconfirmedCheckpoint', 'activeAuction', function () {
      if (!this.get('id')) {
        return true;
      }

      return this.actionCanBePerformed('UPDATE_MAIN_DATA_SECTION');
    }),
    disabled: Ember.computed('canBeUpdated', function () {
      return !this.get('canBeUpdated');
    }),
    actionCanBePerformed: function actionCanBePerformed(actionType) {
      var _this2 = this;

      var authorityRestrictions = this.get('transportType.content').findPositiveAuthorityRestrictionsByActionType(actionType);
      return authorityRestrictions.get('length') !== 0 && authorityRestrictions.every(function (ar) {
        return ar.conditionIsFulfilled(_this2);
      });
    },
    logString: Ember.computed('id', 'internalIndex', 'erpIdentifier', function () {
      return "#".concat(this.get('id'), " / ").concat(this.get('internalIndex'), " / ERP ").concat(this.get('erpIdentifier'));
    }),
    relatedToLabel: Ember.computed('internalIndex', 'erpIdentifier', function () {
      if (!this.get('internalIndex')) {
        return '';
      }

      return this.get('erpIdentifier') ? "".concat(this.get('internalIndex'), " (").concat(this.get('erpIdentifier'), ")") : "".concat(this.get('internalIndex'));
    }),
    timeWindowLoadSortProperty: Ember.computed('earliestTimeWindowStart', 'showAdviceDateRangeOnLoad', 'minAdviceDate', function () {
      return this.timeWindowSortProperty('load');
    }),
    timeWindowUnloadSortProperty: Ember.computed('earliestTimeWindowStart', 'showAdviceDateRangeOnUnload', 'minAdviceDate', function () {
      return this.timeWindowSortProperty('unload');
    }),
    timeWindowSortProperty: function timeWindowSortProperty(stepType) {
      var _this3 = this;

      var showAdviceDatePropertyName = stepType === 'load' ? 'showAdviceDateRangeOnLoad' : 'showAdviceDateRangeOnUnload';

      if (this.get(showAdviceDatePropertyName)) {
        return this.get('minAdviceDate');
      }

      var steps = stepType === 'load' ? this.get('loadSteps') : this.get('unloadSteps');
      return steps.map(function (s) {
        return s.get('isInWarehouse') ? _this3.get('earliestTimeWindowStart') : s.get('arrivalDate');
      }).filter(function (date) {
        return date !== null;
      }).sort(function (a, b) {
        return a.date - b.date;
      })[0];
    },
    transportWithoutWarehouses: Ember.computed('warehouse', 'unloadWarehouses', 'loadWarehouses', function () {
      return Ember.isNone(this.warehouse) && Ember.isEmpty(this.unloadWarehouses) && Ember.isEmpty(this.loadWarehouses);
    }).readOnly(),
    findAllRelatedCompanies: function findAllRelatedCompanies() {
      return [this.get('carrierCompany')].concat(_toConsumableArray(this.get('contractors')));
    },
    checkIfEditionViolatesPeriodicTransportRules: function checkIfEditionViolatesPeriodicTransportRules(oldCompany, newCompany) {
      if (!this.get('periodicTransportActive')) {
        return false;
      }

      if (newCompany && newCompany.get('allowsPeriodicTransport') || !oldCompany || !oldCompany.get('allowsPeriodicTransport')) {
        return false;
      }

      var allRelatedCompanies = this.findAllRelatedCompanies();
      var removedCompanyIndex = allRelatedCompanies.findIndex(function (c) {
        return c.get('id') === oldCompany.get('id');
      });
      allRelatedCompanies.splice(removedCompanyIndex, 1);
      return allRelatedCompanies.every(function (c) {
        return !c.get('allowsPeriodicTransport');
      });
    },
    stepCssClass: Ember.computed('steps.length', function () {
      if (this.get('transportType.alternativeStepsLayout')) {
        return;
      }

      var suffix = this.get('sortedSteps.length') < 4 ? this.get('sortedSteps.length') : 'more';
      return "step-columns-".concat(suffix);
    }),
    maxTransportDate: Ember.computed('timeWindows.@each.stop', 'steps.@each.{arrivalDate,maxAdviceDate}', function () {
      var maxArrivalDate = this.get('steps').filter(function (s) {
        return s.get('arrivalDate');
      }).sortBy('arrivalDate').get('lastObject.arrivalDate');
      var maxNoWindowRange = this.get('steps').filter(function (s) {
        return !s.get('isInWarehouse') && s.get('maxAdviceDate');
      }).sortBy('maxAdviceDate').get('lastObject.maxAdviceDate');
      var maxTimeWindowStop = this.get('timeWindows').filterBy('stop').sortBy('stop').reverse().get('firstObject.stop');
      var dates = [maxArrivalDate, maxTimeWindowStop, maxNoWindowRange].filter(function (d) {
        return d;
      });
      return new Date(Math.max.apply(Math, _toConsumableArray(dates)));
    }),
    firstMandatoryCheckpoint: Ember.computed('checkpoints.[]', function () {
      var checkpointsSortedByIdx = this.get('sortedCheckpoints');
      var firstUnconfirmedCheckpointIdx = this.get('firstUnconfirmedCheckpoint.idx');
      return checkpointsSortedByIdx.find(function (checkpoint) {
        return checkpoint.get('canBeSkipped') === false && checkpoint.get('idx') > firstUnconfirmedCheckpointIdx;
      });
    }),
    carrierSortProperty: Ember.computed('carrierCompany.shortName', function () {
      return this.get('carrierCompany.shortName');
    }),
    stepTypePackageTypeQuantities: function stepTypePackageTypeQuantities(stepType) {
      var result = {};
      stepType.map(function (step) {
        return step.get('stepPackageTypes').reduce(function (countMap, spt) {
          var packageName = spt.get('packageType.name');
          var quantity = spt.get('quantity') || 0;
          countMap[packageName] = (countMap[packageName] || 0) + Number(quantity);
          result[packageName] = (result[packageName] || 0) + Number(quantity);
          return countMap;
        }, {});
      }, {});
      return result;
    },
    loadPackageTypeQuantities: Ember.computed('stepPackageTypes.@each.quantity', function () {
      var loadSteps = this.get('loadSteps');
      return this.stepTypePackageTypeQuantities(loadSteps);
    }),
    unloadPackageTypeQuantities: Ember.computed('stepPackageTypes.@each.quantity', function () {
      var unloadSteps = this.get('unloadSteps');
      return this.stepTypePackageTypeQuantities(unloadSteps);
    }),
    isCombinedTransport: Ember.computed('isMasterTransportInTransportCombining', 'isRelatedTransportInTransportCombining', function () {
      return this.get('isMasterTransportInTransportCombining') || this.get('isRelatedTransportInTransportCombining');
    }),
    totalWeightOfCombinedTransports: Ember.computed('steps.@each.weight', {
      get: function get() {
        var _this4 = this;

        var combinedTransports = this.get('allCombinedTransports');
        var summedWeight = 0;

        if (combinedTransports.length !== 0) {
          combinedTransports.forEach(function (transport) {
            transport.get('unloadSteps').forEach(function (step) {
              if (!step || !step.get('weight')) {
                return;
              }

              summedWeight += step.get('weight');
            });
          });
          combinedTransports.forEach(function (transport) {
            if (transport === _this4) {
              return;
            }

            transport.set('totalWeightOfCombinedTransports', summedWeight);
          });
        } else {
          this.get('unloadSteps').forEach(function (step) {
            if (!step || !step.get('weight')) {
              return;
            }

            summedWeight += step.get('weight');
          });
        }

        return summedWeight;
      },
      set: function set(key, value) {
        return value;
      }
    }),
    masterTransportsAdvices: Ember.computed('masterTransports.@each.advice', function () {
      return this.get('masterTransports').map(function (mt) {
        return mt.get('advice');
      }) || Ember.A([]);
    }),
    hasAdvice: Ember.computed('masterTransportsAdvices', 'advice.id', function () {
      return this.get('advice.id') || this.get('masterTransportsAdvices').some(function (a) {
        return a.get('id');
      });
    })
  });

  _exports.default = _default;
});