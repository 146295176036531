define("apollo/pods/components/transport/courier/add-packages-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sessionAccount: service('session-account'),
    actions: {
      addPackage: function addPackage() {
        var newPackage = this.get('store').createRecord('courierPackage', {
          transport: this.get('transport')
        });
        this.get('transport.courierPackages').addObject(newPackage);
        this.set("action", "showSubModal");
        this.sendAction('action', 'courier.modals.package-form', newPackage);
      }
    }
  });

  _exports.default = _default;
});