define("apollo/helpers/disable-company-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    sessionAccount: Ember.inject.service('session-account'),
    // Poziomy blokady:
    // - SIMPLE: zwykłe pole, podstawowe warunki do blokady
    // - OWN_COMPANY: jeśli użytkownik ma rolę `ROLE_EDIT_COMPANY` może edytować dowolną firmę, jeśli ma `ROLE_EDIT_MY_OWN_COMPANY` - tylko swoją
    // - SENSITIVE: SIMPLE + uprawnienie do edycji pól wrażliwych ("ROLE_FULL_EDIT_COMPANY")
    // - SPECIAL: szczególny zestaw ograniczeń, specyficzny dla danego pola
    BLOCKADE_LEVELS: {
      SIMPLE: ["patron", "allowsPeriodicTransport"],
      OWN_COMPANY: ["addAddress", "shortName", "street", "city", "postal", "country", "contractorNumber"],
      SENSITIVE: ["taxIdNumber", "regon", "krs", "legalStatus"],
      SPECIAL: ["name", "pricingPlan", "maxNumberOfAccounts", "adviceVerification", "adviceVerificationInterval"]
    },
    // Parametry:
    // - firma do blokady: company (obiekt)
    // - czy blokujemy firmę z applies: forApplies (true/false)
    // - nazwa pola, które chcemy zablokować: fieldName (string)
    // - czy rejestracja w toku (state) - NIE JEST WYKORZYSTYWANE, ale musimy to przekazać, żeby helper po zmianie stanu
    //   potrafił się przeliczyć - przy zmianie stanu obiket `company` się nie zmienia, więc musimy niestety sztucznie przekazać stan
    compute: function compute(params, nameArgs) {
      var company = nameArgs.company;
      var forApplies = nameArgs.forApplies;
      var fieldName = nameArgs.fieldName;
      var blockadeLevel = this.findBlockadeLevel(fieldName);
      var disableFunc = this.findDisableFunction(blockadeLevel, fieldName);
      return disableFunc(this, company, forApplies);
    },
    findBlockadeLevel: function findBlockadeLevel(fieldName) {
      var _this = this;

      var levels = Object.keys(this.BLOCKADE_LEVELS);
      return levels.find(function (level) {
        var fields = _this.BLOCKADE_LEVELS[level];
        return fields.includes(fieldName);
      }) || 'SENSITIVE';
    },
    findDisableFunction: function findDisableFunction(blockadeLevel, fieldName) {
      switch (blockadeLevel) {
        case "SIMPLE":
          return this.simpleDisableFunc;

        case "OWN_COMPANY":
          return this.ownCompanyDisableFunc;

        case "SENSITIVE":
          return this.sensitiveDisableFunc;

        case "SPECIAL":
          return this.findSpecialDisableFunction(fieldName);

        default:
          return this.disableAllFunc;
      }
    },
    findSpecialDisableFunction: function findSpecialDisableFunction(fieldName) {
      switch (fieldName) {
        case "name":
          return this.nameDisableFunc;

        case "pricingPlan":
          return this.pricingPlanDisableFunc;

        case "maxNumberOfAccounts":
          return this.maxNumberOfAccountsDisableFunc;

        case "adviceVerification":
        case "adviceVerificationInterval":
          return this.adviceVerificationDisableFunc;

        default:
          return this.disableAllFunc;
      }
    },
    simpleDisableFunc: function simpleDisableFunc(self, company, forApplies) {
      // W przypadku applies i zgłoszenia w trakcie rejestracji nie blokujemy pola
      if (forApplies && company.get('registrationInProgress')) {
        return false;
      } // zalogowany użytkownik jest pryncypałem


      var isCurrentPrincipal = self.get('sessionAccount.currentUser.company.isPrincipal'); // zalogowany użytkownik musi posiadać role "ROLE_EDIT_COMPANY" LUB "ROLE_EDIT_MY_OWN_COMPANY" (gdy edytuje własną firmę)

      var hasProperRole = self.get('sessionAccount').hasRole("ROLE_EDIT_COMPANY") || Number(company.get('id')) === Number(self.get('sessionAccount.currentUser.companyId')) && self.get('sessionAccount').hasRole("ROLE_EDIT_MY_OWN_COMPANY");
      return !isCurrentPrincipal || !hasProperRole;
    },
    ownCompanyDisableFunc: function ownCompanyDisableFunc(self, company, forApplies) {
      // jeśli użytkownik ma ogólne uprawnienie do edycji, może edytować dowolną firmę
      if (self.get('sessionAccount').hasRole("ROLE_EDIT_COMPANY")) {
        return false;
      } // w przypadku applies i zgłoszenia w trakcie rejestracji nie blokujemy pola
      // w przypadku firmy nie z appliesów - blokujemy zawsze


      var hasProperStatus = forApplies && company.get('registrationInProgress'); // jeśli ma uprawnienie do edycji swojej firmy i próbuje edytować swoją firmę, to może

      var hasProperRole = Number(company.get('id')) === Number(self.get('sessionAccount.currentUser.company.id')) && self.get('sessionAccount').hasRole("ROLE_EDIT_MY_OWN_COMPANY");
      return !hasProperStatus || !hasProperRole;
    },
    sensitiveDisableFunc: function sensitiveDisableFunc(self, company, forApplies) {
      // W przypadku applies i zgłoszenia w trakcie rejestracji nie blokujemy pola
      if (forApplies && company.get('registrationInProgress')) {
        return false;
      } // musza być spełnione minimalne warunki na edycję - rola do edycji + user od aktualnego pryncypała


      var simpleDisable = self.simpleDisableFunc(self, company, forApplies); // zalogowany użytkownik musi posiadać dodatkowo rolę "ROLE_FULL_EDIT_COMPANY"

      var hasProperRole = self.get('sessionAccount').hasRole("ROLE_FULL_EDIT_COMPANY");
      return simpleDisable || !hasProperRole;
    },
    nameDisableFunc: function nameDisableFunc(self, company, forApplies) {
      // przy zapisie nowej firmy blokujemy pole z nazwą tylko jeśli został wybrany kraj PL i nie został uzupełniony nr NIP
      if (!company.get('id')) {
        return company.get('country.ibanSymbol') === 'PL' && !company.get('taxIdNumber');
      } // w przypadku edycji:
      // muszą być spełnione warunki na edycję wrażliwych danych


      var sensitiveDisable = self.sensitiveDisableFunc(self, company, forApplies); // jeśli firma jest zweryfikowana w GUS - także uprawnienie "ROLE_EDIT_GUS_DATA"

      var gusConstraint = !company.get('gusVerified') || self.get('sessionAccount').hasRole("ROLE_EDIT_GUS_DATA");
      return sensitiveDisable || !gusConstraint;
    },
    pricingPlanDisableFunc: function pricingPlanDisableFunc(self, company, forApplies) {
      return self.simpleDisableFunc(self, company, forApplies) || !self.get('sessionAccount').hasRole("ROLE_MANAGE_COMPANY_PRICING_PLAN");
    },
    maxNumberOfAccountsDisableFunc: function maxNumberOfAccountsDisableFunc(self, company, forApplies) {
      return self.simpleDisableFunc(self, company, forApplies) || !self.get('sessionAccount').hasRole("ROLE_MANAGE_MAX_COMPANY_ACCOUNT_NUMBER");
    },
    adviceVerificationDisableFunc: function adviceVerificationDisableFunc(self, company, forApplies) {
      return self.simpleDisableFunc(self, company, forApplies) || !self.get('sessionAccount').hasRole("ROLE_MANAGE_ADVICE_VERIFICATION_MAIL");
    },
    disableAllFunc: function disableAllFunc() {
      return true;
    }
  });

  _exports.default = _default;
});