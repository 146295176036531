define("apollo/pods/weights/modals/overweight/controller", ["exports", "apollo/pods/weights/modals/form/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    intl: Ember.inject.service(),
    actions: {
      close: function close() {
        this.send('hideModal');
      }
    }
  });

  _exports.default = _default;
});