define("apollo/pods/components/transport/indexes-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Component.extend({
    store: service(),
    intl: service(),
    sessionAccount: service('session-account'),
    showAddIndexesSection: Ember.computed('', function () {
      if (this.get('hideAddIndexesSection')) {
        return false;
      }

      return !this.get('asPlainTemplate') && !this.get('isEditionDisabled');
    }),
    units: Ember.computed('', function () {
      return ['KG', 'LITERS', 'PIECES'];
    }),
    quantityTypes: Ember.computed('transport.transportType.indexQuantityTypes', function () {
      return this.get('transport.transportType.indexQuantityTypes');
    }),
    canExportIndexes: Ember.computed('transport.id', 'transport.indexes.length', function () {
      return this.get('transport.id') && this.get('transport.indexes.length') > 0 && this.get('sessionAccount').getSettingValue('CAN_EXPORT_INDEXES_TO_XLS') === "true";
    }),
    hasUnsavedChanges: Ember.computed('transport.indexes.@each.hasChanges', function () {
      return this.get('transport.indexes').filterBy('hasChanges').get('length') > 0;
    }),
    editionOfIncompleteLoadingDisabled: Ember.computed('transport.canBeUpdated', function () {
      return !this.get('sessionAccount').hasRole('ROLE_EDIT_INCOMPLETE_LOADING') || !this.get('transport.canBeUpdated');
    }),
    isEditionDisabled: Ember.computed('transport.disabled', function () {
      var transport = this.get('transport').actionCanBePerformed ? this.get('transport') : this.get('transport.content');
      return !transport.actionCanBePerformed('EDIT_INDEX');
    }),
    isDeletionAllowed: Ember.computed('model.id', 'transport.transportType', function () {
      if (!this.get('transport.id')) {
        return false;
      }

      var transport = this.get('transport').actionCanBePerformed ? this.get('transport') : this.get('transport.content');
      return transport.actionCanBePerformed('DELETE_INDEX');
    }),
    editionOrDeletionIsAllowed: Ember.computed('isDeletionAllowed', 'isEditionDisabled', function () {
      return !this.get('isEditionDisabled') || this.get('isDeletionAllowed');
    }),
    indexSetting: Ember.computed('transport.transportType.indexSetting', function () {
      return this.get('transport.transportType.indexSetting');
    }),
    availableContractorSteps: Ember.computed('transport.contractorSteps', function () {
      return this.get('transport.contractorSteps').filterBy('id');
    }),
    actions: {
      togglePanel: function togglePanel() {
        this.toggleProperty('isOpened');
      },
      setStep: function setStep(index, step) {
        index.set('step', step);
      },
      editIndex: function editIndex(index) {
        this.set("action", "showSubModal");
        this.sendAction('action', 'indexes.modals.index-form', index);
      },
      setProductData: function setProductData(index, productData) {
        this.get('store').findRecord('product', productData.id).then(function (product) {
          index.setProperties({
            product: product
          });
          index.get('quantities').filterBy('indexQuantityType.requiresUnit').forEach(function (q) {
            return q.set('unit', product.get('unit'));
          });
        });
      },
      removeProduct: function removeProduct(index) {
        index.setProperties({
          product: null
        });
      },
      disableIndex: function disableIndex(index) {
        var msg = this.get('indexSetting.disableConfirmation');

        if (!confirm(msg)) {
          return;
        }

        if (index.get('id')) {
          index.set('enabled', false);
        } else {
          var transport = index.get('transport');
          transport.get('indexes').removeObject(index);
        }
      },
      showSubModal: function showSubModal(templateName, model) {
        this.set("action", "showSubModal");
        this.sendAction('action', templateName, model);
      },
      toggleIncompleteLoading: function toggleIncompleteLoading() {
        this.get('transport').toggleProperty('incompleteLoading');

        if (!this.get('transport.incompleteLoading')) {
          this.get('transport.indexes').forEach(function (index) {
            index.get('quantities').filterBy('indexQuantityType.enabledForIncompleteLoading').forEach(function (q) {
              return q.set('quantity', null);
            });
          });
        }
      }
    }
  });

  _exports.default = _default;
});