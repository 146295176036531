define("apollo/pods/transport-types/artr-settings/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    access: Ember.A(['ROLE_MANAGE_ARTR_SETTINGS']),
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      var transportTypeId = params.id;
      var transportType = this.store.peekRecord('transport-type', transportTypeId);
      var artrSetting = this.store.queryRecord('artrSetting', {
        transportTypeId: transportTypeId
      });
      return Ember.RSVP.hash({
        transportType: transportType,
        artrSetting: artrSetting
      });
    },
    actions: {
      willTransition: function willTransition() {
        // eslint-disable-next-line ember/no-controller-access-in-routes
        this.get('controller').setProperties({
          model: null,
          errors: null,
          successMessage: null
        });
      },
      refreshModel: function refreshModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});