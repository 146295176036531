define("apollo/helpers/place-button-style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.placeButtonStyle = placeButtonStyle;
  _exports.default = void 0;

  function placeButtonStyle(params, namedArgs) {
    var resource = namedArgs.resource;
    var place = namedArgs.place;
    var transportBackgroundColor = resource.get('transport.backgroundColorStyle');
    var isResourceActive = resource.get('active');
    var currentPlaceIsAssigned = resource.get('place.id') === place.get('id');
    var hasTransport = resource.get('transport.id');

    if (!resource.get('transport.id') && resource.get('place.id') === place.get('id')) {
      return Ember.String.htmlSafe('background-color: steelblue;');
    } else if ((currentPlaceIsAssigned || isResourceActive) && hasTransport) {
      return transportBackgroundColor;
    } else {
      return Ember.String.htmlSafe('background-color: lightgray;');
    }
  }

  var _default = Ember.Helper.helper(placeButtonStyle);

  _exports.default = _default;
});