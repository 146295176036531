define("apollo/pods/vehicle-main-section/model", ["exports", "ember-data", "apollo/pods/section/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = _model.default.extend({
    truck: _emberData.default.belongsTo('truck', {
      async: true,
      deepEmbedded: ['owner']
    }),
    brand: _emberData.default.attr('string'),
    subcontractor: _emberData.default.attr('string'),
    serialNumberOfRecorder: _emberData.default.attr('string'),
    validations: {
      truck: {
        relations: ['belongsTo']
      },
      brand: {
        presence: {
          message: 'blank'
        }
      },
      subcontractor: {
        presence: {
          message: 'blank'
        }
      },
      serialNumberOfRecorder: {
        presence: {
          message: 'blank'
        }
      }
    }
  });

  _exports.default = _default;
});