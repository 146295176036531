define("apollo/pods/transport-types/cmr-settings/controller", ["exports", "apollo/mixins/response-util-mixin"], function (_exports, _responseUtilMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_responseUtilMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    cmrSetting: Ember.computed('model', function () {
      return this.get('model.cmrSetting');
    }),
    transportType: Ember.computed('model.transportType.id', function () {
      return this.get('model.transportType');
    }),
    customFieldDefinitions: Ember.computed('model.transportType.sortedFieldDefinitions', function () {
      return Ember.A(this.get('transportType.sortedFieldDefinitions'));
    }),
    actions: {
      save: function save() {
        var _this = this;

        this.set('errors', []);
        var cmrSetting = this.get('cmrSetting');
        cmrSetting.set('transportType', this.get('transportType'));

        if (!cmrSetting.validate()) {
          return;
        }

        cmrSetting.save().then(function () {
          _this.send('refreshModel');

          var msg = _this.get('intl').t('common.saveSuccessMessage');

          _this.set('successMessage', msg);

          jQuery("html, body").animate({
            scrollTop: 0
          }, "slow");
        }).catch(function (response) {
          _this.set('errors', response.errors);

          jQuery("html, body").animate({
            scrollTop: 0
          }, "slow");
        });
      },
      create: function create() {
        if (this.get('cmrSetting')) {
          return;
        }

        var transportType = this.get('transportType');
        this.set('cmrSetting', this.get('store').createRecord('cmr-setting', {
          transportType: transportType
        }));
      },
      delete: function _delete() {
        var _this2 = this;

        this.set('errors', []);
        this.get('cmrSetting').destroyRecord().then(function () {
          return _this2.handleSuccessResponse(_this2);
        }).catch(function (response) {
          return _this2.handleErrorResponse(_this2, response);
        });
      },
      cancel: function cancel() {
        this.get('cmrSetting').rollbackAttributes();
        this.transitionToRoute('workflows.edit', this.get('transportType.workflow.id'));
      },
      setAppendedDocsSpecificationCustomField: function setAppendedDocsSpecificationCustomField(cfd) {
        this.set('cmrSetting.appendedDocsSpecificationCustomField', cfd);
      },
      setFeaturesAndNumbersCustomField: function setFeaturesAndNumbersCustomField(cfd) {
        this.set('cmrSetting.featuresAndNumbersCustomField', cfd);
      },
      setQuantityCustomField: function setQuantityCustomField(cfd) {
        this.set('cmrSetting.quantityCustomField', cfd);
      },
      setWeightGrossCustomField: function setWeightGrossCustomField(cfd) {
        this.set('cmrSetting.weightGrossCustomField', cfd);
      },
      setSenderInstructionCustomField: function setSenderInstructionCustomField(cfd) {
        this.set('cmrSetting.senderInstructionCustomField', cfd);
      }
    }
  });

  _exports.default = _default;
});