define("apollo/pods/auth/activate-account/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax-service'),
    loginController: Ember.inject.controller('auth/login'),
    actions: {
      activateButton: function activateButton() {
        var _this = this;

        var hash = this.get('model.hash');
        this.get('ajaxService').send("/activateAccount?hash=".concat(hash)).then(function () {
          _this.get('loginController').setProperties({
            message: _this.get('intl').t("auth.accountActivated")
          });

          _this.transitionToRoute('auth.login');
        }).catch(function (response) {
          _this.set('errors', JSON.parse(response.responseText).errors);
        });
      }
    }
  });

  _exports.default = _default;
});