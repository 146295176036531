define("apollo/pods/warehouse-locations/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Controller.extend({
    sessionAccount: service('session-account'),
    intl: service(),
    warehouseLocation: Ember.computed('model.warehouseLocation', function () {
      return this.get('model.warehouseLocation');
    }),
    gates: Ember.computed('model.gates', function () {
      return this.get('model.gates').filterBy('isEntryGate');
    }),
    actions: {
      save: function save() {
        var self = this;
        this.get('warehouseLocation').save().then(function () {
          self.setProperties({
            errors: []
          });
          var wh = self.get('warehouseLocation.warehouse');
          wh.reload();
          self.transitionToRoute('warehouses.edit', wh.get('id'));
        }).catch(function (response) {
          console.log("B\u0142\u0105d przy zapisywaniu lokalizacji: ".concat(response.errors));
          self.set('errors', response.errors);
        });
      },
      delete: function _delete() {
        var self = this;
        self.get('warehouseLocation').destroyRecord().then(function () {
          self.setProperties({
            errors: []
          });
          self.transitionToRoute('warehouses.edit', self.get('warehouseLocation.warehouse.id'));
        }).catch(function (response) {
          console.log("B\u0142\u0105d przy usuwaniu lokalizacji: ".concat(response.errors));
          self.set('errors', response.errors);
        });
      },
      cancel: function cancel() {
        var warehouseId = this.get('warehouseLocation.warehouse.id');
        this.get('warehouseLocation').unloadRecord();
        this.transitionToRoute('warehouses.edit', warehouseId);
      }
    }
  });

  _exports.default = _default;
});