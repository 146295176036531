define("apollo/services/aggregated-authority-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    AGGREGATED_AUTHORITIES_ENDPOINT: '/aggregated-authorities',
    ajaxService: Ember.inject.service('ajax-service'),
    fetch: function fetch(authorityGroupId, transportTypeId) {
      var params = {
        authorityGroupId: authorityGroupId,
        transportTypeId: transportTypeId
      };
      return this.ajaxService.send(this.AGGREGATED_AUTHORITIES_ENDPOINT, 'GET', params).then(function (response) {
        return response;
      });
    },
    send: function send(aggregatedAuthoritiesToSave, aggregatedAuthoritiesToRemove) {
      var data = {
        toRemove: aggregatedAuthoritiesToRemove,
        toSave: aggregatedAuthoritiesToSave
      };
      return this.ajaxService.send(this.AGGREGATED_AUTHORITIES_ENDPOINT, 'POST', data, true);
    }
  });

  _exports.default = _default;
});