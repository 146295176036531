define("apollo/pods/components/voivodeship-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    country: Ember.computed(function () {
      return this.get('country');
    }),
    actions: {
      setVoivodeship: function setVoivodeship(voivodeshipName) {
        var self = this;
        this.get('store').queryRecord('voivodeship', {
          name: voivodeshipName
        }).then(function (voivodeship) {
          self.set('voivodeship', voivodeship);
        }).catch(function (response) {
          console.error(response);
          self.set('errors', response.errors);
        });
      }
    }
  });

  _exports.default = _default;
});