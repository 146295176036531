define("apollo/services/checkpoint-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajaxService: Ember.inject.service('ajax-service'),
    store: Ember.inject.service(),
    reject: function reject(checkpoint) {
      var checkpointId = checkpoint.get('id');
      var self = this;
      var url = '/checkpoints/' + checkpointId + '/reject';
      self.get('ajaxService').send(url, 'PUT').then(function () {
        self.store.findRecord('checkpoint', checkpointId).then(function (data) {
          data.reload();
        });
      });
    }
  });

  _exports.default = _default;
});