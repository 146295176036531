define("apollo/pods/courier/modals/package-form/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    modalTitle: Ember.computed('', function () {
      var transportType = this.get('transport.transportType');
      var cpSetting = this.get('sessionAccount').getCourierSetting(transportType.get('id'));

      if (!cpSetting) {
        return this.get('intl').t('courier.COURIER_PACKAGE.add');
      }

      var key = cpSetting.get('translationKey');
      return this.get('intl').t("courier.".concat(key, ".add"));
    }),
    showCourierPackageValue: Ember.computed(function () {
      return this.get('sessionAccount').hasRole('ROLE_CAN_SEE_COURIER_PACKAGE_VALUE');
    }),
    packageTypes: Ember.computed('', function () {
      return this.get('store').peekAll('package-type').filter(function (packageType) {
        return packageType.get('name') === 'PALLETS' || packageType.get('name') === 'CARTONS';
      });
    }),
    courierSetting: Ember.computed('model.transport.transportType.courierSetting', function () {
      return this.get('model.transport.transportType.courierSetting');
    }),
    // eslint-disable-next-line ember/no-observers
    saveInitialProperties: Ember.observer('model.modelOpenTime', function () {
      var initialProperties = {
        model: this.get('model').toJSON()
      };
      this.set('initialProperties', initialProperties);
    }),
    transport: Ember.computed('model.transport', function () {
      return this.get('model.transport');
    }),
    isEditionDisabled: Ember.computed('transport.disabled', function () {
      if (!this.get('transport')) {
        return true;
      }

      return !this.get('transport').actionCanBePerformed('EDIT_PACKAGES');
    }),
    isNewPackage: Ember.computed('model.id', function () {
      return this.get('model.id');
    }),
    isDeletionAllowed: Ember.computed('model.id', 'transport.transportType', function () {
      if (!this.get('model.id') && this.get('model.isNew') || !this.get('transport')) {
        return false;
      }

      return this.get('transport').actionCanBePerformed('DELETE_PACKAGES');
    }),
    actions: {
      save: function save() {
        this.get('model').validate();

        if (this.get('model').get('errors.length')) {
          return;
        }

        this.get('model').set('enabled', true);
        (0, _jquery.default)('#sub-modal').data('skip-cancel-action', true);
        this.send('hideModal', 'sub-modal');
      },
      setPackageType: function setPackageType(packageType) {
        this.get('model').set('packageType', packageType);
      },
      disable: function disable() {
        var _this = this;

        if (this.get('model').get('id')) {
          this.get('model').set('enabled', false);
          var transport = this.get('model').get('transport');
          transport.get('courierPackages').filter(function (cp) {
            return cp.get('id') === _this.get('model').get('id');
          }).get('firstObject').set('enabled', false);
        } else {
          var _transport = this.get('model').get('transport');

          _transport.get('courierPackages').removeObject(this.get('model'));

          this.get('model').unloadRecord();
        }

        this.send('hideModal', 'sub-modal');
      },

      /**
       * Obsługa anulowania / zamknięcia formularza courierPackage'u.
       *
       * Musimy rozważyć trzy przypadki.
       *
       * 1. courierPackage jest niezapisany ani w bazie, ani w ember-data --> zupełnie usuwamy jego model; taka sytuacja wystąpi,
       * jeśli dodajemy nowy courierPackage, ale nie zapiszemy go i klikniemy "Anuluj".
       *
       * 2. courierPackage jest zapisany, wprowadzamy zmiany w jego formularzu (po kliknięciu ołówka) a następnie
       * wybieramy "Anuluj" --> usuwamy wprowadzone zmiany, ale poza tym courierPackage pozostaje przypisany do transportu.
       *
       * 3. courierPackage jest niezapisany w bazie, ale jest już dodany do transportu --> taka sytuacja wystąpi,
       * gdy dodajemy nowy courierPackage i uda się go zapisać (courierPackage pojawi się w tabelce), następnie otworzymy
       * formularz ze szczegółami (po kliknięciu ołówka) oraz wybierzemy "Anuluj" --> zamykamy wtedy formularz, ale nie usuwamy courierPackage'u
       */
      cancel: function cancel() {
        var _this2 = this;

        console.debug('Zamykamy okienko edycji courierPackageów...'); // To, że courierPackage został poprawnie dodany do transportu (pojawił się na liście courierPackageów) możemy
        // poznać sprawdzając flagę `enabled` .
        //
        // Nie możemy użyć tu `this.get('model').rollbackAttributes()`, ponieważ możliwa jest taka sytuacja:
        //  - użytkownik dodaje nowy courierPackage;
        //  - użytkownik otwiera formularz edycji courierPackage'u (ikona ołówka) oraz klika "Anuluj"
        //
        // Efekt jest taki, że `rollbackAttributes` uzna, że powinien zupełnie usunąć courierPackage, a nie powinniśmy tego
        // robić w tej sytuacji (użytkownik spodziewa się tylko zamknięcia formularza).
        //
        // Stąd tak naprawdę te skomplikowane operacje z `initialProperties` itp (gałąź `else`).

        if (this.get('model') && !this.get('model.id') && !this.get('model.enabled')) {
          // 1. courierPackage jest niezapisany ani w bazie, ani w ember-data
          this.get('transport.courierPackages').removeObject(this.get('model'));
          this.get('model').unloadRecord();
        } else if (this.get('model.id')) {
          // courierPackage jest zapisany, wprowadzamy zmiany w jego formularzu (po kliknięciu ołówka) a następnie wybieramy "Anuluj"
          var enabled = this.get('model.enabled');
          this.get('model').rollback();

          if (!enabled) {
            this.get('model').set('enabled', false);
          } // this.get('model.quantities').forEach(q => q.rollback());

        } else {
          // courierPackage jest niezapisany w bazie, ale jest już dodany do transportu
          var self = this;
          var initialProperties = this.get('initialProperties');
          var initialModelProperties = initialProperties.model;
          var excluded = ['transport', 'enabled'];
          var belongsToKeys = ['step', 'product'];
          Object.keys(initialModelProperties).filter(function (key) {
            return !excluded.includes(key);
          }).forEach(function (key) {
            var value = initialModelProperties[key];

            if (belongsToKeys.includes(key)) {
              if (value) {
                _this2.get('model').set(key, self.get('store').peekRecord(key, value));
              }
            } else {
              _this2.get('model').set(key, initialModelProperties[key]);
            }
          });
        }
      },
      close: function close() {
        this.send('hideModal', 'sub-modal');
      }
    }
  });

  _exports.default = _default;
});