define("apollo/pods/transaction/model", ["exports", "ember-data", "ember-data-copyable", "apollo/mixins/model-validator"], function (_exports, _emberData, _emberDataCopyable, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = _emberData.default.Model.extend(_modelValidator.default, _emberDataCopyable.default, {
    intl: service(),
    sessionAccount: service('session-account'),
    transport: _emberData.default.belongsTo('transport', {
      async: true
    }),
    carrier: _emberData.default.belongsTo('company', {
      async: true
    }),
    currency: _emberData.default.belongsTo('currency'),
    routeOffer: _emberData.default.belongsTo('routeOffer', {
      async: true
    }),
    autoRouteOfferPass: _emberData.default.attr('boolean'),
    autoRouteOfferPassNotAccepted: _emberData.default.attr('boolean'),
    invoiceNumber: _emberData.default.attr('string'),
    invoicePrice: _emberData.default.attr('number'),
    invoiceCurrency: _emberData.default.belongsTo('currency'),
    isInvoiceForm: _emberData.default.attr('boolean'),
    choosingReason: _emberData.default.belongsTo('specialFieldOption'),
    expectedConfirmationDate: _emberData.default.attr('isodate'),
    price: _emberData.default.attr('number'),
    carrierMustAcceptTransport: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return true;
      }
    }),
    isRejected: _emberData.default.attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    // typ serwisu kurierskiego
    typeOfService: _emberData.default.attr('string'),
    invoiceState: function () {
      var withInvoice = this.get('intl').t('transaction.withInvoice').toString();
      var withoutInvoice = this.get('intl').t('transaction.withoutInvoice').toString();

      if (this.get('invoiceNumber')) {
        return {
          name: withInvoice,
          key: 'WITH_INVOICE'
        };
      } else {
        return {
          name: withoutInvoice.toString(),
          key: 'WITHOUT_INVOICE'
        };
      }
    }.property('').readOnly(),
    validations: {
      carrier: {
        presence: {
          message: 'blank'
        }
      },
      price: {
        presence: {
          message: 'blank'
        }
      },
      invoiceNumber: {
        custom: [{
          validation: function validation(key, value, model) {
            return !model.get('isInvoiceForm') || value != null && value.length > 0;
          },
          message: 'blank'
        }]
      },
      invoicePrice: {
        custom: [{
          validation: function validation(key, value, model) {
            return !model.get('isInvoiceForm') || value != null;
          },
          message: 'blank'
        }, {
          validation: function validation(key, value, model) {
            if (!model.get('isInvoiceForm') || !value) {
              return true;
            }

            return /[0-9]+(\.|\,[0-9]{1,2})?$/.test(value);
          },
          message: 'wrongNumberFormat'
        }]
      },
      expectedConfirmationDate: {
        custom: [{
          validation: function validation(key, value, model) {
            //walidacja nie obowiązuje w formularzu faktury
            if (model.get('isInvoiceForm')) {
              return true;
            }

            return value > new Date();
          },
          message: 'isInPast'
        }]
      },
      choosingReason: {
        custom: [{
          validation: function validation(key, value, model) {
            if (model.get('transport.activeAuction')) {
              if (!model.get('requireAuctionChoosingReason')) {
                return true;
              } else {
                return value && value.get('id');
              }
            } else {
              if (model.get('requireTransactionChoosingReason') && model.get('mandatoryTransactionChoosingReason')) {
                return value && value.get('id');
              } else {
                return true;
              }
            }
          },
          message: 'blank'
        }]
      }
    },
    requireAuctionChoosingReason: Ember.computed('notTheBestOffer', 'transport.transportType.id', function () {
      var auctionSetting = this.get('sessionAccount').getAuctionSetting(this.get('transport.transportType.id'));

      if (!auctionSetting) {
        return false;
      }

      return auctionSetting.get('requireReasonIfNotBestOfferIsChosen') && this.get('notTheBestOffer');
    }),
    requireTransactionChoosingReason: Ember.computed('transport.transportType.id', function () {
      var transactionSetting = this.get('sessionAccount').getTransactionSetting(this.get('transport.transportType'));

      if (!transactionSetting) {
        return false;
      }

      return transactionSetting.get('showReasonField');
    }),
    mandatoryTransactionChoosingReason: Ember.computed('transport.transportType.id', function () {
      var transactionSetting = this.get('sessionAccount').getTransactionSetting(this.get('transport.transportType'));

      if (!transactionSetting) {
        return false;
      }

      return transactionSetting.get('requiresReasonField');
    })
  });

  _exports.default = _default;
});