define("apollo/pods/components/partnership-table/row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    tagName: 'tr',
    companyFromPartnership: Ember.computed('partnership', 'company', function () {
      if (this.get('partnership.principalCompany.id') === this.get('company.id')) {
        return this.get('partnership.contractorCompany');
      } else if (!this.get('partnership.isPrincipal')) {
        return this.get('partnership.principalCompany');
      }
    }),
    canDeletePartnership: Ember.computed('company.isPrincipalInGeneral', 'companyFromPartnership.isPrincipalInGeneral', function () {
      return this.get('sessionAccount').hasRole("ROLE_MANAGE_PARTNERSHIP") && !this.get('companyFromPartnership.isPrincipalInGeneral') && !this.get('company.isPrincipalInGeneral');
    }),
    actions: {
      delete: function _delete(partnership) {
        var _this = this;

        if (!confirm(this.get('intl').t('partnership.deleteMsg'))) {
          return;
        }

        partnership.destroyRecord().then(function () {
          var msg = _this.get('intl').t('common.saveSuccessMessage');

          alert(msg);
        }).catch(function (response) {
          alert(response.errors);
        });
      }
    }
  });

  _exports.default = _default;
});