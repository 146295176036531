define("apollo/pods/components/transport/step-user-group-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    showUserGroup: Ember.computed('userGroupsList.@each.length', function () {
      if (this.get('companyType') === 'CARRIER') {
        return false;
      }

      return this.get('hasGroup');
    }),
    userGroupsList: Ember.computed('company.{userGroups.[],nonDefaultUserGroups}', function () {
      return this.get('company.nonDefaultUserGroups');
    }),
    hasGroup: Ember.computed('userGroupsList', function () {
      return this.get('userGroupsList.length') > 0 || this.get('step.companyGroup.id');
    }),
    hasOnlyOneGroup: Ember.computed('userGroupsList', function () {
      return this.get('userGroupsList.length') === 1;
    }),
    defaultUserGroup: Ember.computed('step.companyGroup', 'userGroupsList.{[],@each.length}', {
      get: function get() {
        if (this.get('step.companyGroup.id')) {
          return this.get('step.companyGroup');
        } else {
          if (this.get('hasOnlyOneGroup')) {
            return this.get('userGroupsList').get('firstObject');
          } else {
            return '';
          }
        }
      },
      set: function set(key, value) {
        return value;
      }
    }),
    company: Ember.computed('step', 'step.company', function () {
      return this.get('step.company');
    }),
    actions: {}
  });

  _exports.default = _default;
});