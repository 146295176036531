define("apollo/pods/components/price-list/routes-table-nav/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['price-list-nav-section'],
    actions: {
      setTransportTypeMode: function setTransportTypeMode(mode) {
        this.set('actionName', 'setTransportTypeMode');
        this.sendAction('actionName', mode);
      }
    }
  });

  _exports.default = _default;
});