define("apollo/pods/components/schedule/warehouse-zone-header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['warehouse-zone-header'],
    attributeBindings: ['style'],
    classNameBindings: ['isZoneIdxEven:even'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if ($(this.element).children().width() <= $(this.element).width()) {
        this.set('fullOrShortWarehouseZoneName', this.get('warehouseZone.name'));
      } else {
        this.set('fullOrShortWarehouseZoneName', this.get('warehouseZone.shortName'));
      }
    },
    isZoneIdxEven: function () {
      return this.get('zoneIdx') % 2 === 0;
    }.property('zoneIdx'),
    style: function () {
      return "width: ".concat(this.get('width'), "%").htmlSafe();
    }.property('width'),
    width: function () {
      return this.userRampsInWarehouseZone().length * this.get('rampWidth');
    }.property('warehouseZone.enabledRamps.length', 'rampWidth', 'userRampsIds'),
    userRampsInWarehouseZone: function userRampsInWarehouseZone() {
      var _this = this;

      return this.get('warehouseZone.enabledRamps').filter(function (ramp) {
        return _this.get('userRampsIds').includes(ramp.id);
      });
    },
    fullOrShortWarehouseZoneName: function () {
      return this.get('warehouseZone.name');
    }.property('warehouseZone.enabledRamps.@each', 'warehouseZone.name', 'warehouseZone.shortName', 'width')
  });

  _exports.default = _default;
});