define("apollo/services/transport-copy-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    copyTransport: function copyTransport(originalTransport) {
      var self = this;
      var transportType = originalTransport.get('transportType');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!originalTransport || !originalTransport.copy) {
          reject();
        }

        originalTransport.copy(true, {
          ignoreAttributes: ['advice', 'carrierCompany', 'steps', 'checkpoints', 'internalIndex', 'erpIdentifier', 'altIdentifier', 'auctions', 'timeWindows', 'indexes', 'transaction', 'files', 'periodicTransport', 'customFields', 'carrierCompanyChooser', 'discrepancyReports', 'originalTransportId', 'notes', 'courierPricings', 'courierOrder', 'courierInvoice', 'courierOrderError', 'emailAdvices', 'goodsValue', 'goodsValueCurrency', 'incoterms', 'weights', 'relatedTo', 'relatedToTransports', 'masterTransports', 'hasToWaitRelations', 'relatedHasToWaitForTransports', 'relatedHasToWaitForNotCreatedTransports', 'masterHasToWaitForTransports', 'combinedMasterTransport', 'isRelatedTransportInTransportCombining', 'isMasterTransportInTransportCombining', 'isRelatedTransportInHasToWaitFor', 'isMasterTransportInHasToWaitFor', 'allCombinedTransports', 'transportRelationIdentifier']
        }).then(function (transportCopy) {
          transportCopy.set('showCreateAdviceButton', false);
          transportCopy.set('isCopy', true);
          transportCopy.set('archived', false);
          transportCopy.set('dateOfArchiving', null);
          transportCopy.set('deleted', false);
          transportCopy.set('dateOfDeletion', null);
          transportCopy.set('originalTransportId', originalTransport.get('internalIndex'));

          self._handleAdviceCopy(originalTransport, transportCopy, transportType);

          self.copySteps(originalTransport, transportCopy, transportType);
          self.copyCustomFields(originalTransport, transportCopy);
          transportCopy.set('creator', self.get('sessionAccount').get('currentUser'));
          transportCopy.set('periodicTransport', self.get('store').createRecord('periodicTransport', {
            frequency: 'DAILY',
            startDate: moment().startOf('day').toDate()
          }));
          var comp = self.get('sessionAccount.currentUser.company');

          if (comp.get('partnershipType.name') === 'CARRIER' || comp.get('partnershipType.name') === 'OPERATOR') {
            transportCopy.set('carrierCompany', comp);
          }

          resolve(transportCopy);
        });
      });
    },
    _createNewAdvice: function _createNewAdvice(originalTransport, transportCopy) {
      var advice = this.get('store').createRecord('advice', {
        transport: transportCopy
      });
      transportCopy.set('advice', advice);
    },
    _copyAdvice: function _copyAdvice(originalTransport, transportCopy) {
      if (!originalTransport.get('advice.id')) {
        return;
      }

      originalTransport.get('advice').then(function (advice) {
        return advice.copy(true, {
          ignoreAttributes: ['transport']
        }).then(function (adviceCopy) {
          adviceCopy.set('transport', transportCopy);
          transportCopy.set('advice', adviceCopy);
        });
      });
    },
    _handleAdviceCopy: function _handleAdviceCopy(originalTransport, transportCopy, transportType) {
      if (transportType.get('shouldCopyAdviceOnTransportCopy')) {
        this._copyAdvice(originalTransport, transportCopy, transportType);

        return;
      }

      if (originalTransport.get('transportType.adviceSavedWithTransport')) {
        this._createNewAdvice(originalTransport, transportCopy);
      }
    },
    copySteps: function copySteps(originalTransport, transportCopy, transportType) {
      var self = this;
      var defaultMinAdviceDate = moment().startOf('day');
      var defaultMaxAdviceDate = moment().startOf('day').add(1, 'day'); // Por. step-section/controller.js oraz create-transport-button/controller.js

      var defaultArrivalDate = moment().startOf('day').add(1, 'day').add(18, 'hours').toDate();
      var indexes = originalTransport.get('indexes');
      originalTransport.get('steps').forEach(function (s) {
        var stepCopyPromise = s.copy(true, {
          ignoreAttributes: ['transport', 'timeWindow', 'minAdviceDate', 'maxAdviceDate', 'arrivalDate', 'stepWarehouseLocations']
        });
        stepCopyPromise.then(function (stepCopy) {
          stepCopy.setProperties({
            transport: transportCopy,
            ownedByPrincipal: stepCopy.get('isInWarehouse')
          });

          if (stepCopy.get('isInWarehouse')) {
            if (s.get('minAdviceDate') || s.get('maxAdviceDate')) {
              var defaultMinHour = s.get('minAdviceDate') ? s.get('minAdviceDate').getHours() : 0;
              var defaultMaxHour = s.get('maxAdviceDate') ? s.get('maxAdviceDate').getHours() : 0;
              stepCopy.setProperties({
                minAdviceDate: defaultMinAdviceDate.add(defaultMinHour, 'hours').toDate(),
                maxAdviceDate: defaultMaxAdviceDate.add(defaultMaxHour, 'hours').toDate()
              });
            }
          } else {
            stepCopy.setProperties({
              arrivalDate: defaultArrivalDate
            });
          }

          self.handleAddressCopy(transportType, stepCopy);
          var indexesForStep = indexes.filterBy('step.id', s.get('id'));
          self.copyIndexes(indexesForStep, transportCopy, stepCopy);
          var stepPackageTypes = s.get('stepPackageType');

          if (stepPackageTypes) {
            self.copyStepPackageType(stepPackageTypes, stepCopy);
          }
        });
        transportCopy.notifyPropertyChange('showContractorSection');
      });
    },
    handleAddressCopy: function handleAddressCopy(transportType, step) {
      var newAddressIsDefault = transportType.get(step.get('isLoad') ? 'newAddressAsDefaultInLoadStep' : 'newAddressAsDefaultInUnloadStep');

      if (newAddressIsDefault && step.get('address')) {
        var addressCopyPromise = step.get('address.content').copy(true, {
          ignoreAttributes: ['id']
        });
        addressCopyPromise.then(function (addressCopy) {
          step.set('address', addressCopy);
        });
      }
    },
    copyIndexes: function copyIndexes(indexesForStep, transportCopy, stepCopy) {
      indexesForStep.forEach(function (index) {
        var indexCopyPromise = index.copy(true, {
          ignoreAttributes: ['transport', 'step', 'quantities']
        });
        indexCopyPromise.then(function (indexCopy) {
          indexCopy.setProperties({
            transport: transportCopy,
            step: stepCopy
          });
          index.get('quantities').forEach(function (iq) {
            var quantityCopyPromise = iq.copy(true, {
              ignoreAttributes: ['index']
            });
            quantityCopyPromise.then(function (quantityCopy) {
              quantityCopy.setProperties({
                index: indexCopy
              });
            });
          });
        });
      });
    },
    copyStepPackageType: function copyStepPackageType(stepPackageTypes, stepCopy) {
      stepPackageTypes.forEach(function (spt) {
        var sptPromise = spt.copy(true, {
          ignoreAttributes: ['step']
        });
        sptPromise.then(function (sptCopy) {
          sptCopy.setProperties({
            step: stepCopy
          });
        });
      });
    },
    copyCustomFields: function copyCustomFields(originalTransport, transportCopy) {
      originalTransport.get('customFields').forEach(function (cf) {
        if (cf.get('definition.visibleDuringTransportCreation')) {
          cf.copy(true, {
            ignoreAttributes: ['id', 'transport']
          }).then(function (cfCopy) {
            cfCopy.setProperties({
              transport: transportCopy
            });
          });
        }
      });
    }
  });

  _exports.default = _default;
});