define("apollo/pods/users/queues/controller", ["exports", "apollo/mixins/persistence-mixin", "apollo/mixins/ramps-access-mixin", "apollo/mixins/queues-access-mixin", "apollo/mixins/user-utils-mixin"], function (_exports, _persistenceMixin, _rampsAccessMixin, _queuesAccessMixin, _userUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_persistenceMixin.default, _rampsAccessMixin.default, _userUtilsMixin.default, _queuesAccessMixin.default, {
    intl: Ember.inject.service(),
    session: Ember.inject.service('session'),
    sessionAccount: Ember.inject.service('session-account'),
    ajaxService: Ember.inject.service('ajax-service'),
    usersIndexController: Ember.inject.controller('users/index'),
    usersShowController: Ember.inject.controller('users/show'),
    errors: [],
    user: Ember.computed('model.user', function () {
      return this.get('model.user');
    }),
    setProxiedSquares: Ember.observer('proxiedSquares.length', 'model', function () {
      var self = this;
      Ember.run.once(this, function () {
        self.checkSquares();
      });
    }),
    setProxiedQueues: Ember.observer('proxiedQueues.length', 'model', function () {
      var self = this;
      Ember.run.once(this, function () {
        self.checkQueues();
      });
    }),
    checkSquares: function checkSquares() {
      var proxiedSquares = this.get('proxiedSquares');
      var squaresIds = this.get('user.squaresIds');
      proxiedSquares.forEach(function (s) {
        var checked = squaresIds.includes(s.get('id'));
        s.set('checked', checked);
      });
    },
    checkQueues: function checkQueues() {
      var proxiedQueues = this.get('proxiedQueues');
      var userQueuesIds = this.get('user.queuesIds');
      proxiedQueues.forEach(function (q) {
        var checked = userQueuesIds.includes(q.get('id'));
        q.set('checked', checked);
      });
    },
    actions: {
      save: function save() {
        var _this = this;

        var user = this.get('user');
        var checkedQueues = this.get('checkedQueues');
        var data = {
          user: user.get('id'),
          queues: checkedQueues.map(function (cq) {
            return cq.get('id');
          })
        };
        this.get("ajaxService").send("/person-queues", "POST", data, true).then(function () {
          var msg = _this.get('intl').t('common.saveSuccessMessage');

          _this.set('successMessage', msg);
        }).catch(function (response) {
          _this.set('errors', JSON.parse(response.responseText).errors);
        });
      },
      cancel: function cancel() {
        this.get('errors').clear();
        window.history.back();
      }
    }
  });

  _exports.default = _default;
});