define("apollo/pods/components/server-table/filters/date-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Component.extend({
    sessionAccount: service('session-account'),
    actions: {
      setDate: function setDate(date) {
        if (!date[0]) {
          this.set("column.filterString", null);
        }

        this.set("column.filterString", moment(date[0]).format("DD.MM.YYYY"));
      }
    }
  });

  _exports.default = _default;
});