define("apollo/pods/components/confirm-statuses/component", ["exports", "apollo/mixins/checkpoint-mixin", "apollo/mixins/transport-form-mixin"], function (_exports, _checkpointMixin, _transportFormMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_checkpointMixin.default, _transportFormMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    checkpointService: Ember.inject.service(),
    action: 'confirm',
    isVisibleCheckpointConfirmation: Ember.computed('transport.firstUnconfirmedCheckpoint', function () {
      if (this.get('asPlainTemplate')) {
        return false;
      }

      var checkpoints = this.get('transport.checkpointsThatCanBeConfirmedByGuard').filterBy('id', this.get('transport.firstUnconfirmedCheckpoint.id'));
      return !this.get('sessionAccount.currentUser.roleGroup.isGuard') || this.get('sessionAccount.currentUser.roleGroup.isGuard') && checkpoints.length;
    }),
    checkpointRejectionDateShouldBeVisible: Ember.computed('firstUnconfirmedCheckpoint.{canBeRejected,rejectionDate}', function () {
      return this.get('firstUnconfirmedCheckpoint.canBeRejected') && this.get('firstUnconfirmedCheckpoint.rejectionDate');
    }),
    maximumPermissableVehicleWeight: Ember.computed('model', function () {
      return this.get('sessionAccount').getSettingValue('MAXIMUM_PERMISSIBLE_VEHICLE_WEIGHT_SETTING');
    }),
    weightFromSteps: Ember.computed('transport', function () {
      var weights = this.get('transport').get('steps');
      return weights.reduce(function (accumulator, step) {
        return step.get('STEP_TYPES').UNLOAD ? step.get('weight') + accumulator : 0;
      }, 0);
    }),
    weightFromTransport: Ember.computed('transport', function () {
      var weights = this.get('transport.weights');
      return weights.reduce(function (accumulator, weight) {
        return weight.get('enabled') ? weight.get('readOut') + accumulator : 0;
      }, 0);
    }),
    checkIfVehicleIsOverweight: Ember.computed('weightFromTransport', 'weightFromSteps', 'maximumPermissableVehicleWeight', function () {
      // Wartość z settingu jest podawana w tonach, natomiast wagi w zleceniu są w kg dlatego zamieniamy wartosć z settingu na kg
      var weightFromSetting = this.maximumPermissableVehicleWeight * 1000;
      return this.weightFromSteps + this.weightFromTransport > weightFromSetting;
    }),
    actions: {
      confirm: function confirm(checkpoint, hide) {
        var isFromTransportModal = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
        var self = this;

        if (!isFromTransportModal) {
          this.initializeCustomFields(checkpoint.get('transport')).then(function () {
            self.send('processCheckpoint', checkpoint, hide);
          });
        } else {
          this.send('processCheckpoint', checkpoint);
        }
      },
      processCheckpoint: function processCheckpoint(checkpoint) {
        var hasToAddWeight = checkpoint.get('hasWeightBlockade') && checkpoint.get('isWeighingIncludedInStepWarehouse');
        var isRequiredInCheckpointDelay = checkpoint.get('isCustomFieldRequiredInDelayedCheckpoint');

        if (checkpoint.get('hasCustomFieldsBlockade') || hasToAddWeight || isRequiredInCheckpointDelay) {
          if (hasToAddWeight) {
            this.get('store').createRecord('weight', {
              task: checkpoint.get('task'),
              warehouse: checkpoint.get('step.warehouse'),
              transport: checkpoint.get('transport'),
              enabled: true,
              dateCreated: new Date(),
              confirmator: this.get('sessionAccount.currentUser')
            });
          }

          this.showCustomFieldsModal(checkpoint, this.get('transport'), checkpoint.get('ACTIONS').CONFIRM);
          return;
        }

        if (checkpoint.get('hasWarehouseLocationBlockade')) {
          this.showWarehouseLocationModal(checkpoint, this.get('transport'), checkpoint.get('ACTIONS').CONFIRM);
          return;
        }

        if (checkpoint.get('hasCustomModalBlockade')) {
          this.showCustomModal(checkpoint, this.get('transport'), checkpoint.get('ACTIONS').CONFIRM);
          return;
        }

        if (checkpoint.get('transport').get('transportType').get('maximumPermissableVehicleWeight') && this.checkIfVehicleIsOverweight && checkpoint.get('task.weighingIncluded')) {
          this.showOverweightModal(checkpoint, this.get('transport', checkpoint.get('ACTIONS').CONFIRM));
          this.get('store').createRecord('note', {
            transport: checkpoint.get('transport'),
            creator: this.get('sessionAccount').get('currentUser'),
            note: this.get('intl').t('transport.weightSection.overweightNote'),
            createdAt: new Date(),
            autoGenerated: true
          }).save();
        } // Akcja `confirm` jest ustalana w momencie deklaracji komponentu w template pod
        // nazwą `confirm` i z tego miejsca jest wysyłana do rodzica ten komponentu.


        this.sendAction('confirm', checkpoint);

        if (this.get('isFromShowStatuses')) {
          this.sendAction('hide');
        }
      },
      revoke: function revoke(checkpoint, hide) {
        this.revoke(checkpoint);
        hide();
      },
      reject: function reject(checkpoint, hide) {
        if (checkpoint.get('hasCustomFieldsBlockade')) {
          this.showCustomFieldsModal(checkpoint, this.get('transport'), checkpoint.get('ACTIONS').REJECT, hide);
          return;
        }

        if (!this.confirmRejection()) {
          return;
        }

        this.checkpointService.reject(checkpoint);

        if (!this.get('isFromGuardTable')) {
          hide();
        }
      },
      showModal: function showModal(templateName, checkpoint) {
        this.sendAction('showModal', templateName, checkpoint);
      }
    }
  });

  _exports.default = _default;
});