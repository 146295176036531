define("apollo/pods/companies/allowed-domains/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_MANAGE_ALLOWED_DOMAINS'],
    queryParams: {
      lastUpdated: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      var companyId = params.company_id;
      var company = this.store.findRecord('company', companyId);
      var companyEmailDomains = this.store.query('companyEmailDomain', {
        companyId: companyId
      });
      return Ember.RSVP.hash({
        company: company,
        companyEmailDomains: companyEmailDomains
      });
    },
    setupController: function setupController(controller, model) {
      this.get('controller').setProperties({
        model: model
      });
    }
  });

  _exports.default = _default;
});