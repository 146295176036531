define("apollo/pods/components/transports/netpc-report-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    ajaxService: Ember.inject.service('ajax-service'),
    isSuperUser: Ember.computed('sessionAccount.isSuperUser', function () {
      return this.get('sessionAccount.isSuperUser');
    }),
    _getUrlAndOpen: function _getUrlAndOpen(dashboardName) {
      this.get('ajaxService').send('/netpc/getReportUrl/' + dashboardName, 'GET').then(function (json) {
        var URL = json.url;
        window.open(URL);
      });
    },
    actions: {
      goToReport: function goToReport() {
        var dashboard = this.get('dashboardName');
        Ember.run.debounce(this, '_getUrlAndOpen', dashboard || 'Transport_real', 500);
      }
    }
  });

  _exports.default = _default;
});