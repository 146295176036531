define("apollo/pods/transports/print/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "apollo/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: service('session-account'),
    access: ['ROLE_TRANSPORT_TABLE'],
    model: function model(params) {
      return this.get('store').findRecord('transport', params.id);
    },
    afterModel: function afterModel(model) {
      model.set('canBeUpdated', false);
      model.set('asPlainTemplate', true);

      if (model.get('advice.id')) {
        model.set('advice.canBeUpdated', false);
      }
    },
    renderTemplate: function renderTemplate() {
      this.render('transports.modals.create-transport');
    },
    setupController: function setupController(controller, model) {
      this.controllerFor('transports.modals.create-transport').setProperties({
        model: model,
        asPlainTemplate: true
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.controllerFor('transports.modals.create-transport').setProperties({
          asPlainTemplate: false
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});