define("apollo/pods/places/add-place/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_CREATE_PLACE'],
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      var queueId = params.queue_id;
      var place = this.store.createRecord('place');
      return Ember.RSVP.hash({
        queue: this.store.findRecord('queue', queueId),
        place: place
      });
    },
    renderTemplate: function renderTemplate() {
      this.render('places.create');
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var place = model.place;
      place.setProperties({
        queue: model.queue
      });
      this.controllerFor('places.create').setProperties({
        model: place
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.get('controller').setProperties({
          success: null,
          errors: []
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});