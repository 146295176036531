define("apollo/pods/special-field-option/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    languageService: Ember.inject.service('language-service'),
    transportType: _emberData.default.belongsTo('transportType', {
      async: true
    }),
    field: _emberData.default.attr('string'),
    option: _emberData.default.attr('string'),
    optionEn: _emberData.default.attr('string'),
    optionCs: _emberData.default.attr('string'),
    optionInAppropriateLanguage: Ember.computed('option', 'optionEn', function () {
      var propertyName = this.get('languageService').findProperLanguageVersion('option', 'optionEn');
      return this.get(propertyName);
    })
  });

  _exports.default = _default;
});