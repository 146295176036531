define("apollo/helpers/has-role", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Helper.extend({
    sessionAccount: service('session-account'),
    compute: function compute(params) {
      this.set('value', params[0]);
      return this.get('content');
    },
    valueDidChange: Ember.observer('value', function () {
      var value = this.get('value');

      if (Ember.isEmpty(value)) {
        Ember.defineProperty(this, 'content', false);
        return;
      }

      var self = this;
      Ember.defineProperty(this, 'content', Ember.computed('value', function () {
        var value = self.get('value');
        var sessionAccount = self.get('sessionAccount');
        return !!sessionAccount.get('currentUser').get('roles').findBy('name', value);
      }));
    }),
    contentDidChange: Ember.observer('content', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});