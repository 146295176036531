define("apollo/pods/queues/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model() {
      return this.store.createRecord('queue');
    }
  });

  _exports.default = _default;
});