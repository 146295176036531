define("apollo/pods/components/transport/contractor-section/component", ["exports", "apollo/mixins/form-utils-mixin"], function (_exports, _formUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formUtilsMixin.default, {
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    // Default values.
    companyType: 'CARRIER',
    propertyName: 'carrierCompany',
    propertyNameForError: 'carrierCompany',
    label: Ember.computed('companyType', 'transport.transportType.name', function () {
      var companyType = this.get('companyType');
      var transportTypeAlias = this.get('transport.transportType.alias');
      var translationKey = this.get('transport').get('transportType.supplierFieldTranslationKey');
      var key = "company.type.".concat(companyType, ".").concat(translationKey || transportTypeAlias);
      var fallbackKey;

      if (companyType === 'SERVICEMAN') {
        fallbackKey = 'company.type.SERVICE';
      } else fallbackKey = "company.type.".concat(companyType);

      return this.get('intl').t(key, {
        default: fallbackKey
      });
    }),
    contractor: Ember.computed('transport.{carrierCompany,serviceCompany,firstContractor,contractorSteps.firstObject.company}', 'propertyName', function () {
      if (!this.get('transport')) {
        return null;
      }

      return this.get('transport').get(this.get('propertyName'));
    }),
    editionDisabled: Ember.computed('transport.{canBeUpdated,carrierCompanyChooser.company.id}', 'currentUser.roleGroup.name', function () {
      var companyTypeName = this.get('currentUser.company.typeName');

      if (this.get('companyType') === companyTypeName) {
        return true;
      }

      if (this.get('companyType') === 'CARRIER' || this.get('companyType') === 'OPERATOR') {
        return !this.get('transport').actionCanBePerformed('UPDATE_CARRIER_COMPANY_FIELD');
      }

      var carrierChooserCompanyId = this.get('transport.carrierCompanyChooser.company.id');

      if (carrierChooserCompanyId && carrierChooserCompanyId !== this.get('currentUser.company.id')) {
        return true;
      }

      return !this.get('transport.canBeUpdated');
    }),
    noPartnershipWarning: Ember.computed('currentUser.company.myPartnerships.length', function () {
      return !this.get('currentUser.company.isPrincipal') && this.get('currentUser.company.myPartnerships.length') === 0;
    }),
    step: Ember.computed('transport.steps.[]', function () {
      return this.get('transport.steps').get('firstObject');
    }),
    actions: {
      setContractorCompanyData: function setContractorCompanyData(step, item) {
        var _this = this;

        var self = this;
        this.get('store').findRecord('company', item.id).then(function (company) {
          var transport = self.get('transport');
          var oldCompany = transport.get(self.get('propertyName'));

          if (_this.get('companyType') === 'SUPPLIER') {
            self.get('step').set('company', company);
            self.get('step').set('companyGroup', null);
          }

          if (transport.checkIfEditionViolatesPeriodicTransportRules(oldCompany, company)) {
            alert(_this.get('intl').t('periodicTransport.cannotChangeCompany'));
            transport.set(self.get('propertyName'), oldCompany);
            return;
          }

          transport.set(self.get('propertyName'), company);
        });
      },
      removeContractorCompany: function removeContractorCompany() {
        console.log("Removing ".concat(this.get('propertyName'), " from transport.."));
        this.get('transport').set(this.get('propertyName'), null);
      }
    }
  });

  _exports.default = _default;
});