define("apollo/pods/components/transports/transports-table/transport-sorter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    OPTIONS: [null, 'up', 'down'],
    STORAGE_KEY_PREFIX: 'sortingType_',
    tagName: 'span',
    classNames: ['clickable'],
    direction: null,
    init: function init() {
      var self = this;

      self._super.apply(self, arguments);

      var transportTypeId = self.get('transportType.id');
      var storageKey = self.STORAGE_KEY_PREFIX + transportTypeId;
      var sortingType = JSON.parse(localStorage.getItem(storageKey));

      if (!sortingType) {
        return;
      }

      var sortingKey = sortingType.key;
      var sortingDirection = sortingType.direction;
      var sortingTransportTypeId = sortingType.transportTypeId;

      if (sortingKey === self.get('key') && sortingTransportTypeId === transportTypeId) {
        self.set('key', sortingKey);
        self.set('direction', sortingDirection);
        Ember.run.next(function () {
          self.changeSortingKey(sortingKey, sortingDirection);
        });
      }
    },
    activeKeyChangedObserver: Ember.observer('activeSortingKey', function () {
      if (this.get('key') !== this.get('activeSortingKey')) {
        this.set('direction', null);
      }
    }),
    icon: Ember.computed('direction', function () {
      var baseIcon = 'fa fa-sort';
      var direction = this.get('direction');
      return direction ? "".concat(baseIcon, "-").concat(direction) : baseIcon;
    }),
    click: function click() {
      var self = this;
      var transportTypeId = self.get('transportType.id');
      var currentDirection = self.get('direction');
      var key = self.get('key');
      var currentOptionIndex = self.OPTIONS.indexOf(currentDirection);
      var nextOptionIndex = (currentOptionIndex + 1) % self.OPTIONS.length;
      var newDirection = self.OPTIONS[nextOptionIndex];
      var storageKey = self.STORAGE_KEY_PREFIX + transportTypeId;
      localStorage.setItem(storageKey, JSON.stringify({
        key: key,
        direction: newDirection,
        transportTypeId: transportTypeId
      }));
      self.set('direction', newDirection);
      self.changeSortingKey(key, newDirection);
    }
  });

  _exports.default = _default;
});