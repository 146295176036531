define("apollo/pods/components/show-statuses/component", ["exports", "apollo/config/environment", "jquery", "apollo/mixins/checkpoint-mixin", "apollo/mixins/transport-form-mixin"], function (_exports, _environment, _jquery, _checkpointMixin, _transportFormMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_checkpointMixin.default, _transportFormMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    ajaxService: Ember.inject.service(),
    action: 'confirm',
    canSeeRevokeButtons: Ember.computed('transport.{completed,archived}', function () {
      return !(this.get('transport.completed') || this.get('transport.archived'));
    }),
    firstUnconfirmedCheckpoint: Ember.computed('transport.firstUnconfirmedCheckpoint', function () {
      return this.get('transport.firstUnconfirmedCheckpoint');
    }),
    firstMandatoryCheckpoint: Ember.computed('transport.checkpoints.[]', function () {
      return this.get('transport.firstMandatoryCheckpoint');
    }),
    checkpointRejectionDateShouldBeVisible: Ember.computed('firstUnconfirmedCheckpoint.{canBeRejected,rejectionDate}', function () {
      return this.get('firstUnconfirmedCheckpoint.canBeRejected') && this.get('firstUnconfirmedCheckpoint.rejectionDate');
    }),
    showFirstMandatoryConfirmationButton: Ember.computed('firstMandatoryCheckpoint', 'firstUnconfirmedCheckpoint', function () {
      return !this.get('firstMandatoryCheckpoint.isConfirmed') && this.get('firstUnconfirmedCheckpoint.canBeSkipped') && this.get('firstMandatoryCheckpoint.task.canBeConfirmedByRole');
    }),
    isVisibleCheckpointConfirmation: Ember.computed('transport.firstUnconfirmedCheckpoint', function () {
      var checkpoints = this.get('transport.checkpointsThatCanBeConfirmedByGuard').filterBy('id', this.get('transport.firstUnconfirmedCheckpoint.id'));
      return !this.get('sessionAccount.currentUser.roleGroup.isGuard') || this.get('sessionAccount.currentUser.roleGroup.isGuard') && checkpoints.length > 0;
    }),
    actions: {
      block: function block(checkpoint) {
        console.debug('Otwieramy modal do blokowania statusów dla statusu :: ' + checkpoint);
        this.showBlockadeReasonModal(checkpoint, this.get('transport'), checkpoint.get('ACTIONS').BLOCK);
      },
      unblock: function unblock(checkpoint) {
        console.debug('Odblokowujemy status :: ' + checkpoint);
        this.get('ajaxService').send("/checkpoints/".concat(checkpoint.get('id'), "/unblock"), 'POST', true).then(function () {
          console.debug('Odblokowaliśmy status :: ' + checkpoint);
          checkpoint.reload();
        }).catch(function (response) {
          console.debug('errors', response.errors);
        });
      },
      showStatuses: function showStatuses() {
        var self = this;
        var token = this.get('sessionAccount').get('token');
        this.get('store').query('customFieldValue', {
          transportId: self.get('transport.id')
        }).then(function (cfv) {
          self.get('transport').set('customFields', cfv);
        });

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/' + self.get('transport.id') + '/checkpoints',
          type: 'GET',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (json) {
          self.get('store').pushPayload(json);
          json.checkpoints.forEach(function (checkpoint) {
            var c = self.get('store').peekRecord('checkpoint', checkpoint.id);

            if (!self.get('transport.checkpoints').includes(c)) {
              console.debug("Pushing checkpoint ".concat(c.get('id'), " into transport ").concat(self.get('transport.id'), ".."));
              self.get('transport.checkpoints').pushObject(c);
            }
          });
        });
      },
      confirm: function confirm(checkpoint) {
        this.confirm(checkpoint);
      },
      revoke: function revoke(checkpoint, hide) {
        this.revoke(checkpoint);
        hide();
      },
      reject: function reject(checkpoint, hide) {
        if (checkpoint.get('hasCustomFieldsBlockade')) {
          this.showCustomFieldsModal(checkpoint, this.get('transport'), checkpoint.get('ACTIONS').REJECT, hide);
          return;
        }

        if (!this.confirmRejection()) {
          return;
        }

        this.reject(checkpoint);
        hide();
      },
      showModal: function showModal(templateName, checkpoint) {
        this.showModal(templateName, checkpoint);
      }
    }
  });

  _exports.default = _default;
});