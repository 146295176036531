define("apollo/pods/transport-types/settings/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    errors: [],
    transportType: Ember.computed('model.transportType', function () {
      return this.get('model.transportType');
    }),
    defaultLoadCompany: Ember.computed('model.defaultLoadCompany', function () {
      return this.get('model.defaultLoadCompany');
    }),
    defaultUnloadCompany: Ember.computed('model.defaultUnloadCompany', function () {
      return this.get('model.defaultUnloadCompany');
    }),
    defaultServicemanCompany: Ember.computed('model.defaultServicemanCompany', function () {
      return this.get('model.defaultServicemanCompany');
    }),
    actions: {
      setCompany: function setCompany(property, item) {
        var _this = this;

        this.get('store').findRecord('company', item.id).then(function (company) {
          _this.set(property, company);

          _this.set("transportType.".concat(property, "Id"), company.get('id'));
        });
      },
      removeCompany: function removeCompany(property) {
        this.set(property, null);
        this.set("transportType.".concat(property, "Id"), null);
      },
      save: function save() {
        var self = this;
        var transportType = this.get('transportType');

        if (!transportType.validate()) {
          $('html, body').animate({
            scrollTop: 0
          }, 'slow');
          return;
        }

        this.set('errors', []);
        transportType.save().then(function () {
          self.setProperties({
            success: true,
            errors: []
          });
          var msg = self.get('intl').t('settings.transportTypes.settingsSaveSuccess');
          self.set('successMessage', msg);
          $('html, body').animate({
            scrollTop: 0
          }, 'slow');
        }).catch(function (response) {
          self.set('errors', response.errors);
          $('html, body').animate({
            scrollTop: 0
          }, 'slow');
        });
      },
      cancel: function cancel() {
        this.get('model.transportType').rollbackAttributes();
        this.transitionToRoute('settings.index');
      }
    }
  });

  _exports.default = _default;
});