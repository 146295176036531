define("apollo/pods/components/periodic-transport-form/component", ["exports", "apollo/mixins/form-utils-mixin"], function (_exports, _formUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Component.extend(_formUtilsMixin.default, {
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    frequencyOptions: ['DAILY', 'WEEKLY', 'MONTHLY'],
    setCheckedDays: Ember.on('init', function () {
      var _this = this;

      if (!this.get('transport.id')) {
        return;
      }

      var currentDaysString = this.get('transport.periodicTransport.dayNumbers') ? this.get('transport.periodicTransport.dayNumbers') : "";
      currentDaysString.split(",").filter(function (idx) {
        return idx;
      }).forEach(function (idx) {
        return Ember.set(_this.findDay(idx), 'checked', true);
      });
    }),
    daysOfWeek: Ember.computed(function () {
      var days = [];

      for (var i = 1; i <= 7; i++) {
        days.push({
          idx: i,
          checked: false
        });
      }

      return days;
    }),
    editionDisabled: Ember.computed('transport.id', function () {
      return this.get('transport.id');
    }),
    updateDayNumbers: function updateDayNumbers(dayIdx, newChecked) {
      var currentDaysString = this.get('transport.periodicTransport.dayNumbers') ? this.get('transport.periodicTransport.dayNumbers') : "";
      var currentDays = new Set(currentDaysString.split(",").filter(function (x) {
        return x;
      }));

      if (newChecked) {
        currentDays.add("".concat(dayIdx));
      } else {
        currentDays.delete("".concat(dayIdx));
      }

      this.set('transport.periodicTransport.dayNumbers', _toConsumableArray(currentDays).sort().join(","));
    },
    findDay: function findDay(idx) {
      return this.get('daysOfWeek').find(function (d) {
        return Number(d.idx) === Number(idx);
      });
    },
    actions: {
      checkDay: function checkDay(idx) {
        var day = this.findDay(idx);
        var newChecked = !day.checked;
        Ember.set(day, 'checked', newChecked);
        this.updateDayNumbers(day.idx, newChecked);
      },
      setDate: function setDate(propertyName, date) {
        this.setDateOfPeriodicAdvice(date, propertyName);
      }
    }
  });

  _exports.default = _default;
});