define("apollo/pods/authorities/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_MANAGE_AUTHORITIES'],
    queryParams: {
      roleGroupId: {
        refreshModel: true
      },
      multiSelect: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      var roleGroup = params.roleGroupId ? this.store.peekRecord('roleGroup', params.roleGroupId) : null;
      var roleGroupAuthority = roleGroup ? this.store.createRecord('role-group-authority', {
        roleGroup: roleGroup
      }) : null;
      return Ember.RSVP.hash({
        authorities: this.store.query('authority', {
          canBeManaged: true
        }),
        roleGroupAuthority: roleGroupAuthority,
        selectedAuthority: null,
        roleGroups: Ember.A(),
        multiSelect: params.multiSelect
      });
    }
  });

  _exports.default = _default;
});