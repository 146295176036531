define("apollo/pods/companies/index/controller", ["exports", "apollo/mixins/form-utils-mixin"], function (_exports, _formUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Controller.extend(_formUtilsMixin.default, {
    sessionAccount: service('session-account'),
    intl: service(),
    supplierLabel: Ember.computed(function () {
      var settingKey = this.get('sessionAccount').getSettingValue('SUPPLIER_FIELD_IN_COMPANY_DETAILS_TRANSLATION_KEY');
      var fallbackKey = 'company.type.SUPPLIER';
      var key = settingKey ? "company.type.SUPPLIER.".concat(settingKey) : fallbackKey;
      return this.get('intl').t(key, {
        default: fallbackKey
      });
    }),
    maxRowsInTable: Ember.computed('sessionAccount.currentUser.maxRowsInCompaniesTable', function () {
      return this.get('sessionAccount.currentUser.maxRowsInCompaniesTable');
    }),
    columns: Ember.computed('showCarriersTable', function () {
      return [{
        propertyName: 'contractorCompany.name',
        title: this.get('intl').t('company.name'),
        className: 'company-name column-name'
      }, {
        propertyName: 'partnership.contractorCompanyShortName',
        title: this.get('intl').t('company.shortName'),
        className: 'company-short-name column-shortName responsive-hide-lvl1'
      }, {
        propertyName: 'contractorNumber',
        title: this.get('contractorNumberLabel'),
        className: 'contractor-number column-contractorNumber'
      }, {
        propertyName: 'contractorCompany.taxIdNumber',
        title: this.get('intl').t('company.taxIdNumber'),
        className: 'tax-id-number column-taxIdNumber responsive-hide-lvl1'
      }, {
        propertyName: 'partnershipType',
        title: this.get('intl').t('company.type.label'),
        isHidden: this.get('showCarriersTable'),
        disableSorting: true,
        className: 'partnership-type column-type',
        filterWithSelect: true,
        predefinedFilterOptions: [{
          value: 'SUPPLIER',
          label: this.get('supplierLabel')
        }, {
          value: 'CARRIER',
          label: this.get('intl').t('company.type.CARRIER')
        }, {
          value: 'OPERATOR',
          label: this.get('intl').t('company.type.OPERATOR')
        }, {
          value: 'SERVICEMAN',
          label: this.get('intl').t('company.type.SERVICEMAN')
        }]
      }, {
        propertyName: 'country.ibanSymbol',
        title: this.get('intl').t('company.country'),
        className: 'company-iban-symbol column-ibanSymbol responsive-hide-lvl2'
      }, {
        propertyName: 'contractorCompany.headquarterStreet',
        title: this.get('intl').t('address.street'),
        className: 'company-street column-street responsive-hide-lvl1'
      }, {
        propertyName: 'contractorCompany.headquarterPostal',
        title: this.get('intl').t('address.postal'),
        className: 'company-postal column-postal responsive-hide-lvl1'
      }, {
        propertyName: 'contractorCompany.headquarterCity',
        title: this.get('intl').t('address.city'),
        className: 'company-city column-city responsive-hide-lvl1'
      }, {
        propertyName: 'statuses[]',
        title: this.get('intl').t('company.partnershipStatus.label'),
        filterWithSelect: true,
        className: 'partnership-status column-partnershipStatus responsive-hide-lvl2',
        predefinedFilterOptions: [{
          value: 'NO_INVITATION',
          label: this.get('intl').t('company.partnershipStatus.NO_INVITATION')
        }, {
          value: 'REGISTRATION_IS_IN_PROGRESS',
          label: this.get('intl').t('company.partnershipStatus.REGISTRATION_IS_IN_PROGRESS')
        }, {
          value: 'WAITING',
          label: this.get('intl').t('company.partnershipStatus.WAITING')
        }, {
          value: 'AWAITING_VERIFICATION',
          label: this.get('intl').t('company.partnershipStatus.AWAITING_VERIFICATION')
        }, {
          value: 'UPDATED',
          label: this.get('intl').t('company.partnershipStatus.UPDATED')
        }, {
          value: 'ACCEPTED',
          label: this.get('intl').t('company.partnershipStatus.ACCEPTED')
        }, {
          value: 'REJECTED',
          label: this.get('intl').t('company.partnershipStatus.REJECTED')
        }, {
          value: 'BLOCKED',
          label: this.get('intl').t('company.partnershipStatus.BLOCKED')
        }]
      }, {
        propertyName: 'invite',
        title: this.get('intl').t('invitation.invite'),
        disableFiltering: true,
        disableSorting: true,
        isHidden: this.get('showCarriersTable'),
        className: 'company-invite column-invite responsive-hide-lvl2'
      }, {
        propertyName: 'sections',
        title: this.get('intl').t('company.sections'),
        isHidden: !this.get('showCarriersTable'),
        filterWithSelect: true,
        disableSorting: true,
        className: 'company-sections column-sections',
        predefinedFilterOptions: [{
          value: 'MAIN_DATA_TYPE',
          label: this.get('intl').t('sectionType.MAIN_DATA_TYPE')
        }, {
          value: 'INSURANCE_DOMESTIC_OCP',
          label: this.get('intl').t('sectionType.INSURANCE_DOMESTIC_OCP')
        }, {
          value: 'INSURANCE_INTERNATIONAL_OCP',
          label: this.get('intl').t('sectionType.INSURANCE_INTERNATIONAL_OCP')
        }, {
          value: 'INSURANCE_OCS',
          label: this.get('intl').t('sectionType.INSURANCE_OCS')
        }, {
          value: 'INSURANCE_OC',
          label: this.get('intl').t('sectionType.INSURANCE_OC')
        }, {
          value: 'LICENCE_DOMESTIC',
          label: this.get('intl').t('sectionType.LICENCE_DOMESTIC')
        }, {
          value: 'LICENCE_INTERNATIONAL',
          label: this.get('intl').t('sectionType.LICENCE_INTERNATIONAL')
        }, {
          value: 'LICENCE_FORWARDING',
          label: this.get('intl').t('sectionType.LICENCE_FORWARDING')
        }]
      }, {
        propertyName: 'update',
        title: this.get('intl').t('company.update'),
        isHidden: !this.get('showCarriersTable'),
        componentForFilterCell: 'server-table/filters/date-filter',
        className: 'company-update column-update'
      }];
    }),
    companiesCreateButtonVisible: function () {
      return this.get('sessionAccount').hasRole('ROLE_CREATE_COMPANY');
    }.property()
  });

  _exports.default = _default;
});