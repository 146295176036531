define("apollo/services/application-tab-active-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    currentTabAlias: null,
    userLeftDate: null,
    pollObject: null,
    initialize: function initialize() {
      var _this = this;

      document.addEventListener('visibilitychange', function () {
        return _this.visibilityChanged();
      });
    },
    setOnPollHandlingObject: function setOnPollHandlingObject(pollObject, tabAlias) {
      if (!pollObject && tabAlias !== this.get('currentTabAlias')) {
        return;
      }

      this.set('currentTabAlias', tabAlias);
      this.set('pollObject', pollObject);
    },
    visibilityChanged: function visibilityChanged() {
      if (!this.pollObject) {
        return;
      }

      if (document.visibilityState === "hidden") {
        this.set('userLeftDate', this.pollObject.get('lastPoll') || new Date());
      } else if (document.visibilityState === "visible") {
        if (this.get('userLeftDate')) {
          this.pollObject.set('lastPoll', this.get('userLeftDate'));
          this.pollObject.onPollWrapper();
        }

        this.set('userLeftDate', null);
      }
    }
  });

  _exports.default = _default;
});