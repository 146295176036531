define("apollo/services/grouping-log-service", ["exports", "apollo/utils/objects/groupedLog"], function (_exports, _groupedLog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    sessionAccount: Ember.inject.service('session-account'),
    groupLogs: function groupLogs(logs) {
      var _this = this;

      var groupedLogs = [];
      logs.forEach(function (log) {
        var newGroupedLog = _this.createGroupedLog(log);

        var matchingGroupedLog = groupedLogs.find(function (gl) {
          return gl.isMatch(newGroupedLog);
        });

        if (matchingGroupedLog) {
          matchingGroupedLog.get('changes').push(log);
        } else {
          groupedLogs.push(newGroupedLog);
        }
      });
      return groupedLogs;
    },
    createGroupedLog: function createGroupedLog(log) {
      return _groupedLog.default.create({
        dateCreated: log.get('dateCreated'),
        actor: log.get('actor'),
        persistedObjectVersion: log.get('persistedObjectVersion'),
        transportId: log.get('transportId'),
        customFieldDefinitionId: log.get('customFieldDefinitionId'),
        userActivityTransportLabel: log.get('userActivityTransportLabel'),
        eventLabel: log.get('eventLabel'),
        className: log.get('className'),
        persistedObjectId: log.get('persistedObjectId'),
        eventName: log.get('eventName'),
        changes: [log]
      });
    }
  });

  _exports.default = _default;
});