define("apollo/pods/sections/modals/rejection-modal/controller", ["exports", "apollo/mixins/section-mixin"], function (_exports, _sectionMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_sectionMixin.default, {
    intl: Ember.inject.service(),
    resetProperties: Ember.observer('model.modelOpenTime', function () {
      this.setProperties({
        errors: [],
        comment: null
      });
    }),
    section: Ember.computed('model', function () {
      return this.get('model');
    }),
    title: Ember.computed('section.id', 'section.name', function () {
      if (this.get('section.documentType.translationKey')) {
        return this.get('intl').t("documentType.".concat(this.get('section.documentType.translationKey'), ".name"));
      }

      var typeLabel = this.get('intl').t("sectionType.".concat(this.get('section.name')));

      if (this.get('section.id')) {
        return typeLabel;
      } else {
        return this.get('intl').t("section.".concat(this.get('section.groupName'), ".new"), {
          typeLabel: typeLabel
        });
      }
    }),
    subtitle: Ember.computed('section.id', 'section.number', function () {
      return this.get('section.id') ? this.get('section.number') : null;
    }),
    actions: {
      rejectWithComment: function rejectWithComment() {
        if (!this.get('comment')) {
          var errorMessage = this.get('intl').t('section-action.noCommentError');
          this.set('errors', [errorMessage]);
        } else {
          this.send('reject', this.get('section'), this.get('comment'));
          this.send('hideModal');
        }
      },
      cancel: function cancel() {
        console.debug("Zamykamy okienko odrzucania dokumentu...");
        this.set('errors', []);
      }
    }
  });

  _exports.default = _default;
});