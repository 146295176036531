define("apollo/initializers/older-browsers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* container, application */
  {
    if (Ember.$('html').is('.polyfill')) {
      // Uwaga: To ustawienie sprawia, że jQuery do każdego zapytania GET doda na końcu timestamp, np.:
      // https://apollo-test.logintegra.com:8090/apollo/api/user-groups?principalCompanyId=121528818&_=1652968121793
      // To "&_=1652968121793" powoduje natomiast, że przeglądarka za każdym razem musi wysłać
      // zapytanie preflight (https://stackoverflow.com/questions/29954037/why-is-an-options-request-sent-and-can-i-disable-it),
      // co znowu bardzo negatywnie wpływa na wydajność.
      // O ile więc jakieś konkretne zapytanie nie będzie powodować problemów, lepiej tej opcji nie włączać globalnie.
      Ember.$.ajaxSetup({
        cache: false
      }); // trim function for IE8

      String.prototype.trim = function () {
        return this.replace(/^\s+|\s+$/g, '');
      }; // simple Object.keys polyfill that could work


      if (!Object.keys) {
        Object.keys = function (obj) {
          var keys = [];

          for (var i in obj) {
            if (obj.hasOwnProperty(i)) {
              keys.push(i);
            }
          }

          return keys;
        };
      }

      if (typeof console === "undefined" || typeof console.log === "undefined") {
        // eslint-disable-next-line
        console = {};

        console.log = function (msg) {};

        console.error = function (msg) {};

        console.debug = function (msg) {};

        console.warn = function (msg) {};

        console.info = function (msg) {};
      }
    } // https://stackoverflow.com/a/36213464/2224598


    if (!String.prototype.startsWith) {
      String.prototype.startsWith = function (searchString, position) {
        position = position || 0;
        return this.substr(position, searchString.length) === searchString;
      };
    }

    if (typeof console.table === "undefined") {
      console.table = console.log;
    }
  }

  var _default = {
    name: 'older-browsers',
    initialize: initialize
  };
  _exports.default = _default;
});