define("apollo/pods/components/documents-applies/show-document-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    store: Ember.inject.service(),
    actions: {
      showDocumentForm: function showDocumentForm(groupName) {
        var partnership = this.get('partnership');
        var isInsurance = groupName === 'INSURANCE';
        var currency;

        if (isInsurance) {
          var countryIban = this.get('partnership.contractorCompany.country.ibanSymbol');
          var defaultCurrencySymbol = countryIban === 'PL' ? 'PLN' : 'EUR';
          currency = this.get('store').peekAll('currency').findBy('symbol', defaultCurrencySymbol);
        }

        var documentTypes = this.get('documentTypes');
        var documentType = this.get('documentType') || documentTypes.get('firstObject');
        var suggestedExpirationDate = moment().add(1, 'year').startOf('day').toDate();
        var suggestedExpirationDateFrom = isInsurance ? moment().startOf('day').toDate() : null;
        var scopeOfInsurance = this.get('documentType.ibanRestriction') === 'PL' ? 'Polska' : '';
        var documentModel = this.get('store').createRecord('document', {
          partnership: partnership,
          documentType: documentType,
          expirationDate: suggestedExpirationDate,
          expirationDateFrom: suggestedExpirationDateFrom,
          currency: currency,
          scopeOfInsurance: scopeOfInsurance
        });
        var model = {
          documentTypes: documentTypes,
          document: documentModel
        };
        this.set('action', 'showModal');
        this.sendAction('action', 'documents.modals.document-modal', model);
      }
    }
  });

  _exports.default = _default;
});