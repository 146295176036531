define("apollo/pods/components/transport/step-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    init: function init() {
      this._super.apply(this, arguments);

      this.autoCompleteContactPersonDetails();
    },
    classNames: Ember.A(['step-responsive-width']),
    autoCompleteContactPersonDetails: function autoCompleteContactPersonDetails() {
      if (this.get('step.transport.id') || !this.get('step.transport.transportType.isContactDetailsFieldsVisible')) {
        // uzupełniamy tylko w tworzonym zleceniu z widocznymi polami osoby kontaktowej
        return;
      }

      var currentUser = this.get('sessionAccount.currentUser');
      var name = this.get('step.contactPerson');
      var email = this.get('step.contactPersonEmail');
      var mobilePhoneNumber = this.get('step.contactPersonMobilePhoneNumber');
      var mobilePhoneNumberPrefix = this.get('step.contactPersonMobilePhonePrefix');

      if (email === '' || email === null || email === undefined) {
        this.set('step.contactPersonEmail', currentUser.get('email'));
      }

      if (mobilePhoneNumber === '' || mobilePhoneNumber === null || mobilePhoneNumber === undefined) {
        this.set('step.contactPersonMobilePhoneNumber', currentUser.get('mobilePhoneNumber'));
      }

      if (name === '' || name === null || name === undefined) {
        this.set('step.contactPerson', currentUser.get('name'));
      }

      var currentUserMobilePhonePrefix = currentUser.get('mobilePhoneNumberPrefix');

      if (mobilePhoneNumberPrefix === '' || mobilePhoneNumberPrefix === null || mobilePhoneNumberPrefix === undefined) {
        if (currentUserMobilePhonePrefix !== null && currentUserMobilePhonePrefix !== '') {
          this.set('step.contactPersonMobilePhonePrefix', currentUserMobilePhonePrefix);
        } else {
          this.set('step.contactPersonMobilePhonePrefix', '48');
        }
      }
    },
    addNewAddress: Ember.computed('step.newAddressAsDefault', function () {
      return this.get('step.newAddressAsDefault');
    }),
    defaultPrefix: Ember.computed('step.contactPersonMobilePhonePrefix', function () {
      return this.get('step.contactPersonMobilePhonePrefix');
    }),
    masterSteps: Ember.computed('step.transport.masterTransports.firstObject.steps', function () {
      return this.get('step.transport.masterTransports.firstObject.steps');
    }),
    stepOrMasterStep: Ember.computed('step.{[],warehouse.id}', 'masterSteps.@each.warehouse', function () {
      var self = this;
      var masterSteps = this.get('masterSteps');

      if (!masterSteps) {
        return this.get('step');
      }

      return masterSteps.filter(function (s) {
        return s.get('warehouse.id') && s.get('warehouse.id') === self.get('step.warehouse.id');
      }).get('firstObject') || this.get('step');
    }),
    stepOrMasterTransportStepHasTimeWindow: Ember.computed('stepOrMasterStep.timeWindow.id', function () {
      return !!this.get('stepOrMasterStep.timeWindow.id');
    }),
    countries: Ember.computed(function () {
      return this.get('store').peekAll('country').sortBy('ibanSymbol');
    }),
    companyType: 'SUPPLIER',
    address: Ember.computed('step.address', function () {
      return this.get('step.address');
    }),
    arrivalDate: Ember.computed('step.arrivalDate', function () {
      return moment(this.get('step.arrivalDate')).format('YYYY-MM-DD HH:mm');
    }),
    addressSummary: Ember.computed('address.{recipient,city,postal,street}', function () {
      if (this.get('address.recipient')) {
        return this.get('address.recipient');
      }

      if (this.get('address.street')) {
        return "".concat(this.get('address.street'), ", ").concat(this.get('address.city'), ", ").concat(this.get('address.postal'));
      }

      return '';
    }).readOnly(),
    canChooseCompany: Ember.computed('transport', function () {
      return this.get('transport').actionCanBePerformed('ACCESS_SUPPLIER_COMPANY_FIELD');
    }),
    showNewAddressButton: Ember.computed('step.company.id', 'transport.canBeUpdated', function () {
      if (this.get('canChooseCompany')) {
        return this.get('step.company.id') && this.get('transport.canBeUpdated');
      }

      return this.get('transport.canBeUpdated');
    }),
    showWarehouseTimes: Ember.computed('step.{warehouse,ownedByPrincipal,timeWindow}', 'transport.transportType.requiresAdviceDateRestrictions', function () {
      return this.get('step.isInWarehouse') && !this.get('transport.transportType.requiresAdviceDateRestrictions');
    }),
    showWarehouseZone: Ember.computed('transport.transportType.isWarehouseZoneVisible', function () {
      return this.get('transport.transportType.isWarehouseZoneVisible');
    }),
    showWarehouseLocation: Ember.computed('transport.transportType.isWarehouseLocationVisible', function () {
      return this.get('transport.transportType.isWarehouseLocationVisible') && this.get('transport').actionCanBePerformed('ROLE_ACCESS_TO_LOCATION');
    }),
    warehouseLocationDisabled: Ember.computed('transport.transportType.authorityRestrictions.@each.authorityRange', function () {
      return !this.get('transport').actionCanBePerformed('ROLE_EDIT_WAREHOUSE_LOCATION');
    }),
    showContactPerson: Ember.computed('transport.transportType.isContactDetailsFieldsVisible', function () {
      return this.get('transport.transportType.isContactDetailsFieldsVisible');
    }),
    companyToFindAddress: Ember.computed('step.company.id', 'sessionAccount.currentUser.company.id', function () {
      return this.get('step.company.id') ? this.get('step.company.id') : this.get('sessionAccount.currentUser.company.id');
    }),
    shouldDataBeHidden: Ember.computed('transport.{id,transportType.authorityRestrictions.@each.authorityRange,firstUnconfirmedCheckpoint}', function () {
      if (!this.get('transport.id')) {
        return false;
      }

      return this.get('transport').actionCanBePerformed('DISABLE_DATA_FIELDS');
    }),
    alternativeStepsLayout: Ember.computed('transport.id', function () {
      return this.get('transport.transportType.alternativeStepsLayout');
    }),
    StepCountryField: Ember.computed('step.address.country', function () {
      return this.get('step.address.country');
    }),
    actions: {
      handleAddressOnPasteEvent: function handleAddressOnPasteEvent(step, event) {
        var company = step.get('company');
        var clipboardData = (event.originalEvent.clipboardData || window.clipboardData).getData('text/plain');

        if (!company || !clipboardData) {
          return;
        }

        try {
          /*
              Adres 1
              Adres 2 (Opcjonalny)
              Ulica                 ==>   ['Adres 1', 'Adres 2', 'Ulica', 'Kod pocztowy Miasto']
              Kod pocztowy Miasto
           */
          var addressComponents = clipboardData.trim().split('\n').filter(function (value) {
            return value.trim() !== '';
          });
          var isThreeLinesFormat = addressComponents.length === 3;
          var recipient = addressComponents[0].trim() + (isThreeLinesFormat ? '' : ' ' + addressComponents[1].trim());
          var street = addressComponents[2 - (isThreeLinesFormat ? 1 : 0)].trim();
          var postalAndCity = addressComponents[3 - (isThreeLinesFormat ? 1 : 0)].trim();
          var postalLastIndex = /[0-9] *[a-zA-Z]/.exec(postalAndCity).index;
          var postal = postalAndCity.slice(0, postalLastIndex + 1);
          var city = postalAndCity.slice(postalLastIndex + 1).trim();
          step.set('address', this.get('store').createRecord('address', {
            company: company,
            recipient: recipient,
            street: street,
            postal: postal,
            city: city
          }));
          event.stopPropagation();
          event.preventDefault();
        } catch (err) {
          console.log('Nie udało się przetworzyć podanego adresu');
        }
      },
      setArrivalDate: function setArrivalDate(step, dateValues) {
        Ember.run.next(function () {
          step.set('arrivalDate', dateValues[0]);
        });
      },
      setPhonePrefix: function setPhonePrefix(phonePrefix) {
        this.set('step.contactPersonMobilePhonePrefix', phonePrefix);
      },
      setContractorCompanyData: function setContractorCompanyData(step, item) {
        var _this = this;

        // troche haks - poniższe zapytania i walidacje długo trwają, więc od razu wrzucamy id nowej firmy,
        // żeby przy "szybkim klikaniu" podpowiedzieć już właściwe adresy
        this.set('companyToFindAddress', item.id);
        this.get('store').findRecord('company', item.id).then(function (company) {
          var transport = _this.get('transport');

          var oldCompany = step.get('company');

          if (transport.checkIfEditionViolatesPeriodicTransportRules(oldCompany, company)) {
            alert(_this.get('intl').t('periodicTransport.cannotChangeCompany'));
            step.set('company', oldCompany);
            return;
          }

          step.set('companyGroup', null);
          step.set('company', company);
          step.set('address', null);

          if (_this.get('addNewAddress')) {
            _this.send('setAddressForStep', step);
          }
        });
      },
      removeContractorCompany: function removeContractorCompany() {
        console.debug('Removing company from step..');
        this.set('companyToFindAddress', this.get('sessionAccount.currentUser.company.id'));
        this.get('step').set('company', null);
      },
      selectContractorCompany: function selectContractorCompany(id) {
        if (!id) {
          return;
        }

        var self = this;
        this.get('store').findRecord('company', id).then(function (company) {
          self.get('transport').set('contractorCompany', company);
        });
      },
      offScroll: function offScroll() {
        $(':input').on('mousewheel', function (event) {
          event.preventDefault();
        });
      },
      toggleNewAddress: function toggleNewAddress(step) {
        if (this.get('addNewAddress')) {
          this.send('deleteAddressFromStep', step);
          this.set('addNewAddress', false);
        } else {
          this.send('setAddressForStep', step);
          this.set('addNewAddress', true);
        }
      },
      deleteAddressFromStep: function deleteAddressFromStep(step) {
        step.get('address').then(function (address) {
          address.unloadRecord();
          step.set('address', null);
        });
      },
      setAddressForStep: function setAddressForStep(step) {
        step.set('address', this.get('store').createRecord('address', {
          company: step.get('company')
        }));
      },
      setAddressData: function setAddressData(address) {
        var self = this;

        if (address.id) {
          this.get('store').findRecord('address', address.id).then(function (a) {
            self.get('step').set('address', a);
          });
        } else {
          this.get('step.address').setProperties({
            street: address.street,
            postal: address.postal,
            city: address.city
          });
          this.get('store').findRecord('country', address.country).then(function (c) {
            self.get('step.address').set('country', c);
          });
        }
      },
      removeAddress: function removeAddress() {
        console.debug('Removing address from step..');
        this.get('step').set('address', null);
      },
      setWarehouse: function setWarehouse(warehouse) {
        this.get('step').set('warehouse', warehouse);
        this.get('step.errors').remove('warehouse');
      }
    }
  });

  _exports.default = _default;
});