define("apollo/pods/authority-restrictions/create/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: service('session-account'),
    access: ['ROLE_MANAGE_AUTHORITIES'],
    queryParams: {
      roleGroupId: {
        refreshModel: true
      },
      transportTypeId: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      var roleGroup = this.store.peekRecord('roleGroup', params.roleGroupId);
      var transportType = this.store.peekRecord('transportType', params.transportTypeId);
      var authorityRestriction = this.store.createRecord('authority-restriction', {
        roleGroup: roleGroup
      });
      return Ember.RSVP.hash({
        authorityRestriction: authorityRestriction,
        transportType: transportType
      });
    },
    renderTemplate: function renderTemplate() {
      this.render('authority-restrictions.edit');
    },
    setupController: function setupController(controller, model) {
      this.controllerFor('authority-restrictions.edit').setProperties({
        model: model,
        tasksToSave: []
      });
    }
  });

  _exports.default = _default;
});