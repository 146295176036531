define("apollo/pods/queue-status/index/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    resourcesArray: Ember.computed('model.classificationResources.@each.state', function () {
      var self = this;
      var squares = self.get('sessionAccount.currentUser.accessibleSquares');
      var resourcesArray = Ember.A();
      squares.forEach(function (square) {
        var queuesArray = Ember.A();
        var numberOfClassificationResources = 0;
        var resourceObject = Ember.Object.create({
          square: square,
          queuesArray: null
        });
        var queues = square.get('queues').filter(function (q) {
          return self.get('sessionAccount.currentUser.accessibleQueues').getEach('id').includes(q.get('id'));
        });
        queues.forEach(function (queue) {
          var classificationResources = self.get('model.classificationResources').filter(function (cr) {
            return cr.get('queue.id') === queue.get('id') && cr.get('state') !== cr.get('STATE_NAMES.RESERVED');
          });
          var queueObject = Ember.Object.create({
            queue: queue,
            classificationResources: classificationResources
          });
          numberOfClassificationResources += classificationResources.get('length');
          queuesArray.pushObject(queueObject);
        });
        resourceObject.set('numberOfClassificationResources', numberOfClassificationResources);
        resourceObject.set('queuesArray', queuesArray);
        resourcesArray.pushObject(resourceObject);
      });
      return resourcesArray;
    }),
    shouldSeeSendSmsIcon: Ember.computed('', function () {
      return this.get('sessionAccount').hasRole('ROLE_SEND_SMS_TO_ALL_DRIVERS_IN_QUEUE');
    }),
    actions: {
      showSendSmsModal: function showSendSmsModal(queue) {
        (0, _jquery.default)("#".concat(queue.get('id'))).modal({
          keyboard: false
        });
      }
    }
  });

  _exports.default = _default;
});