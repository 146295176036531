define("apollo/pods/components/transport/courier/packages-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    showAddCourierPackagesSection: Ember.computed('', function () {
      var transport = this.get('transport').actionCanBePerformed ? this.get('transport') : this.get('transport.content');
      return transport.actionCanBePerformed('ADD_PACKAGES');
    }),
    showCourierPackageValue: Ember.computed(function () {
      return this.get('sessionAccount').hasRole('ROLE_CAN_SEE_COURIER_PACKAGE_VALUE');
    }),
    courierPackagesErrors: Ember.computed('transport.{courierPackages.errors.[],errors.courierPackages}', function () {
      if (this.get('transport.errors.courierPackages')) {
        return this.get('transport.errors.courierPackages');
      }

      return this.get('transport.courierPackages.errors');
    }),
    packageTypes: Ember.computed('', function () {
      return this.get('store').peekAll('package-type').filter(function (packageType) {
        return packageType.get('name') === 'PALLETS' || packageType.get('name') === 'CARTONS';
      });
    }),
    courierSetting: Ember.computed('transport.transportType.courierSetting', function () {
      return this.get('transport.transportType.courierSetting');
    }),
    hasUnsavedChanges: Ember.computed('transport.courierPackages.@each.hasChanges', function () {
      return this.get('transport.courierPackages').filterBy('hasChanges').get('length') > 0;
    }),
    editionOfIncompleteLoadingDisabled: Ember.computed('transport.canBeUpdated', function () {
      return !this.get('sessionAccount').hasRole('ROLE_EDIT_INCOMPLETE_LOADING') || !this.get('transport.canBeUpdated');
    }),
    isEditionDisabled: Ember.computed('transport.disabled', function () {
      var transport = this.get('transport').actionCanBePerformed ? this.get('transport') : this.get('transport.content');
      return !transport.actionCanBePerformed('EDIT_PACKAGES');
    }),
    isDeletionAllowed: Ember.computed('model.id', 'transport.transportType', function () {
      if (!this.get('transport.id')) {
        return false;
      }

      var transport = this.get('transport').actionCanBePerformed ? this.get('transport') : this.get('transport.content');
      return transport.actionCanBePerformed('DELETE_PACKAGES');
    }),
    editionOrDeletionIsAllowed: Ember.computed('isDeletionAllowed', 'isEditionDisabled', function () {
      return !this.get('isEditionDisabled') || this.get('isDeletionAllowed');
    }),
    actions: {
      togglePanel: function togglePanel() {
        this.toggleProperty('isOpened');
      },
      setPackageType: function setPackageType(pckg, packageType) {
        pckg.set('packageType', packageType);
      },
      editPackage: function editPackage(pckg) {
        this.set('action', 'showSubModal');
        this.sendAction('action', 'courier.modals.package-form', pckg);
      },
      disablePackage: function disablePackage(pckg) {
        var msg = this.get('courierSetting.disableConfirmation');

        if (!confirm(msg)) {
          return;
        }

        if (pckg.get('id')) {
          pckg.set('enabled', false);
        } else {
          var transport = pckg.get('transport');
          transport.get('courierPackages').removeObject(pckg);
        }
      },
      showSubModal: function showSubModal(templateName, model) {
        this.set('action', 'showSubModal');
        this.sendAction('action', templateName, model);
      },
      toggleIncompleteLoading: function toggleIncompleteLoading() {
        this.get('transport').toggleProperty('incompleteLoading');
      }
    }
  });

  _exports.default = _default;
});