define("apollo/pods/user-groups/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_MANAGE_USER_GROUPS'],
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      var companyId = params.companyId;
      var company = this.store.findRecord('company', companyId);
      var userGroups = this.store.query('userGroup', {
        companyId: companyId
      });
      return Ember.RSVP.hash({
        company: company,
        userGroups: userGroups
      });
    }
  });

  _exports.default = _default;
});