define("apollo/pods/ramps/create/controller", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var service = Ember.inject.service;

  var _default = Ember.Controller.extend({
    sessionAccount: service('session-account'),
    intl: service(),
    warehousesCreateController: Ember.inject.controller('warehouses/create'),
    showDeleteDedicatedWindowsCheckbox: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('AUTOMATIC_DELETING_UNUSED_DEDICATED_WINDOWS_ENABLED') === 'true' || this.get('sessionAccount').getSettingValue('AUTOMATIC_DELETING_USED_DEDICATED_WINDOWS_ENABLED') === 'true';
    }),
    defaultRoleGroups: Ember.computed(function () {
      return this.get('model.roleGroups') ? this.get('model.roleGroups').getEach('id') : Ember.A([]);
    }),
    actions: {
      save: function save() {
        var self = this;
        this.get('model').validate();
        var defaultRoleGroups = this.get('defaultRoleGroups');

        if (this.get('model').get('errors.length')) {
          return;
        }

        this.get('model').set('defaultRoleGroups', defaultRoleGroups);
        this.get('model').save().then(function (m) {
          m.get('warehouse').then(function (w) {
            w.reload();
            self.get('sessionAccount').get('currentUser').reload();
            self.get('sessionAccount').get('currentUser').get('ramps').reload();
            self.setProperties({
              errors: [],
              success: true
            });
            self.transitionToRoute('ramps.edit', m.id);
          });
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      },
      disable: function disable() {
        var _this = this;

        var self = this;
        var model = this.get('model');
        var token = this.get('sessionAccount').get('token');
        Ember.$.ajax({
          url: _environment.default.serverURL + '/ramps/' + model.get('id') + '/disable',
          type: 'PUT',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          var warehouse = model.get('warehouse');
          model.set('enabled', false);
          warehouse.then(function (w) {
            w.reload();
            self.get('sessionAccount').get('currentUser').reload();
            self.get('sessionAccount').get('currentUser').get('ramps').reload();
            var msg = self.get('intl').t('ramp.disabledMessage', {
              rampName: model.get('name')
            });
            self.get('warehousesCreateController').set('successMessage', msg);
            model.unloadRecord();

            _this.transitionToRoute('warehouses.edit', warehouse);
          });
        }, function (response) {
          self.set('errors', response.errors);
        });
      },
      enable: function enable() {
        var _this2 = this;

        var self = this;
        var model = this.get('model');
        var token = this.get('sessionAccount').get('token');
        Ember.$.ajax({
          url: _environment.default.serverURL + '/ramps/' + model.get('id') + '/enable',
          type: 'PUT',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          var warehouse = model.get('warehouse');
          model.set('enabled', true);
          warehouse.then(function (w) {
            w.reload();
            self.get('sessionAccount').get('currentUser').reload();
            self.get('sessionAccount').get('currentUser').get('ramps').reload();
            var msg = self.get('intl').t('ramp.enabledMessage', {
              rampName: model.get('name')
            });
            self.get('warehousesCreateController').set('successMessage', msg);

            _this2.transitionToRoute('warehouses.edit', warehouse);
          });
        }, function (response) {
          self.set('errors', response.errors);
        });
      },
      cancel: function cancel() {
        var warehouse = this.get('model').get('warehouse');
        this.get('model').rollbackAttributes();
        this.transitionToRoute('warehouses.edit', warehouse);
      },
      setDefaultRoleGroups: function setDefaultRoleGroups(roleGroup) {
        var defaultRoleGroups = this.get('defaultRoleGroups');
        var newRoleGroup = roleGroup.get('id');

        if (defaultRoleGroups.includes(newRoleGroup)) {
          // odznaczenie checkbox'a
          var _newDefaultRoleGroups = _toConsumableArray(defaultRoleGroups).filter(function (defaultRoleGroupId) {
            return defaultRoleGroupId !== newRoleGroup;
          });

          this.set('defaultRoleGroups', _newDefaultRoleGroups);
          return;
        }

        var newDefaultRoleGroups = _toConsumableArray(new Set([].concat(_toConsumableArray(defaultRoleGroups), [newRoleGroup])));

        this.set('defaultRoleGroups', newDefaultRoleGroups);
      }
    }
  });

  _exports.default = _default;
});