define("apollo/pods/components/transports/transports-table/transport-title/component", ["exports", "apollo/mixins/attachment-mixin", "apollo/config/environment"], function (_exports, _attachmentMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Component.extend(_attachmentMixin.default, {
    store: service(),
    sessionAccount: service('session-account'),
    relationsTrackerService: service('transport-relations.relations-tracker'),
    ajaxService: service(),
    tagName: 'div',
    classNames: Ember.A(['row', 'h2']),
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    searchParams: {},
    // rzeczywista zmienna propagowana po całym systemie
    action: '',
    canGenerateExcel: Ember.computed('sessionAccount.currentUser.roleNames', function () {
      var _this = this;

      return ['ROLE_ADVICE_EXCEL', 'ROLE_CAN_GENERATE_FULL_SETTLEMENT_REPORT', 'ROLE_CAN_GENERATE_SHORT_SETTLEMENT_REPORT'].some(function (role) {
        return _this.get('sessionAccount').hasRole(role);
      });
    }),
    disableGenerateExcelButton: Ember.computed('searchParams', 'searchParams.filters.@each.filterValue', function () {
      if (this.get('archive') === false) {
        return false;
      } else {
        var searchParams = this.get('searchParams');
        var disableExcelButton = true;

        for (var prop in searchParams) {
          if (searchParams[prop] && searchParams[prop] !== '' && searchParams[prop].length !== 0) {
            disableExcelButton = false;
          }
        }

        return disableExcelButton;
      }
    }),
    percentageOfLoadedRow: Ember.computed('numberOfRenderedRows', 'numberOfTransports', function () {
      if (!this.get('numberOfTransports')) {
        return null;
      }

      return Math.round(this.get('numberOfRenderedRows') / this.get('numberOfTransports') * 100);
    }),
    showPercentage: Ember.computed('numberOfTransports', 'percentageOfLoadedRow', function () {
      return this.get('numberOfTransports') && this.get('percentageOfLoadedRow') < 100;
    }),
    availableTransportTypes: Ember.computed('sessionAccount.currentUser.transportTypes.[]', function () {
      var userTransportTypes = this.get('sessionAccount.currentUser.transportTypes');
      var principalId = localStorage.getItem('principalId');
      var company = this.get('store').peekRecord('company', principalId);
      return company.get('transportTypes').filter(function (tt) {
        return userTransportTypes.includes(tt);
      });
    }),
    refreshButtonHidden: Ember.computed(function () {
      return this.get('sessionAccount').getSettingValue('CAN_REFRESH_MANUALLY') !== 'true';
    }),
    _downloadRegistryOfPersonsXls: function _downloadRegistryOfPersonsXls(warehouse) {
      var token = this.get('sessionAccount.token');
      var url = _environment.default.serverURL + '/reports/personsRegistry?token=' + token + '&warehousesId=' + warehouse.get('id');
      return window.open(url, '_blank');
    },
    actions: {
      poll: function poll() {
        this.set('action', 'poll');
        this.sendAction('action');
      },
      openExportXlsModal: function openExportXlsModal() {
        var availableTransportTypes = this.get('availableTransportTypes');
        var checkedTransportTypes = [];
        checkedTransportTypes.pushObject(this.get('transportType'));
        this.set('action', 'showModal');
        this.sendAction('action', 'transports.modals.transport-types-to-excel', {
          availableTransportTypes: availableTransportTypes,
          checkedTransportTypes: checkedTransportTypes,
          archived: this.get('archive'),
          searchParams: this.get('searchParams')
        });
      },
      downloadRegistryOfPersonsXls: function downloadRegistryOfPersonsXls() {
        var self = this;
        var warehouses = this.get('sessionAccount.currentUser.warehouses');

        if (warehouses.length > 1) {
          self.set('action', 'showModal');
          self.sendAction('action', 'transports.modals.warehouses-to-excel', {
            warehouses: warehouses
          });
          return;
        }

        Ember.run.once(self, '_downloadRegistryOfPersonsXls', warehouses[0]);
      },
      scrollToClosestTransport: function scrollToClosestTransport() {
        this.set('action', 'scrollToClosestTransport');
        this.sendAction('action');
      },
      clearFilters: function clearFilters() {
        this.set('action', 'clearFilters');
        this.sendAction('action');
      },
      openRelationsModal: function openRelationsModal() {
        this.set('action', 'openRelationsModal');
        this.sendAction('action');
      }
    }
  });

  _exports.default = _default;
});