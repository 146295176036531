define("apollo/mixins/error-utils-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Mixin.create({
    store: service(),
    handleErrors: function handleErrors(errors, modelName) {
      var self = this;
      jQuery.each(errors, function (key, val) {
        var model = self.get('store').peekRecord(modelName, key);
        model.send('becomeDirty');
        model.get('errors').add(key, val);
      });
    }
  });

  _exports.default = _default;
});