define("apollo/pods/partnerships/index/controller", ["exports", "apollo/mixins/company-mixin"], function (_exports, _companyMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_companyMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    company: Ember.computed('model', function () {
      return this.get('model');
    }),
    partnerships: Ember.computed('company.id', 'showPartnershipTable', function () {
      var companyId = this.get('company.id');

      if (!companyId) {
        return [];
      }

      var canSeePartnershipTable = this.get('sessionAccount').hasRole('ROLE_SHOW_PARTNERSHIP_TABLE');
      return canSeePartnershipTable ? this.get('store').query('partnership', {
        companyId: companyId,
        partnershipTypeId: this.get('company.partnershipType.id')
      }) : [];
    }),
    actions: {
      showPartnershipModal: function showPartnershipModal(template, model) {
        this.send("showModal", template, model);
      }
    }
  });

  _exports.default = _default;
});