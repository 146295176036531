define("apollo/pods/transports/modals/create-transport/controller", ["exports", "apollo/config/environment", "apollo/mixins/attachment-mixin", "apollo/mixins/advice-utils-mixin", "apollo/mixins/form-utils-mixin", "apollo/mixins/transports-mixin", "apollo/mixins/transport-form-mixin", "apollo/mixins/persistence-mixin", "apollo/mixins/checkpoint-mixin", "jquery", "apollo/utils/parsers/numberParsers"], function (_exports, _environment, _attachmentMixin, _adviceUtilsMixin, _formUtilsMixin, _transportsMixin, _transportFormMixin, _persistenceMixin, _checkpointMixin, _jquery, _numberParsers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Controller.extend(_attachmentMixin.default, _adviceUtilsMixin.default, _checkpointMixin.default, _formUtilsMixin.default, _transportsMixin.default, _transportFormMixin.default, _persistenceMixin.default, {
    // eslint-disable-next-line ember/no-observers
    resetProperties: Ember.observer('model.modelOpenTime', function () {
      console.debug("Resetujemy ustawienia transportu ".concat(this.get('model.id') ? this.get('model.logString') : '(niezapisanego)', " po otwarciu/zamkni\u0119ciu okienka z formularzem..."));
      var transport = this.get('model');

      if (transport && transport.set) {
        transport.set('hasCustomFieldsInitialized', false);
      }
    }),
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    transportCopyService: Ember.inject.service('transport-copy-service'),
    dateUtils: Ember.inject.service('date-utils'),
    ajaxService: Ember.inject.service('ajax-service'),
    errorService: Ember.inject.service('error-service'),
    languageService: Ember.inject.service('language-service'),
    scheduleController: Ember.inject.controller('warehouses/schedule'),
    transportController: Ember.inject.controller('transports/index'),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.run.schedule('afterRender', this, function () {
        this.send('resizeTextArea');
      });
    },
    errors: Ember.A(),
    excludedLogNames: Ember.A(['transportId', 'stepId']),
    attachments: Ember.A(),
    existingFilesToRemove: Ember.A(),
    showExpandedIndexSection: Ember.computed(function () {
      return this.get('asPlainTemplate') && this.get('transport.indexes.length') > 0;
    }),
    showExpandedCourierPackageSection: Ember.computed(function () {
      return this.get('asPlainTemplate') && this.get('transport.courier-packages.length') > 0;
    }),
    columnsInIndexSection: Ember.computed('showCarriersTable', function () {
      return ['index', 'shortName', 'globalNumber', 'localNumber', 'documentNumber', 'transportNumber', 'quantities', 'company', 'productName', 'indexName', 'notes', 'editionOrDeletion'];
    }),
    canCreateSimilar: Ember.computed('model.id', function () {
      if (!this.get('transport')) {
        return false;
      }

      return this.get('transport').actionCanBePerformed('ROLE_CREATE_SIMILAR') && this.get('model.id');
    }),
    canGenerateLOPReport: Ember.computed('transport.id', function () {
      return this.get('transport.id') && this.get('sessionAccount').hasRole('ROLE_CAN_GENERATE_LIST_OF_POINTS_REPORT');
    }),
    canRestoreOrderFromArchive: Ember.computed('transport.{archived,completed,deleted}', function () {
      return this.get('transport.archived') && !this.get('transport.completed') && !this.get('transport.deleted') && this.get('sessionAccount').hasRole('ROLE_RESTORE_ORDER_FROM_ARCHIVE') && !this.restoreFromArchiveTimePassed();
    }),
    restoreFromArchiveTimePassed: function restoreFromArchiveTimePassed() {
      var interval = this.get('sessionAccount').getSettingValue('TIME_TO_RESTORE_ORDER_FROM_ARCHIVE');

      if (!interval) {
        return false;
      }

      var archiveDate = this.get('transport.dateOfArchiving');
      var deadline = this.get('dateUtils').calculateDateFromInterval(new Date(archiveDate), interval);
      return moment().isAfter(moment(deadline));
    },
    principalCompanyId: Ember.computed('', function () {
      return localStorage.getItem('principalId');
    }),
    browserOld: Ember.computed('', function () {
      return (0, _jquery.default)('html').is('.old');
    }),
    dateOfAdvice: Ember.computed('transport.transportType.numberOfDaysAddedToFirstStep', function () {
      var numberOfDaysAddedToFirstStep = this.get('transport.transportType.numberOfDaysAddedToFirstStep');
      var numberOfDaysForFirstStep = numberOfDaysAddedToFirstStep || 0;
      return moment().startOf('day').add(numberOfDaysForFirstStep, 'day').format('YYYY-MM-DD');
    }),
    transport: Ember.computed('model', function () {
      return this.get('model');
    }),
    firstStepWithWarehouse: Ember.computed('transport.stepsWithWarehouse.firstObject', function () {
      return this.get('transport.stepsWithWarehouse.firstObject');
    }),
    warehouseLocationDropdownHidden: Ember.computed('firstStepWithWarehouse.warehouseLocations.length', function () {
      var role = this.get('sessionAccount').hasRole('ROLE_CAN_CHOOSE_MORE_THAN_ONE_LOCATION');

      if (role) {
        return false;
      }

      return this.get('firstStepWithWarehouse.warehouseLocations.length') >= 1;
    }),
    shouldSelectWindow: Ember.computed('transport.{id,steps.[]}', 'transportType.requiresTimeWindow', function () {
      if (!this.get('transport.id')) {
        return this.get('transportType.requiresTimeWindow');
      }

      var newStepsArr = this.get('transport.steps').filter(function (step) {
        return !step.get('id') && step.get('ownedByPrincipal');
      }); // Klawisz `Dalej` pokazujemy tylko gdy transport posiada już okno czasowe i dodamy nowy etap w magazynie

      return newStepsArr.get('length') > 0 && this.get('transport.advice');
    }),
    shouldChangeStepWindow: Ember.computed('transport.stepsWithWarehouse.[]', function () {
      return this.get('transport.stepsWithWarehouse').some(function (step) {
        return step.get('didWarehouseChange');
      });
    }),
    warehouse: Ember.computed('transport.warehouse', function () {
      return this.get('transport.warehouse');
    }),
    transportType: Ember.computed('transport.transportType', function () {
      return this.get('transport.transportType');
    }),
    transportOrMasterTransport: Ember.computed('transport.{advice,transport.masterTransports.firstObject.advice.[]}', function () {
      if (this.get('transport.masterTransports.firstObject.advice.id')) {
        return this.get('transport.masterTransports.firstObject');
      }

      return this.get('transport');
    }),
    showAdviceSectionAsPanel: Ember.computed('transport.advice.id', 'transportType.{adviceSavedWithTransport,requiresTimeWindow}', function () {
      if (this.get('transport.masterTransports.firstObject.advice.id')) {
        return true;
      }

      if (this.get('transportType.adviceSavedWithTransport')) {
        return false;
      }

      return this.get('transport.advice.id') || this.get('transportType.requiresTimeWindow') && !this.get('transportType.adviceSeparatedFromWindow');
    }),
    showWeightSection: Ember.computed('transport.{id,enabledWeights,transportType.authorityRestrictions.@each.authorityRange}', function () {
      return this.get('transport').actionCanBePerformed('ROLE_ACCESS_WEIGHT_SECTION') && this.get('transport.enabledWeights').length > 0;
    }),
    showDiscrepancyReportSection: Ember.computed('transportType.hasDiscrepancyReportEnabled', 'transport.id', function () {
      return this.get('transport.id') && this.get('transportType.hasDiscrepancyReportEnabled');
    }),
    showInspectionSection: Ember.computed('', function () {
      return this.get('transport').actionCanBePerformed('ROLE_ACCESS_TO_INSPECTION_PANEL');
    }),
    showWarehouseSection: Ember.computed('transportType.{hasVisibleSteps,requiresWarehouse,requiresTimeWindow}', function () {
      if (this.get('transportType.requiresWarehouse')) {
        return true;
      }

      return this.get('transportType.requiresTimeWindow') && !this.get('transportType.hasVisibleSteps');
    }),
    showCarrierSection: Ember.computed('transport.transportType', function () {
      if (!this.get('transport')) {
        return false;
      }

      return this.get('transport').actionCanBePerformed('ACCESS_CARRIER_COMPANY_FIELD');
    }),
    showCarrierChooserSection: Ember.computed('transport.carrierCompany.{id,company.id}', function () {
      if (!this.get('sessionAccount.currentCompany.isPrincipal') || !this.get('transport.carrierCompanyChooser.id')) {
        return false;
      }

      return this.get('transport.carrierCompany.id') && this.get('transport.carrierCompanyChooser.company.id') !== this.get('principalCompanyId');
    }),
    showServiceSection: Ember.computed('transport.transportType', function () {
      if (!this.get('transportType.allowsService')) {
        return false;
      }

      return this.get('transport').actionCanBePerformed('ACCESS_SERVICE_COMPANY_FIELD');
    }),
    showWorkerSection: Ember.computed('transport.serviceCompany', function () {
      var transport = this.get('transport');
      return !!this.get('transport').get('serviceCompany.id') && transport.actionCanBePerformed('ROLE_ACCESS_WORKER_FIELD');
    }),
    indexSetting: Ember.computed('transport.transportType.indexSetting', function () {
      return this.get('transport.transportType.indexSetting');
    }),
    shouldShowIndexesSection: Ember.computed('transport.transportType', function () {
      if (!this.get('transport')) {
        return false;
      }

      var transport = this.get('transport').actionCanBePerformed ? this.get('transport') : this.get('transport.content');
      return transport.actionCanBePerformed('ACCESS_INDEX');
    }),
    shouldShowCourierPackagesSection: Ember.computed('transport.transportType', function () {
      if (!this.get('transport')) {
        return false;
      }

      var transport = this.get('transport').actionCanBePerformed ? this.get('transport') : this.get('transport.content');
      return transport.actionCanBePerformed('ACCESS_PACKAGES');
    }),
    shouldDisabledDescription: Ember.computed('transport.transportType', 'asPlainTemplate', function () {
      if (this.get('asPlainTemplate') || !this.get('transport')) {
        return true;
      }

      return !this.get('transport').actionCanBePerformed('EDIT_DESCRIPTION_FIELD');
    }),
    showDescriptionField: Ember.computed('transport.transportType', 'asPlainTemplate', function () {
      if (!this.get('transport')) {
        return false;
      }

      return this.get('transport').actionCanBePerformed('ACCESS_DESCRIPTION_FIELD');
    }),

    /**
     * Sekcję kontrahenta trzeba pokazać, jeśli transport go wymaga, ale w formularzu nie są widoczne stepy,
     * albo są widoczne tylko stepy związane z magazynem.
     */
    showContractorSection: Ember.computed('transportType.{hasVisibleSteps,requiresContractor,requiresTimeWindow}', 'transport.contractorSteps.length', function () {
      if (!this.get('transportType.allowsContractor')) {
        return false;
      }

      var hasVisibleContractorSteps = this.get('transport.contractorSteps.length') > 0;
      var hasVisibleSteps = hasVisibleContractorSteps && this.get('transportType.hasVisibleSteps');

      if (this.get('transportType.requiresContractor') && !hasVisibleSteps) {
        return true;
      }

      if (this.get('transportType.requiresTimeWindow') && !hasVisibleSteps) {
        return true;
      }

      return !hasVisibleSteps;
    }),
    shouldShowCouriersSection: Ember.computed('transport.transportType', function () {
      return this.get('transport').actionCanBePerformed('ROLE_CAN_ACCESS_COURIERS_SECTION');
    }),
    shouldShowCourierInvoiceSection: Ember.computed('transport.transportType', function () {
      return this.get('transport').actionCanBePerformed('ROLE_CAN_ACCESS_COURIERS_SECTION');
    }),
    showErpIdentifier: Ember.computed('transportType.{requiresErpIdentifier,allowsErpIdentifier}', function () {
      return this.get('transportType.requiresErpIdentifier') || this.get('transportType.allowsErpIdentifier');
    }),
    showAltIdentifier: Ember.computed('transportType.{allowsAltIdentifier,requiresAltIdentifier}', function () {
      return this.get('transportType.allowsAltIdentifier') || this.get('transportType.requiresAltIdentifier');
    }),
    disabledAltIdentifier: Ember.computed('transport.canBeUpdated', 'transportType.altIdentifierIsCreatedAutomatically', function () {
      return !this.get('transport.canBeUpdated') || this.get('transportType.altIdentifierIsCreatedAutomatically');
    }),
    showPrintIcon: Ember.computed('transport.id', function () {
      return this.get('sessionAccount').getSettingValue('ORDER_FORM_PRINTING_ENABLED') === 'true' && this.get('transport.id');
    }),
    showDocumentSection: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue('DOCUMENT_SECTION_VISIBLE_IN_TRANSPORT_FORM') === 'true';
    }),
    showAdditionalAddFileButton: Ember.computed('', function () {
      return this.get('showDocumentSection') && this.get('sessionAccount').getSettingValue('ADDITIONAL_ADD_FILE_BUTTON') === 'true';
    }),
    warehouseLabel: Ember.computed('', function () {
      var settingName = this.get('languageService').findProperLanguageVersion('WAREHOUSE_LABEL', 'WAREHOUSE_LABEL_EN');
      var setting = this.get('sessionAccount').getSettingValue(settingName);
      return setting || this.get('intl').t('transport.warehouse');
    }).readOnly(),
    assortmentGroupLabel: Ember.computed('', function () {
      this.get('languageService').findProperLanguageVersion('name', 'englishName');
      var key = this.get('languageService').findProperLanguageVersion('ASSORTMENT_GROUP_LABEL', 'ASSORTMENT_GROUP_LABEL_EN');
      return this.get('sessionAccount').getSettingValue(key);
    }),
    assortmentGroups: Ember.computed('transportType', 'transport.warehouse.id', function () {
      var self = this;
      return this.get('store').peekAll('assortmentGroup').filter(function (ag) {
        return ag.get('transportTypeIds').includes(self.get('transportType.id')) && ag.get('warehouse.id') === self.get('transport.warehouse.id') && ag.get('enabled') === true;
      });
    }),
    showAssortmentGroupSection: Ember.computed('assortmentGroups.[]', function () {
      return this.get('assortmentGroups.length') > 0 && this.get('transport.warehouse.id');
    }),
    hideSaveTransportButton: true,
    // eslint-disable-next-line ember/no-observers
    setHideSaveTransportButton: Ember.observer('model', function () {
      if (this.get('canNotEditBecauseOfOtherContractors')) {
        this.set('hideSaveTransportButton', true);
        return;
      }

      this.set('hideSaveTransportButton', false);
    }),
    // eslint-disable-next-line ember/no-observers
    setRequiresAcceptanceOfTermsOfCondition: Ember.observer('model.advice', function () {
      if (!this.get('model.advice.content')) {
        return;
      }

      var s = this.get('sessionAccount').getSettingValue('SHOW_TERMS_OF_CONDITIONS_ACCEPTED');
      this.get('model.advice').set('requiresAcceptanceOfTermsOfCondition', s === 'true');
    }),
    // eslint-disable-next-line ember/no-observers
    scheduleOpenPanelsAssociatedWithCustomFields: Ember.observer('model.customFields.@each.isAssociatedWithPanel', function () {
      Ember.run.once(this, 'openPanelsAssociatedWithCustomFields');
    }),
    openPanelsAssociatedWithCustomFields: function openPanelsAssociatedWithCustomFields() {
      if (!this.get('model')) {
        return;
      }

      var self = this;
      this.get('model.customFields').filterBy('isAssociatedWithPanel').forEach(function (cf) {
        var transportPanelClass = cf.get('definition.TRANSPORT_PANEL_CLASS')[cf.get('option.transportPanel')];
        self.openPanel(transportPanelClass);
      });
    },
    canNotEditAfterProcessStarts: Ember.computed('model.{id,canBeUpdated}', 'hideSaveTransportButton', 'canNotEditBecauseOfOtherContractors', function () {
      return this.get('hideSaveTransportButton') || this.get('canNotEditBecauseOfOtherContractors') || this.get('model.id') && !this.get('model').get('canBeUpdated');
    }),
    canNotEditBecauseOfOtherContractors: Ember.computed('model.contractors.length', function () {
      var partnershipName = this.get('sessionAccount.currentUser.company.partnershipWithPrincipal.partnershipType.name');
      var isCurrentUserContractor = partnershipName === 'SUPPLIER' || partnershipName === 'OPERATOR';

      if (!isCurrentUserContractor) {
        return false;
      }

      return this.get('model.contractors.length') > 1;
    }),
    principalCompany: Ember.computed('', function () {
      return this.store.peekRecord('company', localStorage.getItem('principalId'));
    }),
    // eslint-disable-next-line ember/no-observers
    carrierChanged: Ember.observer('transport.carrierCompany.id', function () {
      var advice = this.get('transport.advice');

      if (advice === undefined || advice.get('content') === null || advice.get('id') || !this.get('transport.carrierCompany.id')) {
        return;
      }

      advice.setProperties({
        driverLanguage: null,
        driverName: null,
        driverMobilePhoneNumber: null,
        driverMobilePhonePrefix: '48',
        carRegistrationNumber: null,
        trailerRegistrationNumber: null,
        documentNumber: null,
        documentType: 'ID_CARD',
        termsOfConditionsAccepted: false
      });
    }),
    isVisibleCheckpointConfirmation: Ember.computed('transport.firstUnconfirmedCheckpoint', function () {
      if (this.get('asPlainTemplate')) {
        return false;
      }

      var checkpoints = this.get('transport.checkpointsThatCanBeConfirmedByGuard').filterBy('id', this.get('transport.firstUnconfirmedCheckpoint.id'));
      return !this.get('sessionAccount.currentUser.roleGroup.isGuard') || this.get('sessionAccount.currentUser.roleGroup.isGuard') && checkpoints.length;
    }),
    logsProxy: Ember.computed('transport.{notificationLogs.[],transportLogs.[]}', function () {
      var _this = this;

      var logs = Ember.A();
      this.get('transport.transportLogs').forEach(function (tL) {
        return logs.pushObject(tL);
      });
      this.get('transport.notificationLogs').forEach(function (nL) {
        return logs.pushObject(nL);
      });
      this.get('transport.weightLogs').forEach(function (wL) {
        return logs.pushObject(wL);
      });
      return logs.filter(function (log) {
        return !_this.get('excludedLogNames').includes(log.get('propertyName'));
      });
    }),
    integrationLogsProxy: Ember.computed('transport.apiIntegrationLogs.[]', function () {
      var logs = Ember.A();
      this.get('transport.apiIntegrationLogs').forEach(function (log) {
        return logs.pushObject(log);
      });
      return logs;
    }),
    artrLogsProxy: Ember.computed('transport.artrApiLogs.[]', function () {
      var logs = Ember.A();
      this.get('transport.artrApiLogs').forEach(function (log) {
        logs.pushObject(log);
      });
      return logs;
    }),
    saveAndCloseButtonLabel: Ember.computed('transport.transportType.weighingOnSave', function () {
      return this.get('transport.transportType.weighingOnSave') && !this.get('transport.id') ? this.get('intl').t('common.weightAndSave') : this.get('intl').t('common.saveAndClose');
    }),
    saveButtonLabel: Ember.computed('transport.transportType.weighingOnSave', function () {
      return this.get('transport.transportType.weighingOnSave') && !this.get('transport.id') ? this.get('intl').t('common.weightAndSave') : this.get('intl').t('common.save');
    }),
    showAdditionalInformation: Ember.computed('asPlainTemplate', 'additionalInformationInAppropriateLanguage', function () {
      return this.get('asPlainTemplate') && this.get('additionalInformationInAppropriateLanguage');
    }),
    firstUnconfirmedCheckpoint: Ember.computed('transport.firstUnconfirmedCheckpoint', function () {
      return this.get('transport.firstUnconfirmedCheckpoint');
    }),
    checkpointRejectionDateShouldBeVisible: Ember.computed('firstUnconfirmedCheckpoint.{canBeRejected,rejectionDate}', function () {
      return this.get('firstUnconfirmedCheckpoint.canBeRejected') && this.get('firstUnconfirmedCheckpoint.rejectionDate');
    }),
    additionalInformationInAppropriateLanguage: Ember.computed('', function () {
      var settingName = this.get('languageService').findProperLanguageVersion('ADDITIONAL_INFO_ON_PLAIN_TEMPLATE_VIEW', 'ADDITIONAL_INFO_ON_PLAIN_TEMPLATE_VIEW_EN');
      return this.get('sessionAccount').getSettingValue(settingName);
    }),
    showTransactionPriceSection: Ember.computed('asPlainTemplate', 'transport.{id,transaction}', function () {
      if (this.get('transport.id') && this.get('transport').actionCanBePerformed('ROLE_ACCESS_FREIGHT_DATA') && this.get('transport.carrierCompany.id')) {
        return true;
      }

      return this.get('asPlainTemplate') && this.get('transport.transaction');
    }),
    transactionPriceLabel: Ember.computed('transport.transportType.id', function () {
      var transportType = this.get('transport.transportType');
      var transactionSetting = this.get('sessionAccount').getTransactionSetting(transportType);
      return transactionSetting ? transactionSetting.get('priceLabel') : this.get('intl').t('transaction.price');
    }),
    _setGoodsValueFromCourierPackages: function _setGoodsValueFromCourierPackages(transport) {
      var enabledPackages = transport.get('courierPackages').filter(function (cp) {
        return cp.enabled;
      });

      if (enabledPackages && enabledPackages.length > 0) {
        var price = 0.0;
        enabledPackages.forEach(function (cp) {
          var totalPrice = Number(cp.get('numberOfPackages')) * Number(cp.get('packageValue'));
          price += totalPrice;
        });
        transport.set('goodsValue', price);
      }
    },
    _clearDuplicatedCustomFieldValues: function _clearDuplicatedCustomFieldValues(transport) {
      if (transport.get('customFields.length') < 1) {
        return;
      }

      var clearedCustomFields = Ember.A([]);
      transport.get('customFields').sortBy('lastUpdated').forEach(function (cf) {
        if (clearedCustomFields.some(function (it) {
          return it.get('definition.id') === cf.get('definition.id');
        })) {
          return;
        }

        clearedCustomFields.pushObject(cf);
      });
      transport.set('customFields', clearedCustomFields);
    },
    _handleAddressesFromSteps: function _handleAddressesFromSteps(transport) {
      var _this2 = this;

      // Poniższe zostało dodane w ramach zadania A-8354.
      // Gdy step posiada atrybut newAddressAsDefault, po otwarciu modala transportu adres nie resetuje się.
      // Przy zapisie transportu, zmiany w jakichkolwiek polach adresu stepów, nie zapisywały się do bazy.
      // W ten sposób wymuszamy, aby przy jakiejkolwiek zmianie, tworzyć nowy rekord adresu z odpowiednimi danymi i podstawiać w miejsce starego.
      transport.get('steps').forEach(function (step) {
        if (step.get('address').get('hasDirtyAttributes') || step.get('address').get('isDirty')) {
          var oldAddress = step.get('address');

          var newAddress = _this2.get('store').createRecord('address', {
            country: oldAddress.get('country'),
            company: oldAddress.get('company'),
            recipient: oldAddress.get('recipient'),
            city: oldAddress.get('city'),
            postal: oldAddress.get('postal'),
            street: oldAddress.get('street'),
            province: oldAddress.get('province'),
            isHeadquarters: oldAddress.get('isHeadquarters'),
            enabled: oldAddress.get('enabled')
          });

          step.set('address', newAddress);
        }

        if (step.get('shouldAutoSaveCompanyGroup')) {
          step.set('companyGroup', step.get('company.nonDefaultUserGroups').get('firstObject'));
        }
      });
    },
    prepareTransportForSaving: function prepareTransportForSaving(transport) {
      var self = this;
      var saveTransportButton = (0, _jquery.default)('.save-transport-button');
      saveTransportButton.button('loading');
      transport.set('principalCompany', self.get('principalCompany'));
      transport.set('advice', self.removeWhiteSpacesAndSetUpperCase(transport.get('advice')));
      transport.get('customFields').filterBy('definition.isNumberType').forEach(function (pair) {
        if (pair.get('value')) {
          pair.set('value', _numberParsers.default.getParsableNumber(pair.get('value')));
        }
      });

      self._handleAddressesFromSteps(transport);

      self._setGoodsValueFromCourierPackages(transport);

      transport.validate();
      var transportIsValidate = self.customValidate();

      self._clearDuplicatedCustomFieldValues(transport);

      if (transport.get('hasErrors') || !transportIsValidate) {
        self.printErrors(transport);
        self.get('errorService').scrollToFirstError();
        saveTransportButton.button('reset');
        return false;
      }

      if (transport.get('id')) {
        console.debug("Updating transport #".concat(transport.get('id'), ".."));
        this.set('newTransport', false);
      } else {
        console.debug('Saving new transport..');
        this.set('newTransport', true);
      }

      var warehouse = transport.get('warehouse');
      var warehouseStep = this.get('transport.warehouseStep');

      if (warehouseStep && warehouse && transport.get('transportType.typeGroup') !== transport.get('transportType.TYPE_GROUPS.TRANSPORT')) {
        warehouseStep.set('warehouse', warehouse);
      }

      if (transport.get('sureToSaveMessage')) {
        return confirm(this.get('intl').t('transport.sureToSaveMessage'));
      }

      return true;
    },
    handleSavedTransport: function handleSavedTransport(transport, self, saveAndCloseModal) {
      var transportId = transport.get('id');
      console.log("We successfully saved transport ".concat(transport.get('logString'), "!"));

      if (self.get('newTransport') && transport.get('transportType.weighingOnSave')) {
        transport.get('checkpoints').then(function (checkpoints) {
          var weighingCheckpoints = checkpoints.filterBy('executionDate').filter(function (checkpoint) {
            return checkpoint.get('task.weighingIncluded');
          });

          if (weighingCheckpoints.get('length') === 0) {
            console.debug('Brak checkpointów do zważenia');
          } else {
            console.debug('Wykonujemy ważenie dla nowego transportu przy zapisie');
            self.get('transportController').send('weigh', weighingCheckpoints.get('firstObject'));
          }
        });
      }

      self.existingFilesToRemove.forEach(function (fileId) {
        console.log('self.existingFilesToRemove..');
        self.deleteFile(fileId);
      });
      self.existingFilesToRemove.clear();
      var token = self.get('sessionAccount').get('token');

      if (self.attachments) {
        self.uploadAttachment(transport.id, token);
        self.attachments.clear();
      }

      self.handleStepsUpdate(transport);
      transport.get('customFields').then(function (customFields) {
        customFields.forEach(function (cf) {
          return cf.set('changeMessage', null);
        });
        var customFieldsCopy = customFields.slice(0);
        customFieldsCopy.forEach(function (cs) {
          if (cs !== undefined && !cs.get('id') && !cs.get('isFilled') && cs.unloadRecord) {
            console.debug("Unloading redundant custom field: ".concat(cs.get('definition.name'), " for newly saved transport ").concat(transportId, ".."));
            transport.get('customFields').removeObject(cs);
            cs.unloadRecord();
          }
        });
      });
      transport.get('timeWindows').then(function (timeWindows) {
        var timeWindowsCopy = timeWindows.slice(0);
        timeWindowsCopy.forEach(function (tw) {
          if (tw !== undefined && !tw.get('id') && tw.unloadRecord) {
            console.debug("Unloading redundant time window for newly saved transport ".concat(transportId, ".."));
            transport.get('timeWindows').removeObject(tw);
            tw.unloadRecord();
          }
        });
      });
      self.send('handleUpdatedIndexes', transport);
      self.send('handleUpdatedCourierPackages', transport);
      self.get('transportType').set('forcedPollRequestTime', new Date().getTime());
      self.set('errors', []);

      if (saveAndCloseModal) {
        self.send('hideModal');
      } else if (transport.get('id')) {
        self.refreshTransport(transport);
      }

      self.get('scheduleController').setProperties({
        warehouseStepToHandle: null
      });
    },
    // eslint-disable-next-line ember/no-observers
    periodicTransportActiveObserver: Ember.observer('transport.periodicTransportActive', function () {
      // jeśli włączamy awizacje okresową, korygujemy datę żeby była spójna z planowaną datą awizacji
      if (this.get('transport.periodicTransportActive')) {
        this.set('transport.periodicTransport.startDate', this.get('dateOfAdvice'));
      }
    }),
    _generateLopReport: function _generateLopReport() {
      var transportId = this.get('transport.id');
      var token = this.get('sessionAccount').get('token');
      var url = _environment.default.serverURL + '/reports/' + transportId + '/generateLopReport?token=' + token;
      return window.open(url, '_blank');
    },
    showWarehouseLocation: Ember.computed('firstStepWithWarehouse', 'transport.steps.[]', function () {
      var stepWithWarehouse = !!this.get('transport.steps.firstObject').get('warehouse.id');
      return this.get('transport.transportType.isWarehouseLocationVisible') && stepWithWarehouse;
    }),
    transportTypeHasVisibleSteps: Ember.computed('transport.transportType', function () {
      return this.get('transportType.hasVisibleSteps');
    }),
    getWarehouseLocationsList: Ember.computed('transport.steps.[]', 'showWarehouseLocation', function () {
      return this.get('transport.steps.firstObject').get('warehouse.warehouseLocations');
    }),
    warehouseLocationLabel: Ember.computed('transport', function () {
      return this.get('intl').t('warehouse.warehouseLocationLabel');
    }),
    maximumPermissableVehicleWeight: Ember.computed('model', function () {
      return this.get('sessionAccount').getSettingValue('MAXIMUM_PERMISSIBLE_VEHICLE_WEIGHT_SETTING');
    }),
    weightFromSteps: Ember.computed('transport', function () {
      var weights = this.get('transport').get('steps');
      return weights.reduce(function (accumulator, step) {
        return step.get('STEP_TYPES').UNLOAD ? step.get('weight') + accumulator : 0;
      }, 0);
    }),
    weightFromTransport: Ember.computed('transport', function () {
      var weights = this.get('transport.weights');
      return weights.reduce(function (accumulator, weight) {
        return weight.get('enabled') ? weight.get('readOut') + accumulator : 0;
      }, 0);
    }),
    checkIfVehicleIsOverweight: Ember.computed('weightFromTransport', 'weightFromSteps', 'maximumPermissableVehicleWeight', function () {
      // Wartość z settingu jest podawana w tonach, natomiast wagi w zleceniu są w kg dlatego zamieniamy wartosć z settingu na kg
      var weightFromSetting = this.maximumPermissableVehicleWeight * 1000;
      return this.weightFromSteps + this.weightFromTransport > weightFromSetting;
    }),
    canRemoveTransportRelation: Ember.computed('transport.{masterTransports.length,relatedToTransports.length}', function () {
      var canRemove = this.get('transport').actionCanBePerformed('ROLE_CAN_DELETE_RELATION');
      var isMaster = this.get('transport.relatedToTransports.length') > 0;
      var isRelatedTo = this.get('transport.masterTransports.length') > 0;
      return canRemove && (isRelatedTo || isMaster);
    }),
    warehouseLocationDisabled: Ember.computed('transport.transportType.authorityRestrictions.@each.authorityRange', function () {
      var transport = this.get('transport');

      if (transport) {
        return !transport.actionCanBePerformed('ROLE_EDIT_WAREHOUSE_LOCATION');
      } else return false;
    }),
    showLocationField: Ember.computed('transport', 'transport.transportType', 'showWarehouseSection', function () {
      var transport = this.get('transport');
      return transport.actionCanBePerformed('ROLE_ACCESS_TO_LOCATION');
    }),
    getWorkersList: Ember.computed('transport.serviceCompany', function () {
      return this.get('transport.serviceCompany').get('workers');
    }),
    editWorkerField: Ember.computed('transport.serviceCompany', function () {
      var transport = this.get('transport');
      return !transport.actionCanBePerformed('ROLE_EDIT_WORKER_FIELD');
    }),
    workerFieldLabel: Ember.computed('transport.serviceCompany', function () {
      var translationKey = this.get('transport').get('transportType.workerFieldTranslationKey');
      return translationKey || 'WORKERS';
    }),
    transportSupervisorLabel: Ember.computed('transport.transportType.transportSupervisorTranslationKey', function () {
      var translationKey = this.get('transport').get('transportType.transportSupervisorTranslationKey');
      return translationKey || 'transportSupervisor';
    }),
    getTransportSupervisorList: Ember.computed('principalCompany', function () {
      return this.get('principalCompany').get('users');
    }),
    addWorkerButtonLabel: Ember.computed('transport.serviceCompany', function () {
      var translationKey = this.get('transport').get('transportType.workerFieldTranslationKey');

      if (translationKey === 'GUESTS') {
        return 'addGuest';
      } else if (translationKey === 'SERVICEMEN') {
        return 'addServiceman';
      } else return 'addWorker';
    }),
    tasksArray: Ember.computed('model.transportType', function () {
      return Ember.A(_toConsumableArray(this.get('model.transportType.workflow.sortedTasks')));
    }),
    refreshRelatedHasToWaitTransports: function refreshRelatedHasToWaitTransports(transport) {
      if (!transport.get('isMasterTransportInHasToWaitFor')) {
        return;
      }

      transport.get('relatedHasToWaitForTransports').forEach(function (t) {
        if (!t.get('id')) {
          return;
        }

        t.get('transportType').set('transportsTabVisited', false);
        t.reload();
      });
    },
    actions: {
      generateLOPReport: function generateLOPReport() {
        console.log('Generujemy raport LOP');
        Ember.run.once(this, '_generateLopReport');
      },
      resizeTextArea: function resizeTextArea() {
        if (this.get('asPlainTemplate')) {
          var element = (0, _jquery.default)('.textarea-resize');
          var height = 5 + element.prop('scrollHeight') + 'px';
          element.css('height', height);
        }
      },

      /**
       * Metoda planująca zapisanie transportu.
       *
       * W przypadku dodawania opcji za pośrednictwem pola z podpowiadaniem, musimy trochę
       * poczekać na wykonanie wszystkich procesów.
       */
      save: function save() {
        var saveAndCloseModal = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
        var saveTransportButton = (0, _jquery.default)('.save-transport-button');
        saveTransportButton.button('loading');
        var self = this;
        var transportModel = this.get('model');
        Ember.run.later(this, function () {
          if (transportModel.then !== undefined) {
            transportModel.then(function (transport) {
              return self.send('saveOrUpdateTransport', transport, saveAndCloseModal);
            });
          } else {
            self.send('saveOrUpdateTransport', transportModel, saveAndCloseModal);
          }
        }, 250);
      },
      createSimilar: function createSimilar(transportId) {
        var self = this;
        var multiple = this.get('transport').actionCanBePerformed('ROLE_CREATE_SIMILAR_MULTIPLE');

        if (multiple) {
          var model = {
            transport: self.get('transport'),
            count: 1,
            saving: false
          };
          this.send('showModal', 'transports.modals.create-similar-multiple', model);
        } else {
          this.send('hideModal');
          var transportModel = this.get('store').peekRecord('transport', transportId);
          this.get('transportCopyService').copyTransport(transportModel).then(function (t) {
            setTimeout(function () {
              console.log('Pokazujemy modal po wybraniu opcji "Utwórz podobne"...');
              self.send('showModal', 'transports.modals.create-transport', t);
            }, 1000);
          });
        }
      },
      restoreFromArchive: function restoreFromArchive(transportId) {
        var _this3 = this;

        this.get('ajaxService').send("/transports/".concat(transportId, "/restore"), 'PUT').then(function () {
          alert(_this3.get('intl').t('transport.restoredSuccessfully'));

          _this3.send('hideModal');
        }).catch(function (response) {
          (0, _jquery.default)('.transport-modal').scrollTop(0);

          _this3.set('errors', response.responseJSON.errors);
        });
      },
      removeTransportRelation: function removeTransportRelation(transport) {
        var _this4 = this;

        var self = this;
        var masterTransport = transport.get('masterTransports.firstObject');
        var transportTransportRelation = transport.get('relatedTo.firstObject');
        this.get('ajaxService').send("/transport-transport-relations/".concat(transportTransportRelation.id), 'DELETE').then(function () {
          if (masterTransport) {
            masterTransport.get('transportType').set('transportsTabVisited', false);
            masterTransport.reload();
            self.refreshRelatedHasToWaitTransports(masterTransport);
          }

          _this4.get('transport.transportType').set('transportsTabVisited', false);

          _this4.get('transport').reload();

          _this4.send('refreshRoute');

          _this4.send('hideModal');
        }).catch(function (response) {
          (0, _jquery.default)('.transport-modal').scrollTop(0);

          _this4.set('errors', response.errors);
        });
      },
      saveOrUpdateTransport: function saveOrUpdateTransport(transport) {
        var saveAndCloseModal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
        var self = this;
        var valid = self.prepareTransportForSaving(transport);

        if (!valid) {
          (0, _jquery.default)('.save-transport-button').button('reset');
          return;
        }

        transport.set('updateAllFuturePeriodicTransports', this.updateAllFuturePeriodicTransports(transport));
        transport.save().then(function () {
          return self.handleSavedTransport(transport, self, saveAndCloseModal);
        }).catch(function (response) {
          (0, _jquery.default)('.transport-modal').scrollTop(0);
          self.set('errors', response.errors);
        }).finally(function () {
          return (0, _jquery.default)('.save-transport-button').button('reset');
        });
      },
      handleClickWithSpot: function handleClickWithSpot(timeWindowCandidate) {
        var self = this;
        self.set('errors', []);
        self.send('selectWindow', timeWindowCandidate);
      },
      handleUpdatedIndexes: function handleUpdatedIndexes(transport) {
        if (!transport.get('indexes.length')) {
          return;
        }

        transport.get('indexes').then(function (indexes) {
          var indexesCopy = indexes.slice(0);
          indexesCopy.forEach(function (index) {
            if (index !== undefined && !index.get('id')) {
              console.debug("Unloading redundant index for newly saved transport ".concat(transport.get('id'), ".."));
              transport.get('indexes').removeObject(index);
              index.unloadRecord();
            } else if (index !== undefined) {
              // W wyniku bugu (z 2013 roku) ember-data [https://github.com/emberjs/data/issues/1540],
              // określanie `hasDirtyAttributes` nie działa w przypadku numerów ponieważ zaktualizowane
              // pola są zapisywane w modelu jako `string`, a przychodzące z serwera jako `number`.
              //
              // Na razie nie używam zbyt często `hasDirtyAttributes`, dlatego nie próbuję pisać jakiegoś
              // mixina albo serwisu, ale w przyszłości można się nad tym zastanowić.
              index.get('quantities').forEach(function (q) {
                if (typeof q.get('quantity') === 'string') {
                  q.set('quantity', parseFloat(q.get('quantity')));
                }
              });
            }
          });
        });
      },
      handleUpdatedCourierPackages: function handleUpdatedCourierPackages(transport) {
        if (!transport.get('courierPackages.length')) {
          return;
        }

        transport.get('courierPackages').then(function (cps) {
          var cpCopy = cps.slice(0);
          cpCopy.forEach(function (cp) {
            if (cp !== undefined && !cp.get('id')) {
              console.debug("Unloading redundant courierPackages for newly saved transport ".concat(transport.get('id'), ".."));
              transport.get('courierPackages').removeObject(cp);
              cp.unloadRecord();
            }
          });
        });
      },

      /**
       * Metoda planująca przeniesienie użytkownika do harmonogramu.
       *
       * W przypadku dodawania opcji za pośrednictwem pola z podpowiadaniem, musimy trochę
       * poczekać na wykonanie wszystkich procesów.
       */
      planSelectWindow: function planSelectWindow() {
        Ember.run.later(this, function () {
          this.send('selectWindow');
        }, 250);
      },
      selectWindow: function selectWindow(timeWindowCandidate) {
        var _this5 = this;

        var self = this;
        var transport = self.get('transport');
        transport.get('customFields').filterBy('definition.isNumberType').forEach(function (pair) {
          if (pair.get('value')) {
            pair.set('value', _numberParsers.default.getParsableNumber(pair.get('value')));
          }
        });
        transport.get('steps').forEach(function (step) {
          if (step.get('shouldAutoSaveCompanyGroup')) {
            step.set('companyGroup', step.get('company.nonDefaultUserGroups').get('firstObject'));
          }
        });
        var attachments = this.get('attachments'); // `taskGroupIdentifier === 0` to grupa tasków o nazwie "Prace przygotowawcze", pierwszym taskiem
        // z tej grupy zawsze jest utworzenie zlecenia dlatego `idxInGroup === 0` zawsze powinien być taskiem tworzącym zlecenie

        var orderCreationTask = Ember.A(this.get('tasksArray').filter(function (t) {
          return t.taskGroupIdentifier === 0 && t.idxInGroup === 0;
        })).get('firstObject');

        if (orderCreationTask.canBeConfirmAfterAddingDocumentsForTransport && attachments.length === 0) {
          self.setProperties({
            errors: Ember.A(["Etap '".concat(orderCreationTask.checkpointName, "' wymaga dodania dokument\xF3w do transportu przed jego utworzeniem")])
          });
          return;
        }

        transport.validate();
        var transportIsValidate = self.customValidate();

        if (transport.get('hasErrors') || !transportIsValidate) {
          self.get('errorService').scrollToFirstError();
          self.printErrors(transport);
          return;
        }

        var transportType = transport.get('transportType');
        var warehouse = transport.get('warehouse');
        var warehouseStep = this.get('transport.warehouseStep');

        if (warehouseStep && warehouse && transport.get('transportType.typeGroup') !== transport.get('transportType.TYPE_GROUPS.TRANSPORT')) {
          warehouseStep.set('warehouse', warehouse);
        }

        if (transportType.get('typeGroup') === transportType.get('TYPE_GROUPS').SIMPLIFIED_DELIVERY) {
          transport.get('lastStep').setProperties({
            warehouse: warehouse,
            company: transport.get('firstContractor')
          });
        }

        var warehouseStepToHandle = transport.get('firstWarehouseStepWithoutWindow') ? transport.get('firstWarehouseStepWithoutWindow') : transport.get('firstChangedWarehouseStep');

        if (transport.get('firstChangedWarehouseStep')) {
          warehouseStepToHandle.set('didWarehouseChange', false);
        }

        (0, _jquery.default)('#main-modal').data('skip-cancel-action', true);

        if (timeWindowCandidate) {
          console.debug("Wybrana data awizacji: ".concat(timeWindowCandidate.start));
          self.set('dateOfAdvice', timeWindowCandidate.start);
          self.send('hideModal');
          self.get('scheduleController').send('handleClickWithTransport', timeWindowCandidate.start, timeWindowCandidate.stop, timeWindowCandidate.ramp.id, timeWindowCandidate.start);
          self.send('makeTransitionToWarehouse', warehouseStepToHandle);
        } else {
          console.debug('Szukamy wolnego miejsca w harmonogramie..');
          this.getFirstPossibleWindowStart(warehouseStepToHandle, transport, this.get('dateOfAdvice')).then(function (response) {
            var expectedAdviceDate = _this5.get('dateOfAdvice') ? moment(_this5.get('dateOfAdvice')).format('YYYY-MM-DD') : null;
            var firstAvailable = response.firstPossibleStart ? moment(response.firstPossibleStart).format('YYYY-MM-DD') : null;

            if (!response.firstPossibleStart) {
              self.setProperties({
                errors: [response.error]
              });
              return;
            }

            var errorMessage = self.get('intl').t('timeWindow.errors.warehouseHasNoSpaceThisDay', {
              expectedAdviceDate: expectedAdviceDate
            });

            if (expectedAdviceDate && firstAvailable && expectedAdviceDate < firstAvailable) {
              self.setProperties({
                errors: [errorMessage]
              });
              return;
            }

            self.send('hideModal');
            self.send('makeTransitionToWarehouse', warehouseStepToHandle);
          });
        }
      },
      makeTransitionToWarehouse: function makeTransitionToWarehouse(warehouseStepToHandle) {
        var self = this;
        var transport = self.get('transport');
        var dateString = moment(self.get('dateOfAdvice')).format('YYYY-MM-DD');
        var formattedStart = moment(self.get('dateOfAdvice')).format('DD.MM.YYYY HH:mm');
        var controller = self.get('scheduleController');

        if (controller.get('warehouse.loadedDays') && controller.get('warehouse.loadedDays').includes(formattedStart)) {
          controller.get('warehouse.loadedDays').removeObject(formattedStart);
        }

        controller.setProperties({
          attachments: self.get('attachments'),
          errors: null,
          transportToSave: transport,
          warehouseStepToHandle: warehouseStepToHandle
        });
        console.debug("Przechodzimy do harmonogramu w magazynie: ".concat(warehouseStepToHandle.get('warehouse.name'), " w dniu: ").concat(dateString));
        self.transitionToRoute('warehouses.schedule', warehouseStepToHandle.get('warehouse.id'), dateString, '1');
      },
      cancelCreateWindow: function cancelCreateWindow() {
        var _this6 = this;

        this.set('selectedWarehouse', null);
        var model = this.get('transport');
        var transportTypeAlias = model.get('transportType.alias');

        if (model) {
          if (model.rollbackAttributes) {
            this.rollbackTransportChanges(model);
          } else {
            model.then(function (transportModel) {
              _this6.rollbackTransportChanges(transportModel);
            });
          }
        }

        this.setProperties({
          errors: [],
          model: null
        });

        if (this.get('scheduleController').get('warehouseStepToHandle')) {
          this.get('scheduleController').setProperties({
            transportToSave: null,
            warehouseStepToHandle: null,
            warningMessage: null
          });
          this.transitionToRoute('transports.index', transportTypeAlias);
        }
      },
      confirmCheckpoint: function confirmCheckpoint(checkpoint) {
        this.get('transportController').send('confirm', checkpoint);
      },
      reject: function reject(checkpoint) {
        if (checkpoint.get('hasCustomFieldsBlockade')) {
          this.showCustomFieldsModal(checkpoint, this.get('transport'), checkpoint.get('ACTIONS').REJECT);
          return;
        }

        if (!this.confirmRejection()) {
          return;
        }

        this.get('transportController').send('reject', checkpoint);
      },
      weigh: function weigh(checkpoint) {
        this.get('transportController').send('weigh', checkpoint);
      },
      deleteTransport: function deleteTransport(transport) {
        var deleteAllFuturePeriodicTransports = this.deleteAllFuturePeriodicTransports(transport);
        var self = this;
        (0, _jquery.default)('.delete-progress').show();
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          type: 'PUT',
          url: _environment.default.serverURL + '/transports/' + transport.get('id') + '/delete?deleteAllFuturePeriodicTransports=' + deleteAllFuturePeriodicTransports,
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          self.setProperties({
            model: null,
            errors: []
          });

          if (transport.get('timeWindows')) {
            console.debug("Transport ".concat(transport.id, " zosta\u0142 usuni\u0119ty -- usuwamy okna z harmonogramu.."));
            self.get('scheduleController').get('windows').removeObjects(transport.get('timeWindows'));
          }

          transport.set('deleted', true);

          if (self.get('target.currentRouteName') === 'warehouses.schedule') {
            self.get('scheduleController').send('onPoll');
          }

          self.refreshRelatedHasToWaitTransports(transport);
          self.send('hideModal');
          transport.set('transportType.forcedPollRequestTime', new Date().getTime());
        });
      },
      getTransportLogs: function getTransportLogs(transport) {
        var self = this;
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/' + transport.get('id') + '/getTransportLogs',
          type: 'GET',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (json) {
          self.get('store').pushPayload(json);
          json.transportLogs.sortBy('dateCreated').reverse().forEach(function (transportLog) {
            var log = self.get('store').peekRecord('transportLog', transportLog.id);
            transport.get('transportLogs').pushObject(log);
          });
        });

        this.send('getNotificationLogs', transport);
        this.send('getWeightLogs', transport);
      },
      getIntegrationLogs: function getIntegrationLogs(transport) {
        var self = this;
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/' + transport.get('id') + '/getIntegrationLogs',
          type: 'GET',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (json) {
          self.get('store').pushPayload(json);
          json.apiIntegrationLogs.sortBy('dateCreated').reverse().forEach(function (apiIntegrationLog) {
            var log = self.get('store').peekRecord('apiIntegrationLog', apiIntegrationLog.id);
            transport.get('apiIntegrationLogs').pushObject(log);
          });
        });
      },
      getIntegrationRequestLog: function getIntegrationRequestLog(log) {
        var self = this;
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/getIntegrationLog/' + log.get('id'),
          type: 'GET',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (json) {
          var apiIntegrationLog = json.apiIntegrationLog;
          var log = self.get('store').peekRecord('apiIntegrationLog', apiIntegrationLog.id);
          log.set('request', apiIntegrationLog.request);
        });
      },
      getNotificationLogs: function getNotificationLogs(transport) {
        var self = this;
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/' + transport.get('id') + '/getNotificationLogs',
          type: 'GET',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (json) {
          self.get('store').pushPayload(json);
          json.notificationLogs.sortBy('dateCreated').reverse().forEach(function (notificationLog) {
            var log = self.get('store').peekRecord('notificationLog', notificationLog.id);
            transport.get('notificationLogs').pushObject(log);
          });
        });
      },
      getWeightLogs: function getWeightLogs(transport) {
        var self = this;
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/' + transport.get('id') + '/getWeightLogs',
          type: 'GET',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (json) {
          self.get('store').pushPayload(json);
          json.weightLogs.sortBy('dateCreated').reverse().forEach(function (weightLog) {
            var log = self.get('store').peekRecord('weightLog', weightLog.id);
            transport.get('weightLogs').pushObject(log);
          });
        });
      },
      getArtrLogs: function getArtrLogs(transport) {
        var self = this;
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/transports/' + transport.get('id') + '/getArtrLogs',
          type: 'GET',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function (json) {
          self.get('store').pushPayload(json);
          json.artrApiLog.sortBy('dateCreated').reverse().forEach(function (artrApiLog) {
            var log = self.get('store').peekRecord('artrApiLog', artrApiLog.id);
            transport.get('artrApiLogs').pushObject(log);
          });
        });
      },
      setWarehouse: function setWarehouse(warehouse, object) {
        var stepForWarehouse = this.get('firstStepWithWarehouse') || this.get('transport.steps.firstObject');
        stepForWarehouse.set('warehouse', warehouse);
        console.debug("Selected warehouse: ".concat(warehouse.get('name')));
        this.clearBootstrapError((0, _jquery.default)('#' + object.uniqueId));
      },
      setAssortmentGroup: function setAssortmentGroup(ag, object) {
        this.get('firstStepWithWarehouse').set('assortmentGroup', ag);
        this.clearBootstrapError((0, _jquery.default)('#' + object.uniqueId));
      },
      showSubModal: function showSubModal(templateName, model) {
        this.send('showModal', templateName, model, 'sub-modal');
      },
      setDateOfAdvice: function setDateOfAdvice(propertyName, date) {
        this.setDateOfPeriodicAdvice(date, propertyName);
        this.set('dateOfAdvice', moment(date[0]).local().format('YYYY-MM-DD'));
      },
      close: function close() {
        this.send('hideModal');
      },
      openAddWorkerModal: function openAddWorkerModal(transportModel, worker) {
        var self = this;
        var principalCompanyId = localStorage.getItem('principalId');
        var principalCompany = this.get('store').peekRecord('company', principalCompanyId);

        if (!worker) {
          worker = this.get('store').createRecord('worker', {
            company: self.get('model.serviceCompany'),
            enabled: true,
            principalCompany: principalCompany
          });
        }

        var model = {
          worker: worker,
          transport: transportModel
        };
        this.send('showModal', 'companies.modals.add-worker', model);
      }
    },
    customValidate: function customValidate() {
      var validates = true;

      if (!this.get('dateOfAdvice')) {
        this.get('transport').get('errors').add('dateOfAdvice', 'blank');
        validates = false;
      }

      if (this.get('transport.weights')) {
        // eslint-disable-next-line no-return-assign
        this.get('transport.weights').forEach(function (w) {
          return validates &= w.validate();
        });
      }

      this.get('transport.steps').forEach(function (s) {
        var errors = 0;
        s.validate();
        s.get('stepPackageTypes').forEach(function (spt) {
          if (!spt.validate()) errors++;
        });
        if (errors > 0) validates = false;
      });
      this.get('transport.timeWindows').forEach(function (tw) {
        if (tw.get('isDirty')) {
          tw.validate();
        }
      });
      this.get('transport.indexes').forEach(function (i) {
        return i.validate();
      }); // eslint-disable-next-line no-return-assign

      this.get('transport.courierPackages').filterBy('enabled').forEach(function (cp) {
        return validates &= cp.validate();
      });
      this.get('transport.customFields').forEach(function (f) {
        return f.validate();
      });

      if (!this.get('transport.id') && this.get('transport.periodicTransportActive')) {
        var periodicTransport = this.get('transport.periodicTransport').validate ? this.get('transport.periodicTransport') : this.get('transport.periodicTransport.content');
        periodicTransport.set('dateOfAdvice', this.get('dateOfAdvice'));
        var periodicTransportValid = periodicTransport.validate();
        validates &= periodicTransportValid;
      }

      var attachments = this.get('attachments');

      if (attachments) {
        var maxCountString = this.get('sessionAccount').getSettingValue('MAX_SIMULTANEOUSLY_ADDED_FILES_COUNT');
        var maxCount = maxCountString ? Number(maxCountString) : null;

        if (maxCount && maxCount < attachments.length) {
          alert(this.get('intl').t('document.maxCountExceeded', {
            maxCount: maxCount
          }));
          validates &= false;
        }
      }

      return validates;
    },
    rollbackTransportChanges: function rollbackTransportChanges(transportModel) {
      transportModel.rollbackAttributes();
      transportModel.get('customFields').then(function (cFs) {
        if (cFs) {
          cFs.forEach(function (c) {
            if (c) {
              c.rollback();
            }
          });
        }
      });
      transportModel.get('advice').then(function (a) {
        if (a) {
          a.rollback();
        }
      });

      if (transportModel.get('indexes.length')) {
        transportModel.get('indexes').forEach(function (index) {
          if (index) {
            index.rollback();
            index.get('quantities').forEach(function (q) {
              return q.rollback();
            });
          }
        });
      }

      if (transportModel.get('carrierCompany.name')) {
        transportModel.get('carrierCompany').then(function (c) {
          if (c.id) {
            c.rollbackAttributes();
          } else {
            c.unloadRecord();

            if (transportModel.get('oldCarrierCompany')) {
              transportModel.setProperties({
                company: transportModel.get('oldCarrierCompany'),
                oldCarrierCompany: null
              });
            }
          }
        });
      }

      transportModel.get('steps').then(function (steps) {
        steps.forEach(function (s) {
          if (s === undefined) {
            return true;
          }

          s.rollbackAttributes();

          if (s.get('company.name')) {
            s.get('company').then(function (comp) {
              if (comp.get('id')) {
                comp.rollbackAttributes();
              } else {
                comp.unloadRecord();

                if (s.get('oldCompany')) {
                  s.setProperties({
                    company: s.get('oldCompany'),
                    oldCompany: null
                  });
                }
              }
            });
          }

          if (s.get('timeWindow.id')) {
            s.get('timeWindow').then(function (timeWindow) {
              timeWindow.rollbackAttributes();
            });
          }

          if (s.get('stepPackageTypes')) {
            s.get('stepPackageTypes').then(function (packageTypes) {
              packageTypes.forEach(function (pt) {
                pt.rollbackAttributes();
              });
            });
          }
        });
      });
      this.get('attachments').clear();
    },
    refreshTransport: function refreshTransport(transport) {
      this.initializeDiscrepancyReport(transport);
      this.initializeCustomFields(transport);
    },
    deleteAllFuturePeriodicTransports: function deleteAllFuturePeriodicTransports(transport) {
      return transport.get('id') && transport.get('periodicTransport.id') && this.get('sessionAccount').hasRole('ROLE_DELETE_MULTIPLE_PERIODIC_TRANSPORTS') && confirm(this.get('intl').t('periodicTransport.deleteAllConfirmMsg'));
    }
  });

  _exports.default = _default;
});