define("apollo/pods/warehouse-zone/model", ["exports", "ember-data", "apollo/mixins/model-validator"], function (_exports, _emberData, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var service = Ember.inject.service;

  var Warehouse = _emberData.default.Model.extend(_modelValidator.default, {
    sessionAccount: service('session-account'),
    warehouse: _emberData.default.belongsTo('warehouse', {
      async: true
    }),
    transportTypes: _emberData.default.hasMany('transportType', {
      async: true
    }),

    /**
     * id typów transportu, które zostały zaznaczone podczas tworzenia, lub edycji.
     * Zmienna służy do wysłania danych na backend, nie przychodzi z serwera.
     */
    checkedTransportTypesIds: _emberData.default.attr(),
    ramps: _emberData.default.hasMany('ramp', {
      async: true
    }),
    algorithmSettings: _emberData.default.hasMany('algorithmSetting', {
      async: true
    }),
    name: attr('string'),
    englishName: attr('string'),
    shortName: attr('string'),
    idx: attr('number'),
    enabled: attr('boolean', {
      defaultValue: function defaultValue() {
        return true;
      }
    }),
    enabledRamps: Ember.computed('ramps.@each.enabled', function () {
      return this.get('ramps').filterBy('enabled');
    }),
    sortedRamps: Ember.computed('enabledRamps.@each', function () {
      return this.get('enabledRamps').sortBy('idx');
    }),
    validations: {
      name: {
        presence: {
          message: 'blank'
        }
      },
      idx: {
        presence: {
          message: 'blank'
        }
      }
    }
  });

  var _default = Warehouse;
  _exports.default = _default;
});