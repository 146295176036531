define("apollo/pods/table-column/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    languageService: Ember.inject.service('language-service'),
    LABEL_FROM_SETTINGS: {
      ERP_IDENTIFIER: 'ERP_IDENTIFIER_LABEL',
      ALT_IDENTIFIER: 'ALT_IDENTIFIER_LABEL',
      STORAGE_ZONE: 'WAREHOUSE_ZONE_LABEL'
    },
    name: _emberData.default.attr('string'),
    idx: _emberData.default.attr(''),
    label: Ember.computed('name', function () {
      var settingName = this.get('LABEL_FROM_SETTINGS')[this.get('name')];
      var columnName = null;

      if (settingName) {
        var properSuffix = this.get('languageService').findProperLanguageVersion('', '_EN');
        columnName = this.get('sessionAccount').getSettingValue("".concat(settingName).concat(properSuffix));
      }

      return columnName ? columnName : this.get('intl').t("columns.".concat(this.get('name')));
    })
  });

  _exports.default = _default;
});