define("apollo/pods/components/authority-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    sortedAuthorities: Ember.computed('', function () {
      var _this = this;

      var authorities = this.get('authorities');
      var authorityType = authorities[0] ? authorities[0].get('constructor.modelName') : undefined;

      switch (authorityType) {
        case 'role-group-authority':
          // Uprawnienia podstawowe, sortujemy alfabetycznie
          authorities.sort(function (a, b) {
            var authorityInAppropriateName = function authorityInAppropriateName(name) {
              return _this.get('intl').t("settings.roleGroups.authorityLabels.".concat(name));
            };

            return authorityInAppropriateName(a.get('name')).localeCompare(authorityInAppropriateName(b.get('name')));
          });
          return authorities;

        case 'authority-restriction':
          // Uprawnienia zależne od typu transportu:
          // - uprawnienia dotyczące jednego cf oraz assetu są koło siebie,
          // - ważniejsze authority (zależne od idx) są wyświetlane wyżej.
          authorities.sort(function (a, b) {
            return parseInt(a.get('customFieldDefinition.id')) - parseInt(b.get('customFieldDefinition.id')) || parseInt(a.get('assetId')) - parseInt(b.get('assetId')) || parseInt(a.get('authority.idx')) - parseInt(b.get('authority.idx'));
          });
          return authorities;

        default:
          console.error('Znaleźliśmy nieznany typ uprawnienia podczas sortowania. Pomijamy sortowanie.');
          return authorities;
      }
    }),
    actions: {
      delete: function _delete(roleGroupAuthority) {
        this.set('action', 'delete');
        this.sendAction('action', roleGroupAuthority);
      },
      deleteAllAuthorities: function deleteAllAuthorities(roleGroupAuthorities) {
        this.set('action', 'deleteAllAuthorities');
        this.sendAction('action', roleGroupAuthorities);
      }
    }
  });

  _exports.default = _default;
});