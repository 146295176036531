define("apollo/pods/algorithm-settings/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    algorithmSettingsIndexController: Ember.inject.controller('algorithm-settings/index'),
    intl: Ember.inject.service(),
    errors: [],
    actions: {
      save: function save() {
        var self = this;
        var algorithmSetting = this.get('model');
        algorithmSetting.save().then(function () {
          self.setProperties({
            success: true,
            errors: []
          });
          var msg = self.get('intl').t('algorithmSettings.saveSuccessMessage');
          self.get('algorithmSettingsIndexController').set('successMessage', msg);
          self.transitionToRoute('algorithm-settings.index', {
            queryParams: {
              warehouseZoneId: self.get('model.warehouseZone.id')
            }
          });
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      },
      cancel: function cancel() {
        var warehouseZoneId = this.get('model.warehouseZone.id');
        this.get('model').set('warehouseZone', null);
        this.get('model').unloadRecord();
        this.transitionToRoute('algorithm-settings.index', {
          queryParams: {
            warehouseZoneId: warehouseZoneId
          }
        });
      },
      delete: function _delete() {
        var _this = this;

        var self = this;
        var algorithmSetting = this.get('model');
        algorithmSetting.destroyRecord().then(function (a) {
          var msg = self.get('intl').t('algorithmSettings.deletionSuccessMessage', {
            n: a.get('numberOfPallets')
          });
          self.get('algorithmSettingsIndexController').set('successMessage', msg);
          console.log("Poprawnie usun\u0119li\u015Bmy ustawienia algorytmu dla ".concat(a.get('numberOfPallets'), " palet."));

          _this.transitionToRoute('algorithm-settings.index', {
            queryParams: {
              warehouseZoneId: _this.get('model.warehouseZone.id')
            }
          });
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      }
    }
  });

  _exports.default = _default;
});