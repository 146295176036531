define("apollo/pods/queues/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax-service'),
    actions: {
      save: function save() {
        var _this = this;

        var self = this;
        this.get('model').validate();

        if (this.get('model').get('errors.length')) {
          return;
        }

        this.get('model').save().then(function (queue) {
          console.debug("Queue ".concat(queue.get('name'), " saved."));
          self.setProperties({
            errors: []
          });
          var msg = self.get('intl').t('queues.updateSuccessMessage', {
            queueName: _this.get('model').get('name')
          });
          self.set('successMessage', msg);
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      },
      disable: function disable() {
        var self = this;
        var model = this.get('model');
        this.get('ajaxService').send('/queues/' + model.get('id') + '/disable', 'PUT', model, true).then(function () {
          model.set('enabled', false);
          model.get('places').forEach(function (place) {
            return place.set('enabled', false);
          });
          self.transitionToRoute('squares.edit', self.get('model.square'));
        });
      },
      enable: function enable() {
        var self = this;
        var model = this.get('model');
        this.get('ajaxService').send('/queues/' + model.get('id') + '/enable', 'PUT', model, true).then(function () {
          model.set('enabled', true);
          model.get('places').forEach(function (place) {
            return place.set('enabled', true);
          });
          self.transitionToRoute('squares.edit', self.get('model.square'));
        });
      },
      cancel: function cancel() {
        var square = this.get('model.square');
        this.get('model').rollbackAttributes();
        this.transitionToRoute('squares.edit', square);
      }
    }
  });

  _exports.default = _default;
});