define("apollo/pods/authorities/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    authoritiesIndexController: Ember.inject.controller('authorities/index'),
    intl: Ember.inject.service(),
    errors: [],
    roleGroups: Ember.computed(function () {
      var principalCompanyId = localStorage.getItem('principalId');
      return this.store.peekAll('roleGroup').filterBy('principalCompany.id', principalCompanyId);
    }),
    selectedAuthority: Ember.computed('model.selectedAuthority', 'model.roleGroupAuthority.authority', function () {
      return this.get('model.roleGroupAuthority.authority') || this.get('model.selectedAuthority');
    }),
    multiSelect: Ember.computed('model.multiSelect', function () {
      return this.get('model.multiSelect');
    }),
    roleGroupAuthority: Ember.computed('model.roleGroupAuthority', function () {
      return this.get('model.roleGroupAuthority');
    }),
    actions: {
      save: function save() {
        var _this = this;

        var self = this;
        var roleGroupAuthority = this.get('roleGroupAuthority');
        var roleGroups = this.get('model.roleGroups');
        var multiSelect = this.get('multiSelect');

        if (multiSelect) {
          roleGroups.forEach(function (rg) {
            var rga = _this.store.createRecord('role-group-authority', {
              authority: self.get('model.selectedAuthority'),
              roleGroup: rg
            });

            if (!rga.validate()) {
              console.error('RoleGroupAuthority nie przeszło walidacji..');
              return;
            }

            rga.save();
          });
          self.transitionToRoute('authorities.aggregate');
        } else {
          if (!roleGroupAuthority.validate()) {
            console.log('RoleGroupAuthority nie przeszło walidacji..');
            return;
          }

          roleGroupAuthority.save().then(function (roleGroupAuthority) {
            self.setProperties({
              success: true,
              errors: []
            });
            var msg = self.get('intl').t('settings.roleGroups.successUpdate');
            self.get('authoritiesIndexController').set('successMessage', msg);
            self.transitionToRoute('authorities.index', roleGroupAuthority.get('roleGroup.id'));
          }).catch(function (response) {
            self.set('errors', response.errors);
          });
        }
      },
      cancel: function cancel() {
        if (this.get('model.multiSelect')) {
          this.transitionToRoute('authorities.aggregate');
        } else {
          var roleGroupId = this.get('model.roleGroupAuthority.roleGroup.id');
          this.get('model.roleGroupAuthority').unloadRecord();
          this.transitionToRoute('authorities.index', roleGroupId);
        }
      },
      setRoleGroupAuthority: function setRoleGroupAuthority(authority) {
        this.set('authorityId', authority.get('id'));

        if (this.get('model.multiSelect')) {
          this.set('model.selectedAuthority', authority);
        }

        if (this.get('model.roleGroupAuthority')) {
          this.set('model.roleGroupAuthority.authority', authority);
        }
      }
    }
  });

  _exports.default = _default;
});