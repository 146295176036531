define("apollo/pods/transports/modals/email-advice/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    ajaxService: Ember.inject.service('ajax-service'),
    autoCompleteContactPersonDetails: Ember.observer('model', function () {
      var self = this;

      if (this.get('model') && !this.get('model.edit')) {
        // Na razie przyjmujemy, że transport ma tylko jeden step z jednym dostawcą
        var step = this.get('model.transport.contractorSteps') ? this.get('model.transport.contractorSteps').get(0) : null;
        var supCompany = step ? step.get('company') : null;
        var carrierCompany = this.get('model.transport.carrierCompany') ? this.get('model.transport.carrierCompany').content : null; //  Jeśli transport nie ma przypisanego ani przewoznika, ani dostawcy, przerywamy

        if (!supCompany && !carrierCompany) {
          return;
        } // Jeśli dostawca jest przypisany do transportu to pierwszej kolejności próbujemy przypisać dane przedstawiciela dostawcy


        if (supCompany) {
          supCompany.reload().then(function (c) {
            var p = c.partnershipWithPrincipal;
            var supEmail = p.get('representativeEmail');
            var supMobilePhoneNumber = p.get('representativePhoneNumber');
            var supMobilePhoneNumberPrefix = p.get('representativePhoneNumberPrefix'); // Jeśli dostawca ma przypisany email lub numer, próbujemy je uzupełnić

            if (supEmail || supMobilePhoneNumber) {
              if (supEmail) {
                self.set('model.email', supEmail);
              }

              if (supMobilePhoneNumber) {
                self.set('model.mobilePhoneNumber', supMobilePhoneNumber);
              }

              if (supMobilePhoneNumberPrefix) {
                self.set('model.mobilePhonePrefix', supMobilePhoneNumberPrefix);
              }
            } else {
              // Jeśli dostawca nie ma danych, a transport posiada przewoźnika próbujemy przypisać dane przewoźnika
              if (carrierCompany) {
                self._setRepresentativeData(carrierCompany);
              }
            }
          });
        } // Jeśli transport nie ma przypisanego dostawcy, a ma przewoźnika próbujemy przypisać dane przewoźnika


        if (!supCompany && carrierCompany) {
          self._setRepresentativeData(carrierCompany);
        }
      }
    }),
    _setRepresentativeData: function _setRepresentativeData(company) {
      var self = this;
      company.reload().then(function (c) {
        var p = c.partnershipWithPrincipal;
        var email = p.get('representativeEmail');
        var mobilePhoneNumber = p.get('representativePhoneNumber');
        var mobilePhoneNumberPrefix = p.get('representativePhoneNumberPrefix');

        if (email) {
          self.set('model.email', email);
        }

        if (mobilePhoneNumber) {
          self.set('model.mobilePhoneNumber', mobilePhoneNumber);
        }

        if (mobilePhoneNumberPrefix) {
          self.set('model.mobilePhonePrefix', mobilePhoneNumberPrefix);
        }
      });
    },
    title: Ember.computed('model.type', function () {
      return this.get('model.type') === 'ADVICE_DATA' ? this.get('intl').t('emailAdvice.title') : this.get('intl').t('emailAdvice.titleCf');
    }),
    sentLabel: Ember.computed('model.type', function () {
      return this.get('model.type') === 'ADVICE_DATA' ? this.get('intl').t('emailAdvice.alreadySent') : this.get('intl').t('emailAdvice.alreadySentCf');
    }),
    historyList: Ember.computed('model.transport.emailAdvices.@each.expired', function () {
      var _this = this;

      var sentList = this.get('model.transport.emailAdvices').filter(function (ea) {
        return ea && ea.get('id') && ea.get('type') === _this.get('model.type');
      });
      return sentList.sortBy('dateCreated');
    }),
    rodoAccepted: false,
    actions: {
      sendEmailAdvice: function sendEmailAdvice() {
        var self = this;
        var model = this.get('model');
        model.validate();

        if (model.get('errors.length')) {
          return;
        } // Metoda na backendzie (EmailAdviceRESTController#disableOldAndSaveNew),
        // która obsługuję post na /email-advices wykonywany przez EmberJS przy .save()
        // nie tylko tworzy i zapisuje nowy obiekt awizacji mailowej,
        // ale też dezaktywuje starą awizację (i przez to przypisany do niej token url i sms)


        model.save().then(function (m) {
          self.set('errors', []);
          self.get('model.transport.emailAdvices').reload();
          self.send('hideModal');
        }).catch(function (response) {
          self.set('errors', response.errors);
        });
      },
      close: function close() {
        this.send('hideModal');
      },
      cancel: function cancel() {
        this.get('model').deleteRecord();
        this.send('hideModal');
      },
      setPhonePrefix: function setPhonePrefix(phonePrefix) {
        this.get('model').set('mobilePhonePrefix', phonePrefix);
      },
      toggleRodoAcceptance: function toggleRodoAcceptance() {
        this.set('model.rodoAccepted', !this.get('model.rodoAccepted'));
      }
    }
  });

  _exports.default = _default;
});