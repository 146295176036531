define("apollo/pods/components/task-confirmation-date-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e4ijH281",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row margin-bottom\"],[11,\"data-field-name\",\"toggle-confirmation-date\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"clickable\"],[9],[0,\"\\n        \"],[1,[27,\"input\",null,[[\"type\",\"checked\",\"change\"],[\"checkbox\",false,[27,\"action\",[[22,0,[]],\"toggleConfirmationDateForm\"],null]]]],false],[0,\"\\n        \"],[1,[27,\"t\",[\"checkpoint.actualExecutionDateLabel\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"showConfirmationDateForm\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n            \"],[7,\"div\"],[12,\"class\",[28,[\"form-inline \",[27,\"if\",[[23,[\"checkpoint\",\"errors\",\"executionDate\"]],\"has-error\"],null]]]],[11,\"data-field-name\",\"task-confirmation-date-form\"],[9],[0,\"\\n                \"],[7,\"span\"],[9],[0,\"\\n                    \"],[1,[27,\"t\",[\"checkpoint.actualExecutionDate\"],null],false],[0,\":\\n                \"],[10],[0,\"\\n\\n                \"],[1,[27,\"ember-flatpickr\",null,[[\"altFormat\",\"allowInput\",\"altInput\",\"altInputClass\",\"ariaDateFormat\",\"dateFormat\",\"enableTime\",\"locale\",\"minuteIncrement\",\"time_24hr\",\"static\",\"date\",\"onChange\"],[\"d.m.Y H:i\",true,true,\"form-control\",\"d-m-Y\",\"d.m.Y H:i\",true,[23,[\"sessionAccount\",\"language\"]],1,true,true,[27,\"readonly\",[[23,[\"today\"]]],null],[27,\"action\",[[22,0,[]],\"setConfirmationDate\"],null]]]],false],[0,\"\\n\\n                \"],[1,[27,\"render-field-errors\",null,[[\"model\",\"fieldName\"],[[23,[\"checkpoint\"]],\"executionDate\"]]],false],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/task-confirmation-date-form/template.hbs"
    }
  });

  _exports.default = _default;
});