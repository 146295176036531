define("apollo/services/error-service", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    scrollToFirstError: function scrollToFirstError() {
      var containerSelector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "#main-modal";
      var errorClass = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ".has-error";
      //Dajemy opóźnienie, żeby błędy zdążyły się oznaczyć w formularzu
      setTimeout(function () {
        var container = (0, _jquery.default)(containerSelector);
        var errorFields = (0, _jquery.default)(errorClass).not(".panel-title");

        if (errorFields.length === 0) {
          return;
        }

        var errorField = errorFields.first(); // jeśli pole znajduje się w zwiniętym panelu - rozwijamy go

        var parentPanel = errorField.parents(".panel");

        if (parentPanel.length) {
          var panel = parentPanel.find(".panel-collapse");
          panel.collapse('show');
        }

        container.animate({
          scrollTop: errorField.offset().top - container.offset().top + container.scrollTop()
        }, "slow");
      }, 100);
    }
  });

  _exports.default = _default;
});