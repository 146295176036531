define("apollo/pods/transport-types/artr-settings/controller", ["exports", "apollo/mixins/response-util-mixin"], function (_exports, _responseUtilMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_responseUtilMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    artrSetting: Ember.computed('model.artrSetting', function () {
      return this.get('model.artrSetting');
    }),
    transportType: Ember.computed('model.transportType.id', function () {
      return this.get('model.transportType');
    }),
    actions: {
      save: function save() {
        var _this = this;

        this.set('errors', []);
        var artrSetting = this.get('artrSetting');
        artrSetting.set('transportType', this.get('transportType'));

        if (!artrSetting.validate()) {
          return;
        }

        artrSetting.save().then(function () {
          _this.send('refreshModel');

          var msg = _this.get('intl').t('common.saveSuccessMessage');

          _this.set('successMessage', msg);

          jQuery('html, body').animate({
            scrollTop: 0
          }, 'slow');
        }).catch(function (response) {
          _this.set('errors', response.errors);

          jQuery('html, body').animate({
            scrollTop: 0
          }, 'slow');
        });
      },
      create: function create() {
        if (this.get('artrSetting')) {
          return;
        }

        var transportType = this.get('transportType');
        this.set('artrSetting', this.get('store').createRecord('artr-setting', {
          transportType: transportType
        }));
      },
      delete: function _delete() {
        var _this2 = this;

        this.set('errors', []);
        this.get('artrSetting').destroyRecord().then(function () {
          return _this2.handleSuccessResponse(_this2);
        }).catch(function (response) {
          return _this2.handleErrorResponse(_this2, response);
        });
      },
      cancel: function cancel() {
        this.get('artrSetting').rollbackAttributes();
        this.transitionToRoute('workflows.edit', this.get('transportType.workflow.id'));
      }
    }
  });

  _exports.default = _default;
});