define("apollo/pods/transports/modals/warehouses-to-excel/controller", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Controller.extend({
    sessionAccount: service('session-account'),
    selectedWarehouses: Ember.A([]),
    warehouses: Ember.computed('', function () {
      return this.get('model.warehouses');
    }),
    _downloadRegistryOfPersonsXls: function _downloadRegistryOfPersonsXls() {
      var token = this.get('sessionAccount.token');
      var selectedWarehouses = this.get('selectedWarehouses');
      var warehousesAsString = selectedWarehouses.map(function (w) {
        return w.get('id');
      }).join(',');
      var url = _environment.default.serverURL + '/reports/personsRegistry?token=' + token + '&warehousesId=' + warehousesAsString;
      window.open(url, '_blank');
      return this.send('hideModal');
    },
    actions: {
      downloadRegistryOfPersonsXls: function downloadRegistryOfPersonsXls() {
        Ember.run.once(this, '_downloadRegistryOfPersonsXls');
      },
      cancel: function cancel() {
        this.set('selectedWarehouses', Ember.A([]));
      }
    }
  });

  _exports.default = _default;
});