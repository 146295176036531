define("apollo/pods/warehouse-locations/edit/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: service('session-account'),
    model: function model(params) {
      var warehouseLocation = this.store.findRecord('warehouseLocation', params.id);
      var gates = this.get('store').findAll('gate');
      return Ember.RSVP.hash({
        warehouseLocation: warehouseLocation,
        gates: gates
      });
    },
    renderTemplate: function renderTemplate() {
      this.render('warehouseLocations.create');
    },
    setupController: function setupController(controller, model) {
      var gates = this.get('store').findAll('gate').then(function (gates) {
        return gates.filterBy('isEntryGate');
      });
      this.controllerFor('warehouse-locations.create').setProperties({
        warehouseLocation: model,
        gates: gates
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.get('controller').setProperties({
          success: false,
          errors: []
        });
        this.get('currentModel').unloadRecord();
        return true;
      }
    }
  });

  _exports.default = _default;
});