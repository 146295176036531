define("apollo/pods/documents/modals/document-form-modal/controller", ["exports", "apollo/mixins/section-mixin"], function (_exports, _sectionMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_sectionMixin.default, {
    intl: Ember.inject.service(),
    errors: [],
    documentType: Ember.computed('model.documentType', function () {
      return this.get('model.documentType');
    }),
    document: Ember.computed('model.document', function () {
      return this.get('model.document');
    }),
    title: Ember.computed('documentType.id', function () {
      return this.get('intl').t("documentType.".concat(this.get('documentType.translationKey'), ".name"));
    }),
    actions: {
      cancel: function cancel() {
        if (!this.get('document.id')) {
          this.get('document').unloadRecord();
        } else {
          this.get('document').rollback();
        }

        this.set('errors', []);
        this.send('hideModal');
      },
      save: function save() {
        var self = this;
        this.set('savingInProgress', true);

        if (!this.get('document').validate()) {
          console.debug("Document did not pass validation.");
          this.get('document').get('errors').forEach(function (error) {
            return console.log(error);
          });
          self.set('savingInProgress', false);
          return;
        }

        this.get('document').save().then(function (savedDocument) {
          console.log("Document ".concat(savedDocument.get('id'), " has been saved."));
          self.setProperties({
            savingInProgress: false,
            errors: []
          });
          self.send('refreshSection', savedDocument);
          self.send('hideModal');
        }).catch(function (response) {
          self.setProperties({
            savingInProgress: false,
            errors: response.errors
          });
        });
      }
    }
  });

  _exports.default = _default;
});