define("apollo/pods/announcements/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "apollo/mixins/menu-mixin"], function (_exports, _authenticatedRouteMixin, _menuMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _menuMixin.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      max: {
        refreshModel: true
      }
    },
    sessionAccount: Ember.inject.service('session-account'),
    access: ['ROLE_ANNOUNCEMENTS_TAB'],
    activate: function activate() {
      this.activateCurrentTab('ANNOUNCEMENTS_TAB');
    },
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      params.max = params.max || 25;
      params.page = params.page || 1;
      params.location = "ANNOUNCEMENTS_TAB";
      return this.store.query("announcement", params);
    },
    setupController: function setupController(controller, model) {
      this.get('controller').setProperties({
        model: model,
        totalPages: model.get('meta.totalPages')
      });
    }
  });

  _exports.default = _default;
});