define("apollo/pods/users/show/controller", ["exports", "apollo/mixins/user-utils-mixin"], function (_exports, _userUtilsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_userUtilsMixin.default, {
    sessionAccount: Ember.inject.service('session-account'),
    user: Ember.computed('model.user', function () {
      return this.get('model.user');
    }),
    notifications: Ember.computed('user.userNotifications', function () {
      return this.get('user.userNotifications').map(function (un) {
        return un.get('notification');
      }).uniqBy('name');
    })
  });

  _exports.default = _default;
});