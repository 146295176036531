define("apollo/pods/indexes/modals/index-form/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    modalTitle: Ember.computed('', function () {
      var transportType = this.get('transport.transportType');
      var indexSetting = this.get('sessionAccount').getIndexSetting(transportType.get('id'));

      if (!indexSetting) {
        return this.get('intl').t('index.INDEX.add');
      }

      var key = indexSetting.get('translationKey');
      return this.get('intl').t("index.".concat(key, ".add"));
    }),
    units: Ember.computed('', function () {
      return ['KG', 'LITERS', 'PIECES'];
    }),
    saveInitialProperties: Ember.observer('model.modelOpenTime', function () {
      var initialProperties = {
        model: this.get('model').toJSON()
      };
      this.get('model.quantities').forEach(function (q) {
        initialProperties[q.get('indexQuantityType.nameEn')] = q.toJSON();
      });
      this.set('initialProperties', initialProperties);
    }),
    transport: Ember.computed('model.transport', function () {
      return this.get('model.transport');
    }),
    indexSetting: Ember.computed('model.transport.transportType.indexSetting', function () {
      return this.get('model.transport.transportType.indexSetting');
    }),
    productSetting: Ember.computed('model.transport.principalCompany.productSetting', function () {
      return this.get('model.transport.principalCompany.productSetting');
    }),
    isEditionDisabled: Ember.computed('transport.disabled', function () {
      if (!this.get('transport')) {
        return true;
      }

      return !this.get('transport').actionCanBePerformed('EDIT_INDEX');
    }),
    isDeletionAllowed: Ember.computed('model.id', 'transport.transportType', function () {
      if (!this.get('model.id') || !this.get('transport')) {
        return false;
      }

      return this.get('transport').actionCanBePerformed('DELETE_INDEX');
    }),
    actions: {
      setProductData: function setProductData(productData) {
        var self = this;
        this.get('store').findRecord('product', productData.id).then(function (product) {
          self.get('model').setProperties({
            product: product
          });
          self.get('model.quantities').filterBy('indexQuantityType.requiresUnit').forEach(function (q) {
            return q.set('unit', product.get('unit'));
          });
        });
      },
      removeProduct: function removeProduct() {
        this.get('model').setProperties({
          product: null
        });
      },
      save: function save() {
        this.get('model').validate();
        this.get('model.quantities').forEach(function (q) {
          return q.validate();
        });

        if (this.get('model.hasErrors')) {
          return;
        }

        this.get('model').set('enabled', true);
        (0, _jquery.default)("#sub-modal").data("skip-cancel-action", true);
        this.send('hideModal', 'sub-modal');
      },
      setStep: function setStep(model, step) {
        model.set('step', step);
      },
      disable: function disable() {
        if (this.get('model').get('id')) {
          this.get('model').set('enabled', false);
        } else {
          var transport = this.get('model').get('transport');
          transport.get('indexes').removeObject(this.get('model'));
          this.get('model').unloadRecord();
        }

        this.send('hideModal', 'sub-modal');
      },

      /**
       * Obsługa anulowania / zamknięcia formularza indeksu.
       *
       * Musimy rozważyć trzy przypadki.
       *
       * 1. Indeks jest niezapisany ani w bazie, ani w ember-data --> zupełnie usuwamy jego model; taka sytuacja wystąpi,
       * jeśli dodajemy nowy indeks, ale nie zapiszemy go i klikniemy "Anuluj".
       *
       * 2. Indeks jest zapisany, wprowadzamy zmiany w jego formularzu (po kliknięciu ołówka) a następnie
       * wybieramy "Anuluj" --> usuwamy wprowadzone zmiany, ale poza tym indeks pozostaje przypisany do transportu.
       *
       * 3. Indeks jest niezapisany w bazie, ale jest już dodany do transportu --> taka sytuacja wystąpi,
       * gdy dodajemy nowy indeks i uda się go zapisać (indeks pojawi się w tabelce), następnie otworzymy
       * formularz ze szczegółami (po kliknięciu ołówka) oraz wybierzemy "Anuluj" --> zamykamy wtedy formularz, ale nie usuwamy indeksu
       */
      cancel: function cancel() {
        var _this = this;

        console.debug("Zamykamy okienko edycji indeks\xF3w..."); // To, że indeks został poprawnie dodany do transportu (pojawił się na liście indeksów) możemy
        // poznać sprawdzając flagę `enabled` .
        //
        // Nie możemy użyć tu `this.get('model').rollbackAttributes()`, ponieważ możliwa jest taka sytuacja:
        //  - użytkownik dodaje nowy indeks;
        //  - użytkownik otwiera formularz edycji indeksu (ikona ołówka) oraz klika "Anuluj"
        //
        // Efekt jest taki, że `rollbackAttributes` uzna, że powinien zupełnie usunąć indeks, a nie powinniśmy tego
        // robić w tej sytuacji (użytkownik spodziewa się tylko zamknięcia formularza).
        //
        // Stąd tak naprawdę te skomplikowane operacje z `initialProperties` itp (gałąź `else`).

        if (this.get('model') && !this.get('model.id') && !this.get('model.enabled')) {
          // 1. Indeks jest niezapisany ani w bazie, ani w ember-data
          this.get('transport.indexes').removeObject(this.get('model'));
          this.get('model').unloadRecord();
        } else if (this.get('model.id')) {
          // Indeks jest zapisany, wprowadzamy zmiany w jego formularzu (po kliknięciu ołówka) a następnie wybieramy "Anuluj"
          this.get('model').rollback();
          this.get('model.quantities').forEach(function (q) {
            return q.rollback();
          });
        } else {
          // Indeks jest niezapisany w bazie, ale jest już dodany do transportu
          var self = this;
          var initialProperties = this.get('initialProperties');
          var initialModelProperties = initialProperties['model'];
          var excluded = ['transport', 'enabled'];
          var belongsToKeys = ['step', 'product'];
          Object.keys(initialModelProperties).filter(function (key) {
            return !excluded.includes(key);
          }).forEach(function (key) {
            var value = initialModelProperties[key];

            if (belongsToKeys.includes(key)) {
              if (value) {
                _this.get('model').set(key, self.get('store').peekRecord(key, value));
              }
            } else {
              _this.get('model').set(key, initialModelProperties[key]);
            }
          });
          this.get('model.quantities').forEach(function (q) {
            var initialQuantityProperties = initialProperties[q.get('indexQuantityType.nameEn')];
            Object.keys(initialQuantityProperties).filter(function (key) {
              return key !== 'index' && key !== 'indexQuantityType';
            }).forEach(function (key) {
              q.set(key, initialQuantityProperties[key]);
            });
          });
        }
      },
      close: function close() {
        this.send('hideModal', 'sub-modal');
      }
    }
  });

  _exports.default = _default;
});