define("apollo/pods/components/sections/document-section/document-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currencies: Ember.computed('', function () {
      return this.get('store').peekAll('currency');
    }),
    isEditionDisabled: Ember.computed('document.id', 'document.canBeUpdated', function () {
      if (!this.get('document.id')) {
        return false;
      }

      return !this.get('document.canBeUpdated');
    }),
    actions: {
      setExpirationDate: function setExpirationDate(dateValues) {
        var _this = this;

        Ember.run.next(function () {
          _this.get('document').set('expirationDate', dateValues[0]);
        });
      },
      setExpirationDateFrom: function setExpirationDateFrom(dateValues) {
        var _this2 = this;

        Ember.run.next(function () {
          _this2.get('document').set('expirationDateFrom', dateValues[0]);
        });
      },
      showModal: function showModal(templateName, model) {
        this.set('action', 'showModal');
        this.sendAction('action', templateName, model);
      }
    }
  });

  _exports.default = _default;
});