define("apollo/mixins/attachment-mixin", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax-service'),
    uploadAttachment: function uploadAttachment(id, token) {
      var _this = this;

      var attachments = this.get('attachments');
      var transport = this.get('store').findRecord('transport', id);

      if (Ember.isEmpty(attachments)) {
        return;
      }

      var fileNames = attachments.map(function (a) {
        return a.name;
      });
      var promises = attachments.map(function (a) {
        return _this.readFile(a);
      });
      Ember.RSVP.Promise.all(promises).then(function (files) {
        var data = JSON.stringify({
          transportId: id,
          files: {
            result: files
          },
          filesNames: fileNames
        });

        _this.get('ajaxService').send('/files/', 'POST', data, false).then(function () {
          return transport.reload();
        });
      });
    },
    readFile: function readFile(file) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = function () {
          return resolve(reader.result);
        };

        reader.onerror = function (error) {
          return reject(error);
        };
      });
    },
    deleteFile: function deleteFile(fileId) {
      console.log("deleteFile: ".concat(fileId));
      this.get('store').findRecord('file', fileId, {
        reload: true
      }).then(function (f) {
        f.destroyRecord();
      });
    },
    actions: {
      addFiles: function addFiles() {
        var attachments = this.get('attachments'); // obiekty plików są przechowywane w inpucie w tym przypadki w inpucie o id `upload-files`
        // pliki zwracane są w formie `FileList`, która działa podobnie do tablicy ale nie działają
        // na niej metody emberowe dlatego po niej iterujemy i wrzucamy pliki do emberowej tablicy

        var filesFromInput = $('#upload-files')[0].files;
        var files = Ember.A();
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = filesFromInput[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var file = _step.value;
            files.pushObject(file);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        files.forEach(function (file) {
          if (!attachments.findBy('name', file.name)) {
            attachments.pushObject(file);
          }
        });
      },
      removeFile: function removeFile(file) {
        var attachments = this.get('attachments');
        this.set('attachments', attachments.rejectBy('name', file.name));
      },
      removeExistingFile: function removeExistingFile(file) {
        console.log('removeExistingFile..');
        this.get('existingFilesToRemove').push(file.get('id'));
        (0, _jquery.default)('#file-' + file.get('id')).css('text-decoration', 'line-through');
      },
      generateXLS: function generateXLS() {
        var _this2 = this;

        this.send('hideModal');
        alert(this.get('intl').t('transportsToExcel.XLSGenerateMessage'));
        var url = '/reports/transports?token=' + this.get('sessionAccount').get('token');
        var params = {
          // do przemyślenia czy cały ten obiekt nie powinien być wymieniany między komponentami zamiast tylko searchParams
          query: this.get('searchParams.query'),
          archived: this.get('archived'),
          exportType: this.get('exportType'),
          exportActiveOrders: this.get('exportActiveOrders'),
          transportTypeIds: this.get('checkedTransportTypes').getEach('id').join(','),
          principalId: localStorage.getItem('principalId'),
          start: this.get('searchParams.start') ? moment(this.get('searchParams.start')).format() : '',
          stop: this.get('searchParams.stop') ? moment(this.get('searchParams.stop')).format() : '',
          dateFilterProperty: this.get('searchParams.dateFilterProperty'),
          filters: JSON.stringify(this.get('searchParams.filters'))
        };
        this.get('ajaxService').send(url, 'GET', params).then(function () {}).catch(function (response) {
          if (response.status === 404) {
            alert(_this2.get('intl').t('transportsToExcel.errors.XLSGenerateEmailErrorMessage'));
            return;
          }

          if (response.status === 504) {
            return;
          }

          alert(_this2.get('intl').t('transportsToExcel.errors.XLSGenerateErrorMessage'));
        });
      }
    }
  });

  _exports.default = _default;
});