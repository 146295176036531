define("apollo/mixins/checkpoint-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // eslint-disable-next-line ember/no-new-mixins
  var _default = Ember.Mixin.create({
    intl: Ember.inject.service(),
    showCustomFieldsModal: function showCustomFieldsModal(checkpoint, transport, actionType, hide) {
      if (hide) {
        hide();
      }

      checkpoint.set('actionType', actionType);

      if (transport.get('hasCustomFieldsInitialized')) {
        transport.set('hasCustomFieldsInitialized', false);
      }

      this.send('showModal', 'custom-field-definitions.modals.form', checkpoint);
    },
    showWarehouseLocationModal: function showWarehouseLocationModal(checkpoint, transport, actionType, hide) {
      if (hide) {
        hide();
      }

      checkpoint.set('actionType', actionType);
      this.send('showModal', 'warehouse-locations.modals.form', checkpoint);
    },
    showOverweightModal: function showOverweightModal(checkpoint, transport, actionType, hide) {
      if (hide) {
        hide();
      }

      checkpoint.set('actionType', actionType);
      this.send('showModal', 'weights.modals.overweight', checkpoint);
    },
    showWeightModal: function showWeightModal(checkpoint, transport, actionType, hide) {
      if (hide) {
        hide();
      }

      checkpoint.set('actionType', actionType);
      this.send('showModal', 'weights.modals.form', checkpoint);
    },
    showBlockadeReasonModal: function showBlockadeReasonModal(checkpoint, transport, actionType, hide) {
      if (hide) {
        hide();
      }

      checkpoint.set('actionType', actionType);
      this.send('showModal', 'components.checkpoint-blockade-reason-modal', checkpoint);
    },
    showCustomModal: function showCustomModal(checkpoint, transport, actionType, hide) {
      if (hide) {
        hide();
      }

      var modalName = checkpoint.get('task.customModalComponentName') || checkpoint.get('task.DEFAULT_CUSTOM_MODAL_COMPONENT_NAME');
      checkpoint.set('actionType', actionType);
      this.send('showModal', 'components.task-custom-modals.' + modalName, checkpoint);
    },
    confirmRejection: function confirmRejection() {
      var msg = this.get('intl').t('checkpoint.rejectConfirm');
      return confirm(msg);
    }
  });

  _exports.default = _default;
});