define("apollo/pods/company/adapter", ["exports", "apollo/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    // https://emberigniter.com/retrieve-ember-data-models-non-rest-api/
    urlForQuery: function urlForQuery(query) {
      if (query.statuses) {
        return "".concat(this.urlPrefix(), "/companies/carriersToPass");
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});