define("apollo/services/websocket", ["exports", "apollo/config/environment", "apollo/helpers/websocket-proxy"], function (_exports, _environment, _websocketProxy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    sessionAccount: Ember.inject.service('session-account'),
    _sock: null,
    _client: null,
    proxyClient: null,
    reconnectCounter: 0,
    reconnectCounterLimit: Ember.computed('', function () {
      return _environment.default.websocket.reconnectCounterLimit;
    }),
    reconnectTimeout: Ember.computed('', function () {
      return _environment.default.websocket.reconnectTimeout;
    }),
    errorAlertShowed: false,
    isReconnecting: false,
    allowWebsockets: Ember.computed('', function () {
      return this.get('sessionAccount').getSettingValue("USE_WEBSOCKETS") === 'true';
    }),
    getSocket: function getSocket() {
      var existingSocketProxy = this.get('proxyClient');

      if (existingSocketProxy != null && this._isWebSocketConnecting()) {
        return null;
      }

      if (existingSocketProxy != null && this._isWebSocketOpen()) {
        return existingSocketProxy;
      }

      var newSocket = this._createSocket();

      if (existingSocketProxy != null) {
        this.set('proxyClient', newSocket);
        return existingSocketProxy;
      }

      var newProxySocket = this._createProxySocket(newSocket);

      this.set('proxyClient', newProxySocket);
      return newProxySocket;
    },
    _createSocket: function _createSocket() {
      var _this = this;

      var token = this.get('sessionAccount').get('token');
      var user = this.get('sessionAccount').get('currentUser');

      if (user === undefined) {
        console.debug('Nie możemy użyć websocketów, ponieważ użytkownik nie jest zalogowany.');
        return null;
      }

      if (!this.get('allowWebsockets')) {
        console.debug('Nie możemy użyć websocketów, ponieważ setting "USE_WEBSOCKETS" jest wyłączony.');
        return null;
      }

      this._sock = new SockJS(_environment.default.stompURL, null, {
        transports: 'websocket' // W naszej wersji Springa(5.2.21) jest bug, który uniemożliwia korzystanie z transportu typu `xhr-streaming`
        // oraz `xhr-polling` dla SockJS. Omijamy tego bug'a konfigurując SockJS w taki sposób, aby korzystał wyłącznie
        // z protokołu websocket.
        // https://github.com/sockjs/sockjs-client/issues/308
        // https://github.com/spring-projects/spring-framework/commit/f004bb1b64cd9f39d58adc181607a1d5f3034b29

      });
      this._client = webstomp.over(this._sock, {
        debug: _environment.default.stompDebug,
        protocols: webstomp.VERSIONS.supportedProtocols(),
        heartbeat: false
      });

      this._client.connect({
        username: user.get('username'),
        token: token
      }, function () {
        console.debug('Poprawnie ustanowiliśmy połączenie z websocketem..');

        if (_this.get('isReconnecting') && _this.get('reconnectCounter') > 1) {
          alert('Połączenie zostało przywrócone poprawnie.');
          location.reload();
        }

        _this.set('isReconnecting', false);

        _this.set('errorAlertShowed', false);
      }, function () {
        console.error('Nie mogliśmy ustanowić połączenia z websocketem. Próbujemy ponownie.');

        _this._reconnect();
      });

      return this._client;
    },
    _reconnect: function _reconnect() {
      var _this2 = this;

      if (this.get('reconnectCounter') < this.get('reconnectCounterLimit')) {
        this.set('isReconnecting', true);

        if (!this.get('errorAlertShowed') && this.get('reconnectCounter') > 1) {
          alert('Utraciłeś połączenie. Poczekaj na przywrócenie połączenia lub odśwież stronę.');
          this.set('errorAlertShowed', true);
        }

        Ember.run.later(function () {
          _this2.set('reconnectCounter', _this2.get('reconnectCounter') + 1);

          _this2.set('proxyClient', _this2.getSocket());
        }, this.get('reconnectTimeout'));
      }
    },
    _isWebSocketConnecting: function _isWebSocketConnecting() {
      return this._client != null && this._client.ws.readyState === 0;
    },
    _isWebSocketOpen: function _isWebSocketOpen() {
      return this._client != null && this._client.connected;
    },
    _createProxySocket: function _createProxySocket(client) {
      return _websocketProxy.default.create({
        content: this,
        client: client
      });
    }
  });

  _exports.default = _default;
});