define("apollo/pods/components/render-field-errors/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    fieldErrors: function () {
      var fieldName = this.get('fieldName');
      return this.get('model.errors.' + fieldName);
    }.property('model.errors.@each'),
    modelName: function () {
      return this.get('model.constructor.modelName') ? this.get('model.constructor.modelName') : this.get('model._internalModel.modelName');
    }.property('model')
  });

  _exports.default = _default;
});