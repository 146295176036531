define("apollo/pods/price-list/index/controller", ["exports", "apollo/pods/transports/index/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    sessionAccount: Ember.inject.service('session-account'),
    availableTransportTypes: Ember.computed('model.availableTransportTypes', function () {
      return this.get('model.availableTransportTypes');
    }),
    shouldDisplayTransportTypeButtons: Ember.computed('availableTransportTypes.length', function () {
      return this.get('availableTransportTypes.length') > 1;
    }),
    transportType: Ember.computed('availableTransportTypes.@each.alias', 'transportTypeMode', function () {
      var _this = this;

      return this.get('availableTransportTypes').find(function (tt) {
        return tt.get('alias') === _this.get('transportTypeMode');
      });
    }),
    shouldDisplayCreateNewRouteButton: Ember.computed('transportType', function () {
      return this.get('transportType').findPositiveAuthorityRestrictionsByActionType('ROLE_CAN_ADD_ROUTE', this.get('transportType.id')).length > 0;
    }),
    actions: {
      setTransportTypeMode: function setTransportTypeMode(mode) {
        console.log("Cenniki: Przechodzimy do typu ".concat(mode, "..."));
        this.transitionToRoute('price-list.index', mode, {
          queryParams: {
            max: this.get('max'),
            page: 1
          }
        });
      },
      showCreateRouteForm: function showCreateRouteForm() {
        var _this2 = this;

        var routeCustomFieldDefinitions = this.get('transportType.sortedFieldDefinitions').filter(function (d) {
          return d.get('id') && d.get('enabled') && d.get('includedInPriceList');
        });
        var routeCustomFields = routeCustomFieldDefinitions.map(function (definition) {
          return _this2.get('store').createRecord('routeCustomField', {
            definition: definition
          });
        });
        var route = this.get('store').createRecord('route', {
          transportType: this.get('transportType'),
          routeCustomFields: routeCustomFields
        });
        var routeAddressLoad = this.get('store').createRecord('routeAddress', {
          stepType: 'load',
          idx: 0,
          stepTypeIdx: 0
        });
        var routeAddressUnload = this.get('store').createRecord('routeAddress', {
          stepType: 'unload',
          idx: 1,
          stepTypeIdx: 0
        });
        route.get('routeAddresses').addObject(routeAddressLoad);
        route.get('routeAddresses').addObject(routeAddressUnload);
        this.send('showModal', 'routes.modals.route-form.show', route);
      }
    }
  });

  _exports.default = _default;
});