define("apollo/pods/transaction/modals/edit-freight/controller", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax-service'),
    title: Ember.computed('', function () {
      return this.get('intl').t('transaction.label');
    }),
    transaction: Ember.computed('model.transaction', function () {
      return this.get('model.transaction');
    }),
    currencies: Ember.computed('', function () {
      return this.get('store').peekAll('currency');
    }),
    priceUpdateDisabled: Ember.computed('transaction.routeOffer.id', function () {
      return !!this.get('transaction.routeOffer.id');
    }),
    actions: {
      editFreight: function editFreight() {
        var _this = this;

        var transaction = this.get('transaction');

        if (!transaction.validate({
          only: "price"
        })) {
          return;
        }

        var data = {
          freight: transaction.get('price'),
          currencyId: transaction.get('currency.id')
        };
        this.get('ajaxService').send("/transactions/".concat(transaction.get('id'), "/editFreight"), "PUT", data, true).then(function () {
          _this.set('errors', []);

          (0, _jquery.default)("#main-modal").data("skip-cancel-action", true);

          _this.send('hideModal');
        }).catch(function (response) {
          _this.set('errors', response.responseJSON["errors"]);
        });
      },
      cancel: function cancel() {
        console.debug("Wycofujemy zmiany w okienku edycji frachtu...");
        this.get('transaction').rollbackAttributes(); // ember nie umie wycofywać zmian w relacjach :/

        this.set('transaction.currency', this.get('model.initialCurrency'));
      },
      close: function close() {
        this.send('hideModal');
      }
    }
  });

  _exports.default = _default;
});