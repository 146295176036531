define("apollo/pods/places/create/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajaxService: Ember.inject.service('ajax-service'),
    actions: {
      save: function save() {
        var self = this;
        this.get('model').validate();

        if (this.get('model').get('errors.length')) {
          return;
        }

        this.get('model').save().then(function (place) {
          console.debug("Place ".concat(place.get('name'), " saved."));
          self.setProperties({
            errors: []
          });
        }).catch(function (response) {
          self.set('errors', response.errors);
        }).finally(function () {
          self.transitionToRoute('squares.edit', self.get('model.queue.square'));
        });
      },
      disable: function disable() {
        var self = this;
        var model = this.get('model');
        this.get('ajaxService').send('/places/' + model.get('id') + '/disable', 'PUT', model, true).then(function () {
          model.set('enabled', false);
          self.transitionToRoute('squares.edit', self.get('model.queue.square'));
        });
      },
      enable: function enable() {
        var self = this;
        var model = this.get('model');
        this.get('ajaxService').send('/places/' + model.get('id') + '/enable', 'PUT', model, true).then(function () {
          model.set('enabled', true);
          self.transitionToRoute('squares.edit', self.get('model.queue.square'));
        });
      },
      cancel: function cancel() {
        var square = this.get('model.queue.square');
        this.get('model').rollbackAttributes();
        this.transitionToRoute('squares.edit', square);
      }
    }
  });

  _exports.default = _default;
});