define("apollo/pods/transports/modals/transport-relations/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    ajaxService: Ember.inject.service('ajax-service'),
    relationsCombiner: Ember.inject.service('transport-relations.relations-combiner'),
    errors: Ember.A([]),
    noTypeForSelection: Ember.computed('showTransportCombiningLabel', 'showHasToWaitForLabel', function () {
      return this.get('model.currentPossibleRelations.length') < 1;
    }),
    relationList: Ember.computed('model.currentPossibleRelations.[]', function () {
      return this.get('model.currentPossibleRelations');
    }),
    showTransportCombiningLabel: Ember.computed('model.relation', function () {
      return this.get('model.relation.alias') === 'TRANSPORT_COMBINING';
    }),
    masterTransportLabel: Ember.computed('model.firstObject', function () {
      return this.get('model.masterTransport.identifier');
    }),
    otherTransportsLabels: Ember.computed('model.otherTransports.[]', function () {
      var otherTransports = this.get('model.otherTransports');
      var string = '';
      otherTransports.forEach(function (transport) {
        if (transport.id) {
          string += transport.get('identifier') + ' :: ';
        }
      });
      return string.substring(0, string.length - 4);
    }),
    showHasToWaitForLabel: Ember.computed('model.relation', function () {
      return this.get('model.relation.alias') === 'HAS_TO_WAIT_FOR';
    }),
    title: Ember.computed('', function () {
      return this.get('intl').t('transport.relations.title');
    }),
    overweight: Ember.computed('model.selectedRelation', function () {
      var relation = this.get('model.selectedRelation');

      if (!relation) {
        return false;
      }

      return this.get('relationsCombiner').isOverweight(relation);
    }),
    actions: {
      setSelectedRelation: function setSelectedRelation(relation) {
        this.set('model.selectedRelation', relation);
      },
      combine: function combine() {
        var combiningErrors = this.get('relationsCombiner').combine(this.get('model.selectedRelation'));

        if (combiningErrors && combiningErrors.length > 0) {
          this.setProperties({
            errors: combiningErrors
          });
          return;
        }

        this.send('close');
      },
      close: function close() {
        this.set('errors', []);
        this.send('refreshRoute');
        this.send('hideModal');
      }
    }
  });

  _exports.default = _default;
});