define("apollo/services/user-access-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    canAccess: function canAccess(requestedUserId, generalAccess, myAccountAccess) {
      var currentUserId = this.get('sessionAccount.currentUser.id');
      var isInMyAccount = currentUserId === requestedUserId;
      var properRole = isInMyAccount ? myAccountAccess : generalAccess;
      return properRole ? this.get('sessionAccount').hasRole(properRole) : isInMyAccount;
    }
  });

  _exports.default = _default;
});