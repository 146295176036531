define("apollo/pods/checkpoint/model", ["exports", "ember-data", "apollo/mixins/model-validator", "ember-data/model"], function (_exports, _emberData, _modelValidator, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = _model.default.extend(_modelValidator.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    languageService: Ember.inject.service('language-service'),
    transport: belongsTo('transport', {
      async: true,
      inverse: 'checkpoints'
    }),
    step: belongsTo('step', {
      async: true
    }),
    task: belongsTo('task', {
      async: true
    }),
    confirmator: belongsTo('user', {
      async: true
    }),
    expectedDate: _emberData.default.attr('isodate'),
    alertDate: _emberData.default.attr('isodate'),
    executionDate: _emberData.default.attr('isodate'),
    rejectionDate: _emberData.default.attr('isodate'),
    idx: _emberData.default.attr('number'),
    checkpointName: _emberData.default.attr('string'),
    blockadeReason: attr('string'),
    isBlocked: attr('boolean'),
    blockadeRequester: belongsTo('user', {
      async: true
    }),
    skipGeneratingSubtasks: attr('boolean'),
    ACTIONS: Object.freeze({
      CONFIRM: 'CONFIRM',
      REJECT: 'REJECT',
      BLOCK: 'BLOCK'
    }),
    validations: {
      executionDate: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!value) {
              return true;
            }

            var previousCheckpoint = model.get('transport.sortedCheckpoints').reverse().find(function (ch) {
              return ch.get('executionDate') && ch.get('id') !== model.get('id');
            });
            var isDateLaterThanPreviousCheckpoint = value > previousCheckpoint.get('executionDate');
            var isDateInPast = value < new Date();
            return isDateInPast && isDateLaterThanPreviousCheckpoint;
          },
          message: 'isInPast'
        }]
      },
      blockadeReason: {
        custom: [{
          validation: function validation(key, value, model) {
            if (!model.get('task.canBeBlocked')) {
              return true;
            }

            if (!model.get('blockadeRequester.id')) {
              return true;
            }

            return value;
          },
          message: 'blank'
        }, {
          validation: function validation(key, value) {
            if (!value) {
              return true;
            }

            return value.length < 255;
          },
          message: 'length'
        }]
      }
    },
    isLate: Ember.computed('executionDate', 'expectedDate', function () {
      return !this.get('isConfirmed') && this.get('expectedDate').getTime() <= new Date().getTime();
    }),
    wasLate: Ember.computed('executionDate', 'expectedDate', function () {
      if (this.get('executionDate') === null) {
        return false;
      } else {
        return this.get('expectedDate') < this.get('executionDate');
      }
    }),
    isConfirmed: Ember.computed('executionDate', 'confirmator.id', function () {
      return this.get('executionDate') !== null && this.get('confirmator.id');
    }),
    isRejected: Ember.computed('rejectionDate', function () {
      return this.get('rejectionDate') !== null;
    }),
    name: Ember.computed('task.{checkpointName,checkpointNameEn}', function () {
      var propertyName = this.get('languageService').findProperLanguageVersion('checkpointName', 'checkpointNameEn');
      return this.get('task').get(propertyName);
    }),
    altCheckpointName: Ember.computed('task.{altCheckpointName,altCheckpointNameEn}', function () {
      var propertyName = this.get('languageService').findProperLanguageVersion('altCheckpointName', 'altCheckpointNameEn');
      return this.get('task').get(propertyName);
    }),
    altCheckpointColor: Ember.computed('task.altCheckpointColor', function () {
      if (this.get('task.altCheckpointColor')) {
        return Ember.String.htmlSafe('background-color: ' + this.get('task.altCheckpointColor') + ';');
      }

      return null;
    }),
    altCheckpointLabel: Ember.computed('name', 'altCheckpointName', function () {
      var altCheckpointName = this.get('altCheckpointName');
      var checkpointName = this.get('name');
      var taskIncludesWeighing = this.get('task.weighingIncluded');
      var warehouseIncludesWeighing = this.get('step.warehouse.weighingIncluded');

      if (taskIncludesWeighing && !warehouseIncludesWeighing) {
        return checkpointName;
      }

      return altCheckpointName || checkpointName;
    }),
    stageName: Ember.computed('task.stageName', 'name', function () {
      var propertyName = this.get('languageService').findProperLanguageVersion('task.stageName', 'task.stageNameEn');
      return this.get(propertyName) || this.get('intl').t('task.defaultStagePrefix') + ' ' + this.get('name');
    }),
    stageNameEnTrimmed: Ember.computed('task.stageNameEn', 'step.warehouse.name', function () {
      var stageNameEn = this.get('task.stageNameEn') ? this.get('task.stageNameEn') : this.get('intl').t('task.defaultStagePrefix') + ' ' + this.get('name');

      if (this.get('step.warehouse')) {
        stageNameEn = stageNameEn.concat(' ', this.get('step.warehouse.name'));
      }

      return stageNameEn.replace(/ /g, '-');
    }),
    checkpointGroup: Ember.computed('task.checkpointGroup', function () {
      return this.get('task.checkpointGroup');
    }),
    idxInGroup: Ember.computed('task.idxInGroup', function () {
      return this.get('task.idxInGroup');
    }),
    tracked: Ember.computed('task.tracked', function () {
      return this.get('task.tracked');
    }),
    currentWeight: Ember.computed('transport.weights.@each.enabled', function () {
      var _this = this;

      var weights = this.get('transport.weights').filterBy('enabled');
      return weights.filter(function (w) {
        return w.get('task.id') === _this.get('task.id');
      }).get('firstObject');
    }),
    hasUnsynchronizedWeight: Ember.computed('currentWeight', function () {
      return localStorage['weight_'.concat(this.get('task.id'), '_', this.get('transport.id'))];
    }),
    showNetWeight: Ember.computed('checkpoint.task.isLastWeighingTask', 'transport.netWeight', function () {
      var isWarehouseWithWeighing = this.get('step.warehouse.weighingIncluded');
      var entryWeightTaskId = this.get('transport.entryWeight.task.id');
      var isDeparture = !Ember.isEmpty(this.get('transport.entryWeight')) && this.get('task.id') !== entryWeightTaskId;
      return !Ember.isEmpty(this.get('transport.netWeight')) && isWarehouseWithWeighing && isDeparture;
    }),
    transportCustomFields: Ember.computed('transport.customFields', function () {
      return this.get('transport.customFields');
    }),
    customFieldsPairsWithCurrentTaskBlockade: Ember.computed('id', 'transport.{firstUnconfirmedCheckpoint,customFieldPairs}', 'transportCustomFields.@each.{value,isDirty}', function () {
      var self = this;
      return this.get('transport.customFieldPairs').filter(function (cfp) {
        var visibilityDependsOnDefinition = cfp.definition ? cfp.definition.get('visibilityDependsOnDefinition.id') : false;
        var isVisibleBasedOnDefinition = cfp.value ? cfp.value.get('isVisibleBasedOnDefinition') : false;
        var currentTaskMatch = cfp.definition.get('task.id') === self.get('task.id');

        if (visibilityDependsOnDefinition) {
          return currentTaskMatch && isVisibleBasedOnDefinition;
        }

        return currentTaskMatch;
      });
    }),
    hasCustomFieldsBlockade: Ember.computed('customFieldsPairsWithCurrentTaskBlockade', function () {
      var hasBlockade = false;
      this.get('customFieldsPairsWithCurrentTaskBlockade').forEach(function (cfp) {
        var isSelectType = cfp.definition.get('type') === 'select';
        var valueIsNotSaved = !cfp.value || !cfp.value.get('id');
        var valueIsDirty = cfp.value && cfp.value.get('isDirty');
        var valueIsEmpty = !cfp.value || !cfp.value.get('value');
        var valueIsChosen = cfp.value && cfp.value.get('option.id');

        if (!isSelectType && (valueIsEmpty || valueIsDirty)) {
          hasBlockade = true;
        } else if (isSelectType && (valueIsNotSaved || !valueIsChosen)) {
          hasBlockade = true;
        }
      });
      return hasBlockade;
    }),
    hasWeightBlockade: Ember.computed('task.weighingIncluded', function () {
      var weightAccess = this.get('sessionAccount').getSettingValue('AUTOMATIC_WEIGHING') === 'true';
      var weightUrl = !!this.get('sessionAccount').getSettingValue('WEIGHT_URL');
      var weightElement = !!this.get('sessionAccount').getSettingValue('WEIGHT_SELECTOR');
      var autoWeight = weightAccess && weightUrl && weightElement;
      return this.get('task.weighingIncluded') && !autoWeight && !this.get('currentWeight');
    }),
    hasCustomModalBlockade: Ember.computed('task.openCustomModal', function () {
      return this.get('task.openCustomModal');
    }),
    hasWarehouseLocationBlockade: Ember.computed('task.requiresWarehouseLocation', 'step.stepWarehouseLocations', function () {
      return this.get('task.requiresWarehouseLocation') && this.get('step.stepWarehouseLocations').length < 1;
    }),
    hasDocumentBlockade: Ember.computed('task.canBeConfirmAfterAddingDocumentsForTransport', 'transport.numberOfFiles', function () {
      return this.get('task.canBeConfirmAfterAddingDocumentsForTransport') && this.get('transport.numberOfFiles') === 0;
    }),
    confirmButtonShouldBeDisabled: Ember.computed('hasDocumentBlockade', function () {
      return this.get('hasDocumentBlockade');
    }),
    checkpointBlockadeInfo: Ember.computed('confirmButtonShouldBeDisabled', function () {
      var message = Ember.Object.create({
        title: '',
        fields: ''
      });

      if (this.get('hasDocumentBlockade')) {
        message.title = this.get('intl').t('task.blockade.documentRestriction');
        message.fields = '';
      }

      return message;
    }),
    shouldConfirmationButtonBeVisible: Ember.computed('id', 'transport.firstUnconfirmedCheckpoint.id', 'task.canConfirmPrevious', function () {
      if (this.get('id') === this.get('transport.firstUnconfirmedCheckpoint.id') || this.get('transport.firstUnconfirmedCheckpoint.canBeSkipped') && this.get('id') === this.get('transport.firstMandatoryCheckpoint.id')) {
        return true;
      } else {
        return this.get('task.canConfirmPrevious');
      }
    }),
    classificationResourceString: Ember.computed('name', 'executionDate', function () {
      var formattedDate = this.get('executionDate') ? moment(this.get('executionDate')).format('HH:mm') : '';
      return "".concat(this.get('name'), " ").concat(formattedDate);
    }),
    canBeSkipped: Ember.computed('transport.firstUnconfirmedCheckpoint.id', function () {
      var self = this;
      return !!this.get('sessionAccount.authorityRestrictions').find(function (authorityRestriction) {
        return authorityRestriction.get('authority.name') === 'ROLE_CAN_SKIP_CHECKPOINT_CONFIRMATION' && authorityRestriction.get('task.id') === self.get('task.id');
      });
    }),
    canBeBlocked: Ember.computed('task.canBeBlocked', 'isConfirmed', function () {
      return this.get('task.canBeBlockedByRole') && !this.get('isConfirmed');
    }),
    isWeighingIncludedInStepWarehouse: Ember.computed('step.warehouse.weighingIncluded', function () {
      return this.get('step.warehouse').get('weighingIncluded');
    }),
    transportCustomFieldDefinitions: Ember.computed('transport.customFields.[]', function () {
      return this.get('transport').get('customFields').filter(function (cf) {
        return cf.get('definition');
      });
    }),
    isCustomFieldRequiredInDelayedCheckpoint: Ember.computed('transportCustomFieldDefinitions.delayedTask.stageName', 'task.stageName', function () {
      var _this2 = this;

      var customFieldWithDelayedTask = this.get('transport').get('customFields').filter(function (cf) {
        return cf.get('definition.delayedTask').get('stageName') === _this2.get('task').get('stageName');
      }).get('firstObject');

      if (customFieldWithDelayedTask === undefined) {
        return false;
      }

      var cfd = customFieldWithDelayedTask.get('definition');
      var cfdTimeInvervalValue = cfd.get('timeIntervalValue');
      var cfdTimeInvervalUnit = cfd.get('timeIntervalUnit');
      var checkpointExpectedDate = this.get('expectedDate');
      var expectedDateWithAllowedTimeFromCfd = moment(checkpointExpectedDate).add(cfdTimeInvervalValue, cfdTimeInvervalUnit).toDate();
      return moment(expectedDateWithAllowedTimeFromCfd).isBefore(moment().toDate());
    })
  });

  _exports.default = _default;
});