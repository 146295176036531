define("apollo/pods/components/custom-field-dependency/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    availableDefinitions: Ember.computed('model.id', function () {
      var transportTypeId = this.get('model.transportType.id');
      return this.get('store').peekAll('customFieldDefinition').filter(function (definition) {
        return definition.get('transportType.id') === transportTypeId && definition.get('isSelectType');
      });
    }),
    availableOptions: Ember.computed('model.visibilityDependsOnDefinition.id', function () {
      return this.get('model.visibilityDependsOnDefinition.options');
    }),
    definitionIsChosen: Ember.computed('model.visibilityDependsOnDefinition.id', function () {
      return this.get('model.visibilityDependsOnDefinition.id');
    }),
    properHint: Ember.computed('model.obligatory', function () {
      return this.get('model.obligatory') ? this.get('intl').t('custom-field-definition.makeFieldDependentOn.hint.obligatory') : this.get('intl').t('custom-field-definition.makeFieldDependentOn.hint.voluntary');
    }),
    showDependsOnTaskInfo: Ember.computed('model.visibilityDependsOnDefinition.task.id', function () {
      return !!this.get('model.visibilityDependsOnDefinition.task.id');
    }),
    actions: {
      setDefinition: function setDefinition(definition) {
        this.set('model.visibilityDependsOnDefinition', definition);
        this.set('model.visibilityDependsOnOption', null);
      },
      setOption: function setOption(optionId) {
        var option = optionId ? this.get('store').peekRecord('customFieldOption', optionId) : null;
        this.set('model.visibilityDependsOnOption', option);
      },
      toggleShowDependencySection: function toggleShowDependencySection() {
        this.toggleProperty('model.showDependencySection');
      }
    }
  });

  _exports.default = _default;
});