define("apollo/pods/components/transaction/courier-transaction-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax-service'),
    actions: {
      createTransactionAndOpenChooseCourierModal: function createTransactionAndOpenChooseCourierModal() {
        var _this = this;

        console.log("Przygotowujemy i pokazujemy okienko wyboru kuriera...");
        this.get('transport').reload().then(function () {
          var transport = _this.get('transport');

          var steps = transport.get('steps');
          var courierPricings = transport.get('courierPricings');
          var firstLoadStep = steps.find(function (s) {
            return s.stepTypeName === 'load' && s.orderInTransport === 0;
          });
          var lastUnloadStep = steps.find(function (s) {
            return s.stepTypeName === 'unload' && s.orderInTransport === steps.length - 1;
          });
          var courierDate = moment(firstLoadStep.get('minAdviceDate')).format('YYYY-MM-DD');
          var courierHourMin = moment(firstLoadStep.get('minAdviceDate')).format('HH:mm');
          var courierHourMax = moment(firstLoadStep.get('maxAdviceDate')).format('HH:mm');

          var transaction = _this.get('store').createRecord('transaction', {
            transport: transport
          });

          _this.set('action', 'showModal');

          var services = [];
          var couriers = [];
          courierPricings.forEach(function (it) {
            if (it.get('enabled') === true && it.get('pricingSuccesses').length > 0) {
              couriers.push(it.courierCompany);
            }

            it.pricingSuccesses.forEach(function (success) {
              return services.push(success);
            });
          });
          var model = {
            transaction: transaction,
            courierDate: courierDate,
            courierHourMin: courierHourMin,
            courierHourMax: courierHourMax,
            loadCity: firstLoadStep.get('address.city'),
            unloadCity: lastUnloadStep.get('address.city'),
            couriers: couriers,
            services: services
          };

          _this.sendAction('action', 'transaction.modals.choose-courier', model);
        });
      }
    }
  });

  _exports.default = _default;
});