define("apollo/pods/components/expandable-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set("isOpen", this.get("defaultOpen"));
    },
    performActionOnOpen: Ember.observer('isOpen', function () {
      if (!this.get("isOpen")) {
        return;
      }

      if (this.onOpen) {
        this.onOpen();
      }
    }),
    showErrors: Ember.computed('errors', function () {
      if (this.get('errors') && this.get('errors').filter(function (error) {
        return error !== undefined;
      }).length > 0) {
        if (!this.isOpen) {
          this.send('togglePanel');
        }

        return "has-error";
      } else {
        return "";
      }
    }),
    actions: {
      togglePanel: function togglePanel() {
        this.toggleProperty("isOpen");
      }
    }
  });

  _exports.default = _default;
});