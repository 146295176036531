define("apollo/pods/announcements/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'max'],
    ajaxService: Ember.inject.service('ajax-service'),
    sessionAccount: Ember.inject.service('session-account'),
    applicationController: Ember.inject.controller('application'),
    announcements: Ember.computed('model', function () {
      return this.get('model');
    }),
    actions: {
      markAsRead: function markAsRead(announcement) {
        var _this = this;

        if (announcement.get("isRead")) {
          return;
        }

        this.get("ajaxService").send("/announcements/" + announcement.get('id') + "/markAsRead", "POST").then(function () {
          announcement.set("isRead", true);

          _this.get('applicationController').refreshAnnouncementCounter();
        });
      }
    }
  });

  _exports.default = _default;
});