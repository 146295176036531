define("apollo/pods/transports/archive/controller", ["exports", "apollo/mixins/transports-mixin"], function (_exports, _transportsMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Controller.extend(_transportsMixin.default, {
    store: service(),
    sessionAccount: service('session-account'),
    queryParams: ['query', 'start', 'stop', 'dateFilterProperty', 'page'],
    searchParams: function () {
      var query = this.get('query') || '';
      var start = this.get('start') || '';
      var stop = this.get('stop') || '';
      var dateFilterProperty = this.get('dateFilterProperty');

      if (this.get('sessionAccount').isGroupFilterEnabled()) {
        return {
          filterMode: this.get('filterMode') ? this.get('filterMode') : 'user',
          query: query,
          start: start,
          stop: stop,
          dateFilterProperty: dateFilterProperty,
          filters: Ember.A()
        };
      }

      return {
        query: query,
        start: start,
        stop: stop,
        dateFilterProperty: dateFilterProperty,
        filters: Ember.A()
      };
    }.property('model', 'query', 'start', 'stop', 'filterMode', 'dateFilterProperty'),
    availableTransportTypes: function () {
      return [this.get('model')];
    }.property('model.id'),
    allAvailableTransportTypes: function () {
      var principalId = localStorage.getItem('principalId');
      var company = this.store.peekRecord('company', principalId);
      return company.get('transportTypes').filterBy('canBeSeenInArchiveByRoleGroup');
    }.property('sessionAccount.currentUser.transportTypes.@each'),
    actions: {
      setTransportTypeMode: function setTransportTypeMode(mode) {
        var transportTypeId = this.get('store').peekAll('transportType').filter(function (type) {
          return type.get('alias') === mode;
        }).get('firstObject.id');
        this.transitionToRoute('transports.archive', transportTypeId);
      },
      setQueryParams: function setQueryParams(query, start, stop, dateFilterProperty) {
        this.setProperties({
          query: query,
          start: start,
          stop: stop,
          dateFilterProperty: dateFilterProperty
        });
      },
      clearQueryParams: function clearQueryParams(dateFilterProperty) {
        this.setProperties({
          query: undefined,
          start: undefined,
          stop: undefined,
          dateFilterProperty: dateFilterProperty
        });
      }
    }
  });

  _exports.default = _default;
});