define("apollo/pods/classification-resources/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    store: Ember.inject.service(),
    ajaxService: Ember.inject.service('ajax-service'),
    sessionAccount: Ember.inject.service('session-account'),
    translationService: Ember.inject.service('translation-service'),
    classificationResourcesWithoutQueue: Ember.computed('model.classificationResources.@each', 'model.classificationResources.@each.state', function () {
      return this.get('model.classificationResources').filter(function (cr) {
        return cr.get('state') === cr.get('STATE_NAMES.CREATED');
      });
    }),
    classificationResourcesWithQueue: Ember.computed('model.classificationResources.@each', 'model.classificationResources.@each.state', function () {
      return this.get('model.classificationResources').filter(function (cr) {
        return cr.get('state') === cr.get('STATE_NAMES.HAS_QUEUE');
      });
    }),
    classificationResourcesWithPlace: Ember.computed('model.classificationResources.@each', 'model.classificationResources.@each.state', function () {
      return this.get('model.classificationResources').filter(function (cr) {
        return cr.get('state') === cr.get('STATE_NAMES.HAS_PLACE');
      });
    }),
    queuesStatus: Ember.computed('classificationResourcesWithQueue', function () {
      var self = this;
      var squares = self.get('sessionAccount.currentUser.accessibleSquares');
      var queuesStatusArray = Ember.A();
      squares.forEach(function (s) {
        var queues = s.get('queues').filter(function (q) {
          return self.get('sessionAccount.currentUser.accessibleQueues').getEach('id').includes(q.get('id'));
        });
        queues.forEach(function (q, idx) {
          var obj = Ember.Object.create({
            squareName: idx === 0 ? q.get('square.name') : '',
            queueName: q.get('nameInAppropriateLanguage'),
            quantityInQueue: self.get('classificationResourcesWithQueue').filter(function (cr) {
              return cr.get('queue.id') === q.get('id');
            }).get('length'),
            quantityOnPlace: self.get('classificationResourcesWithPlace').filter(function (cr) {
              return cr.get('queue.id') === q.get('id');
            }).get('length')
          });
          queuesStatusArray.pushObject(obj);
        });
      });
      return queuesStatusArray;
    }),
    columns: Ember.computed('showCarriersTable', function () {
      var columns = [{
        propertyName: 'idx',
        title: this.get('intl').t('common.orderNumber'),
        className: 'order-number',
        disableFiltering: true,
        disableSorting: true,
        visible: this.checkColumnVisibility('orderNumberFirstColumnVisibility')
      }, {
        propertyName: 'resource.carRegistrationNumber',
        title: this.get('intl').t('advice.carRegistrationNumberSimple'),
        className: 'car-registration-number',
        disableFiltering: true,
        disableSorting: true,
        visible: this.checkColumnVisibility('carRegistrationFirstTableColumnVisibility')
      }, {
        propertyName: 'resource.hasHDS',
        title: 'HDS',
        className: 'hds',
        disableFiltering: true,
        disableSorting: true,
        visible: this.checkColumnVisibility('hdsFirstTableColumnVisibility')
      }, {
        propertyName: 'common.square',
        title: this.get('translationService').getTranslation('square', 'square'),
        className: 'square',
        disableFiltering: true,
        disableSorting: true,
        visible: this.checkColumnVisibility('squareFirstTableColumnVisibility')
      }, {
        propertyName: 'resource.queue',
        title: this.get('translationService').getTranslation('square', 'queueLabel'),
        className: 'queue',
        disableFiltering: true,
        disableSorting: true,
        visible: this.checkColumnVisibility('queueFirstTableColumnVisibility')
      }, {
        propertyName: '',
        title: '',
        disableFiltering: true,
        disableSorting: true,
        visible: true
      }];
      return columns.filter(function (c) {
        return c.visible === true;
      });
    }),
    queueStatusTableColumns: Ember.computed('showCarriersTable', function () {
      var columns = [{
        propertyName: 'square',
        title: this.get('translationService').getTranslation('square', 'square'),
        className: 'square',
        disableFiltering: true,
        disableSorting: true,
        visible: this.checkColumnVisibility('squareSecondTableColumnVisibility')
      }, {
        propertyName: 'queue',
        title: this.get('translationService').getTranslation('square', 'queueLabel'),
        className: 'queue',
        disableFiltering: true,
        disableSorting: true,
        visible: this.checkColumnVisibility('queueSecondTableColumnVisibility')
      }, {
        propertyName: 'buffer',
        title: this.get('translationService').getTranslation('square', 'buffer'),
        className: 'buffer',
        disableFiltering: true,
        disableSorting: true,
        visible: this.checkColumnVisibility('bufferSecondTableColumnVisibility')
      }, {
        propertyName: 'unload',
        title: this.get('translationService').getTranslation('square', 'unload'),
        className: 'unload',
        disableFiltering: true,
        disableSorting: true,
        visible: this.checkColumnVisibility('unloadSecondTableColumnVisibility')
      }];
      return columns.filter(function (c) {
        return c.visible === true;
      });
    }),
    squareSetting: Ember.computed(function () {
      return this.get('sessionAccount').getSquareSetting();
    }),
    checkColumnVisibility: function checkColumnVisibility(propertyName) {
      var squareSetting = this.get('squareSetting');

      if (!squareSetting) {
        return true;
      }

      return squareSetting.get(propertyName);
    },
    actions: {
      assignQueue: function assignQueue(resource) {
        var self = this;

        if (!resource.get('assignQueueValidator').validate()) {
          $('.save-group-button').button('reset');
          return;
        }

        var data = {
          classificationResource: resource,
          checkpointToConfirm: resource.get('transport.firstUnconfirmedCheckpoint.id')
        };
        this.get('ajaxService').send('/classification-resources/assign-queue', 'PUT', data, true).then(function () {
          resource.reload();
          $('.save-group-button').button('reset');
          var delay = self.get('classifiedResourceDisappearanceDelay');
          setTimeout(function () {
            self.get('classificationResources').removeObject(resource);
          }, delay);
        });
      },
      setSquare: function setSquare(resource, square) {
        resource.set('square', square);
        resource.set('queue', null);
      }
    }
  });

  _exports.default = _default;
});