define("apollo/pods/components/transport/weight-section/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    store: Ember.inject.service(),
    canEditWeight: Ember.computed('transport.hasAutoWeight', function () {
      if (this.get('transport.hasAutoWeight')) {
        return true;
      } else {
        return !this.get('transport').actionCanBePerformed('ROLE_EDIT_WEIGHT');
      }
    }),
    transportWeightReceiptsList: Ember.computed('transport.transportWeightReceipts', function () {
      if (this.get('transport.isRelatedTransportInTransportCombining')) {
        return this.get('transport.masterTransports.firstObject.transportWeightReceipts');
      }

      return this.get('transport.transportWeightReceipts');
    }),
    actions: {
      weigh: function weigh() {
        var lastConfirmedCheckpointWithWeighingIncluded = this.get('transport.lastConfirmedCheckpointWithWeighingIncluded');

        if (!lastConfirmedCheckpointWithWeighingIncluded) {
          console.debug('Brak potwierdzonych checkpointów wymagających ważenia - nie dokonujemy pomiaru');
          return;
        }

        this.set('action', 'weigh');
        this.sendAction('action', lastConfirmedCheckpointWithWeighingIncluded);
      },
      generateWeightReceipt: function generateWeightReceipt() {
        this.set('action', 'generateWeightReceipt');
        this.sendAction('action', this.get('transport.id'));
      }
    }
  });

  _exports.default = _default;
});