define("apollo/pods/components/schedule/schedule-header/component", ["exports", "apollo/mixins/touch-mixin"], function (_exports, _touchMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Component.extend(_touchMixin.default, {
    sessionAccount: service('session-account'),
    tagName: 'div',
    classNames: ['schedule-header'],
    actionName: '',
    initResizeObserver: Ember.on('init', function () {
      $(window).on('resize', this.resize.bind(this));
    }),
    didInsertElement: function didInsertElement() {
      if (this.isTouchDevice) {
        this.send('makeScheduleScrollable');
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.isTouchDevice) {
        this.send('makeScheduleScrollable');
      }
    },
    resize: function resize() {
      //żeby na emulatorze się nie rozjeżdzało
      //przy zmianie rozmiaru okna
      if (this.isTouchDevice) {
        this.send('makeScheduleScrollable');
      }
    },
    touchStart: function touchStart(event) {
      this.touchstart(event);
    },
    touchEnd: function touchEnd(event) {
      var _this = this;

      this.touchend(event, function () {
        _this.handleSwipeX(function () {
          _this.get('shiftScheduleRight')();
        }, function () {
          _this.get('shiftScheduleLeft')();
        });
      });
    },
    actions: {
      makeScheduleScrollable: function makeScheduleScrollable() {
        // Gdy szerokość rampy będzie mniejsza niż RAMP_MIN_WIDTH px
        // to rozszerzamy pole harmonogramu aby móc je scrollować w poziomie
        var RAMP_MIN_WIDTH = 80; //px

        var NUMBER_OF_RAMPS = this.get('viewedColumns');
        var rampWidth = $('.ramp.even').first().outerWidth();

        if (rampWidth <= RAMP_MIN_WIDTH) {
          var minScheduleWidth = RAMP_MIN_WIDTH * NUMBER_OF_RAMPS;
          $('.schedule-header').css('min-width', minScheduleWidth);
          $('.schedule-body').css('min-width', minScheduleWidth);
        }
      },
      toggleWholeRampWindow: function toggleWholeRampWindow(time, rampId) {
        console.log("toggleWholeRampWindow :: t: ".concat(time, ", r: ").concat(rampId, ".."));
        this.set('actionName', 'toggleWholeRampWindow');
        this.sendAction('actionName', time, rampId);
      },
      showModal: function showModal(templateName, model) {
        this.set('action', 'showModal');
        this.sendAction('action', templateName, model);
      }
    }
  });

  _exports.default = _default;
});