define("apollo/pods/addresses/index/controller", ["exports", "apollo/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    canEditAddresses: Ember.computed(function () {
      return this.get('sessionAccount').hasRole('ROLE_CAN_EDIT_ADDRESSES');
    }),
    columns: Ember.computed('canEditAddresses', function () {
      var columns = [{
        propertyName: 'recipient',
        title: this.get('intl').t('address.recipient'),
        className: 'column-recipient'
      }, {
        propertyName: 'street',
        title: this.get('intl').t('address.street'),
        className: 'column-street'
      }, {
        propertyName: 'city',
        title: this.get('intl').t('address.city'),
        className: 'column-city'
      }, {
        propertyName: 'postal',
        title: this.get('intl').t('address.postal'),
        className: 'column-postal'
      }];

      if (this.get('canEditAddresses')) {
        columns.push({
          title: this.get('intl').t('common.edit'),
          className: 'column-edit',
          disableFiltering: true
        }, {
          title: this.get('intl').t('common.delete'),
          className: 'column-delete',
          disableFiltering: true
        });
      }

      return columns;
    }),
    actions: {
      disableAddress: function disableAddress(address) {
        var self = this;
        var token = this.get('sessionAccount').get('token');

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/addresses/' + address.get('id') + '/disable',
          type: 'PUT',
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(function () {
          address.set('enabled', false);
        }, function (response) {
          self.set('errors', response.errors);
        });
      },
      openEditAddressModal: function openEditAddressModal(address) {
        if (!address) {
          address = this.get('store').createRecord('address', {
            company: this.get('model.company'),
            enabled: true,
            isHeadquarters: false
          });
        }

        this.send("showModal", 'companies.modals.edit-address', address);
      }
    }
  });

  _exports.default = _default;
});