define("apollo/pods/warehouse-zones/edit/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    sessionAccount: service('session-account'),
    access: ['ROLE_SETTINGS_TAB'],
    beforeModel: function beforeModel(transition) {
      if (!this.get('sessionAccount').hasAnyOfTheRoles(this.get('access'))) {
        transition.abort();
        this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      return this.store.findRecord('warehouseZone', params.id);
    },
    renderTemplate: function renderTemplate() {
      this.render('warehouseZones.create');
    },
    setupController: function setupController(controller, model) {
      this.controllerFor('warehouseZones.create').setProperties({
        model: model
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.get('controller').setProperties({
          success: false,
          errors: []
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});