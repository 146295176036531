define("apollo/pods/components/radio-button-group/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    property: Ember.computed('model', 'propertyName', function () {
      return this.get("model.".concat(this.get('propertyName')));
    }),
    actions: {
      setProperty: function setProperty(value) {
        this.get('model').set(this.get('propertyName'), value);
        this.notifyPropertyChange('property');
      }
    }
  });

  _exports.default = _default;
});