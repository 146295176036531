define("apollo/pods/components/price-list/routes-table/route/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    actions: {
      toggleOffers: function toggleOffers(route) {
        route.toggleProperty('hasVisibleOffers');
      },
      showRouteForm: function showRouteForm(route) {
        console.log("Wy\u015Bwietlamy formularz trasy ".concat(route.get('id'), "..."));
        this.set('action', 'showModal');
        this.sendAction('action', 'routes.modals.route-form.show', route);
      },
      openAddNewRateModal: function openAddNewRateModal(route) {
        this.set('action', 'showModal');
        var routeOffer = this.get('store').createRecord('route-offer');
        var model = {
          routeOffer: routeOffer,
          route: route
        };
        this.sendAction('action', 'price-list.modals.rate-form', model);
      },
      openEditRateModal: function openEditRateModal(routeOffer) {
        this.set('action', 'showModal');
        var route = routeOffer.get('route');
        var model = {
          routeOffer: routeOffer,
          route: route
        };
        this.sendAction('action', 'price-list.modals.rate-form', model);
      },
      deleteRoute: function deleteRoute(route) {
        route.deleteRecord();
        route.save();
      }
    }
  });

  _exports.default = _default;
});